import { Component, Input } from '@angular/core';

@Component({
	selector: 'simple-text-content',
	templateUrl: './simple-text-content.component.html',
	styleUrls: ['./simple-text-content.component.less']
})
export class SimpleTextContentComponent {

	@Input() textContent: TextContent;
}

export class TextContent {
	mainText: string = '';
	secondaryText: string = '';
	mainUrl: string = '';
	secondaryUrl: string = '';

	constructor(
		mainText: string,
		secondaryText: string,
		mainUrl: string,
		secondaryUrl: string) {
		this.mainText = mainText;
		this.secondaryText = secondaryText;
		this.mainUrl = mainUrl;
		this.secondaryUrl = secondaryUrl;
	}
}
