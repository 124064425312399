<simple-handle-tab
	[headerTitleText]="'AddedSubscribers' | translate"
	[headerSubtitleText]="headerSubtitleText"
	[headerAddButtonText]="'AddInternal' | translate"
	[allAreSelected]="allAreSelected"
	[disableRemoveSelected]="!someAreSelected"
	[disableSelectAll]="!getItems().length"
	[selectedCount]="getSelectedCount()"
	[disableSearch]="!handleItems.length"
	[hideSearch]="true"
	(onAddButtonClick)="toggleListUserModal()"
	(onRemoveSelected)="removeSelected()"
	(onSelectAll)="selectAll()"
	(onSearch)="handleSearch($event)">
		<ng-container slot="headerActions">
			<button *ngIf="!isMediumScreenSize()"
				class="btn btn-success add-external"
				[title]="'AddExternal' | translate"
				(click)="toggleSubscriberModal()">
					{{ 'AddExternal' | translate }}
			</button>
			<button *ngIf="isMediumScreenSize()"
				class="btn btn-success add-external bi-plus-lg"
				[title]="'AddExternal' | translate"
				(click)="toggleSubscriberModal()">
			</button>
			<button *ngIf="!isMediumScreenSize()"
				class="btn btn-success" #setNotificationsPop="bs-popover"
				placement="bottom"
				containerClass="gr-menu-popover"
				[popover]="setNotificationsTemplate"
				[outsideClick]="true"
				[ngClass]="{
					'disabled': !someAreSelected
				}"
				[title]="'SetNotificationsOnSelected' | translate"
				(click)="initiateGlobalNotifications()">
					{{ 'SetNotificationsOnSelected' | translate }}
			</button>
			<button *ngIf="isMediumScreenSize()"
				class="btn btn-success bi-bell" #setNotificationsPop="bs-popover"
				placement="bottom"
				containerClass="gr-menu-popover"
				[popover]="setNotificationsTemplate"
				[outsideClick]="true"
				[ngClass]="{
					'disabled': !someAreSelected
				}"
				[title]="'SetNotificationsOnSelected' | translate"
				(click)="initiateGlobalNotifications()">
			</button>
			<ng-template #setNotificationsTemplate>
				<div class="gr-menu-item">
					<simple-rules
						[rules]="isManualMeasureContext ? globalDummyUser.notificationsEditableInManualMeasureAssignment : displayUrgentIcon ? globalDummyUser.notificationsEditableInScheduledAssignmentWithUrgent : globalDummyUser.notificationsEditableInScheduledAssignment"
						[editable]="true"
						[shouldStopPropagationOnClick]="true"
						(onRuleClick)="handleNotificationClickGlobal($event)">
					</simple-rules>
				</div>
			</ng-template>
		</ng-container>
		<ng-container slot="itemList">
			<cdk-virtual-scroll-viewport itemSize="{{ itemSize }}" minBufferPx="999" maxBufferPx="1000" class="apx-scrollbar" scrollShadow>
				<simple-item *cdkVirtualFor="let item of getItems(); let index = index; cdkVirtualForTrackBy: trackByFn"
					[item]="item"
					[noBorder]="index === handleItems.length - 1"
					[height]="itemSize"
					(onRemove)="remove($event)"
					(onSelect)="select($event)">
						<ng-container slot="content">
							<div class="details">
								<div class="name" title="{{ item.email }}" *ngIf="item.email">
									{{ item.email }}
								</div>
							</div>
							<div class="notifications" *ngIf="!isManualMeasureContext">
								<div class="header">
									{{ 'ScheduledAssignments' | translate }}
								</div>
								<simple-rules
									[rules]="item.notificationsEditableInScheduledAssignmentOnlyScheduled"
									[editable]="true"
									(onRuleClick)="handleNotificationClick(item, $event)">
								</simple-rules>
							</div>
							<div class="notifications">
								<div class="header">
									{{ (isManualMeasureContext ? 'ThisMeasure' : 'Measures') | translate }}
								</div>
								<simple-rules
									[rules]="isManualMeasureContext ? item.notificationsEditableInManualMeasureAssignment : displayUrgentIcon ? item.notificationsEditableInScheduledAssignmentOnlyMeasureWithUrgent : item.notificationsEditableInScheduledAssignmentOnlyMeasure"
									[editable]="true"
									(onRuleClick)="handleNotificationClick(item, $event)">
								</simple-rules>
							</div>
							<div class="status">
								<simple-status-label *ngIf="!item.userId"
									[text]="'External' | translate">
								</simple-status-label>
							</div>
						</ng-container>
						<ng-container slot="actions">
							<div class="edit-button bi-pencil" *ngIf="!item.userId"
								[title]="'Open' | translate"
								(click)="toggleSubscriberModal(item)">
							</div>
							<div class="edit-button disabled" *ngIf="item.userId"></div>
						</ng-container>
				</simple-item>
				<simple-no-items *ngIf="!handleItems.length && !searchValue"
					[title]="'NoSubscribersAdded' | translate">
				</simple-no-items>
				<simple-no-items *ngIf="!getItems().length && searchValue"
					[title]="'NoResultsMatchingSearch' | translate">
				</simple-no-items>
			</cdk-virtual-scroll-viewport>
		</ng-container>
</simple-handle-tab>
