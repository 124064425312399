import {
	Component,
	ViewChild,
	OnInit
} from '@angular/core';
import { Choice } from '../choice';
import { AuthService } from 'app-core/auth/auth.service';
import { ChoiceService } from '../choice.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ChangeDetailsTabComponent } from './tabs/change-details-tab.component';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';

@Component({
	templateUrl: './create-edit-choice.component.html'
})
export class CreateEditChoiceComponent extends SimpleCreateEditModalDirective<Choice> implements OnInit {

	@ViewChild(ChangeDetailsTabComponent) changeChoiceDetailsTabComponent: ChangeDetailsTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private choiceService: ChoiceService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		if (this.isEdit) {
			this.pending = true;
			try {
				this.initialModel = await this.choiceService.get(this.editModelId);
				this.pending = false;

				this.modifiedModel = new Choice(this.getUniqueVariant(this.initialModel));

				setTimeout(() => {
					this.triggerAllValidation();
				}, 0);

			} catch (errorResponse) {
				this.pending = false;
				this.handleErrorResponse(errorResponse);
				this.closeWithDelay();
			}
		} else {
			this.initialModel = new Choice({});
			this.modifiedModel = new Choice(this.getUniqueVariant(this.initialModel));
		}
	}

	changeDetailsIsValid() {
		return this.changeChoiceDetailsTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeChoiceDetailsTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.choiceService.updateWithOrgId(this.modifiedModel.toPayloadObject(), this.selectedOrganization.id)
				: await this.choiceService.createWithOrgId(this.modifiedModel.toPayloadObject(), this.selectedOrganization.id);

			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeChoiceDetailsTabComponent.setServerErrors(this.serverErrors);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeChoiceDetailsTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Choice) {
		return new Choice(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.value !== this.initialModel.value
			|| this.modifiedModel.color !== this.initialModel.color
			|| this.modifiedModel.isError !== this.initialModel.isError
			|| this.modifiedModel.isDefault !== this.initialModel.isDefault
			|| this.modifiedModel.isUnspecified !== this.initialModel.isUnspecified
			|| this.modifiedModel.allowPriority !== this.initialModel.allowPriority;
	}

	hasUnsavedChanges() {
		return this.modifiedModel && this.detailsHasChanged();
	}
}
