import { Injectable } from '@angular/core';
import { Integration } from 'app-core/organization/my-organization/integrations/integration';
import { Filter, KeyValuePair, SortDirection } from 'app-core/shared-core/filter';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { Category } from 'app-core/shared-core/simple-components/various/categories/simple-categories.component';
import { ConversionHelper } from 'app-core/shared-core/tools/conversion-helper';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { UserGroup } from 'app-core/user-group/user-group';
import { User } from 'app-core/user/user';
import { Schedule } from 'app-inspection/schedule/schedule';
import { Task } from 'app-inspection/task/task';
import { BaseService } from '../../app-core/shared-core/abstract-components/service/base.service';
import { Choice } from '../choice/choice';
import { Entity } from '../entity/entity';
import { Facility } from '../facility/facility';
import { TemplateType } from '../template-type/template-type';
import { Assignee, AssigneeGroup, Assignment } from './assignment';
import { ManualMeasurePayload } from './manual-measure';

@Injectable()
export class AssignmentService extends BaseService<Assignment> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
		super(
			hostedHttpClient,
			RoutesUtils.assignment
		);
	}

	async getFilteredDashboard(filter: Filter): Promise<Assignment[]> {
		return this.hostedHttpClient.get(`${this.prefix}/filtered/dashboard`, filter.toPayloadObject())
			.then(res => res.data.map((item: Assignment) => this.instantiateModel(item)));
	}

	createManualMeasure(manualMeasurePayload: ManualMeasurePayload) {
		return this.hostedHttpClient.post(`${this.prefix}/manualmeasure`, manualMeasurePayload);
	}

	getAssigneeGroupsAndAssignees(itemIds: string[], organizationId: number): Promise<Assignment[]> {
		const model = {
			itemIds: itemIds
		};
		return this.hostedHttpClient.post(`${this.prefix}/assignees?organization=${organizationId}`, model)
			.then(res => res.data.map((item: Assignment) => this.instantiateModel(item)));
	}

	updateAssigneeGroupsAndAssignees(itemIds: string[], assigneeGroups: AssigneeGroup[], assignees: Assignee[], isMeasure: boolean) {
		const model = {
			assignments: []
		};

		const userIdsFromGroupsToNotify = assigneeGroups.filter(group => group.notifyOnAdd)
			.flatMap(group => group.userGroup.users)
			.filter(user => !assignees.find(assignee => assignee.user.id === user.id))
			.map(user => user.id);

		const userIdsToNotify = assignees.filter(assignee => assignee.notifyOnAdd)
			.map(assignee => assignee.user.id);

		isMeasure ?
			itemIds.forEach(id => {
				model.assignments.push({
					id: id,
					assignees: assignees.map(user => {
						return {
							userId: user.userId,
							canPerformMeasure: user.canPerformMeasure
						};
					}),
					assigneeGroups: assigneeGroups.map(group => {
						return {
							userGroupId: group.userGroupId,
							canPerformMeasure: group.canPerformMeasure
						};
					}),
					userIdsToNotify: [...userIdsFromGroupsToNotify, ...userIdsToNotify]
				});
			}) :
			itemIds.forEach(id => {
				model.assignments.push({
					id: id,
					assignees: assignees.map(user => {
						return {
							userId: user.userId,
							canPerformMeasure: user.canPerformMeasure,
							canPerformScheduled: user.canPerformScheduled,
							canAssign: user.canAssign
						};
					}),
					assigneeGroups: assigneeGroups.map(group => {
						return {
							userGroupId: group.userGroupId,
							canPerformMeasure: group.canPerformMeasure,
							canPerformScheduled: group.canPerformScheduled,
							canAssign: group.canAssign
						};
					}),
					userIdsToNotify: [...userIdsFromGroupsToNotify, ...userIdsToNotify]
				});
			});

		return this.hostedHttpClient.put(`${this.prefix}/assignees`, model);
	}

	updateDueDate(itemIds: string[], date: string) {
		const model = {
			itemIds: itemIds,
			dueDate: date
		};
		return this.hostedHttpClient.put(`${this.prefix}/duedate`, model);
	}

	getPriorityData(itemIds: string[], priority: boolean, orgId: number) {
		const model = {
			itemIds: itemIds,
			priority: priority,
			organizationId: orgId
		};
		return this.hostedHttpClient.post(`${this.prefix}/priorityids`, model);
	}

	updatePriority(itemIds: string[], priority: boolean, organizationId: number) {
		const model = {
			itemIds: itemIds,
			priority: priority,
			organizationId: organizationId
		};
		return this.hostedHttpClient.put(`${this.prefix}/priority`, model);
	}

	updateMeasureStatus(itemIds: string[], status: string, notes: string, organizationId: number) {
		const model = {
			itemIds: itemIds,
			status: status,
			notes: notes,
			organizationId: organizationId
		};
		return this.hostedHttpClient.put(`${this.prefix}/assignmentstatus`, model);
	}

	deleteWithOrgId(assignmentId: string, organizationId: number) {
		return this.hostedHttpClient.delete(`${this.prefix}/${assignmentId}?organization=${organizationId}`, {});
	}

	deleteRangeWithOrgId(itemIds: string[], organizationId: number) {
		const model = {
			itemIds: itemIds,
			organizationId: organizationId
		};
		return this.hostedHttpClient.delete(`${this.prefix}`, model);
	}

	getTemplateTypes(organizationId: number): Promise<TemplateType[]> {
		return this.hostedHttpClient.get(`templateType/organization/${organizationId}?includeManualMeasure=true`, {})
			.then(res => res.data.map((item: TemplateType) => new TemplateType(item)));
	}

	getFacilities(): Promise<Facility[]> {
		const filter = new Filter();
		filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		filter.addSort(
			new KeyValuePair(
				Utils.uppercaseEachFirst(Utils.nameof<Facility>('name')),
				SortDirection.Ascending
			)
		);
		return this.hostedHttpClient.get(`facility/filteredac`, filter.toPayloadObject())
			.then(res => res.data.map((item: Facility) => new Facility(item)));
	}

	getSchedules(organizationId: number): Promise<Schedule[]> {
		const filter = new Filter();
		filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		filter.addSort(
			new KeyValuePair(
				Utils.uppercaseEachFirst(Utils.nameof<Schedule>('name')),
				SortDirection.Ascending
			)
		);
		return this.hostedHttpClient.get(`schedule?organization=${organizationId}`, filter.toPayloadObject())
			.then(res => res.data.map((item: Schedule) => new Schedule(item)));
	}

	getEntities(): Promise<Entity[]> {
		const filter = new Filter();
		filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		filter.addSort(
			new KeyValuePair(
				Utils.uppercaseEachFirst(Utils.nameof<Entity>('name')),
				SortDirection.Ascending
			)
		);
		return this.hostedHttpClient.get(`entity/filteredac`, filter.toPayloadObject())
			.then(res => res.data.map((item: Entity) => new Entity(item)));
	}

	getUserGroups(organizationId: number): Promise<UserGroup[]> {
		const filter = new Filter();
		filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		filter.addSort(
			new KeyValuePair(
				Utils.uppercaseEachFirst(Utils.nameof<UserGroup>('name')),
				SortDirection.Ascending
			)
		);
		return this.hostedHttpClient.get(`usergroup?organization=${organizationId}`, filter.toPayloadObject(), true)
			.then(res => res.data.map((item: UserGroup) => new UserGroup(item)));
	}

	getUsers(organizationId: number): Promise<User[]> {
		const filter = new Filter();
		filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		filter.addSort(
			new KeyValuePair(
				Utils.uppercaseEachFirst(Utils.nameof<User>('firstname')),
				SortDirection.Ascending
			)
		);
		return this.hostedHttpClient.get(`user?organization=${organizationId}`, filter.toPayloadObject(), true)
			.then(res => res.data.map((item: User) => new User(item)));
	}

	getCategories(organizationId: number): Promise<Category[]> {
		return this.hostedHttpClient.get(`organization/${organizationId}/category`, {})
			.then(res => res.data.map((item: Category) => new Category(item)));
	}

	getIssueNumbers(organizationId: number, isOngoing: boolean): Promise<number[]> {
		return this.hostedHttpClient.get(`${this.prefix}/issueNumber?organization=${organizationId}&active=${isOngoing}`, {})
			.then(res => res.data.map((item: number) => item));
	}

	getTasks(organizationId: number): Promise<Task[]> {
		return this.hostedHttpClient.get(`task?organization=${organizationId}`, {})
			.then(res => res.data.map((item: Task) => new Task(item)));
	}

	getChoices(organizationId: number): Promise<Choice[]> {
		return this.hostedHttpClient.get(`choice?organization=${organizationId}`, {})
			.then(res => res.data.map((item: Choice) => new Choice(item)));
	}

	getIntegrations(organizationId: number): Promise<Integration[]> {
		return this.hostedHttpClient.get(`organization/${organizationId}/webhooks`, {}, true)
			.then(res => res.data.map((item: Integration) => new Integration(item)));
	}

	private convertNewMedia(manualMeasureData: any): Promise<any> {
		return new Promise((resolve, reject) => {
			const hasNewMedia = manualMeasureData.errors.filter((error: any) => {
				return error.newMedia.length;
			}).length;
			if (!hasNewMedia) {
				resolve(manualMeasureData);
			} else {
				const promises = [];
				manualMeasureData.errors.map((error: any) => {
					return error.newMedia;
				}).forEach((newMediaArray: any[]) => {
					newMediaArray.forEach((newItem: any) => {
						const promise = ConversionHelper.blobToBase64asPromise(newItem.data)
							.then(result => {
								newItem.data = result;
							});
						promises.push(promise);
					});
				});
				Promise.all(promises)
					.then(_ => {
						resolve(manualMeasureData);
					})
					.catch(response => {
						reject(response);
					});
			}
		});
	}

	instantiateModel(item: Assignment) {
		return new Assignment(item);
	}
}

export class AssignmentFilter extends Filter {
	context: string;

	toPayloadObject() {
		const tempFacets = {};
		const currentFacets = (Object.entries(this.facets) as [string, string][])
			.map(facet => {
				return {
					key: facet[0],
					value: facet[1]
				};
			});

		currentFacets.forEach(facet => {
			if (facet.key === StringUtils.TEMPLATE_TYPES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.TEMPLATE_TYPE_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.TEMPLATE_TYPE_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.FACILITIES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.FACILITY_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.FACILITY_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.CATEGORIES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.CATEGORY_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.CATEGORY_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.SCHEDULES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.SCHEDULE_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.SCHEDULE_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.ENTITIES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.ERROR_ENTITY_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.ERROR_ENTITY_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.TASKS_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.TASK_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.TASK_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.CHOICES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.CHOICE_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.CHOICE_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.PRIORITIZED_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.HAS_PRIORITY_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.HAS_PRIORITY_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.HAS_PRIORITY_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.COMPLETED_BY_KEY || facet.key === StringUtils.HANDLED_BY_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.COMPLETED_BY_USER_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.COMPLETED_BY_USER_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.CREATED_BY_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.CREATED_BY_USER_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.CREATED_BY_USER_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.HAS_GROUPS_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.HAS_ASSIGNEE_GROUPS_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.HAS_ASSIGNEE_GROUPS_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.HAS_ASSIGNEE_GROUPS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.GROUPS_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.USER_GROUP_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.USER_GROUP_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.HAS_ASSIGNEES_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.HAS_ASSIGNEES_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.HAS_ASSIGNEES_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.ASSIGNEES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.USER_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.USER_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.INTEGRATIONS_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.INTEGRATION_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.INTEGRATION_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.CREATED_BETWEEN_KEY) {
				const strippedKey = facet.key.replace(StringUtils.BETWEEN, '');
				const [fromDate, toDate] = facet.value.split('_');
				const fromFacet = `${StringUtils.FROM_FACET}:${strippedKey}`;
				const toFacet = `${StringUtils.TO_FACET}:${strippedKey}`;
				tempFacets[fromFacet] = fromDate;
				tempFacets[toFacet] = toDate;
			} else if (!currentFacets.some(f => f.key === StringUtils.CREATED_BETWEEN_KEY) && (facet.key === `${StringUtils.FROM_FACET}:${StringUtils.CREATED_KEY}`) || facet.key === `${StringUtils.TO_FACET}:${StringUtils.CREATED_KEY}`) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.EXPIRES_BETWEEN_KEY) {
				const [fromDate, toDate] = facet.value.split('_');
				const fromFacet = `${StringUtils.FROM_FACET}:${StringUtils.DUE_DATE_KEY}`;
				const toFacet = `${StringUtils.TO_FACET}:${StringUtils.DUE_DATE_KEY}`;
				tempFacets[fromFacet] = fromDate;
				tempFacets[toFacet] = toDate;
			} else if (!currentFacets.some(f => f.key === StringUtils.EXPIRES_BETWEEN_KEY) && (facet.key === `${StringUtils.FROM_FACET}:${StringUtils.DUE_DATE_KEY}`) || facet.key === `${StringUtils.TO_FACET}:${StringUtils.DUE_DATE_KEY}`) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.ARCHIVED_BETWEEN_KEY) {
				const strippedKey = facet.key.replace(StringUtils.BETWEEN, '');
				const [fromDate, toDate] = facet.value.split('_');
				const fromFacet = `${StringUtils.FROM_FACET}:${strippedKey}`;
				const toFacet = `${StringUtils.TO_FACET}:${strippedKey}`;
				tempFacets[fromFacet] = fromDate;
				tempFacets[toFacet] = toDate;
			} else if (!currentFacets.some(f => f.key === StringUtils.ARCHIVED_BETWEEN_KEY) && (facet.key === `${StringUtils.FROM_FACET}:${StringUtils.ARCHIVED_KEY}`) || facet.key === `${StringUtils.TO_FACET}:${StringUtils.ARCHIVED_KEY}`) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.TYPE_KEY) {
				const type = facet.value;
				if (type === StringUtils.MEASURE_ASSIGNMENT) {
					tempFacets[StringUtils.IS_MEASURE_KEY] = 'true';
					delete this.facets[StringUtils.IS_MANUAL_MEASURE_KEY];
				} else if (type === StringUtils.MANUAL_MEASURE_ASSIGNMENT) {
					tempFacets[StringUtils.IS_MANUAL_MEASURE_KEY] = 'true';
					delete this.facets[StringUtils.IS_MEASURE_KEY];
				}
			} else if (!currentFacets.some(f => f.key === StringUtils.TYPE_KEY) && (facet.key === StringUtils.IS_MEASURE_KEY || facet.key === StringUtils.IS_MANUAL_MEASURE_KEY) && facet.value === 'true') {
				tempFacets[StringUtils.IS_MEASURE_KEY] = 'true';
				tempFacets[StringUtils.IS_MANUAL_MEASURE_KEY] = 'true';
			} else if (this.context && this.context === RoutesUtils.scheduledArchived) {
				if (facet.key === StringUtils.STATUS_KEY) {
					const status = facet.value;
					if (status === StringUtils.COMPLETED) {
						tempFacets[StringUtils.IS_COMPLETED_KEY] = 'true';
						delete this.facets[StringUtils.IS_EXPIRED_KEY];
					} else if (status === StringUtils.EXPIRED) {
						tempFacets[StringUtils.IS_EXPIRED_KEY] = 'true';
						delete this.facets[StringUtils.IS_COMPLETED_KEY];
					}
				} else if (!currentFacets.some(f => f.key === StringUtils.STATUS_KEY) && (facet.key === StringUtils.IS_COMPLETED_KEY || facet.key === StringUtils.IS_EXPIRED_KEY) && facet.value === 'true') {
					tempFacets[StringUtils.IS_COMPLETED_KEY] = 'true';
					tempFacets[StringUtils.IS_EXPIRED_KEY] = 'true';
				}
			} else if (this.context && this.context === RoutesUtils.scheduledOngoing) {
				if (facet.key === StringUtils.STATUS_KEY) {
					const status = facet.value;
					if (status === StringUtils.STARTED) {
						tempFacets[StringUtils.IS_STARTED_KEY] = 'true';
						delete this.facets[StringUtils.IS_ACTIVE_KEY];
					} else if (status === StringUtils.NOT_STARTED) {
						tempFacets[StringUtils.IS_ACTIVE_KEY] = 'true';
						delete this.facets[StringUtils.IS_STARTED_KEY];
					}
				} else if (!currentFacets.some(f => f.key === StringUtils.STATUS_KEY) && (facet.key === StringUtils.IS_STARTED_KEY || facet.key === StringUtils.IS_ACTIVE_KEY) && facet.value === 'true') {
					tempFacets[StringUtils.IS_STARTED_KEY] = 'true';
					tempFacets[StringUtils.IS_ACTIVE_KEY] = 'true';
				}
			} else if (this.context && this.context === RoutesUtils.measureArchived) {
				if (facet.key === StringUtils.STATUS_KEY) {
					const status = facet.value;
					if (status === StringUtils.COMPLETED) {
						tempFacets[StringUtils.IS_COMPLETED_KEY] = 'true';
						delete this.facets[StringUtils.IS_CLOSED_KEY];
					} else if (status === StringUtils.CLOSED) {
						tempFacets[StringUtils.IS_CLOSED_KEY] = 'true';
						delete this.facets[StringUtils.IS_COMPLETED_KEY];
					}
				} else if (!currentFacets.some(f => f.key === StringUtils.STATUS_KEY) && (facet.key === StringUtils.IS_COMPLETED_KEY || facet.key === StringUtils.IS_CLOSED_KEY) && facet.value === 'true') {
					tempFacets[StringUtils.IS_COMPLETED_KEY] = 'true';
					tempFacets[StringUtils.IS_CLOSED_KEY] = 'true';
				}
			} else if (this.context && this.context === RoutesUtils.measureOngoing) {
				if (facet.key === StringUtils.STATUS_KEY) {
					const status = facet.value;
					if (status === StringUtils.STARTED) {
						tempFacets[StringUtils.IS_STARTED_KEY] = 'true';
						delete this.facets[StringUtils.IS_ACTIVE_KEY];
					} else if (status === StringUtils.NOT_STARTED) {
						tempFacets[StringUtils.IS_ACTIVE_KEY] = 'true';
						delete this.facets[StringUtils.IS_STARTED_KEY];
					}
				} else if (!currentFacets.some(f => f.key === StringUtils.STATUS_KEY) && (facet.key === StringUtils.IS_STARTED_KEY || facet.key === StringUtils.IS_ACTIVE_KEY) && facet.value === 'true') {
					tempFacets[StringUtils.IS_STARTED_KEY] = 'true';
					tempFacets[StringUtils.IS_ACTIVE_KEY] = 'true';
				}
			}
		});

		Object.assign(this.facets, tempFacets);

		// Exclude context property from the filterObject.
		const { context, ...theRest } = this;

		return {
			filter: JSON.stringify(theRest)
		};
	}
}
