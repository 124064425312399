import { HostedHttpClientServiceMiddleware } from 'app-core/shared-core/hosted-httpclient-middleware';
import { HostedHttpRequest } from 'app-core/shared-core/hosted-http-request';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';

export class ClientMiddleware implements HostedHttpClientServiceMiddleware {
	async handleHttpResponse(request: HostedHttpRequest, body: any, retry: (request?: HostedHttpRequest) => Promise<Object>): Promise<Object> {
		if (request.endpoint.endsWith('client/affectedData')) {
			return retry(
				request.merge({
					endpoint: `${StringUtils.CLIENT}/${request.options['body'].itemIds[0]}`,
					useCoreApi: true
				})
			);
		} else {
			return body;
		}
	}
	async handleHttpError(body: Object, request: HostedHttpRequest, retry: (request?: HostedHttpRequest) => Promise<Object>): Promise<Object> {
		return body;
	}
}
