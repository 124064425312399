import { NgModule } from '@angular/core';
import { MediaModule } from 'app-core/media/media-module';
import { InspireTreeModule } from '../shared-core/inspire-tree/inspire-tree.module';
import { SharedModule } from '../shared-core/shared.module';
import { CreateEditOrganizationComponent } from './create-edit-organization/create-edit-organization.component';
import { ChangeDetailsTabComponent } from './create-edit-organization/tabs/change-details-tab.component';
import { GlobalOrganizationModalComponent } from './global-organization/global-organization-modal.component';
import { CreateEditClientComponent } from './my-organization/clients/create-edit-client.component';
import { ChangeClientDetailsTabComponent } from './my-organization/clients/tabs/change-client-details-tab.component';
import { IntegrationModule } from './my-organization/integrations/integration.module';
import { LogotypeImageComponent } from './my-organization/logotype-image/logotype-image.component';
import { MyOrganizationComponent } from './my-organization/my-organization.component';
import { CreateEditTemplateTypeDescriptionComponent } from './my-organization/template-types/create-edit-template-type-description.component';
import { ChangeTemplateTypeDescriptionTabComponent } from './my-organization/template-types/tabs/change-template-type-description-tab.component';
import { OrganizationRoutingModule } from './organization-routing.module';

@NgModule({
	imports: [
		SharedModule,
		OrganizationRoutingModule,
		InspireTreeModule,
		MediaModule,
		IntegrationModule
	],
	declarations: [
		CreateEditOrganizationComponent,
		ChangeDetailsTabComponent,
		GlobalOrganizationModalComponent,
		MyOrganizationComponent,
		LogotypeImageComponent,
		CreateEditClientComponent,
		ChangeClientDetailsTabComponent,
		CreateEditTemplateTypeDescriptionComponent,
		ChangeTemplateTypeDescriptionTabComponent
	],
	exports: [
		GlobalOrganizationModalComponent,
		MyOrganizationComponent
	]
})
export class OrganizationModule { }
