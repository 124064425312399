import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { OrganizationService } from 'app-core/organization/organization.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Entity } from 'app-inspection/entity/entity';
import { HandleEntitiesTabComponent } from 'app-inspection/facility/create-edit-facility/tabs/handle-entities/handle-entities-tab.component';
import { Facility } from 'app-inspection/facility/facility';
import { Task } from 'app-inspection/task/task';
import { HandleTasksTabComponent } from 'app-inspection/template-type/create-edit-template-type/tabs/handle-tasks/handle-tasks-tab.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AssignmentTemplate } from '../assignment-template';
import { AssignmentTemplateService } from '../assignment-template.service';
import { ChangeDetailsTabComponent } from './tabs/change-details/change-details-tab.component';
import { HandleFacilitiesTabComponent } from './tabs/handle-facilities/handle-facilities-tab.component';

@Component({
	templateUrl: './create-edit-assignment-template.component.html'
})
export class CreateEditAssignmentTemplateComponent extends SimpleCreateEditModalDirective<AssignmentTemplate> implements OnInit {

	facilityIds: string[] = [];

	currentTemplateTypeId: string;

	@ViewChild(ChangeDetailsTabComponent) changeAssignmentTemplateDetailsTabComponent: ChangeDetailsTabComponent;
	@ViewChild(HandleFacilitiesTabComponent) handleFacilitiesTabComponent: HandleFacilitiesTabComponent;
	@ViewChild('handleFacilityTasksComponent') handleFacilityTasksTabComponent: HandleTasksTabComponent;
	@ViewChild(HandleEntitiesTabComponent) handleEntitiesTabComponent: HandleEntitiesTabComponent;
	@ViewChild('handleEntityTasksComponent') handleEntityTasksTabComponent: HandleTasksTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private assignmentTemplateService: AssignmentTemplateService,
		private organizationService: OrganizationService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			if (this.isEdit) {
				const [clients, categories] = await Promise.all([
					this.organizationService.getClientsInOrganization(),
					this.assignmentTemplateService.getCategories(this.selectedOrganization.id)
				]);

				this.initialModel = await this.assignmentTemplateService.get(this.editModelId);
				this.pending = false;

				this.initialModel.selectableTemplateTypes.push(this.initialModel.templateType);
				this.initialModel.selectableClients = clients;
				this.initialModel.selectableCategories = categories;

				this.initialModel.facilities.push(this.initialModel.facility);
				this.facilityIds = [this.initialModel.facility.id];

				this.modifiedModel = new AssignmentTemplate(this.getUniqueVariant(this.initialModel));

				setTimeout(() => {
					this.activateTabFromUrl();
					this.triggerAllValidation();
				}, 0);
			} else {

				// Abort if not superadmin.
				if (!this.loggedInUser.isSuperAdmin()) {
					this.displayErrorMessage(`${this.translationService.instant(StringUtils.NO_PERMISSION)}!`);
					this.closeWithDelay();
					return;
				}

				let templateTypes = await this.assignmentTemplateService.getTemplateTypes(this.selectedOrganization.id);
				templateTypes = templateTypes.filter(templateType => !templateType.isManualMeasure);
				const facilities = await this.assignmentTemplateService.getFacilities();

				// Abort if no template types or facilities.
				if (!templateTypes.length || !facilities.length) {
					this.displayErrorMessage(`${this.translationService.instant(StringUtils.NO_TEMPLATE_TYPE_ERROR)}!`);
					this.closeWithDelay();
					return;
				}

				const [clients, categories] = await Promise.all([
					this.organizationService.getClientsInOrganization(),
					this.assignmentTemplateService.getCategories(this.selectedOrganization.id)
				]);
				this.pending = false;

				this.initialModel = new AssignmentTemplate({
					templateTypeId: templateTypes[0].id,
					selectableTemplateTypes: templateTypes,
					selectableClients: clients,
					selectableCategories: categories,
					name: templateTypes[0].name
				});

				this.modifiedModel = new AssignmentTemplate(this.getUniqueVariant(this.initialModel));

				this.currentTemplateTypeId = this.modifiedModel.templateTypeId;

				setTimeout(() => {
					this.activateTabFromUrl();
				}, 0);
			}

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	changeDetailsIsValid() {
		return this.changeAssignmentTemplateDetailsTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeAssignmentTemplateDetailsTabComponent?.formHasErrors();
	}

	handleFacilitiesIsValid() {
		return this.modifiedModel.facilities.length && this.handleFacilitiesTabComponent?.itemListIsValid();
	}

	handleFacilitiesHasErrors() {
		return (!this.modifiedModel.facilities.length && !!this.modifiedModel.facilityTasks.length)
			|| (!this.modifiedModel.facilities.length && !this.modifiedModel.entities.length && !!this.modifiedModel.entityTasks.length)
			|| this.handleFacilitiesTabComponent?.itemListHasErrors();
	}

	handleFacilityTasksIsValid() {
		return (!!this.modifiedModel.facilityTasks.length && this.handleFacilityTasksTabComponent?.itemListIsValid())
			|| (!this.modifiedModel.facilityTasks.length && (!!this.modifiedModel.entities.length || !!this.modifiedModel.entityTasks.length));
	}

	handleFacilityTasksHasErrors() {
		return this.handleFacilityTasksTabComponent?.itemListHasErrors();
	}

	handleEntitiesIsValid() {
		return (!!this.modifiedModel.entities.length && this.handleEntitiesTabComponent?.itemListIsValid())
			|| (!this.modifiedModel.entities.length && !this.modifiedModel.entityTasks.length && !!this.modifiedModel.facilities.length && !!this.modifiedModel.facilityTasks.length);
	}

	handleEntitiesHasErrors() {
		return (!this.modifiedModel.entities.length && !!this.modifiedModel.entityTasks.length)
			|| this.handleEntitiesTabComponent?.itemListHasErrors();
	}

	handleEntityTasksIsValid() {
		return (!!this.modifiedModel.entityTasks.length && this.handleEntityTasksTabComponent?.itemListIsValid())
			|| (!this.modifiedModel.entityTasks.length && !this.modifiedModel.entities.length && !!this.modifiedModel.facilities.length && !!this.modifiedModel.facilityTasks.length);
	}

	handleEntityTasksHasErrors() {
		return (!!this.modifiedModel.entities.length && !this.modifiedModel.entityTasks.length)
			|| (this.modifiedModel.entityTasks.length && this.handleEntityTasksTabComponent?.itemListHasErrors());
	}

	everythingIsValid() {
		return this.changeDetailsIsValid() && this.handleFacilitiesIsValid() && this.handleFacilityTasksIsValid()
			&& this.handleEntitiesIsValid() && this.handleEntityTasksIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.assignmentTemplateService.update(this.modifiedModel.toPayloadObject(this.selectedOrganization.id))
				: await this.assignmentTemplateService.create(this.modifiedModel.toPayloadObject(this.selectedOrganization.id));

			const customMessage = this.isEdit
				? ''
				: this.translationService.instant('BackgroundJobStartedCreateAssignmentTemplate');

			this.handleSuccessResponse(response, customMessage);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeAssignmentTemplateDetailsTabComponent.setServerErrors(this.serverErrors);
				this.goToTab(1);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeAssignmentTemplateDetailsTabComponent?.triggerValidation();
		this.handleFacilitiesTabComponent?.triggerValidation();
		this.handleFacilityTasksTabComponent?.triggerValidation();
		this.handleEntityTasksTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: AssignmentTemplate) {
		return new AssignmentTemplate(item);
	}

	private detailsHasChanged() {
		const detailsChange = this.modifiedModel.templateTypeId !== this.initialModel.templateTypeId
			|| this.modifiedModel.clientId !== this.initialModel.clientId
			|| this.modifiedModel.geoControlled !== this.initialModel.geoControlled;

		const currentCategories = this.modifiedModel.categoryIds;
		const initialCategories = this.initialModel.categoryIds;

		return detailsChange || !Utils.isEqual(currentCategories, initialCategories);
	}

	private facilitiesHasChanged() {
		const currentFacilities = this.stripSelectedFacilities(this.modifiedModel.facilities);
		const initialFacilities = this.stripSelectedFacilities(this.initialModel.facilities);
		const currentFacilityTasks = this.stripSelectedTasks(this.modifiedModel.facilityTasks);
		const initialFacilityTasks = this.stripSelectedTasks(this.initialModel.facilityTasks);

		return !Utils.isEqual(currentFacilities, initialFacilities) || !Utils.isEqual(currentFacilityTasks, initialFacilityTasks);
	}

	private entitiesHasChanged() {
		const currentEntities = this.stripSelectedEntities(this.modifiedModel.entities);
		const initialEntities = this.stripSelectedEntities(this.initialModel.entities);
		const currentEntityTasks = this.stripSelectedTasks(this.modifiedModel.entityTasks);
		const initialEntityTasks = this.stripSelectedTasks(this.initialModel.entityTasks);

		return !Utils.isEqual(currentEntities, initialEntities) || !Utils.isEqual(currentEntityTasks, initialEntityTasks);
	}

	private stripSelectedFacilities(items: Facility[]) {
		return items.map(item => {
			const { selected, ...theRest } = item;
			return theRest as Facility;
		});
	}

	private stripSelectedEntities(items: Entity[]) {
		return items.map(item => {
			const { selected, ...theRest } = item;
			return theRest as Entity;
		});
	}

	private stripSelectedTasks(items: Task[]) {
		return items.map(item => {
			const { selected, ...theRest } = item;
			return theRest as Task;
		});
	}

	hasUnsavedChanges() {
		return this.modifiedModel && (this.detailsHasChanged() || this.facilitiesHasChanged() || this.entitiesHasChanged());
	}

	handleValueChanges() {
		if (this.changeAssignmentTemplateDetailsTabComponent) {
			const templateTypeIdCtrl = this.changeAssignmentTemplateDetailsTabComponent.form.get(this.propertyStrings.templateTypeId);
			const geoControlledCtrl = this.changeAssignmentTemplateDetailsTabComponent.form.get(this.propertyStrings.geoControlled);
			if (!this.isEdit && templateTypeIdCtrl.value !== this.currentTemplateTypeId) {
				this.modifiedModel.facilityTasks = [];
				this.modifiedModel.entityTasks = [];
				this.currentTemplateTypeId = templateTypeIdCtrl.value;
				this.modifiedModel.name = this.modifiedModel.selectableTemplateTypes.find(templateType => templateType.id === templateTypeIdCtrl.value).name;
			}
			if (!this.isEdit && geoControlledCtrl.value) {
				this.modifiedModel.facilities = this.modifiedModel.facilities.filter(facility => facility.hasPosition);
				this.setFacilityIdsAndClearEntities();
			}
			this.setSelectedFlags();
		}
	}

	setFacilityIdsAndClearEntities() {
		this.facilityIds = this.modifiedModel.facilities.map(facility => facility.id);
		this.modifiedModel.entities = this.modifiedModel.entities.filter(entity => this.facilityIds.includes(entity.facility.id));
		this.setSelectedFlags();
	}

	private setSelectedFlags() {
		this.handleFacilitiesTabComponent?.setSelectedFlags();
		this.handleEntitiesTabComponent?.setSelectedFlags();
		this.handleFacilityTasksTabComponent?.setSelectedFlags();
		this.handleEntityTasksTabComponent?.setSelectedFlags();
	}
}
