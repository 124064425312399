<simple-crud-modal
	[titleText]="('Entity' | translate) + (modifiedModel?.name ? ' ─ ' + modifiedModel?.name : '')"
	[closeText]="'Close' | translate"
	[submitText]="(isEdit ? 'Update' : 'Create') | translate"
	[disableSubmit]="!everythingIsValid() || !hasUnsavedChanges()"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab *ngIf="isEdit"
				[customClass]="'valid'"
				(selectTab)="setTabIndexInUrl(1)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-search'"
						[title]="'Overview' | translate"
						[helpText]="'EntityViewDetailsHelpText' | translate">
					</simple-tab-heading>
				</ng-template>
				<view-details-tab
					[viewModel]="modifiedModel">
				</view-details-tab>
			</tab>
			<tab *ngIf="true"
				[customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(isEdit ? 2 : 1)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-card-text'"
						[title]="'Details' | translate"
						[helpText]="isEdit ? ('EntityChangeDetailsEditHelpText' | translate) : ('EntityChangeDetailsHelpText' | translate)">
					</simple-tab-heading>
				</ng-template>
				<change-details-tab
					[changeModel]="modifiedModel"
					[hideFacility]="hideFacility"
					(onValueChange)="handleValueChanges()">
				</change-details-tab>
			</tab>
			<tab *ngIf="true"
				[customClass]="handleMediaIsValid() ? 'valid' : handleMediaHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(isEdit ? 3 : 2)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-files'"
						[title]="'Media' | translate"
						[helpText]="isEdit ? ('EntityHandleMediaEditHelpText' | translate) : ('EntityHandleMediaHelpText' | translate)"
						[displayCount]="modifiedModel.media.length">
					</simple-tab-heading>
				</ng-template>
				<handle-media-tab
					[handleItems]="modifiedModel.media"
					[headerSubtitleText]="'AddedMediaSubtitleEntity' | translate"
					[canToggleDefault]="true"
					(onModelsChange)="modifiedModel.media = $event">
				</handle-media-tab>
			</tab>
		</tabset>
</simple-crud-modal>
