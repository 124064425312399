import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Subscriber } from 'app-core/user/user';
import { UserService } from 'app-core/user/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ChangeSubscriberDetailsTabComponent } from './tabs/change-subscriber-details-tab.component';

@Component({
	templateUrl: './create-edit-subscriber.component.html'
})
export class CreateEditSubscriberComponent extends SimpleCreateEditModalDirective<Subscriber> implements OnInit {

	subscriber: Subscriber;
	allOtherSubscribers: Subscriber[] = [];

	@ViewChild(ChangeSubscriberDetailsTabComponent) changeSubscriberDetailsTabComponent: ChangeSubscriberDetailsTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private userService: UserService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			if (!this.userService.cultureSettings) {
				this.userService.cultureSettings = await this.userService.getCultureSettings();
			}

			if (this.isEdit) {
				this.initialModel = this.subscriber;
				this.pending = false;

				this.assignCultureSettings();

				this.modifiedModel = new Subscriber(this.getUniqueVariant(this.initialModel));

				setTimeout(() => {
					this.triggerAllValidation();
				}, 0);
			} else {
				this.initialModel = new Subscriber({
					culture: 'sv-SE'
				});
				this.pending = false;

				this.assignCultureSettings();

				this.modifiedModel = new Subscriber(this.getUniqueVariant(this.initialModel));
			}
		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	private assignCultureSettings() {
		this.initialModel.selectableCountryCodes = this.userService.cultureSettings.countryCodes;
		this.initialModel.selectableCultures = this.userService.cultureSettings.cultures;
	}

	changeDetailsIsValid() {
		return this.changeSubscriberDetailsTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeSubscriberDetailsTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		if (this.isUnique()) {
			this.updated$.next([this.modifiedModel]);
		} else {
			this.changeSubscriberDetailsTabComponent.setServerErrors(this.serverErrors);
		}
	}

	private isUnique() {
		const emailExists = this.modifiedModel.email && this.allOtherSubscribers.some(sub => sub.email === this.modifiedModel.email);
		if (emailExists) {
			this.serverErrors.push(new KeyValuePair(this.propertyStrings.email, this.translationService.instant('EmailAlreadyExists')));
		}
		return !emailExists;
	}

	protected triggerAllValidation() {
		this.changeSubscriberDetailsTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Subscriber) {
		return new Subscriber(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.email !== this.initialModel.email
			|| this.modifiedModel.culture !== this.initialModel.culture;
	}

	hasUnsavedChanges() {
		return this.modifiedModel && this.detailsHasChanged();
	}
}
