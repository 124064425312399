import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ALT_DATE_TIME_FORMAT } from 'app-core/shared-core/tools/utils';
import * as moment from 'moment';

@Component({
	selector: 'simple-form-date-picker',
	templateUrl: './simple-form-date-picker.component.html',
	styleUrls: ['./simple-form-date-picker.component.less']
})
export class SimpleFormDatePickerComponent {

	@Input() label: string;
	@Input() dateString: string;
	@Input() errorMessage: string;
	@Input() displayClearButton: boolean;

	@Output() onDateChange = new EventEmitter<string>();

	handleBsValueChange(date: Date) {
		const dateString = date ? moment(date).format(ALT_DATE_TIME_FORMAT) : null;
		this.onDateChange.emit(dateString);
	}
}
