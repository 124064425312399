export const RoutesUtils = {
	list: 'list',
	grid: 'grid',
	new: 'new',
	idParam: ':id',
	orgParam: ':org',
	viewQueryParam: '?view=',
	assignments: 'assignments',
	scheduledOngoing: 'scheduled-ongoing',
	scheduledArchived: 'scheduled-archived',
	measureOngoing: 'measure-ongoing',
	measureArchived: 'measure-archived',
	assignmentTemplates: 'assignment-templates',
	schedules: 'schedules',
	facilities: 'facilities',
	entities: 'entities',
	users: 'users',
	userGroups: 'user-groups',
	statistics: 'statistics',
	library: 'library',
	organization: 'organization',
	administration: 'administration',
	notification: 'notification',
	templateTypes: 'template-types',
	tasks: 'tasks',
	choices: 'choices',
	profile: 'profile',
	empty: '',
	base: '/',
	notSpecified: '**',
	logout: 'logout',
	webhook: 'webhook',
	client: 'client',
	dashboard: 'dashboard',

	// Invitation related routes.
	invitation: 'invitation',

	// Auth related routes.
	callback: 'callback',
	renewToken: 'renew-token',

	// Singulars
	assignment: 'assignment',
	assignmentTemplate: 'assignmentTemplate',
	templateType: 'templateType',
	user: 'user',
	userGroup: 'userGroup',
	current: 'current',
	schedule: 'schedule',
	facility: 'facility',
	entity: 'entity',
	task: 'task',
	choice: 'choice',
	admin: 'admin',

	modalTabParam: 'tab',

	modalPrefixValueNew: 'new',
	modalPrefixValueDelete: 'delete',
	modalPrefixValueEdit: 'edit',
	modalPrefixValueResendEmail: 'resendEmail',
	modalPrefixValueViewUsers: 'viewUsers',
	modalPrefixValueViewAssigneeGroupsAndAssignees: 'viewAssigneeGroupsAndAssignees',
	modalPrefixValueViewSchedules: 'viewSchedules',
	modalPrefixValueViewMarkdown: 'viewMarkdown',
	modalPrefixValueChangePassword: 'changePassword',
	modalPrefixValueRecreateAssignment: 'recreateAssignment',
	modalPrefixValueInvite: 'invite',

	// Bulk actions applicable to single rows.
	modalPrefixValueSetDueDate: 'setDueDate',
	modalPrefixValueSetStatus: 'setStatus',
	modalPrefixValueSetAccessibility: 'setAccessibility',
	modalPrefixValueHandleCategories: 'handleCategories',
	modalPrefixValueHandleClient: 'handleClient',
	modalPrefixValueSetGeoFencing: 'setGeoFencing',
	modalPrefixValueSetPriority: 'setPriority',
	modalPrefixValueHandleAssigneeGroupsAndAssignees: 'handleAssigneeGroupsAndAssignees',
	modalPrefixValueHandleScheduleGroupsAndScheduleUsers: 'handleScheduleGroupsAndScheduleUsers',
	modalPrefixValueGetReport: 'getReport',


	// Bulk.
	modalPrefixValueBulk: 'bulk',
	modalPrefixValueBulkSetDueDate: 'bulkSetDueDate',
	modalPrefixValueBulkSetStatus: 'bulkSetStatus',
	modalPrefixValueBulkSetAccessibility: 'bulkSetAccessibility',
	modalPrefixValueBulkHandleCategories: 'bulkHandleCategories',
	modalPrefixValueBulkHandleClient: 'bulkHandleClient',
	modalPrefixValueBulkSetGeoFencing: 'bulkSetGeoFencing',
	modalPrefixValueBulkSetPriority: 'bulkSetPriority',
	modalPrefixValueBulkHandleAssigneeGroupsAndAssignees: 'bulkHandleAssigneeGroupsAndAssignees',
	modalPrefixValueBulkHandleScheduleGroupsAndScheduleUsers: 'bulkHandleScheduleGroupsAndScheduleUsers',
	modalPrefixValueBulkGenerateReport: 'bulkGenerateReport',
	modalPrefixValueBulkGetReport: 'bulkGetReport',

	searchParamString: 'search',

	sortParamString: 'sort',

	wikiCrudParams: {
		new: 'new',
		edit: 'edit',
		view: 'view',
	},

	clickUpActions: {
		postFeedback: 'post-feedback'
	},

	// Environment related objects.
	devEnv: {
		idServer: 'https://login-dev.inquinova.com',
		coreApi: 'https://core-development.azurewebsites.net/api/web/',
		inspectionApi: 'https://inspection-development.azurewebsites.net/api/web/',
		coreUrl: 'https://admin-dev.inquinova.com',
		storageUrl: 'https://europe-west1-inspection-development-38d73.cloudfunctions.net',
	},

	localhostEnv: {
		// This will be changed when the IdentityServer.Api has been separated
		// (client and backend), to run locally as the other projects below,
		// but for now it will run against the Azure dev service.
		idServer: 'http://localhost:5001',
		localIdServer: 'http://localhost:5001',
		coreApi: 'http://localhost:5002/api/web/',
		inspectionApi: 'http://localhost:5003/api/web/',
		inspectionNightlyApi: 'https://inspection-nightly.azurewebsites.net/api/web/',
		coreUrl: 'http://localhost:4200',
		storageUrl: 'https://europe-west1-inspection-development-38d73.cloudfunctions.net',
	},

	testEnv: {
		idServer: 'test',
		coreApi: 'test',
		inspectionApi: 'test',
		coreUrl: 'http://localhost:4200',
		storageUrl: 'test',
		blobstorageUrl: 'test',
	},

	stageEnv: {
		idServer: 'https://login-stage.inquinova.com',
		coreApi: 'https://core-stage.azurewebsites.net/api/web/',
		inspectionApi: 'https://inspection-stage.azurewebsites.net/api/web/',
		coreUrl: 'https://admin-stage.inquinova.com',
		storageUrl: 'https://europe-west1-inspection-development-38d73.cloudfunctions.net',
	},

	prodEnv: {
		idServer: 'https://id.inquinova.com',
		coreApi: 'https://core-production.azurewebsites.net/api/web/',
		inspectionApi: 'https://inspection-production.azurewebsites.net/api/web/',
		coreUrl: 'https://admin.inquinova.com',
		storageUrl: 'https://europe-west1-inspection-production.cloudfunctions.net',
	}
};
