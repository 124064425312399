<div class="notification-messages-actions-menu" #notificationMessagesPop="bs-popover"
	placement="bottom"
	container="body"
	containerClass="notification-messages-menu-popover"
	[outsideClick]="true"
	[popover]="!fetching ? notificationMessagesTemplate : null"
	[ngClass]="{
		'opened': popoverOpened
	}"
	[title]="'Messages' | translate"
	(onShown)="popoverOpened = true"
	(onHidden)="popoverOpened = false">
		<i class="bi bi-bell" *ngIf="!fetching"
			[ngClass]="
			{
				'animated': hasUnreadNotificationMessages()
			}"></i>
		<i class="bi bi-arrow-repeat" *ngIf="fetching"></i>
	<ng-container *ngIf="hasUnreadNotificationMessages() && !fetching">
		<span class="dot" >
			<span class="dot-text">{{ getUnreadCount() }}</span>
		</span>
	</ng-container>
</div>
<ng-template #notificationMessagesTemplate>
	<div class="notification-messages-menu-item" *ngFor="let notificationMessage of notificationMessages; trackBy: trackByFn"
		[ngClass]="{
			'opened': notificationMessage.hasBeenOpened
		}"
		[title]="notificationMessage.title"
		(click)="openNotificationMessageModal(notificationMessage); notificationMessagesPop.hide();">
		<div class="notification-message-wrapper">
			<div class="icon-title-wrapper">
				<div class="notification-message-icon {{ notificationMessage.icon }} {{ notificationMessage.type }}">
				</div>
				<div class="notification-message-title">
					{{ notificationMessage.title }}
				</div>
			</div>
			<div class="date">
				{{ notificationMessage.startDate }}
			</div>
		</div>
	</div>
	<div class="notification-messages-menu-item dummy" *ngIf="!notificationMessages.length">
		<div class="notification-message-wrapper">
			<div class="icon-title-wrapper">
				<div class="notification-message-icon bi-info-circle">
				</div>
				<div class="notification-message-title">
					{{ 'NoMessages' | translate }}
				</div>
			</div>
		</div>
	</div>
</ng-template>