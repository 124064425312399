import { Injectable } from '@angular/core';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { TemplateType } from 'app-inspection/template-type/template-type';
import { BaseService } from '../shared-core/abstract-components/service/base.service';
import { HostedHttpClientService } from '../shared-core/hosted-httpclient.service';
import { PostImage } from '../shared-core/post-image';
import { SSENReportIntegration } from './my-organization/integrations/integration';
import { Address, Client, Email, Organization, Phone } from './organization';

@Injectable()
export class OrganizationService extends BaseService<Organization> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
		super(
			hostedHttpClient,
			RoutesUtils.organization
		);
		this.useCoreApi = true;
	}

	getByFriendlyUrl(friendlyUrl: string) {
		return this.hostedHttpClient.get(`${this.prefix}/byname/${friendlyUrl}`, {}, true)
			.then(res => this.instantiateModel(res.data));
	}

	updateLogotype(orgId: number, img: PostImage) {
		const formData = new FormData();
		formData.append('file', img.blob, img.name);
		return this.hostedHttpClient.putWithHeaders(`${this.prefix}/${orgId}/logotype`, formData, { ContentType: 'upload-file' }, true);
	}

	getAffectedData(orgId: number) {
		return this.hostedHttpClient.get(`${this.prefix}/${orgId}/dataAffected`, {}, true);
	}

	deleteWithParam(orgId: number, moveUsers: boolean) {
		return this.hostedHttpClient.deleteWithParams(`${this.prefix}/${orgId}`, {
			moveAll: JSON.stringify(moveUsers)
		}, true);
	}

	getTemplateTypes(orgId: number): Promise<TemplateType[]> {
		return this.hostedHttpClient.get(`templateType/organization/${orgId}`, {})
			.then(res => res.data.map((item: TemplateType) => new TemplateType(item)));
	}

	updateTemplateTypes(orgId: number, integration: SSENReportIntegration) {
		const model = {
			organizationId: orgId,
			scope: integration.scope
		};
		return this.hostedHttpClient.put(`templateBaseType/${integration.templateBaseTypeId}/scope`, model);
	}

	updateEmail(item: Email) {
		return this.hostedHttpClient.put(`email/${item.id}`, item, true);
	}

	createPhone(item: Phone) {
		return this.hostedHttpClient.post(`phone`, item, true);
	}

	updatePhone(item: Phone) {
		return this.hostedHttpClient.put(`phone/${item.id}`, item, true);
	}

	deletePhone(id: number) {
		return this.hostedHttpClient.delete(`phone/${id}`, {}, true);
	}

	createAddress(item: Address) {
		return this.hostedHttpClient.post(`address`, item, true);
	}

	updateAddress(item: Address) {
		return this.hostedHttpClient.put(`address/${item.id}`, item, true);
	}

	deleteAddress(id: number) {
		return this.hostedHttpClient.delete(`address/${id}`, {}, true);
	}

	async getClientsInOrganization(): Promise<Client[]> {
		return this.hostedHttpClient.get('client', {}, true)
			.then(result => result.data.map((item: Client) => new Client(item)));
	}

	async getClient(clientId: string) {
		return this.hostedHttpClient.get(`client/${clientId}`, {}, true)
			.then(res => new Client(res.data));
	}

	createClient(client: Client) {
		return this.hostedHttpClient.post('client', client, true);
	}

	updateClient(client: Client) {
		return this.hostedHttpClient.put('client', client, true);
	}

	deleteClientsWithAffectedData(clientId: string, orgId: number) {
		const model = {
			itemIds: [
				clientId
			],
			organizationId: orgId
		};
		return this.hostedHttpClient.delete(`client/affectedData`, model, false);
	}

	instantiateModel(item: Organization) {
		return new Organization(item);
	}
}
