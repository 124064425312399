import { CrudItem } from 'app-core/shared-core/simple-components/crud/crud-item';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { Choice } from '../choice/choice';
import { Task } from '../task/task';

export class TemplateType extends CrudItem<TemplateType> {
	name: string = '';
	abbreviation: string = '';
	templateBaseTypeId: string = '';
	templateBaseType: TemplateBaseType = null;
	scope: string = '';
	assignmentDescription: string = '';
	taskChoices: TaskChoice[] = [];
	notificationChoice: Choice;
	notificationChoiceId: string;

	tasks: Task[] = [];
	selectableTemplateBaseTypes: TemplateBaseType[] = [];
	isManualMeasure: boolean = false;
	allowPriority: boolean = false;

	updateAssignmentTemplates: {
		assignmentTemplateId: string,
		assignToEntities: boolean,
		assignToFacility: boolean,
		newEntityIds: string[]
	}[] = [];

	constructor(templateType: Partial<TemplateType>) {
		super();
		Object.assign(this, templateType);
		this.mapData();
		this.setUrl(RoutesUtils.templateTypes, RoutesUtils.templateType);
	}

	private mapData() {
		this.mapTemplateBaseType();
		this.mapTaskChoices();
		this.mapNotificationChoice();
	}

	private mapTemplateBaseType() {
		if (this.templateBaseType) {
			this.templateBaseType = new TemplateBaseType(this.templateBaseType);
			this.templateBaseTypeId = this.templateBaseType.id;
		}
	}

	private mapTaskChoices() {
		this.tasks = this.taskChoices.map(taskChoice => {
			const task = new Task(taskChoice.task);
			task.choices = task.choices.map(choice => new Choice(choice));
			task.choices.sortByProperty(new Choice({}).propertyStrings.value);
			return task;
		});
		this.tasks.sortByProperty(new Task({}).propertyStrings.text);
	}

	private mapNotificationChoice() {
		if (this.notificationChoice) {
			this.notificationChoice = new Choice(this.notificationChoice);
			this.notificationChoiceId = this.notificationChoice.id;
		}
	}

	toPayloadObject(orgId: number) {
		return new TemplateTypePayload({
			id: this.id,
			organizationId: orgId,
			name: this.name,
			abbreviation: this.abbreviation,
			templateBaseTypeId: this.templateBaseTypeId,
			taskChoices: this.convertToTaskChoices(this.tasks),
			notificationChoiceId: this.notificationChoice ? this.notificationChoice.id : this.notificationChoiceId ? this.notificationChoiceId : null,
			updateAssignmentTemplates: this.updateAssignmentTemplates ? this.updateAssignmentTemplates : null
		});
	}

	private convertToTaskChoices(tasks: Task[]) {
		return tasks.map(task => {
			return new TaskChoice({
				taskId: task.id,
				choiceIds: task.choices.map(choice => choice.id)
			});
		});
	}
}

export class TemplateTypePayload extends TemplateType {
	organizationId: number;

	constructor(templateTypePayload: Partial<TemplateTypePayload>) {
		super(templateTypePayload);
		Object.assign(this, templateTypePayload);
	}
}

export class TaskChoice {
	task: Task = null;
	choices: Choice[] = [];
	taskId: string = '';
	choiceIds: string[] = [];

	constructor(taskChoice: Partial<TaskChoice>) {
		Object.assign(this, taskChoice);
	}
}

export class TemplateBaseType {
	id: string = '';
	name: string = '';

	constructor(templateBaseType: Partial<TemplateBaseType>) {
		Object.assign(this, templateBaseType);
	}
}
