export enum TimeSpan {
	ThisDay = 'ThisDay',
	ThisWeek = 'ThisWeek',
	ThisMonth = 'ThisMonth',
	ThisYear = 'ThisYear'
}

export enum AssignmentState {
	ExpiringAssignments = 'ExpiringAssignments',
	CompletedAssignments = 'CompletedAssignments',
	ExpiredAssignments = 'ExpiredAssignments',
	CreatedMeasures = 'CreatedMeasures',
	ResolvedMeasures = 'ResolvedMeasures',
	ClosedMeasures = 'ClosedMeasures',
}
