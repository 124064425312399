import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { SimpleTableConfig } from '../simple-table-config';
import { SimpleTableHeaderAction } from './simple-table-header-action';

@Component({
	selector: 'simple-table-head',
	templateUrl: './simple-table-head.component.html',
	styleUrls: ['./simple-table-head.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTableHeadComponent<T extends { id: string }> {

	headerActionsOpened: boolean;
	hovered: boolean;

	@Input() isInModal: boolean;
	@Input() isCompactView: boolean;
	@Input() config: SimpleTableConfig<T>;
	@Input() allSelected: boolean;
	@Input() someSelected: boolean;
	@Input() hasSelectableData: boolean;

	@Output() onActionClick = new EventEmitter<SimpleTableHeaderAction>();
	@Output() onCheckboxClick = new EventEmitter();

	@ViewChild('headerActionsPop') popover: PopoverDirective;

	closePopover() {
		this.popover?.hide();
	}

	actionClick(action: SimpleTableHeaderAction) {
		if (this.someSelected) {
			this.onActionClick.emit(action);
		}
	}

	checkboxClick() {
		if (this.hasSelectableData) {
			this.onCheckboxClick.emit();
		}
	}

	trackByFn(index: number) {
		return index;
	}
}
