import { Component, Input } from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { Organization } from 'app-core/organization/organization';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { LoggedInUser } from 'app-core/user/user';
import * as domToimage from 'dom-to-image';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ToastrService } from 'ngx-toastr';
import { FeedbackService } from './feedback.service';

@Component({
	selector: 'feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.less'],
	providers: [FeedbackService]
})
export class FeedbackComponent {
	maxLength: number = 2000;

	popoverOpened: boolean = false;
	loggedInUser: LoggedInUser;
	loading: boolean = false;
	screenshot: boolean = false;
	sending: boolean = false;
	wasSent: boolean = false;
	wasNotSent: boolean = false;
	selectedOrganization: Organization;
	feedback: FeedbackObject = new FeedbackObject({});

	@Input() isInModal: boolean;

	constructor(
		private authService: AuthService,
		private toastrService: ToastrService,
		protected translationService: TranslationService,
		protected feedbackService: FeedbackService) {
		this.loggedInUser = this.authService.loggedInUser;
		this.selectedOrganization = this.authService.selectedOrganization;
	}

	async getScreenShot() {
		return await domToimage.toPng(document.body);
	}

	async submit(helpPop: PopoverDirective) {
		if (this.feedback.description.length && this.feedback.description.length <= this.maxLength) {
			this.loading = true;
			setTimeout(async () => {
				try {
					helpPop.hide();
					this.loading = false;
					this.popoverOpened = false;
					setTimeout(async () => {
						this.screenshot = true;
						const screenshot = await this.getScreenShot();
						if (screenshot) {
							this.feedback.screenshot = screenshot;
						}
						this.screenshot = false;
						this.sending = true;
						setTimeout(async () => {
							const response = await this.feedbackService.createClickUpSpec(this.feedback, this.loggedInUser, this.selectedOrganization);
							this.sending = false;
							if (response.ok) {
								this.wasSent = true;
								this.feedback = new FeedbackObject({});
								this.toastrService.success(this.translationService.instant('YourMessageHasBeenSent'));
								setTimeout(() => {
									this.wasSent = false;
								}, 2000);
							} else {
								this.wasNotSent = true;
								this.toastrService.error(this.translationService.instant('SendMessageError'));
								setTimeout(() => {
									this.wasNotSent = false;
								}, 2000);
							}
						}, 500);
					}, 0);

				} catch (e) {
					this.loading = false;
					this.sending = false;
					this.wasNotSent = true;
					this.toastrService.error(this.translationService.instant('ErrorRequestFailed'));
					console.error('Error while creating screenshot or sending feedback: ', e);
					setTimeout(() => {
						this.wasNotSent = false;
					}, 2000);
				}
			}, 500);
		}
	}

	focusTextarea() {
		const textarea = document.querySelector('.feedback-textarea') as HTMLElement;
		if (textarea) {
			textarea.focus();
		}
	}
}

export class FeedbackObject {
	description: string = '';
	screenshot: string = '';

	constructor(feedbackObject: Partial<FeedbackObject>) {
		Object.assign(this, feedbackObject);
	}
}
