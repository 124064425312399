<simple-crud-modal
	[titleText]="'Assessment' | translate"
	[closeText]="'Close' | translate"
	(onClose)="close()">
	<tabset>
		<tab [customClass]="'valid'"
			(selectTab)="setTabIndexInUrl(1)">
			<ng-template tabHeading>
				<simple-tab-heading
					[icon]="'bi bi-card-text'"
					[title]="'Details' | translate"
					[helpText]="'ResultAssessmentViewDetailsHelpText' | translate">
				</simple-tab-heading>
			</ng-template>
			<result-component
				[errorResult]="errorResult"
				[resolvementResult]="resolvementResult">
			</result-component>
		</tab>
	</tabset>
</simple-crud-modal>