<div class="view-objects apx-scrollbar"
	[attr.data-id]="rowId"
	[attr.data-type]="type"
	(wheel)="$event.stopPropagation();">
	<div class="section-wrapper" *ngIf="groups.length">
		<div class="section-header"> {{ 'Groups' | translate }} </div>
		<div class="vo-menu-item" *ngFor="let group of groups"
			[ngClass]="{
				'disabled': !canEdit
			}"
			(click)="canEdit ? navigateTo(group.url) : null">
			<div class="image-details-wrapper">
				<div class="details">
					<div class="name" title="{{ group.name }}" *ngIf="group.name">
						{{ group.name }}
					</div>
					<div class="description-wrapper"
						title="{{ group.users.length }} {{'Assignees' | translate}}">
						<div class="description">
							{{ group.users.length }} {{"Assignees" | translate}}
						</div>
						<view-members
							[members]="group.users"
							[shouldStopPropagation]="true">
						</view-members>
					</div>
				</div>
				<div class="rules" *ngFor="let groupRule of groupRules">
					<simple-rules *ngIf="groupRule.id === group.id"
						[rules]="groupRule.rules"
						[editable]="false">
					</simple-rules>
				</div>
			</div>
		</div>
	</div>

	<div class="section-wrapper" *ngIf="users.length">
		<div class="section-header"> {{ 'Assignees' | translate }} </div>
		<div class="vo-menu-item" *ngFor="let user of users"
			(click)="navigateTo(user.url, user)">
			<div class="image-details-wrapper">
				<div class="item-image" #parentDiv>
					<extensive-image
						[imageUrl]="user.profileImageUrl"
						[imageStorageSize]="parentDiv | getImageStorageSize"
						[rounded]="true"
						[placeholderImageType]="'user'">
					</extensive-image>
				</div>
				<div class="details">
					<div class="name" title="{{ user.fullName }}" *ngIf="user.fullName">
						{{ user.fullName }}
					</div>
					<div class="description-wrapper" *ngIf="user.email"
						[ngClass]="{
							'only': !user.fullName
						}"
						title="{{ user.email }}" >
						<div class="description">
							{{ user.email }}
						</div>
					</div>
				</div>
				<div class="rules" *ngFor="let assigneeRule of assigneeRules">
					<simple-rules *ngIf="assigneeRule.id === user.id"
						[rules]="assigneeRule.rules"
						[editable]="false">
					</simple-rules>
				</div>
			</div>
		</div>
	</div>

	<div class="section-wrapper" *ngIf="assignmentTemplates.length">
		<div class="vo-menu-item" *ngFor="let assignmentTemplate of assignmentTemplates"
			[ngClass]="{
				'disabled': !canEdit
			}"
			(click)="canEdit ? navigateTo(assignmentTemplate.url) : null">
			<div class="image-details-wrapper">
				<div class="details">
					<div class="name" title="{{ assignmentTemplate.name }}">
						{{ assignmentTemplate.name }}
					</div>
					<div class="description-wrapper">
						<div class="description"
							title="{{ assignmentTemplate.facilityName }}" >
								{{ assignmentTemplate.facilityName }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="section-wrapper" *ngIf="schedules.length">
		<div class="vo-menu-item" *ngFor="let schedule of schedules"
			[ngClass]="{
				'disabled': !canEdit
			}"
			(click)="canEdit ? navigateTo(schedule.url) : null">
			<div class="image-details-wrapper">
				<div class="details">
					<div class="name" title="{{ schedule.name }}">
						{{ schedule.name }}
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="section-wrapper" *ngIf="entities.length">
		<div class="vo-menu-item" *ngFor="let entity of entities"
			[ngClass]="{
				'disabled': !canEdit
			}"
			(click)="canEdit ? navigateTo(entity.url) : null">
			<div class="image-details-wrapper">
				<div class="details">
					<div class="name" title="{{ entity.name }}">
						{{ entity.name }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>