<div class="markdown-editor-wrapper">
	<div class="markdown-editor"
		[ngClass]="{
			'focused': isFocused,
			'has-error': errorText
		}">
		<markdown-toolbar for="textarea"
			[ngClass]="{
				'disabled': previewIsActive
			}">
			<ng-container *ngIf="!toolbarAsPopover">
				<ng-container *ngTemplateOutlet="toolbar">
				</ng-container>
			</ng-container>

			<ng-container *ngIf="toolbarAsPopover">
				<button class="btn u-uppercase tools-button" #actionsPop="bs-popover"
					[popover]="popTemplate"
					placement="bottom"
					[outsideClick]="true"
					[ngClass]="{
						'opened': actionsOpened
					}"
					(onShown)="actionsOpened = true"
					(onHidden)="actionsOpened = false"
					title="{{ 'Tools' | translate }}">
					<span class="bi bi-tools"></span>
					<div class="tools-text">
						{{ 'Tools' | translate }}
					</div>
				</button>
				<ng-template #popTemplate>
					<ng-container
						[ngTemplateOutlet]="toolbar"
						[ngTemplateOutletContext]="{actionsPop:actionsPop}">
					</ng-container>
				</ng-template>
			</ng-container>

			<div class="md-button preview-button"
				[ngClass]="{
					'force-enable': previewIsActive && previewButtonIsClicked
				}">
				<button data-md-button class="btn u-uppercase"
					title="{{ 'Preview' | translate }}"
					(click)="handlePreviewClick()">
					<div class="preview-text">
						{{ 'Preview' | translate }}
					</div>
					<span class="bi" [ngClass]="{ 'bi-eye': !previewIsActive, 'bi-eye-fill': previewIsActive }"></span>
				</button>
			</div>
		</markdown-toolbar>
		<ng-container *ngIf="!previewIsActive">
			<textarea #textarea
				id="textarea"
				class="text-editor apx-scrollbar"
				[ngModel]="currentTextareaValue"
				(ngModelChange)="emitChanges($event)"
				(keypress)="handleKeyPress($event)"
				(keydown.backspace)="handleKeyPress($event)"
				(keydown)="handleKeyDownEvent($event)">
			</textarea>
		</ng-container>
		<ng-container *ngIf="previewIsActive">
			<div class="preview-wrapper">
				<div class="preview apx-scrollbar"
					[ngClass]="{
						'with-opacity': !previewButtonIsClicked
					}"
					[innerHtml]="currentTextareaValue | mdToHtml | sanitizeHtml">
				</div>
			</div>
		</ng-container>
		<div class="preview-mask" *ngIf="previewIsActive && !previewButtonIsClicked"
			(click)="focus()">
			{{ 'ClickToEdit' | translate }}
		</div>
	</div>
	<div class="error-text" *ngIf="errorText">
		{{ errorText }} *
	</div>
</div>

<ng-template #toolbar let-actionsPop="actionsPop">
	<md-bold class="md-button" (click)="actionsPop?.hide()">
		<button #button id="b" class="btn" title="{{ 'Bold' | translate}} (Ctrl+B)">
			<i class="bi bi-type-bold"></i>
		</button>
	</md-bold>
	<md-italic class="md-button" (click)="actionsPop?.hide()">
		<button #button id="i" class="btn" title="{{ 'Italic' | translate}} (Ctrl+I)">
			<span class="bi bi-type-italic"></span>
		</button>
	</md-italic>
	<md-strikethrough class="md-button" (click)="actionsPop?.hide()">
		<button #button id="d" class="btn" title="{{ 'Strikethrough' | translate}} (Ctrl+D)">
			<span class="bi bi-type-strikethrough"></span>
		</button>
	</md-strikethrough>
	<md-header class="md-button" (click)="actionsPop?.hide()">
		<button #button class="btn" title="{{ 'Heading' | translate}}">
			<span class="bi bi-type-h3"></span>
		</button>
	</md-header>
	<md-link class="md-button" (click)="actionsPop?.hide()">
		<button #button id="k" class="btn" title="{{ 'Link' | translate}} (Ctrl+K)">
			<span class="bi bi-link"></span>
		</button>
	</md-link>
	<div class="md-button" (click)="actionsPop?.hide(); insertTable()">
		<button data-md-button class="btn"
			title="{{ 'Table' | translate }}">
			<span class="bi bi-table"></span>
		</button>
	</div>
	<md-unordered-list class="md-button" (click)="actionsPop?.hide(); listOngoing('ul')">
		<button #button class="btn"
			title="{{ 'UnorderedList' | translate}}">
			<span class="bi bi-list-ul"></span>
		</button>
	</md-unordered-list>
	<md-ordered-list class="md-button" (click)="actionsPop?.hide(); listOngoing('ol')">
		<button #button class="btn"
			title="{{ 'OrderedList' | translate}}">
			<span class="bi bi-list-ol"></span>
		</button>
	</md-ordered-list>
</ng-template>
