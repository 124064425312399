import { Entity } from 'app-inspection/entity/entity';
import { Facility } from 'app-inspection/facility/facility';

export enum TemplateTypeFacilityEntitySettings {
	FACILITY = 'Facility',
	ENTITIES = 'Entities',
	BOTH = 'Both',
	FACILITY_ENTITY = 'FacilityEntity',
	DESCRIPTION = 'Description'
}

export enum AdditionalInfoActionName {
	ADD = 'Add',
	DELETE = 'Delete',
	UPDATE = 'Update'
}

export class RelatedDataItem {
	id: string;
	name: string;
	url: string;
	email: string;
	firstName: string;
	lastName: string;
	facility: Facility;
	entities: Entity[];
	facilityEntitiesSettings: any;
	facilityOrEntities: string;
	shouldShowMultiSelect: boolean = false;
	multiSelectSettings: any;
	multiSelectValues: string[];
	selectionHasBeenAltered: boolean = false;
	errorMessage: string = null;
	additionalInfoActionName: AdditionalInfoActionName;
	rowShouldHaveCheckbox: boolean = false;
	duplicateExisted: boolean = false;
	validatedOK: boolean = false;
	templateFacilityMissingErrorChoice: boolean = false;
	templateEntityMissingErrorChoice: boolean = false;
	templateFacilityMissingDefaultRequiredTask: boolean = false;
	templateEntityMissingDefaultRequiredTask: boolean = false;
	templateFacilityHasOnlyErrorChoice: boolean = false;
	templateEntityHasOnlyErrorChoice: boolean = false;
	clientId: string;
	isMeasure: boolean = false;
	hideLink: boolean = false;

	constructor(object: Partial<RelatedDataItem>) {
		Object.assign(this, object);
	}
}

export class AdditionalInfo {
	reason: string;
	title: string;
	description: string;
	relatedData: RelatedDataItem[];
	shouldHaveCheckboxes: boolean;
	hideSelectAll: boolean = false;

	constructor(object: Partial<AdditionalInfo>) {
		Object.assign(this, object);
	}
}

export class AdditionalInfoModel {
	data: Object[];
	validationHelperTranslation: string;
	facilityOrEntities?: string;
	entities?: Entity[];

	constructor(object: Partial<AdditionalInfoModel>) {
		Object.assign(this, object);
	}
}

export class TemplateTypeHasTemplatesModel {
	facilityId: string;
	entities: Entity[] = [];
	shouldShowMultiSelect: boolean = false;

	constructor(object: Partial<TemplateTypeHasTemplatesModel>) {
		Object.assign(this, object);
	}
}

export class TemplateTypeFacilityEntitySettingsObject {
	id: string;
	text: string;

	constructor(object: Partial<TemplateTypeFacilityEntitySettingsObject>) {
		Object.assign(this, object);
	}
}

export enum AdditionalInfoReason {
	FacilityHasTemplates = 'FacilityHasTemplates',
	AssignmentTemplateHasActiveAssignment = 'AssignmentTemplateHasActiveAssignment',
	TemplateHasActiveAssignment = 'TemplateHasActiveAssignment',
	ScheduleHasBeenPaused = 'ScheduleHasBeenPaused',
	TemplateTypeHasNoTaskChoices = 'TemplateTypeHasNoTaskChoices',
	ChoiceHasTemplates = 'ChoiceHasTemplates',
	TaskHasTemplates = 'TaskHasTemplates',
	ScheduleHasInactiveAssignmentTemplates = 'ScheduleHasInactiveAssignmentTemplates',
	ScheduleHasInactiveUsers = 'ScheduleHasInactiveUsers',
	TaskChoiceHasActiveAssignment = 'TaskChoiceHasActiveAssignment',
	TaskChoiceHasActiveMeasures = 'TaskChoiceHasActiveMeasures',
	TemplateTypeHasTemplates = 'TemplateTypeHasTemplates',
	UserGroupHasActiveAssignment = 'UserGroupHasActiveAssignment',
	ActiveAssignmentHasNoAssigneesOrGroups = 'ActiveAssignmentHasNoAssigneesOrGroups',
	ScheduleIsEmpty = 'ScheduleIsEmpty',
	ScheduleHasEmptyScheduleGroups = 'ScheduleHasEmptyScheduleGroups',
	ScheduleHasNoValidFlags = 'ScheduleHasNoValidFlags',
	TemplateMissingDefaultRequiredTask = 'TemplateMissingDefaultRequiredTask',
	TemplateMissingErrorChoice = 'TemplateMissingErrorChoice',
	TemplateHasOnlyErrorChoice = 'TemplateHasOnlyErrorChoice',
	TemplateTypeHasTaskWithMoreThanOneStandardChoice = 'TemplateTypeHasTaskWithMoreThanOneStandardChoice',
	TemplateTypeTaskHasOnlyUnspecifiedChoices = 'TemplateTypeTaskHasOnlyUnspecifiedChoices',
	ClientHasActiveAssignment = 'ClientHasActiveAssignment',
	ClientHasAssignmentTemplate = 'ClientHasAssignmentTemplate',
	TemplateTypeManualMeasureHasNoErrorChoice = 'TemplateTypeManualMeasureHasNoErrorChoice',
	TemplateTypeHasDefaultTaskWithNoDefaultChoice = 'TemplateTypeHasDefaultTaskWithNoDefaultChoice',

	AssignmentHasResultsWithMedia = 'AssignmentHasResultsWithMedia',
	AssignmentHasResultsWithComments = 'AssignmentHasResultsWithComments',
	MeasureHasPriorityWithoutPriorityChoice = 'MeasureHasPriorityWithoutPriorityChoice',
	ScheduledResultPriorityRemoved = 'ScheduledResultPriorityRemoved'
}
