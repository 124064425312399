import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Utils } from 'app-core/shared-core/tools/utils';

@Component({
	selector: 'simple-handle-tab',
	templateUrl: './simple-handle-tab.component.html',
	styleUrls: ['./simple-handle-tab.component.less']
})
export class SimpleHandleTabComponent {

	hovered: boolean;

	@Input() headerTitleText: string;
	@Input() headerSubtitleText: string;
	@Input() headerAddButtonText: string;
	@Input() allAreSelected: boolean;
	@Input() disableAddButton: boolean;
	@Input() disableRemoveSelected: boolean;
	@Input() disableSelectAll: boolean;
	@Input() disableSearch: boolean;
	@Input() hideAddButton: boolean;
	@Input() hideSearch: boolean;
	@Input() hideRemoveSelected: boolean;
	@Input() hideSelectAll: boolean;
	@Input() selectedCount: number = 0;
	@Input() searchValue: string;

	@Output() onAddButtonClick = new EventEmitter();
	@Output() onRemoveSelected = new EventEmitter();
	@Output() onSelectAll = new EventEmitter();
	@Output() onSearch = new EventEmitter<string>();

	handleAddButtonClick() {
		if (!this.disableAddButton) {
			this.onAddButtonClick.emit();
		}
	}

	handleRemoveSelectedClick() {
		if (!this.disableRemoveSelected) {
			this.onRemoveSelected.emit();
		}
	}

	handleSelectAllClick() {
		if (!this.disableSelectAll) {
			this.onSelectAll.emit();
		}
	}

	isMediumScreenSize() {
		return Utils.isMediumScreenSize();
	}

	handleSearch() {
		this.onSearch.emit(this.searchValue);
	}
}
