import { AfterViewInit, Component, Input } from '@angular/core';
import { SimpleFormControlDirective } from '../simple-form-control.directive';

@Component({
	selector: 'simple-form-input',
	templateUrl: './simple-form-input.component.html',
	styleUrls: ['./simple-form-input.component.less']
})
export class SimpleFormInputComponent extends SimpleFormControlDirective implements AfterViewInit {

	@Input() useTextarea: boolean;
	@Input() usePasswordType: boolean;

	ngAfterViewInit() {
		if (this.focusOnInit) {
			this.focusElement();
		}
	}
}
