import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/pipes.module';
import { SimpleComponentsModule } from '../simple-components/simple-components.module';
import { MarkdownViewerContentComponent } from './content/markdown-viewer-content.component';
import { MarkdownViewerComponent } from './markdown-viewer.component';
import { MarkdownViewerModalComponent } from './modal/markdown-viewer-modal.component';
import { MarkdownViewerTabComponent } from './modal/tabs/markdown-viewer-tab.component';

@NgModule({
	imports: [
		PipesModule,
		TranslateModule,
		SimpleComponentsModule
	],
	declarations: [
		MarkdownViewerComponent,
		MarkdownViewerTabComponent,
		MarkdownViewerContentComponent,
		MarkdownViewerModalComponent
	],
	exports: [
		MarkdownViewerComponent,
		MarkdownViewerTabComponent,
		MarkdownViewerContentComponent,
		MarkdownViewerModalComponent
	]
})
export class MarkdownViewerModule { }
