import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Schedule } from 'app-inspection/schedule/schedule';
import { ScheduleService } from 'app-inspection/schedule/schedule.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SetStatusTabComponent } from './tabs/set-status-tab.component';

@Component({
	templateUrl: './set-status-schedule.component.html'
})
export class SetStatusScheduleComponent extends SimpleBulkEditModalDirective<Schedule> implements OnInit {

	@ViewChild(SetStatusTabComponent) setScheduleStatusTabComponent: SetStatusTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private scheduleService: ScheduleService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.initialModel = new Schedule({});
		await this.getStatusData(this.initialModel.active);
	}

	private async getStatusData(value: boolean) {
		this.pending = true;
		try {
			const response = await this.scheduleService.getStatusData(this.editModelIds, value);
			this.affectableIds = response.data;
			this.pending = false;
		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	handleValueChanges() {
		if (this.setScheduleStatusTabComponent) {
			const statusCtrl = this.setScheduleStatusTabComponent.form.get(this.propertyStrings.active);
			this.getStatusData(statusCtrl.value);
		}
	}

	changeDetailsIsValid() {
		return this.setScheduleStatusTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.setScheduleStatusTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const ids = this.useAffectableIds ? this.affectableIds : this.editModelIds;
			const response = await this.scheduleService.updateStatus(ids, this.initialModel.active);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.setScheduleStatusTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Schedule) {
		return new Schedule(item);
	}

	hasUnsavedChanges() {
		return false;
	}
}
