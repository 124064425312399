// tslint:disable:quotemark
import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { AuthService } from '../../auth/auth.service';
import { HostedHttpClientService } from '../hosted-httpclient.service';
import { SimpleListDirective } from '../simple-components/list/simple-list.directive';
import { SwalConfig } from '../swal/swal-config.component';
import { FileData, FileHelper } from '../tools/file-helper';
import { FileUtils } from '../tools/file-utils';
import { ParseXLSXService } from '../tools/parse-xlsx.service';
import { StringUtils } from '../tools/string-utils';
import { TranslationService } from '../translation/translation.service';
import { ImportActionKey, ImportExportModelKey, ImportHelper, ImportJobStatusKey } from './import-helper';

export const ORG_QUERY_PARAM = '?organization=';

export const JOB_ENDPOINTS = {
	GET_JOB_STATUS: (type: string) => `backgroundJob/${type}`,
	DELETE_JOB: (id: string) => `backgroundJob/${id}`
};

@Injectable()
export class ImportService<T extends { id: string, url: string, selected: boolean }> {

	private checkStatusInterval = 1000;
	private progressToast: ActiveToast<T>;
	private successToast: ActiveToast<T>;
	private errorToast: ActiveToast<T>;

	constructor(
		private parseXLSXService: ParseXLSXService,
		private hostedHttpClient: HostedHttpClientService,
		private translationService: TranslationService,
		private importHelper: ImportHelper,
		private authService: AuthService,
		private toastrService: ToastrService,
		private fileHelper: FileHelper) { }

	import(file: File, modelName: ImportExportModelKey, actionName: ImportActionKey, listReference: SimpleListDirective<T>, coordinateSystem?: string, propertyToIgnore?: string) {

		if (!FileUtils.isExcel(file)) {
			this.toastrService.error(this.translationService.instant(StringUtils.BAD_FILE_FORMAT));
			return;
		}

		listReference.pending = true;
		this.parseXLSXService.parseFile(file)
			.then(workbookData => {

				// Build endpoint.
				const selectedOrg = this.authService.selectedOrganization;
				const endpoint = `${modelName}/import${ORG_QUERY_PARAM}${selectedOrg.id.toString()}`;

				// Generate data.
				const data = this.importHelper.generateData(workbookData, modelName, coordinateSystem, propertyToIgnore);

				if (!data.length) {
					listReference.pending = false;
					this.toastrService.error(this.translationService.instant(StringUtils.EMPTY_FILE_ERROR));
					return;
				}

				// Prepare payload.
				const stringifiedData = JSON.stringify(data).replace(/'/g, "\\'"); // Escape single quotes.

				// Perform request.
				this.hostedHttpClient.post(endpoint, "'" + stringifiedData + "'")
					.then(response => {
						listReference.pending = false;

						// Display message depending on size.
						// this.validationHelper.handleBackgroundJobResponse( // todo hantera fel
						// 	response,
						// 	this.translationService.instant('BackgroundJobStartedImported')
						// );

						// Start monitoring if a big import or just add to the table.
						if (response.data.backgroundJobStarted) {
							this.monitorProgress(modelName, actionName, listReference);
						} else {
							const newItems = response.data.created.map((item: T) => listReference.service.instantiateModel(item)) as T[];
							if (newItems.length) {
								listReference.handleAdded(newItems);
								this.toastrService.success(this.translationService.instant(StringUtils.IMPORT_SUCCEEDED));
							}
						}

						// Show export modal for failed rows.
						if (response.data.failed.length) {
							this.displayExportModal(new FileData(response.data.file));
						}
					})
					.catch(response => {
						listReference.pending = false;
						// this.validationHelper.handleErrors(response, {});

						// Show export modal for failed rows.
						if (response.error && response.error.data && response.error.data.failed.length) {
							this.displayExportModal(new FileData(response.error.data.file));
						}
					});
			})
			.catch(response => {
				listReference.pending = false;

				// Display errors based on type. ('parseFile()' and 'generateData()' returns error strings)
				if (typeof response === 'string') {
					this.toastrService.error(this.translationService.instant(response));
				} else {
					// this.validationHelper.handleErrors(response, {});
				}
			});
	}

	// Checks if any import job is in progress for a specified model.
	hasImportInProgress(modelName: ImportExportModelKey, actionName: ImportActionKey) {
		return this.hostedHttpClient.get(JOB_ENDPOINTS.GET_JOB_STATUS(modelName), { action: actionName })
			.then(response => {
				return !!response.data && !!response.data.length
					&& !!response.data.find((item: any) => {
						return item.status === ImportJobStatusKey.InProgress;
					});
			});
	}

	// Keeps track of import job statuses for a specified model.
	monitorProgress(modelName: ImportExportModelKey, actionName: ImportActionKey, listReference: SimpleListDirective<T>) {
		this.hostedHttpClient.get(JOB_ENDPOINTS.GET_JOB_STATUS(modelName), { action: actionName })
			.then(response => {
				if (response.data && response.data.length) {
					response.data.forEach((item: any) => {
						switch (item.status) {
							case ImportJobStatusKey.InProgress:
								this.handleInProgressStatus(modelName, actionName, listReference);
								break;
							case ImportJobStatusKey.Succeeded:
								this.handleSucceededStatus(item.id, listReference);
								break;
							case ImportJobStatusKey.Error:
								this.handleErrorStatus(item.id);
								break;
						}
					});
				}
			})
			.catch(response => {
				// this.validationHelper.handleErrors(response, {});
			});
	}

	private handleInProgressStatus(modelName: ImportExportModelKey, actionName: ImportActionKey, listReference: SimpleListDirective<T>) {
		this.displayInProgressMessage();
		setTimeout(() => {
			this.monitorProgress(modelName, actionName, listReference);
		}, this.checkStatusInterval);
	}

	private handleSucceededStatus(jobId: string, listReference: SimpleListDirective<T>) {
		this.removeInProgressMessage();
		this.displaySuccessMessage();
		this.deleteJob(jobId);
		listReference['getTableData']();
		this.successToast = undefined;
	}

	private handleErrorStatus(jobId: string) {
		this.removeInProgressMessage();
		this.displayErrorMessage();
		this.deleteJob(jobId);
		this.errorToast = undefined;
	}

	private removeInProgressMessage() {
		this.toastrService.remove(this.progressToast?.toastId);
		this.progressToast = undefined;
	}

	private displayInProgressMessage() {
		if (!this.progressToast) {
			this.progressToast = this.toastrService.info(
				this.translationService.instant(StringUtils.IMPORT_IN_PROGRESS),
				this.translationService.instant(StringUtils.IMPORTING),
				{
					timeOut: 86400,
					closeButton: false
				}
			);
		}
	}

	private displaySuccessMessage() {
		if (!this.successToast) {
			this.successToast = this.toastrService.success(
				this.translationService.instant(StringUtils.IMPORT_SUCCEEDED),
				this.translationService.instant(StringUtils.FINISHED)
			);
		}
	}

	private displayErrorMessage() {
		if (!this.errorToast) {
			this.errorToast = this.toastrService.error(
				this.translationService.instant(StringUtils.SOMETHING_WENT_WRONG),
				this.translationService.instant(StringUtils.ABORTED),
				{
					timeOut: 86400
				}
			);
		}
	}

	private deleteJob(jobId: string) {
		this.hostedHttpClient.delete(JOB_ENDPOINTS.DELETE_JOB(jobId), {})
			.catch(response => {
				// this.validationHelper.handleErrors(response, {});
			});
	}

	private displayExportModal(fileData: FileData) {
		Swal.fire(
			new SwalConfig(this.translationService).getInfo({
				title: this.translationService.instant('ImportQuestion'),
				text: StringUtils.SOME_ROWS_HAD_ERRORS,
				confirmButtonText: StringUtils.DOWNLOAD_ERROR_LIST,
				cancelButtonText: StringUtils.CLOSE,
			})
		).then(result => {
			if (result.value) {
				this.fileHelper.handleFile(fileData);
			}
		});
	}
}
