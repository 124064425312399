
<div class="sss-wrapper">
	<div class="icon bi-search"
		[ngClass]="{
			'active': !!localSearchValue || isFocused
		}">
	</div>
	<input type="search" class="form-control search-input" #searchInput
		[value]="localSearchValue"
		[title]="'Search' | translate"
		placeholder="{{ 'Search' | translate }}..."
		autocomplete="off"
		spellcheck="false"
		(focus)="isFocused = true"
		(blur)="isFocused = false"
	/>
</div>
