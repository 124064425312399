import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {

	constructor(
		private authService: AuthService) { }

	/**
	 * Intercept all XHR request
	 * @param request
	 * @param next
	 * @returns {Observable<A>}
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.method !== 'JSONP') {

			// This makes sure we do not cache in the browser.
			const modifiedRequest = request.clone({
				headers: request.headers
					.set('Cache-Control', 'no-cache, no-store, must-revalidate')
					.set('Pragma', 'no-cache')
					.set('Expires', '0')
			});
			return next.handle(modifiedRequest)

				// Auth error handling
				.pipe(
					catchError(
						(error, _) => {
							// Intercept the response error and displace it to the console
							if (error.status === 401) {
								this.authService.startLogout();
							}
							throw error;
						}
					)
				);
		}
	}
}
