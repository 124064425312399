import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCrudDirective } from 'app-core/shared-core/simple-components/crud/simple-crud.directive';
import { SwalConfig } from 'app-core/shared-core/swal/swal-config.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { OrganizationMembership } from 'app-core/user/user';
import { UserService } from 'app-core/user/user.service';
import { AssignmentService } from 'app-inspection/assignment/assignment.service';
import { TemplateType } from 'app-inspection/template-type/template-type';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CreateEditOrganizationComponent } from '../create-edit-organization/create-edit-organization.component';
import { Client, Organization } from '../organization';
import { OrganizationService } from '../organization.service';
import { CreateEditClientComponent } from './clients/create-edit-client.component';
import { CreateEditDfResponsComponent } from './integrations/external/df-respons/create-edit-df-respons.component';
import { CreateEditInfraControlComponent } from './integrations/external/infra-control/create-edit-infra-control.component';
import { ExternalIntegration, Integration, IntegrationTypes, SSENReportIntegration, SSENReportIntegrationTypes } from './integrations/integration';
import { IntegrationService } from './integrations/integration.service';
import { EditSSENReportComponent } from './integrations/report/edit-ssen-report.component';
import { LogotypeImageComponent } from './logotype-image/logotype-image.component';
import { CreateEditTemplateTypeDescriptionComponent } from './template-types/create-edit-template-type-description.component';

@Component({
	templateUrl: './my-organization.component.html',
	styleUrls: ['../../landing-page-shared-style.less']
})
export class MyOrganizationComponent extends SimpleCrudDirective<Organization> implements OnInit {

	bsModalRef: BsModalRef<any>;

	fetchingCultureSettings: boolean = false;
	fetchingClients: boolean = false;
	fetchingTemplateTypes: boolean = false;
	fetchingIntegrations: boolean = false;

	clients: Client[] = [];
	templateTypes: TemplateType[] = [];
	integrations: Integration[] = [];
	ssenIntegration: SSENReportIntegration;
	ssenReportIntegrationTypes = SSENReportIntegrationTypes;
	externalIntegrations: ExternalIntegration[] = [];

	hasSSEN: boolean = false;

	actionsOpened: boolean = false;

	clientSearchValue: string;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private organizationService: OrganizationService,
		private assignmentService: AssignmentService,
		private userService: UserService,
		private integrationService: IntegrationService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.fetchingCultureSettings = true;
		this.fetchingClients = true;
		this.fetchingTemplateTypes = true;
		this.fetchingIntegrations = true;
		try {
			if (!this.userService.cultureSettings) {
				this.userService.cultureSettings = await this.userService.getCultureSettings();
			}

			if (this.loggedInUser.isSuperAdmin() && this.selectedOrganization.parentId) {
				this.selectedOrganization.parentOrganization = await this.organizationService.get(this.selectedOrganization.parentId);
			}

			this.fetchingCultureSettings = false;
			this.assignCultureSettings();

			this.refreshClients();
			this.refreshTemplateTypes();
			this.refreshIntegrations();

		} catch (errorResponse) {
			this.fetchingCultureSettings = false;
			this.fetchingClients = false;
			this.fetchingTemplateTypes = false;
			this.fetchingIntegrations = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	private assignCultureSettings() {
		this.selectedOrganization.selectableCountryCodes = this.userService.cultureSettings.countryCodes;
		this.selectedOrganization.selectableCountries = this.userService.cultureSettings.countries;
		this.selectedOrganization.selectableCultures = this.userService.cultureSettings.cultures;
	}

	async deleteOrganization() {
		if (this.loggedInUser.isSuperAdmin()) {
			const fetchedData = await this.organizationService.getAffectedData(this.selectedOrganization.id);
			const swal = await Swal.fire(new SwalConfig(this.translationService).getDelete({
				html: this.getDeleteText(fetchedData),
				input: 'checkbox',
				inputValue: '1',
				inputPlaceholder: this.translationService.instant(StringUtils.YES_KEEP_USERS),
				cancelButtonText: this.translationService.instant('Cancel'),
				confirmButtonText: `${this.translationService.instant('DeleteParam', { param: this.selectedOrganization.orgName })}`,
				customClass: {
					popup: 'wider-swal',
				}
			}));

			if (swal && swal.isConfirmed) {
				const lastChanceSwal = await Swal.fire(new SwalConfig(this.translationService).getAreYouSure({}));

				console.log('lastChanceSwal:', lastChanceSwal)

				if (lastChanceSwal.isConfirmed) {
					try {
						const response = await this.organizationService.deleteWithParam(this.selectedOrganization.id, swal.value === 1 ? true : false);
						this.displaySuccessMessage(response.successMessage);

						if (this.loggedInUser.organization) {
							this.authService.setSelectedOrganization(this.loggedInUser.organization);
							this.router.navigateByUrl(`/${this.loggedInUser.organization.friendlyUrl}/${RoutesUtils.organization}`, { replaceUrl: true });
						} else {
							this.authService.startLogout();
						}
					} catch (errorResponse) {
						this.handleErrorResponse(errorResponse);
					}
				}
			}
		}
	}

	getDeleteText(fetchedData: any) {
		let text = this.translationService.instant('DeleteOrganizationStatement');

		const orgsToBeRemoved = fetchedData.data.subOrganizationsToBeRemoved;
		const allUsers = fetchedData.data.allUsers;
		const usersToBeRemoved = fetchedData.data.usersToBeRemoved;
		const usersToBeMoved = fetchedData.data.usersToBeMoved;
		const membership = new OrganizationMembership(this.loggedInUser.organizationMemberships
			.find(o => o.status === StringUtils.ACTIVE));

		if (orgsToBeRemoved !== 0) {
			text += '<br><br><i>' + this.translationService.instant(StringUtils.SUB_ORGS_WILL_BE_REMOVED).formatBold(orgsToBeRemoved) + '</i>';
		}

		text += '<br><br><i>' + this.translationService.instant(StringUtils.MAIN_ORG_WILL_CHANGE_OTHER).formatBold(allUsers) + '</i>';

		if (this.currentUserIncluded()) {
			text += this.translationService.instant(StringUtils.INCLUDING_YOURSELF);
		}
		if (usersToBeMoved > 0) {
			text += '<br><i>' + this.translationService.instant(StringUtils.USERS_IN_OTHER_ORGS_REMAIN).formatBold(usersToBeMoved) + '</i>';
		}
		if (usersToBeRemoved > 0) {
			text += '<br><i>' + this.translationService.instant(StringUtils.USERS_TO_BE_REMOVED).formatBold(usersToBeRemoved) + '</i>';
		}


		if (!this.shouldMoveCurrentUser()) {
			text += '<br><br>' + this.translationService.instant(StringUtils.NOT_YOUR_MAIN_ORG).formatBold(new Organization(this.loggedInUser.organization).orgName);
		} else if (this.canMoveCurrentUser() && membership) {
			const membershipRole = (membership.roles.find(o => this.loggedInUser.roles.some(p => p === o))
				?? membership.roles[0]).toLowerCase();

			text += '<br><br>' + this.translationService.instant(StringUtils.MAIN_ORG_WILL_CHANGE).formatBold(membershipRole, new Organization(membership?.organization).name);
		} else {
			text += '<br><br><strong class="strong danger">' + this.translationService.instant(StringUtils.NO_OTHER_MAIN_ORG, '.') + '</strong>';
		}

		return text;
	}

	private currentUserIncluded(): boolean {
		return this.loggedInUser.organization.id === this.selectedOrganization.id
			|| this.loggedInUser.organizationMemberships.some(om => om.organization.id === this.selectedOrganization.id);
	}

	private shouldMoveCurrentUser(): boolean {
		return this.loggedInUser.organization.id === this.selectedOrganization.id;
	}

	private canMoveCurrentUser(): boolean {
		return this.loggedInUser.organization.id === this.selectedOrganization.id &&
			this.loggedInUser.organizationMemberships.some(om => om.organization.id !== this.selectedOrganization.id
				&& om.status === StringUtils.ACTIVE);
	}

	getCountryName() {
		const country = this.selectedOrganization.selectableCountries.find(c => c.id === this.selectedOrganization.country);
		if (country) {
			return country.text;
		} else {
			return '';
		}
	}

	getCultureName() {
		const culture = this.selectedOrganization.selectableCultures.find(c => c.id === this.selectedOrganization.culture);
		if (culture) {
			return culture.text;
		} else {
			return '';
		}
	}

	openChangeImageModal() {
		if (this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)) {
			this.bsModalRef = this.modalService.show(
				LogotypeImageComponent,
				{
					initialState: {
						editModelId: this.selectedOrganization.id,
						disableTabIndexUrl: true
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM,
					// ...this.closeInterceptorConfig()
				}
			);
			this.subscribeToCrudModalContent();
		}
	}

	openDetailsModal(isNewOrg?: boolean) {
		if (this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)) {
			this.bsModalRef = this.modalService.show(
				CreateEditOrganizationComponent,
				{
					initialState: {
						editModelId: isNewOrg ? '' : this.selectedOrganization.id,
						disableTabIndexUrl: true
					},
					...ConfigUtils.MODAL_CONFIG_LARGE,
					// ...this.closeInterceptorConfig()
				}
			);
			this.subscribeToCrudModalContent();
		}
	}

	openSSENReportModal(type: SSENReportIntegrationTypes) {
		if (this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl) && this.hasSSEN) {
			this.bsModalRef = this.modalService.show(
				EditSSENReportComponent,
				{
					initialState: {
						editModelId: this.selectedOrganization.id,
						disableTabIndexUrl: true,
						type: type
					},
					...ConfigUtils.MODAL_CONFIG_M_LARGE,
					// ...this.closeInterceptorConfig()
				}
			);
			this.subscribeToCrudModalContent('integrations');
		}
	}

	openClientModal(client?: Client) {
		if (this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)) {
			this.bsModalRef = this.modalService.show(
				CreateEditClientComponent,
				{
					initialState: {
						editModelId: client ? client.id : '',
						disableTabIndexUrl: true
					},
					...ConfigUtils.MODAL_CONFIG_LARGE,
					// ...this.closeInterceptorConfig()
				}
			);
			this.subscribeToCrudModalContent('clients');
		}
	}

	openTemplateTypeModal(templateType: TemplateType) {
		if (this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)) {
			this.bsModalRef = this.modalService.show(
				CreateEditTemplateTypeDescriptionComponent,
				{
					initialState: {
						editModelId: templateType ? templateType.id : '',
						name: templateType.name,
						disableTabIndexUrl: true
					},
					...ConfigUtils.MODAL_CONFIG_M_LARGE,
					// ...this.closeInterceptorConfig()
				}
			);
			this.subscribeToCrudModalContent('template-types');
		}
	}

	openIntegrationModal(type: string) {
		if (this.loggedInUser.isSuperAdmin()) {
			if (type === IntegrationTypes.DfRespons) {
				const dfRespons = this.externalIntegrations.find(integration => integration.type === IntegrationTypes.DfRespons);
				this.bsModalRef = this.modalService.show(
					CreateEditDfResponsComponent,
					{
						initialState: {
							editModelId: dfRespons.id,
							initialModel: dfRespons,
							disableTabIndexUrl: true
						},
						...ConfigUtils.MODAL_CONFIG_LARGE
					}
				);
			} else if (type === IntegrationTypes.InfraControl) {
				const infraControl = this.externalIntegrations.find(integration => integration.type === IntegrationTypes.InfraControl);
				this.bsModalRef = this.modalService.show(
					CreateEditInfraControlComponent,
					{
						initialState: {
							editModelId: infraControl.id,
							initialModel: infraControl,
							disableTabIndexUrl: true
						},
						...ConfigUtils.MODAL_CONFIG_LARGE
					}
				);
			}
			this.subscribeToCrudModalContent('integrations');
		}
	}

	deleteClient(client: Client) {
		if (this.authService.canEdit()) {
			Swal.fire(
				new SwalConfig(this.translationService).getDelete({
					confirmButtonText: this.translationService.instant('DeleteClient')
				})
			).then(async result => {
				if (result.value) {
					try {
						this.fetchingClients = true;
						const response = await this.organizationService.deleteClientsWithAffectedData(client.id, client.organizationId);
						this.displaySuccessMessage(response.successMessage);
						this.refreshClients();

					} catch (errorResponse) {
						this.fetchingClients = false;
						this.handleErrorResponse(errorResponse);
					}
				}
			});
		}
	}

	deleteIntegration(type: string) {
		if (this.loggedInUser.isSuperAdmin()) {
			Swal.fire(
				new SwalConfig(this.translationService).getDelete({
					confirmButtonText: this.translationService.instant('DeleteIntegration')
				})
			).then(async result => {
				if (result.value) {
					try {
						let integration: ExternalIntegration;
						if (type === IntegrationTypes.DfRespons) {
							integration = this.externalIntegrations.find(i => i.type === IntegrationTypes.DfRespons);
						} else if (type === IntegrationTypes.InfraControl) {
							integration = this.externalIntegrations.find(i => i.type === IntegrationTypes.InfraControl);
						}
						this.fetchingIntegrations = true;
						const response = await this.integrationService.delete(integration.id);
						this.displaySuccessMessage(response.successMessage);
						this.refreshIntegrations();

					} catch (errorResponse) {
						this.fetchingIntegrations = false;
						this.handleErrorResponse(errorResponse);
					}
				}
			});
		}
	}

	protected subscribeToCrudModalContent(refreshString?: string) {
		this.currentCrudModalComponent = this.bsModalRef.content;
		this.subscriptions.add(
			this.currentCrudModalComponent.created$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(async createdItems => {
					if (createdItems && createdItems.length) {
						this.closeModal();
						if (refreshString === 'clients') {
							this.refreshClients();
						} else if (refreshString === 'template-types') {
							this.refreshTemplateTypes();
						} else if (refreshString === 'integrations') {
							this.refreshIntegrations();
						} else {
							const newOrganization = createdItems[0];
							await this.authService.requestUser();
							this.router.navigate([`/${newOrganization.friendlyUrl}/${RoutesUtils.organization}`]);
						}
					}
				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.updated$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(async updatedItems => {
					if (updatedItems && updatedItems.length) {
						this.closeModal();
						if (refreshString === 'clients') {
							this.refreshClients();
						} else if (refreshString === 'template-types') {
							this.refreshTemplateTypes();
						} else if (refreshString === 'integrations') {
							this.refreshIntegrations();
						} else {
							const updatedOrganization = updatedItems[0];
							if (updatedOrganization.friendlyUrl !== this.selectedOrganization.friendlyUrl) {
								this.router.navigate([`/${updatedOrganization.friendlyUrl}/${RoutesUtils.organization}`], { replaceUrl: true });
								this.refresh();
							} else {
								this.refresh();
							}
						}
					}
				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(wasClosed => {
					if (wasClosed) {
						this.closeModalWithUnsavedChanges();
					}
				})
		);
	}

	closeModalWithUnsavedChanges() {
		if (this.hasUnsavedChanges()) {
			this.displayUnsavedChangesSwal()
				.then(result => {
					if (result.value) {
						this.closeModal();
					} else {
						// Set the closed value to false again as we have clicked the close button.
						this.currentCrudModalComponent.closed$.next(false);
					}
				});
		} else {
			this.closeModal();
		}
	}

	private closeModal() {
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
	}

	private async refresh() {
		const organization = await this.organizationService.get(this.selectedOrganization.id);
		await this.authService.requestUser();
		this.authService.setSelectedOrganization(organization);
		this.selectedOrganization = this.authService.selectedOrganization;
		this.loggedInUser = this.authService.loggedInUser;
		if (this.loggedInUser.isSuperAdmin() && this.selectedOrganization.parentId) {
			this.selectedOrganization.parentOrganization = await this.organizationService.get(this.selectedOrganization.parentId);
		}

		this.assignCultureSettings();
	}

	private async refreshClients() {
		this.fetchingClients = true;
		this.clients = await this.organizationService.getClientsInOrganization();
		this.clients.sortByProperty(new Client({}).propertyStrings.name);
		this.fetchingClients = false;
	}

	private async refreshTemplateTypes() {
		this.fetchingTemplateTypes = true;
		this.templateTypes = await this.assignmentService.getTemplateTypes(this.selectedOrganization.id);
		this.templateTypes = this.templateTypes.filter(templateType => !templateType.isManualMeasure);
		this.templateTypes.sortByProperty(new TemplateType({}).propertyStrings.name);
		this.fetchingTemplateTypes = false;
	}

	private async refreshIntegrations() {
		this.fetchingIntegrations = true;
		this.integrations = await this.integrationService.getIntegrations(this.selectedOrganization.id);
		this.integrations.sortByProperty(new Integration({}).propertyStrings.name);
		const ssenIntegration = this.integrations.find(integration => integration.type === IntegrationTypes.SSENReport);
		const templateTypes = await this.organizationService.getTemplateTypes(this.selectedOrganization.id);
		const firstSSEN = templateTypes.find(tt => tt.templateBaseType.name === 'SS-EN');
		this.ssenIntegration = new SSENReportIntegration((ssenIntegration as any));
		if (firstSSEN) {
			this.ssenIntegration.scope = firstSSEN.scope;
			this.ssenIntegration.templateBaseTypeId = firstSSEN.templateBaseType.id;
		}
		this.hasSSEN = !!firstSSEN;
		this.externalIntegrations = this.integrations.filter(integration => integration.type === IntegrationTypes.DfRespons || integration.type === IntegrationTypes.InfraControl);
		this.fetchingIntegrations = false;
	}

	hasDfRespons() {
		const dfResponse = this.externalIntegrations.find(integration => integration.type === IntegrationTypes.DfRespons);
		if (dfResponse) {
			return !!dfResponse.id;
		} else {
			return false;
		}
	}

	dfResponsIsActive() {
		const dfResponse = this.externalIntegrations.find(integration => integration.type === IntegrationTypes.DfRespons);
		if (dfResponse) {
			return !!dfResponse.active;
		} else {
			return false;
		}
	}

	hasInfraControl() {
		const infraControl = this.externalIntegrations.find(integration => integration.type === IntegrationTypes.InfraControl);
		if (infraControl) {
			return !!infraControl.id;
		} else {
			return false;
		}
	}

	infraControlIsActive() {
		const infraControl = this.externalIntegrations.find(integration => integration.type === IntegrationTypes.InfraControl);
		if (infraControl) {
			return !!infraControl.active;
		} else {
			return false;
		}
	}

	getClients() {
		return this.clientSearchValue ? this.clients.filter(client => client.name.toLowerCase().includes(this.clientSearchValue.toLowerCase())) : this.clients;
	}

	isSmallScreenSize() {
		return Utils.isSmallScreenSize();
	}
}
