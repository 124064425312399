<div class="help-text bi-question-circle" #helpTextPop="bs-popover"
	[ngClass]="{
		'opened': opened
	}"
	[title]="'DisplayHelpText' | translate"
	[placement]="'bottom'"
	[containerClass]="'hi-menu-popover'"
	[popover]="helpTextTemplate"
	[outsideClick]="true"
	(onShown)="opened = true"
	(onHidden)="opened = false">
</div>
<ng-template #helpTextTemplate>
	<div class="hi-menu-item"
		[innerHTML]="helpText">
	</div>
</ng-template>
