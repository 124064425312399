import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { ALT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Subscriber } from 'app-core/user/user';
import { AssignmentTemplate } from 'app-inspection/assignment-template/assignment-template';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PublishingType, Schedule, ScheduleGroup, ScheduleUser } from '../schedule';
import { ScheduleService } from '../schedule.service';
import { DateItem } from '../timeline/timeline.component';
import { ChangeDetailsTabComponent } from './tabs/change-details/change-details-tab.component';
import { HandleAssignmentTemplatesTabComponent } from './tabs/handle-assignment-templates/handle-assignment-templates-tab.component';
import { HandleScheduleGroupsTabComponent } from './tabs/handle-schedule-groups/handle-schedule-groups-tab.component';
import { HandleScheduleUsersTabComponent } from './tabs/handle-schedule-users/handle-schedule-users-tab.component';
import { HandleSubscribersTabComponent } from './tabs/handle-subscribers/handle-subscribers-tab.component';

@Component({
	templateUrl: './create-edit-schedule.component.html'
})
export class CreateEditScheduleComponent extends SimpleCreateEditModalDirective<Schedule> implements OnInit {

	@ViewChild(ChangeDetailsTabComponent) changeScheduleDetailsTabComponent: ChangeDetailsTabComponent;
	@ViewChild(HandleAssignmentTemplatesTabComponent) handleAssignmentTemplatesTabComponent: HandleAssignmentTemplatesTabComponent;
	@ViewChild(HandleScheduleGroupsTabComponent) handleScheduleGroupsTabComponent: HandleScheduleGroupsTabComponent;
	@ViewChild(HandleScheduleUsersTabComponent) handleScheduleUsersTabComponent: HandleScheduleUsersTabComponent;
	@ViewChild(HandleSubscribersTabComponent) handleSubscribersTabComponent: HandleSubscribersTabComponent;

	get hasAtLeastOneNotificationChoice() {
		return this.modifiedModel.assignmentTemplates
			.some(assignmentTemplate => assignmentTemplate.templateType.notificationChoice);
	}

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private scheduleService: ScheduleService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		if (this.isEdit) {
			this.pending = true;
			try {
				this.initialModel = await this.scheduleService.get(this.editModelId);
				this.pending = false;

				this.modifiedModel = new Schedule(this.getUniqueVariant(this.initialModel));

				this.refreshPublicationDates();

				setTimeout(() => {
					this.activateTabFromUrl();
					this.triggerAllValidation();
				}, 0);

			} catch (errorResponse) {
				this.pending = false;
				this.handleErrorResponse(errorResponse);
				this.closeWithDelay();
			}
		} else {
			this.initialModel = new Schedule({});
			this.modifiedModel = new Schedule(this.getUniqueVariant(this.initialModel));

			this.refreshPublicationDates();

			setTimeout(() => {
				this.activateTabFromUrl();
			}, 0);
		}
	}

	changeDetailsIsValid() {
		return this.changeScheduleDetailsTabComponent?.formIsValid()
			&& !this.changeScheduleDetailsTabComponent?.startDateErrorMessage
			&& !this.changeScheduleDetailsTabComponent?.endDateErrorMessage;
	}

	changeDetailsHasErrors() {
		return this.changeScheduleDetailsTabComponent?.formHasErrors()
			|| this.changeScheduleDetailsTabComponent?.startDateErrorMessage
			|| this.changeScheduleDetailsTabComponent?.endDateErrorMessage;
	}

	handleAssignmentTemplatesIsValid() {
		return this.modifiedModel.assignmentTemplates.length && this.handleAssignmentTemplatesTabComponent?.itemListIsValid();
	}

	handleAssignmentTemplatesHasErrors() {
		return this.handleAssignmentTemplatesTabComponent?.itemListHasErrors();
	}

	handleGroupsIsValid() {
		return this.handleScheduleGroupsTabComponent?.itemListIsValid();
	}

	handleGroupsHasErrors() {
		return this.handleScheduleGroupsTabComponent?.itemListHasErrors();
	}

	handleUsersIsValid() {
		return this.handleScheduleUsersTabComponent?.itemListIsValid();
	}

	handleUsersHasErrors() {
		return this.handleScheduleUsersTabComponent?.itemListHasErrors();
	}

	handleSubscribersIsValid() {
		return this.handleSubscribersTabComponent?.itemListIsValid();
	}

	handleSubscribersHasErrors() {
		return this.handleSubscribersTabComponent?.itemListHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid()
			&& this.handleAssignmentTemplatesIsValid()
			&& this.handleGroupsIsValid()
			&& this.handleUsersIsValid()
			&& this.handleSubscribersIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.scheduleService.update(this.modifiedModel.toPayloadObject(this.selectedOrganization.id))
				: await this.scheduleService.create(this.modifiedModel.toPayloadObject(this.selectedOrganization.id));

			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeScheduleDetailsTabComponent.setServerErrors(this.serverErrors);
				this.goToTab(1);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeScheduleDetailsTabComponent?.triggerValidation();
		this.handleAssignmentTemplatesTabComponent?.triggerValidation();
		this.handleSubscribersTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Schedule) {
		return new Schedule(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.name !== this.initialModel.name
			|| this.modifiedModel.active !== this.initialModel.active
			|| this.modifiedModel.publishingType !== this.initialModel.publishingType
			|| this.modifiedModel.interval !== this.initialModel.interval
			|| (this.modifiedModel.publishingType === PublishingType.Weekly && !Utils.isEqual(this.modifiedModel.weekdaysInternal, this.initialModel.weekdaysInternal))
			|| this.modifiedModel.daysDelay !== this.initialModel.daysDelay
			|| this.modifiedModel.startDate !== this.initialModel.startDate
			|| this.modifiedModel.endDate !== this.initialModel.endDate
			|| this.modifiedModel.publishingTime !== this.initialModel.publishingTime;
	}

	private assignmentTemplatesHasChanged() {
		const currentAssignmentTemplates = this.stripSelectedAssignmentTemplates(this.modifiedModel.assignmentTemplates);
		const initialAssignmentTemplates = this.stripSelectedAssignmentTemplates(this.initialModel.assignmentTemplates);
		return !Utils.isEqual(currentAssignmentTemplates, initialAssignmentTemplates);
	}

	private groupsHasChanged() {
		const currentScheduleGroups = this.stripSelectedGroups(this.modifiedModel.scheduleGroups);
		const initialScheduleGroups = this.stripSelectedGroups(this.initialModel.scheduleGroups);
		return !Utils.isEqual(currentScheduleGroups, initialScheduleGroups);
	}

	private usersHasChanged() {
		const currentScheduleUsers = this.stripSelectedUsers(this.modifiedModel.scheduleUsers);
		const initialScheduleUsers = this.stripSelectedUsers(this.initialModel.scheduleUsers);
		return !Utils.isEqual(currentScheduleUsers, initialScheduleUsers);
	}

	private subscribersHasChanged() {
		const currentSubscribers = this.stripSelectedSubscribers(this.modifiedModel.subscribers);
		const initialSubscribers = this.stripSelectedSubscribers(this.initialModel.subscribers);
		return !Utils.isEqual(currentSubscribers, initialSubscribers);
	}

	private stripSelectedAssignmentTemplates(items: AssignmentTemplate[]) {
		return items.map(item => {
			const { selected, ...theRest } = item;
			return theRest as AssignmentTemplate;
		});
	}

	private stripSelectedGroups(items: ScheduleGroup[]) {
		return this.stripActionsOpened(items).map(item => {
			const { selected, ...theRest } = item;
			return theRest as ScheduleGroup;
		});
	}

	private stripSelectedUsers(items: ScheduleUser[]) {
		return items.map(item => {
			const { selected, ...theRest } = item;
			return theRest as ScheduleUser;
		});
	}

	private stripSelectedSubscribers(items: Subscriber[]) {
		return items.map(item => {
			const { selected, ...theRest } = item;
			return theRest as Subscriber;
		});
	}

	private stripActionsOpened(items: ScheduleGroup[]) {
		return items.map(item => {
			const { actionsOpened, ...theRest } = item;
			return theRest as ScheduleGroup;
		});
	}

	hasUnsavedChanges() {
		return this.modifiedModel && (this.detailsHasChanged() || this.assignmentTemplatesHasChanged() || this.groupsHasChanged() || this.usersHasChanged() || this.subscribersHasChanged());
	}

	handleValueChanges() {
		if (this.changeScheduleDetailsTabComponent) {
			this.changeScheduleDetailsTabComponent.setSelectableIntervalItems();
			this.refreshPublicationDatesWithDebounce();
		}
	}

	handleDateChanges() {
		if (this.modifiedModel.publishingTime) {
			this.refreshPublicationDatesWithDebounce();
		}
	}

	private refreshPublicationDatesWithDebounce = Utils.debounce(() => this.refreshPublicationDates(), NumberUtils.DEFAULT_DEBOUNCE_TIME);

	private async refreshPublicationDates() {
		let publicationDates = await this.scheduleService.getPublicationDates(this.modifiedModel.toPayloadObject(this.selectedOrganization.id));

		if (!publicationDates.length && this.modifiedModel.publishingType === PublishingType.Once) {
			publicationDates = [`${this.modifiedModel.toPayloadObject(this.selectedOrganization.id).startDate} ${this.modifiedModel.publishingTime}`];
		}

		this.modifiedModel.publicationDates = [];
		this.modifiedModel.publicationDates = publicationDates.map(item => new DateItem({
			yearString: Utils.getYear(item),
			dayAndMonthString: `${Utils.getDay(item)} ${Utils.getMonthName(item)}`,
			dayNameString: Utils.getDayName(item),
			infoText: this.getInfoText(item)
		}));

		setTimeout(() => {
			this.modifiedModel.publicationDates.forEach(item => item.display = true);
		}, 0);
	}

	private getInfoText(dateString: string) {
		const first = Utils.getFormattedDateStringFromString(dateString, ALT_DISPLAY_DATE_FORMAT);
		const second = `${this.translationService.instant('Expires')}:\n${moment(dateString).add(this.modifiedModel.daysDelay, 'day').format(ALT_DISPLAY_DATE_FORMAT)}`;
		return `${first}\n\n${second}`;
	}
}
