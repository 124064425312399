import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'app-core/shared-core/pipes/pipes.module';
import { SimpleComponentsModule } from 'app-core/shared-core/simple-components/simple-components.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ImportantMessageComponent } from './important-message/important-message.component';
import { NotificationMessageDetailsComponent } from './modal/details/notification-message-details.component';
import { NotificationMessageModalComponent } from './modal/notification-message-modal.component';
import { NotificationMessagesComponent } from './notification-messages/notification-messages.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		PopoverModule,
		SimpleComponentsModule,
		PipesModule
	],
	declarations: [
		NotificationMessagesComponent,
		ImportantMessageComponent,
		NotificationMessageModalComponent,
		NotificationMessageDetailsComponent
	],
	exports: [
		NotificationMessagesComponent,
		ImportantMessageComponent,
		NotificationMessageModalComponent,
		NotificationMessageDetailsComponent
	]
})
export class NotificationMessageModule { }
