import { Component, ComponentRef, ElementRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { SimpleListDirective } from 'app-core/shared-core/simple-components/list/simple-list.directive';
import { SimpleTableRowAction, SimpleTableRowActionDelete, SimpleTableRowActionEdit } from 'app-core/shared-core/simple-components/list/table/body/simple-table-row-action';
import { SimpleTableCategoriesColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-categories-column';
import { SimpleTableDueDateColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-due-date-column';
import { EventsContent, SimpleTableEventsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-events-column';
import { IconContent, SimpleTableIconsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-icons-column';
import { SimpleTableStatusColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-status-column';
import { SimpleTableTextColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-text-column';
import { SimpleTableEmptyState } from 'app-core/shared-core/simple-components/list/table/empty-state/simple-table-empty-state';
import { SimpleFilterHelperFacility } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-facility';
import { SimpleFilterHelperSchedule } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-schedule';
import { SimpleFilterHelperTemplateType } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-template-type';
import { SimpleFilterHelperUser } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-user';
import { SimpleFilterHelperUserGroup } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-user-group';
import { SimpleFilterInput, SimpleFilterInputType } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-input-settings';
import { SimpleFilterListModalInput } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-list-modal-input-settings';
import { SimpleFilterConfig, SortObject } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-config';
import { SimpleFilterInputItem } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-input-item';
import { SimpleRetainService } from 'app-core/shared-core/simple-components/list/table/filter/simple-retain.service';
import { SimpleTableHeaderAction, SimpleTableHeaderActionDelete } from 'app-core/shared-core/simple-components/list/table/head/simple-table-header-action';
import { SimpleTableConfig } from 'app-core/shared-core/simple-components/list/table/simple-table-config';
import { TextContent } from 'app-core/shared-core/simple-components/various/text-content/simple-text-content.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { GroupOrAssigneeRulesObject, ViewObjectsComponent, ViewObjectsType } from 'app-core/shared-core/view-objects/view-objects.component';
import { Assignment } from 'app-inspection/assignment/assignment';
import { DeleteAssignmentsComponent } from 'app-inspection/assignment/delete-assignments/delete-assignments.component';
import { DetailedAssignmentComponent } from 'app-inspection/assignment/detailed-assignment/detailed-assignment.component';
import { environment } from 'environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AssignmentFilter, AssignmentService } from '../../../assignment.service';
import { HandleAssigneeGroupsAndAssigneesComponent } from '../../handle-assignee-groups-and-assignees/handle-assignee-groups-and-assignees.component';
import { SetDueDateComponent } from './set-due-date/set-due-date.component';

@Component({
	selector: 'list-assignment-scheduled-ongoing',
	templateUrl: '../../../../../app-core/shared-core/simple-components/list/simple-list-shared-template.html',
	providers: [SimpleFilterHelperTemplateType, SimpleFilterHelperFacility, SimpleFilterHelperSchedule, SimpleFilterHelperUserGroup, SimpleFilterHelperUser]
})
export class ListAssignmentScheduledOngoingComponent extends SimpleListDirective<Assignment> {

	// Override filterObject to match backend.
	readonly filterObject = new AssignmentFilter();

	bsModalRef: BsModalRef<any>;

	componentRef: ComponentRef<ViewObjectsComponent>;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected sanitizer: DomSanitizer,
		protected assignmentService: AssignmentService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected retainService: SimpleRetainService,
		protected elementRef: ElementRef,
		protected viewContainerRef: ViewContainerRef,
		private simpleFilterHelperTemplateType: SimpleFilterHelperTemplateType,
		private simpleFilterHelperFacility: SimpleFilterHelperFacility,
		private simpleFilterHelperSchedule: SimpleFilterHelperSchedule,
		private simpleFilterHelperUserGroup: SimpleFilterHelperUserGroup,
		private simpleFilterHelperUser: SimpleFilterHelperUser) {
		super(
			authService,
			modalService,
			toastrService,
			translationService,
			assignmentService,
			route,
			router,
			retainService,
			elementRef,
			viewContainerRef,
			RoutesUtils.assignment
		);
	}

	protected configureListActions() { }

	protected async configureTableFilter(config: SimpleFilterConfig<Assignment>) {
		this.filterObject.context = RoutesUtils.scheduledOngoing;
		config.hideSearch = true;

		config.setInitialFacets(
			new KeyValuePair(StringUtils.IS_ACTIVE_KEY, 'true'),
			new KeyValuePair(StringUtils.IS_STARTED_KEY, 'true'),
			new KeyValuePair(StringUtils.IS_MEASURE_KEY, 'false'),
			new KeyValuePair(StringUtils.IS_MANUAL_MEASURE_KEY, 'false'),
			new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, 'Or')
		);

		const templateTypesKey = StringUtils.TEMPLATE_TYPES_KEY;
		const facilitiesKey = StringUtils.FACILITIES_KEY;
		const categoriesKey = StringUtils.CATEGORIES_KEY;
		const schedulesKey = StringUtils.SCHEDULES_KEY;
		const groupsKey = StringUtils.GROUPS_KEY;
		const assigneesKey = StringUtils.ASSIGNEES_KEY;
		const createdBetweenKey = StringUtils.CREATED_BETWEEN_KEY;
		const expiresBetweenKey = StringUtils.EXPIRES_BETWEEN_KEY;
		const statusKey = StringUtils.STATUS_KEY;
		const hasGroupsKey = StringUtils.HAS_GROUPS_KEY;
		const hasAssigneesKey = StringUtils.HAS_ASSIGNEES_KEY;
		if (!this.isInModal) {
			this.retainService.setCurrentRetainEntries({
				search: null,
				sort: null,
				[templateTypesKey]: null,
				[facilitiesKey]: null,
				[categoriesKey]: null,
				[schedulesKey]: null,
				[groupsKey]: null,
				[assigneesKey]: null,
				[createdBetweenKey]: null,
				[expiresBetweenKey]: null,
				[statusKey]: null,
				[hasGroupsKey]: null,
				[hasAssigneesKey]: null
			});
		}

		try {
			const [templateTypes, facilities, categories, schedules, userGroups, users] = await Promise.all([
				this.assignmentService.getTemplateTypes(this.selectedOrganization.id),
				this.assignmentService.getFacilities(),
				this.assignmentService.getCategories(this.selectedOrganization.id),
				this.assignmentService.getSchedules(this.selectedOrganization.id),
				this.assignmentService.getUserGroups(this.selectedOrganization.id),
				this.assignmentService.getUsers(this.selectedOrganization.id)
			]);

			const convertedTemplateTypes = templateTypes.filter(templateType => !templateType.isManualMeasure).map(templateType => new SimpleFilterInputItem(templateType.id, templateType.name));
			const convertedFacilities = facilities.map(facility => new SimpleFilterInputItem(facility.id, facility.name));
			const convertedCategories = categories.map(category => new SimpleFilterInputItem(category.id, category.name));
			const convertedSchedules = schedules.map(schedule => new SimpleFilterInputItem(schedule.id, schedule.name));
			const convertedUserGroups = userGroups.map(userGroup => new SimpleFilterInputItem(userGroup.id, userGroup.name));
			const convertedUsers = users.map(user => new SimpleFilterInputItem(user.id, user.email));

			config.setFilterInputs(
				new SimpleFilterListModalInput(
					templateTypesKey,
					this.translationService.instant(templateTypesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(templateTypesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(templateTypesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperTemplateType.openListModal(filterInput, templateTypes, this);
					},
					convertedTemplateTypes
				),
				new SimpleFilterListModalInput(
					facilitiesKey,
					this.translationService.instant(facilitiesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(facilitiesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(facilitiesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperFacility.openListModal(filterInput, facilities, this);
					},
					convertedFacilities
				),
				new SimpleFilterInput(
					categoriesKey,
					this.translationService.instant(categoriesKey),
					SimpleFilterInputType.MultiSelectWithSearch,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(categoriesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(categoriesKey, ''));
						}
						return keyValuePairs;
					},
					...convertedCategories
				),
				new SimpleFilterListModalInput(
					schedulesKey,
					this.translationService.instant(schedulesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(schedulesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(schedulesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperSchedule.openListModal(filterInput, schedules, this);
					},
					convertedSchedules
				),
				new SimpleFilterInput(
					expiresBetweenKey,
					this.translationService.instant(expiresBetweenKey),
					SimpleFilterInputType.DateRangeSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(expiresBetweenKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(expiresBetweenKey, ''));
						}
						return keyValuePairs;
					}
				),
				new SimpleFilterInput(
					createdBetweenKey,
					this.translationService.instant(createdBetweenKey),
					SimpleFilterInputType.DateRangeSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(createdBetweenKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(createdBetweenKey, ''));
						}
						return keyValuePairs;
					}
				),
				new SimpleFilterListModalInput(
					groupsKey,
					this.translationService.instant(groupsKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(groupsKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(groupsKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperUserGroup.openListModal(filterInput, userGroups, this);
					},
					convertedUserGroups
				),
				new SimpleFilterInput(
					hasGroupsKey,
					this.translationService.instant(hasGroupsKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(hasGroupsKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(hasGroupsKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.YES,
						this.translationService.instant(StringUtils.YES)
					),
					new SimpleFilterInputItem(
						StringUtils.NO,
						this.translationService.instant(StringUtils.NO)
					)
				),
				new SimpleFilterListModalInput(
					assigneesKey,
					this.translationService.instant(assigneesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(assigneesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(assigneesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperUser.openListModal(filterInput, users, this);
					},
					convertedUsers
				),
				new SimpleFilterInput(
					hasAssigneesKey,
					this.translationService.instant(hasAssigneesKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(hasAssigneesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(hasAssigneesKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.YES,
						this.translationService.instant(StringUtils.YES)
					),
					new SimpleFilterInputItem(
						StringUtils.NO,
						this.translationService.instant(StringUtils.NO)
					)
				),
				new SimpleFilterInput(
					statusKey,
					this.translationService.instant(statusKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(statusKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(statusKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.NOT_STARTED,
						this.translationService.instant(StringUtils.NOT_STARTED)
					),
					new SimpleFilterInputItem(
						StringUtils.STARTED,
						this.translationService.instant(StringUtils.STARTED)
					)
				)
			);

			if (!this.isInModal) {
				// Needs to be called here due to the data fetch delay.
				this.setSelectedFilterItems();
			}

		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}

	protected configureTableSort(config: SimpleFilterConfig<Assignment>) {
		config.setSortObjects(
			new SortObject(
				'AssignmentTemplate.TemplateType.Name' as keyof Assignment,
				this.translationService.instant('TemplateType')
			),
			new SortObject(
				'AssignmentTemplate.Facility.Name' as keyof Assignment,
				this.translationService.instant('Facility')
			),
			new SortObject(
				'Schedule.Name' as keyof Assignment,
				this.translationService.instant('Schedule'),
			),
			new SortObject(
				this.propertyStrings.dueDate,
				this.translationService.instant('Expires'),
				true
			),
			new SortObject(
				this.propertyStrings.created,
				this.translationService.instant('Created')
			),
			new SortObject(
				this.propertyStrings.firstUpdated,
				this.translationService.instant('Started')
			)
		);
	}

	protected configureTableColumns(config: SimpleTableConfig<Assignment>) {
		config.setColumns(
			new SimpleTableTextColumn(
				this.translationService.instant('TemplateType'),
				(item) => new TextContent(
					item.templateType?.name,
					item.facility?.name,
					item.templateType && this.loggedInUser.isSuperAdmin()
						? `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${item.templateType.url}`
						: '',
					item.facility && this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)
						? `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${item.facility.url}`
						: ''
				),
				this.sanitizer,
				this.viewContainerRef,
				this.translationService.instant('Facility')
			),
			new SimpleTableCategoriesColumn(
				this.translationService.instant('Categories'),
				(item) => item.categories,
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableTextColumn(
				this.translationService.instant('Schedule'),
				(item) => new TextContent(
					item.schedule?.name,
					'',
					item.schedule && this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)
						? `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${item.schedule.url}`
						: '',
					''
				),
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableDueDateColumn(
				this.translationService.instant('Expires'),
				(item) => item,
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableEventsColumn(
				this.translationService.instant('Events'),
				(item) => [
					new EventsContent(`${this.translationService.instant('Created')} ${Utils.getFormattedDateStringFromString(item.created, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.created, 'bi-file-earmark-plus'),
					item.firstUpdated
						? new EventsContent(`${this.translationService.instant('Started')} ${Utils.getFormattedDateStringFromString(item.firstUpdated, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.firstUpdated, 'bi-person-walking')
						: null,
					Utils.dateIsAfter(item.lastUpdated, item.firstUpdated)
						? new EventsContent(`${this.translationService.instant('Saved')} ${Utils.getFormattedDateStringFromString(item.lastUpdated, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.lastUpdated, 'bi-clock')
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableIconsColumn(
				'',
				(item) => [
					item.assigneeGroups.filter(group => group.canPerformScheduled).length
						? new IconContent('bi-people', item.assigneeGroups.filter(group => group.canPerformScheduled).length.toString(), `${this.translationService.instant('Groups')}: ${item.assigneeGroups.filter(group => group.canPerformScheduled).length.toString()}`)
						: null,
					item.assignees.filter(assignee => assignee.canPerformScheduled).length
						? new IconContent('bi-person', item.assignees.filter(assignee => assignee.canPerformScheduled).length.toString(), `${this.translationService.instant('Assignees')}: ${item.assignees.filter(assignee => assignee.canPerformScheduled).length.toString()}`)
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableStatusColumn(
				this.translationService.instant('Status'),
				(item) => item.status === StringUtils.ACTIVE
					? StringUtils.NOT_STARTED
					: StringUtils.STARTED,
				this.sanitizer,
				this.viewContainerRef,
				''
			)
		);
	}

	protected configureTableActions(config: SimpleTableConfig<Assignment>) {

		// HEADER
		config.setHeaderActions(
			new SimpleTableHeaderAction(
				this.translationService.instant('HandleGroupsAndAssignees'),
				StringUtils.icons.group,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueBulkHandleAssigneeGroupsAndAssignees}_${selectedIds.join()}`);
				}
			),
			new SimpleTableHeaderAction(
				this.translationService.instant('SetDueDate'),
				StringUtils.icons.setDueDate,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueBulkSetDueDate}_${selectedIds.join()}`);
				}
			),
			new SimpleTableHeaderActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${selectedIds.join()}`);
				}
			)
		);

		// ROW
		this.hideTableRowActions = false;
		config.setHideRowActions(this.hideTableRowActions);

		config.setRowActions(
			new SimpleTableRowActionEdit(
				this.translationService.instant('Open'),
				StringUtils.icons.handle,
				(row) => {
					this.setAsAffected([row.id]);
					this.setCrudParams(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('HandleGroupsAndAssignees'),
				StringUtils.icons.group,
				(row) => {
					this.setAsAffected([row.id]);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueHandleAssigneeGroupsAndAssignees}_${row.id}`);
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('SetDueDate'),
				StringUtils.icons.setDueDate,
				(row) => {
					this.setAsAffected([row.id]);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueSetDueDate}_${row.id}`);
				}
			),
			new SimpleTableRowActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					}
				},
				(row) => {
					return !this.authService.canEdit();
				}
			)
		);
	}

	protected configureTableEmptyState(config: SimpleTableConfig<Assignment>) {
		config.emptyState = new SimpleTableEmptyState(
			'ScheduledAssignments',
			StringUtils.icons.scheduledAssignment
		);
	}

	protected setPropertiesAndOpenViewObjectsPopover(item: Assignment, type: ViewObjectsType, rowClickNumber: number, rowId: string) {
		this.componentRef = this.viewContainerRef.createComponent(ViewObjectsComponent);
		this.componentRef.instance.rowId = rowId;
		this.componentRef.instance.type = type;

		switch (type) {
			case ViewObjectsType.groupsAndUsers:
				this.componentRef.instance.groups = item.assigneeGroups.map(assigneeGroup => assigneeGroup.userGroup);
				this.componentRef.instance.users = item.assignees.filter(assignee => assignee.canPerformScheduled).map(assignee => assignee.user);
				this.componentRef.instance.groupRules = item.assigneeGroups.map(ag => new GroupOrAssigneeRulesObject({ id: ag.userGroup.id, rules: ag.rulesReadonlyInScheduledAssignment }))
				this.componentRef.instance.assigneeRules = item.assignees.filter(a => a.canPerformScheduled).map(a => new GroupOrAssigneeRulesObject({ id: a.user.id, rules: a.rulesReadonlyInScheduledAssignment }));
				this.componentRef.instance.triggerViewChanges();

				this.openViewObjectsPopover(this.componentRef, rowClickNumber, rowId, StringUtils.icons.group);
				break;
		}
	}

	protected openModalByActionName(value: string) {
		const [actionName, idString] = value.split('_');
		this.shouldClearAfterUpdate = !this.isInModal && this.isBulkAction(actionName);
		if (actionName === RoutesUtils.modalPrefixValueEdit) {
			this.bsModalRef = this.modalService.show(
				DetailedAssignmentComponent,
				{
					initialState: {
						editModelId: idString,
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_X_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueBulkHandleAssigneeGroupsAndAssignees || actionName === RoutesUtils.modalPrefixValueHandleAssigneeGroupsAndAssignees) {
			this.bsModalRef = this.modalService.show(
				HandleAssigneeGroupsAndAssigneesComponent,
				{
					initialState: {
						editModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_X_LARGE
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueBulkSetDueDate || actionName === RoutesUtils.modalPrefixValueSetDueDate) {
			this.bsModalRef = this.modalService.show(
				SetDueDateComponent,
				{
					initialState: {
						editModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueDelete) {
			this.bsModalRef = this.modalService.show(
				DeleteAssignmentsComponent,
				{
					initialState: {
						deleteModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_SMALL
				}
			);
		}
		this.subscribeToCrudModalContent();
	}

	protected import(file: File) { }

	protected export() { }
}
