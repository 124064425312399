import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Assignment } from 'app-inspection/assignment/assignment';
import { AssignmentService } from 'app-inspection/assignment/assignment.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SetDueDateTabComponent } from './tabs/set-due-date-tab.component';

@Component({
	templateUrl: './set-due-date.component.html'
})
export class SetDueDateComponent extends SimpleBulkEditModalDirective<Assignment> implements OnInit {

	@ViewChild(SetDueDateTabComponent) setAssignmentDueDateTabComponent: SetDueDateTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private assignmentService: AssignmentService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.initialModel = new Assignment({});
	}

	changeDetailsIsValid() {
		return this.setAssignmentDueDateTabComponent?.formIsValid()
			&& !this.setAssignmentDueDateTabComponent?.dueDateErrorMessage;
	}

	changeDetailsHasErrors() {
		return this.setAssignmentDueDateTabComponent?.formHasErrors()
			|| this.setAssignmentDueDateTabComponent?.dueDateErrorMessage;
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {

			const [date, _] = this.initialModel.dueDate.split(' ');
			const newDueDate = `${date} ${this.initialModel.dueTime}`;

			const response = await this.assignmentService.updateDueDate(this.editModelIds, newDueDate);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.setAssignmentDueDateTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Assignment) {
		return new Assignment(item);
	}

	hasUnsavedChanges() {
		return false;
	}
}
