import { ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SimpleStatusLabelComponent } from 'app-core/shared-core/simple-components/various/status-label/simple-status-label.component';
import { SimpleTableColumn } from './simple-table-column';

export class SimpleTableStatusColumn<T> extends SimpleTableColumn<T> {

	readonly getStatus: (item: T) => string;

	constructor(
		label: string,
		getStatus: (item: T) => string,
		sanitizer: DomSanitizer,
		viewContainerRef: ViewContainerRef,
		secondaryLabel: string) {
		super(
			label,
			sanitizer,
			viewContainerRef,
			secondaryLabel
		);
		this.getStatus = getStatus;
		this.maxWidth = '85px';
	}

	renderValue(item: T) {
		const componentRef = this.viewContainerRef.createComponent(SimpleStatusLabelComponent);
		const status = this.getStatus(item);
		componentRef.instance.text = status;
		componentRef.instance.triggerViewChanges();
		this.viewContainerRef.clear();
		const statusDiv = componentRef.location.nativeElement.childNodes[0] as HTMLDivElement;
		return this.sanitizer.bypassSecurityTrustHtml(statusDiv.outerHTML);
	}
}
