import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { UserSupportLocalStorageService } from 'app-core/user-support/user-support-local-storage.service';
import { UserSupportService } from 'app-core/user-support/user-support.service';
import { LoggedInUser } from 'app-core/user/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { NotificationMessageModalComponent } from '../modal/notification-message-modal.component';
import { NotificationMessage } from '../notification-message';
import { NotificationMessageDirective } from '../notification-message.directive';

@Component({
	selector: 'notification-messages',
	templateUrl: './notification-messages.component.html',
	styleUrls: ['./notification-messages.component.less']
})
export class NotificationMessagesComponent extends NotificationMessageDirective implements OnInit {

	loggedInUser: LoggedInUser;

	constructor(
		protected userSupportService: UserSupportService,
		private userSupportLocalStorageService: UserSupportLocalStorageService,
		private modalService: BsModalService,
		authService: AuthService) {
		super(
			userSupportService
		);
		this.loggedInUser = authService.loggedInUser;
	}

	protected mapData() {
		this.notificationMessages = this.userSupportLocalStorageService.setHasBeenOpenedState(
			this.file.notificationMessages.filter(message => !message.important && this.shouldDisplay(message)),
			this.loggedInUser.userConfig
		);
	}

	openNotificationMessageModal(notificationMessage: NotificationMessage) {
		if (!this.bsModalRef) {
			notificationMessage.hasBeenOpened = true;
			this.saveUserConfig(notificationMessage);

			this.bsModalRef = this.modalService.show(
				NotificationMessageModalComponent,
				{
					initialState: {
						notificationMessage: notificationMessage
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM
				}
			);
			this.modalCloseSubscription = this.bsModalRef.content.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe((wasClosed: boolean) => {
					if (wasClosed) {
						this.closeModal();
					}
				});
		}
	}

	hasUnreadNotificationMessages() {
		return this.notificationMessages.some(notification => !notification.hasBeenOpened);
	}

	getUnreadCount() {
		return this.notificationMessages.filter(notification => !notification.hasBeenOpened).length;
	}

	private saveUserConfig(notificationMessage: NotificationMessage) {
		if (notificationMessage.hasBeenOpened) {
			if (!this.loggedInUser.userConfig.openedNotificationIds.includes(notificationMessage.id)) {
				this.loggedInUser.userConfig.openedNotificationIds.push(notificationMessage.id);
			}
		} else {
			if (this.loggedInUser.userConfig.openedNotificationIds.includes(notificationMessage.id)) {
				this.loggedInUser.userConfig.openedNotificationIds = this.loggedInUser.userConfig.openedNotificationIds.filter(id => id !== notificationMessage.id);
			}
		}
		this.userSupportLocalStorageService.updateUserConfig(this.loggedInUser.userConfig);
	}
}
