import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app-core/auth/auth.service';
import { SortDirection } from 'app-core/shared-core/tools/extensions';
import { Utils } from 'app-core/shared-core/tools/utils';
import { ReplaySubject, Subscription } from 'rxjs';
import { filter, repeatWhen, takeUntil } from 'rxjs/operators';
import {
	DateTypes,
	ReferenceTypes,
	SortOptions,
	SpanTypes,
	StatisticsByDataReloadData,
	StatisticsByDateAndTypePayload,
	StatisticsByDateData,
	StatisticsDataByReferenceTypeToRender,
	StatisticsSort,
	StatisticsTypes
} from './statistics';
import { StatisticsService } from './statistics.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'statistics-by-date-data-modal',
	templateUrl: 'statistics-by-date-data-modal.component.html',
	styleUrls: ['./statistics.component.less']
})

export class StatisticsByDateDataModalComponent implements OnInit, OnDestroy {

	header: string;
	dateTypesEnum = DateTypes;
	spanTypesEnum = SpanTypes;
	referenceTypesEnum = ReferenceTypes;
	sortOptionsEnum = SortOptions;
	sortDirectionsEnum = SortDirection;
	dateTypesEnumKeys = Object.keys(DateTypes);
	externalError: string = null;
	internalError: string = null;
	loading = true;
	refreshing = false;
	locale: string = 'sv-SE';
	dateSortOptions = [SortOptions.Year, SortOptions.Month, SortOptions.Day];
	activeDateType: DateTypes = null;
	activeReferenceTypes: ReferenceTypes[] = null;
	activeSort: StatisticsSort = null;
	dataToRender: StatisticsDataByReferenceTypeToRender = null;
	newDataAvailable = false;

	private _destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	private _subscriptions = new Subscription();

	@Input() payload: StatisticsByDateAndTypePayload;
	@Output() onClose = new EventEmitter<any>();

	constructor(
		private translateService: TranslateService,
		private cdRef: ChangeDetectorRef,
		private authService: AuthService,
		private statisticsService: StatisticsService) {
	}

	ngOnInit() {
		const currentUser = this.authService.loggedInUser;
		if (!currentUser.isSuperAdmin()) {
			return;
		}
		this.locale = currentUser.culture;

		const translatedPayload = {
			discriminators: '<b class="strong">[' + this.translateService.instant(
				this.payload.discriminators.map(o => `${ReferenceTypes[o]}`)
					.join('') + 'Statistics')
				.toLowerCase() + ']</b>',
			type: '<b class="strong">[' + this.translateService.instant(
				StatisticsTypes[this.payload.type] + 'StatisticsAssignment')
				.toLowerCase() + ']</b>'
		};

		this.header = this.translateService.instant(
			'StatisticsForTypesAndDates',
			translatedPayload);

		this._subscriptions.add(
			this.statisticsService.error$
				.pipe(
					takeUntil(this._destroyed$),
					takeUntil(this.statisticsService.refreshing$),
					repeatWhen(_ => this.statisticsService.refreshingDone$)
				)
				.subscribe(state => {
					this.externalError = state;
					this.cdRef.detectChanges();
				})
		);

		this._subscriptions.add(
			this.statisticsService.refreshing$
				.pipe(takeUntil(this._destroyed$))
				.subscribe(state => {
					this.refreshing = state;
					this.cdRef.detectChanges();
				})
		);


		this._subscriptions.add(
			this.statisticsService.refreshingDone$
				.pipe(takeUntil(this._destroyed$))
				.subscribe(state => {
					if (state) {
						this.newDataAvailable = true;
						// this.statisticsService.refreshingDone$.next(false);
					}
				})
		);

		this._subscriptions.add(
			this.statisticsService.statisticsByDateReload$
				.pipe(
					takeUntil(this._destroyed$),
					filter(o => o.value === 'out'))
				.subscribe(state => {
					setTimeout(() => {
						this.payload = state.data;
						this.initializeData();
						this.statisticsService.statisticsByDateReload$.next(new StatisticsByDataReloadData('none'));
						this.cdRef.detectChanges();
					});
				})
		);

		this.initializeData();
	}

	private initializeData() {
		const activeDateType = this.activeDateType ?? this.payload.dateType;
		const activeReferenceTypes = this.activeReferenceTypes ?? this.payload.discriminators;
		this.activeDateType = activeDateType;
		this.activeReferenceTypes = activeReferenceTypes;
		this.activeSort = this.activeSort ?? this.payload.sort;
		this.newDataAvailable = false;

		this.toggleData(activeDateType, activeReferenceTypes);
	}

	scrollToTop() {
		const modalBody = document.getElementsByClassName('by-date-data')[0] as HTMLElement;
		if (modalBody) {
			modalBody.parentElement.scrollTop = 0;
		}
	}

	changeDateType(dateType: DateTypes) {
		const activeDateType = this.activeDateType;
		const statisticsSort = this.activeSort;
		if (statisticsSort.option !== SortOptions.Total) {
			statisticsSort.option = SortOptions[dateType];
			this.activeSort = statisticsSort;
		}

		if (activeDateType === dateType) {
			return;
		}

		this.activeDateType = dateType;
		this.toggleData(dateType, this.activeReferenceTypes ?? this.payload.discriminators);
	}

	changeReferenceType(referenceTypes: ReferenceTypes[]) {
		let existingTypes = this.activeReferenceTypes;

		if (existingTypes.some(o => referenceTypes.includes(o))) {
			existingTypes = existingTypes.filter(o => !referenceTypes.includes(o));
		} else {
			existingTypes = existingTypes.concat(referenceTypes);
		}

		if (existingTypes.length === 0) {
			return;
		}
		this.activeReferenceTypes = existingTypes;
		this.toggleData(this.activeDateType ?? this.payload.dateType, existingTypes);
	}

	toggleData(dateType?: DateTypes, referenceTypes?: ReferenceTypes[]) {
		this.loading = true;
		this.refreshing = true;
		this.cdRef.detectChanges();
		let mappedData: StatisticsByDateData[];
		let data: StatisticsDataByReferenceTypeToRender;
		const currentSort = this.activeSort;

		if (referenceTypes.length > 1 &&
			Utils.arraysEqual(referenceTypes, this.payload.discriminators)) {
			mappedData = this.payload.combinedYears
				.concat(this.payload.combinedMonths)
				.concat(this.payload.combinedDays)
				.filter(o => o.dateType === dateType);
		} else {
			mappedData = this.payload.data
				.find(o => String(o.type) === ReferenceTypes[referenceTypes[0]])
			[DateTypes[dateType].toLowerCase()];
		}

		mappedData.sort((a, b) => {
			let sort: number;
			if (this.dateSortOptions.some(o => o === currentSort.option)) {
				sort = new Date(b.date).valueOf() - new Date(a.date).valueOf();
			} else {
				sort = b.total - a.total;
			}

			return sort * currentSort.direction;
		});

		data = new StatisticsDataByReferenceTypeToRender({
			types: `${referenceTypes.sort().map(o => ReferenceTypes[o]).join('')}Statistics`,
			dateType: dateType,
			data: mappedData
		});

		this.dataToRender = data;
		this.externalError = null;
		this.internalError = null;
		this.loading = false;
		this.refreshing = false;
		this.cdRef.detectChanges();

		setTimeout(() => {
			this.refreshing = true;
			const highestTotal = Math.max.apply(Math, data.data.map(o => o.total));
			const maxTotal = Math.round(highestTotal * 1.35);

			const container = document.getElementsByClassName('by-date-data')[0] as HTMLElement;
			const headerContainer = container?.querySelector('.section-inner-description') as HTMLElement;
			if (headerContainer) {
				const firstHeader = headerContainer.querySelector('.description-value.description.date') as HTMLElement;

				if (firstHeader) {
					for (const innerData of data.data) {
						innerData.renderConfig.width = (innerData.total / maxTotal) * 100;

						if (innerData.renderConfig.width <= 8.5) {
							innerData.renderConfig.numbersFromBar =
								innerData.total <= 9 ? -15
									: innerData.total <= 99 ? -25
										: innerData.total <= 999 ? -35
											: innerData.total <= 9999 ? -45
												: 0;
						}
						innerData.renderConfig.initialized = true;
					}
					setTimeout(() => {
						this.refreshing = false;
						this.cdRef.detectChanges();
					}, 1000)
				}
			} else {
				setTimeout(() => {
					this.refreshing = false;
					this.cdRef.detectChanges();
				}, 1000)
			}
		});
	}

	sort(statisticsSort: StatisticsSort) {
		const currentData = this.dataToRender;
		this.activeSort = statisticsSort;

		currentData.data
			.sort((a, b) => {
				let sort: number;
				if (this.dateSortOptions.some(o => o === statisticsSort.option)) {
					sort = new Date(b.date).valueOf() - new Date(a.date).valueOf();
				} else {
					sort = b.total - a.total;
				}

				return sort * statisticsSort.direction;
			});

		this.dataToRender = currentData;
	}

	updateData() {
		this.refreshing = true;
		this.statisticsService.statisticsByDateReload$.next(new StatisticsByDataReloadData('in'));
	}

	closeModal() {
		this.onClose.emit();
	}

	ngOnDestroy() {
		this._destroyed$.next(true);
		this._destroyed$.complete();
	}
}
