import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { GlobalOrganizationModalComponent } from 'app-core/organization/global-organization/global-organization-modal.component';
import { LocalStorageKey, LocalStorageService } from 'app-core/shared-core/local-storage/local-storage.service';
import { SimpleCrudDirective } from 'app-core/shared-core/simple-components/crud/simple-crud.directive';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.less']
})
export class SideMenuComponent extends SimpleCrudDirective<any> {

	routesUtils = RoutesUtils;
	stringUtils = StringUtils;

	collapsed: boolean = false;

	@Input() isInvalidRoute: boolean;
	@Input() menuOpenedMobile: boolean;

	@Output() onItemClick = new EventEmitter();

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private localStorageService: LocalStorageService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
		this.setCollapsedState();
	}

	private setCollapsedState() {
		const value = this.localStorageService.getItem(LocalStorageKey.CollapsedMenu);
		this.collapsed = value ? value : false;
	}

	routeIsActive(pathName: string) {
		const strippedPath = this.getCurrentStrippedURLPath();
		return pathName.split('/').every(entry => strippedPath.indexOf(entry) !== -1);
	}

	routeIsDisabled(pathName: string) {
		// Disable when active, but not in "/new", "/:id" etc...
		const maxLength = pathName.split('/').length > 1 ? 4 : 3;
		return this.routeIsActive(pathName) && (this.router.url.split('/').length - 1) < maxLength;
	}

	getCurrentStrippedURLPath() {
		return window.location.pathname.replace(this.selectedOrganization.friendlyUrl, '').split('/')
			.filter(entry => !!entry);
	}

	toggleMenu() {
		this.collapsed = !this.collapsed;
		this.localStorageService.setItem(LocalStorageKey.CollapsedMenu, this.collapsed);
	}

	handleItemClick() {
		this.onItemClick.emit();
	}

	toggleGlobalOrgModal() {
		if (this.canSwitchOrganization()) {
			this.bsModalRef = this.modalService.show(
				GlobalOrganizationModalComponent,
				ConfigUtils.MODAL_CONFIG_MEDIUM
			);

			const component = this.bsModalRef.content;
			this.subscriptions.add(
				component.closed$
					.pipe(
						takeUntil(this.destroyed$)
					)
					.subscribe(wasClosed => {
						if (wasClosed) {
							this.closeModal();
						}
					})
			);
		}
	}

	logout() {
		this.authService.startLogout();
	}

	getCurrentRole() {
		const roles = this.loggedInUser.getCurrentRoles(this.selectedOrganization.id);
		if (roles.length) {
			return this.translationService.translateArray(roles).toCommaSeparatedList();
		} else {
			return this.translationService.instant('NotInOrganization');
		}
	}

	canSwitchOrganization() {
		return !this.isInvalidRoute && (this.loggedInUser.hasActiveMemberships() || this.loggedInUser.isSuperAdmin());
	}

	closeModal() {
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
	}
}
