import { Component, ComponentRef, ElementRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { SimpleListAction } from 'app-core/shared-core/simple-components/list/actions/simple-list-action';
import { SimpleListDirective } from 'app-core/shared-core/simple-components/list/simple-list.directive';
import { SimpleTableRowAction, SimpleTableRowActionDelete, SimpleTableRowActionEdit, SimpleTableRowActionKeepOpen } from 'app-core/shared-core/simple-components/list/table/body/simple-table-row-action';
import { EventsContent, SimpleTableEventsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-events-column';
import { IconContent, SimpleTableIconsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-icons-column';
import { SimpleTablePublishDateColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-publish-date-column';
import { SimpleTableStatusColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-status-column';
import { SimpleTableTextColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-text-column';
import { SimpleTableEmptyState } from 'app-core/shared-core/simple-components/list/table/empty-state/simple-table-empty-state';
import { SimpleFilterHelperAssignmentTemplate } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-assignment-template';
import { SimpleFilterHelperUser } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-user';
import { SimpleFilterHelperUserGroup } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-user-group';
import { SimpleFilterInput, SimpleFilterInputType } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-input-settings';
import { SimpleFilterListModalInput } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-list-modal-input-settings';
import { SimpleFilterInputItem } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-input-item';
import { SimpleRetainService } from 'app-core/shared-core/simple-components/list/table/filter/simple-retain.service';
import { SimpleTableHeaderAction, SimpleTableHeaderActionDelete } from 'app-core/shared-core/simple-components/list/table/head/simple-table-header-action';
import { TextContent } from 'app-core/shared-core/simple-components/various/text-content/simple-text-content.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { GroupOrAssigneeRulesObject, ViewObjectsComponent, ViewObjectsType } from 'app-core/shared-core/view-objects/view-objects.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SimpleFilterConfig, SortObject } from '../../../app-core/shared-core/simple-components/list/table/filter/simple-filter-config';
import { SimpleTableConfig } from '../../../app-core/shared-core/simple-components/list/table/simple-table-config';
import { CreateEditScheduleComponent } from '../create-edit-schedule/create-edit-schedule.component';
import { DeleteSchedulesComponent } from '../delete-schedules/delete-schedules.component';
import { Schedule } from '../schedule';
import { ScheduleFilter, ScheduleService } from '../schedule.service';
import { HandleScheduleGroupsAndScheduleUsersComponent } from './handle-schedule-groups-and-schedule-users/handle-schedule-groups-and-schedule-users.component';
import { SetStatusScheduleComponent } from './set-status/set-status-schedule.component';

@Component({
	selector: 'list-schedule',
	templateUrl: '../../../app-core/shared-core/simple-components/list/simple-list-shared-template.html',
	providers: [SimpleFilterHelperAssignmentTemplate, SimpleFilterHelperUserGroup, SimpleFilterHelperUser]
})
export class ListScheduleComponent extends SimpleListDirective<Schedule> {

	// Override filterObject to match backend.
	readonly filterObject = new ScheduleFilter();

	displayNewButton: boolean;

	componentRef: ComponentRef<ViewObjectsComponent>;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected sanitizer: DomSanitizer,
		protected scheduleService: ScheduleService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected retainService: SimpleRetainService,
		protected elementRef: ElementRef,
		protected viewContainerRef: ViewContainerRef,
		private simpleFilterHelperAssignmentTemplate: SimpleFilterHelperAssignmentTemplate,
		private simpleFilterHelperUserGroup: SimpleFilterHelperUserGroup,
		private simpleFilterHelperUser: SimpleFilterHelperUser) {
		super(
			authService,
			modalService,
			toastrService,
			translationService,
			scheduleService,
			route,
			router,
			retainService,
			elementRef,
			viewContainerRef,
			RoutesUtils.schedule
		);
	}

	protected configureListActions() {
		this.setListActions(
			new SimpleListAction(
				this.translationService.instant('Create'),
				this.translationService.instant('NewSchedule'),
				StringUtils.icons.new,
				() => {
					if (!this.isInModal) {
						this.setCrudParams(RoutesUtils.modalPrefixValueNew);
					} else {
						this.openModalByActionName(RoutesUtils.modalPrefixValueNew);
					}
				},
				() => {
					return !this.displayNewButton;
				}
			)
		);

	}

	protected async configureTableFilter(config: SimpleFilterConfig<Schedule>) {
		const assignmentTemplatesKey = StringUtils.ASSIGNMENT_TEMPLATES_KEY;
		const groupsKey = StringUtils.GROUPS_KEY;
		const assigneesKey = StringUtils.ASSIGNEES_KEY;
		const createdKey = StringUtils.CREATED_KEY;
		const updatedKey = StringUtils.UPDATED_KEY;
		const statusKey = StringUtils.STATUS_KEY;
		const hasAssignmentTemplatesKey = StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY;
		const hasGroupsKey = StringUtils.HAS_GROUPS_KEY;
		const hasAssigneesKey = StringUtils.HAS_ASSIGNEES_KEY;
		if (!this.isInModal) {
			this.retainService.setCurrentRetainEntries({
				search: null,
				sort: null,
				[assignmentTemplatesKey]: null,
				[groupsKey]: null,
				[assigneesKey]: null,
				[createdKey]: null,
				[updatedKey]: null,
				[statusKey]: null,
				[hasAssignmentTemplatesKey]: null,
				[hasGroupsKey]: null,
				[hasAssigneesKey]: null
			});
		}

		try {
			const [assignmentTemplates, userGroups, users] = await Promise.all([
				this.scheduleService.getAssignmentTemplates(this.selectedOrganization.id),
				this.scheduleService.getUserGroups(this.selectedOrganization.id),
				this.scheduleService.getUsers(this.selectedOrganization.id)
			]);

			const convertedAssignmentTemplates = assignmentTemplates.map(assignmentTemplate => new SimpleFilterInputItem(assignmentTemplate.id, assignmentTemplate.name));
			const convertedUserGroups = userGroups.map(userGroup => new SimpleFilterInputItem(userGroup.id, userGroup.name));
			const convertedUsers = users.map(user => new SimpleFilterInputItem(user.id, user.email));

			this.displayNewButton = !!assignmentTemplates.length;

			config.setFilterInputs(
				new SimpleFilterInput(
					createdKey,
					this.translationService.instant(createdKey),
					SimpleFilterInputType.DateSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(createdKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(createdKey, ''));
						}
						return keyValuePairs;
					}
				),
				new SimpleFilterInput(
					updatedKey,
					this.translationService.instant(updatedKey),
					SimpleFilterInputType.DateSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(updatedKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(updatedKey, ''));
						}
						return keyValuePairs;
					}
				),
				new SimpleFilterListModalInput(
					assignmentTemplatesKey,
					this.translationService.instant(assignmentTemplatesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(assignmentTemplatesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(assignmentTemplatesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperAssignmentTemplate.openListModal(filterInput, assignmentTemplates, this);
					},
					convertedAssignmentTemplates
				),
				new SimpleFilterInput(
					hasAssignmentTemplatesKey,
					this.translationService.instant(hasAssignmentTemplatesKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(hasAssignmentTemplatesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(hasAssignmentTemplatesKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.YES,
						this.translationService.instant(StringUtils.YES)
					),
					new SimpleFilterInputItem(
						StringUtils.NO,
						this.translationService.instant(StringUtils.NO)
					)
				),
				new SimpleFilterListModalInput(
					groupsKey,
					this.translationService.instant(groupsKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(groupsKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(groupsKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperUserGroup.openListModal(filterInput, userGroups, this);
					},
					convertedUserGroups
				),
				new SimpleFilterInput(
					hasGroupsKey,
					this.translationService.instant(hasGroupsKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(hasGroupsKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(hasGroupsKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.YES,
						this.translationService.instant(StringUtils.YES)
					),
					new SimpleFilterInputItem(
						StringUtils.NO,
						this.translationService.instant(StringUtils.NO)
					)
				),
				new SimpleFilterListModalInput(
					assigneesKey,
					this.translationService.instant(assigneesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(assigneesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(assigneesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperUser.openListModal(filterInput, users, this);
					},
					convertedUsers
				),
				new SimpleFilterInput(
					hasAssigneesKey,
					this.translationService.instant(hasAssigneesKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(hasAssigneesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(hasAssigneesKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.YES,
						this.translationService.instant(StringUtils.YES)
					),
					new SimpleFilterInputItem(
						StringUtils.NO,
						this.translationService.instant(StringUtils.NO)
					)
				),
				new SimpleFilterInput(
					statusKey,
					this.translationService.instant(statusKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(statusKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(statusKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.ACTIVE,
						this.translationService.instant(StringUtils.ACTIVE)
					),
					new SimpleFilterInputItem(
						StringUtils.INACTIVE,
						this.translationService.instant(StringUtils.INACTIVE)
					)
				)
			);

			if (!this.isInModal) {
				// Needs to be called here due to the data fetch delay.
				this.setSelectedFilterItems();
			}

		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}

	protected configureTableSort(config: SimpleFilterConfig<Schedule>) {
		config.setSortObjects(
			new SortObject(
				this.propertyStrings.name,
				this.translationService.instant('Name'),
				true
			),
			new SortObject(
				this.propertyStrings.created,
				this.translationService.instant('Created')
			),
			new SortObject(
				this.propertyStrings.updated,
				this.translationService.instant('Updated')
			),
			new SortObject(
				this.propertyStrings.active,
				this.translationService.instant('Status')
			)
		);
	}

	protected configureTableColumns(config: SimpleTableConfig<Schedule>) {
		config.setColumns(
			new SimpleTableTextColumn(
				this.translationService.instant('Name'),
				(item) => new TextContent(
					item.name,
					'',
					'',
					''
				),
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTablePublishDateColumn(
				this.translationService.instant('NextPublication'),
				(item) => item,
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableEventsColumn(
				this.translationService.instant('Events'),
				(item) => [
					new EventsContent(`${this.translationService.instant('Created')} ${Utils.getFormattedDateStringFromString(item.created, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.created, 'bi-file-earmark-plus'),
					Utils.dateIsAfter(item.updated, item.created)
						? new EventsContent(`${this.translationService.instant('Updated')} ${Utils.getFormattedDateStringFromString(item.updated, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.updated, 'bi-file-earmark-text')
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableIconsColumn(
				'',
				(item) => [
					item.assignmentTemplates.length
						? new IconContent('bi-map', item.assignmentTemplates.length.toString(), `${this.translationService.instant('AssignmentTemplates')}: ${item.assignmentTemplates.length.toString()}`)
						: null,
					item.scheduleGroups.length
						? new IconContent('bi-people', item.scheduleGroups.length.toString(), `${this.translationService.instant('Groups')}: ${item.scheduleGroups.length.toString()}`)
						: null,
					item.scheduleUsers.length
						? new IconContent('bi-person', item.scheduleUsers.length.toString(), `${this.translationService.instant('Assignees')}: ${item.scheduleUsers.length.toString()}`)
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableStatusColumn(
				this.translationService.instant('Status'),
				(item) => item.active ?
					StringUtils.ACTIVE
					: StringUtils.INACTIVE,
				this.sanitizer,
				this.viewContainerRef,
				''
			)
		);
	}

	protected configureTableActions(config: SimpleTableConfig<Schedule>) {

		// HEADER
		config.setHeaderActions(
			new SimpleTableHeaderAction(
				this.translationService.instant('HandleGroupsAndAssignees'),
				StringUtils.icons.group,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueBulkHandleScheduleGroupsAndScheduleUsers}_${selectedIds.join()}`);
				}
			),
			new SimpleTableHeaderAction(
				this.translationService.instant('SetStatus'),
				StringUtils.icons.setStatus,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueBulkSetStatus}_${selectedIds.join()}`);
				}
			),
			new SimpleTableHeaderActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${selectedIds.join()}`);
				}
			)
		);

		// ROW
		config.setRowActions(
			new SimpleTableRowActionEdit(
				this.translationService.instant('Open'),
				StringUtils.icons.handle,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					}
				}
			),
			new SimpleTableRowActionKeepOpen(
				this.translationService.instant('ViewAssignmentTemplates'),
				StringUtils.icons.assignmentTemplate,
				(row) => {
					this.setAsAffected([row.id]);

					this.prepareViewObjectsPopover(row.data, ViewObjectsType.assignmentTemplate, 1, row.id);
				},
				(row) => {
					return !row.data.assignmentTemplates.length;
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('HandleGroupsAndAssignees'),
				StringUtils.icons.group,
				(row) => {
					this.setAsAffected([row.id]);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueHandleScheduleGroupsAndScheduleUsers}_${row.id}`);
				},
				(row) => {
					return !row.data.scheduleGroups.length && !row.data.scheduleUsers.length;
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('SetStatus'),
				StringUtils.icons.setStatus,
				(row) => {
					this.setAsAffected([row.id]);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueSetStatus}_${row.id}`);
				}
			),

			// new SimpleTableRowActionKeepOpen(
			// 	this.translationService.instant('ViewGroupsAndAssignees'),
			// 	StringUtils.icons.group,
			// 	(row) => {
			// 		this.setAsAffected([row.id]);

			// 		const assignmentTemplatesExists = row.data.assignmentTemplates.length > 0;
			// 		this.prepareViewObjectsPopover(row.data, ViewObjectsType.groupsAndUsers, assignmentTemplatesExists ? 2 : 1, row.id);
			// 	},
			// 	(row) => {
			// 		return !row.data.scheduleGroups.length && !row.data.scheduleUsers.length;
			// 	}
			// ),
			new SimpleTableRowActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					}
				}
			)
		);
	}

	protected configureTableEmptyState(config: SimpleTableConfig<Schedule>) {
		config.emptyState = new SimpleTableEmptyState(
			'Schedules',
			StringUtils.icons.schedule
		);
	}

	protected setPropertiesAndOpenViewObjectsPopover(item: Schedule, type: ViewObjectsType, rowClickNumber: number, rowId: string) {
		this.componentRef = this.viewContainerRef.createComponent(ViewObjectsComponent);
		this.componentRef.instance.rowId = rowId;
		this.componentRef.instance.type = type;

		switch (type) {
			case ViewObjectsType.groupsAndUsers:
				this.componentRef.instance.groups = item.scheduleGroups.map(group => group.userGroup);
				this.componentRef.instance.users = item.scheduleUsers.map(user => user.user);
				this.componentRef.instance.groupRules = item.scheduleGroups.map(sg => new GroupOrAssigneeRulesObject({ id: sg.userGroup.id, rules: sg.rulesReadonlyInSchedule }))
				this.componentRef.instance.assigneeRules = item.scheduleUsers.filter(su => su.canPerformMeasure).map(su => new GroupOrAssigneeRulesObject({ id: su.user.id, rules: su.rulesReadonlyInSchedule }));
				this.componentRef.instance.triggerViewChanges();
				this.openViewObjectsPopover(this.componentRef, rowClickNumber, rowId, StringUtils.icons.group);
				break;
			case ViewObjectsType.assignmentTemplate:
				this.componentRef.instance.assignmentTemplates = item.assignmentTemplates;
				this.componentRef.instance.triggerViewChanges();
				this.openViewObjectsPopover(this.componentRef, rowClickNumber, rowId, StringUtils.icons.assignmentTemplate);
				break;
		}
	}

	protected openModalByActionName(value: string) {
		const [actionName, idString] = value.split('_');
		this.shouldClearAfterUpdate = !this.isInModal && this.isBulkAction(actionName);
		if (actionName === RoutesUtils.modalPrefixValueNew) {
			this.bsModalRef = this.modalService.show(
				CreateEditScheduleComponent,
				{
					initialState: {
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_X_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueEdit) {
			this.bsModalRef = this.modalService.show(
				CreateEditScheduleComponent,
				{
					initialState: {
						editModelId: idString,
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_X_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueBulkHandleScheduleGroupsAndScheduleUsers || actionName === RoutesUtils.modalPrefixValueHandleScheduleGroupsAndScheduleUsers) {
			this.bsModalRef = this.modalService.show(
				HandleScheduleGroupsAndScheduleUsersComponent,
				{
					initialState: {
						editModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_X_LARGE
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueBulkSetStatus || actionName === RoutesUtils.modalPrefixValueSetStatus) {
			this.bsModalRef = this.modalService.show(
				SetStatusScheduleComponent,
				{
					initialState: {
						editModelIds: idString.split(','),
						useAffectableIds: true
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueDelete) {
			this.bsModalRef = this.modalService.show(
				DeleteSchedulesComponent,
				{
					initialState: {
						deleteModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_SMALL
				}
			);
		}
		this.subscribeToCrudModalContent();
	}

	protected import(file: File) { }
	protected export(selectedIds: string[]) { }
}
