<simple-crud-modal
	[titleText]="(isMeasure ? 'ErrorReport' : 'SummaryReport') | translate"
	[closeText]="'Close' | translate"
	[submitText]="(tooLargeForDownload || sendReportByEmail ? 'Send' : 'Download') | translate"
	[disableSubmit]="!everythingIsValid() || !affectableIdsAreValid()"
	[isLoading]="pending"
	[displayDummy]="!initialModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="initialModel">
			<tab [customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-card-text'"
							[title]="'Details' | translate"
							[helpText]="isMeasure ? ('GenerateErrorReportHelpText' | translate) : ('GenerateReportHelpText' | translate)">
						</simple-tab-heading>
					</ng-template>
					<generate-report-tab
						[changeModel]="initialModel"
						[selectedIdsCount]="editModelIds.length"
						[subscribers]="subscribers"
						[tooLargeForDownload]="tooLargeForDownload"
						[sendReportByEmail]="sendReportByEmail"
						(onValueChange)="handleValueChanges()">
					</generate-report-tab>
			</tab>
		</tabset>
</simple-crud-modal>
