
import { Injectable } from '@angular/core';
import { Filter } from 'app-core/shared-core/filter';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { BaseService } from '../../app-core/shared-core/abstract-components/service/base.service';
import { Facility } from './facility';

@Injectable()
export class FacilityService extends BaseService<Facility> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
		super(
			hostedHttpClient,
			RoutesUtils.facility
		);
	}

	updateEntities(facilityId: string, itemIds: string[], assignmentTemplateIds: string[] = [], entityIdsToRemove: string[]) {
		const model = {
			entities: [],
			entityIdsToRemove: entityIdsToRemove
		}
		itemIds.forEach(id => model.entities.push(
			{
				entityId: id,
				assignmentTemplateIds: assignmentTemplateIds
			}
		))
		return this.hostedHttpClient.put(`${this.prefix}/${facilityId}/entity`, model);
	}

	updateAccessibility(itemIds: string[], accessible: boolean) {
		const model = {
			itemIds: itemIds,
			accessible: accessible
		};
		return this.hostedHttpClient.put(`${this.prefix}/accessible`, model, this.useCoreApi);
	}

	getAssignmentTemplatesWithEntities(facilityId: string) {
		return this.hostedHttpClient.get(`${this.prefix}/${facilityId}/assignmentTemplatesWithEntities`, { facilityId: facilityId }, this.useCoreApi)
			.then(res => res.data);
	}

	deleteFacility(id: string | number, shouldDeleteEntities: boolean) {
		const model = {
			shouldDeleteEntities: shouldDeleteEntities
		}
		return this.hostedHttpClient.delete(`${this.prefix}/${id}`, model, this.useCoreApi);
	}

	deleteFacilities(itemIds: string[], shouldDeleteEntities: boolean) {
		const model = {
			itemIds: itemIds,
			shouldDeleteEntities: shouldDeleteEntities
		}
		return this.hostedHttpClient.delete(`${this.prefix}`, model, this.useCoreApi);
	}

	instantiateModel(item: Facility) {
		return new Facility(item);
	}
}

export class FacilityFilter extends Filter {
	toPayloadObject() {
		const tempFacets = {};
		const currentFacets = (Object.entries(this.facets) as [string, string][])
			.map(facet => {
				return {
					key: facet[0],
					value: facet[1]
				};
			});

		currentFacets.forEach(facet => {
			if (facet.key === StringUtils.TEMPLATE_TYPES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.TEMPLATE_TYPE_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.TEMPLATE_TYPE_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.HAS_SCHEDULES_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.HAS_SCHEDULES_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.HAS_SCHEDULES_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.LOCATION_DATA_KEY) {
				const coordinates = facet.value;
				if (coordinates === StringUtils.YES) {
					tempFacets[StringUtils.HAS_POSITION_KEY] = 'true';
				} else if (coordinates === StringUtils.NO) {
					tempFacets[StringUtils.HAS_POSITION_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.HAS_POSITION_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.STATUS_KEY) {
				const status = facet.value;
				if (status === StringUtils.ACTIVE) {
					tempFacets[StringUtils.STATUS_KEY] = 'true';
				} else if (status === StringUtils.INACTIVE) {
					tempFacets[StringUtils.STATUS_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.ACCESSIBILITY_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.ACCESSIBLE_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.ACCESSIBLE_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.ACCESSIBLE_KEY) {
				delete this.facets[facet.key];
			}
		});

		Object.assign(this.facets, tempFacets);

		return {
			filter: JSON.stringify(this)
		};
	}
}
