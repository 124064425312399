import { Component, Input } from '@angular/core';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { SimpleReadModalDirective } from '../../crud/modal/simple-read-modal.directive';
import { SimpleListDirective } from '../simple-list.directive';

@Component({
	selector: 'simple-list-modal',
	templateUrl: './simple-list-modal.component.html'
})
export class SimpleListModalComponent<T extends { id: string, url: string, selected: boolean }> extends SimpleReadModalDirective<T> {

	@Input() listReference: SimpleListDirective<T>;

	private allData: T[] = [];

	hasConfirmed = false;

	override close() {
		if (!this.listReference.closed$.value) {
			this.listReference.closed$.next(true);
		}
	}

	async confirm() {
		if (!this.hasConfirmed) {
			this.hasConfirmed = true;
			this.listReference.pending = true;
			const allSelectedRowsExist = Array.from(this.listReference.selectedIds.keys())
				.every(id => this.listReference.cachedRows
					.find(row => row.id === id));

			if (allSelectedRowsExist) {
				const selectedData = this.listReference.cachedRows
					.filter(row => this.listReference.selectedIds.has(row.id))
					.map(row => row.data);
				this.reallyConfirm(selectedData);
			} else {
				await this.getAllData();
				const selectedData = this.allData
					.filter(data => this.listReference.selectedIds.has(data.id));
				this.reallyConfirm(selectedData);
			}
		}
	}

	private reallyConfirm(selectedData: T[]) {
		this.listReference.confirmed$.next(selectedData);
	}

	private async getAllData() {
		this.listReference.pending = true;
		this.listReference.filterObject.resetPage();
		this.listReference.filterObject.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		this.listReference.filterObject.clearSearch();
		this.listReference.filterObject.clearFacets();
		this.listReference.applyInitialFacets();
		try {
			this.allData = await this.listReference.service.getFiltered(this.listReference.filterObject);
			this.listReference.pending = false;

		} catch (errorResponse) {
			this.listReference.handleErrorResponse(errorResponse);
		}
	}
}
