import { ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ColorUtils } from 'app-core/shared-core/tools/color-utils';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { Schedule } from 'app-inspection/schedule/schedule';
import { SimpleTableColumn } from './simple-table-column';

export class SimpleTablePublishDateColumn<T> extends SimpleTableColumn<T> {

	readonly getSchedule: (item: T) => Schedule;

	constructor(
		label: string,
		getSchedule: (item: T) => Schedule,
		sanitizer: DomSanitizer,
		viewContainerRef: ViewContainerRef,
		secondaryLabel: string) {
		super(
			label,
			sanitizer,
			viewContainerRef,
			secondaryLabel
		);
		this.getSchedule = getSchedule;
		this.maxWidth = '150px';
		this.useDivSwitching = true;
	}

	renderValue(item: T) {
		const schedule = this.getSchedule(item);

		const wrapper = document.createElement('div');
		wrapper.classList.add('publish-date-wrapper');

		const publishDateDiv = document.createElement('div');

		if (schedule.nextPublishDate) {
			if (schedule.active) {
				publishDateDiv.innerHTML = Utils.convertToHumanDate(schedule.nextPublishDate);
				publishDateDiv.innerHTML = publishDateDiv.innerHTML.charAt(0).toUpperCase() + publishDateDiv.innerHTML.slice(1);
				publishDateDiv.title = Utils.getFormattedDateStringFromString(schedule.nextPublishDate, DEFAULT_DISPLAY_DATE_FORMAT);
			} else {
				publishDateDiv.style.color = ColorUtils.RED_COLOR;
				publishDateDiv.innerHTML = Utils.getFormattedDateStringFromString(schedule.nextPublishDate, DEFAULT_DISPLAY_DATE_FORMAT);
				publishDateDiv.title = Utils.getFormattedDateStringFromString(schedule.nextPublishDate, DEFAULT_DISPLAY_DATE_FORMAT);
			}
			if (Utils.isTodayDate(schedule.nextPublishDate) && Utils.isFutureDate(schedule.nextPublishDate)) {
				publishDateDiv.style.fontWeight = '600';
			}
		}

		publishDateDiv.style.overflow = 'hidden';
		publishDateDiv.style.textOverflow = 'ellipsis';

		wrapper.append(publishDateDiv);

		return this.sanitizer.bypassSecurityTrustHtml(wrapper.outerHTML);
	}
}
