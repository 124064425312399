import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { User } from 'app-core/user/user';
import { UserService } from 'app-core/user/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ResendEmailTabComponent } from './tabs/resend-email-tab.component';

@Component({
	templateUrl: './resend-email.component.html'
})
export class ResendEmailComponent extends SimpleCreateEditModalDirective<User> implements OnInit {

	isLoggedInUser: boolean = false;

	@ViewChild(ResendEmailTabComponent) resendEmailTabComponent: ResendEmailTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private userService: UserService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.isLoggedInUser = this.editModelId === this.loggedInUser.id;

		this.pending = true;
		try {
			this.initialModel = await this.userService.get(this.editModelId);
			this.pending = false;

			this.initialModel.inviteDate = this.initialModel.getCurrentInviteDate(this.selectedOrganization.id);

			setTimeout(() => {
				this.triggerAllValidation();
			}, 0);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	changeDetailsIsValid() {
		return this.resendEmailTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.resendEmailTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	override submit() {
		if (this.everythingIsValid()) {
			this.serverErrors = [];
			this.createOrUpdate();
		} else {
			this.triggerAllValidation();
		}
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = await this.userService.resendInvite(this.initialModel.toPayloadObject(this.selectedOrganization.id, this.isLoggedInUser), this.selectedOrganization.id);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.resendEmailTabComponent.setServerErrors(this.serverErrors);
			}
		}
	}

	protected triggerAllValidation() {
		this.resendEmailTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: User) {
		return new User(item);
	}
}
