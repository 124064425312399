import { Component, ComponentRef, ElementRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { SimpleListAction } from 'app-core/shared-core/simple-components/list/actions/simple-list-action';
import { SimpleListDirective } from 'app-core/shared-core/simple-components/list/simple-list.directive';
import { SimpleTableRowAction, SimpleTableRowActionDelete, SimpleTableRowActionEdit, SimpleTableRowActionKeepOpen } from 'app-core/shared-core/simple-components/list/table/body/simple-table-row-action';
import { SimpleTableCategoriesColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-categories-column';
import { EventsContent, SimpleTableEventsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-events-column';
import { IconContent, SimpleTableIconsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-icons-column';
import { SimpleTableStatusColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-status-column';
import { SimpleTableTextColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-text-column';
import { SimpleTableEmptyState } from 'app-core/shared-core/simple-components/list/table/empty-state/simple-table-empty-state';
import { SimpleFilterHelperFacility } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-facility';
import { SimpleFilterHelperTemplateType } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-template-type';
import { SimpleFilterInput, SimpleFilterInputType } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-input-settings';
import { SimpleFilterListModalInput } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-list-modal-input-settings';
import { SimpleFilterInputItem } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-input-item';
import { SimpleRetainService } from 'app-core/shared-core/simple-components/list/table/filter/simple-retain.service';
import { SimpleTableHeaderAction, SimpleTableHeaderActionDelete } from 'app-core/shared-core/simple-components/list/table/head/simple-table-header-action';
import { TextContent } from 'app-core/shared-core/simple-components/various/text-content/simple-text-content.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { ViewObjectsComponent, ViewObjectsType } from 'app-core/shared-core/view-objects/view-objects.component';
import { ListScheduleComponent } from 'app-inspection/schedule/list-schedule/list-schedule.component';
import { Schedule } from 'app-inspection/schedule/schedule';
import { environment } from 'environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { SimpleFilterConfig, SortObject } from '../../../app-core/shared-core/simple-components/list/table/filter/simple-filter-config';
import { SimpleTableConfig } from '../../../app-core/shared-core/simple-components/list/table/simple-table-config';
import { AssignmentTemplate } from '../assignment-template';
import { AssignmentTemplateFilter, AssignmentTemplateService } from '../assignment-template.service';
import { CreateEditAssignmentTemplateComponent } from '../create-edit-assignment-template/create-edit-assignment-template.component';
import { DeleteAssignmentTemplatesComponent } from '../delete-assignment-templates/delete-assignment-templates.component';
import { HandleCategoriesComponent } from './handle-categories/handle-categories.component';
import { HandleClientComponent } from './handle-client/handle-client.component';
import { SetGeoFencingComponent } from './set-geo-fencing/set-geo-fencing.component';

@Component({
	selector: 'list-assignment-template',
	templateUrl: '../../../app-core/shared-core/simple-components/list/simple-list-shared-template.html',
	providers: [SimpleFilterHelperTemplateType, SimpleFilterHelperFacility]
})
export class ListAssignmentTemplateComponent extends SimpleListDirective<AssignmentTemplate> {

	// Override filterObject to match backend.
	readonly filterObject = new AssignmentTemplateFilter();

	displayNewButton: boolean;

	bsModalRef: BsModalRef<any>;
	listScheduleModal: ListScheduleComponent;

	componentRef: ComponentRef<ViewObjectsComponent>;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected sanitizer: DomSanitizer,
		protected assignmentTemplateService: AssignmentTemplateService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected retainService: SimpleRetainService,
		protected elementRef: ElementRef,
		protected viewContainerRef: ViewContainerRef,
		private simpleFilterHelperTemplateType: SimpleFilterHelperTemplateType,
		private simpleFilterHelperFacility: SimpleFilterHelperFacility) {
		super(
			authService,
			modalService,
			toastrService,
			translationService,
			assignmentTemplateService,
			route,
			router,
			retainService,
			elementRef,
			viewContainerRef,
			RoutesUtils.assignmentTemplate
		);
	}

	protected configureListActions() {
		this.setListActions(
			new SimpleListAction(
				this.translationService.instant('Create'),
				this.translationService.instant('NewAssignmentTemplate'),
				StringUtils.icons.new,
				() => {
					if (!this.isInModal) {
						this.setCrudParams(RoutesUtils.modalPrefixValueNew);
					} else {
						this.openModalByActionName(RoutesUtils.modalPrefixValueNew);
					}
				},
				() => {
					return !this.displayNewButton;
				}
			)
		);
	}

	protected async configureTableFilter(config: SimpleFilterConfig<AssignmentTemplate>) {
		config.hideSearch = true;

		const templateTypesKey = StringUtils.TEMPLATE_TYPES_KEY;
		const categoriesKey = StringUtils.CATEGORIES_KEY;
		const clientsKey = StringUtils.CLIENTS_KEY;
		const geoFencingKey = StringUtils.GEO_FENCING_KEY;
		const facilitiesKey = StringUtils.FACILITIES_KEY;
		const schedulesKey = StringUtils.SCHEDULES_KEY;
		const createdKey = StringUtils.CREATED_KEY;
		const updatedKey = StringUtils.UPDATED_KEY;
		const statusKey = StringUtils.STATUS_KEY;
		const hasSchedulesKey = StringUtils.HAS_SCHEDULES_KEY;
		if (!this.isInModal) {
			this.retainService.setCurrentRetainEntries({
				search: null,
				sort: null,
				[templateTypesKey]: null,
				[categoriesKey]: null,
				[clientsKey]: null,
				[geoFencingKey]: null,
				[facilitiesKey]: null,
				[schedulesKey]: null,
				[createdKey]: null,
				[updatedKey]: null,
				[statusKey]: null,
				[hasSchedulesKey]: null
			});
		}

		try {
			const [templateTypes, categories, clients, facilities, schedules] = await Promise.all([
				this.assignmentTemplateService.getTemplateTypes(this.selectedOrganization.id),
				this.assignmentTemplateService.getCategories(this.selectedOrganization.id),
				this.assignmentTemplateService.getClients(this.selectedOrganization.id),
				this.assignmentTemplateService.getFacilities(),
				this.assignmentTemplateService.getSchedules(this.selectedOrganization.id)
			]);

			const convertedTemplateTypes = templateTypes.map(templateType => new SimpleFilterInputItem(templateType.id, templateType.name));
			const convertedCategories = categories.map(category => new SimpleFilterInputItem(category.id, category.name));
			const convertedClients = clients.map(client => new SimpleFilterInputItem(client.id, `${client.name} (${client.email})`));
			const convertedFacilities = facilities.map(facility => new SimpleFilterInputItem(facility.id, facility.name));
			const convertedSchedules = schedules.map(schedule => new SimpleFilterInputItem(schedule.id, schedule.name));

			this.displayNewButton = templateTypes.some(tt => !tt.isManualMeasure) && !!facilities.length && this.loggedInUser.isSuperAdmin();

			config.setFilterInputs(
				new SimpleFilterListModalInput(
					templateTypesKey,
					this.translationService.instant(templateTypesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(templateTypesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(templateTypesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperTemplateType.openListModal(filterInput, templateTypes, this);
					},
					convertedTemplateTypes
				),
				new SimpleFilterListModalInput(
					facilitiesKey,
					this.translationService.instant(facilitiesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(facilitiesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(facilitiesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperFacility.openListModal(filterInput, facilities, this);
					},
					convertedFacilities
				),
				new SimpleFilterInput(
					categoriesKey,
					this.translationService.instant(categoriesKey),
					SimpleFilterInputType.MultiSelectWithSearch,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(categoriesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(categoriesKey, ''));
						}
						return keyValuePairs;
					},
					...convertedCategories
				),
				new SimpleFilterInput(
					clientsKey,
					this.translationService.instant(clientsKey),
					SimpleFilterInputType.MultiSelectWithSearch,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(clientsKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(clientsKey, ''));
						}
						return keyValuePairs;
					},
					...convertedClients
				),
				new SimpleFilterInput(
					createdKey,
					this.translationService.instant(createdKey),
					SimpleFilterInputType.DateSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(createdKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(createdKey, ''));
						}
						return keyValuePairs;
					}
				),
				new SimpleFilterInput(
					updatedKey,
					this.translationService.instant(updatedKey),
					SimpleFilterInputType.DateSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(updatedKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(updatedKey, ''));
						}
						return keyValuePairs;
					}
				),
				new SimpleFilterInput(
					geoFencingKey,
					this.translationService.instant(geoFencingKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(geoFencingKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(geoFencingKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.YES,
						this.translationService.instant(StringUtils.YES)
					),
					new SimpleFilterInputItem(
						StringUtils.NO,
						this.translationService.instant(StringUtils.NO)
					)
				),
				new SimpleFilterListModalInput(
					schedulesKey,
					this.translationService.instant(schedulesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(schedulesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(schedulesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.openListScheduleModal(filterInput, schedules, this);
					},
					convertedSchedules
				),
				new SimpleFilterInput(
					hasSchedulesKey,
					this.translationService.instant(hasSchedulesKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(hasSchedulesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(hasSchedulesKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.YES,
						this.translationService.instant(StringUtils.YES)
					),
					new SimpleFilterInputItem(
						StringUtils.NO,
						this.translationService.instant(StringUtils.NO)
					)
				),
				new SimpleFilterInput(
					statusKey,
					this.translationService.instant(statusKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(statusKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(statusKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.ACTIVE,
						this.translationService.instant(StringUtils.ACTIVE)
					),
					new SimpleFilterInputItem(
						StringUtils.INACTIVE,
						this.translationService.instant(StringUtils.INACTIVE)
					)
				)
			);

			if (!this.isInModal) {
				// Needs to be called here due to the data fetch delay.
				this.setSelectedFilterItems();
			}

		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}

	protected configureTableSort(config: SimpleFilterConfig<AssignmentTemplate>) {
		config.setSortObjects(
			new SortObject(
				'TemplateType.Name' as keyof AssignmentTemplate,
				this.translationService.instant('TemplateType')
			),
			new SortObject(
				'Facility.Name' as keyof AssignmentTemplate,
				this.translationService.instant('Facility'),
				true
			),
			new SortObject(
				this.propertyStrings.created,
				this.translationService.instant('Created')
			),
			new SortObject(
				this.propertyStrings.updated,
				this.translationService.instant('Updated')
			),
			new SortObject(
				this.propertyStrings.geoControlled,
				this.translationService.instant('GeoFencing')
			)
		);
	}

	protected configureTableColumns(config: SimpleTableConfig<AssignmentTemplate>) {
		config.setColumns(
			new SimpleTableTextColumn(
				this.translationService.instant('TemplateType'),
				(item) => new TextContent(
					item.templateType?.name,
					item.facility?.name,
					item.templateType && this.loggedInUser.isSuperAdmin()
						? `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${item.templateType.url}`
						: '',
					item.facility
						? `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${item.facility.url}`
						: ''
				),
				this.sanitizer,
				this.viewContainerRef,
				this.translationService.instant('Facility')
			),
			new SimpleTableCategoriesColumn(
				this.translationService.instant('Categories'),
				(item) => item.categories,
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableTextColumn(
				this.translationService.instant('Client'),
				(item) => new TextContent(
					item.client?.name,
					'',
					'',
					''
				),
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableEventsColumn(
				this.translationService.instant('Events'),
				(item) => [
					new EventsContent(`${this.translationService.instant('Created')} ${Utils.getFormattedDateStringFromString(item.created, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.created, 'bi-file-earmark-plus'),
					Utils.dateIsAfter(item.updated, item.created)
						? new EventsContent(`${this.translationService.instant('Updated')} ${Utils.getFormattedDateStringFromString(item.updated, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.updated, 'bi-file-earmark-text')
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableIconsColumn(
				'',
				(item) => [
					item.geoControlled
						? new IconContent('bi-bounding-box-circles', '', this.translationService.instant('Geofencing'))
						: null,
					item.schedules.length
						? new IconContent('bi-calendar-week', item.schedules.length.toString(), `${this.translationService.instant('Schedules')}: ${item.schedules.length.toString()}`)
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableStatusColumn(
				this.translationService.instant('Status'),
				(item) => item.hasActiveTemplateObjects ?
					StringUtils.ACTIVE
					: StringUtils.INACTIVE,
				this.sanitizer,
				this.viewContainerRef,
				''
			)
		);
	}

	protected configureTableActions(config: SimpleTableConfig<AssignmentTemplate>) {

		// HEADER
		config.setHeaderActions(
			new SimpleTableHeaderAction(
				this.translationService.instant('HandleCategories'),
				StringUtils.icons.category,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueBulkHandleCategories}_${selectedIds.join()}`);
				}
			),
			new SimpleTableHeaderAction(
				this.translationService.instant('HandleClient'),
				StringUtils.icons.client,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueBulkHandleClient}_${selectedIds.join()}`);
				}
			),
			new SimpleTableHeaderAction(
				this.translationService.instant('SetGeoFencing'),
				StringUtils.icons.geo,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueBulkSetGeoFencing}_${selectedIds.join()}`);
				}
			),
			new SimpleTableHeaderActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${selectedIds.join()}`);
				}
			)
		);

		// ROW
		config.setRowActions(
			new SimpleTableRowActionEdit(
				this.translationService.instant('Open'),
				StringUtils.icons.handle,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					}
				}
			),
			new SimpleTableRowActionKeepOpen(
				this.translationService.instant('ViewSchedules'),
				StringUtils.icons.schedule,
				(row) => {
					this.setAsAffected([row.id]);

					this.prepareViewObjectsPopover(row.data, ViewObjectsType.schedule, 2, row.id)
				},
				(row) => {
					return !row.data.schedules.length;
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('HandleCategories'),
				StringUtils.icons.category,
				(row) => {
					this.setAsAffected([row.id]);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueHandleCategories}_${row.id}`);
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('HandleClient'),
				StringUtils.icons.client,
				(row) => {
					this.setAsAffected([row.id]);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueHandleClient}_${row.id}`);
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('SetGeoFencing'),
				StringUtils.icons.geo,
				(row) => {
					this.setAsAffected([row.id]);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueSetGeoFencing}_${row.id}`);
				}
			),
			new SimpleTableRowActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					}
				}
			)
		);
	}

	protected configureTableEmptyState(config: SimpleTableConfig<AssignmentTemplate>) {
		config.emptyState = new SimpleTableEmptyState(
			'AssignmentTemplates',
			StringUtils.icons.assignmentTemplate
		);
	}

	protected setPropertiesAndOpenViewObjectsPopover(item: AssignmentTemplate, type: ViewObjectsType, rowClickNumber: number, rowId: string) {
		this.componentRef = this.viewContainerRef.createComponent(ViewObjectsComponent);
		this.componentRef.instance.rowId = rowId;
		this.componentRef.instance.type = type;

		switch (type) {
			case ViewObjectsType.schedule:
				this.componentRef.instance.schedules = item.schedules;
				this.componentRef.instance.triggerViewChanges();
				this.openViewObjectsPopover(this.componentRef, rowClickNumber, rowId, StringUtils.icons.schedule);
				break;
		}
	}

	protected openModalByActionName(value: string) {
		const [actionName, idString] = value.split('_');
		this.shouldClearAfterUpdate = !this.isInModal && this.isBulkAction(actionName);
		if (actionName === RoutesUtils.modalPrefixValueNew) {
			this.bsModalRef = this.modalService.show(
				CreateEditAssignmentTemplateComponent,
				{
					initialState: {
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_X_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueEdit) {
			this.bsModalRef = this.modalService.show(
				CreateEditAssignmentTemplateComponent,
				{
					initialState: {
						editModelId: idString,
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_X_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueBulkHandleCategories || actionName === RoutesUtils.modalPrefixValueHandleCategories) {
			this.bsModalRef = this.modalService.show(
				HandleCategoriesComponent,
				{
					initialState: {
						editModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueBulkHandleClient || actionName === RoutesUtils.modalPrefixValueHandleClient) {
			this.bsModalRef = this.modalService.show(
				HandleClientComponent,
				{
					initialState: {
						editModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueBulkSetGeoFencing || actionName === RoutesUtils.modalPrefixValueSetGeoFencing) {
			this.bsModalRef = this.modalService.show(
				SetGeoFencingComponent,
				{
					initialState: {
						editModelIds: idString.split(','),
						useAffectableIds: true
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueDelete) {
			this.bsModalRef = this.modalService.show(
				DeleteAssignmentTemplatesComponent,
				{
					initialState: {
						deleteModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_SMALL
				}
			);
		}
		this.subscribeToCrudModalContent();
	}

	private openListScheduleModal(filterInput: SimpleFilterListModalInput, items: Schedule[], listReference: SimpleListDirective<any>) {
		const currentSelectedItems = this.getCurrentSelecteditems(filterInput, items);
		this.bsModalRef = this.modalService.show(
			ListScheduleComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: true,
					hideTableHeaderActions: true,
					hideTableRowActions: true,
					idsToSetAsSelected: currentSelectedItems.map(item => item.id)
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToListScheduleModalContent(filterInput, listReference);
	}

	protected subscribeToListScheduleModalContent(filterInput: SimpleFilterListModalInput, listReference: SimpleListDirective<Schedule>) {
		this.listScheduleModal = this.bsModalRef.content;
		this.subscriptions.add(
			this.listScheduleModal.confirmed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(confirmedItems => {
					if (confirmedItems) {
						filterInput.selectedItems = this.mapToFilterInputItems(confirmedItems);
						listReference.triggerFilter(filterInput);
						this.closeListScheduleModal(filterInput);
					}
				})
		);
		this.subscriptions.add(
			this.listScheduleModal.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(wasClosed => {
					if (wasClosed) {
						this.closeListScheduleModal(filterInput);
					}
				})
		);
	}

	private closeListScheduleModal(filterInput: SimpleFilterListModalInput) {
		filterInput.opened = false;
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
	}

	protected getCurrentSelecteditems(filterInput: SimpleFilterListModalInput, items: Schedule[]) {
		return items.filter(item => filterInput.selectedItems.find(selectedItem => selectedItem.id === item.id));
	}

	protected mapToFilterInputItems(items: Schedule[]) {
		return items.map(item => new SimpleFilterInputItem(item.id, item.name));
	}

	protected import(file: File) { }
	protected export() { }
}
