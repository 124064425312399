import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { SimpleCrudDirective } from 'app-core/shared-core/simple-components/crud/simple-crud.directive';
import { Utils } from 'app-core/shared-core/tools/utils';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'simple-search-sub',
	templateUrl: './simple-search-sub.component.html',
	styleUrls: ['./simple-search-sub.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleSearchSubComponent extends SimpleCrudDirective<any> implements OnInit, OnChanges {

	isFocused: boolean = false;

	localSearchValue: string = '';

	@Input() searchValue: string = '';

	@Output() onSearch = new EventEmitter<string>();

	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;

	ngOnInit() {
		this.localSearchValue = this.searchValue;
		this.subscriptions.add(
			fromEvent(this.searchInput.nativeElement, 'keyup')
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe((event: KeyboardEvent) => {
					const value = (event.target as HTMLInputElement).value;
					this.localSearchValue = value;
					this.onSearch.emit(value.trim());
				})
		);

		if (!Utils.isExtraSmallScreenSize()) {
			this.searchInput.nativeElement.focus();
		}
	}

	ngOnChanges() {
		if (this.searchValue && this.localSearchValue && this.searchValue !== this.localSearchValue) {
			if (this.searchValue !== this.localSearchValue.trim()) {
				this.localSearchValue = this.searchValue;
			}
		} else if (!this.searchValue) {
			this.localSearchValue = '';
		} else if (this.searchValue && !this.localSearchValue) {
			this.localSearchValue = this.searchValue;
		}
	}
}
