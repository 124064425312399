export class JsonHelper {

	// Returns a parsed object or the original string.
	static tryToParse = (item: string) => {
		try {
			return JSON.parse(item);
		} catch (errorResponse) {
			return item;
		}
	}

	// Returns a stringified object or the original string.
	static tryToStringify = (item: any) => {
		if (JsonHelper.isObject(item)) {
			return JSON.stringify(item);
		} else {
			return item;
		}
	}

	// Check if is an object.
	static isObject = (item: any) => {
		return typeof item === 'object' && !Array.isArray(item) && item !== null;
	}
}
