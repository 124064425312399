import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { Category } from 'app-core/shared-core/simple-components/various/categories/simple-categories.component';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { AssignmentTemplate } from 'app-inspection/assignment-template/assignment-template';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'change-details-tab',
	templateUrl: './change-details-tab.component.html'
})
export class ChangeDetailsTabComponent extends SimpleChangeTabDirective<AssignmentTemplate> implements OnInit {

	selectableTemplateTypeItems: FormSelectItem[] = [];
	selectableClientItems: FormSelectItem[] = [];
	selectableCategoryItems: FormSelectItem[] = [];
	selectableGeoFencingItems: FormSelectItem[] = [];

	clientsIsDisabled: boolean = false;
	geoFencingIsDisabled: boolean = false;

	disabledClientLink: string = '';
	disabledGeoFencingLink: string = '';

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableTemplateTypeItems();
		this.setSelectableClientItems();
		this.setSelectableCategoryItems();
		this.setSelectableGeoFencingItems();

		this.clientsIsDisabled = !this.selectableClientItems.length;
		this.geoFencingIsDisabled = this.isEdit && !this.changeModel.geoControlled && !this.changeModel.facility.hasPosition;

		this.disabledClientLink = this.selectedOrganization.url;
		this.disabledGeoFencingLink = this.changeModel.facility?.url;

		this.form = this.formBuilder.group({
			[this.propertyStrings.templateTypeId]: [this.changeModel.templateTypeId,
			Validators.required
			],
			[this.propertyStrings.clientId]: [this.changeModel.clientId],
			[this.propertyStrings.categoryIds]: [this.changeModel.categoryIds],
			[this.propertyStrings.geoControlled]: [this.changeModel.geoControlled,
			Validators.required
			]
		});
		super.ngOnInit();
	}

	private setSelectableTemplateTypeItems() {
		this.selectableTemplateTypeItems = this.changeModel.selectableTemplateTypes.map(templateType => new FormSelectItem(templateType.id, templateType.name));
	}

	private setSelectableClientItems() {
		this.selectableClientItems = this.changeModel.selectableClients.map(client => new FormSelectItem(client.id, `${client.name} (${client.email})`));
	}

	private setSelectableCategoryItems() {
		this.selectableCategoryItems = this.changeModel.selectableCategories.map(category => new FormSelectItem(category.id, category.name));
	}

	private setSelectableGeoFencingItems() {
		this.selectableGeoFencingItems = [
			new FormSelectItem(false, this.translationService.instant(StringUtils.NO)),
			new FormSelectItem(true, this.translationService.instant(StringUtils.YES)),
		];
	}

	handleAddNewItemInSelect(item: FormSelectItem) {
		const category = new Category({
			id: item.id,
			name: item.name
		});
		this.changeModel.selectableCategories.push(category);
	}
}
