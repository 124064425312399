import { DefaultUrlSerializer, UrlTree, UrlSerializer } from '@angular/router';
import { UrlUtils } from './url-utils';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
	private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

	parse(url: string): UrlTree {
		if (url && !UrlUtils.isCallbackRoute()) {
			// Encode "+" to "%2B"
			url = url.replace(/\+/gi, '%2B');
		}
		return this._defaultUrlSerializer.parse(url);
	}

	serialize(tree: UrlTree): string {
		if (tree && !UrlUtils.isCallbackRoute()) {
			return this._defaultUrlSerializer.serialize(tree).replace(/\+/gi, '%2B');
		} else {
			return this._defaultUrlSerializer.serialize(tree);
		}
	}
}
