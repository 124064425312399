import { LOCATION_INITIALIZED } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, Injector, NgModule } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';
import { CustomInterceptor } from './auth/customInterceptor';
import { CustomUrlSerializer } from './shared-core/tools/custom-url-serializer';
import { UrlUtils } from './shared-core/tools/url-utils';
import { TranslationService } from './shared-core/translation/translation.service';
import { DEFAULT_LANGUAGE, LoggedInUser, SUPPORTED_LANGUAGES } from './user/user';

/**
 * Add square brackets around strings that don't have translations.
 */
@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams) {
		return '[' + params.key + ']';
	}
}

/**
 * Initial data load.
 * @param authService
 * @param injector
 */
export function requestInitialAppData(authService: AuthService, injector: Injector) {
	return () => Promise.all([
		new Promise<any>((resolve: any) => {
			authService.clearStaleState();
			const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
			locationInitialized.then(() => {
				const translate = injector.get(TranslationService);
				if (!UrlUtils.isInvitationRoute() && !UrlUtils.isCallbackRoute()) {
					authService.requestUser().then(loggedInUser => {
						setLang(translate, loggedInUser, resolve);
					});
				} else {
					setLang(translate, undefined, resolve);
				}
			});
		})
	]);
}

/**
 * Sets the language
 */
export function setLang(translationService: TranslationService, loggedInUser: LoggedInUser, resolve?: any) {
	let langToSet = loggedInUser ? loggedInUser.language.toLowerCase() : DEFAULT_LANGUAGE;
	langToSet = SUPPORTED_LANGUAGES.some(o => o.indexOf(langToSet) >= 0) ? langToSet : DEFAULT_LANGUAGE;
	translationService.setDefaultLang(langToSet);
	translationService.use(langToSet)
		.subscribe(() => {
		}, err => {
			console.error(`Problem with '${langToSet}' language initialization.'`);
		}, () => {
			resolve(null);
		});
}

@NgModule({
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: requestInitialAppData,
			deps: [AuthService, Injector],
			multi: true
		},
		{
			provide: MissingTranslationHandler,
			useClass: MyMissingTranslationHandler
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CustomInterceptor,
			multi: true
		},
		{
			provide: UrlSerializer,
			useClass: CustomUrlSerializer
		}
	]
})
export class InitModule { }
