import { Injectable } from '@angular/core';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { Choice, ChoicePayload } from './choice';
import { BaseService } from '../../app-core/shared-core/abstract-components/service/base.service';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { Filter } from 'app-core/shared-core/filter';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';

@Injectable()
export class ChoiceService extends BaseService<Choice> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
			super(
				hostedHttpClient,
				RoutesUtils.choice
			);
	}

	createWithOrgId(item: ChoicePayload, organizationId: number) {
		return this.hostedHttpClient.post(`${this.prefix}?organization=${organizationId}`, [item]);
	}

	updateWithOrgId(item: ChoicePayload, organizationId: number) {
		return this.hostedHttpClient.put(`${this.prefix}?organization=${organizationId}`, [item]);
	}

	instantiateModel(item: Choice) {
		return new Choice(item);
	}
}

export class ChoiceFilter extends Filter {
	toPayloadObject() {
		const tempFacets = {};
		const currentFacets = (Object.entries(this.facets) as [string, string][])
			.map(facet => {
				return {
					key: facet[0],
					value: facet[1]
				};
			});

		currentFacets.forEach(facet => {
			if (facet.key === StringUtils.RULES_KEY) {
				const rules = facet.value.split(',');
				rules.forEach(rule => {
					if (rule === StringUtils.IS_ERROR) {
						tempFacets[StringUtils.IS_ERROR_KEY] = 'true';
					} else if (rule === StringUtils.IS_DEFAULT) {
						tempFacets[StringUtils.IS_DEFAULT_KEY] = 'true';
					} else if (rule === StringUtils.IS_UNSPECIFIED) {
						tempFacets[StringUtils.IS_UNSPECIFIED_KEY] = 'true';
					} else if (rule === StringUtils.ALLOWS_PRIORITY) {
						tempFacets[StringUtils.ALLOW_PRIORITY_KEY] = 'true';
					}
				});
			} else if (facet.key === StringUtils.IS_ERROR_KEY
				|| facet.key === StringUtils.IS_DEFAULT_KEY
				|| facet.key === StringUtils.IS_UNSPECIFIED_KEY
				|| facet.key === StringUtils.ALLOW_PRIORITY_KEY) {
					delete this.facets[facet.key];
			}
		});

		Object.assign(this.facets, tempFacets);

		return {
			filter: JSON.stringify(this)
		};
	}
}
