import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Entity } from 'app-inspection/entity/entity';
import { ListEntityComponent } from 'app-inspection/entity/list-entity/list-entity.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdditionalInfo, AdditionalInfoActionName, AdditionalInfoReason, RelatedDataItem, TemplateTypeFacilityEntitySettings, TemplateTypeHasTemplatesModel } from '../additional-info';

@Component({
	selector: 'additional-info-handler',
	templateUrl: 'additional-info-handler.component.html',
	styleUrls: ['additional-info-handler.component.less']
})
export class AdditionalInfoHandlerComponent extends SimpleHandleTabDirective<any> implements OnInit, AfterViewInit, OnDestroy {

	selectedData: string[];
	currentEditableModel$: BehaviorSubject<TemplateTypeHasTemplatesModel> = new BehaviorSubject<TemplateTypeHasTemplatesModel>(null);
	disabledEntities: Entity[] = [];
	currentRowId: string;
	reasons = AdditionalInfoReason;
	dropDownItems: FormSelectItem[] = [];

	bsModalRef: BsModalRef;

	@Input() additionalInfoRow: AdditionalInfo;
	@Input() shouldDisplayTriggerButton: boolean;

	@Output() onSelection = new EventEmitter<string[]>();
	@Output() onButtonEnabled = new EventEmitter();
	@Output() onMultiSelection = new EventEmitter();
	@Output() onHideSelectAll = new EventEmitter();

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastr: ToastrService,
		protected translationService: TranslationService,
		protected cdRef: ChangeDetectorRef) {
		super(
			authService,
			modalService,
			router,
			toastr,
			translationService,
			cdRef
		);
	}

	ngOnInit() {
		this.selectedData = [];
		this.dropDownItems = [
			new FormSelectItem(TemplateTypeFacilityEntitySettings.FACILITY, this.translationService.instant(TemplateTypeFacilityEntitySettings.FACILITY)),
			new FormSelectItem(TemplateTypeFacilityEntitySettings.ENTITIES, this.translationService.instant(TemplateTypeFacilityEntitySettings.ENTITIES)),
			new FormSelectItem(TemplateTypeFacilityEntitySettings.BOTH, this.translationService.instant(TemplateTypeFacilityEntitySettings.BOTH)),
		];
		if (this.additionalInfoRow.reason === AdditionalInfoReason.TemplateTypeHasTemplates) {
			this.checkActions();
		}
		this.initialDropdownValue();

		// Get unique links
		let links = this.additionalInfoRow.relatedData.map(item => item.url).filter((item, i, arr) => arr.indexOf(item) === i);

		// Make sure there are no duplicate links showing in the modal
		this.additionalInfoRow.relatedData.forEach(row => {
			if (links.find(link => link === row.url)) {
				this.additionalInfoRow.relatedData.forEach(r => {
					if (r !== row && r.url === row.url) {
						// r.url = null;
						r.hideLink = true;
						links = links.remove(row.url);
					}
				});
			}
		});
	}

	ngAfterViewInit() {
		this.initialToggleState();
	}

	isChecked(id: string) {
		return this.selectedData.indexOf(id) !== -1;
	}

	selectRow(row: RelatedDataItem) {
		const checkbox = document.getElementById(row.id) as HTMLInputElement;
		if (this.isChecked(row.id)) {
			this.selectedData.splice(this.selectedData.indexOf(row.id), 1);
			checkbox.checked = false;
		} else {
			this.selectedData.push(row.id);
			checkbox.checked = true;
		}
		this.toggleUpdateButton(row);
		this.onSelection.emit(this.selectedData);
	}

	clearSelection() {
		this.selectedData = [];
		this.onSelection.emit(this.selectedData);
		this.toggleUpdateButton();
	}

	initialToggleState() {
		if (this.shouldDisplayTriggerButton
			&& (this.additionalInfoRow.reason === AdditionalInfoReason.FacilityHasTemplates
				|| this.additionalInfoRow.reason === AdditionalInfoReason.TemplateTypeHasTemplates
				|| this.additionalInfoRow.reason === AdditionalInfoReason.TaskChoiceHasActiveAssignment)) {
			this.additionalInfoRow.relatedData.forEach(obj => {
				if (obj.rowShouldHaveCheckbox) {
					this.selectRow(obj);
				}
			});
		}
	}

	initialDropdownValue() {
		if (this.shouldDisplayTriggerButton) {
			this.additionalInfoRow.relatedData.forEach(obj => {
				this.setDropdownValue(TemplateTypeFacilityEntitySettings.FACILITY, obj);
				this.initialMultiSelectValues(obj);
			});
		}
	}

	initialMultiSelectValues(row: RelatedDataItem) {
		row.multiSelectValues = row.entities
			?.filter(entity => entity.alreadyExists)
			.map(entity => entity.name)
			.sort()
			.toCommaSeparatedList(true);
	}

	checkActions() {
		// If there is no 'Add' action, the 'select all' box will be hidden.
		if (this.additionalInfoRow.relatedData.every(row => row.additionalInfoActionName !== AdditionalInfoActionName.ADD)) {
			this.additionalInfoRow.hideSelectAll = true;
			this.onHideSelectAll.emit();
		} else {
			// Look for rows with 'Delete' action.
			const deleteActionRows = this.additionalInfoRow.relatedData
				.filter(row => row.additionalInfoActionName === AdditionalInfoActionName.DELETE)
				.map(row => row);

			const onlyDeletes = this.additionalInfoRow.relatedData.every(row => {
				deleteActionRows.includes(row);
			});

			// If there are not only 'Delete' actions, check for 'Add' actions and remove the 'Delete', duplicated row recieved from from back-end.
			if (!onlyDeletes) {
				deleteActionRows.forEach(deleteRow => {
					this.additionalInfoRow.relatedData.forEach(row => {
						if (row.additionalInfoActionName === AdditionalInfoActionName.DELETE && deleteRow.id === row.id) {
							this.additionalInfoRow.relatedData.splice(this.additionalInfoRow.relatedData.indexOf(row));
						} else if (row.additionalInfoActionName === AdditionalInfoActionName.ADD && deleteRow.id === row.id) {
							row.duplicateExisted = true;
						}
					});
				});
			}
		}
	}

	setShouldShowMultiSelectForRow(row: RelatedDataItem) {
		row.shouldShowMultiSelect = (row.facilityOrEntities === TemplateTypeFacilityEntitySettings.ENTITIES
			|| row.facilityOrEntities === TemplateTypeFacilityEntitySettings.BOTH) ? true : false;
		this.toggleUpdateButton(row);
	}

	setDropdownValue(event: TemplateTypeFacilityEntitySettings, row: RelatedDataItem) {
		row.facilityOrEntities = event;
		row.shouldShowMultiSelect = (event === TemplateTypeFacilityEntitySettings.ENTITIES
			|| event === TemplateTypeFacilityEntitySettings.BOTH) ? true : false;
		this.toggleUpdateButton(row);
	}

	setMultiSelectValues() {
		const row = this.additionalInfoRow.relatedData.find(item => item.id === this.currentRowId);
		if (row) {
			row.entities = this.currentEditableModel$.value.entities;
			row.multiSelectValues = row.entities.map(entity => entity.name)
				.toCommaSeparatedList(true);
			row.selectionHasBeenAltered = true;
		}
		this.toggleUpdateButton(row);
	}

	validateRows() {
		this.additionalInfoRow.relatedData.forEach(item => {
			if (item.facilityOrEntities !== TemplateTypeFacilityEntitySettings.FACILITY && this.isChecked(item.id)) {
				if (item.multiSelectValues?.length === 0) {
					item.validatedOK = false;
				} else if (item.multiSelectValues?.length) {
					item.validatedOK = true;
				}
			} else {
				item.validatedOK = true;
			}
		});
	}

	toggleUpdateButton(row?: RelatedDataItem) {
		this.validateRows();
		if ((this.isChecked(row?.id) && !row?.shouldShowMultiSelect)
			|| (this.isChecked(row?.id) && row?.shouldShowMultiSelect && row?.multiSelectValues.length >= 1)
			|| !this.isChecked(row?.id)) {
			if (this.additionalInfoRow.relatedData.every(item => item.validatedOK)) {
				this.onButtonEnabled.emit(true);
			}
		} else {
			this.onButtonEnabled.emit(false);
		}
	}

	toggleListEntityModal(row: RelatedDataItem) {
		this.initCurrentRowVariables(row);

		this.bsModalRef = this.modalService.show(
			ListEntityComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: true,
					hideTableHeaderActions: true,
					hideTableRowActions: true,
					idsToSetAsSelected: this.currentEditableModel$.value.entities.map(item => item.id),
					idsToSetAsUnselectable: this.disabledEntities.map(item => item.id),
					facilityIds: [this.currentEditableModel$.value.facilityId],
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);

		const listComponent = (this.bsModalRef.content as ListEntityComponent);
		this.subscriptions.add(
			listComponent.confirmed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(confirmedItems => {
					if (confirmedItems) {
						this.currentEditableModel$.value.entities = this.constructItemList(this.currentEditableModel$.value.entities, confirmedItems);
						this.currentEditableModel$.value.entities.sortByProperty(`${new Entity({}).propertyStrings.name}`);

						this.setMultiSelectValues();

						this.closeModal();
					}
				})
		);
		this.subscriptions.add(
			listComponent.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(wasClosed => {
					if (wasClosed) {
						this.closeModal();
					}
				})
		);
	}

	initCurrentRowVariables(row: RelatedDataItem) {
		this.currentRowId = row.id;
		this.disabledEntities = row.entities?.length ? row.entities.filter(entity => entity.alreadyExists) : [];

		this.currentEditableModel$.next(new TemplateTypeHasTemplatesModel({
			facilityId: row.facility.id,
			entities: row.selectionHasBeenAltered
				? row.entities.map(entity => new Entity(entity))
				: this.disabledEntities
		}));
	}

	protected validate(items: any[]): void { }

	itemListIsValid(): void { }

	getItems() {
		return this.handleItems;
	}

	trackByFn(index, item) {
		return item.id;
	}

	ngOnDestroy() {
		this.currentEditableModel$.complete();
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this.subscriptions.unsubscribe();
	}
}
