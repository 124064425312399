<div class="wrapper">
	<div id="existingImage" class="existing-image" *ngIf="!uploadedFile"
		(dragover)="handleDragOver($event)"
		(dragleave)="handleDragLeave($event)"
		(drop)="handleFileDrop($event)">
			<div class="image" #parentDiv
				[ngClass]="{
					'u-rounded': rounded
				}">
					<extensive-image
						[imageUrl]="imageUrl"
						[imageStorageSize]="parentDiv | getImageStorageSize"
						[rounded]="rounded"
						[placeholderImageType]="rounded ? 'user' : 'organization'"
						[displayLoader]="true">
					</extensive-image>
			</div>
			<input #fileInput type="file"
				class="u-display-none"
				[multiple]="false"
				[accept]="acceptedMediaTypes"
				(change)="handleFileChange($event)" />
			<div class="buttons"
				[ngClass]="{
					'use-center': !imageUrl
				}">
					<button class="btn btn-transparent" *ngIf="imageUrl"
						(click)="removeExistingImage()">
							{{ "Remove" | translate }}
					</button>
					<button class="btn btn-success openFileReader"
						(click)="fileInput.click()">
							{{ imageUrl ? ("Replace" | translate) : ("Select" | translate) }}
					</button>
			</div>
			<div #dropHelper class="filedrop-helper">
				<img width="68" height="68" src="{{ media.MISC_UPLOAD}}" />
			</div>
			<small class="validation-errors"
				[hidden]="!validationErrors.length">
					<span class="errors" *ngFor="let error of validationErrors">
						{{ error }}
					</span>
			</small>
	</div>
	<div class="cropper" *ngIf="uploadedFile">
		<image-cropper
			[imageFile]="uploadedFile"
			[maintainAspectRatio]="true"
			[roundCropper]="rounded"
			[format]="$any(format)"
			[transform]="transform"
			[backgroundColor]="shouldUseBackgroundColor ? backgroundColor : null"
			(imageCropped)="handleImageCropped($event)"
			(imageLoaded)="handleImageLoaded()">
		</image-cropper>
		<div class="overview" *ngIf="imageIsLoaded">
			<div class="overview-actions">
				<div class="back-button">
					<button
						class="btn btn-transparente"
						title="{{ 'Back' | translate }}"
						(click)="reset()">
							{{ "Back" | translate }}
					</button>
				</div>
				<div class="transform-buttons">
					<button class="btn btn-transparent zoom-in"
						title="{{ 'ZoomIn' | translate }}"
						(click)="zoomIn()">
							{{ "ZoomIn" | translate }}
					</button>
					<button class="btn btn-transparent zoom-out"
						title="{{ 'ZoomOut' | translate }}"
						(click)="zoomOut()">
							{{ "ZoomOut" | translate }}
					</button>
				</div>
			</div>
			<div class="preview-background-color-wrapper" *ngIf="imageIsLoaded">
				<div class="preview-wrapper">
					<div class="preview-text">
						{{ 'Preview' | translate }}
					</div>
					<img class="preview-image"
						[ngClass]="{
							'u-rounded': rounded,
							'hidden': !croppedImage
						}"
						[src]="croppedImageAsUrl" />
					<div class="preview-size">
						<div class="size-mb"
							[ngClass]="{
								'over-maxlimit': exceededMaxLimit
							}">
								<span>
									{{ croppedImageSize }} MB
								</span>
								<br>
								({{ ('MaxLimit' | translate) | lowercase }} {{ maxImageSizeOnServer }} MB)
						</div>
					</div>
				</div>
				<div class="background-color-action">
					<div class="checkbox"
						(click)="toggleShouldUseBackgroundColor()">
						<div class="checkbox-wrapper">
							<input
								[id]="'checkbox'"
								[checked]="shouldUseBackgroundColor"
								type="checkbox"/>
							<span class="checkmark"></span>
						</div>
						<div class="checkbox-text">{{ 'UseBackgroundForImage' | translate }}</div>
					</div>
					<div class="background-color-picker" *ngIf="shouldUseBackgroundColor">
						<div
							[colorPicker]="backgroundColor"
							[style.background]="backgroundColor"
							[cpToggle]="true"
							[cpAlphaChannel]="'disabled'"
							[cpDialogDisplay]="'inline'"
							(cpSliderDragStart)="handleSliderDragStart()"
							(cpSliderDragEnd)="handleSliderDragEnd($event)"
							(colorPickerChange)="handleColorChange($event)">
						</div>
					</div>
				</div>
			</div>
		</div>
		<dots-loader *ngIf="!imageIsLoaded"></dots-loader>
	</div>
</div>
