import { NgModule } from '@angular/core';
import { SharedModule } from 'app-core/shared-core/shared.module';
import { ListTaskComponent } from './list-task/list-task.component';
import { TaskRoutingModule } from './task-routing.module';
import { CreateEditTaskComponent } from './create-edit-task/create-edit-task.component';
import { DeleteTasksComponent } from './delete-tasks/delete-tasks.component';
import { ChangeDetailsTabComponent } from './create-edit-task/tabs/change-details-tab.component';
import { TemplateTypeModule } from 'app-inspection/template-type/template-type.module';

@NgModule({
	imports: [
		SharedModule,
		TaskRoutingModule,
		TemplateTypeModule
	],
	declarations: [
		ListTaskComponent,
		CreateEditTaskComponent,
		ChangeDetailsTabComponent,
		DeleteTasksComponent
	]
})
export class TaskModule { }
