<div class="sf-header" *ngIf="!isInModal">
	<div class="icon {{ tableConfig.emptyState.icon }}"></div>
	<div class="text">
		{{ tableConfig.emptyState.identifier | translate }}
	</div>
	<ng-container *ngTemplateOutlet="listActionsTemplate" >
	</ng-container>
</div>
<ng-container *ngIf="isInModal">
	<ng-container *ngTemplateOutlet="listActionsTemplate" >
	</ng-container>
</ng-container>

<div class="sf-container"
	[ngClass]="{
		'has-search-or-filter': !!activeSearch || activeFilter.length
	}">
		<div class="sf-actions"
			[ngClass]="{
				'in-modal': isInModal
			}">
			<div class="sf-section" *ngIf="!filterConfig.hideSearch">
				<simple-search-sub
					[searchValue]="activeSearch"
					(onSearch)="handleSearch($event)"
					(onSearchClear)="handleClearSearch()">
				</simple-search-sub>
			</div>
			<div class="sf-section" *ngIf="sortLabel">
				<div class="sf-action sort hide-text-on-small" #sortPop="bs-popover"
					placement="bottom"
					containerClass="standard-menu-popover"
					[popover]="!disableSearchFilterSort ? sortTemplate : null"
					[outsideClick]="true"
					[title]="(getSortedText() | translate) + ' ' + sortLabel.toLowerCase()"
					[ngClass]="{
						'opened': sortOpened,
						'disabled': disableSearchFilterSort
					}"
					(click)="closeOtherPopovers(sortPop)"
					(onShown)="sortOpened = true"
					(onHidden)="sortOpened = false">
						<div class="icon"
							[ngClass]="{
								'bi-sort-down': !sortReversed,
								'bi-sort-up': sortReversed
							}">
						</div>
						<div class="text">
							{{ sortLabel }}
						</div>
				</div>
				<ng-template #sortTemplate>
					<ng-container *ngFor="let sortObject of filterConfig.sortObjects; trackBy: trackByFn">
						<div class="standard-menu-item sort" *ngIf="sortObject.sortProperty"
							[ngClass]="{
								'highlighted': sortObject.sortProperty === sortProperty
							}"
							[title]="(getSortText(sortObject.sortProperty) | translate) + ' ' + sortObject.label.toLowerCase()"
							(click)="sortPop.hide(); handleSort(sortObject)">
								{{ sortObject.label }}
						</div>
					</ng-container>
				</ng-template>
			</div>
			<div class="sf-section">
				<div class="sf-action filter hide-text-on-small"
					[title]="activeFilter.length ? getActiveFilterAsText() : ('Filter' | translate)"
					(click)="toggleFilterOpened()"
					[ngClass]="{
						'opened': filterOpened,
						'highlighted': activeFilter.length,
						'disabled': disableSearchFilterSort
					}">
					<div class="icon bi-filter"></div>
					<div class="text">
						{{ 'Filter' | translate }}
					</div>
					<div class="icon smaller"
						[ngClass]="{
							'bi-chevron-down': !filterOpened,
							'bi-chevron-up': filterOpened
						}">
					</div>
				</div>
			</div>
			<div class="sf-section">
				<div class="sf-action row-count hide-text-on-small"
					[ngClass]="{'highlighted': selectedCount > 0}">
						<div class="sf-total"
							[title]="!activeSearch && !activeFilter.length ? ('TotalRowCount' | translate) : ('SearchedOrFilteredRowCount' | translate)">
							<div class="text">
								{{ 'Total' | translate }}:
							</div>
							<div class="count">
								{{ (totalCount ? totalCount : 0) | localeString }}
							</div>
						</div>
						<div class="sf-selected" *ngIf="selectedCount > 0"
							[title]="'SelectedRowCount' | translate">
								<div class="text">
									{{ 'Selected' | translate }}:
								</div>
								<div class="count">
									{{ selectedCount | localeString }}
								</div>
						</div>
						<div class="clear-button bi-x" *ngIf="selectedCount > 0"
							[title]="'ClearSelectedRows' | translate"
							(click)="handleSelectedClear()">
						</div>
				</div>
			</div>
		</div>
		<div class="sf-actions" *ngIf="!!activeSearch || activeFilter.length">
			<div class="sf-section apx-scrollbar">
				<ng-container *ngIf="!!activeSearch && !filterConfig.hideSearch">
					<div class="sf-action highlighted no-hover">
						<div class="text">
							{{ getActiveSearchAsText() }}
						</div>
						<div class="clear-button bi-x"
							[title]="'Clear' | translate"
							(click)="$event.stopImmediatePropagation(); handleClearSearch()">
						</div>
					</div>
				</ng-container>
				<ng-container *ngFor="let filterInput of activeFilter">
					<div class="sf-action highlighted no-hover"
						[title]="getActiveFilterSingleAsText(filterInput)">
							<div class="text">
								{{ getActiveFilterSingleAsText(filterInput) }}
							</div>
							<div class="clear-button bi-x"
								[title]="'Clear' | translate"
								(click)="$event.stopImmediatePropagation(); handleClearFilterSingle(filterInput)">
							</div>
					</div>
				</ng-container>
			</div>
			<button class="clear-filter-button btn btn-transparent" *ngIf="activeFilter.length"
				[title]="'ClearFilter' | translate"
				(click)="$event.stopImmediatePropagation(); handleClearFilter()">
					{{ 'ClearFilter' | translate }}
			</button>
		</div>
		<div class="filter-opened-wrapper" *ngIf="filterOpened">
			<simple-filter-sub
				[filterInputs]="filterConfig.filterInputs"
				(onFilter)="handleFilter($event)"
				(onFilterClear)="handleClearFilter()"
				(onClose)="toggleFilterOpened()"
				(onLstModalOpened)="toggleFilterOpened()">
			</simple-filter-sub>
		</div>
</div>

<ng-template #listActionsTemplate>
	<div class="list-actions apx-scrollbar" *ngIf="!hideListActions && listActions.length"
		[ngClass]="{
			'in-modal': isInModal
		}">
		<div class="list-action hide-text-on-small" *ngFor="let action of listActions"
			[title]="action.titleText"
			(click)="closeAllPopovers(); handleListActionClick(action)">
				<div class="icon {{ action.iconName }}">
				</div>
				<div class="text">
					{{ action.name }}
				</div>
		</div>
	</div>
</ng-template>
