import { Injectable } from '@angular/core';
import { BaseService } from 'app-core/shared-core/abstract-components/service/base.service';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { Assignment } from 'app-inspection/assignment/assignment';

@Injectable()
export class DashboardService extends BaseService<Assignment> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
		super(
			hostedHttpClient,
			RoutesUtils.assignment
		);
	}

	instantiateModel(_: Assignment) {
		return null;
	}
}
