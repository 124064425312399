import { AuthService } from './auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'renewtoken-ts',
	template: `
		<dots-loader></dots-loader>
	`
})
export class RenewTokenComponent implements OnInit {
	constructor(private authService: AuthService) { }

	ngOnInit() {
		this.authService.userManager.signinSilentCallback();
	}
}
