import { Injectable } from '@angular/core';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { Facility } from 'app-inspection/facility/facility';
import { ListFacilityComponent } from 'app-inspection/facility/list-facility/list-facility.component';
import { SimpleListDirective } from '../../../simple-list.directive';
import { SimpleFilterListModalInput } from '../input-settings/simple-filter-list-modal-input-settings';
import { SimpleFilterInputItem } from '../simple-filter-input-item';
import { SimpleFilterHelperDirective } from './simple-filter-helper-base';

@Injectable()
export class SimpleFilterHelperFacility extends SimpleFilterHelperDirective<Facility> {

	openListModal(filterInput: SimpleFilterListModalInput, items: Facility[], listReference: SimpleListDirective<any>) {
		const currentSelectedItems = this.getCurrentSelecteditems(filterInput, items);
		this.bsModalRef = this.modalService.show(
			ListFacilityComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: true,
					hideTableHeaderActions: true,
					hideTableRowActions: true,
					idsToSetAsSelected: currentSelectedItems.map(item => item.id)
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToListModalContent(filterInput, listReference);
	}

	protected mapToFilterInputItems(items: Facility[]) {
		return items.map(item => new SimpleFilterInputItem(item.id, item.name));
	}
}
