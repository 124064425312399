import { KeyValuePair } from 'app-core/shared-core/filter';
import { SimpleFilterInputItem } from '../simple-filter-input-item';
import { SimpleFilterInput, SimpleFilterInputType } from './simple-filter-input-settings';

export class SimpleFilterListModalInput extends SimpleFilterInput {

	triggerListModal: (filterInput: SimpleFilterInput) => void;

	constructor(
		id: string,
		label: string,
		type: SimpleFilterInputType,
		transformValues: (values: string) => KeyValuePair[],
		triggerListModal: (filterInput: SimpleFilterInput) => void,
		items: SimpleFilterInputItem[]) {
		super(
			id,
			label,
			type,
			transformValues,
			...items
		);
		this.triggerListModal = triggerListModal;
	}
}
