import { Injectable } from '@angular/core';
import { OrganizationService } from '../../organization/organization.service';
import { AuthService } from '../auth.service';
import { LocalStorageService, LocalStorageKey } from '../../shared-core/local-storage/local-storage.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';

@Injectable()
export class AuthGuardHelper {

	private isInvalidRoute: boolean;

	constructor(
		private authService: AuthService,
		private router: Router,
		private organizationService: OrganizationService,
		private localStorageService: LocalStorageService,
		private toastr: ToastrService,
		private translationService: TranslationService) { }

	// Validates the org param on every navigation.
	// Resolves false if a redirect will be made, otherwise true.
	resolveOrgParam(orgParam: string, destination: string, isInvalidRoute: boolean): Promise<boolean> {
		this.isInvalidRoute = isInvalidRoute;
		const loggedInUser = this.authService.loggedInUser;
		return new Promise(async resolve => {
			if (orgParam) {
				const selectedOrganization = this.authService.selectedOrganization;

				if (!selectedOrganization || selectedOrganization.friendlyUrl !== orgParam) {
					// Organization has changed, try to get the new one and set it if has permission.
					// Otherwise set the stored one if exists and has permission otherwise set the user organization.

					try {
						const orgSimplified = await this.organizationService.getByFriendlyUrl(orgParam);
						const organization = await this.organizationService.get(orgSimplified.id);

						const isUserOrg = loggedInUser.organization.id === organization.id;
						const isMemberOrg = loggedInUser.isMembershipMemberActive(organization.id);
						if (isUserOrg || isMemberOrg || loggedInUser.isSuperAdmin()) {
							this.authService.setSelectedOrganization(organization);
							return resolve(true);
						} else {
							this.authService.setSelectedOrganization(loggedInUser.organization);
							this.performRedirect(loggedInUser.organization.friendlyUrl, destination);
							return resolve(false);
						}
					} catch {
						const storedOrganization = this.localStorageService.getItem(LocalStorageKey.SelectedOrganization);
						if (storedOrganization) {
							try {
								const organization = await this.organizationService.get(storedOrganization.id);
								const isUserOrg = loggedInUser.organization.id === organization.id;
								const isMemberOrg = loggedInUser.isMembershipMemberActive(organization.id);
								if (isUserOrg || isMemberOrg || loggedInUser.isSuperAdmin()) {
									this.authService.setSelectedOrganization(organization);
									this.performRedirect(organization.friendlyUrl, destination, orgParam);
									return resolve(false);
								} else {
									this.authService.setSelectedOrganization(loggedInUser.organization);
									this.performRedirect(loggedInUser.organization.friendlyUrl, destination, orgParam);
									return resolve(false);
								}
							} catch (e) {
								this.authService.setSelectedOrganization(loggedInUser.organization);
								this.performRedirect(loggedInUser.organization.friendlyUrl, destination, orgParam);
								this.toastr.error(this.translationService.instant(StringUtils.SELECTED_ORG_NOT_FOUND));
								return resolve(false);

							}
						} else {
							this.authService.setSelectedOrganization(loggedInUser.organization);
							this.performRedirect(loggedInUser.organization.friendlyUrl, destination, orgParam);
							this.toastr.error(this.translationService.instant(StringUtils.SELECTED_ORG_NOT_FOUND));
							return resolve(false);
						}
					}
				} else {
					this.authService.setSelectedOrganization(selectedOrganization);
					return resolve(true);
				}
			} else {
				// Found no org param.
				// Try to get selected organization from local storage and set it if exists and has permission.
				// Othwerwise set the user organization.
				// Invalid routes will never have an org param so we always end up here in those cases.
				// We do not need to redirect when visiting invalid routes so we just set the organization and resolve to true in those cases.
				const storedOrganization = this.localStorageService.getItem(LocalStorageKey.SelectedOrganization);
				if (storedOrganization) {
					try {
						const organization = await this.organizationService.get(storedOrganization.id);
						const isUserOrg = loggedInUser.organization.id === organization.id;
						const isMemberOrg = loggedInUser.isMembershipMemberActive(organization.id);
						if (isUserOrg || isMemberOrg || loggedInUser.isSuperAdmin()) {
							this.authService.setSelectedOrganization(organization);
							if (this.isInvalidRoute) {
								return resolve(true);
							} else {
								this.performRedirect(organization.friendlyUrl, destination);
								return resolve(false);
							}
						} else {
							this.authService.setSelectedOrganization(loggedInUser.organization);
							if (this.isInvalidRoute) {
								return resolve(true);
							} else {
								this.performRedirect(loggedInUser.organization.friendlyUrl, destination);
								return resolve(false);
							}
						}
					} catch (e) {
						this.authService.setSelectedOrganization(loggedInUser.organization);
						if (this.isInvalidRoute) {
							return resolve(true);
						} else {
							this.performRedirect(loggedInUser.organization.friendlyUrl, destination);
							return resolve(false);
						}
					}
				} else {
					this.authService.setSelectedOrganization(loggedInUser.organization);
					if (this.isInvalidRoute) {
						return resolve(true);
					} else {
						this.performRedirect(loggedInUser.organization.friendlyUrl, destination);
						return resolve(false);
					}
				}
			}
		});
	}

	performRedirect(newOrgName: string, destination: string, orgName?: string) {

		// Needed for å, ä, ö to work.
		destination = decodeURI(destination);

		// Remove any old org name and/or any query params before redirecting.
		if (orgName && destination.includes(orgName)) {
			destination = destination.replace('/' + orgName, '');
		}
		if (destination.indexOf('?') !== -1) {
			const end = destination.indexOf('?');
			destination = destination.substring(0, end);
		}
		this.router.navigate(['/' + newOrgName + destination]);
	}
}
