import { NgModule } from '@angular/core';
import { MediaModule } from 'app-core/media/media-module';
import { SharedModule } from 'app-core/shared-core/shared.module';
import { AssignmentTemplateModule } from 'app-inspection/assignment-template/assignment-template.module';
import { FacilityModule } from 'app-inspection/facility/facility.module';
import { ScheduleModule } from 'app-inspection/schedule/schedule.module';
import { AssignmentRoutingModule } from './assignment-routing.module';
import { CreateManualMeasureComponent } from './create-manual-measure/create-manual-measure.component';
import { IssueModalEntityComponent } from './create-manual-measure/issue-modal-entity/issue-modal-entity.component';
import { IssueDetailsEntityTabComponent } from './create-manual-measure/issue-modal-entity/tabs/issue-details-entity/issue-details-entity-tab.component';
import { IssueModalFacilityComponent } from './create-manual-measure/issue-modal-facility/issue-modal-facility.component';
import { IssueDetailsFacilityTabComponent } from './create-manual-measure/issue-modal-facility/tabs/issue-details-facility/issue-details-facility-tab.component';
import { HandleEntitiesTabMeasureComponent } from './create-manual-measure/tabs/handle-entities-tab/handle-entities-tab.component';
import { HandleFacilityTabComponent } from './create-manual-measure/tabs/handle-facility/handle-facility-tab.component';
import { DeleteAssignmentsComponent } from './delete-assignments/delete-assignments.component';
import { DetailedAssignmentComponent } from './detailed-assignment/detailed-assignment.component';
import { AssessmentContentComponent } from './detailed-assignment/info-modal/assessment/content/assessment/assessment-content/assessment-content.component';
import { AssessmentComponent } from './detailed-assignment/info-modal/assessment/content/assessment/assessment.component';
import { ResultModalComponent } from './detailed-assignment/info-modal/assessment/content/result-modal/result-modal.component';
import { ResultContentComponent } from './detailed-assignment/info-modal/assessment/content/result/result-content/result-content.component';
import { ResultComponent } from './detailed-assignment/info-modal/assessment/content/result/result.component';
import { ChangeDetailsTabComponent } from './detailed-assignment/tabs/change-details/change-details-tab.component';
import { HandleAssigneeGroupsTabComponent } from './detailed-assignment/tabs/handle-assignee-groups/handle-assignee-groups-tab.component';
import { HandleAssigneesTabComponent } from './detailed-assignment/tabs/handle-assignees/handle-assignees-tab.component';
import { ViewDetailsTabComponent } from './detailed-assignment/tabs/view-details/view-details-tab.component';
import { HandleAssigneeGroupsAndAssigneesComponent } from './list-assignment/handle-assignee-groups-and-assignees/handle-assignee-groups-and-assignees.component';
import { ListAssignmentMeasureArchivedComponent } from './list-assignment/measure/archived/list-assignment-measure-archived.component';
import { ListAssignmentMeasureOngoingComponent } from './list-assignment/measure/ongoing/list-assignment-measure-ongoing.component';
import { SetPriorityComponent } from './list-assignment/measure/ongoing/set-priority/set-priority.component';
import { SetPriorityTabComponent } from './list-assignment/measure/ongoing/set-priority/tabs/set-priority-tab.component';
import { SetStatusAssignmentComponent } from './list-assignment/measure/ongoing/set-status/set-status-assignment.component';
import { SetStatusTabComponent } from './list-assignment/measure/ongoing/set-status/tabs/set-status-tab.component';
import { ListAssignmentScheduledArchivedComponent } from './list-assignment/scheduled/archived/list-assignment-scheduled-archived.component';
import { ListAssignmentScheduledOngoingComponent } from './list-assignment/scheduled/ongoing/list-assignment-scheduled-ongoing.component';
import { SetDueDateComponent } from './list-assignment/scheduled/ongoing/set-due-date/set-due-date.component';
import { SetDueDateTabComponent } from './list-assignment/scheduled/ongoing/set-due-date/tabs/set-due-date-tab.component';
import { ReportModule } from './report/report.module';

@NgModule({
	imports: [
		SharedModule,
		AssignmentRoutingModule,
		MediaModule,
		ReportModule,
		AssignmentTemplateModule,
		FacilityModule,
		ScheduleModule
	],
	declarations: [
		ListAssignmentScheduledOngoingComponent,
		ListAssignmentScheduledArchivedComponent,
		ListAssignmentMeasureOngoingComponent,
		ListAssignmentMeasureArchivedComponent,
		CreateManualMeasureComponent,
		DetailedAssignmentComponent,
		ViewDetailsTabComponent,
		ChangeDetailsTabComponent,
		HandleAssigneeGroupsTabComponent,
		HandleAssigneesTabComponent,
		DeleteAssignmentsComponent,
		HandleAssigneeGroupsAndAssigneesComponent,
		SetStatusAssignmentComponent,
		SetStatusTabComponent,
		SetDueDateComponent,
		SetDueDateTabComponent,
		SetPriorityComponent,
		SetPriorityTabComponent,
		IssueModalFacilityComponent,
		IssueDetailsFacilityTabComponent,
		IssueModalEntityComponent,
		IssueDetailsEntityTabComponent,
		ResultModalComponent,
		AssessmentComponent,
		ResultComponent,
		HandleFacilityTabComponent,
		HandleEntitiesTabMeasureComponent,
		ResultContentComponent,
		AssessmentContentComponent
	]
})
export class AssignmentModule { }
