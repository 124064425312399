<div class="affectable-count-message-wrapper">
	<div class="affectable-count-message all" *ngIf="affectableIdsCount === selectedIdsCount">
		{{ 'AffectableCountMessageAll' | translate }}.
	</div>

	<div class="affectable-count-message some" *ngIf="affectableIdsCount > 0 && affectableIdsCount < selectedIdsCount">
		{{ 'AffectableCountMessageSomeFirst' | translate }}
		<span class="count">{{ affectableIdsCount | localeString }}</span>
		{{ 'AffectableCountMessageSomeSecond' | translate }}.
	</div>

	<div class="affectable-count-message none" *ngIf="affectableIdsCount === 0">
		{{ 'AffectableCountMessageNone' | translate }}.
	</div>
</div>
