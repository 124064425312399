import { ViewContainerRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export abstract class SimpleTableColumn<T> {

	readonly label: string;
	readonly sanitizer: DomSanitizer;
	readonly viewContainerRef: ViewContainerRef;
	readonly secondaryLabel: string;

	// These can be set from specific columns.
	maxWidth: string;
	useDivSwitching: boolean = false;

	constructor(
		label: string,
		sanitizer: DomSanitizer,
		viewContainerRef: ViewContainerRef,
		secondaryLabel: string) {
		this.label = label;
		this.sanitizer = sanitizer;
		this.viewContainerRef = viewContainerRef;
		this.secondaryLabel = secondaryLabel;
	}

	abstract renderValue(item: T): SafeHtml;
}
