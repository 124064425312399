import { Component, Input } from '@angular/core';
import { SimpleViewTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/view/simple-view-tab.directive';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { Result } from 'app-inspection/assignment/assignment';
import { ResultModalComponent } from 'app-inspection/assignment/detailed-assignment/info-modal/assessment/content/result-modal/result-modal.component';

@Component({
	selector: 'assessment-component',
	templateUrl: './assessment.component.html',
	styleUrls: ['./assessment.component.less']
})
export class AssessmentComponent extends SimpleViewTabDirective<Result> {

	@Input() errorResult: Result;
	@Input() resolvementResult: Result;
	@Input() isMeasure: boolean;
	@Input() wasClosed: boolean;
	@Input() isCompleted: boolean;

	openResult() {
		this.bsModalRef = this.modalService.show(
			ResultModalComponent,
			{
				initialState: {
					errorResult: this.errorResult,
					resolvementResult: this.resolvementResult,
					disableTabIndexUrl: true
				},
				...ConfigUtils.MODAL_CONFIG_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}
}
