import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CrudItem } from 'app-core/shared-core/simple-components/crud/crud-item';
import { SimpleReadModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-read-modal.directive';

@Component({
	selector: 'markdown-viewer',
	templateUrl: './markdown-viewer.component.html'
})
export class MarkdownViewerComponent extends SimpleReadModalDirective<MarkdownObject> implements OnInit, OnChanges {
	@Input() readModelInput: string;

	ngOnInit(): void {
		this.readModel = new MarkdownObject({
			content: this.readModelInput ? this.readModelInput : ''
		});
	}

	ngOnChanges(): void {
		this.readModel = new MarkdownObject({
			content: this.readModelInput ? this.readModelInput : ''
		});
	}
}

export class MarkdownObject extends CrudItem<MarkdownObject> {

	content: string;

	constructor(item: Partial<MarkdownObject>) {
		super();
		Object.assign(this, item);
	}
}
