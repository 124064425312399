import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { RegexUtils } from 'app-core/shared-core/tools/regex-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { ALT_DATE_TIME_FORMAT_WITHOUT_SECONDS, DEFAULT_DATE_FORMAT, DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { PublishingType, Schedule } from 'app-inspection/schedule/schedule';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'change-details-tab',
	templateUrl: './change-details-tab.component.html',
	styleUrls: ['./change-details-tab.component.less']
})
export class ChangeDetailsTabComponent extends SimpleChangeTabDirective<Schedule> implements OnInit {

	selectableStatusItems: FormSelectItem[] = [];
	selectablePublishingTypeItems: FormSelectItem[] = [];
	selectableIntervalYearItems: FormSelectItem[] = [];
	selectableIntervalMonthItems: FormSelectItem[] = [];
	selectableIntervalWeekItems: FormSelectItem[] = [];
	selectableIntervalItems: FormSelectItem[] = [];
	selectableWeekdayItems: FormSelectItem[] = [];
	selectablePreviewCountItems: FormSelectItem[] = [];

	shouldValidateStartDate: boolean = false;
	shouldValidateEndDate: boolean = false;

	startDateErrorMessage: string = '';
	endDateErrorMessage: string = '';

	initialStartDate: string;

	publishingType = PublishingType;

	@Output() onDateChange = new EventEmitter();

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableStatusItems();
		this.setSelectablePublishingTypeItems();
		this.setSelectableIntervalYearItems();
		this.setSelectableIntervalMonthItems();
		this.setSelectableIntervalWeekItems();
		this.setSelectableIntervalItems();
		this.setSelectableWeekdayItems();
		this.setSelectablePreviewCountItems();

		this.form = this.formBuilder.group({
			[this.propertyStrings.name]: [this.changeModel.name,
			[Validators.required, Validators.maxLength(100)]
			],
			[this.propertyStrings.active]: [this.changeModel.active,
			Validators.required
			],
			[this.propertyStrings.publishingType]: [this.changeModel.publishingType,
			Validators.required
			],
			[this.propertyStrings.interval]: [this.changeModel.interval,
			Validators.required
			],
			[this.propertyStrings.weekdaysInternal]: [this.changeModel.weekdaysInternal,
			Validators.required
			],
			[this.propertyStrings.daysDelay]: [this.changeModel.daysDelay,
			[Validators.required, this.daysDelayValidator]
			],
			[this.propertyStrings.publishingTime]: [this.changeModel.publishingTime,
			Validators.required
			],
			[this.propertyStrings.previewCount]: [this.changeModel.previewCount,
			Validators.required],
		}, { validators: this.weekdaysValidator });

		if (this.isEdit) {
			this.initialStartDate = this.changeModel.startDate;
			if (!this.changeModel.active) {
				this.shouldValidateStartDate = true;
				this.shouldValidateEndDate = true;
			} else {
				if (!this.startDateIsBeforeToday() && !this.endDateIsBeforeToday()) {
					this.shouldValidateStartDate = true;
					this.shouldValidateEndDate = true;
				} else if (this.startDateIsBeforeToday() && !this.endDateIsBeforeToday()) {
					this.shouldValidateStartDate = true;
					this.shouldValidateEndDate = true;
				} else if (this.startDateIsBeforeToday() && this.endDateIsBeforeToday()) {
					// TODO: Visa ett meddelande som säger att denna egentligen är inaktiv men att det syns först vid kl. 05 idag?
				}
			}
		} else {
			this.shouldValidateStartDate = true;
			this.shouldValidateEndDate = true;
		}

		this.setStartDateErrorMessage();
		this.setEndDateErrorMessage();

		// Refresh every 1 second in order for startdate message to display correctly.
		this.subscriptions.add(
			interval(1000)
				.pipe(takeUntil(this.destroyed$))
				.subscribe(() => {
					this.setStartDateErrorMessage();
				})
		);

		super.ngOnInit();
	}

	private daysDelayValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.NUMBER).test(control.value)) {
			return { number: true };
		} else {
			return null;
		}
	}

	private weekdaysValidator(form: FormGroup): ValidationErrors {
		const publishingType = form.get('publishingType');
		const weekdaysInternal = form.get('weekdaysInternal');

		if (publishingType.value === PublishingType.Weekly && !weekdaysInternal.value.length) {
			weekdaysInternal.setErrors({ required: true });
		} else {
			weekdaysInternal.setErrors(null);
		}
		return null;
	}

	private setSelectableStatusItems() {
		this.selectableStatusItems = [
			new FormSelectItem(true, this.translationService.instant(StringUtils.ACTIVE)),
			new FormSelectItem(false, this.translationService.instant(StringUtils.INACTIVE))
		];
	}

	private setSelectablePublishingTypeItems() {
		this.selectablePublishingTypeItems = [
			new FormSelectItem(PublishingType.Once, this.translationService.instant('Once')),
			new FormSelectItem(PublishingType.Weekly, this.translationService.instant('Weekly')),
			new FormSelectItem(PublishingType.Monthly, this.translationService.instant('Monthly')),
			new FormSelectItem(PublishingType.Annually, this.translationService.instant('Annually'))
		];
	}

	private setSelectableIntervalYearItems() {
		this.selectableIntervalYearItems = [
			new FormSelectItem(0, this.translationService.instant('EveryYear')),
			new FormSelectItem(1, this.translationService.instant('EveryOtherYear')),
			new FormSelectItem(2, this.translationService.instant('EveryThreeYears'))
		];
	}

	private setSelectableIntervalMonthItems() {
		this.selectableIntervalMonthItems = [
			new FormSelectItem(0, this.translationService.instant('EveryMonth')),
			new FormSelectItem(1, this.translationService.instant('EveryOtherMonth')),
			new FormSelectItem(2, this.translationService.instant('EveryThreeMonths')),
			new FormSelectItem(3, this.translationService.instant('EveryFourMonths')),
			new FormSelectItem(4, this.translationService.instant('EveryFiveMonths')),
			new FormSelectItem(5, this.translationService.instant('EverySixMonths')),
			new FormSelectItem(6, this.translationService.instant('EverySevenMonths')),
			new FormSelectItem(7, this.translationService.instant('EveryEightMonths')),
			new FormSelectItem(8, this.translationService.instant('EveryNineMonths')),
			new FormSelectItem(9, this.translationService.instant('EveryTenMonths')),
			new FormSelectItem(10, this.translationService.instant('EveryElevenMonths'))
		];
	}

	private setSelectableIntervalWeekItems() {
		this.selectableIntervalWeekItems = [
			new FormSelectItem(0, this.translationService.instant('EveryWeek')),
			new FormSelectItem(1, this.translationService.instant('EveryOtherWeek')),
			new FormSelectItem(2, this.translationService.instant('EveryThreeWeeks')),
			new FormSelectItem(3, this.translationService.instant('EveryFourWeeks')),
			new FormSelectItem(4, this.translationService.instant('EveryFiveWeeks')),
			new FormSelectItem(5, this.translationService.instant('EverySixWeeks')),
			new FormSelectItem(6, this.translationService.instant('EverySevenWeeks')),
			new FormSelectItem(7, this.translationService.instant('EveryEightWeeks')),
			new FormSelectItem(8, this.translationService.instant('EveryNineWeeks')),
			new FormSelectItem(9, this.translationService.instant('EveryTenWeeks')),
			new FormSelectItem(10, this.translationService.instant('EveryElevenWeeks')),
			new FormSelectItem(11, this.translationService.instant('EveryTwelveWeeks')),
			new FormSelectItem(12, this.translationService.instant('EveryThirteenWeeks')),
			new FormSelectItem(13, this.translationService.instant('EveryFourteenWeeks')),
			new FormSelectItem(14, this.translationService.instant('EveryFifteenWeeks')),
			new FormSelectItem(15, this.translationService.instant('EverySixteenWeeks')),
			new FormSelectItem(16, this.translationService.instant('EverySeventeenWeeks')),
			new FormSelectItem(17, this.translationService.instant('EveryEighteenWeeks')),
			new FormSelectItem(18, this.translationService.instant('EveryNineteenWeeks')),
			new FormSelectItem(19, this.translationService.instant('EveryTwentyWeeks')),
			new FormSelectItem(20, this.translationService.instant('EveryTwentyOneWeeks')),
			new FormSelectItem(21, this.translationService.instant('EveryTwentyTwoWeeks')),
			new FormSelectItem(22, this.translationService.instant('EveryTwentyThreeWeeks')),
			new FormSelectItem(23, this.translationService.instant('EveryTwentyFourWeeks')),
			new FormSelectItem(24, this.translationService.instant('EveryTwentyFiveWeeks')),
			new FormSelectItem(25, this.translationService.instant('EveryTwentySixWeeks')),
			new FormSelectItem(26, this.translationService.instant('EveryTwentySevenWeeks')),
			new FormSelectItem(27, this.translationService.instant('EveryTwentyEightWeeks')),
			new FormSelectItem(28, this.translationService.instant('EveryTwentyNineWeeks')),
			new FormSelectItem(29, this.translationService.instant('EveryThirtyWeeks')),
			new FormSelectItem(30, this.translationService.instant('EveryThirtyOneWeeks')),
			new FormSelectItem(31, this.translationService.instant('EveryThirtyTwoWeeks')),
			new FormSelectItem(32, this.translationService.instant('EveryThirtyThreeWeeks')),
			new FormSelectItem(33, this.translationService.instant('EveryThirtyFourWeeks')),
			new FormSelectItem(34, this.translationService.instant('EveryThirtyFiveWeeks')),
			new FormSelectItem(35, this.translationService.instant('EveryThirtySixWeeks')),
			new FormSelectItem(36, this.translationService.instant('EveryThirtySevenWeeks')),
			new FormSelectItem(37, this.translationService.instant('EveryThirtyEightWeeks')),
			new FormSelectItem(38, this.translationService.instant('EveryThirtyNineWeeks')),
			new FormSelectItem(39, this.translationService.instant('EveryFourtyWeeks')),
			new FormSelectItem(40, this.translationService.instant('EveryFourtyOneWeeks')),
			new FormSelectItem(41, this.translationService.instant('EveryFourtyTwoWeeks')),
			new FormSelectItem(42, this.translationService.instant('EveryFourtyThreeWeeks')),
			new FormSelectItem(43, this.translationService.instant('EveryFourtyFourWeeks')),
			new FormSelectItem(44, this.translationService.instant('EveryFourtyFiveWeeks')),
			new FormSelectItem(45, this.translationService.instant('EveryFourtySixWeeks')),
			new FormSelectItem(46, this.translationService.instant('EveryFourtySevenWeeks')),
			new FormSelectItem(47, this.translationService.instant('EveryFourtyEightWeeks')),
			new FormSelectItem(48, this.translationService.instant('EveryFourtyNineWeeks')),
			new FormSelectItem(49, this.translationService.instant('EveryFiftyWeeks')),
			new FormSelectItem(50, this.translationService.instant('EveryFiftyOneWeeks')),
			new FormSelectItem(51, this.translationService.instant('EveryFiftyTwoWeeks'))
		];
	}

	setSelectableIntervalItems() {
		if (this.changeModel.publishingType === PublishingType.Once || this.changeModel.publishingType === PublishingType.Annually) {
			this.selectableIntervalItems = this.selectableIntervalYearItems;
		} else if (this.changeModel.publishingType === PublishingType.Monthly) {
			this.selectableIntervalItems = this.selectableIntervalMonthItems;
		} else if (this.changeModel.publishingType === PublishingType.Weekly) {
			this.selectableIntervalItems = this.selectableIntervalWeekItems;
		}
	}

	private setSelectableWeekdayItems() {
		this.selectableWeekdayItems = [
			new FormSelectItem(0, Utils.capitalizeFirstLetter(this.translationService.instant('Monday'))),
			new FormSelectItem(1, Utils.capitalizeFirstLetter(this.translationService.instant('Tuesday'))),
			new FormSelectItem(2, Utils.capitalizeFirstLetter(this.translationService.instant('Wednesday'))),
			new FormSelectItem(3, Utils.capitalizeFirstLetter(this.translationService.instant('Thursday'))),
			new FormSelectItem(4, Utils.capitalizeFirstLetter(this.translationService.instant('Friday'))),
			new FormSelectItem(5, Utils.capitalizeFirstLetter(this.translationService.instant('Saturday'))),
			new FormSelectItem(6, Utils.capitalizeFirstLetter(this.translationService.instant('Sunday')))
		];
	}

	private setSelectablePreviewCountItems() {
		this.selectablePreviewCountItems = [
			new FormSelectItem(3, '3'),
			new FormSelectItem(10, '10')
		];
	}

	handleStartDateChange(dateValue: string) {
		this.changeModel.startDate = dateValue;
		this.setStartDateErrorMessage();
		this.setEndDateErrorMessage();
		this.onDateChange.emit();
	}

	handleEndDateChange(dateValue: string) {
		this.changeModel.endDate = dateValue;
		this.setEndDateErrorMessage();
		this.onDateChange.emit();
	}

	handleTimeChange() {
		this.setStartDateErrorMessage();
	}

	private startDateIsBeforeToday() {
		const [startDateWithoutTime, _] = this.changeModel.startDate.split(' ');
		const startDateWithPublishingTime = moment(new Date(`${startDateWithoutTime} ${this.changeModel.publishingTime}`)).format(ALT_DATE_TIME_FORMAT_WITHOUT_SECONDS);
		return startDateWithPublishingTime <= Utils.getToday(ALT_DATE_TIME_FORMAT_WITHOUT_SECONDS);
	}

	private endDateIsBeforeToday() {
		if (!!this.changeModel.endDate) {
			const [endDateWithoutTime, _] = this.changeModel.endDate.split(' ');
			return endDateWithoutTime <= Utils.getToday(DEFAULT_DATE_FORMAT);
		}
		return false;
	}

	private endDateIsBeforeStartDate() {
		return Utils.getFormattedDateStringFromString(this.changeModel.endDate, DEFAULT_DATE_FORMAT) <= Utils.getFormattedDateStringFromString(this.changeModel.startDate, DEFAULT_DATE_FORMAT);
	}

	private setStartDateErrorMessage() {
		if (this.shouldValidateStartDate) {
			if (this.startDateIsBeforeToday() && this.changeModel.startDate !== this.initialStartDate) {
				this.startDateErrorMessage = `${this.translationService.instant('MustBeLaterThanToday', { date: Utils.getToday(DEFAULT_DISPLAY_DATE_FORMAT) })}`;
			} else {
				this.startDateErrorMessage = '';
			}
		}
	}

	private setEndDateErrorMessage() {
		if (this.shouldValidateEndDate && !!this.changeModel.endDate) {
			if (this.endDateIsBeforeToday()) {
				this.endDateErrorMessage = `${this.translationService.instant('MustBeLaterThanToday', { date: Utils.getToday('ll') })}`;
			} else if (this.endDateIsBeforeStartDate()) {
				this.endDateErrorMessage = `${this.translationService.instant('MustBeLaterThan', { date: Utils.getFormattedDateStringFromString(this.changeModel.startDate, 'll') })}`;
			} else {
				this.endDateErrorMessage = '';
			}
		} else {
			this.endDateErrorMessage = '';
		}
	}
}
