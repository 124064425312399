import { ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { Assignment } from 'app-inspection/assignment/assignment';
import { SimpleTableColumn } from './simple-table-column';

export class SimpleTableDueDateColumn<T> extends SimpleTableColumn<T> {

	readonly getAssignment: (item: T) => Assignment;

	constructor(
		label: string,
		getAssignment: (item: T) => Assignment,
		sanitizer: DomSanitizer,
		viewContainerRef: ViewContainerRef,
		secondaryLabel: string) {
		super(
			label,
			sanitizer,
			viewContainerRef,
			secondaryLabel
		);
		this.getAssignment = getAssignment;
		this.maxWidth = '150px';
		this.useDivSwitching = true;
	}

	renderValue(item: T) {
		const assignment = this.getAssignment(item);

		const wrapper = document.createElement('div');
		wrapper.classList.add('due-date-wrapper');

		const dueDateDiv = document.createElement('div');

		dueDateDiv.innerHTML = Utils.convertToHumanDate(assignment.dueDate);
		dueDateDiv.innerHTML = dueDateDiv.innerHTML.charAt(0).toUpperCase() + dueDateDiv.innerHTML.slice(1);
		dueDateDiv.title = Utils.getFormattedDateStringFromString(assignment.dueDate, DEFAULT_DISPLAY_DATE_FORMAT);
		if (Utils.isTodayDate(assignment.dueDate) && Utils.isFutureDate(assignment.dueDate)) {
			dueDateDiv.style.fontWeight = '600';
		}
		dueDateDiv.style.overflow = 'hidden';
		dueDateDiv.style.textOverflow = 'ellipsis';

		wrapper.append(dueDateDiv);

		return this.sanitizer.bypassSecurityTrustHtml(wrapper.outerHTML);
	}
}
