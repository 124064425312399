<simple-crud-modal
	[titleText]="('Choice' | translate) + (modifiedModel?.value ? ' ─ ' + modifiedModel?.value : '')"
	[closeText]="'Close' | translate"
	[submitText]="(isEdit ? 'Update' : 'Create') | translate"
	[disableSubmit]="!everythingIsValid() || !hasUnsavedChanges()"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab [customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-card-text'"
						[title]="'Details' | translate"
						[helpText]="'ChoiceChangeDetailsHelpText' | translate">
					</simple-tab-heading>
				</ng-template>
				<change-details-tab
					[changeModel]="modifiedModel">
				</change-details-tab>
			</tab>
		</tabset>
</simple-crud-modal>
