<i class="view-assignees bi-person-fill"
	[title]="'ViewAssignees' | translate"
	placement="bottom"
	containerClass="vm-menu-popover apx-scrollbar"
	[popover]="members.length ? membersTemplate : null"
	[outsideClick]="true"
	[ngClass]="{
		'disabled': !members.length,
		'opened': actionsOpened
	}"
	container="body"
	(click)="shouldStopPropagation ? $event.stopPropagation() : null"
	(onShown)="actionsOpened = true"
	(onHidden)="actionsOpened = false">
</i>
<ng-template #membersTemplate>
	<div class="vm-menu-item" *ngFor="let member of members"
		(click)="navigateTo(member); $event.stopPropagation()">
		<div class="image-details-wrapper">
			<div class="item-image" #parentDiv>
				<extensive-image
					[imageUrl]="member.profileImageUrl"
					[imageStorageSize]="parentDiv | getImageStorageSize"
					[rounded]="true"
					[placeholderImageType]="'user'">
				</extensive-image>
			</div>
			<div class="details">
				<div class="name" title="{{ member.fullName }}" *ngIf="member.fullName">
					{{ member.fullName }}
				</div>
				<div class="description" *ngIf="member.email"
					[ngClass]="{
						'only': !member.fullName
					}"
					title="{{ member.email }}" >
						{{ member.email }}
				</div>
			</div>
		</div>
	</div>
</ng-template>
