import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
	selector: '[scrollShadow]'
})
export class ScrollShadowDirective implements AfterViewInit {
	constructor(
		private _renderer: Renderer2,
		private _el: ElementRef) { }

	ngAfterViewInit() {
		setTimeout(() => {
			// Get parent of the original element.
			const parent = this._el.nativeElement.parentNode;

			// Create the scroll element.
			const divElement = this._renderer.createElement('scroll-shadow');

			// Add the scroll element, just before the original.
			this._renderer.insertBefore(parent, divElement, this._el.nativeElement);

			// Remove the original.
			this._renderer.removeChild(parent, this._el.nativeElement);

			// Remove the directive attribute (not really necessary, but just to be clean).
			this._renderer.removeAttribute(this._el.nativeElement, 'scrollShadow');

			// Re-add it inside the scroll element.
			this._renderer.appendChild(divElement, this._el.nativeElement);
		}, 0);
	}
}
