<simple-change-tab
	[form]="form">
	<div class="change-section">
		<div class="change-section-header">
			{{ 'General' | translate }}
		</div>
		<simple-form-input
			[label]="'Name' | translate"
			[name]="propertyStrings.name"
			[form]="form"
			[maxlength]="100"
			[serverErrors]="serverErrors"
			[focusOnInit]="!isEdit">
		</simple-form-input>

		<simple-form-input
			[label]="'Abbreviation' | translate"
			[name]="propertyStrings.abbreviation"
			[form]="form"
			[maxlength]="10"
			[serverErrors]="serverErrors">
		</simple-form-input>

		<simple-form-select
			[label]="'BaseType' | translate"
			[name]="propertyStrings.templateBaseTypeId"
			[form]="form"
			[disabled]="isEdit"
			[items]="selectableTemplateBaseTypeItems"
			[serverErrors]="serverErrors">
		</simple-form-select>
	</div>
	<div class="change-section">
		<div class="change-section-header">
			{{ 'UrgentChoice' | translate }}
		</div>
		<choices #component
			[handleItems]="changeModel.notificationChoice ? [changeModel.notificationChoice] : []"
			[actionTitle]="'Add' | translate"
			[useButtonAction]="true"
			[hideAddButton]="!!changeModel.notificationChoice"
			[useSingleSelectionInList]="true"
			(onModelsChange)="handleChoicesChange($event)">
		</choices>
		<div class="error-messages" *ngIf="notificationChoiceErrorMessages.length">
			<div class="error-message" *ngFor="let message of notificationChoiceErrorMessages">
				&bull; {{ message | translate }}
			</div>
		</div>
	</div>
</simple-change-tab>
