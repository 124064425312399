import { Component, ViewChild } from '@angular/core';
import { HandleMediaTabComponent } from 'app-core/media/handle-media/handle-media-tab.component';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { ConversionHelper } from 'app-core/shared-core/tools/conversion-helper';
import { Choice } from 'app-inspection/choice/choice';
import { Entity } from 'app-inspection/entity/entity';
import { IssueDetailsEntityTabComponent } from './tabs/issue-details-entity/issue-details-entity-tab.component';

@Component({
	selector: 'issue-modal-entity',
	templateUrl: './issue-modal-entity.component.html',
	styleUrls: ['./issue-modal-entity.component.less']
})
export class IssueModalEntityComponent extends SimpleCreateEditModalDirective<Entity> {

	choice: Choice;

	protected createOrUpdate(): void { }

	@ViewChild(IssueDetailsEntityTabComponent) issueDetailsTabComponent: IssueDetailsEntityTabComponent;
	@ViewChild(HandleMediaTabComponent) handleMediaTabComponent: HandleMediaTabComponent;

	everythingIsValid(): boolean {
		return this.issueDetailsIsValid();
	}

	issueDetailsIsValid() {
		return this.issueDetailsTabComponent?.formIsValid();
	}

	issueDetailsHasErrors() {
		return this.issueDetailsTabComponent?.formHasErrors();
	}

	handleIssueMedia(event: MediaWidgetItem[]) {
		/**
		 * Backend want base64 for images on manual measures,
		 * 	so I added a new property, 'base64' on MediaWidgetItems and set that property immediately
		 **/
		this.modifiedModel.issueMedia = event;
		this.modifiedModel.issueMedia.forEach(async item => {
			if (!item.base64) {
				item.base64 = await ConversionHelper.blobToBase64asPromise(item.blob);
			}
		});
	}

	private detailsHasChanged() {
		return this.modifiedModel.issueDetails !== this.initialModel.issueDetails
			|| this.modifiedModel.priority !== this.initialModel.priority
			|| this.modifiedModel.issueMedia.length !== this.initialModel.issueMedia.length;
	}

	hasUnsavedChanges() {
		return this.detailsHasChanged();
	}

	override submit(): void {
		if (this.everythingIsValid()) {
			this.updated$.next([this.modifiedModel]);
		} else {
			this.triggerAllValidation();
		}
	}

	protected triggerAllValidation(): void {
		this.issueDetailsTabComponent.triggerValidation();
	}

	protected instantiateModel(item: Entity) {
		return new Entity(item);
	}
}
