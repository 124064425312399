<div class="form-color-picker">
	<div class="label-wrapper">
		<label>
			{{ label }}
		</label>
	</div>
	<div
		[colorPicker]="colorString"
		[style.background]="colorString"
		[cpPresetColors]="getPresetColors()"
		[cpToggle]="true"
		[cpDialogDisplay]="'inline'"
		(colorPickerChange)="handlePickerChange($event)">
	</div>
</div>
