<div class="assessment-item"
	[ngClass]="{
		'allow-click': !isMeasure
	}"
	(click)="!isMeasure ? assessmentClicked() : null">
	<div class="content-wrapper">
		<div class="choice"
			[title]="errorResult.task.choice.value">
				<span class="value">
					{{ errorResult.task.choice.value }}
				</span>
				<span class="color"
					[style.backgroundColor]="errorResult.task.choice.color">
				</span>
		</div>
		<div class="task">
			<div class="text"
				[title]="errorResult.task.text">
					{{ errorResult.task.text }}
			</div>
			<div class="text"
				[title]="errorResult.task.code">
					{{ errorResult.task.code }}
			</div>
		</div>
	</div>
	<div class="status bi-check-circle" *ngIf="((!isMeasure && isCompleted) || isMeasure) && (resolvementResult || errorResult.solved)"
		[ngClass]="{
			'closed': wasClosed
		}">
		{{ (isMeasure ? (wasClosed ? 'Closed' : 'Resolved') : 'Handled' ) | translate }}
	</div>
	<div class="status" *ngIf="((!isMeasure && isCompleted) || isMeasure) && !resolvementResult && errorResult.task.choice.isError && !errorResult.solved"
		[ngClass]="{
			'not-resolved': !resolvementResult
		}">
		{{ 'NotHandled' | translate }}
	</div>
	<simple-link-button *ngIf="errorResult.measureAssignmentId && !isMeasure"
		[url]="getMeasureUrl()"
		[name]="'MeasureAssignment' | translate"
		[shouldStopPropagation]="true">
	</simple-link-button>
</div>