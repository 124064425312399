import { Injectable } from '@angular/core';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { ListUserGroupComponent } from 'app-core/user-group/list-user-group/list-user-group.component';
import { UserGroup } from 'app-core/user-group/user-group';
import { SimpleListDirective } from '../../../simple-list.directive';
import { SimpleFilterListModalInput } from '../input-settings/simple-filter-list-modal-input-settings';
import { SimpleFilterInputItem } from '../simple-filter-input-item';
import { SimpleFilterHelperDirective } from './simple-filter-helper-base';

@Injectable()
export class SimpleFilterHelperUserGroup extends SimpleFilterHelperDirective<UserGroup> {

	openListModal(filterInput: SimpleFilterListModalInput, items: UserGroup[], listReference: SimpleListDirective<any>) {
		const currentSelectedItems = this.getCurrentSelecteditems(filterInput, items);
		this.bsModalRef = this.modalService.show(
			ListUserGroupComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: true,
					hideTableHeaderActions: true,
					hideTableRowActions: true,
					idsToSetAsSelected: currentSelectedItems.map(item => item.id)
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToListModalContent(filterInput, listReference);
	}

	protected mapToFilterInputItems(items: UserGroup[]) {
		return items.map(item => new SimpleFilterInputItem(item.id, item.name));
	}
}
