import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MediaUtils } from '../../shared-core/tools/media-utils';
import { MediaResolver } from '../resolver/media-resolver';

/**
 * This component works as a div tag with a background image. It will resolve an image based on passed in url and options.
 * It also has added extensive functionality like error handling if an image fails to load.
 */
@Component({
	selector: 'extensive-image',
	templateUrl: './extensive-image.component.html',
	styleUrls: ['./extensive-image.component.less']
})
export class ExtensiveImageComponent implements OnInit, OnChanges {

	isLoading: boolean;

	@Input() imageUrl: string;
	@Input() blob: Blob;
	@Input() imageStorageSize: number;
	@Input() rounded: boolean;
	@Input() useContain: boolean;
	@Input() placeholderImageType: string;
	@Input() displayLoader: boolean;
	@Output() onImageLoaded = new EventEmitter<HTMLDivElement>();

	@ViewChild('mainDivEl', { read: ElementRef, static: true }) mainDivEl: ElementRef;
	@ViewChild('secondaryDivEl', { read: ElementRef, static: true }) secondaryDivEl: ElementRef;
	@ViewChild('imgEl', { read: ElementRef, static: true }) imgEl: ElementRef;

	get mainDiv(): HTMLDivElement {
		return this.mainDivEl.nativeElement;
	}

	get secondaryDiv(): HTMLDivElement {
		return this.secondaryDivEl.nativeElement;
	}

	get img(): HTMLImageElement {
		return this.imgEl.nativeElement;
	}

	constructor(
		private mediaResolver: MediaResolver) { }

	ngOnInit() {
		if (this.displayLoader) {
			this.isLoading = true;
		}
	}

	ngOnChanges() {
		this.refresh();
	}

	refresh() {
		if (this.blob) {
			const blobUrl = URL.createObjectURL(this.blob);
			this.img.src = blobUrl;
			this.mainDiv.classList.remove('default-placeholder-style');
		} else {
			const resolvedUrl = this.mediaResolver.resolve(this.imageUrl, this.getSize(), this.placeholderImageType);
			this.img.src = resolvedUrl;

			if (this.imageUrl === null && this.placeholderImageType !== 'user' && this.placeholderImageType !== 'organization') {
				this.mainDiv.classList.add('default-placeholder-style');
			} else if (this.imageUrl !== null && this.placeholderImageType !== 'user' && this.placeholderImageType !== 'organization') {
				this.mainDiv.classList.remove('default-placeholder-style');
			}
		}
		if (this.rounded) {
			this.mainDiv.classList.add('u-rounded');
		} else {
			this.mainDiv.classList.remove('u-rounded');
		}
	}

	setErrorImage() {
		this.mainDiv.style.backgroundImage = `url(${MediaUtils.GRAPHICS_ERROR_IMAGE_BROKEN_LINK})`;
		this.mainDiv.classList.add('error-image-style');
		if (this.useContain) {
			this.secondaryDiv.classList.remove('visible');
		}
		if (this.displayLoader) {
			this.isLoading = false;
		}
	}

	setImage() {
		this.mainDiv.style.backgroundImage = `url(${this.img.src})`;
		this.mainDiv.classList.remove('error-image-style');
		if (this.useContain) {
			this.secondaryDiv.style.backgroundImage = `url(${this.img.src})`;
			this.mainDiv.classList.add('blur');
			this.secondaryDiv.classList.add('visible');
		}
		if (this.displayLoader) {
			this.isLoading = false;
		}

		this.onImageLoaded.next(this.mainDiv);
	}

	private getSize() {
		return this.imageStorageSize ?? null;
	}
}
