import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { MediaModule } from 'app-core/media/media-module';
import { SharedModule } from 'app-core/shared-core/shared.module';
import { StatisticsByDateDataModalComponent } from './statistics-by-date-data-modal.component';
import { StatisticsModalComponent } from './statistics-modal.component';
import { StatisticsRelatedDataModalComponent } from './statistics-related-data-modal.component';
import { StatisticsComponent } from './statistics.component';

@NgModule({
	imports: [
		SharedModule,
		MediaModule,
		NgSelectModule
	],
	declarations: [
		StatisticsComponent,
		StatisticsModalComponent,
		StatisticsRelatedDataModalComponent,
		StatisticsByDateDataModalComponent
	],
	exports: [
		StatisticsComponent,
		StatisticsModalComponent,
		StatisticsRelatedDataModalComponent,
		StatisticsByDateDataModalComponent
	]
})
export class StatisticsModule { }
