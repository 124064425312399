import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Assignment } from 'app-inspection/assignment/assignment';
import { AssignmentService } from 'app-inspection/assignment/assignment.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SetPriorityTabComponent } from './tabs/set-priority-tab.component';

@Component({
	templateUrl: './set-priority.component.html'
})
export class SetPriorityComponent extends SimpleBulkEditModalDirective<Assignment> implements OnInit {

	@ViewChild(SetPriorityTabComponent) setAssignmentPriorityTabComponent: SetPriorityTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private assignmentService: AssignmentService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.initialModel = new Assignment({});
		await this.getPriorityData(this.initialModel.priority);
	}

	private async getPriorityData(value: boolean) {
		this.pending = true;
		try {
			const response = await this.assignmentService.getPriorityData(this.editModelIds, value, this.selectedOrganization.id);
			this.affectableIds = response.data;
			this.pending = false;
		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	handleValueChanges() {
		if (this.setAssignmentPriorityTabComponent) {
			const priorityCtrl = this.setAssignmentPriorityTabComponent.form.get(this.propertyStrings.priority);
			this.getPriorityData(priorityCtrl.value);
		}
	}

	changeDetailsIsValid() {
		return this.setAssignmentPriorityTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.setAssignmentPriorityTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const ids = this.useAffectableIds ? this.affectableIds : this.editModelIds;
			const response = await this.assignmentService.updatePriority(ids, this.initialModel.priority, this.selectedOrganization.id);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.setAssignmentPriorityTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Assignment) {
		return new Assignment(item);
	}

	hasUnsavedChanges() {
		return false;
	}
}
