import { Injectable } from '@angular/core';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { ListUserComponent } from 'app-core/user/list-user/list-user.component';
import { User } from 'app-core/user/user';
import { SimpleListDirective } from '../../../simple-list.directive';
import { SimpleFilterListModalInput } from '../input-settings/simple-filter-list-modal-input-settings';
import { SimpleFilterInputItem } from '../simple-filter-input-item';
import { SimpleFilterHelperDirective } from './simple-filter-helper-base';

@Injectable()
export class SimpleFilterHelperUser extends SimpleFilterHelperDirective<User> {

	openListModal(filterInput: SimpleFilterListModalInput, items: User[], listReference: SimpleListDirective<any>) {
		const currentSelectedItems = this.getCurrentSelecteditems(filterInput, items);
		this.bsModalRef = this.modalService.show(
			ListUserComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: true,
					hideTableHeaderActions: true,
					hideTableRowActions: true,
					idsToSetAsSelected: currentSelectedItems.map(item => item.id)
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToListModalContent(filterInput, listReference);
	}

	protected mapToFilterInputItems(items: User[]) {
		return items.map(item => new SimpleFilterInputItem(item.id, item.email));
	}
}
