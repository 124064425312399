import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { BackendResponse } from '../abstract-components/service/base.service';
import { HostedHttpRequest } from '../hosted-http-request';
import { HostedHttpClientServiceMiddleware } from '../hosted-httpclient-middleware';
import { ConfigUtils } from '../tools/config-utils';
import { DomUtils } from '../tools/dom-utils';
import { StringUtils } from '../tools/string-utils';
import { TranslationService } from '../translation/translation.service';
import { AdditionalInfo } from './additional-info';
import { AdditionalInfoComponent } from './additional-info.component';

@Injectable()
export class AdditionalInfoMiddleware implements HostedHttpClientServiceMiddleware {

	bsModalRef: BsModalRef;

	constructor(
		protected modalService: BsModalService,
		protected translationService: TranslationService) { }

	/**
	 * This fires whenever a http request where this component is added as middleware is executed.
	 * Whenever that happens, this component is initiated and trigger the modal to show itself.
	*/
	handleHttpResponse(request: HostedHttpRequest, body: Object, retry: (request?: HostedHttpRequest) => Promise<Object>): Promise<Object> {
		return new Promise((resolve) => {
			const additionalInfo = body['additionalInfo'] as any[];
			if (additionalInfo && additionalInfo.length) {
				this.clearErrors(body);

				// Kickstart the additional info component
				setTimeout(() => {
					this.bsModalRef = this.modalService.show(
						AdditionalInfoComponent,
						{
							initialState: {
								resume: () => { },
								cancel: () => { },
								retry: (modifiedRequest) => retry(modifiedRequest ?? request),
								getOriginalRequest: () => request,
								data: body['data'],
								additionalInfo: additionalInfo.map(ai => new AdditionalInfo(ai))
							},
							...ConfigUtils.MODAL_CONFIG_LARGE
						}
					);

					const additionalInfoComponent = (this.bsModalRef.content as AdditionalInfoComponent);
					additionalInfoComponent.subscriptions.add(
						additionalInfoComponent.closed$
							.pipe(
								takeUntil(additionalInfoComponent.destroyed$)
							)
							.subscribe(wasClosed => {
								if (wasClosed) {
									this.closeModal();
								}
							}));
				}, 0);
			}
			resolve(body);
		});
	}

	/**
	 * This fires whenever a http request where this component is added as middleware is executed.
	 * Whenever that happens, this component is initiated and trigger the modal to show itself.
	*/
	handleHttpError(body: any, request: HostedHttpRequest, retry: (request?: HostedHttpRequest) => Promise<Object>): Promise<Object> {
		return new Promise((resolve, reject) => {
			const additionalInfo = body.error?.additionalInfo ?? body.additionalInfo;
			if (additionalInfo?.length) {
				this.clearErrors(body);

				if (this.bsModalRef) { // todo ha kvar?
					this.closeModal();
				}

				// Kickstart the additional info component
				setTimeout(() => {
					this.bsModalRef = this.modalService.show(
						AdditionalInfoComponent,
						{
							initialState: {
								resume: (newBody) => resolve(newBody || body),
								cancel: () => reject(new BackendResponse().errorMessage = this.translationService.instant(StringUtils.ADDITIONAL_INFO_CANCEL)),
								retry: ((modifiedRequest) => retry(modifiedRequest ?? request)
									.then(response => {
										resolve(response);
										return response;
									})
									.catch(error => {
										reject(error);
									})) as any,
								getOriginalRequest: () => request,
								error: body.error,
								data: body.data,
								additionalInfo: additionalInfo.map(ai => new AdditionalInfo(ai))
							},
							...ConfigUtils.MODAL_CONFIG_LARGE
						}
					);

					const additionalInfoComponent = (this.bsModalRef.content as AdditionalInfoComponent);
					additionalInfoComponent.subscriptions.add(
						additionalInfoComponent.closed$
							.pipe(
								takeUntil(additionalInfoComponent.destroyed$)
							)
							.subscribe(wasClosed => {
								if (wasClosed) {
									this.closeModal();
								}
							}));
				});
			} else {
				reject(body);
			}
		});
	}

	/**
	 * Clears the errors collection in a HTTP response
	 * @param body HTTP response
	 */
	private clearErrors(body: Object) {
		// An AdditionalInfo array is present, clear the Errors array as they
		// contains the exact same data. If changed, AIW-2229 should
		// be responsible for this action.
		if (body['error'] && body['error'].errors) {
			body['error'].errors = {};
		} else if (body['errors']) {
			body['errors'] = {};
		}
	}

	private closeModal() {
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
	}
}
