import { ClipboardModule } from '@angular/cdk/clipboard';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ExtensiveMediaModule } from 'app-core/media/extensive-image/extensive-media.module';
import { NgChartsModule } from 'ng2-charts';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DirectivesModule } from './directives/directives.module';
import { DotsLoaderModule } from './dots-loader/dots-loader.module';
import { GoogleMapModule } from './google-maps/google-maps.module';
import { MarkdownEditorModule } from './markdown-editor/markdown-editor.module';
import { MarkdownViewerModule } from './markdown-viewer/markdown-viewer.module';
import { NoticeIconComponent } from './notice-icon/notice-icon.component';
import { PipesModule } from './pipes/pipes.module';
import { SimpleComponentsModule } from './simple-components/simple-components.module';
import { ViewMembersComponent } from './view-members/view-members.component';
import { ViewObjectsComponent } from './view-objects/view-objects.component';

/**
 * More about the SharedModule in the Readme.
 */
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		TranslateModule,
		PipesModule,
		DirectivesModule,
		DotsLoaderModule,
		ModalModule,
		ButtonsModule,
		BsDatepickerModule,
		BsDropdownModule,
		PopoverModule,
		TooltipModule,
		CollapseModule,
		ProgressbarModule,
		SimpleComponentsModule,
		MarkdownEditorModule,
		ClipboardModule,
		ReactiveFormsModule,
		TabsModule,
		ScrollingModule,
		ExtensiveMediaModule,
		GoogleMapModule,
		MarkdownEditorModule,
		MarkdownViewerModule,
		NgChartsModule
	],
	declarations: [
		NoticeIconComponent,
		ViewObjectsComponent,
		ViewMembersComponent
	],
	exports: [
		CommonModule,
		FormsModule,
		RouterModule,
		TranslateModule,
		PipesModule,
		DirectivesModule,
		DotsLoaderModule,
		ModalModule,
		ButtonsModule,
		BsDatepickerModule,
		BsDropdownModule,
		PopoverModule,
		TooltipModule,
		CollapseModule,
		ProgressbarModule,
		SimpleComponentsModule,
		MarkdownEditorModule,
		ClipboardModule,
		ReactiveFormsModule,
		TabsModule,
		ScrollingModule,
		ExtensiveMediaModule,
		NoticeIconComponent,
		ViewMembersComponent,
		GoogleMapModule,
		MarkdownEditorModule,
		MarkdownViewerModule,
		NgChartsModule
	]
})
export class SharedModule { }
