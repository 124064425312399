import { Component, Input } from '@angular/core';

@Component({
	selector: 'simple-help',
	templateUrl: './simple-help.component.html',
	styleUrls: ['./simple-help.component.less']
})
export class SimpleHelpComponent {

	opened: boolean = false;

	@Input() helpText: string;
}
