import {
	Component
} from '@angular/core';
import { Choice } from '../choice';
import { AuthService } from 'app-core/auth/auth.service';
import { ChoiceService } from '../choice.service';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { SimpleDeleteModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-delete-modal.directive';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
	templateUrl: './delete-choices.component.html'
})
export class DeleteChoicesComponent extends SimpleDeleteModalDirective<Choice> {

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private choiceService: ChoiceService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	protected async delete() {
		this.pending = true;
		try {
			const response = await this.choiceService.delete(this.deleteModelIds[0]);
			this.handleSuccessResponse(response, this.translationService.instant('BackgroundJobStartedDeleteChoice'));

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected async deleteRange() {
		this.pending = true;
		try {
			const response = await this.choiceService.deleteRange(this.deleteModelIds);
			this.handleSuccessResponse(response, this.translationService.instant('BackgroundJobStartedDeleteChoices'));

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}
}
