import { CrudItem } from 'app-core/shared-core/simple-components/crud/crud-item';
import { UrlUtils } from 'app-core/shared-core/tools/url-utils';
import { DEFAULT_LANGUAGE } from 'app-core/user/user';
import { NotificationMessage } from '../notification-message/notification-message';

export class HelpArticle extends CrudItem<HelpArticle> {
	path: string;
	adminUrl: AdminUrl[] = [];
	parent: string;
	title: string;
	name: string;
	order: number;
	icon: string;
	notification: NotificationMessage;
	titleToDisplay: string;

	paths: CultureDataObject;
	parents: CultureDataObject;
	titles: CultureDataObject;
	names: CultureDataObject;

	constructor(helpArticle: Partial<HelpArticle>, currentLang: string) {
		super();
		Object.assign(this, helpArticle);
		this.adminUrl = this.adminUrl ? this.adminUrl.map(o => new AdminUrl(o)) : [];
		this.icon = Icon.getIconName(this.parent);

		const path = this.paths[currentLang];
		const parent = this.parents[currentLang];
		const title = this.titles[currentLang];
		const name = this.names[currentLang];

		this.path = path ? `${UrlUtils.WIKI_URL}${path}` : `${UrlUtils.WIKI_URL}${this.paths[DEFAULT_LANGUAGE]}`;
		this.parent = parent ? parent : this.parents[DEFAULT_LANGUAGE];
		this.title = title ? title : this.titles[DEFAULT_LANGUAGE];
		this.name = name ? name : this.names[DEFAULT_LANGUAGE];

		if (this.notification) {
			this.notification.path = this.path;
		}
	}
}

export class CultureDataObject {
	sv: string;
	en: string;
	no: string;

	constructor(object: Partial<CultureDataObject>) {
		Object.assign(this, object);
	}
}

export class AdminUrl {
	glob: string;
	regexp: RegExp;

	constructor(adminUrl: Partial<AdminUrl>) {
		this.glob = adminUrl.glob;
		this.regexp = new RegExp(adminUrl.regexp);
	}
}

class Icon {
	private static bug: { keys: string[], value: string } = { keys: ['Bugs', 'Buggar'], value: 'bug' };
	private static faq: { keys: string[], value: string } = { keys: ['Frequently asked questions', 'Vanliga frågor'], value: 'faq' };
	private static guide: { keys: string[], value: string } = { keys: ['Guides', 'Guider'], value: 'guide' };
	private static icons: { keys: string[], value: string }[] = [Icon.bug, Icon.faq, Icon.guide];

	static getIconName(value: string): string {
		const icon = Icon.icons.find(o => o.keys.includes(value));
		return icon?.value ?? 'bi bi-question-circle-fill';
	}
}
