import { Component, Input } from '@angular/core';

@Component({
	selector: 'simple-tab-heading',
	templateUrl: './simple-tab-heading.component.html'
})
export class SimpleTabHeadingComponent {

	@Input() icon: string;
	@Input() title: string;
	@Input() helpText: string;
	@Input() displayCount: number;
}
