<div class="invitation-page">
		<div class="content apx-scrollbar">
			<div class="logotype">
				<img src="{{ headerLogo }}">
			</div>
			<div class="form-wrapper">
				<ng-container *ngTemplateOutlet="notReadyTemplate"></ng-container>
				<ng-container *ngTemplateOutlet="inviteTemplate"></ng-container>
				<ng-container *ngTemplateOutlet="declineTemplate"></ng-container>
				<ng-container *ngTemplateOutlet="acceptTemplate"></ng-container>
			</div>
			<div class="footer" *ngIf="footerIsReady">
				<p>©2009-{{ currentYear }} Inquinova AB. {{ "AllRightsReserved" | translate }}.</p>
				<a href="http://apirix.se/user-agreement.pdf" target="_blank">{{ "TermsOfService" | translate }}</a>
			</div>
		</div>

	<!-- Invite template (Default) -->
	<ng-template #inviteTemplate>
		<form #formRef="ngForm" *ngIf="ready && !declinedInvite && !acceptedInvite"
			[formGroup]="form">
			<section class="section invite-section"
				[ngClass]="{'loadmask': submitting}">
					<section class="section-body">
						<div class="info">
							<span
								[innerHTML]="('EnterInvitationEmail' | translate).formatBold().toHtmlBreakLine()">
							</span>
						</div>
						<div class="input-email">
							<simple-form-input
								[label]="'Email' | translate"
								[name]="propertyStrings.email"
								[form]="form"
								[serverErrors]="serverErrors">
							</simple-form-input>
						</div>
						<div class="buttons">
							<button class="btn btn-danger decline"
								type="button"
								[disabled]="!formIsValid()"
								(click)="handleDecline()">
								{{ 'Decline' | translate }}
							</button>
							<button class="btn btn-success accept"
								type="submit"
								[disabled]="!formIsValid()"
								(click)="handleAccept()">
								{{ 'Accept' | translate }}
							</button>
						</div>
					</section>
					<dots-loader *ngIf="submitting"></dots-loader>
			</section>
		</form>
	</ng-template>

	<!-- Accepted invite template -->
	<ng-template #acceptTemplate>
		<ng-container *ngIf="acceptedInvite">
			<div class="info accept-no-register" *ngIf="!isRegistering">
				<div class="bi bi-hand-thumbs-up"></div>
				<span
					[innerHTML]="('AcceptedInvitation' | translate).toHtmlBreakLine()">
				</span>
				<button class="btn btn-success go-to-login"
					(click)="goToLogin()">
					{{ 'Go to login page' | translate }}
				</button>
			</div>
			<form #formRef="ngForm" *ngIf="isRegistering"
				[formGroup]="registerForm">
				<section class="section register-section"
					[ngClass]="{'loadmask': submitting}">
						<header class="section-header">
							<h1 class="title">{{ "RegisterAccount" | translate }}</h1>
							<span>
								{{ "RegisterAccountSubtitle" | translate }}
							</span>
						</header>
						<section class="section-body">
							<div class="organization">
								<div class="organization-logo" #parentDiv *ngIf="changeModel.organization.logotypeUrl">
									<extensive-image
										[imageUrl]="changeModel.organization.logotypeUrl"
										[imageStorageSize]="parentDiv | getImageStorageSize"
										[placeholderImageType]="'organization'">
									</extensive-image>
								</div>
								<div class="organization-name">
									{{ changeModel.organization.orgName }}
								</div>
							</div>
							<div class="input-email read-only">
								{{ changeModel.email }}
							</div>
							<div class="inputs-wrapper">
								<div class="form-group input">
									<simple-form-input
										[label]="'Firstname' | translate"
										[name]="propertyStrings.firstname"
										[form]="registerForm"
										[maxlength]="25"
										[serverErrors]="serverErrors">
									</simple-form-input>
								</div>
								<div class="form-group input">
									<simple-form-input
										[label]="'Lastname' | translate"
										[name]="propertyStrings.lastname"
										[form]="registerForm"
										[maxlength]="25"
										[serverErrors]="serverErrors">
									</simple-form-input>
								</div>
								<div class="form-group input">
									<simple-form-input
										[label]="'NewPassword' | translate"
										[name]="propertyStrings.newPassword"
										[form]="registerForm"
										[serverErrors]="serverErrors"
										[usePasswordType]="true">
									</simple-form-input>
								</div>
								<div class="form-group input">
									<simple-form-input
										[label]="'ConfirmPassword' | translate"
										[name]="propertyStrings.confirmPassword"
										[form]="registerForm"
										[serverErrors]="serverErrors"
										[usePasswordType]="true">
									</simple-form-input>
								</div>
							</div>
						</section>
						<footer class="section-footer">
							<button type="submit" class="btn btn-success"
								[disabled]="!registerFormIsValid()"
								(click)="register()">
								{{ "RegisterAccount" | translate }}
							</button>
						</footer>
						<dots-loader *ngIf="submitting"></dots-loader>
				</section>
			</form>
		</ng-container>
	</ng-template>

	<!-- Declined invite template -->
	<ng-template #declineTemplate>
		<div class="info decline" *ngIf="declinedInvite">
			<div class="bi bi-hand-thumbs-down"></div>
			<span
				[innerHTML]="('DeclinedInvitation' | translate)">
			</span>
		</div>
	</ng-template>

	<!-- Not ready template -->
	<ng-template #notReadyTemplate>
		<div class="info not-ready" *ngIf="!ready">
			<div class="bi bi-exclamation-triangle"></div>
			<span
				[innerHTML]="('LinkNotValid' | translate)">
			</span>
		</div>
	</ng-template>
</div>