<div class="heading-wrapper"
	title="{{ title }}">
		<div class="icon {{icon}}">
		</div>
		<div class="title">
			{{ title }}
			<ng-container *ngIf="displayCount >= 0">
				({{ displayCount }})
			</ng-container>
		</div>
		<simple-help *ngIf="helpText"
			[helpText]="helpText">
		</simple-help>
</div>
