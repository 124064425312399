import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';
import { ColorUtils } from 'app-core/shared-core/tools/color-utils';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { AssignmentTemplate } from 'app-inspection/assignment-template/assignment-template';
import { ListAssignmentTemplateComponent } from 'app-inspection/assignment-template/list-assignment-template/list-assignment-template.component';
import { TemplateType } from 'app-inspection/template-type/template-type';

@Component({
	selector: 'handle-assignment-templates-tab',
	templateUrl: './handle-assignment-templates-tab.component.html',
	styleUrls: ['./handle-assignment-templates-tab.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandleAssignmentTemplatesTabComponent extends SimpleHandleTabDirective<AssignmentTemplate> {

	colorUtils = ColorUtils;

	toggleListAssignmentTemplateModal() {
		this.bsModalRef = this.modalService.show(
			ListAssignmentTemplateComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: this.readonlyListModal,
					hideTableHeaderActions: this.readonlyListModal,
					hideTableRowActions: this.readonlyListModal,
					idsToSetAsSelected: this.handleItems.map(item => item.id)
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	itemListIsValid() {
		return true;
	}

	override emitModelsChange() {
		this.handleItems.sortByProperty(`${this.propertyStrings.templateType} ${new TemplateType({}).propertyStrings.name}`);
		super.emitModelsChange();
	}

	validate() { }

	decreaseQuantity(assignmentTemplate: AssignmentTemplate) {
		if (assignmentTemplate.quantity > 1) {
			assignmentTemplate.quantity -= 1;
		}
	}

	increaseQuantity(assignmentTemplate: AssignmentTemplate) {
		if (assignmentTemplate.quantity < 10) {
			assignmentTemplate.quantity += 1;
		}
	}

	handleModelChange(assignmentTemplate: AssignmentTemplate, value: number) {
		assignmentTemplate.quantity = value && value > 0 && value < 11
			? value
			: null;
	}

	handleFocus(assignmentTemplate: AssignmentTemplate) {
		assignmentTemplate.quantityCached = assignmentTemplate.quantity;
		assignmentTemplate.quantity = null;
	}

	handleBlur(assignmentTemplate: AssignmentTemplate) {
		if (assignmentTemplate.quantity) {
			assignmentTemplate.quantityCached = assignmentTemplate.quantity;
		} else {
			assignmentTemplate.quantity = assignmentTemplate.quantityCached;
		}
	}

	handleWheel(assignmentTemplate: AssignmentTemplate, event: WheelEvent) {
		event.preventDefault();
		const scrollUp = event.deltaY < 0;
		if (scrollUp) {
			this.increaseQuantity(assignmentTemplate);
		} else {
			this.decreaseQuantity(assignmentTemplate);
		}
	}

	togglePublishDirectly(assignmentTemplate: AssignmentTemplate) {
		assignmentTemplate.publishDirectly = !assignmentTemplate.publishDirectly;
	}

	getItems() {
		return this.searchValue
			? this.handleItems.filter(item => item.name.toLowerCase().includes(this.searchValue.toLowerCase())
				|| item.facility.name.toLowerCase().includes(this.searchValue.toLowerCase()))
			: this.handleItems;
	}
}
