import { SimpleAction } from '../../simple-action';

export class SimpleTableHeaderAction extends SimpleAction {

	readonly event: () => void;
	readonly hidden: () => boolean;
	isDeleteAction: boolean;

	constructor(
		name: string,
		iconName: string,
		event: () => void,
		hidden?: () => boolean) {
		super(
			name,
			iconName
		);
		this.event = event;
		this.hidden = hidden ? hidden : () => false;
	}
}

export class SimpleTableHeaderActionDelete extends SimpleTableHeaderAction {
	isDeleteAction = true;
}
