import { Component, ElementRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { ExportService } from 'app-core/shared-core/export/export.service';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { ImportActionKey, ImportExportModelKey } from 'app-core/shared-core/import/import-helper';
import { ImportService } from 'app-core/shared-core/import/import.service';
import { SimpleListAction } from 'app-core/shared-core/simple-components/list/actions/simple-list-action';
import { SimpleListDirective } from 'app-core/shared-core/simple-components/list/simple-list.directive';
import { SimpleTableRowActionDelete, SimpleTableRowActionEdit } from 'app-core/shared-core/simple-components/list/table/body/simple-table-row-action';
import { SimpleTableChoicesColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-choices-column';
import { EventsContent, SimpleTableEventsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-events-column';
import { SimpleTableRulesColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-rules-column';
import { SimpleTableTextColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-text-column';
import { SimpleTableEmptyState } from 'app-core/shared-core/simple-components/list/table/empty-state/simple-table-empty-state';
import { SimpleFilterInput, SimpleFilterInputType } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-input-settings';
import { SimpleFilterInputItem } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-input-item';
import { SimpleRetainService } from 'app-core/shared-core/simple-components/list/table/filter/simple-retain.service';
import { TextContent } from 'app-core/shared-core/simple-components/various/text-content/simple-text-content.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SimpleFilterConfig, SortObject } from '../../../app-core/shared-core/simple-components/list/table/filter/simple-filter-config';
import { SimpleTableHeaderActionDelete } from '../../../app-core/shared-core/simple-components/list/table/head/simple-table-header-action';
import { SimpleTableConfig } from '../../../app-core/shared-core/simple-components/list/table/simple-table-config';
import { CreateEditTaskComponent } from '../create-edit-task/create-edit-task.component';
import { DeleteTasksComponent } from '../delete-tasks/delete-tasks.component';
import { Task } from '../task';
import { TaskFilter, TaskService } from '../task.service';

@Component({
	selector: 'list-task',
	templateUrl: '../../../app-core/shared-core/simple-components/list/simple-list-shared-template.html'
})
export class ListTaskComponent extends SimpleListDirective<Task> {

	// Override filterObject to match backend and to set custom query params for this context.
	readonly filterObject = new TaskFilter();

	// When used inside a modal.
	includeChoices: boolean;
	templateTypeId: string;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected sanitizer: DomSanitizer,
		protected taskService: TaskService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected retainService: SimpleRetainService,
		protected elementRef: ElementRef,
		protected viewContainerRef: ViewContainerRef,
		private importService: ImportService<Task>,
		private exportService: ExportService<Task>) {
		super(
			authService,
			modalService,
			toastrService,
			translationService,
			taskService,
			route,
			router,
			retainService,
			elementRef,
			viewContainerRef,
			RoutesUtils.task
		);
	}

	protected configureListActions() {
		this.setListActions(
			new SimpleListAction(
				this.translationService.instant('Create'),
				this.translationService.instant('NewTask'),
				StringUtils.icons.new,
				() => {
					if (!this.isInModal) {
						this.setCrudParams(RoutesUtils.modalPrefixValueNew);
					} else {
						this.openModalByActionName(RoutesUtils.modalPrefixValueNew);
					}
				}
			),
			new SimpleListAction(
				this.translationService.instant('Import'),
				this.translationService.instant('Import'),
				StringUtils.icons.import,
				() => {
					this.importInput.nativeElement.click();
				},
				() => {
					return !this.loggedInUser.isSuperAdmin();
				}
			),
			new SimpleListAction(
				this.translationService.instant('Export'),
				this.translationService.instant('Export'),
				StringUtils.icons.export,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.export(selectedIds);
				},
				() => {
					return !this.loggedInUser.isSuperAdmin();
				}
			)
		);

		if (this.loggedInUser.isSuperAdmin()) {

			// Check if has any imports to start monitoring.
			this.importService.hasImportInProgress(ImportExportModelKey.Task, ImportActionKey.ImportTasks)
				.then(inProgress => {
					if (inProgress) {
						this.importService.monitorProgress(ImportExportModelKey.Task, ImportActionKey.ImportTasks, this);
					}
				})
				.catch(response => {
					this.handleErrorResponse(response);
				});
		}
	}

	protected configureTableFilter(config: SimpleFilterConfig<Task>) {
		if (this.includeChoices) {
			this.filterObject.includeChoices = this.includeChoices;
		}
		if (this.templateTypeId) {
			config.setInitialFacets(
				new KeyValuePair(StringUtils.TEMPLATE_TYPE_ID_KEY, this.templateTypeId)
			);
		}

		const rulesKey = StringUtils.RULES_KEY;
		const createdKey = StringUtils.CREATED_KEY;
		const updatedKey = StringUtils.UPDATED_KEY;
		if (!this.isInModal) {
			this.retainService.setCurrentRetainEntries({
				search: null,
				sort: null,
				[rulesKey]: null,
				[createdKey]: null,
				[updatedKey]: null
			});
		}

		config.setFilterInputs(
			new SimpleFilterInput(
				rulesKey,
				this.translationService.instant(rulesKey),
				SimpleFilterInputType.MultiSelect,
				(values: string) => {
					const keyValuePairs: KeyValuePair[] = [];
					if (values) {
						keyValuePairs.push(new KeyValuePair(rulesKey, values));
					} else {
						keyValuePairs.push(new KeyValuePair(rulesKey, ''));
					}
					return keyValuePairs;
				},
				new SimpleFilterInputItem(
					StringUtils.IS_REQUIRED,
					this.translationService.instant(StringUtils.IS_REQUIRED)
				),
				new SimpleFilterInputItem(
					StringUtils.ALLOWS_COMMENT,
					this.translationService.instant(StringUtils.ALLOWS_COMMENT)
				),
				new SimpleFilterInputItem(
					StringUtils.ALLOWS_MEDIA,
					this.translationService.instant(StringUtils.ALLOWS_MEDIA)
				),
				new SimpleFilterInputItem(
					StringUtils.IS_DEFAULT,
					this.translationService.instant(StringUtils.IS_DEFAULT)
				)
			),
			new SimpleFilterInput(
				createdKey,
				this.translationService.instant(createdKey),
				SimpleFilterInputType.DateSelect,
				(values: string) => {
					const keyValuePairs: KeyValuePair[] = [];
					if (values) {
						keyValuePairs.push(new KeyValuePair(createdKey, values));
					} else {
						keyValuePairs.push(new KeyValuePair(createdKey, ''));
					}
					return keyValuePairs;
				}
			),
			new SimpleFilterInput(
				updatedKey,
				this.translationService.instant(updatedKey),
				SimpleFilterInputType.DateSelect,
				(values: string) => {
					const keyValuePairs: KeyValuePair[] = [];
					if (values) {
						keyValuePairs.push(new KeyValuePair(updatedKey, values));
					} else {
						keyValuePairs.push(new KeyValuePair(updatedKey, ''));
					}
					return keyValuePairs;
				}
			)
		);
	}

	protected configureTableSort(config: SimpleFilterConfig<Task>) {
		config.setSortObjects(
			new SortObject(
				this.propertyStrings.text,
				this.translationService.instant('Name'),
				true
			),
			new SortObject(
				this.propertyStrings.description,
				this.translationService.instant('Description')
			),
			new SortObject(
				this.propertyStrings.created,
				this.translationService.instant('Created')
			),
			new SortObject(
				this.propertyStrings.updated,
				this.translationService.instant('Updated')
			)
		);
	}

	protected configureTableColumns(config: SimpleTableConfig<Task>) {
		config.setColumns(
			new SimpleTableTextColumn(
				this.translationService.instant('Name'),
				(item) => new TextContent(
					item.text,
					item.code,
					'',
					''
				),
				this.sanitizer,
				this.viewContainerRef,
				this.translationService.instant('Code')
			),
			new SimpleTableTextColumn(
				this.translationService.instant('Description'),
				(item) => new TextContent(
					item.description,
					'',
					'',
					''
				),
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableRulesColumn(
				this.translationService.instant('Rules'),
				(item) => {
					return item.rulesReadonly;
				},
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			this.includeChoices ?
				new SimpleTableChoicesColumn(
					this.translationService.instant('Choices'),
					(item) => item.choices,
					this.sanitizer,
					this.viewContainerRef,
					''
				)
				: null,
			new SimpleTableEventsColumn(
				this.translationService.instant('Events'),
				(item) => [
					new EventsContent(`${this.translationService.instant('Created')} ${Utils.getFormattedDateStringFromString(item.created, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.created, 'bi-file-earmark-plus'),
					Utils.dateIsAfter(item.updated, item.created)
						? new EventsContent(`${this.translationService.instant('Updated')} ${Utils.getFormattedDateStringFromString(item.updated, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.updated, 'bi-file-earmark-text')
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			)
		);
	}

	protected configureTableActions(config: SimpleTableConfig<Task>) {

		// HEADER
		config.setHeaderActions(
			new SimpleTableHeaderActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${selectedIds.join()}`);
				}
			)
		);

		// ROW
		config.setRowActions(
			new SimpleTableRowActionEdit(
				this.translationService.instant('Open'),
				StringUtils.icons.handle,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					}
				}
			),
			new SimpleTableRowActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					}
				}
			)
		);
	}

	protected configureTableEmptyState(config: SimpleTableConfig<Task>) {
		config.emptyState = new SimpleTableEmptyState(
			'Tasks',
			StringUtils.icons.task
		);
	}

	protected setPropertiesAndOpenViewObjectsPopover() { }

	protected openModalByActionName(value: string) {
		const [actionName, idString] = value.split('_');
		this.shouldClearAfterUpdate = !this.isInModal && this.isBulkAction(actionName);
		if (actionName === RoutesUtils.modalPrefixValueNew) {
			this.bsModalRef = this.modalService.show(
				CreateEditTaskComponent,
				{
					initialState: {
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueEdit) {
			this.bsModalRef = this.modalService.show(
				CreateEditTaskComponent,
				{
					initialState: {
						editModelId: idString,
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueDelete) {
			this.bsModalRef = this.modalService.show(
				DeleteTasksComponent,
				{
					initialState: {
						deleteModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_SMALL
				}
			);
		}
		this.subscribeToCrudModalContent();
	}

	protected import(file: File) {
		this.importService.hasImportInProgress(ImportExportModelKey.Task, ImportActionKey.ImportTasks)
			.then(inProgress => {
				if (inProgress) {
					this.toastrService.error(`${this.translationService.instant(StringUtils.IMPORT_IN_PROGRESS)}!`);
				} else {
					this.importService.import(file, ImportExportModelKey.Task, ImportActionKey.ImportTasks, this);
				}
			})
			.catch(response => {
				this.handleErrorResponse(response);
			});
	}

	protected export(selectedIds: string[]) {
		this.exportService.export(selectedIds, ImportExportModelKey.Task, this);
	}
}
