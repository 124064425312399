import { Component, ElementRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { SimpleListAction } from 'app-core/shared-core/simple-components/list/actions/simple-list-action';
import { SimpleListDirective } from 'app-core/shared-core/simple-components/list/simple-list.directive';
import { SimpleTableRowActionDelete, SimpleTableRowActionEdit } from 'app-core/shared-core/simple-components/list/table/body/simple-table-row-action';
import { EventsContent, SimpleTableEventsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-events-column';
import { SimpleTableTextColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-text-column';
import { SimpleTableEmptyState } from 'app-core/shared-core/simple-components/list/table/empty-state/simple-table-empty-state';
import { SimpleFilterInput, SimpleFilterInputType } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-input-settings';
import { SimpleFilterConfig, SortObject } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-config';
import { SimpleFilterInputItem } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-input-item';
import { SimpleRetainService } from 'app-core/shared-core/simple-components/list/table/filter/simple-retain.service';
import { SimpleTableHeaderActionDelete } from 'app-core/shared-core/simple-components/list/table/head/simple-table-header-action';
import { SimpleTableConfig } from 'app-core/shared-core/simple-components/list/table/simple-table-config';
import { TextContent } from 'app-core/shared-core/simple-components/various/text-content/simple-text-content.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { AssignmentService } from 'app-inspection/assignment/assignment.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../app-core/auth/auth.service';
import { CreateEditTemplateTypeComponent } from '../create-edit-template-type/create-edit-template-type.component';
import { DeleteTemplateTypesComponent } from '../delete-template-types/delete-template-types.component';
import { TemplateType } from '../template-type';
import { TemplateTypeFilter, TemplateTypeService } from '../template-type.service';

@Component({
	selector: 'list-template-type',
	templateUrl: '../../../app-core/shared-core/simple-components/list/simple-list-shared-template.html'
})
export class ListTemplateTypeComponent extends SimpleListDirective<TemplateType> {

	// Override filterObject to set custom query params for this context.
	readonly filterObject = new TemplateTypeFilter();

	// When used inside a modal.
	parentOrganizationId: number;

	hasTemplateBaseTypes: boolean;

	isDoneFetchingTemplateTypes: boolean = false;
	manualMeasureTT: TemplateType;
	manualMeasureTTFromParent: TemplateType;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected sanitizer: DomSanitizer,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected templateTypeService: TemplateTypeService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected retainService: SimpleRetainService,
		protected elementRef: ElementRef,
		protected viewContainerRef: ViewContainerRef,
		private assignmentService: AssignmentService) {
		super(
			authService,
			modalService,
			toastrService,
			translationService,
			templateTypeService,
			route,
			router,
			retainService,
			elementRef,
			viewContainerRef,
			RoutesUtils.templateType
		);
	}

	protected configureListActions() {
		this.setListActions(
			new SimpleListAction(
				this.translationService.instant('Create'),
				this.translationService.instant('NewTemplateType'),
				StringUtils.icons.new,
				() => {
					if (!this.isInModal) {
						this.setCrudParams(RoutesUtils.modalPrefixValueNew);
					} else {
						this.openModalByActionName(RoutesUtils.modalPrefixValueNew);
					}
				},
				() => {
					return !this.hasTemplateBaseTypes;
				}
			),
			new SimpleListAction(
				this.translationService.instant('ImportFromParentOrganization'),
				this.translationService.instant('ImportFromParentOrganization'),
				StringUtils.icons.import,
				() => {
					this.openListModalTemplateType();
				},
				() => {
					return !this.selectedOrganization.parentId || !this.isDoneFetchingTemplateTypes;
				}
			)
		);

		if (this.loggedInUser.isSuperAdmin() && this.selectedOrganization.parentId) {
			this.setImportManualMeasureFlags();
		}
	}

	protected async configureTableFilter(config: SimpleFilterConfig<TemplateType>) {
		if (this.parentOrganizationId) {
			this.filterObject.parentOrganizationId = this.parentOrganizationId;
		}

		const baseTypesKey = StringUtils.BASE_TYPES_KEY;
		const createdKey = StringUtils.CREATED_KEY;
		const updatedKey = StringUtils.UPDATED_KEY;
		if (!this.isInModal) {
			this.retainService.setCurrentRetainEntries({
				search: null,
				sort: null,
				[baseTypesKey]: null,
				[createdKey]: null,
				[updatedKey]: null
			});
		}

		try {
			const templateBaseTypes = await this.templateTypeService.getTemplateBaseTypes();
			const convertedTemplateBaseTypes = templateBaseTypes.map(baseType => new SimpleFilterInputItem(baseType.id, baseType.name));

			this.hasTemplateBaseTypes = !!templateBaseTypes.length;

			config.setFilterInputs(
				new SimpleFilterInput(
					baseTypesKey,
					this.translationService.instant(baseTypesKey),
					SimpleFilterInputType.MultiSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(baseTypesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(baseTypesKey, ''));
						}
						return keyValuePairs;
					},
					...convertedTemplateBaseTypes
				),
				new SimpleFilterInput(
					createdKey,
					this.translationService.instant(createdKey),
					SimpleFilterInputType.DateSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(createdKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(createdKey, ''));
						}
						return keyValuePairs;
					}
				),
				new SimpleFilterInput(
					updatedKey,
					this.translationService.instant(updatedKey),
					SimpleFilterInputType.DateSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(updatedKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(updatedKey, ''));
						}
						return keyValuePairs;
					}
				)
			);

			if (!this.isInModal) {
				// Needs to be called here due to the data fetch delay.
				this.setSelectedFilterItems();
			}

		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}

	protected configureTableSort(config: SimpleFilterConfig<TemplateType>) {
		config.setSortObjects(
			new SortObject(
				this.propertyStrings.name,
				this.translationService.instant('Name'),
				true
			),
			new SortObject(
				'TemplateBaseType.Name' as keyof TemplateType,
				this.translationService.instant('BaseType')
			),
			new SortObject(
				this.propertyStrings.created,
				this.translationService.instant('Created')
			),
			new SortObject(
				this.propertyStrings.updated,
				this.translationService.instant('Updated')
			)
		);
	}

	protected configureTableColumns(config: SimpleTableConfig<TemplateType>) {
		config.setColumns(
			new SimpleTableTextColumn(
				this.translationService.instant('Name'),
				(item) => new TextContent(
					item.name,
					item.abbreviation,
					'',
					''
				),
				this.sanitizer,
				this.viewContainerRef,
				this.translationService.instant('Abbreviation')
			),
			new SimpleTableTextColumn(
				this.translationService.instant('BaseType'),
				(item) => new TextContent(
					item.templateBaseType.name,
					'',
					'',
					''
				),
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableEventsColumn(
				this.translationService.instant('Events'),
				(item) => [
					new EventsContent(`${this.translationService.instant('Created')} ${Utils.getFormattedDateStringFromString(item.created, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.created, 'bi-file-earmark-plus'),
					Utils.dateIsAfter(item.updated, item.created)
						? new EventsContent(`${this.translationService.instant('Updated')} ${Utils.getFormattedDateStringFromString(item.updated, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.updated, 'bi-file-earmark-text')
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			)
		);
	}

	protected configureTableActions(config: SimpleTableConfig<TemplateType>) {

		// HEADER
		config.setHeaderActions(
			new SimpleTableHeaderActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${selectedIds.join()}`);
				}
			)
		);

		// ROW
		config.setRowActions(
			new SimpleTableRowActionEdit(
				this.translationService.instant('Open'),
				StringUtils.icons.handle,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					}
				}
			),
			new SimpleTableRowActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					}
				}
			)
		);
	}

	protected configureTableEmptyState(config: SimpleTableConfig<TemplateType>) {
		config.emptyState = new SimpleTableEmptyState(
			'TemplateTypes',
			StringUtils.icons.templateType
		);
	}

	protected setPropertiesAndOpenViewObjectsPopover() { }

	protected openModalByActionName(value: string) {
		const [actionName, idString] = value.split('_');
		this.shouldClearAfterUpdate = !this.isInModal && this.isBulkAction(actionName);
		if (actionName === RoutesUtils.modalPrefixValueNew) {
			this.bsModalRef = this.modalService.show(
				CreateEditTemplateTypeComponent,
				{
					initialState: {
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_X_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueEdit) {
			this.bsModalRef = this.modalService.show(
				CreateEditTemplateTypeComponent,
				{
					initialState: {
						editModelId: idString,
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_X_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueDelete) {
			this.bsModalRef = this.modalService.show(
				DeleteTemplateTypesComponent,
				{
					initialState: {
						deleteModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_SMALL
				}
			);
		}
		this.subscribeToCrudModalContent();
	}

	private openListModalTemplateType() {
		this.bsModalRef = this.modalService.show(
			ListTemplateTypeComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: true,
					hideTableHeaderActions: true,
					hideTableRowActions: true,
					parentOrganizationId: this.selectedOrganization.parentId,
					idsToSetAsUnselectable: this.manualMeasureTT && this.manualMeasureTTFromParent ? [this.manualMeasureTTFromParent.id] : []
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);

		const listComponent = this.bsModalRef.content;
		this.subscriptions.add(
			listComponent.confirmed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(async confirmedItems => {
					if (confirmedItems) {
						if (confirmedItems.length) {
							this.pending = true;
							try {
								const response = await this.templateTypeService.importFromParent(confirmedItems, this.selectedOrganization.id);
								this.pending = false;

								this.displaySuccessMessage(response.successMessage);

								this.getTableData();

								this.setImportManualMeasureFlags();

							} catch (errorResponse) {
								this.pending = false;
								this.handleErrorResponse(errorResponse);
							}
						}
						this.closeModal();
					}
				})
		);
		this.subscriptions.add(
			listComponent.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(wasClosed => {
					if (wasClosed) {
						this.closeModal();
					}
				})
		);
	}

	protected import(_: File) { }
	protected export(_: string[]) { }

	private async setImportManualMeasureFlags() {
		// Make sure we can't import manual measure from parent if we already have one in this organization.
		try {
			this.isDoneFetchingTemplateTypes = false;
			const templateTypes = await this.assignmentService.getTemplateTypes(this.selectedOrganization.id);
			const templateTypesFromParent = await this.assignmentService.getTemplateTypes(this.selectedOrganization.parentId);
			this.isDoneFetchingTemplateTypes = true;
			this.manualMeasureTT = templateTypes.find(item => item.isManualMeasure);
			this.manualMeasureTTFromParent = templateTypesFromParent.find(item => item.isManualMeasure);

		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}
}
