import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AuthService } from 'app-core/auth/auth.service';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { ALT_DATE_TIME_FORMAT, DEFAULT_DATE_FORMAT, DEFAULT_DATE_FORMAT_TIME, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import * as moment from 'moment';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ReplaySubject, Subscription } from 'rxjs';
import { filter, repeatWhen, takeUntil } from 'rxjs/operators';
import {
	OrganizationAndUserCommonData,
	ReferenceTypes,
	SpanTypes,
	StatisticsAssignments,
	StatisticsAssignmentsByOrganizationName,
	StatisticsAssignmentsData,
	StatisticsOrganizationData,
	StatisticsOrganizations,
	StatisticsPayload,
	StatisticsTypes,
	StatisticsUserData,
	StatisticsUsers,
	StatisticsUsersByOrganizationName
} from './statistics';
import { StatisticsService } from './statistics.service';

@Component({
	selector: 'statistics-related-data-modal',
	templateUrl: 'statistics-related-data-modal.component.html',
	styleUrls: ['./statistics.component.less']
})

export class StatisticsRelatedDataModalComponent implements OnInit, OnDestroy {

	refreshing = false;
	header: string;
	externalError: string = null;
	internalError: string = null;
	assignmentStatisticsData: StatisticsAssignmentsByOrganizationName[] = null;
	organizationStatisticsData: StatisticsOrganizationData[] = null;
	userStatisticsData: StatisticsUsersByOrganizationName[] = null;
	newDataAvailable: boolean = false;
	statisticsTypesEnum = StatisticsTypes;
	dateFormat = ALT_DATE_TIME_FORMAT;
	dateFormatDate = DEFAULT_DATE_FORMAT;
	dateFormatTime = DEFAULT_DATE_FORMAT_TIME;
	statisticsPayloadAssignment = StatisticsPayload;
	expandedSection: string;

	private isAssignmentData: boolean;
	private assignmentStateRoute: string;
	private templateTypeIds: string[] = [];
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	private _subscriptions = new Subscription();
	private _statisticalDataSubscription = new Subscription();

	@Input() payload: StatisticsPayload;
	@Output() onClose = new EventEmitter<any>();

	@ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
	@ViewChildren('section') sections: QueryList<ElementRef>;


	constructor(
		private statisticsService: StatisticsService,
		private translationService: TranslationService,
		private cdRef: ChangeDetectorRef,
		private authService: AuthService) { }

	ngOnInit() {
		if (!this.authService.loggedInUser.isSuperAdmin()) {
			return;
		}

		this.isAssignmentData = this.payload.discriminator.toString() === ReferenceTypes[ReferenceTypes.MeasureAssignment]
			|| this.payload.discriminator.toString() === ReferenceTypes[ReferenceTypes.ScheduledAssignment];

		if (this.isAssignmentData) {
			this.assignmentStateRoute = this.payload?.type === StatisticsTypes.Created
				? RoutesUtils.scheduledOngoing
				: RoutesUtils.scheduledArchived;
		}

		const refreshingStarted$ = this.statisticsService.refreshing$.pipe(
			takeUntil(this.destroyed$),
			filter(o => o));
		const refreshingDone$ = this.statisticsService.refreshing$.pipe(
			takeUntil(this.destroyed$),
			filter(o => !o));

		this._subscriptions.add(
			this.statisticsService.error$
				.pipe(
					takeUntil(this.destroyed$),
					takeUntil(refreshingStarted$),
					repeatWhen(_ => refreshingDone$)
				)
				.subscribe(state => {
					this.externalError = state;
					this.cdRef.detectChanges();
				})
		);

		this._subscriptions.add(
			this.statisticsService.refreshingDone$
				.pipe(takeUntil(this.destroyed$))
				.subscribe(state => {
					if (state) {
						this.newDataAvailable = true;
						// this.statisticsService.refreshingDone$.next(false);
					}
				})
		);

		const translatedPayload = {
			discriminator: '<b class="strong">[' + this.translationService.instant(
				this.payload.discriminator + 'Statistics')
				.toLowerCase() + ']</b>',
			type: '<b class="strong">[' + this.translationService.instant(
				StatisticsTypes[this.payload?.type] + 'StatisticsAssignment')
				.toLowerCase() + ']</b>',
			the: this.translationService.instant(
				this.payload.span === SpanTypes.LastThirtyDays
					? 'ThePlural'
					: 'TheSingular')
				.toLowerCase(),
			span: '<b class="strong">[' + this.translationService.instant(
				SpanTypes[this.payload.span])
				.toLowerCase() + ']</b>',
		};

		if (!this.isAssignmentData) {
			// translatedPayload.discriminator = '';
			translatedPayload.type = '';
		}

		if (this.payload.span === SpanTypes.Total) {
			translatedPayload.the = '';
			translatedPayload.span = '';
		}

		this.header = this.translationService.instant(
			this.payload.span === SpanTypes.LastDay
				? 'StatisticsForTypesToday'
				: 'StatisticsForTypes',
			translatedPayload);

		this.mapStatistics();
	}

	trackByFn(index, item) {
		return item.id;
	}

	renderError() {
		this.internalError = this.translationService.instant('StatisticDataNotFound', {
			span: this.translationService.instant(SpanTypes[this.payload.span]).toLowerCase()
		});
	}

	mapStatistics() {
		const statisticalData = this.statisticsService.statisticsRelatedData$.getValue();
		const commonDataHelper = (data: OrganizationAndUserCommonData) => {
			if (moment(data.createdByName, this.dateFormat).isValid()) {
				data.createdByName = this.translationService.instant('UserDeletedOn', { date: moment(data.createdByName).format(this.dateFormat) });
				data.createdByNameShort = this.translationService.instant('DeletedStatisticsHeader');
			}

			if (moment(data.updatedByName, this.dateFormat).isValid()) {
				data.updatedByName = this.translationService.instant('UserDeletedOn', { date: moment(data.updatedByName).format(this.dateFormat) });
				data.updatedByNameShort = this.translationService.instant('DeletedStatisticsHeader');
			}
		};

		if (statisticalData instanceof StatisticsAssignments) {
			statisticalData?.assignments.map(p => {
				if (String(this.payload.discriminator) === ReferenceTypes[ReferenceTypes.ScheduledAssignment]
					&& this.templateTypeIds.indexOf(p.templateTypeId) === -1) {
					this.templateTypeIds.push(p.templateTypeId);
				}

				if (this.payload?.type !== StatisticsTypes.Deleted) {
					p.url = `${p.friendlyOrganizationName}/${RoutesUtils.assignments}/${this.assignmentStateRoute}?assignment=edit_${p.id}&tab=1`;
					p.linkTitle = this.translationService.instant(StringUtils.OPEN_IN_NEW_TAB, { 0: p.name });
				}

				if (!p.actionById && !p.actionByName) {
					p.actionByName = this.translationService.instant('NoUserFound');
					p.actionByNameShort = this.translationService.instant('NoUserFound');
				} else if (moment(p.actionByName, this.dateFormat).isValid()) {
					p.actionByName = this.translationService.instant('UserDeletedOn', { date: moment(p.actionByName).format(this.dateFormat) });
					p.actionByNameShort = this.translationService.instant('DeletedStatisticsHeader');
				}

				if (moment(p.updatedByName, this.dateFormat).isValid()) {
					p.updatedByName = this.translationService.instant('UserDeletedOn', { date: moment(p.updatedByName).format(this.dateFormat) });
					p.updatedByNameShort = this.translationService.instant('DeletedStatisticsHeader');
				}
				return p;
			});
		} else if (statisticalData instanceof StatisticsOrganizations) {
			statisticalData?.organizations.map(p => {
				p.url = `${p.friendlyUrl}/${RoutesUtils.organization}`;
				p.linkTitle = this.translationService.instant(StringUtils.OPEN_IN_NEW_TAB, { 0: p.name });
				commonDataHelper(p);
				return p;
			});
		} else if (statisticalData instanceof StatisticsUsers) {
			statisticalData?.users.map(p => {
				p.url = `${p.organizationFriendlyUrl}/${RoutesUtils.users}?user=edit_${p.id}&tab=1`;
				p.linkTitle = this.translationService.instant(StringUtils.OPEN_IN_NEW_TAB, { 0: `${p.firstname} ${p.lastname}` });
				commonDataHelper(p);
				return p;
			});
		}

		this.loadStatisticalData(statisticalData);
		this.refreshing = false;
		this.newDataAvailable = false;
	}

	loadStatisticalData(data: StatisticsAssignments | StatisticsOrganizations | StatisticsUsers) {
		if (this.isAssignmentData && data && data instanceof StatisticsAssignments) {
			if (!data.assignments.length) {
				this.renderError();
			} else {
				this.externalError = null;
				this.internalError = null;
				const statsByOrgName: StatisticsAssignmentsByOrganizationName[] = [];
				const groupedByOrgName = Utils.groupBy(data.assignments, Utils.nameof<StatisticsAssignmentsData>('organizationName'));
				Object.keys(groupedByOrgName).forEach(o => {
					const array = groupedByOrgName[o] as StatisticsAssignmentsData[];
					const isAnyMeasure = String(this.payload.discriminator) === ReferenceTypes[ReferenceTypes.MeasureAssignment]
						&& array.some(p => !p.isManualMeasure);
					const isAnyManualMeasure = String(this.payload.discriminator) === ReferenceTypes[ReferenceTypes.MeasureAssignment]
						&& array.some(p => p.isManualMeasure);
					statsByOrgName.push(
						new StatisticsAssignmentsByOrganizationName({
							name: o,
							isMeasure: isAnyMeasure,
							isManualMeasure: isAnyManualMeasure,
							friendlyOrganizationName: array[0].friendlyOrganizationName,
							assignments: array
						})
					);
				});

				this.expandedSection = statsByOrgName[0]?.name;
				this.assignmentStatisticsData = statsByOrgName;
			}
		} else if (data && data instanceof StatisticsOrganizations) {
			if (!data.organizations.length) {
				this.renderError();
			} else {
				this.organizationStatisticsData = data.organizations;
			}
		} else if (data && data instanceof StatisticsUsers) {
			if (!data.users.length) {
				this.renderError();
			} else {
				const statsByOrgName: StatisticsUsersByOrganizationName[] = [];
				const groupedByOrgName = Utils.groupBy(data.users, Utils.nameof<StatisticsUserData>('organizationName'));
				Object.keys(groupedByOrgName).forEach(o => {
					const array = groupedByOrgName[o] as StatisticsUserData[];
					statsByOrgName.push(
						new StatisticsUsersByOrganizationName({
							name: o,
							friendlyOrganizationName: array[0].organizationFriendlyUrl,
							users: array
						})
					);
				});

				this.expandedSection = statsByOrgName[0]?.name;
				this.userStatisticsData = statsByOrgName;
			}
		}
	}

	updateData() {
		this.refreshing = true;
		this.mapStatistics();
	}

	navigate(item: any) {
		const modalBody = document.getElementsByClassName('related-data')[0] as HTMLElement;
		if (modalBody) {
			const itemToNavigate = modalBody.parentElement?.querySelector(`div.section-inner[data-location="${item}"]`) as Element;

			if (itemToNavigate) {
				itemToNavigate.scrollIntoView();

				if (itemToNavigate.classList.contains('collapsed') && !!itemToNavigate.querySelector('.clickable')) {
					this.expandSection(itemToNavigate.attributes['data-location'].value);
				}
			}
		}
	}

	expandSection(sectionName: string) {
		if (sectionName === this.expandedSection) {
			return;
		}

		const allSections = this.sections.map(section => section.nativeElement as Element);
		const expanded = allSections.find(sectionElem => !sectionElem.classList.contains('collapsed'));
		const expandedHeader = expanded.querySelector('.h4.type');
		const clicked = allSections.find(sectionElem => sectionElem.attributes['data-location'].value === sectionName);
		const clickedHeader = clicked.querySelector('.h4.type');

		expanded.classList.add('collapsed');
		expandedHeader.classList.add('clickable');
		clicked.classList.remove('collapsed');
		clickedHeader.classList.remove('clickable');
		this.expandedSection = sectionName;
	}

	scrollToTop() {
		const modalBody = document.getElementsByClassName('related-data')[0] as HTMLElement;
		if (modalBody) {
			modalBody.parentElement.scrollTop = 0;
		}
	}

	// TODO uncomment when adjusted for the separation of assignment lists and new retain param logic.
	// showInList(friendlyOrgName: string, manualMeasurePresent: boolean, measurePresent: boolean) {
	// 	const facetParams: KeyValuePair[] = [];

	// 	if (this.payload.discriminator === AssignmentTypes.ScheduledAssignment
	// 		&& this.templateTypeIds.length) {
	// 		// If ScheduleAssignment and any TemplateTypeIds present
	// 		facetParams.push(
	// 			new KeyValuePair(StringUtils.TEMPLATE_TYPE_IDS, this.templateTypeIds.join(',')),
	// 			new KeyValuePair(MANUAL_MEASURE_OBJECT.filterFacetKey, 'false'),
	// 			new KeyValuePair(MEASURE_ASSIGNMENT_OBJECT.filterFacetKey, 'false')
	// 		);
	// 	} else {
	// 		// If MeasureAssignment and the IsMeasure and IsManualMeasure flags has been set
	// 		facetParams.push(
	// 			new KeyValuePair(MANUAL_MEASURE_OBJECT.filterFacetKey, manualMeasurePresent ? 'true' : 'false'),
	// 			new KeyValuePair(MEASURE_ASSIGNMENT_OBJECT.filterFacetKey, measurePresent ? 'true' : 'false')
	// 		);
	// 	}

	// 	if (this.payload.type === StatisticsTypes.Completed) {
	// 		// If archived
	// 		facetParams.push(
	// 			new KeyValuePair('IsCompleted', 'true')
	// 		);
	// 	} else if (this.payload.type === StatisticsTypes.DueDate) {
	// 		// If expired
	// 		facetParams.push(
	// 			new KeyValuePair('IsExpired', 'true')
	// 		);
	// 	}

	// 	// Always add the status condition 'Or'
	// 	facetParams.push(
	// 		new KeyValuePair('StatusCondition', 'Or')
	// 	);

	// 	// Add date filter
	// 	const momentBase = moment();
	// 	let spanFromDate: string;
	// 	const spanToDate = momentBase.format(ALT_DATE_TIME_FORMAT);

	// 	// Special case for the DueDate property as this use the compound key 'Archived'
	// 	const statisTicsTypeProperty = this.payload.type === StatisticsTypes.Completed
	// 		|| this.payload.type === StatisticsTypes.DueDate
	// 			? StringUtils.ARCHIVED_FACET
	// 			: StatisticsTypes[this.payload.type];

	// 	switch (this.payload.span) {
	// 		case SpanTypes.LastThirtyDays:
	// 			spanFromDate = momentBase.subtract(30, 'd').format(ALT_DATE_TIME_FORMAT);
	// 			break;
	// 		case SpanTypes.LastDay:
	// 			spanFromDate = momentBase.subtract(1, 'd').format(ALT_DATE_TIME_FORMAT);
	// 			break;
	// 		case SpanTypes.LastHour:
	// 			spanFromDate = momentBase.subtract(1, 'h').format(ALT_DATE_TIME_FORMAT);
	// 			break;
	// 		case SpanTypes.LastHalfHour:
	// 			spanFromDate = momentBase.subtract(30, 'm').format(ALT_DATE_TIME_FORMAT);
	// 			break;
	// 	}

	// 	facetParams.push(
	// 		new KeyValuePair(`From:${statisTicsTypeProperty}`, spanFromDate),
	// 		new KeyValuePair(`To:${statisTicsTypeProperty}`, spanToDate)
	// 	);

	// 	// Add sort
	// 	let sortParams: KeyValuePair[] = [];
	// 	const sortPropExists = this.payload.type !== StatisticsTypes.Deleted
	// 		&& !!(StatisticsTypes[this.payload.type].charAt(0).toLowerCase()
	// 		+ StatisticsTypes[this.payload.type].slice(1) as keyof Assignment);

	// 	if (sortPropExists) {
	// 		let sortProp: string;

	// 		if (this.payload.type === StatisticsTypes.Completed
	// 			|| this.payload.type === StatisticsTypes.DueDate) {
	// 				sortProp = `${Utils.nameof<Assignment>(StatisticsTypes[StatisticsTypes.DueDate] as keyof Assignment).humanizeString(true)},` +
	// 					`${Utils.nameof<Assignment>(StatisticsTypes[StatisticsTypes.Completed] as keyof Assignment).humanizeString(true)}`;
	// 		} else {
	// 			sortProp = `${Utils.nameof<Assignment>(StatisticsTypes[StatisticsTypes.Created] as keyof Assignment).humanizeString(true)}`;
	// 		}

	// 		sortParams = [
	// 			new KeyValuePair(sortProp, SortDirection.Descending)
	// 		];
	// 	}

	// 	const filterWithParam = {};
	// 	filterWithParam[StringUtils.FILTER_PARAM] = '';

	// 	const url = `${friendlyOrgName}/${RoutesUtils.assignments}/${this.assignmentStateRoute}?${filterWithParamStringified}`;
	// 	window.open(url, '_blank');
	// }

	dataCopied(copiedPop: PopoverDirective) {
		setTimeout(() => {
			copiedPop.hide();
		}, 2000);
	}

	closeModal() {
		this.onClose.emit();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this._statisticalDataSubscription.unsubscribe();
		this._subscriptions.unsubscribe();
	}
}
