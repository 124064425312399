<simple-crud-modal
	[titleText]="('Issue' | translate) + (modifiedModel?.name ? ' ─ ' + modifiedModel?.name : '')"
	[closeText]="'Close' | translate"
	[submitText]="'Update' | translate"
	[disableSubmit]="!everythingIsValid()"
	[isLoading]="pending"
	(onClose)="close()"
	(onSubmit)="submit()">
	<tabset>
		<tab
			[customClass]="issueDetailsIsValid() ? 'valid' : issueDetailsHasErrors() ? 'invalid' : ''"
			(selectTab)="setTabIndexInUrl(1)">
			<ng-template tabHeading>
				<simple-tab-heading
					[icon]="'bi bi-card-text'"
					[title]="'Details' | translate"
					[helpText]="'IssueDetailsEntityHelpText' | translate">
				</simple-tab-heading>
			</ng-template>
			<issue-details-entity-tab
				[changeModel]="modifiedModel"
				[choice]="choice">
			</issue-details-entity-tab>
		</tab>
		<tab
		[customClass]="'valid'"
		(selectTab)="setTabIndexInUrl(2)">
			<ng-template tabHeading>
				<simple-tab-heading
					[icon]="'bi bi-files'"
					[title]="'IssueMedia' | translate"
					[helpText]="'IssueMediaHelpText' | translate"
					[displayCount]="modifiedModel.issueMedia.length">
				</simple-tab-heading>
			</ng-template>
			<handle-media-tab
				[handleItems]="modifiedModel.issueMedia"
				[headerSubtitleText]="'AddedIssueMediaSubtitleMeasure' | translate"
				[onlyImageUpload]="true"
				[canToggleDefault]="false"
				[modifiedTitle]="'AddedIssueMedia' | translate"
				[modifiedAddButtonText]="'AddIssueMedia' | translate"
				[modifiedDummyText]="'NoIssueMediaAdded' | translate"
				(onModelsChange)="handleIssueMedia($event)">
			</handle-media-tab>
		</tab>
	</tabset>
</simple-crud-modal>
