<simple-view-tab>
	<div class="layout-wrapper">
		<div class="layout">
			<div class="view-section">
				<div class="view-section-header">
					{{ "General" | translate }}
				</div>
				<div class="sub-section">
					<div class="info-text"
						title="{{ 'TemplateType' | translate }}">
						<b>{{ 'TemplateType' | translate }}</b>
						<span class="dots"></span>
						<div class="content"
							title="{{ (viewModel.isMeasureAssignment() && !viewModel.isManualMeasureAssignment()) ?
								(stringUtils.MEASURE_ASSIGNMENT | translate) : viewModel.assignmentTemplate.templateType.name }}">
							{{ (viewModel.isMeasureAssignment() && !viewModel.isManualMeasureAssignment()) ?
							(stringUtils.MEASURE_ASSIGNMENT | translate) : viewModel.assignmentTemplate.templateType.name }}
						</div>
					</div>
					<div class="info-text"
						title="{{ 'Facility' | translate }}">
						<b>{{ 'Facility' | translate }}</b>
						<span class="dots"></span>
						<simple-link-button
							[url]="viewModel.facility.url"
							[linkText]="viewModel.facility.name"
							[name]="viewModel.facility.name"
							[shouldDisable]="!loggedInUser.canEdit(selectedOrganization.friendlyUrl)">
						</simple-link-button>
					</div>
					<div class="info-text" *ngIf="viewModel.isMeasureAssignment() && viewModel.entities.length"
						title="{{ 'Entity' | translate }}">
						<b>{{ 'Entity' | translate }}</b>
						<span class="dots"></span>
						<simple-link-button
							[url]="viewModel.entities[0].url"
							[linkText]="viewModel.entities[0].name"
							[name]="viewModel.entities[0].name"
							[shouldDisable]="!loggedInUser.canEdit(selectedOrganization.friendlyUrl)">
						</simple-link-button>
					</div>
					<div class="info-text" *ngIf="!viewModel.isMeasureAssignment()"
						[ngClass]="{
							'use-block': viewModel.assignmentTemplate.categories.length
						}"
						title="{{ 'Categories' | translate }}">
						<b>{{ 'Categories' | translate }}</b>
						<span class="dots" *ngIf="!viewModel.assignmentTemplate.categories.length"></span>
						<div class="content" [ngClass]="{
								'dummy-text': !viewModel.assignmentTemplate.categories.length,
								'apx-scrollbar': viewModel.assignmentTemplate.categories.length,
								'category-wrapper': viewModel.assignmentTemplate.categories.length
							}"
							title="{{ !viewModel.assignmentTemplate.categories.length ? ('NotAdded' | translate) : ''}}">
							<simple-categories *ngIf="viewModel.assignmentTemplate.categories.length"
								[categories]="viewModel.assignmentTemplate.categories">
							</simple-categories>
							<ng-container *ngIf="!viewModel.assignmentTemplate.categories.length">
								{{ 'NotAdded' | translate }}
							</ng-container>
						</div>
					</div>
					<div class="info-text" *ngIf="!viewModel.isMeasureAssignment()"
						title="{{ 'Client' | translate }}">
						<b>{{ 'Client' | translate }}</b>
						<span class="dots"></span>
						<div class="content" [ngClass]="{
								'dummy-text': !viewModel.assignmentTemplate.clientName
							}"
							title="{{ viewModel.assignmentTemplate.clientName
								? viewModel.assignmentTemplate.clientName
								: 'NotAdded' | translate }}">
							{{ viewModel.assignmentTemplate.clientName }}
							<ng-container *ngIf="!viewModel.assignmentTemplate.clientName">
								{{ 'NotAdded' | translate }}
							</ng-container>
						</div>
					</div>
					<div class="info-text" *ngIf="viewModel.dueDate && !viewModel.isExpired && !viewModel.completed"
						title="{{ 'Expires' | translate }}">
						<b>{{ "Expires" | translate }}</b>
						<span class="dots"></span>
						<div class="content"
							title="{{ viewModel.dueDate | datex }}"
							[ngClass]="{
								'use-bold': isTodayAndFuture()
							}">
							{{ getHumanDueDate() }}
						</div>
					</div>
				</div>
				<div class="sub-section">
					<div class="info-text" *ngIf="viewModel.isMeasureAssignment()"
						title="{{ 'IssueNumber' | translate }}">
						<b>{{ 'IssueNumber' | translate }}</b>
						<span class="dots"></span>
						<div class="content"
							title="{{ viewModel.issueNumber }}">
							{{ viewModel.issueNumber }}
						</div>
					</div>
					<div class="info-text" *ngIf="viewModel.isMeasureAssignment()"
						title="{{ 'Prioritized' | translate }}">
						<b>{{ 'Prioritized' | translate }}</b>
						<span class="dots"></span>
						<div class="content"
							title="{{ viewModel.errorResult.priority ? ('Yes' | translate) : ('No' | translate) }}">
							{{ viewModel.errorResult.priority ? ('Yes' | translate) : ('No' | translate) }}
						</div>
					</div>
					<div class="info-text" *ngIf="!viewModel.isMeasureAssignment()"
						title="{{ 'GeoFencing' | translate }}">
						<b>{{ 'GeoFencing' | translate }}</b>
						<span class="dots"></span>
						<div class="content"
							title="{{ viewModel.assignmentTemplate.geoControlled ? ('Yes' | translate) : ('No' | translate) }}">
							{{ viewModel.assignmentTemplate.geoControlled ? ('Yes' | translate) : ('No' | translate) }}
						</div>
					</div>
					<div class="info-text" *ngIf="viewModel.isMeasureAssignment() && viewModel.integrations.length"
						[ngClass]="{
							'use-block': viewModel.integrations.length
						}"
						title="{{ 'Integrations' | translate }}">
						<b>{{ 'Integrations' | translate }}</b>
						<span class="dots" *ngIf="!viewModel.integrations.length"></span>
						<div class="content" [ngClass]="{
								'apx-scrollbar': viewModel.integrations.length,
								'integration-wrapper': viewModel.integrations.length
							}">
							<div *ngFor="let integration of viewModel.integrations" class="integration"
								title="{{ integration }}">
									{{ integration }}
							</div>
						</div>
					</div>
					<div class="info-text" *ngIf="!viewModel.completed && viewModel.status !== stringUtils.EXPIRED"
						title="{{ 'GroupsAssignees' | translate }}">
						<b>{{ 'GroupsAssignees' | translate }}</b>
						<span class="dots"></span>
						<a class="content"
							[ngClass]="{
								'dummy-text': !viewModel.assigneeGroups.length
							}"
							(click)="viewModel.assigneeGroups.length? goToGroupsTab() : null"
							title="{{ 'Amount' | translate: getTranslateAmountParam(viewModel.assigneeGroups.length) | localeString }}">
							{{ 'Amount' | translate: getTranslateAmountParam(viewModel.assigneeGroups.length) | localeString }}
						</a>
						<span [ngClass]="{ 'dummy-text': !viewModel.assigneeGroups.length && !viewModel.assignees.length}">&nbsp;/&nbsp;</span>
						<a class="content"
							[ngClass]="{
								'dummy-text': !viewModel.assignees.length
							}"
							(click)="viewModel.assignees.length ? goToAssigneesTab() : null"
							title="{{ 'Amount' | translate: getTranslateAmountParam(viewModel.assignees.length) | localeString }}">
							{{ 'Amount' | translate: getTranslateAmountParam(viewModel.assignees.length) | localeString }}
						</a>
					</div>
					<div class="info-text"
						title="{{ 'Status' | translate }}">
						<b>{{ 'Status' | translate }}</b>
						<span class="dots"></span>
						<simple-status-label
							[text]="viewModel.isMeasureAssignment() && viewModel.status === stringUtils.COMPLETED
								? stringUtils.RESOLVED
								: viewModel.status === stringUtils.ACTIVE ? stringUtils.NOT_STARTED : viewModel.status"
							title="{{ viewModel.isMeasureAssignment() && viewModel.status === stringUtils.COMPLETED
								? stringUtils.RESOLVED
								: viewModel.status === stringUtils.ACTIVE ? stringUtils.NOT_STARTED : viewModel.status }}">
						</simple-status-label>
					</div>
				</div>
				<div class="sub-section" *ngIf="(viewModel.isMeasureAssignment() && !viewModel.isArchivedAssignment()) ? viewModel.errorAssignment.reportUrl : viewModel.reportUrl">
					<div class="info-text"
						title="{{ 'AssignmentReport' | translate }}">
						<b>{{ 'AssignmentReport' | translate }}</b>
						<span class="dots"></span>
						<a class="content"
							(click)="getReport()"
							title="{{ 'Download' | translate }}">
							{{ 'Download' | translate }}
						</a>
					</div>
				</div>
			</div>
			<div class="view-section">
				<div class="view-section-header">
					{{ 'Implementation' | translate }}
				</div>
				<div class="sub-section modal-buttons extra-margin" *ngIf="viewModel.isSSEN() && viewModel.assignmentTemplate.templateType.scope && !viewModel.isMeasureAssignment()">
					<button
						type="button" class="btn btn-transparent w-border show-button"
						title="{{ 'ViewScope' | translate }}" (click)="openScope()">
						{{ 'ViewScope' | translate }}
						<div class="bi bi-search"></div>
					</button>
				</div>
				<div class="sub-section" *ngIf="viewModel.isSSEN() && !viewModel.isMeasureAssignment() && viewModel.notes">
					<div class="info-text"
						[ngClass]="{
							'use-block': viewModel.notes
						}"
						title="{{
							(viewModel.assignmentTemplate.templateType.scope
							? 'Deviations'
							: 'ScopeFreeText') | translate
							}}">
						<b>{{
							(viewModel.assignmentTemplate.templateType.scope
							? 'Deviations'
							: 'ScopeFreeText') | translate
							}}</b>
						<p *ngIf="viewModel.notes">
							{{ viewModel.notes }}
						</p>
						<span class="dots" *ngIf="!viewModel.notes"></span>
						<div class="content dummy-text" *ngIf="!viewModel.notes"
							title="{{ 'NotAdded' | translate }}">
							{{ 'NotAdded' | translate }}
						</div>
					</div>
				</div>
				<div class="sub-section" *ngIf="viewModel.isMeasureAssignment() && viewModel.adminComment">
					<div class="info-text"
						[ngClass]="{
							'use-block': viewModel.adminComment
						}"
						title="{{ 'AdminComment' | translate }}">
						<b>{{ 'AdminComment' | translate }}</b>
						<span class="dots" *ngIf="!viewModel.adminComment"></span>
						<div class="content" [ngClass]="{
								'dummy-text': !viewModel.adminComment
							}"
							title="{{ viewModel.adminComment ? viewModel.adminComment : ('NotAdded' | translate) }}">
							{{ viewModel.adminComment ? viewModel.adminComment : ('NotAdded' | translate) }}
						</div>
					</div>
				</div>
				<div class="sub-section">
					<div class="info-text"
						title="{{ 'Events' | translate }}">
						<b>{{ "Events" | translate }}</b>
					</div>
					<div class="info-text with-icon apx-scrollbar" *ngIf="viewModel.created && !viewModel.createdByUser"
						title="{{ 'Created' | translate }} {{ viewModel.created | datex }}">
						<i class="bi bi-file-earmark-plus"></i>
						{{ "Created" | translate }}
						{{ viewModel.created | datex }}
					</div>
					<div class="info-text with-icon apx-scrollbar" *ngIf="viewModel.created && viewModel.createdByUser"
						title="{{ 'Created' | translate }} {{ viewModel.created | datex }}">
						<i class="bi bi-file-earmark-plus"></i>
						<div class="wrapper">
							{{ 'CreatedOnBy' | translate: createdTranslateParam }}:
							<simple-link-button
								[url]="getCreatedByUserUrl()"
								[linkText]="viewModel.createdByUser.fullName ? viewModel.createdByUser.fullName : viewModel.createdByUser.email"
								[name]="viewModel.createdByUser.fullName ? viewModel.createdByUser.fullName : viewModel.createdByUser.email"
								[shouldDisable]="!!viewModel.createdByUser.getCurrentDeleted(selectedOrganization.id)">
							</simple-link-button>
							<ng-container *ngIf="(viewModel.isMeasureAssignment() && !viewModel.isManualMeasureAssignment())">
								{{ ('From' | translate) | lowercase }}
								<simple-link-button *ngIf="viewModel.errorAssignment.url"
									[url]="viewModel.errorAssignment.url"
									[linkText]="viewModel.assignmentTemplate.templateType.name"
									[name]="viewModel.assignmentTemplate.templateType.name">
								</simple-link-button>
							</ng-container>
						</div>
					</div>
					<div class="info-text with-icon apx-scrollbar" *ngIf="viewModel.firstUpdated"
						title="{{ 'Started' | translate }} {{ viewModel.firstUpdated | datex }}">
						<i class="bi bi-person-walking"></i>
						{{ "Started" | translate }}
						{{ viewModel.firstUpdated | datex }}
					</div>
					<div class="info-text with-icon apx-scrollbar" *ngIf="viewModel.lastUpdated && assignmentHasStatusSaved()"
						title="{{ 'Saved' | translate }} {{ viewModel.lastUpdated | datex }}">
						<i class="bi bi-clock"></i>
						{{ "Saved" | translate }}
						{{ viewModel.lastUpdated | datex }}
					</div>
					<div class="info-text with-icon apx-scrollbar" *ngIf="viewModel.dueDate && viewModel.isExpired"
						title="{{ 'Expired' | translate }} {{ viewModel.dueDate | datex }}">
						<i class="bi bi-hourglass-bottom"></i>
						{{ "Expired" | translate }}
						{{ viewModel.dueDate | datex }}
					</div>
					<div class="info-text with-icon apx-scrollbar" *ngIf="viewModel.completed && viewModel.isMeasureAssignment() && assignmentHasResult()"
						title="{{ (viewModel.status === 'Closed' ? 'Closed' : 'Resolved') | translate }} {{ viewModel.completed | datex }}">
						<i class="bi {{ viewModel.status === 'Closed' ? 'bi-clipboard-x' : 'bi-clipboard-check' }}"></i>
						<div class="wrapper">
							{{ (viewModel.status === 'Closed' ? 'ClosedOnBy' : 'ResolvedOnBy') | translate: resolvedTranslateParam }}:
							<simple-link-button
								[url]="getCompletedByUserUrl()"
								[linkText]="viewModel.completedByUser.fullName ? viewModel.completedByUser.fullName : viewModel.completedByUser.email"
								[name]="viewModel.completedByUser.fullName ? viewModel.completedByUser.fullName : viewModel.completedByUser.email"
								[shouldDisable]="!!viewModel.completedByUser.getCurrentDeleted(selectedOrganization.id)">
							</simple-link-button>
						</div>
					</div>
					<div class="info-text with-icon apx-scrollbar" *ngIf="viewModel.completed && !viewModel.isMeasureAssignment()"
						title="{{ 'Completed' | translate }} {{ viewModel.completed | datex }}">
						<i class="bi bi-clipboard-check"></i>
						<div class="wrapper">
							{{ 'CompletedOnBy' | translate: completedTranslateParam }}:
							<simple-link-button
								[url]="getCompletedByUserUrl()"
								[linkText]="viewModel.completedByUser.fullName ? viewModel.completedByUser.fullName : viewModel.completedByUser.email"
								[name]="viewModel.completedByUser.fullName ? viewModel.completedByUser.fullName : viewModel.completedByUser.email"
								[shouldDisable]="!!viewModel.completedByUser.getCurrentDeleted(selectedOrganization.id)">
							</simple-link-button>
						</div>
					</div>
					<span class="styled-text" *ngIf="viewModel.isSSEN() && !viewModel.isMeasureAssignment() && viewModel.performed && viewModel.completed && viewModel.status !== 'Closed'"
						title="{{ 'PerformedDate' | translate }} {{ viewModel.performed | datex: 'LL' }}">
						({{ 'PerformedDate' | translate }}
						{{ viewModel.performed | datex: 'LL' }})
					</span>
				</div>
			</div>
		</div>
		<div class="layout">
			<div class="view-section">
				<div class="view-section-header">
					{{ "Assessments" | translate }}
				</div>
				<div class="sub-section">
					<ng-container *ngIf="!viewModel.isMeasureAssignment()">
						<!-- Facility -->
						<div class="assessments" *ngIf="viewModel.facility.results.length">
							<assessment-component *ngFor="let result of viewModel.facility.results"
								[errorResult]="result"
								[isMeasure]="false"
								[isCompleted]="!!viewModel.completed">
							</assessment-component>
						</div>

						<!-- Entities -->
						<ng-container *ngIf="viewModel.entities.length">
							<div class="sub-header"
								[ngClass]="{ 'extra-margin': viewModel.facility.results.length && viewModel.entities.length }">
								{{ "Entities" | translate }}
							</div>
							<ul class="entity-list">
								<ng-container *ngFor="let entity of viewModel.entities">
									<li class="entity-item"
										[ngClass]="{
											'has-errors': entity.numberOfErrors > 0
										}"
										title="{{ entity.name }}"
										(click)="entity.issueExpanded = !entity.issueExpanded">
											<div class="wrapper">
												<div class="value"
													title="{{ 'AmountOfErrors' | translate }}">
													{{ entity.numberOfErrors > 0 ? entity.numberOfErrors : 'OK' }}
												</div>
												<div class="name">
													{{ entity.name }}
												</div>
											</div>
											<i class="icon"
												[ngClass]="{
													'bi-chevron-double-up': entity.issueExpanded,
													'bi-chevron-double-down': !entity.issueExpanded
												}">
											</i>
											<simple-link-button
												[url]="entity.url"
												[name]="entity.name"
												[shouldDisable]="!loggedInUser.canEdit(selectedOrganization.friendlyUrl)"
												[shouldStopPropagation]="true">
											</simple-link-button>
									</li>
									<li class="entity-content" *ngIf="entity.issueExpanded">
										<div class="assessments">
											<assessment-component *ngFor="let result of entity.results"
												[errorResult]="result"
												[isMeasure]="false"
												[isCompleted]="!!viewModel.completed">
											</assessment-component>
										</div>
									</li>
								</ng-container>
							</ul>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="viewModel.isMeasureAssignment()">
						<div class="sub-header" *ngIf="viewModel.entities.length">
							{{ viewModel.errorResult.objectName }}
						</div>
						<assessment-component
							[errorResult]="viewModel.errorResult"
							[resolvementResult]="resolvementResult"
							[isMeasure]="true"
							[wasClosed]="viewModel.status === 'Closed'">
						</assessment-component>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</simple-view-tab>