<simple-change-tab
	[form]="form"
	[hideRequiredInfoText]="true">
		<div class="change-section">
			<div class="change-section-header">
				{{ 'Description' | translate }}
			</div>
			<div class="content">
				<markdown-editor
					[initialTextareaValue]="changeModel.assignmentDescription"
					[toolbarAsPopover]="isExtraSmallScreenSize()"
					(onTextChange)="handleChange($event)">
				</markdown-editor>
			</div>
		</div>

		<!-- Needed just to have a form..... -->
		<simple-form-input *ngIf="!changeModel"
			[label]="''"
			[name]="propertyStrings.name"
			[form]="form">
		</simple-form-input>
</simple-change-tab>
