
<simple-change-tab
	[form]="form">
		<div class="change-section">
			<div class="change-section-header">
				{{ 'General' | translate }}
			</div>
			<ng-container *ngIf="changeModel.isMeasureAssignment()">
				<simple-form-select
					[label]="'Priority' | translate"
					[name]="propertyStrings.priority"
					[form]="form"
					[items]="selectablePriorityItems"
					[disabled]="priorityIsDisabled"
					[serverErrors]="serverErrors">
				</simple-form-select>
				<simple-form-info *ngIf="choice && priorityIsDisabled"
					[firstText]="'Choice' | translate"
					[linkText]="choice.value"
					[linkHref]="disabledPriorityLink"
					[secondText]="'DisabledPriorityInfoText' | translate"
					[name]="choice.value">
				</simple-form-info>

				<simple-form-select
					[label]="'Status' | translate"
					[name]="propertyStrings.status"
					[form]="form"
					[items]="selectableStatusItems"
					[serverErrors]="serverErrors">
				</simple-form-select>

				<simple-form-input *ngIf="changeModel.status !== stringUtils.ACTIVE && changeModel.status !== stringUtils.NOT_STARTED && changeModel.status !== stringUtils.STARTED"
					[label]="'Notes' | translate"
					[name]="propertyStrings.notes"
					[form]="form"
					[maxlength]="500"
					[serverErrors]="serverErrors"
					[useTextarea]="true">
				</simple-form-input>
			</ng-container>

			<ng-container *ngIf="!changeModel.isMeasureAssignment()">
				<simple-form-date-picker
					[label]="'DueDate' | translate"
					[dateString]="changeModel.dueDate"
					[errorMessage]="dueDateErrorMessage"
					(onDateChange)="handleDueDateChange($event)">
				</simple-form-date-picker>
				<simple-form-time-input
					[label]="'DueTime' | translate"
					[name]="propertyStrings.dueTime"
					[form]="form"
					[serverErrors]="serverErrors"
					(onTimeChange)="handleTimeChange()">
				</simple-form-time-input>
			</ng-container>
		</div>
		<div class="change-section" *ngIf="changeModel.isMeasureAssignment()">
			<div class="change-section-header">
				{{ 'Implementation' | translate }}
			</div>
			<simple-form-input
				[label]="'AdminComment' | translate"
				[name]="propertyStrings.adminComment"
				[form]="form"
				[maxlength]="500"
				[serverErrors]="serverErrors"
				[useTextarea]="true">
			</simple-form-input>
		</div>
</simple-change-tab>
