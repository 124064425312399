import { AuthService } from './auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService, LocalStorageKey } from '../shared-core/local-storage/local-storage.service';
import { Utils, LogLevel } from 'app-core/shared-core/tools/utils';
import * as appConfig from '../../../package.json';

@Component({
	selector: 'callback-ts',
	template: `
		<dots-loader></dots-loader>
	`
})
export class CallbackComponent implements OnInit {

	constructor(
		private authService: AuthService,
		private localStorageService: LocalStorageService,
		private router: Router) { }

	ngOnInit() {
		this.authService.completeLogin()
			.then(async user => {
				let redirectUrl = '/';

				// If there exists a local storage, use that.
				// Otherwise use the user state if it exists and is not the root.
				if (this.localStorageService.getItem(LocalStorageKey.RedirectUrl)) {
					redirectUrl = this.localStorageService.getItem(LocalStorageKey.RedirectUrl);
				} else if (user.state) {
					redirectUrl = user.state;
				}

				const latestBustedVersion = localStorage.getItem("latest-busted-version");
				const versionToBust = appConfig?.version;

				if (latestBustedVersion !== versionToBust) {

					await fetch(redirectUrl, {
						method: "GET",
						cache: "no-cache",
					}).catch(e => console.error(e));

					localStorage.setItem("latest-busted-version", versionToBust);
				}

				// Run request user to initiate app variables.
				this.authService.requestUser()
					.then(() => {
						// Make sure any stored redirect url is removed.
						this.localStorageService.removeItem(LocalStorageKey.RedirectUrl);

						// Replace the logins url to remove it from the history.
						window.location.replace(redirectUrl);

						// Perform redirect.
						this.router.navigateByUrl(redirectUrl);
					});
			})
			.catch(error => {
				Utils.logMessage(error, null, LogLevel.Error);
				this.authService.startLogout();
			});
	}
}
