import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { FileData, FileHelper } from 'app-core/shared-core/tools/file-helper';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Subscriber } from 'app-core/user/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../report.service';
import { StaticReport } from './static-report';
import { StaticReportTabComponent } from './tabs/static-report-tab.component';

@Component({
	templateUrl: './static-report.component.html'
})
export class StaticReportComponent extends SimpleBulkEditModalDirective<StaticReport> implements OnInit {

	isMeasure: boolean;
	tooLargeForDownload: boolean;
	sendReportByEmail: boolean;

	subscribers: Subscriber[] = [];
	helpText: string;
	hideText: boolean = false;

	@ViewChild(StaticReportTabComponent) staticReportTabComponent: StaticReportTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private reportService: ReportService,
		private fileHelper: FileHelper) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			this.subscribers = await this.reportService.getSubscribers(this.editModelIds, this.selectedOrganization.id);
			const response = await this.reportService.getReportData(this.editModelIds, this.selectedOrganization.id);
			this.affectableIds = response.data;
			this.pending = false;

			this.tooLargeForDownload = this.editModelIds.length >= NumberUtils.MAX_ITEM_COUNT_FOR_REPORT_DOWNLOAD;
			this.sendReportByEmail = this.tooLargeForDownload;

			this.initialModel = new StaticReport({});

			this.setLoggedInUserAsRecipient();
			this.helpText = this.editModelIds.length > 1
				? this.isMeasure
					? this.translationService.instant('StaticReportMeasureHelpTextMultiple')
					: this.translationService.instant('StaticReportHelpTextMultiple')
				: this.isMeasure
					? this.translationService.instant('StaticReportMeasureHelpText')
					: this.translationService.instant('StaticReportHelpText');

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	changeDetailsIsValid() {
		return this.staticReportTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.staticReportTabComponent?.formHasErrors();
	}

	private hasSubscribers() {
		return this.subscribers.some(subscriber => subscriber.selected);
	}

	private hasUsers() {
		return !!this.initialModel.users.length;
	}

	private hasExternalEmails() {
		return !!this.initialModel.emails.length;
	}

	everythingIsValid() {
		return this.changeDetailsIsValid()
			&& (!this.sendReportByEmail || (this.hasSubscribers() || this.hasUsers() || this.hasExternalEmails()));
	}

	protected async createOrUpdate() {
		const subscriberEmails = this.subscribers.filter(subscriber => subscriber.selected)
			.map(subscriber => subscriber.email);
		const userEmails = this.initialModel.users.map(user => user.email);
		const externalEmails = this.initialModel.emails;

		const allEmails = Utils.getUniqueEntriesOnly(...subscriberEmails, ...userEmails, ...externalEmails);

		const ids = this.useAffectableIds ? this.affectableIds : this.editModelIds;
		const model = new StaticReport({
			itemIds: ids,
			organizationId: this.selectedOrganization.id,
			emails: this.sendReportByEmail ? allEmails : []
		});

		this.pending = true;
		try {
			const response = await this.reportService.getStaticReports(model);
			this.displaySuccessMessage(response.successMessage);
			this.handled$.next(true);

			if (response.data) {
				this.fileHelper.handleFile(new FileData(response.data.file));
			}

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.staticReportTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: StaticReport) {
		return new StaticReport(item);
	}

	hasUnsavedChanges() {
		return false;
	}

	handleValueChanges() {
		if (this.staticReportTabComponent) {
			const sendReportByEmailCtrl = this.staticReportTabComponent.form.get('sendReportByEmail');
			this.sendReportByEmail = sendReportByEmailCtrl.value;

			if (this.sendReportByEmail) {
				this.staticReportTabComponent.setSelectableEmailItems();
			}
		}
	}

	private setLoggedInUserAsRecipient() {
		const loggedInUserInSubscribers = this.subscribers.find(subscriber => subscriber.email === this.loggedInUser.email);
		const loggedInUserIsInOrganization = this.loggedInUser.isOrgMember(this.selectedOrganization.friendlyUrl);

		if (!!loggedInUserInSubscribers) {
			loggedInUserInSubscribers.selected = true;
		} else if (loggedInUserIsInOrganization) {
			this.initialModel.users.push(this.loggedInUser);
		} else {
			this.initialModel.selectableEmails.push(this.loggedInUser.email);
			this.initialModel.emails.push(this.loggedInUser.email);
		}
	}
}
