import { Injectable } from '@angular/core';
import { MediaUtils } from 'app-core/shared-core/tools/media-utils';
import { UrlUtils } from 'app-core/shared-core/tools/url-utils';

@Injectable()
export class MediaResolver {
	resolve(url: string, size?: number, placeholderImageType?: string) {
		if (url) {
			return UrlUtils.toMediaUrl(url, size);
		} else {
			if (placeholderImageType === 'user') {
				return this.getPlaceholderImageUser();
			} else if (placeholderImageType === 'organization') {
				return this.getPlaceholderImageOrganization();
			} else {
				return this.getPlaceholderImage();
			}
		}
	}

	private getPlaceholderImageUser() {
		return MediaUtils.PLACEHOLDER_IMAGE_USER;
	}

	private getPlaceholderImageOrganization() {
		return MediaUtils.PLACEHOLDER_IMAGE_ORGANIZATION;
	}

	private getPlaceholderImage() {
		return MediaUtils.MISC_CAMERA;
	}
}
