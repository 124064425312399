import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../auth/auth.service';
import { Organization, OrganizationTreeItem } from '../organization';
import { InspireTreeComponent } from '../../shared-core/inspire-tree/inspire-tree.component';
import { GlobalOrganizationService } from './global-organization.service';
import { SimpleReadModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-read-modal.directive';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';

@Component({
	templateUrl: './global-organization-modal.component.html',
	styleUrls: ['./global-organization-modal.component.less']
})
export class GlobalOrganizationModalComponent extends SimpleReadModalDirective<Organization> implements OnInit, AfterViewInit {

	@ViewChild(InspireTreeComponent) inspireTreeComponent: InspireTreeComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private globalOrganizationService: GlobalOrganizationService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.pending = true;
	}

	ngAfterViewInit() {
		this.inspireTreeComponent.componentReady();
	}

	public onGetTreeData($event: any) {
		const [node, resolve, _] = $event;
		if (!node) {
			// A falsey node indicates this is an initial data request:
			// fetch the entire tree.
			this.globalOrganizationService.getTreeData()
				.then((organizations: OrganizationTreeItem[]) => {
					this.pending = false;
					return resolve(organizations);
				})
				.catch(__ => {
					this.pending = false;
					return resolve(null);
				});
		} else {
			// An actual node is passed: fetch a tree branch.
			this.globalOrganizationService.getTreeBranch(node.realId)
				.then((organizations: OrganizationTreeItem[]) => {
					this.addTreeBranch(node.realId, organizations);
					return resolve(organizations);
				})
				.catch(__ => {
					return resolve(null);
				});
		}
	}

	private addTreeBranch(nodeRealId: number, branchChildren: OrganizationTreeItem[]) {
		this.traverseNodes((node: OrganizationTreeItem) => {
			if (node.realId === nodeRealId) {
				node.children = branchChildren;
				return true;
			}
		});
	}

	private traverseNodes(predicate: Function) {
		let stopTraversal = false;

		const traverseNodes = (nodes: OrganizationTreeItem[]) => {
			if (stopTraversal) { return; }

			nodes.forEach(node => {
				if (predicate.call(null, node) === true) {
					stopTraversal = true;
					return;
				}
				if (node.children && typeof node.children === 'object') {
					traverseNodes(node.children);
				}
			});
		};

		this.globalOrganizationService.getTreeData()
			.then(nodes => {
				traverseNodes(nodes);
			});
	}

	public onNodeSelected($event: any) {
		const [node] = $event;
		const selectedFriendlyUrl = node.url;
		const currentFriendlyUrl = this.authService.selectedOrganization.friendlyUrl;
		const currentUrl = this.router.url;
		const currentUrlWithoutQueryParams = currentUrl.split('?')[0];
		if (currentUrl.includes(currentFriendlyUrl)) {
			// This triggers OrganizationResolver which sets a current organization.
			this.router.navigateByUrl(currentUrlWithoutQueryParams.replace(/^\/[a-zA-Z-.~_0-9]*/, selectedFriendlyUrl));
		} else {
			this.router.navigateByUrl(`/${selectedFriendlyUrl}${currentUrlWithoutQueryParams}`);
		}

		this.close();
	}
}
