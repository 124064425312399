<div class="modal-wrapper" [ngClass]="{'loadmask': isLoading}" #wrapper>
	<div class="modal-header" *ngIf="!hideHeader">
		<div class="title">
			{{ titleText }}
		</div>
		<div class="actions">
			<ng-content select="[extraActions]"></ng-content>
			<help [isInModal]="true"></help>
			<feedback [isInModal]="true"></feedback>
			<div class="close-button bi bi-x"
				title="{{ closeText }}"
				(click)="handleClose()">
			</div>
		</div>
	</div>
	<div class="modal-body apx-scrollbar" scrollShadow
		[ngClass]="{
			'no-header': hideHeader
		}">
		<ng-content></ng-content>
		<div class="dummy" *ngIf="!isDelete && displayDummy"></div>
	</div>
	<div class="modal-footer">
		<button type="button"
			class="btn btn-transparent"
			title="{{ closeText }}"
			(click)="handleClose()">
				{{ closeText }}
		</button>
		<div (click)="disableSubmit ? handleSubmit() : null">
			<button type="button" *ngIf="submitText"
				class="btn"
				[ngClass]="{
					'btn-success': !isDelete,
					'btn-danger': isDelete
				}"
				title="{{ submitText }}"
				[disabled]="disableSubmit"
				(click)="handleSubmit()">
					{{ submitText }}
			</button>
		</div>
	</div>
	<dots-loader *ngIf="isLoading"></dots-loader>
</div>
