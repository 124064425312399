<div class="form-date-picker">
	<div class="label-wrapper">
		<label>
			{{ label }}
		</label>
		<div class="section">
			<div class="date"
			[ngClass]="{
				'not-specified': !dateString
			}">
				{{ dateString ? (dateString | datex: 'LL'): ('NotSpecified' | translate) }}
			</div>
			<div class="clear-button bi-x" *ngIf="displayClearButton"
				[title]="'Clear' | translate"
				(click)="dateString = ''">
			</div>
		</div>
	</div>
	<bs-datepicker-inline
		[bsValue]="dateString | toDateOrUndefined"
		[bsConfig]="{
			customTodayClass: 'today',
			selectWeek: true,
			showWeekNumbers: false,
			selectFromOtherMonth: true,
			containerClass: errorMessage ? 'has-error' : ''
		}"
		(bsValueChange)="handleBsValueChange($event)">
	</bs-datepicker-inline>
	<div class="error-message" *ngIf="errorMessage">
		&bull; {{ errorMessage }}
	</div>
</div>
