import { Component, ComponentRef, ElementRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { SimpleListAction } from 'app-core/shared-core/simple-components/list/actions/simple-list-action';
import { SimpleListDirective } from 'app-core/shared-core/simple-components/list/simple-list.directive';
import { SimpleTableRowActionDelete, SimpleTableRowActionEdit, SimpleTableRowActionKeepOpen } from 'app-core/shared-core/simple-components/list/table/body/simple-table-row-action';
import { EventsContent, SimpleTableEventsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-events-column';
import { IconContent, SimpleTableIconsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-icons-column';
import { SimpleTableTextColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-text-column';
import { SimpleTableEmptyState } from 'app-core/shared-core/simple-components/list/table/empty-state/simple-table-empty-state';
import { SimpleFilterHelperUser } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-user';
import { SimpleFilterInput, SimpleFilterInputType } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-input-settings';
import { SimpleFilterListModalInput } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-list-modal-input-settings';
import { SimpleFilterConfig, SortObject } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-config';
import { SimpleFilterInputItem } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-input-item';
import { SimpleRetainService } from 'app-core/shared-core/simple-components/list/table/filter/simple-retain.service';
import { SimpleTableHeaderActionDelete } from 'app-core/shared-core/simple-components/list/table/head/simple-table-header-action';
import { SimpleTableConfig } from 'app-core/shared-core/simple-components/list/table/simple-table-config';
import { TextContent } from 'app-core/shared-core/simple-components/various/text-content/simple-text-content.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { ViewObjectsComponent, ViewObjectsType } from 'app-core/shared-core/view-objects/view-objects.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CreateEditUserGroupComponent } from '../create-edit-usergroup/create-edit-user-group.component';
import { DeleteUserGroupsComponent } from '../delete-user-groups/delete-user-groups.component';
import { UserGroup } from '../user-group';
import { UserGroupFilter, UserGroupService } from '../user-group.service';

@Component({
	selector: 'list-user-group',
	templateUrl: '../../../app-core/shared-core/simple-components/list/simple-list-shared-template.html',
	providers: [SimpleFilterHelperUser]
})
export class ListUserGroupComponent extends SimpleListDirective<UserGroup> {

	// Override filterObject to match backend.
	readonly filterObject = new UserGroupFilter();

	componentRef: ComponentRef<ViewObjectsComponent>;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected sanitizer: DomSanitizer,
		protected userGroupService: UserGroupService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected retainService: SimpleRetainService,
		protected elementRef: ElementRef,
		protected viewContainerRef: ViewContainerRef,
		private simpleFilterHelperUser: SimpleFilterHelperUser) {
		super(
			authService,
			modalService,
			toastrService,
			translationService,
			userGroupService,
			route,
			router,
			retainService,
			elementRef,
			viewContainerRef,
			RoutesUtils.userGroup
		);
	}

	protected configureListActions() {
		this.setListActions(
			new SimpleListAction(
				this.translationService.instant('Create'),
				this.translationService.instant('NewGroup'),
				StringUtils.icons.new,
				() => {
					if (!this.isInModal) {
						this.setCrudParams(RoutesUtils.modalPrefixValueNew);
					} else {
						this.openModalByActionName(RoutesUtils.modalPrefixValueNew);
					}
				}
			)
		);
	}

	protected async configureTableFilter(config: SimpleFilterConfig<UserGroup>) {
		const assigneesKey = StringUtils.ASSIGNEES_KEY;
		const createdKey = StringUtils.CREATED_KEY;
		const updatedKey = StringUtils.UPDATED_KEY;
		const hasUsersKey = StringUtils.HAS_USERS_KEY;
		if (!this.isInModal) {
			this.retainService.setCurrentRetainEntries({
				search: null,
				sort: null,
				[assigneesKey]: null,
				[createdKey]: null,
				[updatedKey]: null,
				[hasUsersKey]: null
			});
		}

		try {
			const users = await this.userGroupService.getUsers(this.selectedOrganization.id);
			const convertedUsers = users.map(user => new SimpleFilterInputItem(user.id, user.email));

			config.setFilterInputs(
				new SimpleFilterInput(
					createdKey,
					this.translationService.instant(createdKey),
					SimpleFilterInputType.DateSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(createdKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(createdKey, ''));
						}
						return keyValuePairs;
					}
				),
				new SimpleFilterInput(
					updatedKey,
					this.translationService.instant(updatedKey),
					SimpleFilterInputType.DateSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(updatedKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(updatedKey, ''));
						}
						return keyValuePairs;
					}
				),
				new SimpleFilterListModalInput(
					assigneesKey,
					this.translationService.instant(assigneesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(assigneesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(assigneesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperUser.openListModal(filterInput, users, this);
					},
					convertedUsers
				),
				new SimpleFilterInput(
					hasUsersKey,
					this.translationService.instant(hasUsersKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(hasUsersKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(hasUsersKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.YES,
						this.translationService.instant(StringUtils.YES)
					),
					new SimpleFilterInputItem(
						StringUtils.NO,
						this.translationService.instant(StringUtils.NO)
					)
				)
			);

			if (!this.isInModal) {
				// Needs to be called here due to the data fetch delay.
				this.setSelectedFilterItems();
			}

		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}

	protected configureTableSort(config: SimpleFilterConfig<UserGroup>) {
		config.setSortObjects(
			new SortObject(
				this.propertyStrings.name,
				this.translationService.instant('Name'),
				true
			),
			new SortObject(
				this.propertyStrings.created,
				this.translationService.instant('Created')
			),
			new SortObject(
				this.propertyStrings.updated,
				this.translationService.instant('Updated')
			)
		);
	}

	protected configureTableColumns(config: SimpleTableConfig<UserGroup>) {
		config.setColumns(
			new SimpleTableTextColumn(
				this.translationService.instant('Name'),
				(item) => new TextContent(
					item.name,
					'',
					'',
					''
				),
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableEventsColumn(
				this.translationService.instant('Events'),
				(item) => [
					new EventsContent(`${this.translationService.instant('Created')} ${Utils.getFormattedDateStringFromString(item.created, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.created, 'bi-file-earmark-plus'),
					Utils.dateIsAfter(item.updated, item.created)
						? new EventsContent(`${this.translationService.instant('Updated')} ${Utils.getFormattedDateStringFromString(item.updated, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.updated, 'bi-file-earmark-text')
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableIconsColumn(
				'',
				(item) => [
					item.users.length
						? new IconContent('bi-person', item.users.length.toString(), `${this.translationService.instant('Assignees')}: ${item.users.length.toString()}`)
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			)
		);
	}

	protected configureTableActions(config: SimpleTableConfig<UserGroup>) {

		// HEADER
		config.setHeaderActions(
			new SimpleTableHeaderActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${selectedIds.join()}`);
				}
			)
		);

		// ROWS
		config.setRowActions(
			new SimpleTableRowActionEdit(
				this.translationService.instant('Open'),
				StringUtils.icons.handle,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					}
				}
			),
			new SimpleTableRowActionKeepOpen(
				this.translationService.instant('ViewAssignees'),
				StringUtils.icons.user,
				(row) => {
					this.setAsAffected([row.id]);

					this.prepareViewObjectsPopover(row.data, ViewObjectsType.users, 1, row.id);
				},
				(row) => {
					return !row.data.users.length;
				}
			),
			new SimpleTableRowActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					}
				}
			)
		);
	}

	protected configureTableEmptyState(config: SimpleTableConfig<UserGroup>) {
		config.emptyState = new SimpleTableEmptyState(
			'Groups',
			StringUtils.icons.group
		);
	}

	protected setPropertiesAndOpenViewObjectsPopover(item: UserGroup, type: ViewObjectsType, rowClickNumber: number, rowId: string) {
		this.componentRef = this.viewContainerRef.createComponent(ViewObjectsComponent);
		this.componentRef.instance.rowId = rowId;
		this.componentRef.instance.type = type;

		switch (type) {
			case ViewObjectsType.users:
				this.componentRef.instance.users = item.users;
				this.componentRef.instance.triggerViewChanges();
				this.openViewObjectsPopover(this.componentRef, rowClickNumber, rowId, StringUtils.icons.user);
				break;
		}
	}

	protected openModalByActionName(value: string) {
		const [actionName, idString] = value.split('_');
		this.shouldClearAfterUpdate = !this.isInModal && this.isBulkAction(actionName);
		if (actionName === RoutesUtils.modalPrefixValueNew) {
			this.bsModalRef = this.modalService.show(
				CreateEditUserGroupComponent,
				{
					initialState: {
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_M_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueEdit) {
			this.bsModalRef = this.modalService.show(
				CreateEditUserGroupComponent,
				{
					initialState: {
						editModelId: idString,
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_M_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueDelete) {
			this.bsModalRef = this.modalService.show(
				DeleteUserGroupsComponent,
				{
					initialState: {
						deleteModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_SMALL
				}
			);
		}
		this.subscribeToCrudModalContent();
	}

	protected import(_: File) { }
	protected export(_: string[]) { }
}
