import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DotsLoaderModule } from 'app-core/shared-core/dots-loader/dots-loader.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ExtensiveImageComponent } from './extensive-image.component';
import { ExtensivePdfComponent } from '../extensive-pdf/extensive-pdf.component';

@NgModule({
	imports: [
		CommonModule,
		DotsLoaderModule,
		PdfViewerModule
	],
	declarations: [
		ExtensiveImageComponent,
		ExtensivePdfComponent
	],
	exports: [
		ExtensiveImageComponent,
		ExtensivePdfComponent
	]
})
export class ExtensiveMediaModule { }
