import {
	Component
} from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleDeleteModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-delete-modal.directive';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentTemplate } from '../assignment-template';
import { AssignmentTemplateService } from '../assignment-template.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';

@Component({
	templateUrl: './delete-assignment-templates.component.html'
})
export class DeleteAssignmentTemplatesComponent extends SimpleDeleteModalDirective<AssignmentTemplate> {

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private assignmentTemplateService: AssignmentTemplateService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	protected async delete() {
		this.pending = true;
		try {
			const response = await this.assignmentTemplateService.delete(this.deleteModelIds[0]);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected async deleteRange() {
		this.pending = true;
		try {
			const response = await this.assignmentTemplateService.deleteRange(this.deleteModelIds);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}
}
