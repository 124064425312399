import { NumberUtils } from './tools/number-utils';

export enum SortDirection {
	Ascending = 'ascending',
	Descending = 'descending'
}

export class Filter {

	public page: number = 0;
	public pageSize: number = NumberUtils.TABLE_DATA_PAGE_SIZE;
	public search: string = '';
	public facets = {};
	public sort = {};

	constructor(sortParams: KeyValuePair[] = [], facetParams: KeyValuePair[] = [], pageSize?: number) {
		sortParams.forEach(pair => {
			this.sort[pair.key] = pair.value;
		});
		facetParams.forEach(pair => {
			this.facets[pair.key] = pair.value;
		});
		if (pageSize) {
			this.pageSize = pageSize;
		}
	}

	public addSearch(value: string) {
		this.search = value;
	}

	public clearSearch() {
		this.search = '';
	}

	public addFacet(facetParam: KeyValuePair) {
		this.facets[facetParam.key] = facetParam.value;
	}

	public removeFacet(facetParam: KeyValuePair) {
		delete this.facets[facetParam.key];
	}

	public clearFacets() {
		this.facets = {};
	}

	public addSort(sortParam: KeyValuePair) {
		this.sort[sortParam.key] = sortParam.value;
	}

	public clearSort() {
		this.sort = {};
	}

	public resetPage() {
		this.page = 0;
	}

	toPayloadObject() {
		return {
			filter: JSON.stringify(this)
		};
	}
}

export class KeyValuePair {
	public key: string;
	public value: string;

	constructor(key: string, value: string) {
		this.key = key;
		this.value = value;
	}
}
