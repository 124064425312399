<div class="st-head"
	[ngClass]="{
		'compact': isCompactView,
		'in-modal': isInModal
	}">
		<div class="st-header">

			<!-- Standard view -->
			<ng-container *ngIf="!isCompactView">
				<div class="st-section" *ngFor="let column of config.columns; trackBy: trackByFn"
					[style.maxWidth]="column.maxWidth && column !== config.columns[0] ? column.maxWidth : ''"
					[title]="column.label">
						<div class="st-value column-header">
							{{ column.label }}
							<div class="secondary" *ngIf="column.secondaryLabel">
								{{ column.secondaryLabel }}
							</div>
						</div>
				</div>

				<!-- Actions inside a popover -->
				<div class="st-section actions">
					<ng-container *ngIf="!config.hideHeaderActions && hasSelectableData">
						<div class="st-actions-menu bi-three-dots-vertical" #headerActionsPop="bs-popover"
							placement="left"
							containerClass="standard-menu-popover header-actions-popover"
							container="body"
							[popover]="headerActionTemplate"
							[outsideClick]="true"
							[ngClass]="{
								'opened': headerActionsOpened,
								'disabled': !config.getHeaderActions().length || !someSelected
							}"
							[title]="'OpenMenu' | translate"
							(onShown)="headerActionsOpened = true"
							(onHidden)="headerActionsOpened = false"
							(click)="!config.getHeaderActions().length || !someSelected ? $event.stopImmediatePropagation() : null">
						</div>
						<ng-template #headerActionTemplate>
							<div class="standard-menu-item" *ngFor="let action of config.getHeaderActions(); trackBy: trackByFn"
								[title]="action.name"
								[ngClass]="{
									'red-highlight': action.isDeleteAction
								}"
								(click)="headerActionsPop.hide(); actionClick(action)">
									{{ action.name }}
							</div>
						</ng-template>
					</ng-container>
					<div class="st-checkbox" *ngIf="!config.isSingleSelect && hasSelectableData"
						(mouseover)="hovered = true"
						(mouseleave)="hovered = false"
						[ngClass]="{
							'disabled': !hasSelectableData,
							'bi-square': !hovered && !allSelected && hasSelectableData && !someSelected,
							'bi-check-square': hovered && !allSelected,
							'bi-check-square-fill': allSelected && hasSelectableData,
							'bi-dash-square': someSelected && !allSelected && !hovered
						}"
						[title]="allSelected ? ('UnselectAll' | translate) : ('SelectAll' | translate)"
						(click)="checkboxClick()">
					</div>
				</div>
			</ng-container>

			<!-- Compact view -->
			<ng-container *ngIf="isCompactView">

				<!-- Actions as separate icons -->
				<div class="st-section actions" >
					<ng-container *ngIf="!config.hideHeaderActions && hasSelectableData">
						<div class="st-action-icon {{ action.iconName }}" *ngFor="let action of config.getHeaderActions(); trackBy: trackByFn"
							[ngClass]="{
								'disabled': !someSelected
							}"
							[title]="action.name"
							(click)="actionClick(action)">
						</div>
					</ng-container>
					<div class="st-checkbox" *ngIf="!config.isSingleSelect && hasSelectableData"
						(mouseover)="hovered = true"
						(mouseleave)="hovered = false"
						[ngClass]="{
							'disabled': !hasSelectableData,
							'bi-square': !hovered && !allSelected && hasSelectableData && !someSelected,
							'bi-check-square': hovered && !allSelected,
							'bi-check-square-fill': allSelected && hasSelectableData,
							'bi-dash-square': someSelected && !allSelected && !hovered
						}"
						[title]="allSelected ? ('UnselectAll' | translate) : ('SelectAll' | translate)"
						(click)="checkboxClick()">
					</div>
				</div>
			</ng-container>
		</div>
</div>
