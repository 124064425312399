import { ListRange } from '@angular/cdk/collections';
import { AfterViewInit, ComponentRef, Directive, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationStart, Params, Router } from '@angular/router';
import { BaseService } from 'app-core/shared-core/abstract-components/service/base.service';
import { SimpleTableHeaderAction } from 'app-core/shared-core/simple-components/list/table/head/simple-table-header-action';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { ACCEPTED_MEDIA_TYPES } from 'app-core/shared-core/tools/file-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { ViewObjectsComponent, ViewObjectsType } from 'app-core/shared-core/view-objects/view-objects.component';
import { environment } from 'environments/environment';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthService } from '../../../auth/auth.service';
import { Filter, KeyValuePair, SortDirection } from '../../filter';
import { NumberUtils } from '../../tools/number-utils';
import { FILTER_DISPLAY_DATE_FORMAT, KEYS, Utils } from '../../tools/utils';
import { SimpleCrudDirective } from '../crud/simple-crud.directive';
import { SimpleListAction } from './actions/simple-list-action';
import { SimpleListDataSource } from './simple-list-data-source';
import { SimpleTableRow } from './table/body/simple-table-row';
import { SimpleTableRowAction, SimpleTableRowActionReferenceUrl } from './table/body/simple-table-row-action';
import { SimpleFilterInputType } from './table/filter/input-settings/simple-filter-input-settings';
import { SimpleFilterListModalInput } from './table/filter/input-settings/simple-filter-list-modal-input-settings';
import { SimpleFilterConfig, SortObject } from './table/filter/simple-filter-config';
import { SimpleFilterInputItem } from './table/filter/simple-filter-input-item';
import { SimpleRetainService } from './table/filter/simple-retain.service';
import { SimpleTableConfig } from './table/simple-table-config';
import { SimpleTableComponent } from './table/simple-table.component';

@Directive()
export abstract class SimpleListDirective<T extends { id: string, url: string, selected: boolean }> extends SimpleCrudDirective<T> implements OnInit, AfterViewInit, OnDestroy {

	listActions: SimpleListAction[] = [];

	readonly filterConfig = new SimpleFilterConfig<T>();
	readonly affectedIds = new Set<string>();
	readonly selectedIds = new Set<string>();
	readonly unselectableIds = new Set<string>();
	readonly addedIds = new Set<string>();
	readonly modifiedIds = new Set<string>();
	readonly tableConfig: SimpleTableConfig<T> = new SimpleTableConfig<T>();
	readonly filterObject = new Filter();
	readonly listReference = this;

	totalCount: number;
	filteredIds: string[] = [];
	isCompactView: boolean;
	dataSource: SimpleListDataSource<T>;
	allSelected: boolean;
	someSelected: boolean;
	sortProperty: string;
	sortLabel: string;
	sortReversed: boolean;
	initialSortValue: string;

	// When used inside a modal.
	isInModal: boolean;
	hideListActions: boolean;
	hideTableHeaderActions: boolean;
	hideTableRowActions: boolean;
	hideTableSelectBoxes: boolean;
	isSingleSelectTable: boolean;
	idsToSetAsSelected: string[] = [];
	idsToSetAsUnselectable: string[] = [];

	cachedRows: SimpleTableRow<T>[] = [];

	protected currentPrefixValue: string;
	protected currentTabIndexValue: number;

	protected isNavigation: boolean;

	protected fetchedPages: Array<SimpleTableRow<T>[]> = [];

	private dataFetchingIsEnabled: boolean;

	acceptedMediaTypesForImport = ACCEPTED_MEDIA_TYPES.SPREADSHEET;

	allowModalClosing: boolean;

	shouldClearAfterUpdate: boolean = true;
	lastRenderedRange: ListRange;

	@ViewChild(SimpleTableComponent) simpleTable: SimpleTableComponent<T>;
	@ViewChild('importInput') importInput: ElementRef;
	@ViewChild('listActionsContainer', { read: ViewContainerRef }) listActionsContainer: ViewContainerRef;
	@ViewChild('listActions') listActionsComponent: TemplateRef<any>;

	// Listens to navigation outside of the angular app such as browser refresh, browser close etc.
	@HostListener('window:beforeunload') unloadNotification() {
		return !this.hasUnsavedChanges();
	}

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		public readonly service: BaseService<T>,
		protected route: ActivatedRoute,
		protected router: Router,
		protected retainService: SimpleRetainService,
		protected elementRef: ElementRef,
		protected viewContainerRef: ViewContainerRef,
		protected prefix: string) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		// Initiate the datasource.
		this.dataSource = new SimpleListDataSource();

		this.applyFlagStates();

		// Apply configurations from child.
		this.configureListActions();
		this.configureTableFilter(this.filterConfig);
		this.configureTableSort(this.filterConfig);
		this.configureTableColumns(this.tableConfig);
		this.configureTableActions(this.tableConfig);
		this.configureTableEmptyState(this.tableConfig);

		// General configs.
		this.addCommonActions();

		// Set dimensions and viewmode.
		this.setRowHeights();
		this.setViewMode();
		this.subscriptions.add(
			fromEvent(window, 'resize')
				.pipe(
					debounceTime(NumberUtils.WINDOW_RESIZE_WAIT_TIME),
					takeUntil(this.destroyed$)
				)
				.subscribe(__ => {
					this.setViewMode();
					setTimeout(() => {
						this.simpleTable.simpleTableBody?.viewPort?.checkViewportSize();
					}, 0);
				})
		);


		// Validate table config and initiate.
		if (this.isValidTableConfig()) {
			const defaultSort = this.filterConfig.sortObjects.find(sortObject => sortObject.shouldBeDefault);

			if (defaultSort) {
				this.sortProperty = defaultSort.sortProperty;
				this.sortLabel = defaultSort.label;
				this.sortReversed = defaultSort.reversed;
			} else {
				this.setDefaultSort();
			}

			if (this.sortProperty) {
				const property = Utils.capitalizeFirstLetter(this.sortProperty);
				const direction = this.sortReversed ? SortDirection.Descending : SortDirection.Ascending;
				this.filterObject.addSort(new KeyValuePair(property, direction));

				this.initialSortValue = `${this.sortProperty}_${direction}`;
			}

			this.applyInitialFacets();

			if (!this.isInModal) {
				this.initQueryParamSubscriptionForModals();
				const retainEntries = Object.entries(this.retainService.getCurrentRetainEntries()) as [string, string][];
				const hasRetainParams = retainEntries.some(entry => this.route.snapshot.queryParams[entry[0]]);
				if (hasRetainParams) {
					// If we have retain params the navigation subscription will handle the initial load as well.
					this.isNavigation = true;
				} else {
					const prefixValue = this.route.snapshot.queryParams[this.prefix] as string;
					const [actionName, idString] = prefixValue ? prefixValue.split('_') : [];
					if (prefixValue && this.shouldScrollToItem(actionName, idString)) {
						await this.getTableDataAndScrollToItem(idString);
						this.setAsAffected([idString]);
					} else {
						await this.getTableData();
						this.dataFetchingIsEnabled = true;
					}
				}
				this.initNavigationSubscription();
				this.initQueryParamSubscription();
			} else {
				this.setPreValues();
				await this.getTableData();
				this.dataFetchingIsEnabled = true;
			}
		}
	}

	ngAfterViewInit() {
		this.initScrollSubscription();
	}

	applyInitialFacets() {
		this.filterConfig.initialFacets.forEach(facet => this.filterObject.addFacet(facet));
	}

	private addCommonActions() {
		if (this.prefix !== RoutesUtils.user) {
			// Add the copy reference row action for all lists except user.
			const secondToLastRowActionIndex = this.tableConfig.rowActions.length - 1;
			this.tableConfig.rowActions.splice(
				secondToLastRowActionIndex,
				0,
				new SimpleTableRowActionReferenceUrl(
					this.translationService.instant('CopyReference'),
					StringUtils.icons.copyReference,
					(row) => {
						this.setAsAffected([row.id]);
						this.toastrService.success(`${this.translationService.instant('ReferenceWasCopied')}!`);
					}
				)
			);
		}
	}

	private setRowHeights() {
		this.tableConfig.standardRowHeight = NumberUtils.TABLE_STANDARD_ROW_HEIGHT;
		this.tableConfig.compactRowHeight = (this.tableConfig.standardRowHeight * this.tableConfig.columns.length) + this.tableConfig.standardRowHeight;
		this.tableConfig.compactSectionHeight = this.tableConfig.standardRowHeight;
	}

	getSelectableIds() {
		return this.filteredIds.filter(id => !this.unselectableIds.has(id));
	}

	getSelectableRows() {
		return this.dataSource.rows$.value.filter(row => !this.unselectableIds.has(row.id));
	}

	hasSelectableData() {
		return !!this.dataSource && !!this.getSelectableRows().length;
	}

	// These are specified in every list.
	protected abstract configureListActions(): void;
	protected abstract configureTableFilter(config: SimpleFilterConfig<T>): void;
	protected abstract configureTableSort(config: SimpleFilterConfig<T>): void;
	protected abstract configureTableColumns(config: SimpleTableConfig<T>): void;
	protected abstract configureTableActions(config: SimpleTableConfig<T>): void;
	protected abstract configureTableEmptyState(config: SimpleTableConfig<T>): void;
	protected abstract import(file: File): void;
	protected abstract export(selectedIds: string[]): void;
	protected abstract setPropertiesAndOpenViewObjectsPopover(item: T, type: ViewObjectsType, rowClickNumber: number, rowId: string): void;
	protected abstract openModalByActionName(value: string): void;

	private setPending(value: boolean) {
		this.pending = value;
		requestAnimationFrame(() => {
			const cdkContentWrapper = this.simpleTable.simpleTableBody.viewPort._contentWrapper.nativeElement;
			if (cdkContentWrapper) {
				if (this.pending) {
					cdkContentWrapper.classList.add('loadmask');
				} else {
					cdkContentWrapper.classList.remove('loadmask');
				}
			}
		});
	}
	protected async getTableData(page: number = 0) {
		this.fetchedPages = [];
		this.setPending(true);
		await this.getFilteredIds();

		const promises = Array<Promise<any>>();

		// Get page.
		this.fetchedPages[page] = [];
		promises.push(this.getData(page));

		// Get previous page as well if there is one.
		const isFirstPage = page === 0;
		if (!isFirstPage) {
			this.fetchedPages[page - 1] = [];
			promises.push(this.getData(page - 1));
		}

		// Get next page as well if there is one.
		const isLastPage = page === Math.floor(this.filteredIds.length / NumberUtils.TABLE_DATA_PAGE_SIZE);
		if (!isLastPage) {
			this.fetchedPages[page + 1] = [];
			promises.push(this.getData(page + 1));
		}

		await Promise.all(promises);
		this.setPending(false);

		this.assignRows();
		this.scrollToIndex(0);
	}

	protected setAsAdded(ids: string[]) {
		const rowsToAdd = this.dataSource.rows$.value.filter(row => ids.includes(row.id));
		rowsToAdd.forEach(row => {
			row.added = true;
			this.addedIds.add(row.id);
		});

		this.refreshTableView();
	}

	protected setAsModified(ids: string[]) {
		const rowsToModify = this.dataSource.rows$.value.filter(row => ids.includes(row.id));
		rowsToModify.forEach(row => {
			row.modified = true;
			this.modifiedIds.add(row.id);
		});

		this.refreshTableView();
	}


	protected refreshTableView() {
		this.dataSource.rows$.next([...this.dataSource.rows$.value]);
	}

	private applyFlagStates() {
		// Set states based on permissions for regular lists only.
		if (!this.isInModal) {
			this.hideListActions = !this.authService.canEdit();
			this.hideTableHeaderActions = !this.authService.canEdit();
			this.hideTableRowActions = !this.authService.canEdit();
			this.hideTableSelectBoxes = !this.authService.canEdit();
		}

		// Apply the flag states on the table.
		this.tableConfig.setHideHeaderActions(this.hideTableHeaderActions);
		this.tableConfig.setHideRowActions(this.hideTableRowActions);
		this.tableConfig.setIsSingleSelect(this.isSingleSelectTable);
	}

	private setViewMode() {
		this.isCompactView = Utils.isSmallScreenSize();
	}

	private isValidTableConfig() {
		let hasColumns = false;
		let hasValidDefaultSort = false;

		if (this.tableConfig.columns.length) {
			hasColumns = true;
		} else {
			console.warn('At least one column is needed.');
		}

		const defaultSorts = this.filterConfig.sortObjects.filter(sortObject => sortObject.shouldBeDefault);
		if (defaultSorts.length === 0) {
			hasValidDefaultSort = true;
		} else if (defaultSorts.length === 1) {
			const column = defaultSorts[0];
			if (column.sortProperty) {
				hasValidDefaultSort = true;
			} else {
				console.warn('The column with default sort must have a sort property.');
			}
		} else {
			console.warn('Default sort may only be specified for one column.');
		}

		return hasColumns && hasValidDefaultSort;
	}

	private setDefaultSort() {
		const firstSortable = this.filterConfig.sortObjects.find(sortObject => sortObject.sortProperty);
		if (firstSortable) {
			this.sortProperty = firstSortable.sortProperty;
			this.sortLabel = firstSortable.label;
			this.sortReversed = firstSortable.reversed;
		}
	}

	private async getTableDataAndScrollToItem(itemId: string) {
		this.dataFetchingIsEnabled = false;
		const filteredIds = await this.service.getFilteredIds(this.filterObject);
		const existsInList = filteredIds.includes(itemId);

		let page = 0;
		if (existsInList) {
			const index = filteredIds.findIndex(id => id === itemId);
			page = Math.floor(index / NumberUtils.TABLE_DATA_PAGE_SIZE);
		}

		await this.getTableData(page);

		if (existsInList) {
			this.scrollToItem(itemId);
			this.enableDataFetching(itemId);
		}
	}

	private enableDataFetching(itemId: string) {
		setTimeout(() => {
			const renderedRange = this.simpleTable.simpleTableBody.viewPort.getRenderedRange()
			if (this.lastRenderedRange !== renderedRange) {
				this.lastRenderedRange = renderedRange;
				this.enableDataFetching(itemId);
			} else {
				// If gets stuck at the top, make sure it tries to scroll again.
				if (renderedRange.start === 0) {
					this.scrollToItem(itemId)
				}
				this.dataFetchingIsEnabled = true;
			}
		}, 100)
	}

	private async getFilteredIds() {
		try {
			const filteredIds = await this.service.getFilteredIds(this.filterObject);
			this.totalCount = filteredIds.length;
			this.filteredIds = filteredIds;
		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}

	private async getData(page: number) {
		try {
			this.filterObject.page = page;
			const data = await this.service.getFiltered(this.filterObject);

			this.applyData(data, page);
		} catch (errorResponse) {
			this.setPending(false);
			this.handleErrorResponse(errorResponse);
		}
	}

	private applyData(data: T[], page: number) {
		const rows = this.mapToTableRows(data);

		// If any of the data should be affected, make it affected.
		if (this.affectedIds.size > 0) {
			this.setRowsAsAffected(rows);
		}

		// If any of the data should be selected, make it selected.
		if (this.selectedIds.size > 0) {
			this.setRowsAsSelected(rows);
		}

		// If any of the data should be unselectable, make it unselectable.
		if (this.unselectableIds.size > 0) {
			this.setRowsAsUnselectable(rows);
		}

		// If any of the data should be added, make it added.
		if (this.addedIds.size > 0) {
			this.setRowsAsAdded(rows);
		}

		// If any of the data should be modified, make it modified.
		if (this.modifiedIds.size > 0) {
			this.setRowsAsModified(rows);
		}

		this.setReferenceUrl(rows);
		this.setIndex(rows);

		this.fetchedPages[page] = rows;
	}

	private assignRows() {
		const rowsToSet = new Array<SimpleTableRow<T>>().concat(...this.fetchedPages).filter(item => item);
		this.dataSource.rows$.next([...rowsToSet]);

		const selectableIds = this.getSelectableIds();
		this.allSelected = selectableIds.every(id => this.selectedIds.has(id));
		this.someSelected = selectableIds.some(id => this.selectedIds.has(id));

		// Cache rows in order for them to be selectable in list modals.
		if (this.isInModal) {
			rowsToSet.forEach(rowToSet => {
				const cachedRow = this.cachedRows.find(row => row.id === rowToSet.id);
				if (cachedRow) {
					Object.assign(cachedRow, rowToSet);
				} else {
					this.cachedRows.push(rowToSet);
				}
			});
		}
	}

	private mapToTableRows(data: T[]) {
		return data.map(item => new SimpleTableRow(item));
	}

	private setRowsAsAffected(rows: SimpleTableRow<T>[]) {
		rows.forEach(row => row.affected = this.affectedIds.has(row.id));
	}

	private setRowsAsSelected(rows: SimpleTableRow<T>[]) {
		rows.forEach(row => row.selected = this.selectedIds.has(row.id));
	}

	private setRowsAsUnselectable(rows: SimpleTableRow<T>[]) {
		rows.forEach(row => row.unselectable = this.unselectableIds.has(row.id));
	}

	private setRowsAsAdded(rows: SimpleTableRow<T>[]) {
		rows.forEach(row => row.added = this.addedIds.has(row.id));
	}

	private setRowsAsModified(rows: SimpleTableRow<T>[]) {
		rows.forEach(row => row.modified = this.modifiedIds.has(row.id));
	}

	private setReferenceUrl(rows: SimpleTableRow<T>[]) {
		rows.forEach(row => row.referenceUrl = this.getReferenceUrl(row));
	}

	private setIndex(rows: SimpleTableRow<T>[]) {
		rows.forEach(row => row.index = this.filteredIds.findIndex(id => id === row.id) + 1);
	}

	setListActions(...listActions: SimpleListAction[]) {
		this.listActions = listActions;
	}

	getListActions(): SimpleListAction[] {
		return this.listActions.filter(action => !action.hidden());
	}

	handleListActionClick(action: SimpleListAction) {
		action.event();
	}

	handleSearch(value: string) {
		this.filterObject.addSearch(value);

		if (!this.isInModal) {
			this.retainService.setParam(new KeyValuePair(RoutesUtils.searchParamString, value ? value : null));
			this.retainService.setRetainEntry(new KeyValuePair(RoutesUtils.searchParamString, value ? value : null));
		}

		this.getTableData();
	}

	handleFilter(keyValuePairs: KeyValuePair[]) {
		keyValuePairs.forEach(pair => {
			if (pair.value) {
				this.filterObject.addFacet(pair);

				if (!this.isInModal) {
					this.retainService.setParam(pair);
					this.retainService.setRetainEntry(pair);
				}
			} else {
				this.filterObject.removeFacet(pair);

				if (!this.isInModal) {
					this.retainService.setParam(new KeyValuePair(pair.key, null));
					this.retainService.setRetainEntry(new KeyValuePair(pair.key, null));
				}
			}
		});

		this.getTableData();
	}

	handleSearchClear() {
		if (!this.isInModal) {
			this.retainService.setParam(new KeyValuePair(RoutesUtils.searchParamString, null));
			this.retainService.clearCurrentRetainValues();
		}

		this.filterObject.clearSearch();

		this.getTableData();
	}

	handleFilterClear() {
		if (!this.isInModal) {
			const currentFacetKeys = Object.entries(this.filterObject.facets).map(facet => facet[0]);
			currentFacetKeys.forEach(key => this.retainService.setParam(new KeyValuePair(key, null)));
			this.retainService.clearCurrentRetainValues();
		}

		this.filterObject.clearFacets();
		this.clearSelectedFilterItems();

		this.applyInitialFacets();

		this.getTableData();
	}

	handleSelectedClear() {
		this.allSelected = false;

		this.selectedIds.clear();

		const selectableRows = this.getSelectableRows();
		selectableRows.forEach(row => row.selected = this.allSelected);

		this.someSelected = false;

		this.refreshTableView();
	}

	handleSort(sortObject: SortObject<T>) {
		if (sortObject.sortProperty === this.sortProperty) {
			this.sortReversed = !this.sortReversed;
		} else {
			this.sortProperty = sortObject.sortProperty;
			this.sortLabel = sortObject.label;
			this.sortReversed = false;
		}

		this.filterObject.clearSort();

		const property = Utils.capitalizeFirstLetter(this.sortProperty);
		const direction = this.sortReversed ? SortDirection.Descending : SortDirection.Ascending;
		this.filterObject.addSort(new KeyValuePair(property, direction));

		if (!this.isInModal) {
			const sortValue = `${this.sortProperty}_${direction}` === this.initialSortValue ? null : `${this.sortProperty}_${direction}`;
			this.retainService.setParam(new KeyValuePair(RoutesUtils.sortParamString, sortValue));
			this.retainService.setRetainEntry(new KeyValuePair(RoutesUtils.sortParamString, sortValue));
		}

		this.getTableDataWithDebounce();
	}

	private getTableDataWithDebounce = Utils.debounce(() => this.getTableData(), NumberUtils.DEFAULT_DEBOUNCE_TIME);

	handleHeaderActionClick(action: SimpleTableHeaderAction) {
		action.event();
	}

	handleHeaderCheckboxClick() {
		this.allSelected = !this.allSelected;
		const selectableIds = this.getSelectableIds();
		if (this.allSelected) {
			selectableIds.forEach(id => this.selectedIds.add(id));
		} else {
			selectableIds.forEach(id => this.selectedIds.delete(id));
		}

		const selectableRows = this.getSelectableRows();
		selectableRows.forEach(row => row.selected = this.allSelected);

		this.someSelected = selectableIds.some(id => this.selectedIds.has(id));

		this.refreshTableView();
	}

	handleRowActionClick([action, row]: [SimpleTableRowAction<T>, SimpleTableRow<T>]) {
		action.event(row);
	}

	handleRowCheckboxClick([event, row]: [MouseEvent, SimpleTableRow<T>]) {
		row.selected = !row.selected;
		if (row.selected) {
			if (this.isSingleSelectTable) {
				this.selectedIds.clear();
				const rowsToUnselect = this.dataSource.rows$.value.filter(dataRow => dataRow.id !== row.id);
				rowsToUnselect.forEach(dataRow => dataRow.selected = false);
			}
			this.selectedIds.add(row.id);
		} else {
			this.selectedIds.delete(row.id);
		}

		if (event?.getModifierState(KEYS.Control)) {
			this.toggleSimilarRows(row);
		}

		const selectableIds = this.getSelectableIds();
		this.allSelected = selectableIds.every(id => this.selectedIds.has(id));
		this.someSelected = selectableIds.some(id => this.selectedIds.has(id));
	}

	handleRowClick(row: SimpleTableRow<T>) {
		if (!this.hideTableSelectBoxes) {
			this.handleRowCheckboxClick([null, row]);
		}
	}

	handleRowDoubleClick(row: SimpleTableRow<T>) {
		const actions = this.tableConfig.rowActions.filter(rowAction => rowAction.isEditAction && !rowAction.hidden(row));
		if (actions.length && !this.hideTableRowActions) {
			actions[0].event(row);
		}
	}

	async handleAdded(items: T[]) {
		const itemIds = items.map(item => item.id);

		let itemId = itemIds[0];

		await this.getTableDataAndScrollToItem(itemId);

		this.setAsAffected(itemIds);
		this.setAsAdded(itemIds);

		if (this.isInModal) {
			if (this.isSingleSelectTable) {
				this.selectedIds.clear();
				const rowsToUnselect = this.dataSource.rows$.value.filter(dataRow => dataRow.id !== itemId);
				rowsToUnselect.forEach(dataRow => dataRow.selected = false);

				const row = this.dataSource.rows$.value.find(r => r.id === itemId);
				if (row) {
					row.selected = true;
				}
				this.selectedIds.add(itemId);
			} else {
				itemIds.forEach(id => {
					const row = this.dataSource.rows$.value.find(r => r.id === id);
					if (row) {
						row.selected = true;
					}
					this.selectedIds.add(id);
				});
			}
		}
	}

	async handleModified(items: T[]) {
		const itemIds = items.map(item => item.id);

		let itemId = itemIds[0];

		await this.getTableDataAndScrollToItem(itemId);

		this.setAsModified(itemIds);

		if (this.isInModal) {
			this.updated$.next(items);
		}

		if (this.shouldClearAfterUpdate) {
			this.clearAfterUpdate();
		}

		this.shouldClearAfterUpdate = true;
	}

	clearAfterUpdate() {
		this.selectedIds.clear();
		this.dataSource.rows$.value.forEach(item => item.selected = false);
		this.allSelected = false;
		this.someSelected = false;
	}

	async handleRemoved(itemIds: string[]) {
		let itemId = itemIds[0];

		// Get the previous itemId to scroll to instead, if there is one.
		const index = this.filteredIds.filter(id => !itemIds.includes(id) || id === itemId).findIndex(id => id === itemId);
		if (index > 0) {
			itemId = this.filteredIds[index - 1];
			await this.getTableDataAndScrollToItem(itemId);
		} else {
			await this.getTableData();
		}

		itemIds.forEach(id => {
			this.affectedIds.delete(id);
			this.selectedIds.delete(id);
			this.unselectableIds.delete(id);
			this.addedIds.delete(id);
			this.modifiedIds.delete(id);
		});

		if (this.isInModal) {
			this.deleted$.next(itemIds);
		}
	}

	protected setAsAffected(ids: string[]) {
		this.dataSource.rows$.value.forEach(row => row.affected = false);
		this.affectedIds.clear();

		const rowsToAffect = this.dataSource.rows$.value.filter(row => ids.includes(row.id));
		rowsToAffect.forEach(row => {
			row.affected = true;
			this.affectedIds.add(row.id);
		});

		this.refreshTableView();
	}

	private toggleSimilarRows(row: SimpleTableRow<T>) {
		let idsToToggleAsWell = new Array<string>();
		const selectableIds = this.getSelectableIds();
		if (row.added) {
			idsToToggleAsWell = selectableIds.filter(id => this.addedIds.has(id) && id !== row.id);
		} else if (row.modified) {
			idsToToggleAsWell = selectableIds.filter(id => this.modifiedIds.has(id) && id !== row.id);
		} else if (row.affected) {
			idsToToggleAsWell = selectableIds.filter(id => this.affectedIds.has(id) && id !== row.id);
		}

		idsToToggleAsWell.forEach(id => {
			const dataRow = this.dataSource.rows$.value.find(r => r.id === id);
			if (dataRow) {
				dataRow.selected = row.selected;
			}
			if (row.selected) {
				this.selectedIds.add(id);
			} else {
				this.selectedIds.delete(id);
			}
		});
	}

	private scrollToItem(itemId: string) {
		const index = this.findItemIndex(itemId);
		this.scrollToIndex(index);
	}

	private findItemIndex(itemId: string) {
		return this.dataSource.rows$.value.findIndex(row => row.id === itemId);
	}

	private scrollToIndex(index: number) {
		this.simpleTable.simpleTableBody.viewPort.scrollToIndex(index, 'smooth');
	}

	private setPreValues() {
		// Set selected items if any was specified.
		if (this.idsToSetAsSelected.length) {
			this.setSelectedItems();
		}

		// Set unselectable items if any was specified.
		if (this.idsToSetAsUnselectable.length) {
			this.setUnselectableItems();
		}
	}

	setSelectedItems() {
		if (this.isSingleSelectTable) {
			this.selectedIds.add(this.idsToSetAsSelected[0]);
		} else {
			this.idsToSetAsSelected.forEach(id => this.selectedIds.add(id));
		}
	}

	setUnselectableItems() {
		this.idsToSetAsUnselectable.forEach(id => this.unselectableIds.add(id));
	}

	protected setCrudParams(value: string, index?: number) {
		this.router.navigate([], {
			queryParams: {
				[this.prefix]: value,
				[RoutesUtils.modalTabParam]: index ? index : 1
			},
			queryParamsHandling: 'merge'
		});
	}

	protected subscribeToCrudModalContent() {
		this.currentCrudModalComponent = this.bsModalRef.content;
		this.subscriptions.add(
			this.currentCrudModalComponent.created$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(createdItems => {
					if (createdItems && createdItems.length) {
						this.handleAdded(createdItems);
						this.closeModal();
					}
				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.updated$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(updatedItems => {
					if (updatedItems && updatedItems.length) {
						this.handleModified(updatedItems);
						this.closeModal();
					}
				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.deleted$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(deletedItemIds => {
					if (deletedItemIds && deletedItemIds.length) {
						this.handleRemoved(deletedItemIds);
						this.closeModal(true);
					}
				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(wasClosed => {
					if (wasClosed) {
						this.closeModalWithUnsavedChanges();
					}
				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.handled$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(wasHandled => {
					if (wasHandled) {
						this.closeModal();
						if (this.shouldClearAfterUpdate) {
							this.clearAfterUpdate();
							this.refreshTableView();
						}
					}
				})
		)
	}

	closeModal(replaceUrl?: boolean) {
		this.allowModalClosing = true;
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
		this.currentCrudModalComponent = null;
		this.allowModalClosing = false;

		if (!this.isInModal) {
			this.resetValuesAndParams(replaceUrl);
		}
	}

	closeModalWithUnsavedChanges(wasNavigation?: boolean) {
		if (this.hasUnsavedChanges()) {
			this.displayUnsavedChangesSwal()
				.then(result => {
					if (result.value) {
						this.closeModal();
					} else {
						if (wasNavigation) {
							// Put the stored paramvalues back in the url again as we have navigated away.
							this.setCrudParams(this.currentPrefixValue, this.currentTabIndexValue);
						} else {
							// Set the closed value to false again as we have clicked the close button.
							this.currentCrudModalComponent.closed$.next(false);
						}
					}
				});
		} else {
			this.closeModal();
		}
	}

	private resetValuesAndParams(replaceUrl?: boolean) {
		this.currentPrefixValue = null;
		this.currentTabIndexValue = null;

		this.router.navigate([], {
			queryParams: {
				[this.prefix]: null,
				[RoutesUtils.modalTabParam]: null
			},
			queryParamsHandling: 'merge',
			replaceUrl: !!replaceUrl
		});
	}

	protected closeInterceptorConfig() {
		return {
			closeInterceptor: () => {
				if (this.allowModalClosing) {
					return Promise.resolve();
				} else {
					return Promise.reject();
				}
			}
		} as ModalOptions;
	}

	private getReferenceUrl(row: SimpleTableRow<T>) {
		return `${environment.coreUrl}/${this.selectedOrganization.friendlyUrl}/${row.data.url}`;
	}

	triggerFilter(filterInput: SimpleFilterListModalInput) {
		this.simpleTable.simpleFilter.handleFilter(filterInput);
	}

	protected setSelectedFilterItems() {
		const updatedRetainEntries = Object.entries(this.retainService.getCurrentRetainEntries()) as [string, string][];
		for (const [retainKey, retainValue] of updatedRetainEntries) {
			const filterInput = this.filterConfig.filterInputs.find(fi => fi.id === retainKey);
			if (filterInput) {
				const selectedItems = new Array<SimpleFilterInputItem>();
				const values = retainValue ? retainValue.split(',') : [];
				if (filterInput.type === SimpleFilterInputType.DateSelect) {
					if (values.length) {
						const value = values[0];
						selectedItems.push(
							new SimpleFilterInputItem(
								value,
								Utils.getFormattedDateStringFromString(value, FILTER_DISPLAY_DATE_FORMAT)
							)
						);
						filterInput.dateValue = new Date(value);
					} else {
						filterInput.dateValue = null;
					}
				} else if (filterInput.type === SimpleFilterInputType.DateRangeSelect) {
					if (values.length) {
						const value = values[0];
						const [fromDate, toDate] = value.split('_');
						selectedItems.push(
							new SimpleFilterInputItem(
								value,
								`${Utils.getFormattedDateStringFromString(fromDate, FILTER_DISPLAY_DATE_FORMAT)} & ${Utils.getFormattedDateStringFromString(toDate, FILTER_DISPLAY_DATE_FORMAT)}`
							)
						);
						filterInput.dateRangeValue = [new Date(fromDate), new Date(toDate)];
					} else {
						filterInput.dateRangeValue = [];
					}
				} else {
					values.forEach(value => {
						const item = filterInput.items.find(i => i.id === value);
						if (item) {
							selectedItems.push(new SimpleFilterInputItem(value, item.name));
						}
					});
				}

				filterInput.selectedItems = selectedItems;

				// Trigger changes.
				document.body.click();
			}
		}
	}

	private clearSelectedFilterItems() {
		this.filterConfig.filterInputs.forEach(filterInput => filterInput.selectedItems = []);
	}

	handleImportInputChange(event: Event) {
		const input = (event.target as HTMLInputElement);
		const file = input.files[0];
		if (file) {
			this.import(file);
		}
		input.value = '';
	}

	private initScrollSubscription() {
		setTimeout(() => {
			this.subscriptions.add(
				this.elementRef.nativeElement.querySelector('.st-body').addEventListener('wheel', () => {
					this.elementRef.nativeElement.querySelector('.view-objects')?.remove();
					document.body.querySelector('.vm-menu-popover')?.remove();
					this.simpleTable.simpleTableBody.popovers.forEach(popover => popover.hide());
				})
			)
		});

		this.subscriptions.add(
			this.dataSource.collectionViewer.viewChange
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(async range => {
					// Only run when is active and has data.
					if (this.dataFetchingIsEnabled && range.start !== range.end) {

						const promises = Array<Promise<any>>();
						let idToScrollTo = '';

						// Upward scroll -> get the previous page if there is one.
						if (range.start === 0) {
							const earliestPageIndex = this.fetchedPages.findIndex(page => page && page.length);
							const isFirstPage = earliestPageIndex === 0;
							if (!isFirstPage && !this.fetchedPages[earliestPageIndex - 1]) {
								this.fetchedPages[earliestPageIndex - 1] = [];
								promises.push(this.getData(earliestPageIndex - 1));

								// Scroll to the first item of the previous page in order to be able to scroll upwards again.
								// For downward scroll this is already done automatically by the lib.
								idToScrollTo = this.dataSource.rows$.value[0]?.id;
							}
						}

						// Downward scroll -> get the next page if there is one.
						if (range.end === this.dataSource.rows$.value.length) {
							const latestPageIndex = this.fetchedPages.reduce((acc, page, index) => (page && page.length ? index : acc), - 1);
							const isLastPage = latestPageIndex === Math.floor(this.filteredIds.length / NumberUtils.TABLE_DATA_PAGE_SIZE);
							if (!isLastPage && !this.fetchedPages[latestPageIndex + 1]) {
								this.fetchedPages[latestPageIndex + 1] = [];
								promises.push(this.getData(latestPageIndex + 1));
							}
						}

						if (promises.length) {
							this.setPending(true);
							await Promise.all(promises);
							this.assignRows();
							this.setPending(false);

							if (idToScrollTo) {
								this.scrollToItem(idToScrollTo);
							}
						}
					}
				})
		);
	}

	private initNavigationSubscription() {
		this.subscriptions.add(
			this.router.events
				.pipe(takeUntil(this.destroyed$))
				.subscribe((event: NavigationStart) => {
					if (event.navigationTrigger === 'popstate') {
						this.isNavigation = true;

						// Cancel any open swals when navigating.
						if (Swal.isVisible()) {
							Swal.clickCancel();
						}
					}
				})
		);
	}

	private initQueryParamSubscription() {
		this.subscriptions.add(
			this.route.queryParams
				.pipe(takeUntil(this.destroyed$))
				.subscribe(queryParams => {
					if (this.isNavigation) {
						this.handleRetainParamLogic(queryParams);
						this.isNavigation = false;
					}
				})
		);
	}

	private initQueryParamSubscriptionForModals() {
		this.subscriptions.add(
			this.route.queryParams
				.pipe(takeUntil(this.destroyed$))
				.subscribe(queryParams => {
					this.handleModalParamLogic(queryParams);
				})
		);
	}

	private async handleRetainParamLogic(queryParams: Params) {
		let shouldGetData = false;
		const retainEntries = Object.entries(this.retainService.getCurrentRetainEntries()) as [string, string][];

		// Loop through the retain entries and apply any changes.
		for (const [retainKey, retainValue] of retainEntries) {
			let paramValues = queryParams[retainKey] as string;

			if (paramValues) {
				if (paramValues !== retainValue) {
					if (retainKey === RoutesUtils.searchParamString) {
						this.filterObject.addSearch(paramValues);
						this.retainService.setRetainEntry(new KeyValuePair(retainKey, paramValues));
					} else if (retainKey === RoutesUtils.sortParamString) {
						const [sortProperty, sortDirection] = paramValues.split('_');

						const sortObject = this.filterConfig.sortObjects.find(r => r.sortProperty === sortProperty);
						if (sortObject) {
							this.sortProperty = sortObject.sortProperty;
							this.sortLabel = sortObject.label;
							this.sortReversed = sortDirection === SortDirection.Descending;
						}

						this.filterObject.clearSort();

						if (this.sortProperty) {
							const property = Utils.capitalizeFirstLetter(this.sortProperty);
							const direction = this.sortReversed ? SortDirection.Descending : SortDirection.Ascending;
							this.filterObject.addSort(new KeyValuePair(property, direction));

							paramValues = `${this.sortProperty}_${direction}` === this.initialSortValue ? null : `${this.sortProperty}_${direction}`;
							this.retainService.setRetainEntry(new KeyValuePair(retainKey, paramValues));
						}
					} else {
						this.filterObject.addFacet(new KeyValuePair(retainKey, paramValues));
						this.retainService.setRetainEntry(new KeyValuePair(retainKey, paramValues));
					}
					shouldGetData = true;
				}
			} else {
				if (retainValue) {
					if (retainKey === RoutesUtils.searchParamString) {
						this.filterObject.clearSearch();
						this.retainService.setRetainEntry(new KeyValuePair(retainKey, null));
					} else if (retainKey === RoutesUtils.sortParamString) {
						const defaultSort = this.filterConfig.sortObjects.find(sortObject => sortObject.shouldBeDefault);
						if (defaultSort) {
							this.sortProperty = defaultSort.sortProperty;
							this.sortLabel = defaultSort.label;
							this.sortReversed = defaultSort.reversed;
						} else {
							this.setDefaultSort();
						}

						this.filterObject.clearSort();

						if (this.sortProperty) {
							const property = Utils.capitalizeFirstLetter(this.sortProperty);
							const direction = this.sortReversed ? SortDirection.Descending : SortDirection.Ascending;
							this.filterObject.addSort(new KeyValuePair(property, direction));
							this.retainService.setRetainEntry(new KeyValuePair(retainKey, null));
						}
					} else {
						this.filterObject.removeFacet(new KeyValuePair(retainKey, null));
						this.retainService.setRetainEntry(new KeyValuePair(retainKey, null));
					}
					shouldGetData = true;
				}
			}
		}

		this.setSelectedFilterItems();

		const prefixValue = queryParams[this.prefix] as string;
		const [actionName, idString] = prefixValue ? prefixValue.split('_') : [];
		if (prefixValue && this.shouldScrollToItem(actionName, idString)) {
			shouldGetData = true;
		}

		if (shouldGetData) {
			if (prefixValue && prefixValue !== this.currentPrefixValue && this.shouldScrollToItem(actionName, idString)) {
				await this.getTableDataAndScrollToItem(idString);
				this.setAsAffected([idString]);
			} else {
				await this.getTableData();
				this.dataFetchingIsEnabled = true;
			}
		}
	}

	private handleModalParamLogic(queryParams: Params) {
		const prefixValue = queryParams[this.prefix] as string;
		if (prefixValue) {
			if (prefixValue !== this.currentPrefixValue) {

				this.openModalByActionName(prefixValue);

				// Select items when opening bulk actions.
				const [actionName, idString] = prefixValue.split('_');
				if (this.isBulkAction(actionName)) {
					const itemIds = idString ? idString.split(',') : [];
					const onlySelectableItemIds = itemIds.filter(id => !this.unselectableIds.has(id));
					onlySelectableItemIds.forEach(id => {
						const row = this.dataSource.rows$.value.find(r => r.id === id);
						if (row) {
							row.selected = true;
						}
						this.selectedIds.add(id);
					});
					this.setAsAffected(onlySelectableItemIds);

					const selectableIds = this.getSelectableIds();
					this.allSelected = selectableIds.every(id => this.selectedIds.has(id));
					this.someSelected = selectableIds.some(id => this.selectedIds.has(id));

					this.refreshTableView();
				}

				// Store the current prefix value.
				this.currentPrefixValue = prefixValue;
			}

			// Store the current tab index.
			const indexValue = queryParams[RoutesUtils.modalTabParam];
			if (indexValue) {
				this.currentTabIndexValue = indexValue;
			}
		} else {
			if (this.bsModalRef) {
				this.closeModalWithUnsavedChanges(true);
			}
		}
	}

	private shouldScrollToItem(actionName: string, idString: string) {
		// Only scroll to an item when is not create and is only one id.
		return actionName !== RoutesUtils.modalPrefixValueNew
			&& actionName !== RoutesUtils.modalPrefixValueInvite
			&& idString && !idString.includes(',');
	}

	protected isBulkAction(actionName: string) {
		return actionName.includes(RoutesUtils.modalPrefixValueBulk);
	}

	prepareViewObjectsPopover(item: T, type: ViewObjectsType, rowClickNumber: number, rowId: string) {
		const viewObjectsPopover = document.body.querySelector('.view-objects') as HTMLElement;
		if (viewObjectsPopover) {
			viewObjectsPopover.remove();
			this.viewContainerRef.clear();

			if (viewObjectsPopover.dataset.id === rowId && viewObjectsPopover.dataset.type === type) {
				return;
			}
		}

		this.setPropertiesAndOpenViewObjectsPopover(item, type, rowClickNumber, rowId);
	}

	openViewObjectsPopover(componentRef: ComponentRef<ViewObjectsComponent>, rowClickNumber: number, rowId: string, iconName: string) {
		const objectsDiv = DomUtils.getObjectsDiv(componentRef);
		let context: HTMLElement;
		if (this.isCompactView) {
			context = document.getElementById(`${rowId}${iconName}`);
			const compactViewButtonBounds = context.getBoundingClientRect();
			objectsDiv.style.bottom = `calc(0px + ${compactViewButtonBounds.height}px + 1px)`;
			context.append(objectsDiv);
		} else {
			context = DomUtils.getPopovermenu();
			objectsDiv.style.left = `calc(-${objectsDiv.offsetWidth}px - 1px)`;
			objectsDiv.style.top = `calc(${rowClickNumber} * 38px)`;
			DomUtils.setObjectsDivBounds(context, objectsDiv);
			context.append(objectsDiv);
		}
	}

	hasUnsavedChanges() {
		return this.currentCrudModalComponent?.hasUnsavedChanges();
	}

	ngOnDestroy() {
		if (!this.isInModal) {
			this.retainService.clearCurrentRetainEntries();
		}

		if (this.bsModalRef) {
			this.closeModal();
		}

		this.elementRef.nativeElement.querySelector('.st-body')?.removeAllListeners();

		super.ngOnDestroy();
	}
}
