<simple-crud-modal
	[titleText]="('UserReal' | translate) + (modifiedModel?.fullName ? ' ─ ' + modifiedModel?.fullName : '')"
	[closeText]="'Close' | translate"
	[submitText]="(isEdit ? loggedInUser.canEdit(selectedOrganization.friendlyUrl) && (canChangeDetails() || canChangeAuthorization()) || modifiedModel?.id === loggedInUser.id ? 'Update' : '' : isInvite ? 'Invite' : 'Create') | translate"
	[disableSubmit]="!everythingIsValid() || !hasUnsavedChanges()"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab *ngIf="isEdit && modifiedModel.id !== loggedInUser.id"
				[customClass]="'valid'"
				(selectTab)="setTabIndexInUrl(1)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi-search'"
						[title]="'Overview' | translate"
						[helpText]="'UserViewDetailsHelpText' | translate">
					</simple-tab-heading>
				</ng-template>
				<view-details-tab
					[viewModel]="modifiedModel"
					[documents]="currentDocuments"
					[schedules]="schedules">
				</view-details-tab>
			</tab>
			<tab *ngIf="(loggedInUser.canEdit(selectedOrganization.friendlyUrl) && (canChangeDetails() || canChangeAuthorization()) || modifiedModel.id === loggedInUser.id)"
				(selectTab)="setTabIndexInUrl(isEdit && modifiedModel.id !== loggedInUser.id ? 2 : 1)"
				[customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi-card-text'"
						[title]="'Details' | translate"
						[helpText]="isEdit ? ('UserChangeDetailsEditHelpText' | translate) : isInvite ? ('UserChangeDetailsHelpText' | translate) : ('CreateNewUserHelpText' | translate)">
					</simple-tab-heading>
				</ng-template>
				<change-details-tab
					[changeModel]="modifiedModel"
					[canChangeDetails]="canChangeDetails()"
					[canChangeAuthorization]="canChangeAuthorization()"
					[documents]="currentDocuments"
					[isInvite]="isInvite"
					(onValueChange)="handleValueChanges()"
					(onMediaChange)="currentDocuments = $event">
				</change-details-tab>
			</tab>
		</tabset>
</simple-crud-modal>
