<assessment-content
	[isMeasure]="isMeasure"
	[errorResult]="errorResult"
	[resolvementResult]="resolvementResult"
	[isCompleted]="isCompleted"
	[wasClosed]="wasClosed"
	(onAssessmentClicked)="openResult()">
</assessment-content>
<ng-template [ngIf]="isMeasure">
	<result-content
		[isMeasure]="isMeasure"
		[errorResult]="errorResult"
		[resolvementResult]="resolvementResult">
	</result-content>
</ng-template>
