import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';
import { RuleObject } from 'app-core/shared-core/simple-components/various/rules/simple-rules.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { ListUserGroupComponent } from 'app-core/user-group/list-user-group/list-user-group.component';
import { UserGroup } from 'app-core/user-group/user-group';
import { ScheduleGroup } from 'app-inspection/schedule/schedule';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'handle-schedule-groups-tab',
	templateUrl: './handle-schedule-groups-tab.component.html',
	styleUrls: ['./handle-schedule-groups-tab.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandleScheduleGroupsTabComponent extends SimpleHandleTabDirective<ScheduleGroup> implements OnInit {

	globalDummyGroup = new ScheduleGroup({});

	// Special ovverride due to using ScheduleGroup.
	bsModalRef: BsModalRef<any>;

	toggleListUserGroupModal() {
		this.bsModalRef = this.modalService.show(
			ListUserGroupComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: this.readonlyListModal,
					hideTableHeaderActions: this.readonlyListModal,
					hideTableRowActions: this.readonlyListModal,
					idsToSetAsSelected: this.handleItems.map(item => item.userGroup).map(item => item.id)
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	itemListIsValid() {
		return true;
	}

	override emitModelsChange() {
		this.handleItems.sortByProperty(this.propertyStrings.name);
		super.emitModelsChange();
	}

	protected validate() { }

	initiateGlobalRules() {
		const selectedItems = this.handleItems.filter(model => model.selected === true);
		this.globalDummyGroup.canPerformScheduled = selectedItems.every(item => item.canPerformScheduled);
		this.globalDummyGroup.canPerformMeasure = selectedItems.every(item => item.canPerformMeasure);
		this.globalDummyGroup.canAssign = selectedItems.every(item => item.canAssign);
	}

	handleRuleClick(item: ScheduleGroup, rule: RuleObject) {
		const propertyName = Utils.uncapitalizeFirstLetter(rule.title);
		item[propertyName] = !item[propertyName];

		if (propertyName === this.propertyStrings.canAssign && item.canAssign) {
			item.canPerformScheduled = true;
		} else if (propertyName === this.propertyStrings.canPerformScheduled && !item.canPerformScheduled) {
			item.canAssign = false;
		}
	}

	handleRuleClickGlobal(rule: RuleObject) {
		const propertyName = Utils.uncapitalizeFirstLetter(rule.title);
		this.globalDummyGroup[propertyName] = !this.globalDummyGroup[propertyName];
		const selectedItems = this.handleItems.filter(model => model.selected === true);
		selectedItems.forEach(item => item[propertyName] = this.globalDummyGroup[propertyName]);

		if (propertyName === this.propertyStrings.canAssign && this.globalDummyGroup.canAssign) {
			this.globalDummyGroup.canPerformScheduled = true;
			selectedItems.forEach(item => item.canPerformScheduled = true);
		} else if (propertyName === this.propertyStrings.canPerformScheduled && !this.globalDummyGroup.canPerformScheduled) {
			this.globalDummyGroup.canAssign = false;
			selectedItems.forEach(item => item.canAssign = false);
		}
	}

	isMediumScreenSize() {
		return Utils.isMediumScreenSize();
	}

	override handleConfirmed(confirmedItems: UserGroup[]) {
		const mappedConfirmedItems = confirmedItems.map(confirmedItem => {
			const existingItem = this.handleItems.find(item => item.userGroup.id === confirmedItem.id);
			if (existingItem) {
				return existingItem;
			} else {
				return new ScheduleGroup({
					userGroup: confirmedItem,
					userGroupId: confirmedItem.id
				});
			}
		});
		this.handleItems = this.constructItemList(this.handleItems, mappedConfirmedItems);
		this.setAllAreSelected();
		this.setSomeAreSelected();
		this.emitModelsChange();
		this.closeModal();
	}

	override constructItemList(currentItemList: ScheduleGroup[], confirmedItemList: ScheduleGroup[]) {
		// Reduce to only confirmed items.
		currentItemList = currentItemList
			.filter(currentItem => confirmedItemList.find(confirmedItem => confirmedItem.userGroup.id === currentItem.userGroup.id));

		// Apply only new items.
		currentItemList = currentItemList
			.concat(confirmedItemList
				.filter(confirmedItem => !currentItemList.find(currentItem => currentItem.userGroup.id === confirmedItem.userGroup.id)));

		return currentItemList;
	}

	override remove(itemToRemove: ScheduleGroup) {
		this.handleItems = this.handleItems.filter(model => model.userGroup.id !== itemToRemove.userGroup.id);
		this.setAllAreSelected();
		this.setSomeAreSelected();
		this.emitModelsChange();
	}

	toggleCanBeAssigned(scheduleGroup: ScheduleGroup) {
		scheduleGroup.canBeAssigned = !scheduleGroup.canBeAssigned;
	}

	getItems() {
		return this.searchValue
			? this.handleItems.filter(item => item.userGroup.name.toLowerCase().includes(this.searchValue.toLowerCase()))
			: this.handleItems;
	}
}
