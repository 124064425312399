import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { AssignmentTemplate } from 'app-inspection/assignment-template/assignment-template';
import { AssignmentTemplateService } from 'app-inspection/assignment-template/assignment-template.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SetGeoFencingTabComponent } from './tabs/set-geo-fencing-tab.component';

@Component({
	templateUrl: './set-geo-fencing.component.html'
})
export class SetGeoFencingComponent extends SimpleBulkEditModalDirective<AssignmentTemplate> implements OnInit {

	@ViewChild(SetGeoFencingTabComponent) setAssignmentTemplateGeoFencingTabComponent: SetGeoFencingTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private assignmentTemplateService: AssignmentTemplateService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.initialModel = new AssignmentTemplate({});
		await this.getGeoFencingData(this.initialModel.geoControlled);
	}

	private async getGeoFencingData(value: boolean) {
		this.pending = true;
		try {
			const response = await this.assignmentTemplateService.getGeoFencingData(this.editModelIds, value, this.selectedOrganization.id);
			this.affectableIds = response.data;
			this.pending = false;
		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	handleValueChanges() {
		if (this.setAssignmentTemplateGeoFencingTabComponent) {
			const geoControlledCtrl = this.setAssignmentTemplateGeoFencingTabComponent.form.get(this.propertyStrings.geoControlled);
			this.getGeoFencingData(geoControlledCtrl.value);
		}
	}

	changeDetailsIsValid() {
		return this.setAssignmentTemplateGeoFencingTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.setAssignmentTemplateGeoFencingTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const ids = this.useAffectableIds ? this.affectableIds : this.editModelIds;
			const response = await this.assignmentTemplateService.updateGeoFencing(ids, this.initialModel.geoControlled, this.selectedOrganization.id);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.setAssignmentTemplateGeoFencingTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: AssignmentTemplate) {
		return new AssignmentTemplate(item);
	}

	hasUnsavedChanges() {
		return false;
	}
}
