<simple-crud-modal
	[titleText]="('Schedule' | translate) + (modifiedModel?.name ? ' ─ ' + modifiedModel?.name : '')"
	[closeText]="'Close' | translate"
	[submitText]="(isEdit ? 'Update' : 'Create') | translate"
	[disableSubmit]="!everythingIsValid() || !hasUnsavedChanges()"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab
				[customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(1)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-card-text'"
							[title]="'Details' | translate"
							[helpText]="'ScheduleChangeDetailsHelpText' | translate">
						</simple-tab-heading>
					</ng-template>
					<change-details-tab
						[changeModel]="modifiedModel"
						(onValueChange)="handleValueChanges()"
						(onDateChange)="handleDateChanges()">
					</change-details-tab>
			</tab>
			<tab #handleTab="tab"
				[customClass]="handleAssignmentTemplatesIsValid() ? 'valid' : handleAssignmentTemplatesHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(2)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-map'"
							[title]="'AssignmentTemplates' | translate"
							[helpText]="'ScheduleHandleAssignmentTemplatesHelpText' | translate"
							[displayCount]="modifiedModel.assignmentTemplates.length">
						</simple-tab-heading>
					</ng-template>
					<handle-assignment-templates-tab
						[handleItems]="modifiedModel.assignmentTemplates"
						[headerSubtitleText]="'AddedAssignmentTemplatesSubtitleSchedule' | translate"
						[readonlyListModal]="true"
						[wasSelected]="handleTab.active"
						(onModelsChange)="modifiedModel.assignmentTemplates = $event">
					</handle-assignment-templates-tab>
			</tab>
			<tab #handleTab2="tab"
				[customClass]="handleGroupsIsValid() ? 'valid' : handleGroupsHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(3)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-people'"
							[title]="'Groups' | translate"
							[helpText]="'ScheduleHandleGroupsHelpText' | translate"
							[displayCount]="modifiedModel.scheduleGroups.length">
						</simple-tab-heading>
					</ng-template>
					<handle-schedule-groups-tab
						[handleItems]="modifiedModel.scheduleGroups"
						[headerSubtitleText]="'AddedGroupsSubtitleSchedule' | translate"
						[readonlyListModal]="true"
						[wasSelected]="handleTab2.active"
						(onModelsChange)="modifiedModel.scheduleGroups = $event">
					</handle-schedule-groups-tab>
			</tab>
			<tab #handleTab3="tab"
				[customClass]="handleUsersIsValid() ? 'valid' : handleUsersHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(4)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-person'"
							[title]="'Assignees' | translate"
							[helpText]="'ScheduleHandleUsersHelpText' | translate"
							[displayCount]="modifiedModel.scheduleUsers.length">
						</simple-tab-heading>
					</ng-template>
					<handle-schedule-users-tab
						[handleItems]="modifiedModel.scheduleUsers"
						[headerSubtitleText]="'AddedUsersSubtitleSchedule' | translate"
						[readonlyListModal]="true"
						[selectedScheduleGroups]="modifiedModel.scheduleGroups"
						[wasSelected]="handleTab3.active"
						(onModelsChange)="modifiedModel.scheduleUsers = $event">
					</handle-schedule-users-tab>
			</tab>
			<tab #handleTab4="tab"
				[customClass]="handleSubscribersIsValid() ? 'valid' : handleSubscribersHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(5)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-bell'"
							[title]="'Subscribers' | translate"
							[helpText]="'ScheduleHandleSubscribersHelpText' | translate"
							[displayCount]="modifiedModel.subscribers.length">
						</simple-tab-heading>
					</ng-template>
					<handle-subscribers-tab
						[handleItems]="modifiedModel.subscribers"
						[headerSubtitleText]="'AddedSubscribersSubtitleSchedule' | translate"
						[readonlyListModal]="true"
						[wasSelected]="handleTab4.active"
						[bypassRequiredError]="true"
						[displayUrgentIcon]="hasAtLeastOneNotificationChoice"
						(onModelsChange)="modifiedModel.subscribers = $event">
					</handle-subscribers-tab>
			</tab>
		</tabset>
</simple-crud-modal>
