import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app-core/auth/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleChangeBulkTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/bulk/simple-change-bulk-tab.directive';
import { Router } from '@angular/router';
import { User } from 'app-core/user/user';

@Component({
	selector: 'set-status-tab',
	templateUrl: './set-status-tab.component.html'
})
export class SetStatusTabComponent extends SimpleChangeBulkTabDirective<User> implements OnInit {

	selectableStatusItems: FormSelectItem[] = [];

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableStatusItems();

		this.form = this.formBuilder.group({
			[this.propertyStrings.status]: [this.changeModel.status,
			Validators.required
			]
		});
		super.ngOnInit();
	}

	private setSelectableStatusItems() {
		this.selectableStatusItems = [
			new FormSelectItem(StringUtils.ACTIVE, this.translationService.instant(StringUtils.ACTIVE)),
			new FormSelectItem(StringUtils.INACTIVE, this.translationService.instant(StringUtils.INACTIVE)),
		];
	}
}
