
<div class="list-wrapper" [ngClass]="{'has-checkboxes': shouldDisplayTriggerButton}">
	<ul class="list">
		<li *ngFor="let row of additionalInfoRow.relatedData; trackBy: trackByFn"
			class="item-row"
			[ngClass]="{'has-error': row.errorMessage?.length, 'is-showing-extra-row': row.shouldShowMultiSelect}">
			<div class="row-main-content">
				<a class="link" *ngIf="!row.hideLink"
					[ngClass]="{ 'multi-selection-available': additionalInfoRow.reason === reasons.TemplateTypeHasTemplates }"
					target="_blank"
					href="{{ row.url }}"
					title="{{ ((additionalInfoRow.reason === reasons.TaskChoiceHasActiveAssignment
						|| additionalInfoRow.reason === reasons.TemplateHasActiveAssignment
						|| additionalInfoRow.reason === reasons.UserGroupHasActiveAssignment
						|| additionalInfoRow.reason === reasons.AssignmentHasResultsWithMedia
						|| additionalInfoRow.reason === reasons.AssignmentHasResultsWithComments
						|| additionalInfoRow.reason === reasons.ScheduledResultPriorityRemoved) ? 'GoToAssignment'
						: (additionalInfoRow.reason === reasons.TaskChoiceHasActiveMeasures
							|| additionalInfoRow.reason === reasons.MeasureHasPriorityWithoutPriorityChoice) ? 'GoToMeasure'
						: (additionalInfoRow.reason === reasons.ScheduleHasBeenPaused) ? 'GoToSchedule'
						: (additionalInfoRow.reason === reasons.TemplateTypeHasTaskWithMoreThanOneStandardChoice
							|| additionalInfoRow.reason === reasons.TemplateTypeTaskHasOnlyUnspecifiedChoices
							|| additionalInfoRow.reason === reasons.TemplateTypeManualMeasureHasNoErrorChoice
							|| additionalInfoRow.reason === reasons.TemplateTypeHasDefaultTaskWithNoDefaultChoice) ? 'GoToTemplateType'
						:'GoToAssignmentTemplate') | translate }}">
					<div class="name-link-container">
						<div class="name">
							<ng-container *ngIf="row.email">
								{{ row.firstName }} {{ row.lastName }}
							</ng-container>
							<ng-container *ngIf="!row.email">
								{{ row.name }}
							</ng-container>
							<div class="description" title="{{row.facility?.name}}" *ngIf="row.facility?.name && !row.email">
								{{ row.facility?.name }}
							</div>
							<div class="warning" *ngIf="additionalInfoRow.reason === reasons.TemplateTypeHasTemplates && row.duplicateExisted">
								{{ 'AdditionalInfoAssTempWillBeRemovedInfo' | translate }}
							</div>
							<div class="warning" *ngIf="additionalInfoRow.reason === reasons.TaskChoiceHasActiveAssignment && row.duplicateExisted">
								{{ 'AdditionalInfoActiveAssWillBeRemovedInfo' | translate }}
							</div>
						</div>
						<div class="link-icon"></div>
					</div>
				</a>
				<ng-container *ngIf="additionalInfoRow.reason === reasons.TemplateTypeHasTemplates && row.rowShouldHaveCheckbox">
					<ng-select
						[items]="dropDownItems"
						[clearable]="false"
						[searchable]="false"
						[bindValue]="'id'"
						[bindLabel]="'name'"
						(change)="setShouldShowMultiSelectForRow(row)"
						[(ngModel)]="row.facilityOrEntities">
					</ng-select>
				</ng-container>
				<div class="checkbox" *ngIf="shouldDisplayTriggerButton && additionalInfoRow.shouldHaveCheckboxes && (additionalInfoRow.reason === reasons.TemplateTypeHasTemplates && row.rowShouldHaveCheckbox)
					|| shouldDisplayTriggerButton && additionalInfoRow.shouldHaveCheckboxes && additionalInfoRow.reason === reasons.FacilityHasTemplates"
					(click)="selectRow(row)">
					<div class="checkbox-wrapper">
						<input
							[id]="row.id"
							[value]="row.id"
							type="checkbox"/>
						<span class="checkmark"></span>
					</div>
				</div>
			</div>
			<div *ngIf="row.shouldShowMultiSelect"
				class="row-extra-content"
				(click)="toggleListEntityModal(row)"
				title="{{'OpenEntityList' | translate}}">
				<div class="row-extra-content-text">
					{{ row.multiSelectValues.length ? row.multiSelectValues : ('AddEntities' | translate) }}
				</div>
				<div class="list-icon"></div>
			</div>
			<div *ngIf="row.errorMessage?.length"
				class="error-text"
				[innerHTML]="row.errorMessage">
			</div>
		</li>
	</ul>
</div>
