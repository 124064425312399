import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'lineBreaks'
})
export class LineBreaksPipe implements PipeTransform {
	transform(value: any): string {
		return value.replace(/(\r\n|\n|\r)/gm, '<br />');
	}
}
