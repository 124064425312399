<simple-change-bulk-tab
	[form]="form"
	[affectableIdsCount]="affectableIdsCount"
	[selectedIdsCount]="selectedIdsCount">
		<div class="change-bulk-section">
			<div class="change-bulk-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-select
				[label]="'Priority' | translate"
				[name]="propertyStrings.priority"
				[form]="form"
				[items]="selectablePriorityItems"
				[serverErrors]="serverErrors">
			</simple-form-select>
		</div>
</simple-change-bulk-tab>
