<simple-change-bulk-tab
	[form]="form"
	[selectedIdsCount]="selectedIdsCount">
		<div class="change-bulk-section">
			<div class="change-bulk-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-select
				[label]="'Client' | translate"
				[name]="propertyStrings.clientId"
				[form]="form"
				[items]="selectableClientItems"
				[multiple]="false"
				[searchable]="true"
				[serverErrors]="serverErrors"
				[disabled]="clientsIsDisabled">
			</simple-form-select>

			<simple-form-info *ngIf="clientsIsDisabled"
				[firstText]="'Organization' | translate"
				[linkText]="selectedOrganization.orgName"
				[linkHref]="disabledClientLink"
				[secondText]="'DisabledClientInfoText' | translate"
				[name]="selectedOrganization.orgName">
			</simple-form-info>
		</div>
</simple-change-bulk-tab>
