import { Component, Input } from '@angular/core';
import { MediaResolver } from 'app-core/media/resolver/media-resolver';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { FileHelper } from 'app-core/shared-core/tools/file-helper';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Result } from 'app-inspection/assignment/assignment';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'result-content',
	templateUrl: './result-content.component.html',
	styleUrls: ['./result-content.component.less']
})
export class ResultContentComponent {
	@Input() isMeasure: boolean = false;
	@Input() errorResult: Result;
	@Input() resolvementResult: Result;

	constructor(
		private translationService: TranslationService,
		private toastrService: ToastrService,
		private mediaResolver: MediaResolver,
		private fileHelper: FileHelper) { }

	async downloadAll(items: MediaWidgetItem[]) {
		const itemsToConvert = items.map(item => {
			return {
				title: item.title,
				url: this.mediaResolver.resolve(item.url),
				isImage: item.isImage
			} as MediaWidgetItem;
		});
		const succeeded = await this.fileHelper.convertToZipAndDownload(itemsToConvert);
		if (!succeeded) {
			this.toastrService.error(this.translationService.instant(StringUtils.SOME_FILES_COULD_NOT_BE_DOWNLOADED_ERROR));
		}
	}
}
