import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCrudDirective } from 'app-core/shared-core/simple-components/crud/simple-crud.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RoutesUtils } from '../../../app-core/shared-core/tools/routes-utils';

@Component({
	selector: 'update-ass-temps-with-entities',
	templateUrl: './update-assignment-templates-with-entities.component.html',
	styleUrls: ['./update-assignment-templates-with-entities.component.less']
})
export class UpdateAssignmentTemplateWithEntitiesComponent extends SimpleCrudDirective<any> implements OnInit {

	assignmentTemplatesToUpdate: AssignmentTemplateWithEntities[] = [];

	selectAllHovered: boolean = false;
	assignmentTemplateBaseUrl: string = '';

	@Output() onUpdate = new EventEmitter<AssignmentTemplateWithEntities[]>()
	@Output() onSelection = new EventEmitter<string[]>();

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit(): void {
		this.assignmentTemplateBaseUrl = `${this.selectedOrganization.friendlyUrl}/${RoutesUtils.assignmentTemplates}?${RoutesUtils.assignmentTemplate}=${RoutesUtils.modalPrefixValueEdit}`;
		this.assignmentTemplatesToUpdate.forEach(assignmentTemplate => {
			assignmentTemplate.url = `${this.assignmentTemplateBaseUrl}_${assignmentTemplate.id}&${RoutesUtils.modalTabParam}=1`;
			assignmentTemplate.selected = true;
		});
	}

	isSelected(assignmentTemplate: AssignmentTemplateWithEntities) {
		return assignmentTemplate.selected;
	}

	areSomeSelected() {
		return this.assignmentTemplatesToUpdate.some(at => at.selected)
	}

	areAllSelected() {
		return this.assignmentTemplatesToUpdate.every(at => at.selected);
	}

	selectAll() {
		if (this.areSomeSelected() && !this.areAllSelected()) {
			this.assignmentTemplatesToUpdate.forEach(at => at.selected = false);
		}
		this.assignmentTemplatesToUpdate.forEach(at => this.selectRow(at));
	}

	selectRow(row: AssignmentTemplateWithEntities) {
		if (this.isSelected(row)) {
			row.selected = false;
		} else {
			row.selected = true;
		}
	}

	close() {
		this.onUpdate.next([]);
	}

	triggerUpdate() {
		if (this.areSomeSelected() || this.areAllSelected()) {
			this.onUpdate.next(this.assignmentTemplatesToUpdate.filter(at => at.selected))
		}
	}
}

class AssignmentTemplateWithEntities {
	id: string;
	name: string;
	facilityId: string;
	url: string;
	selected: boolean;
	hovered: boolean;

	constructor(assignmentTemplateWithEntities: Partial<AssignmentTemplateWithEntities>) {
		Object.assign(this, assignmentTemplateWithEntities);
	}
}
