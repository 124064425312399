<div class="page-container presentation-page">
	<div class="page__heading small"></div>
	<div class="page__body">
		<div class="row row--column-reset">
			<div class="col-xs-12">
				<div class="error-content"
					[ngClass]="{
						'not-authorized-forbidden': errorType === errorTypeKey.NotAuthorized || errorType === errorTypeKey.Forbidden,
						'not-found': errorType === errorTypeKey.NotFound,
						'bad-request': errorType === errorTypeKey.BadRequest,
						'server-error': errorType === errorTypeKey.ServerError
					}">
						<div class="error-image"></div>
						<div class="error-header">
							{{ errorType }} -
							<ng-container *ngIf="errorType === errorTypeKey.NotAuthorized || errorType === errorTypeKey.Forbidden">
								{{ 'NotAuthorizedForbidden' | translate }}
							</ng-container>
							<ng-container *ngIf="errorType === errorTypeKey.NotFound">
								{{ 'PageNotFound' | translate }}
							</ng-container>
							<ng-container *ngIf="errorType === errorTypeKey.BadRequest">
								{{ 'BadRequest' | translate }}
							</ng-container>
							<ng-container *ngIf="errorType === errorTypeKey.ServerError">
								{{ 'ServerError' | translate }}
							</ng-container>
						</div>
						<div class="error-text">
							<ng-container *ngIf="errorType === errorTypeKey.NotAuthorized || errorType === errorTypeKey.Forbidden">
								<p [innerHTML]="('NotAuthorizedForbiddenDesc' | translate: translateParam).formatBold()">
								</p>
							</ng-container>
							<ng-container *ngIf="errorType === errorTypeKey.NotFound">
								<p [innerHTML]="('PageNotFoundDesc' | translate: translateParam).formatBold()">
								</p>
							</ng-container>
							<ng-container *ngIf="errorType === errorTypeKey.BadRequest">
								<p [innerHTML]="('BadRequestDesc' | translate: translateParam).formatBold()">
								</p>
							</ng-container>
							<ng-container *ngIf="errorType === errorTypeKey.ServerError">
								<p [innerHTML]="('ServerErrorDesc' | translate: translateParam).formatBold()">
								</p>
							</ng-container>
						</div>
						<div
							class="error-home-button"
							(click)="handleRedirectClick()">
						</div>
				</div>
			</div>
		</div>
	</div>
</div>
