import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewChildren } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { MediaUtils } from '../tools/media-utils';
import { KEYS } from '../tools/utils';

@Component({
	selector: 'markdown-editor',
	templateUrl: './markdown-editor.component.html',
	styleUrls: ['./markdown-editor.component.less']
})
export class MarkdownEditorComponent implements AfterViewInit {

	media = MediaUtils;
	previewIsActive: boolean = true;
	previewButtonIsClicked: boolean = false;
	orderedListRegex: RegExp = new RegExp(/(\d)\.\s.+/g);
	unorderedListRegex: RegExp = new RegExp(/-\s.+/g);
	oldValue: string = '';
	writingString: string = '';
	currentTextareaValue: string;
	lastCursorPosition: number;
	actionsOpened: boolean = false;
	isFocused: boolean = false;

	@Input() initialTextareaValue: string = '';
	@Input() toolbarAsPopover: boolean = false;
	@Input() errorText: string = '';
	@Input() formControlName: string = '';

	@Output() onTextChange = new EventEmitter();
	@Output() onBlur = new EventEmitter();
	@Output() onFocus = new EventEmitter();

	@ViewChild('textarea', { read: ElementRef }) textAreaEl: ElementRef;
	@ViewChild('actionsPop') popover: PopoverDirective;

	@ViewChildren('button', { read: ElementRef }) buttonsEl: ElementRef[];

	@HostListener('document:mousedown', ['$event']) onGlobalClick(event: any): void {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.blur();
		}
	}

	constructor(private elementRef: ElementRef) { }

	get buttons(): HTMLButtonElement[] {
		return this.buttonsEl.map(button => button.nativeElement);
	}

	get textArea(): HTMLTextAreaElement {
		return this.textAreaEl.nativeElement;
	}

	ngAfterViewInit() {
		this.initialTextareaValue = this.initialTextareaValue ? this.initialTextareaValue : '';
		this.currentTextareaValue = this.initialTextareaValue;
	}

	focus() {
		this.previewIsActive = false;
		this.previewButtonIsClicked = false;
		setTimeout(() => {
			this.textArea.focus();
			this.isFocused = true;
			this.onFocus.emit(true);
		}, 0);
	}

	blur() {
		this.previewIsActive = true;
		this.previewButtonIsClicked = false;
		this.isFocused = false;
		this.onFocus.emit(false);
	}

	handleKeyPress(event: any) {
		if (this.previewIsActive) {
			this.previewIsActive = false;
			setTimeout(() => {
				this.textArea.focus();
			}, 200);
		} else {
			// If key is backspace, remove last character.
			if (event.key === KEYS.Backspace) {
				this.writingString = this.writingString.slice(0, this.writingString.length - 1);
			} else {
				// Else add the character to the string.
				this.writingString = this.writingString + (event.key === KEYS.Enter ? '\n' : event.key);
			}
			if (event.key === KEYS.Enter) {
				setTimeout(() => {
					// If key is enter, find the portion of text just written.
					const searchString = this.textArea.value.slice(this.textArea.selectionEnd - (this.oldValue.length + this.writingString.length), this.textArea.selectionEnd + (this.oldValue.length + this.writingString.length));
					this.writingString = '';
					let isOrderedList: boolean;

					// See if the first character of the search string is a number.
					const possibleNumber = parseInt(searchString.slice(0, 1), 10);
					if (isNaN(possibleNumber)) {
						if (searchString.match(/-\s/)) {
							isOrderedList = false;
						}
					} else {
						if (searchString.match(/\d\.\s/)) {
							isOrderedList = true;
						}
					}
					this.handleListInput(isOrderedList, searchString, possibleNumber);
				});
			}
			this.currentTextareaValue = this.textArea.value;
		}
	}

	handleListInput(isOrderedList: boolean, searchString: string, number: number) {
		let line = '';

		// Search backwards from the selection (cursor) until the whole search string is searched.
		for (let i = this.textArea.selectionEnd; i > this.textArea.selectionEnd - (searchString.length + 1); i--) {
			line = this.textArea.value.slice(this.textArea.selectionEnd - (this.textArea.selectionEnd - i), this.textArea.selectionEnd);

			/**
			 *  If there is a match for either ordered list regex (digit followed by a dot, a space and any text) or
			 * unordered list regex (dash followed by a space and any text), then we want to input a new line with a new list item in the beginning.
			 * */
			if (line.match(isOrderedList ? this.orderedListRegex : this.unorderedListRegex)) {
				const stringToInsert = isNaN(number) ? '- ' : `${number + 1}. `;
				const beforeString = this.textArea.value.slice(0, this.textArea.selectionEnd);
				const endString = this.textArea.value.slice(this.textArea.selectionEnd);

				this.oldValue = stringToInsert;
				this.textArea.value = beforeString + stringToInsert + endString;
				this.textArea.setSelectionRange(beforeString.length + stringToInsert.length, beforeString.length + stringToInsert.length);
				break;

				/**
				 * If no match is found but there is a start of a new list item (ordered list: digit followed by a dot and a space | unordered list: dash followed by a space),
				 * then remove that new list item after enter has been pressed so the user ends up with a new empty line.
				 */
			} else if (line.match(isOrderedList ? /\d\.\s\n/ : /-\s\n/)) {
				const beforeString = this.textArea.value.slice(0, this.textArea.selectionEnd - (this.oldValue.length + 1));
				const endString = this.textArea.value.slice(this.textArea.selectionEnd);
				this.oldValue = '';
				this.textArea.value = beforeString + endString;
				this.textArea.setSelectionRange(beforeString.length, beforeString.length);
				break;
			}
		}
		this.currentTextareaValue = this.textArea.value;
		this.emitChanges(this.currentTextareaValue);
	}

	handleKeyDownEvent(event: KeyboardEvent) {
		if (event.getModifierState(KEYS.Control)) {
			if (this.isToolbarShortcut(event)) {
				event.preventDefault();
			}
			if (this.toolbarAsPopover) {
				this.popover.containerClass = 'hidden';
				this.popover.show();
				setTimeout(() => {
					this.performClick(event);
					this.popover.hide();
					this.popover.containerClass = '';
				}, 0);
			} else {
				this.performClick(event);
			}
		}
	}

	isToolbarShortcut(event: KeyboardEvent) {
		return event.key.toLocaleLowerCase() === KEYS.b
			|| event.key.toLocaleLowerCase() === KEYS.i
			|| event.key.toLocaleLowerCase() === KEYS.d
			|| event.key.toLocaleLowerCase() === KEYS.k;
	}

	performClick(event: KeyboardEvent) {
		switch (event.key.toLocaleLowerCase()) {
			case KEYS.b:
				this.clickButton(KEYS.b);
				break;
			case KEYS.i:
				this.clickButton(KEYS.i);
				break;
			case KEYS.d:
				this.clickButton(KEYS.d);
				break;
			case KEYS.k:
				this.clickButton(KEYS.k);
				break;
		}
	}

	clickButton(id: string) {
		this.buttons.find(button => button.id === id)?.click();
	}

	listOngoing(listType: string) {
		// If the list is of type 'Ordered'
		if (listType === 'ol') {
			this.oldValue = '1. ';
			// else the list is of type 'Unordered'
		} else {
			this.oldValue = '- ';
		}
	}

	handlePreviewClick() {
		this.previewButtonIsClicked = !this.previewButtonIsClicked;

		if (this.previewButtonIsClicked) {
			this.lastCursorPosition = this.textArea.selectionEnd;
			this.previewIsActive = true;
		} else {
			this.previewIsActive = false;
			setTimeout(() => {
				this.textArea.setSelectionRange(this.lastCursorPosition, this.lastCursorPosition);
				this.textArea.focus();
			}, 0);
		}
	}

	insertTable() {
		if (!this.previewIsActive) {
			const tableString = '\n|**RubrikKolumn1**|**RubrikKolumn2**|\n|---|---|\n|Feltyp|_Felbeskrivning_|\n|Feltyp|_Felbeskrivning_|\n|Feltyp|_Felbeskrivning_|\n';

			if (this.textArea.selectionStart || this.textArea.selectionStart === 0) {
				const startPos = this.textArea.selectionStart;
				const endPos = this.textArea.selectionEnd;
				this.textArea.value = this.textArea.value.substring(0, startPos)
					+ tableString
					+ this.textArea.value.substring(endPos, this.textArea.value.length);
			} else {
				this.textArea.value += tableString;
			}

			this.currentTextareaValue = this.textArea.value;
			this.textArea.focus();
			this.emitChanges(this.currentTextareaValue);
		}
	}

	emitChanges(event: string) {
		this.currentTextareaValue = event;
		this.onTextChange.emit(this.currentTextareaValue);
	}
}
