<simple-change-tab
	[form]="form">
		<div class="change-section">
			<div class="change-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-input
				[label]="'LegalName' | translate"
				[name]="propertyStrings.name"
				[form]="form"
				[maxlength]="50"
				[serverErrors]="serverErrors"
				[focusOnInit]="!isEdit">
			</simple-form-input>

			<simple-form-input
				[label]="'OrganizationNumber' | translate"
				[name]="propertyStrings.organizationNumber"
				[form]="form"
				[serverErrors]="serverErrors">
			</simple-form-input>

			<simple-form-input
				[label]="'EmailAddress' | translate"
				[name]="propertyStrings.emailAddress"
				[form]="form"
				[serverErrors]="serverErrors">
			</simple-form-input>

			<div class="form-control-wrapper">
				<simple-form-select
					[label]="'CountryCode' | translate"
					[name]="propertyStrings.countryCode"
					[form]="form"
					[items]="selectableCountryCodeItems"
					[serverErrors]="serverErrors">
				</simple-form-select>

				<simple-form-input class="stretch"
					[label]="'PhoneNumber' | translate"
					[name]="propertyStrings.phoneNumber"
					[form]="form"
					[serverErrors]="serverErrors">
				</simple-form-input>
			</div>

			<simple-form-input
				[label]="'OperationsManager' | translate"
				[name]="propertyStrings.operationsManager"
				[form]="form"
				[maxlength]="50"
				[serverErrors]="serverErrors">
			</simple-form-input>
		</div>

		<div class="change-section">
			<div class="change-section-header">
				{{ 'Address' | translate }}
			</div>
			<simple-form-input
				[label]="'StreetAddress' | translate"
				[name]="propertyStrings.streetAddress"
				[form]="form"
				[maxlength]="50"
				[serverErrors]="serverErrors">
			</simple-form-input>

			<div class="form-control-wrapper">
				<simple-form-input
					[label]="'ZipCode' | translate"
					[name]="propertyStrings.zipCode"
					[form]="form"
					[maxlength]="15"
					[serverErrors]="serverErrors">
				</simple-form-input>

				<simple-form-input class="stretch"
					[label]="'City' | translate"
					[name]="propertyStrings.city"
					[form]="form"
					[maxlength]="50"
					[serverErrors]="serverErrors">
				</simple-form-input>
			</div>

			<simple-form-select
				[label]="'Country' | translate"
				[name]="propertyStrings.country"
				[form]="form"
				[items]="selectableCountryItems"
				[searchable]="true"
				[serverErrors]="serverErrors">
			</simple-form-select>
		</div>
</simple-change-tab>
