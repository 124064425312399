import { NgModule } from '@angular/core';
import { UserGroupService } from 'app-core/user-group/user-group.service';
import { AssignmentTemplateService } from 'app-inspection/assignment-template/assignment-template.service';
import { AssignmentService } from 'app-inspection/assignment/assignment.service';
import { ReportService } from 'app-inspection/assignment/report/report.service';
import { ChoiceService } from 'app-inspection/choice/choice.service';
import { EntityService } from 'app-inspection/entity/entity.service';
import { FacilityService } from 'app-inspection/facility/facility.service';
import { StatisticsService } from 'app-inspection/statistics/statistics.service';
import { TaskService } from 'app-inspection/task/task.service';
import { TemplateTypeService } from 'app-inspection/template-type/template-type.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ScheduleService } from '../app-inspection/schedule/schedule.service';
import { AdministrationService } from './administration/administration.service';
import { DashboardService } from './dashboard/dashboard.service';
import { ErrorService } from './error/error.service';
import { MediaResolver } from './media/resolver/media-resolver';
import { IntegrationService } from './organization/my-organization/integrations/integration.service';
import { OrganizationService } from './organization/organization.service';
import { AdditionalInfoMiddleware } from './shared-core/additional-info/additional-info.middleware';
import { AppMonitoringService } from './shared-core/app-monitoring/app-monitoring.service';
import { ExportService } from './shared-core/export/export.service';
import { ImportHelper } from './shared-core/import/import-helper';
import { ImportService } from './shared-core/import/import.service';
import { LocalStorageService } from './shared-core/local-storage/local-storage.service';
import { PhoneValidationHelper } from './shared-core/phone-validation/phone-validation-helper';
import { SimpleRetainService } from './shared-core/simple-components/list/table/filter/simple-retain.service';
import { FileHelper } from './shared-core/tools/file-helper';
import { ParseXLSXService } from './shared-core/tools/parse-xlsx.service';
import { TranslationService } from './shared-core/translation/translation.service';
import { UserSupportLocalStorageService } from './user-support/user-support-local-storage.service';
import { UserSupportService } from './user-support/user-support.service';
import { UserService } from './user/user.service';

@NgModule({
	providers: [
		ErrorService,
		ParseXLSXService,
		ImportService,
		ExportService,
		ReportService,
		ImportHelper,
		FileHelper,
		LocalStorageService,
		UserSupportLocalStorageService,
		AppMonitoringService,
		AssignmentService,
		AssignmentTemplateService,
		FacilityService,
		EntityService,
		UserService,
		UserGroupService,
		MediaResolver,
		TemplateTypeService,
		TaskService,
		ChoiceService,
		OrganizationService,
		IntegrationService,
		AdditionalInfoMiddleware,
		BsLocaleService,
		ScheduleService,
		StatisticsService,
		SimpleRetainService,
		PhoneValidationHelper,
		TranslationService,
		UserSupportService,
		AdministrationService,
		DashboardService
	]
})
export class ServicesModule { }
