<simple-crud-modal
	[titleText]="'UserReal' | translate"
	[closeText]="'Close' | translate"
	[submitText]="getSelectedCountText('Update' | translate)"
	[disableSubmit]="!everythingIsValid() || !affectableIdsAreValid()"
	[isLoading]="pending"
	[displayDummy]="!initialModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="initialModel">
			<tab [customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-card-text'"
						[title]="'Details' | translate"
						[helpText]="'UserSetStatusHelpText' | translate">
					</simple-tab-heading>
				</ng-template>
				<set-status-tab
					[changeModel]="initialModel"
					[affectableIdsCount]="affectableIds.length"
					[selectedIdsCount]="editModelIds.length"
					(onValueChange)="handleValueChanges()">
				</set-status-tab>
			</tab>
		</tabset>
</simple-crud-modal>
