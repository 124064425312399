import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Task } from 'app-inspection/task/task';
import { AuthService } from 'app-core/auth/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { Router } from '@angular/router';

@Component({
	selector: 'change-details-tab',
	templateUrl: './change-details-tab.component.html'
})
export class ChangeDetailsTabComponent extends SimpleChangeTabDirective<Task> implements OnInit {

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.form = this.formBuilder.group({
			[this.propertyStrings.text]: [this.changeModel.text,
			[Validators.required, Validators.maxLength(150)]
			],
			[this.propertyStrings.code]: [this.changeModel.code,
			Validators.maxLength(150)
			],
			[this.propertyStrings.description]: [this.changeModel.description,
			Validators.maxLength(2000)
			],
			[this.propertyStrings.isRequired]: [this.changeModel.isRequired,
			this.rulesHelper],
			[this.propertyStrings.isDefault]: [this.changeModel.isDefault,
			this.rulesHelper],
			[this.propertyStrings.allowComment]: [this.changeModel.allowComment],
			[this.propertyStrings.allowMedia]: [this.changeModel.allowMedia]
		});
		super.ngOnInit();
	}

	private rulesHelper(control: AbstractControl) {
		if (control.parent) {
			const form = control.parent;
			const controlName = (Object.keys(form.controls).find(key => form.controls[key] === control));
			const isRequired = form.get('isRequired');
			const isDefault = form.get('isDefault');

			if (controlName === 'isRequired' && control.value) {
				isDefault.setValue(!control.value);
			} else if (controlName === 'isDefault' && control.value) {
				isRequired.setValue(!control.value);
			}
		}
		return null;
	}
}
