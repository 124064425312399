<div class="feedback-action" #helpPop="bs-popover"
	placement="bottom"
	container="body"
	containerClass="feedback-popover"
	[outsideClick]="true"
	[popover]="feedbackTemplate"
	[ngClass]="{
		'opened': popoverOpened,
		'in-modal': isInModal,
		'disabled': wasSent || wasNotSent || sending || loading
	}"
	[title]="'ContactSupport' | translate"
	(disabled)="wasSent || wasNotSent || sending || loading"
	(onShown)="popoverOpened = true; focusTextarea()"
	(onHidden)="popoverOpened = false">
		<i class="bi bi-chat-heart" *ngIf="!sending && !wasSent && !wasNotSent && !screenshot"></i>
		<i class="bi bi-envelope-paper" *ngIf="screenshot"></i>
		<i class="bi bi-send" *ngIf="sending"></i>
		<i class="bi bi-envelope-check" *ngIf="wasSent"></i>
		<i class="bi bi-envelope-slash" *ngIf="wasNotSent"></i>
</div>
<ng-template #feedbackTemplate>
	<div class="feedback-wrapper"
		[ngClass]="{
			'loadmask': loading
		}">
			<div class="feedback">
				<textarea #textarea
					id="textarea"
					class="feedback-textarea apx-scrollbar"
					[placeholder]="'DescribeCase' | translate"
					[rows]="10"
					[maxLength]="maxLength"
					[(ngModel)]="feedback.description">
				</textarea>
				<span class="counter">
					{{ feedback.description.length }} / {{ maxLength }}
				</span>
			</div>
			<button class="btn btn-success"
				[disabled]="!feedback.description.length || feedback.description.length > maxLength"
				(click)="submit(helpPop)">
				{{ 'Send' | translate }}
			</button>
			<dots-loader *ngIf="loading"></dots-loader>
	</div>
</ng-template>