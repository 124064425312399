import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SimpleCrudDirective } from 'app-core/shared-core/simple-components/crud/simple-crud.directive';
import { DEFAULT_DATE_FORMAT, FILTER_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import * as moment from 'moment';
import { SimpleFilterInput, SimpleFilterInputType } from '../input-settings/simple-filter-input-settings';
import { SimpleFilterListModalInput } from '../input-settings/simple-filter-list-modal-input-settings';
import { SimpleFilterInputItem } from '../simple-filter-input-item';

@Component({
	selector: 'simple-filter-sub',
	templateUrl: './simple-filter-sub.component.html',
	styleUrls: ['./simple-filter-sub.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleFilterSubComponent extends SimpleCrudDirective<any> {

	inputTypes = SimpleFilterInputType;
	randomNumberString = Utils.getRandomNumberString();

	@Input() filterInputs: SimpleFilterInput[];

	@Output() onFilter = new EventEmitter<SimpleFilterInput>();
	@Output() onFilterClear = new EventEmitter();
	@Output() onClose = new EventEmitter();
	@Output() onLstModalOpened = new EventEmitter();

	// MULTISELECT

	getMultiSelectSettings(filterInput: SimpleFilterInput) {
		this.setInputLabel(filterInput);

		switch (filterInput.type) {
			case SimpleFilterInputType.MultiSelect:
				return this.getSettings(filterInput);
			case SimpleFilterInputType.SingleSelect:
				return this.getSettings(filterInput, {
					singleSelection: true
				});
			case SimpleFilterInputType.MultiSelectWithSearch:
				return this.getSettings(filterInput, {
					allowSearchFilter: true
				});
			case SimpleFilterInputType.SingleSelectWithSearch:
				return this.getSettings(filterInput, {
					singleSelection: true,
					allowSearchFilter: true
				});
			default:
				return this.getSettings(filterInput);
		}
	}

	private setInputLabel(filterInput: SimpleFilterInput) {
		const btn = this.getMultiSelectButton(filterInput);
		btn.innerHTML = '<div>' + filterInput.label + '</div>';
		btn.classList.add('ui-unselectable-text');
	}

	private getSettings(filterInput: SimpleFilterInput, extraSettings?: Object) {
		const sharedSettings = {
			idField: 'id',
			textField: 'name',
			searchPlaceholderText: this.translationService.instant('Search'),
			enableCheckAll: false,
			noDataAvailablePlaceholderText: this.translationService.instant('NoDataWasFound'),
			noFilteredDataAvailablePlaceholderText: this.translationService.instant('NoDataWasFound'),
			placeHolder: filterInput.label,
			disabled: !filterInput.items.length,
			maxHeight: 190,
			closeDropDownOnSelection: true
		}

		this.refreshClass(filterInput);

		if (extraSettings) {
			return Object.assign(sharedSettings, extraSettings);
		} else {
			return sharedSettings;
		}
	}

	private getClass(filterInput: SimpleFilterInput) {
		return filterInput.selectedItems.length ? 'sfs-multiselect-active' : 'sfs-multiselect';
	}

	handleMultiSelectChange(filterInput: SimpleFilterInput) {
		this.refreshClass(filterInput);
		this.onFilter.emit(filterInput);
	}

	handleMultiSelectOpen(filterInput: SimpleFilterInput) {
		const btn = this.getMultiSelectButton(filterInput);
		if (!btn.classList.contains('opened')) {
			btn.classList.add('opened');
		}

		const element = document.getElementById(`${filterInput.id}${this.randomNumberString}`);
		const dropdownList = element.querySelector('.dropdown-list') as HTMLElement;
		dropdownList.classList.add('multiselect-dropdown-list');
		if (filterInput.type === SimpleFilterInputType.MultiSelectWithSearch) {
			const searchField = dropdownList.querySelector('.item1') as HTMLElement;
			searchField.classList.add('multiselect-search-field');
		}
	}

	handleMultiSelectClose(filterInput: SimpleFilterInput) {
		const btn = this.getMultiSelectButton(filterInput);
		setTimeout(() => {
			btn.classList.remove('opened');
		})
	}

	private refreshClass(filterInput: SimpleFilterInput) {
		const element = document.getElementById(`${filterInput.id}${this.randomNumberString}`);

		if (element) {
			element.classList.remove('sfs-multiselect');
			element.classList.remove('sfs-multiselect-active');
			element.classList.add(this.getClass(filterInput));

			const list = element.querySelector('.item2') as HTMLElement;

			for (let i = 0; i < list.children.length; i++) {
				const li = list.children[i];
				if (li.classList.contains('selected-item')) {
					li.classList.remove('selected-item');
				}

				const input = li.children[0] as HTMLInputElement;
				if (input.checked) {
					li.classList.add('selected-item');
				}
			}
		}
	}

	private getMultiSelectButton(filterInput: SimpleFilterInput) {
		const element = document.getElementById(`${filterInput.id}${this.randomNumberString}`);
		const btn = element.querySelector('.dropdown-btn') as HTMLElement;
		return btn;
	}


	// LISTMODAL

	handleListModalTriggerClick(filterInput: SimpleFilterListModalInput) {
		if (filterInput.items.length) {
			filterInput.opened = true;
			filterInput.triggerListModal(filterInput);
			this.onLstModalOpened.emit()
		}
	}


	// DATE SELECT

	handleDateSelectOpen(filterInput: SimpleFilterInput, bsDatePicker: any) {
		filterInput.opened = true;

		// Add clear button
		if (filterInput.dateValue || filterInput.dateRangeValue.length) {
			const clearButton = document.createElement('div');
			clearButton.title = this.translationService.instant('Clear');
			clearButton.innerHTML = this.translationService.instant('Clear')
			clearButton.classList.add('clear')
			clearButton.onclick = () => {
				this.clearFilter();
				bsDatePicker.hide();
			}

			const picker = document.querySelector('bs-datepicker-container');
			const pickerRange = document.querySelector('bs-daterangepicker-container');
			if (picker) {
				picker.classList.add('has-clear-button');
				picker.append(clearButton);
			}
			if (pickerRange) {
				pickerRange.classList.add('has-clear-button');
				pickerRange.append(clearButton);
			}
		}
	}

	handleDateSelectClose(filterInput: SimpleFilterInput) {
		filterInput.opened = false;
	}

	handleDateSelectChange(filterInput: SimpleFilterInput) {
		if (filterInput.opened && filterInput.dateValue) {
			filterInput.selectedItems = [
				new SimpleFilterInputItem(
					moment(filterInput.dateValue).format(DEFAULT_DATE_FORMAT),
					moment(filterInput.dateValue).format(FILTER_DISPLAY_DATE_FORMAT)
				)
			];
			this.onFilter.emit(filterInput);
		}
	}


	// DATE RANGE SELECT

	handleDateRangeSelectChange(filterInput: SimpleFilterInput) {
		if (filterInput.opened && filterInput.dateRangeValue.length) {
			filterInput.selectedItems = [
				new SimpleFilterInputItem(
					`${moment(filterInput.dateRangeValue[0]).format(DEFAULT_DATE_FORMAT)}_${moment(filterInput.dateRangeValue[1]).format(DEFAULT_DATE_FORMAT)}`,
					`${moment(filterInput.dateRangeValue[0]).format(FILTER_DISPLAY_DATE_FORMAT)} & ${moment(filterInput.dateRangeValue[1]).format(FILTER_DISPLAY_DATE_FORMAT)}`
				)
			];
			this.onFilter.emit(filterInput);
		}
	}


	// GENERAL

	hasFilterValues() {
		return this.filterInputs.some(filterInput => filterInput.selectedItems.length);
	}

	shouldDisplay(filterInput: SimpleFilterInput) {
		return (filterInput.type === this.inputTypes.DateSelect || filterInput.type === this.inputTypes.DateRangeSelect)
			|| filterInput.items.length;
	}

	clearFilter() {
		if (this.hasFilterValues()) {
			this.onFilterClear.emit();
		}
	}

	closeFilter() {
		this.onClose.emit();
	}
}
