import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Phone } from './phone';

@Injectable()
export class PhoneValidationHelper {
	phoneNumberUtil = PhoneNumberUtil.getInstance();
	phoneNumber: PhoneNumber;

	setUpNumber(phone: Phone) {
		phone.region = this.getRegionByCountryCode(phone.countryCode);
		this.phoneNumber = this.phoneNumberUtil.parse(phone.number, phone.region);
	}

	validatePhoneNumber(phone: Phone): boolean {
		this.setUpNumber(phone);
		const validNumber = this.phoneNumberUtil.isValidNumber(this.phoneNumber);
		if (validNumber) {
			return true;
		} else {
			return false;
		}
	}

	validatePhoneNumberForRegion(phone: Phone) {
		this.setUpNumber(phone);
		const validNumber = this.phoneNumberUtil.isValidNumberForRegion(this.phoneNumber, phone.region);
		if (validNumber) {
			return true;
		} else {
			return false;
		}
	}

	getPhonePattern(): RegExp {
		return this.phoneNumberUtil.VALID_PHONE_NUMBER_;
	}

	getFormatedPhoneNumberNational(phone: Phone): string {
		this.setUpNumber(phone);
		return this.phoneNumberUtil.format(this.phoneNumber, PhoneNumberFormat.NATIONAL);
	}

	getFormatedPhoneNumberOriginal(phone: Phone): string {
		this.setUpNumber(phone);
		return this.phoneNumberUtil.formatInOriginalFormat(this.phoneNumber, phone.region);
	}

	getRegionByCountryCode(countryCode): string {
		return this.phoneNumberUtil.getRegionCodeForCountryCode(countryCode);
	}

	phoneNumberValidator(countryCodePropertyName: string): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			let validNumber = false;
			let validNumberForRegion = false;
			const messageObject = {
				phone: false,
				phoneForRegion: false,
				notAPhoneNumber: false
			};


			const form = control.parent;
			try {
				if (form) {
					const countryCode = form.get(countryCodePropertyName);
					if (countryCode.value && control.value) {
						const phone = new Phone({
							countryCode: countryCode.value,
							number: control.value
						});

						validNumber = this.validatePhoneNumber(phone);

						if (validNumber) {
							const formattedNumber = this.getFormatedPhoneNumberNational(phone);
							if (formattedNumber !== phone.number) {
								control.setValue(formattedNumber);
							}

							return null;
						} else {
							validNumberForRegion = this.validatePhoneNumberForRegion(phone);

							if (!validNumberForRegion) {
								messageObject.phoneForRegion = true;
							} else {
								messageObject.phone = true;
							}
							return messageObject;
						}
					}
				}
			} catch (errorResponse) {
				messageObject.notAPhoneNumber = true;
				return messageObject;
			}
		};
	}
}
