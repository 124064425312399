<div class="sfs-wrapper">
	<div class="sfs-input-wrapper apx-scrollbar" scrollShadow
		[ngClass]="{
			'overflow': filterInputs.length > 7
		}">
			<ng-container *ngFor="let filterInput of filterInputs"
				[ngTemplateOutlet]="filterInputTemplate"
				[ngTemplateOutletContext]="{ filterInput: filterInput }">
			</ng-container>
	</div>
</div>

<ng-template #filterInputTemplate let-filterInput="filterInput">
	<div class="sfs-input" *ngIf="shouldDisplay(filterInput)"
		title="{{ ('FilterOn' | translate) + ' ' + filterInput.label.toLowerCase() }}">

			<ng-container *ngIf="filterInput.type !== inputTypes.ListModal && filterInput.type !== inputTypes.DateSelect && filterInput.type !== inputTypes.DateRangeSelect">
				<ng-multiselect-dropdown
					id="{{ filterInput.id + randomNumberString }}"
					[settings]="getMultiSelectSettings(filterInput)"
					[data]="filterInput.items"
					[(ngModel)]="filterInput.selectedItems"
					(onSelect)="handleMultiSelectChange(filterInput)"
					(onDeSelect)="handleMultiSelectChange(filterInput)"
					(onSelectAll)="handleMultiSelectChange(filterInput)"
					(onDeSelectAll)="handleMultiSelectChange(filterInput)"
					(onDropDownClose)="handleMultiSelectClose(filterInput)"
					(click)="handleMultiSelectOpen(filterInput)">
				</ng-multiselect-dropdown>
			</ng-container>

			<ng-container *ngIf="filterInput.type === inputTypes.ListModal">
				<div class="sfs-list-modal-trigger"
					[ngClass]="{
						'opened': filterInput.opened,
						'has-values': filterInput.selectedItems.length,
						'disabled': !filterInput.items.length
					}"
					(click)="handleListModalTriggerClick($any(filterInput))">
						{{ filterInput.label }}
				</div>
			</ng-container>

			<ng-container *ngIf="filterInput.type === inputTypes.DateSelect">
				<input class="form-control" #dateselect="bsDatepicker"
					type="text"
					id="{{ filterInput.id + randomNumberString }}"
					[bsConfig]="{
						customTodayClass: 'today',
						selectWeek: true,
						showWeekNumbers: false,
						selectFromOtherMonth: true,
						containerClass: '',
						adaptivePosition: true
					}"
					[(ngModel)]="filterInput.dateValue"
					(onShown)="handleDateSelectOpen(filterInput, dateselect)"
					(onHidden)="handleDateSelectClose(filterInput)"
					(ngModelChange)="handleDateSelectChange(filterInput)"
					placeholder="{{ filterInput.label }}"
					readonly
					bsDatepicker />
				<div class="sfs-date-select"
					[ngClass]="{
						'opened': filterInput.opened,
						'has-values': filterInput.selectedItems.length
					}"
					(click)="dateselect.toggle()">
						{{ filterInput.label }}
				</div>
			</ng-container>

			<ng-container *ngIf="filterInput.type === inputTypes.DateRangeSelect">
				<input class="form-control" #daterangeselect="bsDaterangepicker"
					type="text"
					id="{{ filterInput.id + randomNumberString }}"
					[bsConfig]="{
						customTodayClass: 'today',
						selectWeek: true,
						showWeekNumbers: false,
						selectFromOtherMonth: true,
						containerClass: '',
						adaptivePosition: true,
						displayMonths : 1
					}"
					[(ngModel)]="filterInput.dateRangeValue"
					(onShown)="handleDateSelectOpen(filterInput, daterangeselect)"
					(onHidden)="handleDateSelectClose(filterInput)"
					(ngModelChange)="handleDateRangeSelectChange(filterInput)"
					placeholder="{{ filterInput.label }}"
					readonly
					bsDaterangepicker />
				<div class="sfs-date-select"
					[ngClass]="{
						'opened': filterInput.opened,
						'has-values': filterInput.selectedItems.length
					}"
					(click)="daterangeselect.toggle()">
						{{ filterInput.label }}
				</div>
			</ng-container>
	</div>
</ng-template>
