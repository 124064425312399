import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleChangeBulkTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/bulk/simple-change-bulk-tab.directive';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Facility } from 'app-inspection/facility/facility';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'set-accessibility-tab',
	templateUrl: './set-accessibility-tab.component.html'
})
export class SetAccessibilityTabComponent extends SimpleChangeBulkTabDirective<Facility> implements OnInit {

	selectableAccessibilityItems: FormSelectItem[] = [];

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableAccessibilityItems();

		this.form = this.formBuilder.group({
			[this.propertyStrings.accessible]: [this.changeModel.accessible,
			Validators.required
			]
		});
		super.ngOnInit();
	}

	private setSelectableAccessibilityItems() {
		this.selectableAccessibilityItems = [
			new FormSelectItem(true, this.translationService.instant(StringUtils.YES)),
			new FormSelectItem(false, this.translationService.instant(StringUtils.NO)),
		];
	}
}
