import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { PhoneValidationHelper } from 'app-core/shared-core/phone-validation/phone-validation-helper';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { RegexUtils } from 'app-core/shared-core/tools/regex-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { User } from 'app-core/user/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'change-details-tab',
	templateUrl: './change-details-tab.component.html',
	styleUrls: ['./change-details-tab.component.less']
})
export class ChangeDetailsTabComponent extends SimpleChangeTabDirective<User> implements OnInit {

	selectableCountryCodeItems: FormSelectItem[] = [];
	selectableCountryItems: FormSelectItem[] = [];
	selectableTimeZoneItems: FormSelectItem[] = [];
	selectableCultureItems: FormSelectItem[] = [];
	selectableRoleItems: FormSelectItem[] = [];
	selectableStatusItems: FormSelectItem[] = [];

	@Input() canChangeDetails: boolean;
	@Input() canChangeAuthorization: boolean;
	@Input() documents: MediaWidgetItem[] = [];
	@Input() isInvite: boolean;

	@Output() onMediaChange = new EventEmitter<MediaWidgetItem[]>();

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder,
		private phoneValidationHelper: PhoneValidationHelper) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableCountryCodeItems();
		this.setSelectableCountryItems();
		this.setSelectableTimezoneItems();
		this.setSelectableCultureItems();
		this.setSelectableRoleItems();
		this.setSelectableStatusItems();

		if (this.isEdit) {
			this.form = this.formBuilder.group({
				[this.propertyStrings.firstname]: [this.changeModel.firstname,
				[Validators.required, Validators.maxLength(25)]
				],
				[this.propertyStrings.lastname]: [this.changeModel.lastname,
				[Validators.required, Validators.maxLength(25)]
				],
				[this.propertyStrings.email]: [this.changeModel.email,
				[Validators.required, this.emailValidator]
				],
				[this.propertyStrings.contactCountryCode]: [this.changeModel.contactCountryCode,
				this.countryCodeTriggerPhoneValidator],
				[this.propertyStrings.contactPhoneNumber]: [this.changeModel.contactPhoneNumber,
				this.phoneValidationHelper.phoneNumberValidator(this.propertyStrings.contactCountryCode)
				],
				[this.propertyStrings.country]: [this.changeModel.country,
				Validators.required
				],
				[this.propertyStrings.timeZone]: [this.changeModel.timeZone,
				Validators.required
				],
				[this.propertyStrings.culture]: [this.changeModel.culture,
				Validators.required
				],
				[this.propertyStrings.rolesTemp]: [this.changeModel.rolesTemp,
				Validators.required
				],
				[this.propertyStrings.statusTemp]: [this.changeModel.statusTemp,
				Validators.required
				],
				[this.propertyStrings.certificates]: [this.changeModel.certificates,
				Validators.maxLength(50)
				]
			}, { validators: this.countryCodePhoneValidator });
		} else {
			if (!this.isInvite) {
				this.form = this.formBuilder.group({
					[this.propertyStrings.firstname]: [this.changeModel.firstname,
					[Validators.required, Validators.maxLength(25)]
					],
					[this.propertyStrings.lastname]: [this.changeModel.lastname,
					[Validators.required, Validators.maxLength(25)]
					],
					[this.propertyStrings.email]: [this.changeModel.email,
					[Validators.required, this.emailValidator]
					],
					[this.propertyStrings.rolesTemp]: [this.changeModel.rolesTemp,
					Validators.required
					],
					[this.propertyStrings.password]: [this.changeModel.password,
					[
						Validators.required,
						this.passwordDigitValidator,
						this.passwordLowerValidator,
						this.passwordUpperValidator,
						this.passwordSpecialValidator,
						this.passwordLengthValidator
					]
					],
					[this.propertyStrings.confirmPassword]: [this.changeModel.confirmPassword,
					Validators.required]
				}, { validators: this.passwordMatchValidator });
			} else {
				this.form = this.formBuilder.group({
					[this.propertyStrings.email]: [this.changeModel.email,
					[Validators.required, this.emailValidator]
					],
					[this.propertyStrings.rolesTemp]: [this.changeModel.rolesTemp,
					Validators.required
					]
				});
			}
		}
		super.ngOnInit();
	}

	private emailValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.EMAIL).test(control.value)) {
			return { email: true };
		} else {
			return null;
		}
	}

	private countryCodePhoneValidator(form: FormGroup): ValidationErrors {
		const countryCode = form.get('contactCountryCode');
		const phoneNumber = form.get('contactPhoneNumber');

		if (countryCode.value && !phoneNumber.value) {
			phoneNumber.setErrors({ required: true });
			phoneNumber.markAsTouched();
		} else if (phoneNumber.value && !countryCode.value) {
			countryCode.setErrors({ required: true });
			countryCode.markAsTouched();
		} else if (!countryCode.value && !phoneNumber.value) {
			countryCode.setErrors(null);
			phoneNumber.setErrors(null);
		}
		return null;
	}

	private countryCodeTriggerPhoneValidator(control: AbstractControl) {
		const form = control.parent;
		if (form && control.value) {
			const phone = form.get('contactPhoneNumber');
			phone.updateValueAndValidity();
		}
	}

	private passwordMatchValidator(form: FormGroup): ValidationErrors {
		const newPassword = form.get('password');
		const confirmPassword = form.get('confirmPassword');

		if (newPassword.value && confirmPassword.value && newPassword.value !== confirmPassword.value) {
			confirmPassword.setErrors({ passwordsDoNotMatch: true });
		}

		if (newPassword.value && confirmPassword.value && newPassword.value === confirmPassword.value) {
			confirmPassword.setErrors(null);
		}
		return null;
	}

	private passwordDigitValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_DIGIT).test(control.value)) {
			return { passwordRequiresDigit: true };
		} else {
			return null;
		}
	}

	private passwordLowerValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_LOWER).test(control.value)) {
			return { passwordRequiresLower: true };
		} else {
			return null;
		}
	}

	private passwordUpperValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_UPPER).test(control.value)) {
			return { passwordRequiresUpper: true };
		} else {
			return null;
		}
	}

	private passwordSpecialValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_SPECIAL).test(control.value)) {
			return { passwordRequiresNonAlphanumeric: true };
		} else {
			return null;
		}
	}

	private passwordLengthValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_LENGTH).test(control.value)) {
			return { passwordRequiresLengthSixOrMore: true };
		} else {
			return null;
		}
	}

	private setSelectableCountryCodeItems() {
		this.selectableCountryCodeItems = this.changeModel.selectableCountryCodes.map(countryCode => new FormSelectItem(countryCode.id, `+${countryCode.id}`));
	}

	private setSelectableCountryItems() {
		this.selectableCountryItems = this.changeModel.selectableCountries.map(country => new FormSelectItem(country.id, country.text));
	}

	private setSelectableTimezoneItems() {
		this.selectableTimeZoneItems = this.changeModel.selectableTimeZones.map(timeZone => new FormSelectItem(timeZone.id, timeZone.text));
	}

	private setSelectableCultureItems() {
		this.selectableCultureItems = this.changeModel.selectableCultures.map(culture => new FormSelectItem(culture.id, culture.text));
	}

	private setSelectableRoleItems() {
		this.selectableRoleItems = this.changeModel.selectableRoles.map(role => new FormSelectItem(role.id, this.translationService.instant(role.id)));
	}

	private setSelectableStatusItems() {
		this.selectableStatusItems = [
			new FormSelectItem(StringUtils.ACTIVE, this.translationService.instant(StringUtils.ACTIVE)),
			new FormSelectItem(StringUtils.INACTIVE, this.translationService.instant(StringUtils.INACTIVE))
		];
	}

	handleMediaCollectionChange(collection: MediaWidgetItem[]) {
		this.onMediaChange.emit(collection);
	}
}
