import { NgModule } from '@angular/core';
import { MediaModule } from 'app-core/media/media-module';
import { SharedModule } from 'app-core/shared-core/shared.module';
import { CreateEditFacilityComponent } from './create-edit-facility/create-edit-facility.component';
import { ChangeDetailsTabComponent } from './create-edit-facility/tabs/change-details/change-details-tab.component';
import { HandleEntitiesTabComponent } from './create-edit-facility/tabs/handle-entities/handle-entities-tab.component';
import { ViewDetailsTabComponent } from './create-edit-facility/tabs/view-details/view-details-tab.component';
import { DeleteFacilitiesComponent } from './delete-facilities/delete-facilities.component';
import { FacilityRoutingModule } from './facility-routing.module';
import { ListFacilityComponent } from './list-facility/list-facility.component';
import { SetAccessibilityFacilityComponent } from './list-facility/set-accessibility/set-accessibility-facility.component';
import { SetAccessibilityTabComponent } from './list-facility/set-accessibility/tabs/set-accessibility-tab.component';
import { SetStatusFacilityComponent } from './list-facility/set-status/set-status-facility.component';
import { SetStatusTabComponent } from './list-facility/set-status/tabs/set-status-tab.component';
import { UpdateAssignmentTemplateWithEntitiesComponent } from './update-assignment-templates-with-entities/update-assignment-templates-with-entities.component';

@NgModule({
	imports: [
		SharedModule,
		FacilityRoutingModule,
		MediaModule
	],
	declarations: [
		ListFacilityComponent,
		CreateEditFacilityComponent,
		ChangeDetailsTabComponent,
		HandleEntitiesTabComponent,
		DeleteFacilitiesComponent,
		SetStatusFacilityComponent,
		SetStatusTabComponent,
		SetAccessibilityFacilityComponent,
		SetAccessibilityTabComponent,
		ViewDetailsTabComponent,
		UpdateAssignmentTemplateWithEntitiesComponent
	],
	exports: [
		HandleEntitiesTabComponent
	]
})
export class FacilityModule { }
