<div class="modal-wrapper" [ngClass]="{'loadmask': submitting || isRefreshingData}">
	<div class="header">
		<div class="header-icon"></div>
	</div>
	<div class="modal-body apx-scrollbar">
		<div class="additional-info-row" *ngFor="let additionalInfoRow of additionalInfo">
			<div class="sub-title">
				{{ additionalInfoRow.title | translate }}
			</div>
			<div class="description"
				[innerHTML]="(additionalInfoRow.description | translate).formatBold().formatItalic()">
			</div>

			<div class="btn-group action-container" *ngIf="shouldDisplayTriggerButton && additionalInfoRow.shouldHaveCheckboxes">
				<div class="refresh-icon" *ngIf="reasonTemplateTypeHasTemplatesExists"
					title="{{ 'RefreshList' | translate }}"
					[attr.disabled]="isRefreshingData"
					[ngClass]="{'select-all-hidden': additionalInfoRow.hideSelectAll}"
					(click)="refreshModalData()">
				</div>
				<div class="dropdown-select-all" *ngIf="reasonTemplateTypeHasTemplatesExists && !additionalInfoRow.hideSelectAll">
					<div class="dropdown-select-all-text">
						{{ 'SelectForAll' | translate }}
					</div>
					<ng-select
						[items]="dropDownItems"
						[clearable]="false"
						[searchable]="false"
						[bindValue]="'id'"
						[bindLabel]="'name'"
						(change)="setDropdownValue($event.id, additionalInfoRow)"
						[(ngModel)]="facilityOrEntities">
					</ng-select>
				</div>
				<div class="select-all-action" *ngIf="!additionalInfoRow.hideSelectAll">
					<div class="select-all-text" *ngIf="selectedData.length === 0">
						{{ 'Select all' | translate }}&nbsp;
					</div>
					<div class="select-all-number" *ngIf="selectedData.length >= 1">
						{{ selectedData.length | localeString }}
					</div>
					<div class="checkbox"
						(click)="toggleAll()">
						<div class="checkbox-wrapper">
							<input
								[id]="'global-checkbox'"
								[checked]="isAllChecked()"
								type="checkbox"/>
							<span class="checkmark"></span>
						</div>
					</div>
				</div>
			</div>
			<additional-info-handler #additionalInfoHandler
				[additionalInfoRow]="additionalInfoRow"
				[shouldDisplayTriggerButton]="shouldDisplayTriggerButton"
				(onSelection)="setSelectedData($event)"
				(onButtonEnabled)="toggleButtonEnabled($event)"
				(onHideSelectAll)="changeTitleAndDescription(additionalInfoRow)">
			</additional-info-handler>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button"
			title="{{ 'Close' | translate }}"
			class="btn btn-transparent u-uppercase"
			(click)="close()">{{ "Close" | translate }}
		</button>
		<button type="button" *ngIf="shouldDisplayTriggerButton"
			title="{{ triggerButtonText }}"
			class="btn btn-success u-uppercase"
			[attr.disabled]="isButtonEnabled ? null : true"
			(click)="triggerUpdate()">{{ triggerButtonText }}
		</button>
	</div>
	<dots-loader *ngIf="submitting || isRefreshingData"></dots-loader>
</div>
