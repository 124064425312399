import { Component } from '@angular/core';
import { SimpleReadModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-read-modal.directive';
import { NotificationMessage } from '../notification-message';

@Component({
	templateUrl: './notification-message-modal.component.html'
})
export class NotificationMessageModalComponent extends SimpleReadModalDirective<NotificationMessage> {
	notificationMessage: NotificationMessage;
}
