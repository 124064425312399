import { HostedHttpClientServiceMiddleware } from 'app-core/shared-core/hosted-httpclient-middleware';
import { HostedHttpRequest } from 'app-core/shared-core/hosted-http-request';
import { TemplateType } from './template-type';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';

export class TemplateTypeMiddleware implements HostedHttpClientServiceMiddleware {
	async handleHttpResponse(request: HostedHttpRequest, body: any, retry: (request?: HostedHttpRequest) => Promise<Object>): Promise<Object> {
		if (request.endpoint.endsWith('affectedentities')) {
			return retry(
				request.merge({
					endpoint: `${StringUtils.TEMPLATE_TYPE}/${(request.data as TemplateType).id}`
				})
			);
		} else {
			return body;
		}
	}
	async handleHttpError(body: Object, request: HostedHttpRequest, retry: (request?: HostedHttpRequest) => Promise<Object>): Promise<Object> {
		return body;
	}
}
