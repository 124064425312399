import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ACCEPTED_MEDIA_TYPES, FileUtils } from 'app-core/shared-core/tools/file-utils';
import { MediaUtils } from 'app-core/shared-core/tools/media-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';

@Component({
	selector: 'simple-change-image',
	templateUrl: './simple-change-image.component.html',
	styleUrls: ['./simple-change-image.component.less']
})
export class SimpleChangeImageComponent implements OnInit {

	initialImageUrl: string;

	media = MediaUtils;
	acceptedMediaTypes = ACCEPTED_MEDIA_TYPES.IMAGE;

	uploadedFile: File;
	imageIsLoaded: boolean = false;

	// We can allow larger files than backend here due to that we always crop before sending.
	// The user will be warned and permitted when the resulting cropped image will be over the maxlimit.
	maxImageSize: number = 60;
	maxImageSizeBytes = FileUtils.mbToBytes(this.maxImageSize);
	maxImageSizeOnServer: number = 10;

	croppedImage: Blob;
	format: string;
	scale = 1;
	transform: ImageTransform = {};
	croppedImageSize: number = 0;
	croppedImageAsUrl: string;
	shouldUseBackgroundColor: boolean = false;
	backgroundColor: string = 'rgba(255, 255, 255, 1)';
	isSlidingColorPicker = false;

	exceededMaxLimit: boolean = false;

	validationErrors = [];

	@Input() imageUrl: string;
	@Input() rounded: boolean;

	@Output() onClear = new EventEmitter();

	@ViewChild('dropHelper', { read: ElementRef }) dropHelperEl: ElementRef;
	@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

	get dropHelper(): HTMLDivElement {
		return this.dropHelperEl.nativeElement;
	}

	constructor(private translationService: TranslationService) { }

	ngOnInit() {
		this.initialImageUrl = this.imageUrl;
	}

	handleFileChange(event: any) {
		const inputElement = (<HTMLInputElement>event.target);
		const files = inputElement.files;
		this.uploadFile(files[0]);
		inputElement.value = '';
	}

	handleDragOver(event: DragEvent) {
		event.preventDefault();
		this.dropHelper.classList.add('is-visible');
	}

	handleDragLeave(event: DragEvent) {
		event.preventDefault();

		// We need some calculation in order to only do stuff when we leave the actual dropzone.
		const rect = document.getElementById('existingImage').getBoundingClientRect();
		if (event.clientY < rect.top
			|| event.clientY >= rect.bottom
			|| event.clientX < rect.left
			|| event.clientX >= rect.right) {
			this.dropHelper.classList.remove('is-visible');
		}
	}

	handleFileDrop(event: DragEvent) {
		event.preventDefault();
		this.dropHelper.classList.remove('is-visible');
		this.uploadFile(event.dataTransfer.files[0]);
	}

	uploadFile(file: File) {
		this.validationErrors = [];

		if (!FileUtils.isImage(file)) {
			this.validationErrors.push(this.translationService.instant('FileValidationNotValidImageSingle'));
		}

		if (!FileUtils.isAllowedFileSize(file, this.maxImageSizeBytes)) {
			if (!this.validationErrors.length) {
				const fileSizeMb = Math.round(FileUtils.bytesToMb(this.maxImageSizeBytes));
				this.validationErrors.push(this.translationService.instant('FileValidationExceedsTheLimitSingle', { 0: file.name, 1: fileSizeMb, 2: 'MB' }));
			}
		}

		if (!this.validationErrors.length) {
			this.uploadedFile = file;
			this.setFormat();
		}
	}

	reset() {
		this.uploadedFile = null;
		this.croppedImage = null;
		this.imageIsLoaded = false;
		this.scale = 1;
		this.transform = {};
		this.croppedImageSize = 0;
	}

	removeExistingImage() {
		this.validationErrors = [];
		this.imageUrl = null;
		this.onClear.emit();
	}

	// Cropper

	setFormat() {
		this.format = 'png';
	}

	setCroppedImageUrl() {
		if (this.croppedImage) {
			this.croppedImageAsUrl = URL.createObjectURL(this.croppedImage);
		}
	}

	handleImageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.blob;
		this.setCroppedImageUrl();
		this.croppedImageSize = this.getCroppedImageSizeAsMb();
		this.exceededMaxLimit = this.croppedImageSize > this.maxImageSizeOnServer;
	}

	getCroppedImageSizeAsMb() {
		return (Math.ceil(FileUtils.bytesToMb(this.croppedImage.size + Number.EPSILON) * 100)) / 100;
	}

	toggleShouldUseBackgroundColor() {
		this.shouldUseBackgroundColor = !this.shouldUseBackgroundColor;
		this.shouldUseBackgroundColor ? this.handleColorChange('rgba(255, 255, 255, 1)') : this.handleColorChange('rgba(255, 255, 255, 0)');

	}

	handleColorChange(colorValue: string) {
		if (!this.isSlidingColorPicker) {
			this.backgroundColor = colorValue;

			setTimeout(() => {
				this.imageCropper.crop();
			})
		}
	}

	handleSliderDragStart() {
		this.isSlidingColorPicker = true;
	}

	handleSliderDragEnd(event: { slider: string, color: string }) {
		this.isSlidingColorPicker = false;
		this.handleColorChange(event.color);
	}

	handleImageLoaded() {
		this.imageIsLoaded = true;
		this.exceededMaxLimit = true;
	}

	zoomIn() {
		this.scale += .1;
		this.transform = {
			...this.transform,
			scale: this.scale
		};
	}

	zoomOut() {
		if (this.scale > 0.2) {
			this.scale -= .1;
			this.transform = {
				...this.transform,
				scale: this.scale
			};
		}
	}
}
