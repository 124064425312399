export class SimpleTableEmptyState {
	identifier: string;
	icon: string;

	constructor(
		identifier: string,
		icon: string) {
		this.identifier = identifier;
		this.icon = icon;
	}
}
