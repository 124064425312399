<simple-change-bulk-tab
	[form]="form"
	[selectedIdsCount]="selectedIdsCount">
		<div class="change-bulk-section">
			<div class="change-bulk-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-select
				[label]="'Categories' | translate"
				[name]="propertyStrings.categoryIds"
				[form]="form"
				[items]="selectableCategoryItems"
				[multiple]="true"
				[searchable]="true"
				[addable]="true"
				[useStrictSearch]="true"
				[serverErrors]="serverErrors"
				(onAddNewItem)="handleAddNewItemInSelect($event)">
			</simple-form-select>
		</div>
</simple-change-bulk-tab>
