import { ModalOptions } from 'ngx-bootstrap/modal';

export class ConfigUtils {

	public static MODAL_CONFIG_SMALL = {
		keyboard: false,
		ignoreBackdropClick: true,
		class: 'small',
		animated: false
	} as ModalOptions;

	public static MODAL_CONFIG_MEDIUM = {
		keyboard: false,
		ignoreBackdropClick: true,
		class: 'medium',
		animated: false
	} as ModalOptions;

	public static MODAL_CONFIG_LARGE = {
		keyboard: false,
		ignoreBackdropClick: true,
		class: 'large',
		animated: false
	} as ModalOptions;

	public static MODAL_CONFIG_LARGE_WITH_OUTSIDE_CLICK = {
		keyboard: false,
		ignoreBackdropClick: false,
		class: 'large',
		animated: false
	} as ModalOptions;

	public static MODAL_CONFIG_M_LARGE = {
		keyboard: false,
		ignoreBackdropClick: true,
		class: 'm-large',
		animated: false
	} as ModalOptions;

	public static MODAL_CONFIG_X_LARGE = {
		keyboard: false,
		ignoreBackdropClick: true,
		class: 'x-large',
		animated: false
	} as ModalOptions;

	public static MODAL_CONFIG_XX_LARGE = {
		keyboard: false,
		ignoreBackdropClick: true,
		class: 'xx-large',
		animated: false
	} as ModalOptions;
}
