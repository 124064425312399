<simple-change-bulk-tab
	[form]="form"
	[affectableIdsCount]="affectableIdsCount"
	[selectedIdsCount]="selectedIdsCount">
		<div class="change-bulk-section">
			<div class="change-bulk-section-header">
				{{ 'Authorization' | translate }}
			</div>
			<simple-form-select
				[label]="'Status' | translate"
				[name]="propertyStrings.status"
				[form]="form"
				[items]="selectableStatusItems"
				[redBackground]="changeModel.status === stringUtils.INACTIVE"
				[serverErrors]="serverErrors">
			</simple-form-select>
		</div>
</simple-change-bulk-tab>
