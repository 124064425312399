import { Component, Input } from '@angular/core';
import { SimpleItemComponent } from '../simple-item.component';

@Component({
	selector: 'simple-item-extended',
	templateUrl: './simple-item-extended.component.html',
	styleUrls: ['./simple-item-extended.component.less']
})
export class SimpleItemExtendedComponent<T extends { id: string, selected: boolean, errorMessages: string[], url: string }> extends SimpleItemComponent<T> {
	@Input() isValid: boolean;
}
