import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import '@github/markdown-toolbar-element';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PipesModule } from '../pipes/pipes.module';
import { MarkdownEditorComponent } from './markdown-editor.component';

@NgModule({
	imports: [
		TranslateModule,
		FormsModule,
		PipesModule,
		CommonModule,
		PopoverModule
	],
	declarations: [
		MarkdownEditorComponent
	],
	exports: [
		MarkdownEditorComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MarkdownEditorModule { }
