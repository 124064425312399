<div class="st-empty-state apx-scrollbar"
	[ngClass]="{
		'in-modal': isInModal,
		'has-search-or-filter': hasActiveSearchOrFilter,
		'filters-opened': filterOpened
	}">
		<div class="header-section">
			<div class="icon {{ hasActiveSearchOrFilter ? 'no-results' : emptyState.icon }}">
			</div>
			<div class="header-text">
				{{ (hasActiveSearchOrFilter ? 'NoResultHeader' : 'NoDataHeader') | translate: { identifier: (emptyState.identifier | translate) | lowercase } }}!
			</div>
			<div class="info-text">
				{{ (hasActiveSearchOrFilter ? 'NoResultsMatchingSearchOrFilter' : 'OpenUserSupportOrContactNearestSupport') | translate }}
			</div>
		</div>
		<div class="action-section">
			<ng-container *ngIf="!hideListActions && !hasActiveSearchOrFilter">
				<div class="action" *ngFor="let action of listActions"
					[title]="action.name"
					(click)="handleListActionClick(action)">
						<div class="icon {{ action.iconName }}">
						</div>
						<div class="text">
							{{ action.name }}
						</div>
				</div>
			</ng-container>
		</div>
</div>
