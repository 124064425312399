import { NgModule } from '@angular/core';
import { MediaModule } from 'app-core/media/media-module';
import { SharedModule } from '../shared-core/shared.module';
import { InvitationRoutingModule } from './invitation-routing.module';
import { InvitationComponent } from './invitation.component';

/**
 * The invitation module is a public module which means that everyone can access its routes.
 * The AuthGaurd will not authenticate a user that is trying to access any routes inside this module.
 */
@NgModule({
	imports: [
		SharedModule,
		InvitationRoutingModule,
		MediaModule
	],
	declarations: [
		InvitationComponent
	]
})
export class InvitationModule { }
