import { Pipe, PipeTransform } from '@angular/core';
import { LoggedInUser } from 'app-core/user/user';

@Pipe({
	name: 'isSuperAdmin'
})
export class IsSuperAdminPipe implements PipeTransform {
	transform(loggedInUser: LoggedInUser): any {
		return loggedInUser.isSuperAdmin();
	}
}
