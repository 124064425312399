import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { TemplateType } from 'app-inspection/template-type/template-type';
import { TemplateTypeService } from 'app-inspection/template-type/template-type.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ChangeTemplateTypeDescriptionTabComponent } from './tabs/change-template-type-description-tab.component';

@Component({
	templateUrl: './create-edit-template-type-description.component.html'
})
export class CreateEditTemplateTypeDescriptionComponent extends SimpleCreateEditModalDirective<TemplateType> implements OnInit {

	name: string = '';

	@ViewChild(ChangeTemplateTypeDescriptionTabComponent) changeTemplateTypeDescriptionTabComponent: ChangeTemplateTypeDescriptionTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private templateTypeService: TemplateTypeService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			this.initialModel = await this.templateTypeService.get(this.editModelId);
			this.pending = false;

			this.modifiedModel = new TemplateType(this.getUniqueVariant(this.initialModel));

			setTimeout(() => {
				this.triggerAllValidation();
			}, 0);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	changeDetailsIsValid() {
		return this.changeTemplateTypeDescriptionTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeTemplateTypeDescriptionTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = await this.templateTypeService.updateDescription(this.modifiedModel.id, this.modifiedModel.assignmentDescription, this.selectedOrganization.id);

			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeTemplateTypeDescriptionTabComponent.setServerErrors(this.serverErrors);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeTemplateTypeDescriptionTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: TemplateType) {
		return new TemplateType(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.assignmentDescription !== this.initialModel.assignmentDescription;
	}

	hasUnsavedChanges() {
		return this.modifiedModel && this.detailsHasChanged();
	}
}
