<simple-view-tab>
	<div class="view-section">
		<div class="image-wrapper">
			<div class="image" #parentDiv>
				<extensive-image
					[imageUrl]="thumbnailUrl"
					[blob]="blob ? blob : null"
					[imageStorageSize]="parentDiv | getImageStorageSize"
					[displayLoader]="true">
				</extensive-image>
			</div>
			<div class="details">
				<div class="primary"
					[title]="viewModel.name">
						{{ viewModel.name }}
				</div>
				<div class="secondary" *ngIf="viewModel.description"
					[title]="viewModel.description">
						{{ viewModel.description }}
				</div>
			</div>
		</div>
	</div>
	<div class="view-section">
		<div class="information">
			<div class="header-text">
				{{ 'General' | translate }}
			</div>
			<div class="icon-text-wrapper">
				<div class="icon bi ii-entities" [title]="'Name' | translate"></div>
				<div class="text" title="{{ 'Name' | translate }}: {{ viewModel.name }}">
					{{ viewModel.name }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.description">
				<div class="icon bi bi-file-text" [title]="'Description' | translate"></div>
				<div class="text" title="{{ 'Description' | translate }}: {{ viewModel.description }}">
					{{ viewModel.description }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.typeDesignation">
				<div class="icon bi bi-tag" [title]="'TypeDesignation' | translate"></div>
				<div class="text" title="{{ 'TypeDesignation' | translate }}: {{ viewModel.typeDesignation }}">
					{{ viewModel.typeDesignation }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.articleId">
				<div class="icon bi bi-newspaper" [title]="'EntityID' | translate"></div>
				<div class="text" title="{{ 'EntityID' | translate }}: {{ viewModel.articleId }}">
					{{ viewModel.articleId }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.manufacturer">
				<div class="icon bi bi-hammer" [title]="'Manufacturer' | translate"></div>
				<div class="text" title="{{ 'Manufacturer' | translate }}: {{ viewModel.manufacturer }}">
					{{ viewModel.manufacturer }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.assembler">
				<div class="icon bi bi-wrench" [title]="'Assembler' | translate"></div>
				<div class="text" title="{{ 'Assembler' | translate }}: {{ viewModel.assembler }}">
					{{ viewModel.assembler }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.assemblyYear">
				<div class="icon bi bi-calendar2" [title]="'AssemblyYear' | translate"></div>
				<div class="text" title="{{ 'AssemblyYear' | translate }}: {{ viewModel.assemblyYear }}">
					{{ viewModel.assemblyYear }}
				</div>
			</div>
			<div class="icon-text-wrapper">
				<div class="icon bi bi-person-wheelchair" [title]="'Accessibility' | translate"></div>
				<div class="text" title="{{ 'Accessibility' | translate }}: {{ (viewModel.accessible ? 'Yes' : 'No') | translate }}">
					{{ (viewModel.accessible ? 'Yes' : 'No') | translate }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.facility">
				<div class="icon bi ii-facility" [title]="'Facility' | translate"></div>
				<div class="text" title="{{ 'Facility' | translate }}: {{ viewModel.facility.name }}">
					{{ viewModel.facility.name }}
				</div>
			</div>
			<div class="icon-text-wrapper">
				<div class="icon bi bi-power" [title]="'Status' | translate"></div>
				<div class="status">
					<simple-status-label
						[text]="viewModel.status ? 'Active' : 'Inactive'"
						[title]="viewModel.status ? 'Active' : 'Inactive'">
					</simple-status-label>
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.infoLink">
				<div class="icon bi bi-link-45deg" [title]="'InfoLink' | translate"></div>
				<a class="text"
					title="{{ viewModel.infoLink }}"
					target="_blank"
					href="{{ viewModel.infoLink }}">
					{{ viewModel.infoLink }}
				</a>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.externalId && (selectedOrganization.hasDfIntegration || selectedOrganization.hasInfraControlIntegration)">
				<div class="icon bi bi-database-fill" [title]="'ExternalId' | translate"></div>
				<div class="text" title="{{ 'ExternalId' | translate }}: {{ viewModel.externalId }}">
					{{ viewModel.externalId }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.extraInfo">
				<div class="icon bi bi-card-text" [title]="'ExtraInfo' | translate"></div>
				<div class="text" title="{{ ('ExtraInfo' | translate) }}: {{ viewModel.extraInfo }}">
					<div class="markdown-viewer-wrapper"
						(click)="openExtraInfo()">
						<markdown-viewer
							[readModelInput]="viewModel.extraInfo">
						</markdown-viewer>
						<div class="hover-mask">
							{{ 'ShowMore' | translate }}
						</div>
					</div>
				</div>
			</div>
			<div class="header-text with-margin-top" *ngIf="viewModel.latitude && viewModel.longitude">
				{{ 'LocationData' | translate }}
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.latitude && viewModel.longitude">
				<div class="icon bi bi-geo-alt-fill" title="{{ 'Latitude' | translate }}"></div>
				<div class="text" title="{{ viewModel.latitude }}">
					{{ viewModel.latitude }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.latitude && viewModel.longitude">
				<div class="icon bi bi-geo-alt-fill" title="{{ 'Longitude' | translate }}"></div>
				<div class="text" title="{{ viewModel.longitude }}">
					{{ viewModel.longitude }}
				</div>
			</div>
		</div>
		<div class="map-wrapper" [hidden]="!viewModel.latitude || !viewModel.longitude">
			<google-maps-component
				[latitude]="viewModel.latitude"
				[longitude]="viewModel.longitude"
				[name]="viewModel.name"
				[isStatic]="true">
			</google-maps-component>
		</div>
	</div>
	<div class="view-section">
		<div class="connections-wrapper">
			<simple-view-connections
				[facility]="viewModel.facility">
			</simple-view-connections>
			<div class="dummy-text" *ngIf="!viewModel.facility">
				{{ 'NoConnectionsYet' | translate }}
			</div>
		</div>
	</div>
</simple-view-tab>