// https://github.com/Apirix/tools/tree/master/script/TypescriptPropertyNameGetter

export class PropertyNameGetter {
	private static handler = {
		get: function (obj, property) {
			return property;
		}
	};

	static propertiesOf<T>(type: new (args?: any) => T): { [K in keyof T]: K } {
		const proxy = new Proxy({}, PropertyNameGetter.handler);
		return proxy as { [K in keyof T] };
	}
}
