<simple-handle-tab
	[headerTitleText]="'AddedGroups' | translate"
	[headerSubtitleText]="headerSubtitleText"
	[headerAddButtonText]="'AddGroups' | translate"
	[allAreSelected]="allAreSelected"
	[disableRemoveSelected]="!someAreSelected"
	[disableSelectAll]="!getItems().length"
	[selectedCount]="getSelectedCount()"
	[disableSearch]="!handleItems.length"
	(onAddButtonClick)="toggleListUserGroupModal()"
	(onRemoveSelected)="removeSelected()"
	(onSelectAll)="selectAll()"
	(onSearch)="handleSearch($event)">
		<ng-container slot="headerActions">
			<button *ngIf="!isMediumScreenSize()"
				class="btn btn-success" #setRulesPop="bs-popover"
				placement="bottom"
				containerClass="gr-menu-popover"
				[popover]="setRulesTemplate"
				[outsideClick]="true"
				[ngClass]="{
					'disabled': !someAreSelected
				}"
				[title]="'SetRulesOnSelected' | translate"
				(click)="initiateGlobalRules()">
					{{ 'SetRulesOnSelected' | translate }}
			</button>
			<button *ngIf="isMediumScreenSize()"
				class="btn btn-success bi-ui-checks-grid" #setRulesPop="bs-popover"
				placement="bottom"
				containerClass="gr-menu-popover"
				[popover]="setRulesTemplate"
				[outsideClick]="true"
				[ngClass]="{
					'disabled': !someAreSelected
				}"
				[title]="'SetRulesOnSelected' | translate"
				(click)="initiateGlobalRules()">
			</button>
			<ng-template #setRulesTemplate>
				<div class="gr-menu-item">
					<simple-rules
						[rules]="globalDummyGroup.rulesEditableInSchedule"
						[editable]="true"
						[shouldStopPropagationOnClick]="true"
						(onRuleClick)="handleRuleClickGlobal($event)">
					</simple-rules>
				</div>
			</ng-template>
		</ng-container>
		<ng-container slot="itemList">
			<cdk-virtual-scroll-viewport itemSize="{{ itemSize }}" minBufferPx="999" maxBufferPx="1000" class="apx-scrollbar" scrollShadow>
				<simple-item *cdkVirtualFor="let item of getItems(); let index = index; cdkVirtualForTrackBy: trackByFn"
					[item]="item"
					[noBorder]="index === handleItems.length - 1"
					[hideRemoveButton]="readonlyItems"
					[hideSelectButton]="readonlyItems"
					[url]="item.userGroup.url"
					[name]="item.userGroup.name"
					[height]="itemSize"
					(onRemove)="remove($event)"
					(onSelect)="select($event)">
						<ng-container slot="content">
							<div class="details">
								<div class="name" title="{{ item.userGroup.name }}" *ngIf="item.userGroup.name">
									{{ item.userGroup.name }}
								</div>
								<div class="description" title="{{ item.userGroup.users.length }} {{ ('Assignees' | translate) | lowercase }}">
									{{ item.userGroup.users.length }} {{ ('Assignees' | translate) | lowercase }}
									<view-members
										[members]="item.userGroup.users">
									</view-members>
								</div>
							</div>
							<div class="rules">
								<simple-rules
									[rules]="item.rulesEditableInSchedule"
									[editable]="true"
									(onRuleClick)="handleRuleClick(item, $event)">
								</simple-rules>
							</div>
						</ng-container>
						<ng-container slot="actions">
							<div class="can-be-assigned"
								[title]="'CanBeAssigned' | translate"
								(click)="toggleCanBeAssigned(item)">
									<div class="bi icon"
										[ngClass]="{
											'bi-file-plus': !item.canBeAssigned,
											'bi-file-plus-fill': item.canBeAssigned
										}">
									</div>
							</div>
						</ng-container>
				</simple-item>
				<simple-no-items *ngIf="!handleItems.length && !searchValue"
					[title]="'NoGroupsAdded' | translate">
				</simple-no-items>
				<simple-no-items *ngIf="!getItems().length && searchValue"
					[title]="'NoResultsMatchingSearch' | translate">
				</simple-no-items>
			</cdk-virtual-scroll-viewport>
		</ng-container>
</simple-handle-tab>
