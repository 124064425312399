<div class="st-container"
	[ngClass]="{
		'is-in-modal': isInModal
	}">

	<!-- FILTER -->
	<simple-filter
		[listActions]="listActions"
		[hideListActions]="hideListActions"
		[tableConfig]="tableConfig"
		[filterConfig]="filterConfig"
		[activeSearch]="activeSearch"
		[activeFilter]="activeFilter"
		[totalCount]="totalCount"
		[selectedCount]="selectedCount"
		[sortProperty]="sortProperty"
		[sortLabel]="sortLabel"
		[sortReversed]="sortReversed"
		[isInModal]="isInModal"
		[disableSearchFilterSort]="pending || (!dataSource?.rows$.value.length && !activeSearch && !activeFilter.length)"
		(onListActionClick)="listActionClick($event)"
		(onSearch)="search($event)"
		(onSort)="sort($event)"
		(onFilter)="filter($event)"
		(onSearchClear)="clearSearch()"
		(onFilterClear)="clearFilter()"
		(onSelectedClear)="clearSelected()"
		(onFilterOpened)="toggleFilterOpened($event)">
	</simple-filter>

	<!-- HEAD -->
	<simple-table-head
		[isInModal]="isInModal"
		[isCompactView]="isCompactView"
		[config]="tableConfig"
		[allSelected]="allSelected"
		[someSelected]="someSelected"
		[hasSelectableData]="hasSelectableData"
		(onActionClick)="headerActionClick($event)"
		(onCheckboxClick)="headerCheckboxClick()">
	</simple-table-head>

	<!-- BODY -->
	<simple-table-body
		[isInModal]="isInModal"
		[isCompactView]="isCompactView"
		[pending]="pending"
		[config]="tableConfig"
		[dataSource]="dataSource"
		[hasActiveSearchOrFilter]="!!activeSearch || !!activeFilter.length"
		[filterOpened]="filterOpened"
		(onPopoverOpen)="popoverOpened()"
		(onActionClick)="rowActionClick($event)"
		(onCheckboxClick)="rowCheckboxClick($event)"
		(onRowClick)="rowClick($event)"
		(onRowDoubleClick)="rowDoubleClick($event)">
	</simple-table-body>

	<!-- EMPTY STATE -->
	<simple-table-empty-state *ngIf="!dataSource?.rows$.value.length && !pending"
		[emptyState]="tableConfig.emptyState"
		[isInModal]="isInModal"
		[hasActiveSearchOrFilter]="!!activeSearch || !!activeFilter.length"
		[filterOpened]="filterOpened"
		[listActions]="listActions"
		[hideListActions]="hideListActions"
		(onListActionClick)="listActionClick($event)">
	</simple-table-empty-state>

	<dots-loader *ngIf="pending"></dots-loader>
</div>
