import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeyValuePair } from 'app-core/shared-core/filter';

@Injectable()
export class SimpleRetainService {

	private currentRetainEntries: Object = {};

	constructor(
		private router: Router) { }

	setParam(pair: KeyValuePair) {
		setTimeout(() => {
			this.router.navigate([], {
				queryParams: {
					[pair.key]: pair.value
				},
				queryParamsHandling: 'merge'
			});
		}, 0);
	}

	setRetainEntry(pair: KeyValuePair) {
		this.currentRetainEntries[pair.key] = pair.value;
	}

	setCurrentRetainEntries(retainEntries: Object) {
		Object.assign(this.currentRetainEntries, retainEntries);
	}

	getCurrentRetainEntries() {
		return this.currentRetainEntries;
	}

	clearCurrentRetainEntries() {
		this.currentRetainEntries = {};
	}

	clearCurrentRetainValues() {
		for (const key in this.currentRetainEntries) {
			if (this.currentRetainEntries.hasOwnProperty(key)) {
				this.currentRetainEntries[key] = null;
			}
		}
	}
}
