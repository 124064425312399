import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { TemplateType } from 'app-inspection/template-type/template-type';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'change-template-type-description-tab',
	templateUrl: './change-template-type-description-tab.component.html'
})
export class ChangeTemplateTypeDescriptionTabComponent extends SimpleChangeTabDirective<TemplateType> implements OnInit {

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		// Needed just to have a form.....
		this.form = this.formBuilder.group({
			[this.propertyStrings.name]: [this.changeModel.name]
		});
	}

	isExtraSmallScreenSize() {
		return Utils.isExtraSmallScreenSize();
	}

	handleChange(text: string) {
		this.changeModel.assignmentDescription = text;
	}
}
