import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Choice } from '../../choice';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { Router } from '@angular/router';

@Component({
	selector: 'change-details-tab',
	templateUrl: './change-details-tab.component.html'
})
export class ChangeDetailsTabComponent extends SimpleChangeTabDirective<Choice> implements OnInit {

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.form = this.formBuilder.group({
			[this.propertyStrings.value]: [this.changeModel.value,
			[Validators.required, Validators.maxLength(3)]
			],
			[this.propertyStrings.isError]: [this.changeModel.isError,
			this.rulesHelper
			],
			[this.propertyStrings.isDefault]: [this.changeModel.isDefault,
			this.rulesHelper
			],
			[this.propertyStrings.isUnspecified]: [this.changeModel.isUnspecified,
			this.rulesHelper
			],
			[this.propertyStrings.allowPriority]: [this.changeModel.allowPriority,
			this.rulesHelper
			]
		});
		super.ngOnInit();
	}

	private rulesHelper(control: AbstractControl) {
		if (control.parent) {
			const form = control.parent;
			const controlName = (Object.keys(form.controls).find(key => form.controls[key] === control));
			const isError = form.get('isError');
			const isDefault = form.get('isDefault');
			const isUnspecified = form.get('isUnspecified');
			const allowPriority = form.get('allowPriority');

			if (controlName === 'isError' && !control.value) {
				allowPriority.setValue(control.value);
			} else if (controlName === 'isDefault' && !control.value) {
				isUnspecified.setValue(control.value);
			} else if (controlName === 'isUnspecified' && control.value) {
				isDefault.setValue(control.value);
				isError.setValue(!control.value);
			} else if (controlName === 'allowPriority' && control.value) {
				isError.setValue(control.value);
			} else if (controlName === 'isError' && control.value) {
				isUnspecified.setValue(!control.value);
			}
		}
		return null;
	}

	handleColorChange(colorValue: string) {
		this.changeModel.color = colorValue;
	}
}
