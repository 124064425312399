import { ComponentRef } from '@angular/core';
import { ViewObjectsComponent } from '../view-objects/view-objects.component';
import { Utils } from './utils';

const IMPORTANT = 'important';

export const MAX_XS_WIDTH = 768;

export class DomUtils {

	/**
	 * Get element dimensions (width x height), with or without padding.
	 * @param element The target element
	 * @param withPadding Whether padding should be included or not
	 */
	public static getElementDimensions(element: HTMLElement, withPadding = true) {

		let elementHeight = element.clientHeight;  // height with padding
		let elementWidth = element.clientWidth;   // width with padding

		if (!withPadding) {
			const computedStyle = getComputedStyle(element);
			elementHeight -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
			elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
		}

		return {
			width: elementWidth,
			height: elementHeight
		};
	}

	public static setProperties(element: HTMLElement, properties: {}, isImportant = false) {
		for (const property in properties) {
			element.style.setProperty(property, properties[property], isImportant ? IMPORTANT : null);
		}
	}

	/**
	 * Check if a focus event occured on a provided target element or on one of its descendants.
	 * @param event A blur/focousout event.
	 * @param parentEl A parent element.
	 */
	public static blurEventContainsElement(event: FocusEvent, parentEl: HTMLElement) {
		let containsActive = false;

		if (event && event.relatedTarget) {
			const focusedEl = <HTMLElement>event.relatedTarget;

			// If the current focus target is the select itself OR some element inside.
			containsActive = DomUtils.elementContainsElement(parentEl, focusedEl);
		}

		return containsActive;
	}

	/**
	 * Check if one element is (===) or a descendant of a parent element (CONTAINS).
	 * @param parent A parent element.
	 * @param child A child element.
	 */
	public static elementContainsElement(parent: Element, child: Element) {
		if (parent && child) {
			return (parent === child) || parent.contains(child);
		}

		return false;
	}

	/**
	 * Focusing a sibling element relative to a specified target element.
	 * Takes into account whether the next or the previous sibling should be focused (whether the "shift" key is pressed or not).
	 *
	 * @param event Original keyboard event
	 * @param target A target element
	 */
	public static switchFocusToSibling(event: KeyboardEvent, target: HTMLElement) {
		if (event.shiftKey) {
			target.focus();
		} else {
			const children = target.children;

			if (children.length) {
				(children[children.length - 1] as HTMLElement).focus();
			} else {
				target.focus();
			}
		}

		// Clone the event
		const tabEvent = new KeyboardEvent('keydown', event);
		target.dispatchEvent(tabEvent);
	}

	/**
	 * Given an element, navigate up in the DOM tree.
	 * If the element is inside an element with a provided class, return that element.
	 * Otherwise return the window object.
	 *
	 * @param element A target element
	 * @param containerClass A CSS class a parent element
	 */
	public static getContainerOrWindow(element: HTMLElement, containerClass = 'modal-body') {
		let container: HTMLElement | Window = window;
		let parentElement = element.parentElement;

		// Navigate to the top-most parent in DOM.
		while (parentElement) {
			if (parentElement.classList.contains(containerClass)) {
				container = parentElement;
				break;
			}

			parentElement = parentElement.parentElement
		}

		return container;
	}

	public static getPopovermenu(): HTMLElement {
		return document.body.querySelector('.standard-menu-popover');
	}

	public static getObjectsDiv(componentRef: ComponentRef<ViewObjectsComponent>) {
		return componentRef.location.nativeElement.childNodes[0] as HTMLDivElement;
	}

	public static setObjectsDivBounds(popoverMenu: HTMLElement, objectsDiv: HTMLElement) {
		const popoverMenuBounds = popoverMenu.getBoundingClientRect();
		const objectDivBottom = (popoverMenuBounds.top + objectsDiv.offsetTop) + objectsDiv.clientHeight;

		const divBottomIsOutsideClientHeight = objectDivBottom > document.body.clientHeight;

		if (divBottomIsOutsideClientHeight) {
			const divAndHeightDifference = objectDivBottom - document.body.clientHeight;
			const newTop = objectsDiv.offsetTop - divAndHeightDifference - 4;

			objectsDiv.style.top = `${newTop}px`;

			if (popoverMenuBounds.bottom >= document.body.clientHeight) {
				objectsDiv.style.top = 'unset';
				objectsDiv.style.bottom = '0px';
			}
		}
	}

	public static showLargerImageOnHover(div: HTMLDivElement, rounded: boolean, imgSrc?: string) {
		if (div && !div.classList.contains('default-placeholder-style')) {
			div.id = 'imageThumbnailDiv'
			div.classList.remove('error-image-style');
			if (!imgSrc) {
				const backgroundImageUrl = div.style.backgroundImage.slice(4, -1).replace(/"/g, "");
				imgSrc = backgroundImageUrl;
			}
			div.style.backgroundImage = `url(${imgSrc})`;


			if (!Utils.isSmallScreenSize()) {
				const size = 100;
				const hoverImgSizeUrl = imgSrc.substring(0, imgSrc.lastIndexOf('\/') + 1).concat(size.toString());
				const image = new Image();
				image.src = hoverImgSizeUrl;

				div.style.cursor = 'pointer';

				function showLargerImage() {
					document.getElementById('thumbnailHoverDiv')?.remove();

					const thumbnailHoverDiv = document.createElement('div');
					thumbnailHoverDiv.id = 'thumbnailHoverDiv';
					thumbnailHoverDiv.style.backgroundImage = `url(${hoverImgSizeUrl})`;
					thumbnailHoverDiv.style.backgroundRepeat = 'no-repeat';
					thumbnailHoverDiv.style.backgroundSize = 'cover';
					thumbnailHoverDiv.style.backgroundPosition = 'center';
					thumbnailHoverDiv.style.width = `${size}px`;
					thumbnailHoverDiv.style.height = `${size}px`;
					thumbnailHoverDiv.style.position = 'absolute';
					thumbnailHoverDiv.style.borderRadius = rounded ? '50%' : '4px';
					thumbnailHoverDiv.style.boxShadow = '0 1px 2px rgba(0, 0, 0, .2)';
					thumbnailHoverDiv.style.outline = '1px solid #DDDDDD';
					thumbnailHoverDiv.style.zIndex = '10';
					thumbnailHoverDiv.style.pointerEvents = 'none';
					thumbnailHoverDiv.style.left = (div.getBoundingClientRect().left - (size / 2) + (div.clientWidth / 2)).toString().concat('px');
					thumbnailHoverDiv.style.top = (div.getBoundingClientRect().top - (size / 2) + (div.clientHeight / 2)).toString().concat('px');
					document.body.append(thumbnailHoverDiv);
				}

				div.removeAllListeners();

				// Hovering
				div.addEventListener('mouseenter', showLargerImage);

				// Stop hovering
				div.addEventListener('mouseleave', () => {
					const div = document.getElementById('thumbnailHoverDiv');
					if (div) {
						div.remove();
					}
				});

				div.addEventListener('wheel', () => {
					const div = document.getElementById('thumbnailHoverDiv');
					if (div) {
						div.remove();
					}
				});
			}
		}
	}

	public static getLatestOpenedModal() {
		const modalContainer = document.body.querySelectorAll('modal-container');
		const latestModal = modalContainer[modalContainer.length - 1];
		return latestModal;
	}

	public static hideLatestOpenedModal() {
		const latestModal = DomUtils.getLatestOpenedModal();
		latestModal.classList.remove('show');
	}
}
