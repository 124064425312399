<simple-handle-tab
	[headerTitleText]="'AddedAssignees' | translate"
	[headerSubtitleText]="headerSubtitleText"
	[headerAddButtonText]="'AddAssignees' | translate"
	[allAreSelected]="allAreSelected"
	[disableRemoveSelected]="!someAreSelected"
	[disableSelectAll]="!getItems().length"
	[selectedCount]="getSelectedCount()"
	[disableSearch]="!handleItems.length"
	(onAddButtonClick)="toggleListUserModal()"
	(onRemoveSelected)="removeSelected()"
	(onSelectAll)="selectAll()"
	(onSearch)="handleSearch($event)">
		<ng-container slot="headerActions">
			<button *ngIf="!isMediumScreenSize()"
				class="btn btn-success" #setRulesPop="bs-popover"
				placement="bottom"
				containerClass="gr-menu-popover"
				[popover]="setRulesTemplate"
				[outsideClick]="true"
				[ngClass]="{
					'disabled': !someAreSelected
				}"
				[title]="'SetRulesOnSelected' | translate"
				(click)="initiateGlobalRules()">
					{{ 'SetRulesOnSelected' | translate }}
			</button>
			<button *ngIf="isMediumScreenSize()"
				class="btn btn-success bi-ui-checks-grid" #setRulesPop="bs-popover"
				placement="bottom"
				containerClass="gr-menu-popover"
				[popover]="setRulesTemplate"
				[outsideClick]="true"
				[ngClass]="{
					'disabled': !someAreSelected
				}"
				[title]="'SetRulesOnSelected' | translate"
				(click)="initiateGlobalRules()">
			</button>
			<ng-template #setRulesTemplate>
				<div class="gr-menu-item">
					<simple-rules
						[rules]="globalDummyUser.rulesEditableInSchedule"
						[editable]="true"
						[shouldStopPropagationOnClick]="true"
						(onRuleClick)="handleRuleClickGlobal($event)">
					</simple-rules>
				</div>
			</ng-template>
		</ng-container>
		<ng-container slot="itemList">
			<cdk-virtual-scroll-viewport itemSize="{{ itemSize }}" minBufferPx="999" maxBufferPx="1000" class="apx-scrollbar" scrollShadow>
				<simple-item *cdkVirtualFor="let item of getItems(); let index = index; cdkVirtualForTrackBy: trackByFn"
					[item]="item"
					[noBorder]="index === handleItems.length - 1"
					[hideRemoveButton]="readonlyItems"
					[hideSelectButton]="readonlyItems"
					[url]="item.user.url"
					[name]="item.user.fullName ? item.user.fullName : item.user.email"
					[height]="itemSize"
					(onRemove)="remove($event)"
					(onSelect)="select($event)">
						<ng-container slot="content">
							<div class="image-details-wrapper">
								<notice-icon
									[shouldDisplay]="userExistsInAnySelectedScheduleGroup(item)"
									[iconClass]="'bi bi-exclamation-circle-fill'"
									[titleText]="'UserExistsInGroup' | translate"
									[noticeText]="'UserExistsInGroup' | translate">
								</notice-icon>
								<div class="item-image" #parentDiv>
									<extensive-image
										[imageUrl]="item.user.profileImageUrl"
										[imageStorageSize]="parentDiv | getImageStorageSize"
										[rounded]="true"
										[placeholderImageType]="'user'">
									</extensive-image>
								</div>
								<div class="details">
									<div class="name" title="{{ item.user.fullName }}" *ngIf="item.user.fullName">
										{{ item.user.fullName }}
									</div>
									<div class="description" *ngIf="item.user.email"
										[ngClass]="{
											'only': !item.user.fullName
										}"
										title="{{ item.user.email }}" >
											{{ item.user.email }}
									</div>
								</div>
							</div>
							<div class="rules">
								<simple-rules
									[rules]="item.rulesEditableInSchedule"
									[editable]="true"
									(onRuleClick)="handleRuleClick(item, $event)">
								</simple-rules>
							</div>
							<div class="status">
								<simple-status-label
									[text]="item.user.getCurrentStatus(selectedOrganization.id)">
								</simple-status-label>
							</div>
						</ng-container>
				</simple-item>
				<simple-no-items *ngIf="!handleItems.length && !searchValue"
					[title]="'NoAssigneesAdded' | translate">
				</simple-no-items>
				<simple-no-items *ngIf="!getItems().length && searchValue"
					[title]="'NoResultsMatchingSearch' | translate">
				</simple-no-items>
			</cdk-virtual-scroll-viewport>
		</ng-container>
</simple-handle-tab>
