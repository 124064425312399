<simple-handle-tab
	[headerTitleText]="'AddedEntities' | translate"
	[headerSubtitleText]="headerSubtitleText"
	[headerAddButtonText]="'AddEntities' | translate"
	[hideAddButton]="hideHeaderAddButton"
	[hideRemoveSelected]="hideHeaderRemoveSelected"
	[hideSelectAll]="hideHeaderSelectAll"
	[disableAddButton]="!facilityIds.length && !facilityId"
	[allAreSelected]="allAreSelected"
	[disableRemoveSelected]="!someAreSelected"
	[disableSelectAll]="!getItems().length"
	[selectedCount]="getSelectedCount()"
	[disableSearch]="!handleItems.length"
	[hideRemoveSelected]="usePopoverRemoveButton"
	(onAddButtonClick)="toggleListEntityModal()"
	(onRemoveSelected)="removeSelected()"
	(onSelectAll)="selectAll()"
	(onSearch)="handleSearch($event)">
		<ng-container slot="itemList">
			<cdk-virtual-scroll-viewport itemSize="{{ itemSize }}" minBufferPx="999" maxBufferPx="1000" class="apx-scrollbar" scrollShadow>
				<simple-item *cdkVirtualFor="let item of getItems(); let index = index; cdkVirtualForTrackBy: trackByFn"
					[item]="item"
					[noBorder]="index === handleItems.length - 1"
					[hideRemoveButton]="readonlyItems"
					[hideSelectButton]="readonlyItems"
					[url]="item.url"
					[name]="item.name"
					[height]="itemSize"
					[hideRemoveButton]="usePopoverRemoveButton"
					(onRemove)="remove($event)"
					(onSelect)="select($event)">
						<ng-container slot="content">
							<div class="image-details-wrapper">
								<div class="item-image" #parentDiv>
									<extensive-image
										[imageUrl]="item.thumbnailUrl"
										[imageStorageSize]="parentDiv | getImageStorageSize"
										(onImageLoaded)="setupHover($event)">
									</extensive-image>
								</div>
								<div class="details">
									<div class="name" title="{{ item.name }}" *ngIf="item.name">
										{{ item.name }}
									</div>
									<div class="description" title="{{ item.description }}" *ngIf="item.description">
										{{ item.description }}
									</div>
								</div>
							</div>
							<div class="facility-name" *ngIf="displayFacilityNames">
								{{ item.facility.name }}
							</div>
							<div class="icons">
								<div class="bi bi-person-wheelchair" *ngIf="item.accessible"
									title="{{ 'Accessibility' | translate }}">
								</div>
								<div class="bi bi-geo-alt-fill" *ngIf="item.latitude && item.longitude"
									title="{{ 'LocationData' | translate }}">
								</div>
							</div>
							<div class="status">
								<simple-status-label
									[text]="item.status ? 'Active' : 'Inactive'">
								</simple-status-label>
							</div>
						</ng-container>
						<ng-container slot="actionsAfterRemove" *ngIf="usePopoverRemoveButton">
							<div class="action bi-x-lg" #removeActionsPop="bs-popover"
								placement="left"
								container="body"
								containerClass="standard-menu-popover"
								[popover]="removeActionTemplate"
								[outsideClick]="true"
								[ngClass]="{
									'opened': item.actionsOpened
								}"
								[title]="'Remove' | translate"
								(onShown)="item.actionsOpened = true"
								(onHidden)="item.actionsOpened = false">
							</div>
							<ng-template #removeActionTemplate>
								<div class="standard-menu-item"
									[title]="'DisconnectFromFacility' | translate"
									(click)="remove(item); removeActionsPop.hide()">
										{{ 'DisconnectFromFacility' | translate }}
								</div>
								<div class="standard-menu-item red-highlight"
									[title]="'DeleteFromOrganization' | translate"
									(click)="deleteFromOrg(item); removeActionsPop.hide()">
										{{ 'DeleteFromOrganization' | translate }}
								</div>
							</ng-template>
						</ng-container>
				</simple-item>
				<simple-no-items *ngIf="!handleItems.length && !searchValue"
					[title]="'NoEntitiesAdded' | translate">
				</simple-no-items>
				<simple-no-items *ngIf="!getItems().length && searchValue"
					[title]="'NoResultsMatchingSearch' | translate">
				</simple-no-items>
			</cdk-virtual-scroll-viewport>
		</ng-container>
		<ng-container slot="actionsAfterRemove" *ngIf="usePopoverRemoveButton">
			<div class="action bi-x-lg" #removeActionsPop="bs-popover"
					placement="left"
					container="body"
					containerClass="standard-menu-popover"
					[popover]="removeActionTemplate"
					[outsideClick]="true"
					[ngClass]="{
						'opened': actionsOpened,
						'disabled': !someAreSelected
					}"
					[title]="'RemoveSelected' | translate"
					(onShown)="actionsOpened = true"
					(onHidden)="actionsOpened = false">
				</div>
				<ng-template #removeActionTemplate>
					<div class="standard-menu-item"
						[title]="'DisconnectFromFacility' | translate"
						(click)="removeSelected(); removeActionsPop.hide()">
							{{ 'DisconnectFromFacility' | translate }}
					</div>
					<div class="standard-menu-item red-highlight"
						[title]="'DeleteFromOrganization' | translate"
						(click)="deleteSelectedFromOrg(); removeActionsPop.hide()">
							{{ 'DeleteFromOrganization' | translate }}
					</div>
				</ng-template>
		</ng-container>
</simple-handle-tab>
