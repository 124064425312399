<simple-crud-modal
	[titleText]="'Assignment' | translate"
	[closeText]="'Close' | translate"
	[submitText]="getSelectedCountText('Update' | translate)"
	[disableSubmit]="!everythingIsValid() || !affectableIdsAreValid()"
	[isLoading]="pending"
	[displayDummy]="!initialModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="initialModel">
			<tab #handleTab="tab" [customClass]="'valid'">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-people'"
						[title]="'Groups' | translate"
						[helpText]="'AssignmentHandleGroupsHelpText' | translate"
						[displayCount]="initialModel.assigneeGroups.length">
					</simple-tab-heading>
				</ng-template>
				<handle-assignee-groups-tab
					[handleItems]="initialModel.assigneeGroups"
					[headerSubtitleText]="'AddedGroupsSubtitleAssignmentMultiple' | translate"
					[readonlyListModal]="true"
					[isMeasure]="initialModel.isMeasureAssignment()"
					[wasSelected]="handleTab.active"
					(onModelsChange)="initialModel.assigneeGroups = $event">
				</handle-assignee-groups-tab>
			</tab>
			<tab #handleTab2="tab" [customClass]="'valid'">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-person'"
						[title]="'Assignees' | translate"
						[helpText]="'AssignmentHandleUsersHelpText' | translate"
						[displayCount]="initialModel.assignees.length">
					</simple-tab-heading>
				</ng-template>
				<handle-assignees-tab
					[handleItems]="initialModel.assignees"
					[headerSubtitleText]="'AddedUsersSubtitleAssignmentMultiple' | translate"
					[selectedAssigneeGroups]="initialModel.assigneeGroups"
					[readonlyListModal]="true"
					[isMeasure]="initialModel.isMeasureAssignment()"
					[wasSelected]="handleTab2.active"
					(onModelsChange)="initialModel.assignees = $event">
				</handle-assignees-tab>
			</tab>
		</tabset>
</simple-crud-modal>
