import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { SimpleTableRow } from './table/body/simple-table-row';

export class SimpleListDataSource<T extends { id: string }> extends DataSource<SimpleTableRow<T>> {

	readonly rows$ = new BehaviorSubject<SimpleTableRow<T>[]>([]);
	collectionViewer: CollectionViewer;

	// Called automatically when the virtual scroll viewport is created.
	connect(collectionViewer: CollectionViewer): Observable<SimpleTableRow<T>[]> {
		this.collectionViewer = collectionViewer;
		return this.rows$;
	}

	// Called automatically when the virtual scroll viewport is destroyed.
	disconnect(): void {
		this.rows$.complete();
	}
}
