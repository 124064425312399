import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { InfraControlIntegration, IntegrationTypes } from '../../integration';
import { IntegrationService } from '../../integration.service';
import { ChangeInfraControlDetailsTabComponent } from './tabs/change-infra-control-details-tab.component';

@Component({
	templateUrl: './create-edit-infra-control.component.html'
})
export class CreateEditInfraControlComponent extends SimpleCreateEditModalDirective<InfraControlIntegration> implements OnInit {

	@ViewChild(ChangeInfraControlDetailsTabComponent) changeDetailsTabComponent: ChangeInfraControlDetailsTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private integrationService: IntegrationService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		if (this.isEdit) {
			this.initialModel = new InfraControlIntegration(this.initialModel);
			this.initialModel.password = '';
			this.initialModel.newPassword = '';
			this.initialModel.confirmPassword = '';
			this.modifiedModel = new InfraControlIntegration(this.getUniqueVariant(this.initialModel));

			setTimeout(() => {
				this.triggerAllValidation();
			}, 0);
		} else {
			this.initialModel = new InfraControlIntegration({
				system: '',
				organizationId: this.selectedOrganization.id,
				type: IntegrationTypes.InfraControl,
				active: true,
				password: '',
				newPassword: '',
				confirmPassword: ''
			});

			this.modifiedModel = new InfraControlIntegration(this.getUniqueVariant(this.initialModel));
		}
	}

	changeDetailsIsValid() {
		return this.changeDetailsTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeDetailsTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.integrationService.update(this.modifiedModel.toPayloadObject())
				: await this.integrationService.create(this.modifiedModel.toPayloadObject());

			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeDetailsTabComponent.setServerErrors(this.serverErrors);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeDetailsTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: InfraControlIntegration) {
		return new InfraControlIntegration(item);
	}

	private detailsHasChanged() {
		if (this.isEdit) {
			return this.modifiedModel.system !== this.initialModel.system
				|| this.modifiedModel.active !== this.initialModel.active
				|| this.modifiedModel.serviceAccountEmail !== this.initialModel.serviceAccountEmail
				|| this.modifiedModel.username !== this.initialModel.username
				|| !Utils.isEqual(this.modifiedModel.emails, this.initialModel.emails)
				|| !this.modifiedModel.password.isNullOrEmpty()
				|| !this.modifiedModel.newPassword.isNullOrEmpty()
				|| !this.modifiedModel.confirmPassword.isNullOrEmpty();
		} else {
			return this.modifiedModel.system !== this.initialModel.system
				|| this.modifiedModel.active !== this.initialModel.active
				|| this.modifiedModel.serviceAccountEmail !== this.initialModel.serviceAccountEmail
				|| this.modifiedModel.username !== this.initialModel.username
				|| !Utils.isEqual(this.modifiedModel.emails, this.initialModel.emails)
				|| !this.modifiedModel.newPassword.isNullOrEmpty()
				|| !this.modifiedModel.confirmPassword.isNullOrEmpty();
		}
	}

	hasUnsavedChanges() {
		return this.modifiedModel && this.detailsHasChanged();
	}
}
