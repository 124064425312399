import { NgModule } from '@angular/core';
import { StatisticsModule } from 'app-inspection/statistics/statistics.module';
import { SharedModule } from '../shared-core/shared.module';
import { AdministrationComponent } from './administration.component';

@NgModule({
	imports: [
		SharedModule,
		StatisticsModule
	],
	declarations: [
		AdministrationComponent
	]
})
export class AdministrationModule { }
