import {
	Component, OnDestroy, OnInit
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationStart, NavigationEnd, Router } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { TemplateTypeMiddleware } from 'app-inspection/template-type/template-type.middleware';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as locales from 'ngx-bootstrap/locale';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { ErrorTypeKey } from './error/error-type';
import { ErrorService } from './error/error.service';
import { ClientMiddleware } from './organization/my-organization/clients/client.middleware';
import { Organization } from './organization/organization';
import { AdditionalInfoMiddleware } from './shared-core/additional-info/additional-info.middleware';
import { MapConfig } from './shared-core/google-maps/google-maps.component';
import { HostedHttpClientService } from './shared-core/hosted-httpclient.service';
import { RoutesUtils } from './shared-core/tools/routes-utils';
import { StringUtils } from './shared-core/tools/string-utils';
import { Utils } from './shared-core/tools/utils';
import { TranslationService } from './shared-core/translation/translation.service';
import { UserGroupMiddleware } from './user-group/user-group-middleware';
import { UserSupportLocalStorageService } from './user-support/user-support-local-storage.service';
import { DEFAULT_LANGUAGE, LoggedInUser, SUPPORTED_LANGUAGES } from './user/user';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {

	loggedInUser: LoggedInUser;
	selectedOrganization: Organization;

	isInvalidRoute: boolean = false;

	errorTypeKey = ErrorTypeKey;

	displayLoader: boolean = true;

	routesUtils = RoutesUtils;

	environmentName: string = '';

	environmentEmails: string[] = [
		'patrik.erlandsson@inquinova.com',
		'kim.nilsson@inquinova.com',
		'henrik.gabrielsson@inquinova.com',
		'rasmus.israelsson@inquinova.com'
	];

	menuOpenedMobile: boolean = false;

	private subscriptions = new Subscription();
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

	get displayEnvironmentName() {
		return this.environmentName === 'Production' && this.environmentEmails.includes(this.loggedInUser.email);
	}

	constructor(
		private authService: AuthService,
		private translationService: TranslationService,
		private router: Router,
		private titleService: Title,
		public errorService: ErrorService,
		private modalService: BsModalService,
		private bsLocaleService: BsLocaleService,
		private hostedHttpClient: HostedHttpClientService,
		private toastrService: ToastrService,
		private userSupportLocalStorageService: UserSupportLocalStorageService) { }

	async ngOnInit() {
		this.subscriptions.add(
			this.router.events
				.pipe(takeUntil(this.destroyed$))
				.subscribe((navigationEnd: NavigationEnd) => {
					if (navigationEnd instanceof NavigationEnd) {

						// When navigating away from an error page, reset the error service variable.
						if (this.errorService.errorOfType$.value !== ErrorTypeKey.Default) {
							this.errorService.errorOfType$.next(ErrorTypeKey.Default);
						}

						this.loggedInUser = this.authService.loggedInUser;
						if (this.loggedInUser) {
							this.initializeUser();
						}

						this.displayLoader = false;
					}
				})
		);

		this.subscriptions.add(
			this.authService.selectedOrganization$
				.pipe(takeUntil(this.destroyed$))
				.subscribe(selectedOrganization => {
					if (selectedOrganization) {
						if (this.selectedOrganization && this.selectedOrganization.id !== selectedOrganization.id) {
							setTimeout(() => {
								location.reload();
							}, 0);
						} else {
							this.selectedOrganization = selectedOrganization;
						}
					}
				})
		);

		this.subscriptions.add(
			this.authService.loggedInUser$
				.pipe(takeUntil(this.destroyed$))
				.subscribe(loggedInUser => {
					if (loggedInUser && this.selectedOrganization) {
						this.loggedInUser = loggedInUser;
					}
				})
		);

		this.subscriptions.add(
			this.authService.isSwitchingUser$
				.pipe(takeUntil(this.destroyed$))
				.subscribe(isSwitchingUser => {
					if (isSwitchingUser) {
						this.toastrService.error(this.translationService.instant('SwitchingUserMessage'));
					}
				})
		);

		// Handles dynamic title for pages
		this.initDynamicTitles();

		// Start all middlewares.
		this.hostedHttpClient.add(new AdditionalInfoMiddleware(this.modalService, this.translationService));
		this.hostedHttpClient.add(new TemplateTypeMiddleware());
		this.hostedHttpClient.add(new UserGroupMiddleware());
		this.hostedHttpClient.add(new ClientMiddleware());

		this.environmentName = environment.production ? 'Production' : 'Development';

		// Get map api
		this.getMap();
	}

	/**
	 * Listens on the event ActivationStart and then applies the specified title for the current page.
	 * setTimeout is needed to not set the title to early, which would result in history having incorrect titles.
	*/
	initDynamicTitles() {
		this.subscriptions.add(
			this.router.events
				.pipe(filter(event => event instanceof ActivationStart), takeUntil(this.destroyed$))
				.subscribe(event => {
					const title = event['snapshot'].data['title'];
					setTimeout(() => {
						if (!!title) {
							this.titleService.setTitle(this.translationService.instant(title));
						} else {
							this.titleService.setTitle(this.translationService.instant(StringUtils.DEFAULT_PAGE_TITLE));
						}
					});

					// Keeps track if we are on an invalid route.
					this.isInvalidRoute = event['snapshot'].data['isInvalid'];
				})
		);

		/**
		 * This subscription is needed to override the title if an error occured on a correct route.
		 * For example, if an ID does not exist.
		*/
		this.subscriptions.add(this.errorService.errorOfType$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(error => {
				switch (error) {
					case ErrorTypeKey.NotFound:
						this.titleService.setTitle(this.translationService.instant('PageNotFound'));
						break;

					case ErrorTypeKey.BadRequest:
						this.titleService.setTitle(this.translationService.instant('BadRequest'));
						break;

					case ErrorTypeKey.ServerError:
						this.titleService.setTitle(this.translationService.instant('ServerError'));
						break;
				}
			})
		);
	}

	async initializeUser() {
		// Set language and timezone.
		let language = this.loggedInUser ? this.loggedInUser.language.toLowerCase() : DEFAULT_LANGUAGE;
		language = SUPPORTED_LANGUAGES.some(o => o.indexOf(language) >= 0) ? language : DEFAULT_LANGUAGE;
		this.translationService.setDefaultLang(language);
		this.translationService.use(language);
		moment.locale(language.replace('no', 'nb'));
		moment.tz.setDefault(this.loggedInUser.timeZoneIANA);
		this.setDatepickerLanguage(language.replace('no', 'nb'));

		// A nicer format.
		if (language === 'sv') {
			moment.updateLocale(language, { longDateFormat: { "lll": "D MMM YYYY (LT)" } } as any);
		}

		// User support config.
		this.userSupportLocalStorageService.setInitialUserConfig(this.loggedInUser.userConfig);
	}

	private setDatepickerLanguage(language: string) {

		// We need to define the locale before we can use it.
		for (const locale in locales) {
			if (locales[locale].abbr === language) {
				defineLocale(language, locales[locale]);
				this.bsLocaleService.use(language);
				break;
			}
		}
	}

	hasOpenModals() {
		return this.modalService.getModalsCount() > 0;
	}

	navigateToProfile() {
		this.router.navigate([`/${this.selectedOrganization.friendlyUrl}/${RoutesUtils.profile}`]);
	}

	logout() {
		this.authService.startLogout();
	}

	routeIsActive(pathName: string) {
		const strippedPath = this.getCurrentStrippedURLPath();
		return pathName.split('/').every(entry => strippedPath.indexOf(entry) !== -1);
	}

	getCurrentStrippedURLPath() {
		return window.location.pathname.replace(this.selectedOrganization.friendlyUrl, '').split('/')
			.filter(entry => !!entry);
	}

	routeIsDisabled(pathName: string) {
		// Disable when active, but not in "/new", "/:id" etc...
		const maxLength = pathName.split('/').length > 1 ? 4 : 3;
		return this.routeIsActive(pathName) && (this.router.url.split('/').length - 1) < maxLength;
	}

	getMap() {
		const loader = new Loader({
			apiKey: MapConfig.apiKey,
			version: 'weekly',
			libraries: [
				'marker'
			]
		});
		loader.importLibrary('maps');
	}

	toggleMenuMobile() {
		if (Utils.isMediumScreenSize()) {
			this.menuOpenedMobile = !this.menuOpenedMobile;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this.subscriptions.unsubscribe();
	}
}
