import { NgModule } from '@angular/core';
import { SharedModule } from 'app-core/shared-core/shared.module';
import { CreateEditDfResponsComponent } from './external/df-respons/create-edit-df-respons.component';
import { ChangeDfResponsDetailsTabComponent } from './external/df-respons/tabs/change-df-respons-details-tab.component';
import { CreateEditInfraControlComponent } from './external/infra-control/create-edit-infra-control.component';
import { ChangeInfraControlDetailsTabComponent } from './external/infra-control/tabs/change-infra-control-details-tab.component';
import { EditSSENReportComponent } from './report/edit-ssen-report.component';
import { ChangeDetailsTabComponent } from './report/tabs/change-details-tab.component';

@NgModule({
	imports: [
		SharedModule
	],
	declarations: [
		EditSSENReportComponent,
		ChangeDetailsTabComponent,
		CreateEditDfResponsComponent,
		ChangeDfResponsDetailsTabComponent,
		CreateEditInfraControlComponent,
		ChangeInfraControlDetailsTabComponent
	],
	exports: [
		EditSSENReportComponent,
		CreateEditDfResponsComponent,
		CreateEditInfraControlComponent
	]
})
export class IntegrationModule { }
