import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as InspireTree from 'inspire-tree/dist/inspire-tree';

import { InspireTreeNodesComponent } from './inspire-tree-nodes.component';

/**
 * Inspire Tree component. Initializes InspireTree on a given element.
 */
@Component({
	selector: 'inspire-tree',
	viewProviders: [InspireTreeNodesComponent],

	template: `
		<div class="inspire-tree {{ customClass }}" tabindex="-1" #scrollableElement>
			<ol inspire-tree-nodes
				[nodes]="tree.nodes()"
				[tree]="tree">
			</ol>
		</div>
	`
})
export class InspireTreeComponent {
	private initialized = false;

	// Keep a reference to the tree instance
	tree: any;

	@ViewChild('scrollableElement') scrollableElement: ElementRef;

	@Input()
	customClass: string;

	@Output()
	dataLoad = new EventEmitter();

	@Output()
	nodeSelected = new EventEmitter();

	/**
	 * Initialize InspireTree on the component's target element.
	 *
	 * @param {ElementRef} el An HTML element.
	 * @param {HttpClient} http HTTP service for loading JSON data.
	 * @param {ChangeDetectorRef} ref Change detection service/
	 */
	constructor(private el: ElementRef, http: HttpClient, ref: ChangeDetectorRef) {
		this.tree = new InspireTree({
			data: (node, resolve, reject) => {
				// Stop when attempting to request the initial data:
				// will request in `initialize` instead.
				if (!node) { return; }
				return this.loadData(node, resolve, reject);
			},
			selection: {
				multiple: false,
				require: true
			}
		});

		// Listen for the model loaded event
		this.tree.on('model.loaded', _ => {
			// Angular can't see this change, so we have to tell it
			ref.markForCheck();

			// Fires after the initial data has been loaded
			window.setTimeout(() => {
				this.scrollSelectedIntoView();
			}, 1);
		});

		this.tree.on('node.selected', (event, node) => {
			this.nodeSelected.emit([event, node]);
		});
	}

	/**
	 * Called externally after the parent component has been initialized (i.e all animations completed).
	 */
	public componentReady() {
		this.tree.load((node, resolve, reject) => {
			return this.loadData(node, resolve, reject);
		});
	}

	private loadData(node, resolve, reject) {
		this.dataLoad.emit([node, resolve, reject]);
	}

	/**
	 * Select a node by ID.
	 * @param nodeId
	 */
	public selectNode(nodeId: number) {
		this.traverseNodes((node) => {
			if (node.realId === nodeId) {
				node.select();
			}
		});
	}

	/**
	 * Tracerses all nodes in the tree, calling a predicate function with each node.
	 * @param predicate
	 */
	public traverseNodes(predicate: Function) {
		this.tree.flatten(node => {
			predicate.call(null, node);
		});
	}

	/**
	 * Scrolls the tree so the selected node gets visible in the viewport
	 */
	public scrollSelectedIntoView() {
		// Get the tree itself and find the selected element inside
		const $tree = this.el.nativeElement;
		const $selected = $tree && $tree.querySelector('.selected');
		
		if ($selected) {
			if (typeof $selected.scrollIntoView === 'function') {
				// Use a native method, if available.
				$selected.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
			} else if (this.scrollableElement) {
				// Manual scroll otherwise.
				this.scrollableElement.nativeElement.scrollTop = $selected.offsetTop;
			}
		}
	}

}
