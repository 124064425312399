<div class="item"
	[ngClass]="{
		'no-border': noBorder,
		'has-error': item.errorMessages?.length
	}">
		<div class="item-content"
			[ngStyle]="{
				'height': height + 'px'
			}">
				<ng-content select="[slot=content]"></ng-content>
				<div class="actions">
					<ng-content select="[slot=actions]"></ng-content>
					<simple-link-button *ngIf="url && name"
						[url]="url"
						[name]="name"
						[shouldDisable]="shouldDisableLink">
					</simple-link-button>
					<div class="action bi-x-lg" *ngIf="!hideRemoveButton"
						title="{{'Remove' | translate}}"
						(click)="remove(item)">
					</div>
					<ng-content select="[slot=actionsAfterRemove]"></ng-content>
					<div class="action select" *ngIf="!hideSelectButton"
						(mouseover)="hovered = true"
						(mouseleave)="hovered = false"
						[ngClass]="{
							'bi-square': !hovered && !item.selected,
							'bi-check-square': hovered && !item.selected,
							'bi-check-square-fill': item.selected
						}"
						title="{{ (item.selected ? 'Unselect' : 'Select') | translate }}"
						(click)="select(item)">
					</div>
				</div>
		</div>
		<div class="item-messages" *ngIf="item.errorMessages?.length">
			<div class="item-message" *ngFor="let message of item.errorMessages">
				&bull; {{ message | translate }}
			</div>
		</div>
</div>
