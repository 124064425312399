import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { BackendResponse } from 'app-core/shared-core/abstract-components/service/base.service';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { RegexUtils } from 'app-core/shared-core/tools/regex-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { InvitedUser } from 'app-core/user/user';
import { UserService } from 'app-core/user/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
	selector: 'invitation',
	templateUrl: './invitation.component.html',
	styleUrls: ['./invitation.component.less']
})
export class InvitationComponent extends SimpleChangeTabDirective<InvitedUser> implements OnInit {

	ready: boolean = false;
	footerIsReady: boolean = false;
	submitting: boolean = false;
	headerLogo = StringUtils.DEFAULT_HEADER_LOGO;
	currentYear: number;
	acceptedInvite: boolean;
	declinedInvite: boolean;
	registerForm: FormGroup;

	code: string;
	culture: string;

	isRegistering = false;
	passwordIsMatching = true;
	hasSubmittedRegisterForm = false;

	constructor(
		protected router: Router,
		protected modalService: BsModalService,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected authService: AuthService,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private userService: UserService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit(): void {
		this.currentYear = new Date().getFullYear();
		const params = this.route.snapshot.queryParams;
		this.code = params['code'];
		this.culture = params['culture'];

		if (this.code) {
			if (this.culture) {
				this.translationService.setLanguageFromUrl(this.culture);
			}

			this.changeModel = new InvitedUser({
				email: '',
				firstname: '',
				lastname: '',
				culture: this.culture,
				code: this.code
			});

			this.form = this.formBuilder.group({
				[this.propertyStrings.email]: [
					this.changeModel.email,
					[
						this.emailValidator,
						Validators.required
					]
				]
			});

			this.registerForm = this.formBuilder.group({
				[this.propertyStrings.firstname]: [
					this.changeModel.firstname,
					[Validators.required, Validators.maxLength(25)]
				],
				[this.propertyStrings.lastname]: [
					this.changeModel.lastname,
					[Validators.required, Validators.maxLength(25)]
				],
				[this.propertyStrings.newPassword]: [
					this.changeModel.newPassword,
					[
						Validators.required,
						this.passwordDigitValidator,
						this.passwordLowerValidator,
						this.passwordUpperValidator,
						this.passwordSpecialValidator,
						this.passwordLengthValidator
					]
				],
				[this.propertyStrings.confirmPassword]: [
					this.changeModel.confirmPassword,
					Validators.required
				]
			}, { validators: this.passwordMatchValidator });
			super.ngOnInit();
			this.initReactiveRegisterForm();
			this.ready = true;
		}
		this.footerIsReady = true;
	}

	initReactiveRegisterForm() {
		this.subscriptions.add(
			this.registerForm.valueChanges
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(_ => {
					Object.entries(this.registerForm.controls).forEach(([name, control]) => {
						if (!control.disabled) {
							this.changeModel[name] = control.value;
						}
					});
				})
		);
	}

	private emailValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.EMAIL).test(control.value)) {
			return { email: true };
		} else {
			return null;
		}
	}

	private passwordMatchValidator(form: FormGroup): ValidationErrors {
		const newPassword = form.get('newPassword');
		const confirmPassword = form.get('confirmPassword');

		if (newPassword.value && confirmPassword.value && newPassword.value !== confirmPassword.value) {
			confirmPassword.setErrors({ passwordsDoNotMatch: true });
		}

		if (newPassword.value && confirmPassword.value && newPassword.value === confirmPassword.value) {
			confirmPassword.setErrors(null);
		}
		return null;
	}

	private passwordDigitValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_DIGIT).test(control.value)) {
			return { passwordRequiresDigit: true };
		} else {
			return null;
		}
	}

	private passwordLowerValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_LOWER).test(control.value)) {
			return { passwordRequiresLower: true };
		} else {
			return null;
		}
	}

	private passwordUpperValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_UPPER).test(control.value)) {
			return { passwordRequiresUpper: true };
		} else {
			return null;
		}
	}

	private passwordSpecialValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_SPECIAL).test(control.value)) {
			return { passwordRequiresNonAlphanumeric: true };
		} else {
			return null;
		}
	}

	private passwordLengthValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_LENGTH).test(control.value)) {
			return { passwordRequiresLengthSixOrMore: true };
		} else {
			return null;
		}
	}

	handleAccept() {
		this.handleRequest(true);
	}

	handleDecline() {
		this.handleRequest(false);
	}

	async handleRequest(acceptedInvite: boolean) {
		try {
			this.submitting = true;
			const response = await this.userService.handleInvitation(this.changeModel.email, this.code, this.culture, acceptedInvite);

			this.displaySuccessMessage(response.successMessage);

			if (acceptedInvite) {
				if (response.data !== null) {
					this.mapUserObject(response);
				}

				this.acceptedInvite = true;
				this.declinedInvite = false;
			} else {
				this.acceptedInvite = false;
				this.declinedInvite = true;
			}
			this.submitting = false;

		} catch (errorResponse) {
			this.submitting = false;
			this.serverErrors = [];
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.setServerErrors(this.serverErrors);
			}
		}
	}

	// todo denna komponent borde ärva från createEditDirective och i sin html ha en subkomponent som ärver från changeTabDirective.
	override handleErrorResponse(response: BackendResponse) {
		if (response.error?.errors) {
			const errors = Object.entries(response.error.errors)
				.map(entry => new KeyValuePair(entry[0], entry[1].toString()));

			errors.forEach(error => {
				const key = error.key.toLowerCase();
				this.serverErrors.push(new KeyValuePair(key, error.value));
			});
		} else {
			super.handleErrorResponse(response);
		}
	}

	mapUserObject(response: any) {
		this.isRegistering = true;
		this.changeModel.organization = response.data.organization;
		this.changeModel.id = response.data.id;
	}

	inputsHaveValues() {
		return this.changeModel.firstname.length > 0
			&& this.changeModel.lastname.length > 0
			&& this.changeModel.newPassword.length > 0
			&& this.changeModel.confirmPassword.length > 0;
	}

	checkPasswordMatch(text: string, fieldName: string) {
		if (fieldName === 'new') {
			this.passwordIsMatching = text === this.changeModel.confirmPassword;
		} else if (fieldName === 'confirm') {
			this.passwordIsMatching = text === this.changeModel.newPassword;
		}
	}

	async register() {
		if (this.registerFormIsValid()) {
			try {
				this.submitting = true;
				const response = await this.userService.register(this.changeModel.id, this.changeModel.culture, this.changeModel.toPayloadObject());

				this.displaySuccessMessage(response.successMessage);

				setTimeout(() => {
					this.isRegistering = false;
				}, 1500);
			} catch (errorResponse) {
				this.submitting = false;
				this.handleErrorResponse(errorResponse);
			}
		}
	}

	registerFormIsValid() {
		return this.registerForm.valid || Object.keys(this.registerForm.controls).every(key => {
			const formControl = this.registerForm.get(key);
			return formControl.disabled;
		});
	}

	goToLogin() {
		this.authService.startLogin(true);
	}
}
