import { Component, Input } from '@angular/core';

@Component({
	selector: 'simple-no-items',
	templateUrl: './simple-no-items.component.html',
	styleUrls: ['./simple-no-items.component.less']
})
export class SimpleNoItemsComponent {

	@Input() title: string;
	@Input() redColor: boolean;
}
