import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { Entity } from 'app-inspection/entity/entity';
import { EntityService } from 'app-inspection/entity/entity.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { SetStatusTabComponent } from './tabs/set-status-tab.component';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';

@Component({
	templateUrl: './set-status-entity.component.html'
})
export class SetStatusEntityComponent extends SimpleBulkEditModalDirective<Entity> implements OnInit {

	@ViewChild(SetStatusTabComponent) setEntityStatusTabComponent: SetStatusTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private entityService: EntityService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.initialModel = new Entity({});
	}

	changeDetailsIsValid() {
		return this.setEntityStatusTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.setEntityStatusTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = await this.entityService.updateStatus(this.editModelIds, this.initialModel.status);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.setEntityStatusTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Entity) {
		return new Entity(item);
	}

	hasUnsavedChanges() {
		return false;
	}
}
