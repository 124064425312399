<simple-handle-tab
	[headerTitleText]="'AddedTasks' | translate"
	[headerSubtitleText]="headerSubtitleText"
	[headerAddButtonText]="'AddTasks' | translate"
	[hideAddButton]="hideHeaderAddButton"
	[hideRemoveSelected]="hideHeaderRemoveSelected"
	[hideSelectAll]="hideHeaderSelectAll"
	[allAreSelected]="allAreSelected"
	[disableRemoveSelected]="!someAreSelected"
	[disableSelectAll]="!getItems().length"
	[selectedCount]="getSelectedCount()"
	[disableSearch]="!handleItems.length"
	(onAddButtonClick)="toggleListTaskModal()"
	(onRemoveSelected)="removeSelected()"
	(onSelectAll)="selectAll()"
	(onSearch)="handleSearch($event)">
		<ng-container slot="headerActions">
			<choices *ngIf="!readonlyChoices"
				[handleItems]="getChoicesGlobal()"
				[actionTitle]="'AddChoicesToSelected' | translate"
				[disableAction]="!someAreSelected"
				[hideChoices]="true"
				[useButtonAction]="true"
				(onModelsChange)="handleChoicesChangeGlobal($event)"
				(onUpdated)="handleUpdatedChoices($event)"
				(onDeleted)="handleDeletedChoices($event)">
			</choices>
		</ng-container>
		<ng-container slot="itemList">
			<cdk-virtual-scroll-viewport itemSize="{{ itemSize }}" minBufferPx="999" maxBufferPx="1000" class="apx-scrollbar" scrollShadow>
				<simple-item *cdkVirtualFor="let item of getItems(); let index = index; cdkVirtualForTrackBy: trackByFn"
					[item]="item"
					[noBorder]="index === handleItems.length - 1"
					[hideRemoveButton]="readonlyItems"
					[hideSelectButton]="readonlyItems"
					[url]="loggedInUser.isSuperAdmin() ? item.url : ''"
					[name]="item.text"
					[height]="itemSize"
					(onRemove)="remove($event)"
					(onSelect)="select($event)">
						<ng-container slot="content">
							<div class="details">
								<div class="name" title="{{ item.text }}" *ngIf="item.text">
									{{ item.text }}
								</div>
								<div class="description" title="{{item.code}}" *ngIf="item.code">
									{{ item.code }}
								</div>
							</div>
							<div class="rules">
								<simple-rules
									[rules]="item.rulesReadonly">
								</simple-rules>
							</div>
							<div class="choices">
								<choices #component
									[handleItems]="item.choices"
									[actionTitle]="'AddChoices' | translate"
									[readonlyItems]="readonlyChoices"
									(onModelsChange)="handleChoicesChange(item, $event)"
									(onUpdated)="handleUpdatedChoices($event)"
									(onDeleted)="handleDeletedChoices($event)">
								</choices>
							</div>
						</ng-container>
				</simple-item>
				<simple-no-items *ngIf="!handleItems.length && !searchValue"
					[title]="'NoTasksAdded' | translate">
				</simple-no-items>
				<simple-no-items *ngIf="!getItems().length && searchValue"
					[title]="'NoResultsMatchingSearch' | translate">
				</simple-no-items>
			</cdk-virtual-scroll-viewport>
		</ng-container>
</simple-handle-tab>
