import { NgModule } from '@angular/core';
import { SharedModule } from 'app-core/shared-core/shared.module';
import { TemplateTypeRoutingModule } from './template-type-routing.module';
import { ListTemplateTypeComponent } from './list-template-type/list-template-type.component';
import { DeleteTemplateTypesComponent } from './delete-template-types/delete-template-types.component';
import { CreateEditTemplateTypeComponent } from './create-edit-template-type/create-edit-template-type.component';
import { ChangeDetailsTabComponent } from './create-edit-template-type/tabs/change-details/change-details-tab.component';
import { HandleTasksTabComponent } from './create-edit-template-type/tabs/handle-tasks/handle-tasks-tab.component';
import { ChoicesComponent } from './create-edit-template-type/tabs/handle-tasks/choices/choices.component';

@NgModule({
	imports: [
		SharedModule,
		TemplateTypeRoutingModule
	],
	declarations: [
		ListTemplateTypeComponent,
		CreateEditTemplateTypeComponent,
		ChangeDetailsTabComponent,
		HandleTasksTabComponent,
		ChoicesComponent,
		DeleteTemplateTypesComponent
	],
	exports: [
		HandleTasksTabComponent
	]
})
export class TemplateTypeModule { }
