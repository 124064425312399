<simple-view-tab>
	<div class="view-section">
		<div class="image-wrapper">
			<div class="image" #parentDiv>
				<extensive-image
					[imageUrl]="viewModel.profileImageUrl"
					[imageStorageSize]="parentDiv | getImageStorageSize"
					[placeholderImageType]="'user'"
					[displayLoader]="true"
					[rounded]="true">
				</extensive-image>
			</div>
			<div class="details">
				<div class="primary"
					title="{{ viewModel.firstname + ' ' +  viewModel.lastname }}">
						{{ viewModel.firstname + ' ' +  viewModel.lastname }}
				</div>
				<div class="secondary"
					[title]="viewModel.email">
						{{ viewModel.email }}
				</div>
			</div>
		</div>
	</div>
	<div class="view-section">
		<div class="information">
			<div class="header-text">
				{{ 'General' | translate }}
			</div>
			<div class="icon-text-wrapper">
				<div class="icon bi bi-person" [title]="'Name' | translate"></div>
				<div class="text" title="{{ 'Name' | translate }}: {{ viewModel.firstname + ' ' +  viewModel.lastname }}">
					{{ viewModel.firstname + ' ' +  viewModel.lastname }}
				</div>
			</div>
			<div class="icon-text-wrapper">
				<div class="icon bi bi-envelope" [title]="'Email' | translate"></div>
				<div class="text" title="{{ 'Email' | translate }}: {{ viewModel.email }}">
					{{ viewModel.email }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.contactPhoneNumber">
				<div class="icon bi bi-telephone" [title]="'PhoneNumber' | translate"></div>
				<div class="phone-wrapper">
					<div class="country-code" *ngIf="viewModel.contactCountryCode"
						title="(+{{ viewModel.contactCountryCode }})">
							(+{{ viewModel.contactCountryCode }})
					</div>
					<div class="number" *ngIf="viewModel.contactPhoneNumber"
						title="{{ viewModel.contactPhoneNumber }}">
						{{ viewModel.contactPhoneNumber }}
					</div>
				</div>
			</div>
			<div class="icon-text-wrapper">
				<div class="icon bi bi-flag" [title]="'Country' | translate"></div>
				<div class="text" title="{{ 'Country' | translate }}: {{ getCountryName() }}">
					{{ getCountryName() }}
				</div>
			</div>
			<div class="header-text with-margin-top">
				{{ 'Settings' | translate }}
			</div>
			<div class="icon-text-wrapper">
				<div class="icon bi bi-translate" [title]="'Language' | translate"></div>
				<div class="text" title="{{ 'Language' | translate }}: {{ getCultureName() }}">
					{{ getCultureName() }}
				</div>
			</div>
			<div class="icon-text-wrapper">
				<div class="icon bi bi-clock" [title]="'Timezone' | translate"></div>
				<div class="text" title="{{ 'Timezone' | translate }}: {{ getTimeZoneName() }}">
					{{ getTimeZoneName() }}
				</div>
			</div>
			<div class="header-text with-margin-top" *ngIf="viewModel.certificates || documents.length">
				{{ 'Certificate' | translate }}
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.certificates">
				<div class="icon bi bi-pass" [title]="'CertificateNumber' | translate"></div>
				<div class="text" title="{{ 'CertificateNumber' | translate }}: {{ viewModel.certificates }}">
					{{ viewModel.certificates }}
				</div>
			</div>
			<div class="documents apx-scrollbar" *ngIf="documents.length">
				<div class="document" *ngFor=" let document of documents"
					[title]="document.title">
						<extensive-pdf
							[pdfUrl]="document.url"
							[blob]="document.blob ? document.blob : null"
							[thumbnailVersion]="true">
						</extensive-pdf>
				</div>
			</div>
		</div>
	</div>
	<div class="view-section">
		<div class="organizations">
			<div class="header-text">
				{{ 'MainOrganization' | translate }}
			</div>
			<div class="organization"
				[ngClass]="{
					'highlighted': viewModel.organization.id === selectedOrganization.id
				}">
					<div class="image" #parentDiv>
						<extensive-image
							[imageUrl]="viewModel.organization.logotypeUrl"
							[imageStorageSize]="parentDiv | getImageStorageSize"
							[placeholderImageType]="'organization'"
							[displayLoader]="true">
						</extensive-image>
					</div>
					<div class="info">
						<div class="name">
							{{ getOrgName(viewModel.organization) }}
						</div>
						<div class="roles">
							{{ getFormattedRoles(viewModel.roles) }}
						</div>
						<div class="status">
							<simple-status-label
								[text]="viewModel.status">
							</simple-status-label>
						</div>
					</div>
					<div class="actions-wrapper">
						<div class="actions">
							<button class="btn btn-transparent select-button bi bi-arrow-repeat"
								[ngClass]="{
									'disabled': viewModel.organization.id === selectedOrganization.id || (!(loggedInUser | isSuperAdmin) && !loggedInUser.isOrgMember(viewModel.organization.friendlyUrl))
								}"
								[title]="'Select' | translate"
								(click)="selectOrganization(viewModel.organization, loggedInUser.isSuperAdmin() || loggedInUser.isOrgMember(viewModel.organization.friendlyUrl))">
							</button>
						</div>
						<div class="invite-date">
							{{ 'InvitedOn' | translate }} {{ viewModel.inviteDate | datex: 'LL' }}
						</div>
					</div>
					<div class="test-marker" *ngIf="viewModel.organization.isTest">
						{{ stringUtils.TEST }}
					</div>
			</div>
			<div class="header-text with-margin-top" *ngIf="viewModel.organizationMemberships.length">
				{{ 'Memberships' | translate }}
			</div>
			<div class="organization" *ngFor="let membership of viewModel.organizationMemberships"
				[ngClass]="{
					'highlighted': membership.organization.id === selectedOrganization.id
				}">
					<div class="image" #parentDiv>
						<extensive-image
							[imageUrl]="membership.organization.logotypeUrl"
							[imageStorageSize]="parentDiv | getImageStorageSize"
							[placeholderImageType]="'organization'"
							[displayLoader]="true">
						</extensive-image>
					</div>
					<div class="info">
						<div class="name">
							{{ getOrgName(membership.organization) }}
						</div>
						<div class="roles">
							{{ getFormattedRoles(membership.roles) }}
						</div>
						<div class="status">
							<simple-status-label
								[text]="membership.status">
							</simple-status-label>
						</div>
					</div>
					<div class="actions-wrapper">
						<div class="actions">
							<button class="btn btn-transparent select-button bi bi-arrow-repeat"
								[ngClass]="{
									'disabled': membership.organization.id === selectedOrganization.id || (!(loggedInUser | isSuperAdmin) && !loggedInUser.isOrgMember(membership.organization.friendlyUrl))
								}"
								[title]="'Select' | translate"
								(click)="selectOrganization(membership.organization, loggedInUser.isSuperAdmin() || loggedInUser.isOrgMember(membership.organization.friendlyUrl))">
							</button>
						</div>
						<div class="invite-date">
							{{ 'InvitedOn' | translate }} {{ membership.inviteDate | datex: 'LL' }}
						</div>
					</div>
					<div class="test-marker" *ngIf="membership.organization.isTest">
						{{ stringUtils.TEST }}
					</div>
			</div>
		</div>
	</div>
	<div class="view-section">
		<div class="connections-wrapper">
			<simple-view-connections
				[userGroups]="viewModel.userGroups"
				[schedules]="schedules"
				[disabled]="!(loggedInUser | canEdit: selectedOrganization.friendlyUrl)">
			</simple-view-connections>
			<div class="dummy-text" *ngIf="!viewModel.userGroups.length && !schedules.length">
				{{ 'NoConnectionsYet' | translate }}
			</div>
		</div>
	</div>
</simple-view-tab>
