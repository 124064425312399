import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SSENReportIntegration, SSENReportIntegrationTypes } from '../../integration';

@Component({
	selector: 'change-details-tab',
	templateUrl: './change-details-tab.component.html',
	styleUrls: ['./change-details-tab.component.less']
})
export class ChangeDetailsTabComponent extends SimpleChangeTabDirective<SSENReportIntegration> implements OnInit {

	errorText: string = '';
	ssenReportIntegrationTypes = SSENReportIntegrationTypes
	initialTextAreaValue: string = '';

	@Input() type: string;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		switch (this.type) {
			case this.ssenReportIntegrationTypes.InAccordanceWith:
				this.initialTextAreaValue = this.changeModel.inAccordanceWith;
				break;
			case this.ssenReportIntegrationTypes.Scope:
				this.initialTextAreaValue = this.changeModel.scope;
				break;
			case this.ssenReportIntegrationTypes.RiskAnalysis:
				this.initialTextAreaValue = this.changeModel.riskAnalysis;
				break;
			case this.ssenReportIntegrationTypes.ErrorCodeExplanation:
				this.initialTextAreaValue = this.changeModel.errorCodeExplanation;
				break;
			case this.ssenReportIntegrationTypes.SerenoReference:
				this.initialTextAreaValue = this.changeModel.serenoReference;
				break;

		}

		// Needed just to have a form.....
		this.form = this.formBuilder.group({
			[this.propertyStrings.name]: [this.changeModel.name]
		});
	}

	isExtraSmallScreenSize() {
		return Utils.isExtraSmallScreenSize();
	}

	handleMarkdownChange(text: string) {
		switch (this.type) {
			case this.ssenReportIntegrationTypes.InAccordanceWith:
				this.changeModel.inAccordanceWith = text;
				this.errorText = !this.changeModel.inAccordanceWith ? this.translationService.instant('Required') : '';
				break;
			case this.ssenReportIntegrationTypes.Scope:
				this.changeModel.scope = text;
				break;
			case this.ssenReportIntegrationTypes.RiskAnalysis:
				this.changeModel.riskAnalysis = text;
				this.errorText = !this.changeModel.riskAnalysis ? this.translationService.instant('Required') : '';
				break;
			case this.ssenReportIntegrationTypes.ErrorCodeExplanation:
				this.changeModel.errorCodeExplanation = text;
				this.errorText = !this.changeModel.errorCodeExplanation ? this.translationService.instant('Required') : '';
				break;
			case this.ssenReportIntegrationTypes.SerenoReference:
				this.changeModel.serenoReference = text;
				break;
		}

	}
}
