import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { Client } from 'app-core/organization/organization';
import { PhoneValidationHelper } from 'app-core/shared-core/phone-validation/phone-validation-helper';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { RegexUtils } from 'app-core/shared-core/tools/regex-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'change-client-details-tab',
	templateUrl: './change-client-details-tab.component.html'
})
export class ChangeClientDetailsTabComponent extends SimpleChangeTabDirective<Client> implements OnInit {

	selectableCountryCodeItems: FormSelectItem[] = [];
	selectableCountryItems: FormSelectItem[] = [];

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder,
		private phoneValidationHelper: PhoneValidationHelper) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableCountryCodeItems();
		this.setSelectableCountryItems();

		this.form = this.formBuilder.group({
			[this.propertyStrings.name]: [this.changeModel.name,
			[
				Validators.required,
				Validators.maxLength(50)
			]
			],
			[this.propertyStrings.organizationNumber]: [this.changeModel.organizationNumber,
			[Validators.required, this.organizationNumberValidator]
			],
			[this.propertyStrings.emailAddress]: [this.changeModel.emailAddress,
			[Validators.required, this.emailValidator]
			],
			[this.propertyStrings.countryCode]: [this.changeModel.countryCode,
			this.countryCodeTriggerPhoneValidator],
			[this.propertyStrings.phoneNumber]: [this.changeModel.phoneNumber,
			this.phoneValidationHelper.phoneNumberValidator(this.propertyStrings.countryCode)
			],
			[this.propertyStrings.operationsManager]: [this.changeModel.operationsManager,
			Validators.maxLength(50)
			],
			[this.propertyStrings.streetAddress]: [this.changeModel.streetAddress,
			Validators.maxLength(50)
			],
			[this.propertyStrings.zipCode]: [this.changeModel.zipCode,
			Validators.maxLength(15)
			],
			[this.propertyStrings.city]: [this.changeModel.city,
			Validators.maxLength(50)
			],
			[this.propertyStrings.country]: [this.changeModel.country]
		}, { validators: [this.countryCodePhoneValidator, this.addressValidator] });

		super.ngOnInit();
	}

	private organizationNumberValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.ORGANIZATION_NUMBER).test(control.value)) {
			return { organizationNumber: true };
		} else {
			return null;
		}
	}

	private emailValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.EMAIL).test(control.value)) {
			return { email: true };
		} else {
			return null;
		}
	}

	private countryCodePhoneValidator(form: FormGroup): ValidationErrors {
		const countryCode = form.get('countryCode');
		const phoneNumber = form.get('phoneNumber');

		if (countryCode.value && !phoneNumber.value) {
			phoneNumber.setErrors({ required: true });
			phoneNumber.markAsTouched();
		} else if (phoneNumber.value && !countryCode.value) {
			countryCode.setErrors({ required: true });
			countryCode.markAsTouched();
		} else if (!countryCode.value && !phoneNumber.value) {
			countryCode.setErrors(null);
			phoneNumber.setErrors(null);
		}
		return null;
	}

	private countryCodeTriggerPhoneValidator(control: AbstractControl) {
		const form = control.parent;
		if (form && control.value) {
			const phone = form.get('phoneNumber');
			phone.updateValueAndValidity();
		}
	}

	private addressValidator(form: FormGroup): ValidationErrors {
		const streetAddress = form.get('streetAddress');
		const zipCode = form.get('zipCode');
		const city = form.get('city');
		const country = form.get('country');

		if (streetAddress.value && (!zipCode.value || !city.value || !country.value)) {
			if (!zipCode.value) {
				zipCode.setErrors({ required: true });
				zipCode.markAsTouched();
			}
			if (!city.value) {
				city.setErrors({ required: true });
				city.markAsTouched();
			}
			if (!country.value) {
				country.setErrors({ required: true });
				country.markAsTouched();
			}
		} else if (zipCode.value && (!streetAddress.value || !city.value || !country.value)) {
			if (!streetAddress.value) {
				streetAddress.setErrors({ required: true });
				streetAddress.markAsTouched();
			}
			if (!city.value) {
				city.setErrors({ required: true });
				city.markAsTouched();
			}
			if (!country.value) {
				country.setErrors({ required: true });
				country.markAsTouched();
			}
		} else if (city.value && (!streetAddress.value || !zipCode.value || !country.value)) {
			if (!streetAddress.value) {
				streetAddress.setErrors({ required: true });
				streetAddress.markAsTouched();
			}
			if (!zipCode.value) {
				zipCode.setErrors({ required: true });
				zipCode.markAsTouched();
			}
			if (!country.value) {
				country.setErrors({ required: true });
				country.markAsTouched();
			}
		} else if (country.value && (!streetAddress.value || !zipCode.value || !city.value)) {
			if (!streetAddress.value) {
				streetAddress.setErrors({ required: true });
				streetAddress.markAsTouched();
			}
			if (!zipCode.value) {
				zipCode.setErrors({ required: true });
				zipCode.markAsTouched();
			}
			if (!city.value) {
				city.setErrors({ required: true });
				city.markAsTouched();
			}
		} else if (!streetAddress.value && !zipCode.value && !city.value && !country.value) {
			streetAddress.setErrors(null);
			zipCode.setErrors(null);
			city.setErrors(null);
			country.setErrors(null);
		}
		return null;
	}

	private setSelectableCountryCodeItems() {
		this.selectableCountryCodeItems = this.changeModel.selectableCountryCodes.map(countryCode => new FormSelectItem(countryCode.id, `+${countryCode.id}`));
	}

	private setSelectableCountryItems() {
		this.selectableCountryItems = this.changeModel.selectableCountries.map(country => new FormSelectItem(country.id, country.text));
	}
}
