import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Choice } from 'app-inspection/choice/choice';
import { Facility } from 'app-inspection/facility/facility';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'issue-details-facility-tab',
	templateUrl: './issue-details-facility-tab.component.html',
	styleUrls: ['./issue-details-facility-tab.component.less']
})
export class IssueDetailsFacilityTabComponent extends SimpleChangeTabDirective<Facility> implements OnInit {

	selectablePriorityItems: FormSelectItem[] = [];
	selectableGeoFencingItems: FormSelectItem[] = [];

	disabledPriorityLink: string = '';
	priorityIsDisabled: boolean = false;
	disabledGeoFencingLink: string = '';
	geoFencingIsDisabled: boolean = false;

	@Input() choice: Choice;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectablePriorityItems();
		this.setSelectableGeoFencingItems();

		this.priorityIsDisabled = !this.choice.allowPriority;
		this.disabledPriorityLink = this.loggedInUser.isSuperAdmin() ? this.choice.url : '';

		this.disabledGeoFencingLink = this.loggedInUser.canEdit(this.selectedOrganization.id) ? this.changeModel.url : '';
		this.geoFencingIsDisabled = !this.changeModel.hasPosition;

		this.form = this.formBuilder.group({
			[this.propertyStrings.issueDetails]: [
				this.changeModel?.issueDetails,
				[
					Validators.required,
					Validators.maxLength(500)
				]
			],
			[this.propertyStrings.priority]: [
				this.changeModel?.priority,
				Validators.required
			],
			[this.propertyStrings.geoControlled]: [
				this.changeModel?.geoControlled,
				Validators.required
			],
		});
		super.ngOnInit();
	}

	handleMediaCollectionChange(collection: MediaWidgetItem[], object: Facility) {
		object.issueMedia = collection;
	}


	private setSelectablePriorityItems() {
		this.selectablePriorityItems = [
			new FormSelectItem(false, this.translationService.instant(StringUtils.NO)),
			new FormSelectItem(true, this.translationService.instant(StringUtils.YES))
		];
	}

	private setSelectableGeoFencingItems() {
		this.selectableGeoFencingItems = [
			new FormSelectItem(false, this.translationService.instant(StringUtils.NO)),
			new FormSelectItem(true, this.translationService.instant(StringUtils.YES))
		];
	}
}
