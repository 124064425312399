import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AdditionalInfoComponent } from './additional-info.component';
import { DotsLoaderModule } from '../dots-loader/dots-loader.module';
import { AdditionalInfoHandlerComponent } from './additional-info-handler/additional-info-handler.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EntityModule } from 'app-inspection/entity/entity.module';
import { PipesModule } from '../pipes/pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		ModalModule,
		DotsLoaderModule,
		EntityModule,
		PipesModule,
		NgSelectModule,
		FormsModule
	],
	declarations: [
		AdditionalInfoComponent,
		AdditionalInfoHandlerComponent
	],
	exports: [
		AdditionalInfoComponent
	]
})
export class AdditionalInfoModule { }
