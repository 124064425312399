import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCrudDirective } from 'app-core/shared-core/simple-components/crud/simple-crud.directive';
import { SwalConfig } from 'app-core/shared-core/swal/swal-config.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ACCEPTED_MEDIA_TYPES, FileUtils } from '../../shared-core/tools/file-utils';
import { MediaUtils } from '../../shared-core/tools/media-utils';
import { MediaResolver } from '../resolver/media-resolver';
import { MediaType, MediaWidgetItem } from './item/media-widget-item';
import { MediaWidgetItemViewerComponent } from './item/viewer/media-widget-item-viewer.component';

@Component({
	selector: 'media-widget',
	templateUrl: 'media-widget.component.html',
	styleUrls: ['media-widget.component.less']
})
export class MediaWidgetComponent extends SimpleCrudDirective<MediaWidgetItem> implements OnInit {

	media = MediaUtils;
	acceptedMediaTypes: string;

	mediaWidgetBodyId = Utils.getRandomNumberString();

	@Input() collection: MediaWidgetItem[] = [];
	@Input() allowMultipleUpload: boolean = true;
	@Input() allowFileDrop: boolean = true;
	@Input() fileSizeLimit: number = FileUtils.mbToBytes(10);
	@Input() onlyImageUpload: boolean;
	@Input() onlyPdfUpload: boolean;
	@Input() canToggleDefault: boolean = true;
	@Input() viewOnly: boolean;
	@Input() itemSize: number = 120;
	@Input() infoText: string;
	@Input() disabled: boolean;
	@Input() hideAddButton: boolean;
	@Input() modifiedDummyText: string = null;
	@Input() useHeaderInBottom: boolean = false;

	@Output() onCollectionChange = new EventEmitter<MediaWidgetItem[]>();
	@Output() onCollectionAdd = new EventEmitter<MediaWidgetItem[]>();
	@Output() onCollectionRemove = new EventEmitter<MediaWidgetItem[]>();

	@ViewChild('dropHelper', { read: ElementRef }) dropHelperEl: ElementRef;
	@ViewChild('fileInput', { read: ElementRef }) fileInputEl: ElementRef;

	get dropHelper(): HTMLDivElement {
		return this.dropHelperEl.nativeElement;
	}

	get fileInput(): HTMLDivElement {
		return this.fileInputEl.nativeElement;
	}

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private mediaResolver: MediaResolver) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		if (this.onlyImageUpload) {
			this.acceptedMediaTypes = ACCEPTED_MEDIA_TYPES.IMAGE;
		} else if (this.onlyPdfUpload) {
			this.acceptedMediaTypes = ACCEPTED_MEDIA_TYPES.PDF;
		} else {
			this.acceptedMediaTypes = [ACCEPTED_MEDIA_TYPES.IMAGE, ACCEPTED_MEDIA_TYPES.PDF].join(',');
		}
	}

	openItem(item: MediaWidgetItem) {
		this.bsModalRef = this.modalService.show(
			MediaWidgetItemViewerComponent,
			{
				initialState: {
					item: item,
					collection: this.collection
				},
				...ConfigUtils.MODAL_CONFIG_X_LARGE
			}
		);

		const component = this.bsModalRef.content;
		this.subscriptions.add(
			component.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(wasClosed => {
					if (wasClosed) {
						this.closeModal();
					}
				})
		);
	}

	toggleDefault(item: MediaWidgetItem) {
		this.collection.forEach(mediaListItem => {
			mediaListItem.default = false;
		});
		item.default = true;
		this.onCollectionChange.emit(this.collection);
	}

	downloadItem(mediaItem: MediaWidgetItem) {
		const url = this.mediaResolver.resolve(mediaItem.url);
		window.open(url, '_blank');
	}

	removeItem(mediaItem: MediaWidgetItem) {
		this.removeFromCollection(mediaItem);
	}

	handleFileChange(event: any) {
		const inputElement = (<HTMLInputElement>event.target);
		const files = inputElement.files;
		this.uploadFiles(files);
		inputElement.value = '';
	}

	handleDragOver(event: DragEvent) {
		event.preventDefault();
		if (!this.allowFileDrop) { return; }
		this.dropHelper.classList.add('is-visible');
	}

	handleDragLeave(event: DragEvent) {
		event.preventDefault();
		if (!this.allowFileDrop) { return; }

		// We need some calculation in order to only do stuff when we leave the actual dropzone.
		const rect = document.getElementById(this.mediaWidgetBodyId).getBoundingClientRect();
		if (event.clientY < rect.top
			|| event.clientY >= rect.bottom
			|| event.clientX < rect.left
			|| event.clientX >= rect.right) {
			this.dropHelper.classList.remove('is-visible');
		}
	}

	handleFileDrop(event: DragEvent) {
		event.preventDefault();
		if (!this.allowFileDrop) { return; }
		this.dropHelper.classList.remove('is-visible');
		this.uploadFiles(event.dataTransfer.files);
	}

	uploadFiles(files: FileList) {
		const validFiles: File[] = [];
		const errorMessages: string[] = [];

		Array.prototype.forEach.call(files, (file: File) => {
			const error = this.validateFile(file);
			if (error) {
				errorMessages.push(error);
			} else {
				validFiles.push(file);
			}
		});

		const processValidFiles = () => {
			this.addToCollection(validFiles.map(file => new MediaWidgetItem({
				id: Utils.getRandomNumberString(),
				title: file.name,
				mediaType: FileUtils.isPDF(file) ? MediaType.PDF : MediaType.Image,
				blob: file
			})));
		};

		new Promise((resolve) => {
			// Show validation message
			if (errorMessages.length) {
				const uniqueErrors = Utils.getUniqueEntriesOnly(...errorMessages);
				Swal.fire(new SwalConfig(this.translationService).getBlank({
					html: uniqueErrors.join('<br/><br/>'),
					showCancelButton: false,
					confirmButtonText: this.translationService.instant('Ok')
				}))
					.then(result => {
						if (result.value) {
							resolve(null);
						}
					});
			} else {
				resolve(null);
			}
		})
			.then(() => {
				if (validFiles.length) {
					processValidFiles();
				}
			});
	}

	private validateFile(file: File) {
		let error = '';

		if (this.onlyImageUpload) {
			if (!FileUtils.isImage(file)) {
				error = this.translationService.instant('FileValidationNotValidImage');
			}
		} else if (this.onlyPdfUpload) {
			if (!FileUtils.isPDF(file)) {
				error = this.translationService.instant('FileValidationNotValidPdf');
			}
		} else {
			if (!FileUtils.isImage(file) && !FileUtils.isPDF(file)) {
				error = this.translationService.instant('FileValidationNotValid');
			}
		}

		if (!FileUtils.isAllowedFileSize(file, this.fileSizeLimit)) {
			if (!error) {
				const fileSizeMb = Math.round(FileUtils.bytesToMb(this.fileSizeLimit));
				error = this.translationService.instant('FileValidationExceedsTheLimit', { 0: file.name, 1: fileSizeMb, 2: 'MB' });
			}
		}

		return error;
	}

	private addToCollection(itemsToAdd: MediaWidgetItem[]) {
		this.collection.push(...itemsToAdd);

		if (this.canToggleDefault) {
			// If no default image, find and choose the first one.
			const existingDefaultMedia = this.collection.filter(item => item.isImage).some(item => item.default);
			if (!existingDefaultMedia) {
				const firstImage = this.collection.find(item => item.isImage);
				if (firstImage) {
					this.toggleDefault(firstImage);
				}
			}
		}

		this.onCollectionChange.emit(this.collection);
		this.onCollectionAdd.emit(itemsToAdd);
	}

	private removeFromCollection(itemToRemove: MediaWidgetItem) {
		this.collection = this.collection.filter(item => item.id !== itemToRemove.id);

		if (this.canToggleDefault) {
			// If it was the current default image, find and choose the next one.
			if (itemToRemove.default && this.collection.length) {
				const nextImage = this.collection.find(item => {
					return item.isImage;
				});
				if (nextImage) {
					this.toggleDefault(nextImage);
				}
			}
		}

		this.onCollectionChange.emit(this.collection);
		this.onCollectionRemove.emit([itemToRemove]);
	}

	private closeModal() {
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
	}
}
