<simple-crud-modal
	[titleText]="('Facility' | translate) + (modifiedModel?.name ? ' ─ ' + modifiedModel?.name : '')"
	[closeText]="'Close' | translate"
	[submitText]="(isEdit ? 'Update' : 'Create') | translate"
	[disableSubmit]="!everythingIsValid() || !hasUnsavedChanges()"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab *ngIf="isEdit"
				[customClass]="'valid'"
				(selectTab)="setTabIndexInUrl(1)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-search'"
						[title]="'Overview' | translate"
						[helpText]="'FacilityViewDetailsHelpText' | translate">
					</simple-tab-heading>
				</ng-template>
				<view-details-tab
					[viewModel]="modifiedModel">
				</view-details-tab>
			</tab>
			<tab *ngIf="true"
				[customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(isEdit ? 2 : 1)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-card-text'"
							[title]="'Details' | translate"
							[helpText]="isEdit ? ('FacilityChangeDetailsEditHelpText' | translate) : 'FacilityChangeDetailsHelpText' | translate">
						</simple-tab-heading>
					</ng-template>
					<change-details-tab
						[changeModel]="modifiedModel"
						(onValueChange)="handleValueChanges()">
					</change-details-tab>
			</tab>
			<tab #handleTab="tab" *ngIf="true"
				[customClass]="handleEntitiesIsValid() ? 'valid' : handleEntitiesHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(isEdit ? 3 : 2)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'ii-entities'"
							[title]="'Entities' | translate"
							[helpText]="isEdit ? ('FacilityHandleEntitiesEditHelpText' | translate) : ('FacilityHandleEntitiesHelpText' | translate)"
							[displayCount]="modifiedModel.entities.length">
						</simple-tab-heading>
					</ng-template>
					<handle-entities-tab
						[handleItems]="modifiedModel.entities"
						[headerSubtitleText]="'AddedEntitiesSubtitleFacility' | translate"
						[facilityId]="isEdit ? modifiedModel.id : 'useNull'"
						[wasSelected]="handleTab.active"
						[usePopoverRemoveButton]="true"
						(onDeleteEntities)="deleteEntities($event)"
						(onModelsChange)="handleEntities($event)">
					</handle-entities-tab>
			</tab>
			<tab *ngIf="true"
				[customClass]="handleMediaIsValid() ? 'valid' : handleMediaHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(isEdit ? 4 : 3)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-files'"
						[title]="'Media' | translate"
						[helpText]="isEdit ? ('FacilityHandleMediaEditHelpText' | translate) : ('FacilityHandleMediaHelpText' | translate)"
						[displayCount]="modifiedModel.media.length">
					</simple-tab-heading>
				</ng-template>
				<handle-media-tab
					[handleItems]="modifiedModel.media"
					[headerSubtitleText]="'AddedMediaSubtitleFacility' | translate"
					[canToggleDefault]="true"
					(onModelsChange)="modifiedModel.media = $event">
				</handle-media-tab>
			</tab>
		</tabset>
</simple-crud-modal>
