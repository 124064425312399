import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DfResponsIntegration, IntegrationTypes } from '../../integration';
import { IntegrationService } from '../../integration.service';
import { ChangeDfResponsDetailsTabComponent } from './tabs/change-df-respons-details-tab.component';

@Component({
	templateUrl: './create-edit-df-respons.component.html'
})
export class CreateEditDfResponsComponent extends SimpleCreateEditModalDirective<DfResponsIntegration> implements OnInit {

	@ViewChild(ChangeDfResponsDetailsTabComponent) changeDetailsTabComponent: ChangeDfResponsDetailsTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private integrationService: IntegrationService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		if (this.isEdit) {
			this.initialModel = new DfResponsIntegration(this.initialModel);
			this.modifiedModel = new DfResponsIntegration(this.getUniqueVariant(this.initialModel));

			setTimeout(() => {
				this.triggerAllValidation();
			}, 0);
		} else {
			this.initialModel = new DfResponsIntegration({
				email: '',
				organizationId: this.selectedOrganization.id,
				type: IntegrationTypes.DfRespons,
				active: true
			});

			this.modifiedModel = new DfResponsIntegration(this.getUniqueVariant(this.initialModel));
		}
	}

	changeDetailsIsValid() {
		return this.changeDetailsTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeDetailsTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.integrationService.update(this.modifiedModel.toPayloadObject())
				: await this.integrationService.create(this.modifiedModel.toPayloadObject());

			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeDetailsTabComponent.setServerErrors(this.serverErrors);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeDetailsTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: DfResponsIntegration) {
		return new DfResponsIntegration(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.email !== this.initialModel.email
			|| this.modifiedModel.active !== this.initialModel.active;
	}

	hasUnsavedChanges() {
		return this.modifiedModel && this.detailsHasChanged();
	}
}
