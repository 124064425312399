import { Component, ElementRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AdministrationService } from 'app-core/administration/administration.service';
import { AuthService } from 'app-core/auth/auth.service';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { SimpleListDirective } from 'app-core/shared-core/simple-components/list/simple-list.directive';
import { SimpleTableRowAction, SimpleTableRowActionDelete, SimpleTableRowActionEdit } from 'app-core/shared-core/simple-components/list/table/body/simple-table-row-action';
import { SimpleTableCategoriesColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-categories-column';
import { SimpleTableChoicesColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-choices-column';
import { EventsContent, SimpleTableEventsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-events-column';
import { SimpleTableStatusColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-status-column';
import { SimpleTableTextColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-text-column';
import { SimpleTableEmptyState } from 'app-core/shared-core/simple-components/list/table/empty-state/simple-table-empty-state';
import { SimpleFilterHelperChoice } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-choice';
import { SimpleFilterHelperFacility } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-facility';
import { SimpleFilterHelperSchedule } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-schedule';
import { SimpleFilterHelperTemplateType } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-template-type';
import { SimpleFilterHelperUser } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-user';
import { SimpleFilterInput, SimpleFilterInputType } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-input-settings';
import { SimpleFilterListModalInput } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-list-modal-input-settings';
import { SimpleFilterInputItem } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-input-item';
import { SimpleRetainService } from 'app-core/shared-core/simple-components/list/table/filter/simple-retain.service';
import { SimpleTableHeaderAction, SimpleTableHeaderActionDelete } from 'app-core/shared-core/simple-components/list/table/head/simple-table-header-action';
import { TextContent } from 'app-core/shared-core/simple-components/various/text-content/simple-text-content.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { User } from 'app-core/user/user';
import { Assignment } from 'app-inspection/assignment/assignment';
import { AssignmentFilter, AssignmentService } from 'app-inspection/assignment/assignment.service';
import { DeleteAssignmentsComponent } from 'app-inspection/assignment/delete-assignments/delete-assignments.component';
import { DetailedAssignmentComponent } from 'app-inspection/assignment/detailed-assignment/detailed-assignment.component';
import { GenerateReportComponent } from 'app-inspection/assignment/report/generated/generate-report.component';
import { StaticReportComponent } from 'app-inspection/assignment/report/static/static-report.component';
import { environment } from 'environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SimpleFilterConfig, SortObject } from '../../../../../app-core/shared-core/simple-components/list/table/filter/simple-filter-config';
import { SimpleTableConfig } from '../../../../../app-core/shared-core/simple-components/list/table/simple-table-config';

@Component({
	selector: 'list-assignment-scheduled-archived',
	templateUrl: '../../../../../app-core/shared-core/simple-components/list/simple-list-shared-template.html',
	providers: [SimpleFilterHelperTemplateType, SimpleFilterHelperFacility, SimpleFilterHelperSchedule, SimpleFilterHelperChoice, SimpleFilterHelperUser]
})
export class ListAssignmentScheduledArchivedComponent extends SimpleListDirective<Assignment> {

	// Override filterObject to match backend.
	readonly filterObject = new AssignmentFilter();

	bsModalRef: BsModalRef<any>;
	wasRowAction: boolean = false;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected sanitizer: DomSanitizer,
		protected assignmentService: AssignmentService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected retainService: SimpleRetainService,
		protected elementRef: ElementRef,
		protected viewContainerRef: ViewContainerRef,
		private simpleFilterHelperTemplateType: SimpleFilterHelperTemplateType,
		private simpleFilterHelperFacility: SimpleFilterHelperFacility,
		private simpleFilterHelperSchedule: SimpleFilterHelperSchedule,
		private simpleFilterHelperChoice: SimpleFilterHelperChoice,
		private simpleFilterHelperUser: SimpleFilterHelperUser,
		private administrationService: AdministrationService) {
		super(
			authService,
			modalService,
			toastrService,
			translationService,
			assignmentService,
			route,
			router,
			retainService,
			elementRef,
			viewContainerRef,
			RoutesUtils.assignment
		);
	}

	protected configureListActions() { }

	protected async configureTableFilter(config: SimpleFilterConfig<Assignment>) {
		this.filterObject.context = RoutesUtils.scheduledArchived;
		config.hideSearch = true;

		config.setInitialFacets(
			new KeyValuePair(StringUtils.IS_COMPLETED_KEY, 'true'),
			new KeyValuePair(StringUtils.IS_EXPIRED_KEY, 'true'),
			new KeyValuePair(StringUtils.IS_MEASURE_KEY, 'false'),
			new KeyValuePair(StringUtils.IS_MANUAL_MEASURE_KEY, 'false'),
			new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, 'Or')
		);

		const templateTypesKey = StringUtils.TEMPLATE_TYPES_KEY;
		const facilitiesKey = StringUtils.FACILITIES_KEY;
		const categoriesKey = StringUtils.CATEGORIES_KEY;
		const schedulesKey = StringUtils.SCHEDULES_KEY;
		const choicesKey = StringUtils.CHOICES_KEY;
		const completedByKey = StringUtils.COMPLETED_BY_KEY;
		const archivedBetweenKey = StringUtils.ARCHIVED_BETWEEN_KEY;
		const statusKey = StringUtils.STATUS_KEY;
		if (!this.isInModal) {
			this.retainService.setCurrentRetainEntries({
				search: null,
				sort: null,
				[templateTypesKey]: null,
				[facilitiesKey]: null,
				[categoriesKey]: null,
				[schedulesKey]: null,
				[choicesKey]: null,
				[completedByKey]: null,
				[archivedBetweenKey]: null,
				[statusKey]: null
			});
		}

		try {
			const [templateTypes, facilities, categories, schedules, choices, users] = await Promise.all([
				this.assignmentService.getTemplateTypes(this.selectedOrganization.id),
				this.assignmentService.getFacilities(),
				this.assignmentService.getCategories(this.selectedOrganization.id),
				this.assignmentService.getSchedules(this.selectedOrganization.id),
				this.assignmentService.getChoices(this.selectedOrganization.id),
				this.assignmentService.getUsers(this.selectedOrganization.id)
			]);

			const convertedTemplateTypes = templateTypes.filter(templateType => !templateType.isManualMeasure).map(templateType => new SimpleFilterInputItem(templateType.id, templateType.name));
			const convertedFacilities = facilities.map(facility => new SimpleFilterInputItem(facility.id, facility.name));
			const convertedCategories = categories.map(category => new SimpleFilterInputItem(category.id, category.name));
			const convertedSchedules = schedules.map(schedule => new SimpleFilterInputItem(schedule.id, schedule.name));
			const convertedChoices = choices.map(choice => new SimpleFilterInputItem(choice.id, choice.value));
			const convertedUsers = users.map(completedByUser => new SimpleFilterInputItem(completedByUser.id, completedByUser.email));

			config.setFilterInputs(
				new SimpleFilterListModalInput(
					templateTypesKey,
					this.translationService.instant(templateTypesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(templateTypesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(templateTypesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperTemplateType.openListModal(filterInput, templateTypes, this);
					},
					convertedTemplateTypes
				),
				new SimpleFilterListModalInput(
					facilitiesKey,
					this.translationService.instant(facilitiesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(facilitiesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(facilitiesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperFacility.openListModal(filterInput, facilities, this);
					},
					convertedFacilities
				),
				new SimpleFilterInput(
					categoriesKey,
					this.translationService.instant(categoriesKey),
					SimpleFilterInputType.MultiSelectWithSearch,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(categoriesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(categoriesKey, ''));
						}
						return keyValuePairs;
					},
					...convertedCategories
				),
				new SimpleFilterListModalInput(
					schedulesKey,
					this.translationService.instant(schedulesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(schedulesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(schedulesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperSchedule.openListModal(filterInput, schedules, this);
					},
					convertedSchedules
				),
				new SimpleFilterListModalInput(
					choicesKey,
					this.translationService.instant(choicesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(choicesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(choicesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperChoice.openListModal(filterInput, choices, this);
					},
					convertedChoices
				),
				new SimpleFilterListModalInput(
					completedByKey,
					this.translationService.instant(completedByKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(completedByKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(completedByKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperUser.openListModal(filterInput, users, this);
					},
					convertedUsers
				),
				new SimpleFilterInput(
					archivedBetweenKey,
					this.translationService.instant(archivedBetweenKey),
					SimpleFilterInputType.DateRangeSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(archivedBetweenKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(archivedBetweenKey, ''));
						}
						return keyValuePairs;
					}
				),
				new SimpleFilterInput(
					statusKey,
					this.translationService.instant(statusKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(statusKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(statusKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.COMPLETED,
						this.translationService.instant(StringUtils.COMPLETED)
					),
					new SimpleFilterInputItem(
						StringUtils.EXPIRED,
						this.translationService.instant(StringUtils.EXPIRED)
					)
				)
			);

			if (!this.isInModal) {
				// Needs to be called here due to the data fetch delay.
				this.setSelectedFilterItems();
			}

		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}

	protected configureTableSort(config: SimpleFilterConfig<Assignment>) {
		config.setSortObjects(
			new SortObject(
				'AssignmentTemplate.TemplateType.Name' as keyof Assignment,
				this.translationService.instant('TemplateType')
			),
			new SortObject(
				'AssignmentTemplate.Facility.Name' as keyof Assignment,
				this.translationService.instant('Facility'),
			),
			new SortObject(
				'Schedule.Name' as keyof Assignment,
				this.translationService.instant('Schedule'),
			),
			new SortObject(
				'Completed, DueDate' as keyof Assignment,
				this.translationService.instant('Archived'),
				true,
				true
			)
		);
	}

	protected configureTableColumns(config: SimpleTableConfig<Assignment>) {
		config.setColumns(
			new SimpleTableTextColumn(
				this.translationService.instant('TemplateType'),
				(item) => new TextContent(
					item.templateType?.name,
					item.facility?.name,
					item.templateType && this.loggedInUser.isSuperAdmin()
						? `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${item.templateType.url}`
						: '',
					item.facility && this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)
						? `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${item.facility.url}`
						: ''
				),
				this.sanitizer,
				this.viewContainerRef,
				this.translationService.instant('Facility')
			),
			new SimpleTableCategoriesColumn(
				this.translationService.instant('Categories'),
				(item) => item.categories,
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableTextColumn(
				this.translationService.instant('Schedule'),
				(item) => new TextContent(
					item.schedule?.name,
					'',
					item.schedule && this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)
						? `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${item.schedule.url}`
						: '',
					''
				),
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableChoicesColumn(
				this.translationService.instant('Choices'),
				(item) => item.choices,
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableTextColumn(
				this.translationService.instant('CompletedBy'),
				(item) => new TextContent(
					item.completedByUser
						? item.completedByUser.getCurrentDeleted(this.selectedOrganization.id)
							? ''
							: `${item.completedByUser.firstname} ${item.completedByUser.lastname}`
						: '',
					item.completedByUser
						? item.completedByUser.getCurrentDeleted(this.selectedOrganization.id)
							? this.translationService.instant('DeletedUserOn', { date: Utils.formatDate(item.completedByUser.getCurrentDeleted(this.selectedOrganization.id), DEFAULT_DISPLAY_DATE_FORMAT) })
							: item.completedByUser.email
						: '',
					item.completedByUser
						? `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${this.getUserUrl(item.completedByUser)}`
						: '',
					''

				),
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableEventsColumn(
				this.translationService.instant('Events'),
				(item) => [
					new EventsContent(`${this.translationService.instant(item.completed ? 'Completed' : 'Expired')} ${Utils.getFormattedDateStringFromString(item.archived, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.archived, item.completed ? 'bi-clipboard-check' : 'bi-hourglass-bottom')
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableStatusColumn(
				this.translationService.instant('Status'),
				(item) => item.status,
				this.sanitizer,
				this.viewContainerRef,
				''
			)
		);
	}

	private getUserUrl(user: User) {
		const pendingUrl = `${RoutesUtils.users}?${RoutesUtils.user}=${RoutesUtils.modalPrefixValueResendEmail}_${user.id}&tab=1`;
		return user.getCurrentStatus(this.selectedOrganization.id) === StringUtils.PENDING ? pendingUrl : user.id === this.loggedInUser.id ? RoutesUtils.profile : user.url;
	}

	protected configureTableActions(config: SimpleTableConfig<Assignment>) {

		// HEADER
		config.setHeaderActions(
			new SimpleTableHeaderAction(
				this.translationService.instant('GenerateSummaryReport'),
				StringUtils.icons.generateErrorReport,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueBulkGenerateReport}_${selectedIds.join()}`);
				}
			),
			new SimpleTableHeaderAction(
				this.translationService.instant('GetAssignmentReport'),
				StringUtils.icons.download,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.wasRowAction = false;
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueBulkGetReport}_${selectedIds.join()}`);
				}
			),
			new SimpleTableHeaderActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${selectedIds.join()}`);
				}
			)
		);

		// ROW
		this.hideTableRowActions = false;
		config.setHideRowActions(this.hideTableRowActions);

		config.setRowActions(
			new SimpleTableRowActionEdit(
				this.translationService.instant('Open'),
				StringUtils.icons.handle,
				(row) => {
					this.setAsAffected([row.id]);
					this.setCrudParams(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('GetAssignmentReport'),
				StringUtils.icons.download,
				(row) => {
					this.setAsAffected([row.id]);
					this.wasRowAction = true;
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueGetReport}_${row.id}`);
				},
				(row) => {
					return !row.data.reportUrl;
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('RecreateAssignmentReport'),
				StringUtils.icons.recreateReport,
				(row) => {
					this.setAsAffected([row.id]);
					this.administrationService.openRecreateReportSwal(row.id, this.selectedOrganization.id, () => this.getTableData());
				},
				() => {
					return !this.loggedInUser.isSuperAdmin();
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('RecreateScheduledAssignment'),
				StringUtils.icons.recreateAssignment,
				(row) => {
					this.setAsAffected([row.id]);
					const newDueDate = new Date();
					newDueDate.setDate(new Date().getDate() + (row.data.schedule.daysDelay ? row.data.schedule.daysDelay : 7));
					this.administrationService.openRecreateAssignmentSwal(row.id, newDueDate.toISOString(), () => this.getTableDataAndClearAfterUpdate(row.id));
				},
				() => {
					return !this.loggedInUser.isSuperAdmin();
				}
			),
			new SimpleTableRowActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					}
				},
				(row) => {
					return !this.authService.canEdit();
				}
			)
		);
	}

	private getTableDataAndClearAfterUpdate(rowId) {
		this.selectedIds.delete(rowId);
		this.getTableData();
	}

	protected configureTableEmptyState(config: SimpleTableConfig<Assignment>) {
		config.emptyState = new SimpleTableEmptyState(
			'ScheduledAssignments',
			StringUtils.icons.scheduledAssignment
		);
	}

	protected setPropertiesAndOpenViewObjectsPopover() { }

	protected openModalByActionName(value: string) {
		const [actionName, idString] = value.split('_');
		this.shouldClearAfterUpdate = !this.isInModal && this.isBulkAction(actionName);
		if (actionName === RoutesUtils.modalPrefixValueEdit) {
			this.bsModalRef = this.modalService.show(
				DetailedAssignmentComponent,
				{
					initialState: {
						editModelId: idString,
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_X_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueBulkGenerateReport) {
			this.bsModalRef = this.modalService.show(
				GenerateReportComponent,
				{
					initialState: {
						editModelIds: idString.split(','),
						isArchived: true,
						sort: this.filterObject.sort,
						disableTabIndexUrl: true
					},
					...ConfigUtils.MODAL_CONFIG_LARGE
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueBulkGetReport || actionName === RoutesUtils.modalPrefixValueGetReport) {
			this.bsModalRef = this.modalService.show(
				StaticReportComponent,
				{
					initialState: {
						editModelIds: idString.split(','),
						disableTabIndexUrl: true,
						useAffectableIds: true,
						hideText: this.wasRowAction,
						isMeasure: false
					},
					...ConfigUtils.MODAL_CONFIG_LARGE
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueDelete) {
			this.bsModalRef = this.modalService.show(
				DeleteAssignmentsComponent,
				{
					initialState: {
						deleteModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_SMALL
				}
			);
		}
		this.subscribeToCrudModalContent();
	}

	protected import(file: File) { }

	protected export() { }
}
