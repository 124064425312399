import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { environment } from 'environments/environment';

@Component({
	selector: 'simple-link-button',
	templateUrl: './simple-link-button.component.html',
	styleUrls: ['./simple-link-button.component.less']
})
export class SimpleLinkButtonComponent implements OnInit {

	@Input() url: string;
	@Input() linkText: string;
	@Input() shouldStopPropagation: boolean;
	@Input() shouldDisable: boolean;
	@Input() name: string;

	titleText: string;

	constructor(
		private authService: AuthService,
		private translationService: TranslationService) { }

	ngOnInit() {
		const name = this.name ? `"${this.name}"` : this.translationService.instant('Object').toLowerCase();
		this.titleText = `${this.translationService.instant('Open')} ${name} ${this.translationService.instant('InANewTab').toLowerCase()}`;
	}

	navigateTo($event: MouseEvent) {
		if (!this.shouldDisable) {
			if (this.shouldStopPropagation) {
				$event.stopImmediatePropagation();
			}
			const url = `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${this.url}`;
			window.open(url, '_blank');
		}
	}
}
