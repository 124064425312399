import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { LocalStorageKey, LocalStorageService } from 'app-core/shared-core/local-storage/local-storage.service';
import { SimpleCrudDirective } from 'app-core/shared-core/simple-components/crud/simple-crud.directive';
import { ColorUtils } from 'app-core/shared-core/tools/color-utils';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Assignment } from 'app-inspection/assignment/assignment';
import { AssignmentFilter, AssignmentService } from 'app-inspection/assignment/assignment.service';
import { ChartData, ChartEvent, ChartType } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DashboardConfig, DashboardStorage, FilterItem, FilterObject, LabelObject, StoredFilter } from './classes';
import { DashboardUtils } from './dashboard-utils';
import { AssignmentState, TimeSpan } from './enums';
import { ViewAssignmentsModalComponent } from './view-assignments/view-assignments.component';

@Component({
	templateUrl: './dashboard.component.html',
	styleUrls: ['../landing-page-shared-style.less', './dashboard.component.less']
})
export class DashboardComponent extends SimpleCrudDirective<Assignment> implements AfterViewInit, OnDestroy {

	chartPlugins = [DataLabelsPlugin,];

	pendingCounts: boolean = false;
	pendingItems: boolean = false;

	timeSpanEnum = TimeSpan;
	selectedTimeSpan: TimeSpan = null;

	assignmentStateEnum = AssignmentState;
	selectedAssignmentState: AssignmentState = null;

	currentChartLabels: LabelObject[] = [];

	dashboardUtils = DashboardUtils;

	scheduledAssignmentsExpiringCount: number = 0;
	scheduledAssignmentsCompletedCount: number = 0;
	scheduledAssignmentsExpiredCount: number = 0;
	measureAssignmentsCreatedCount: number = 0;
	measureAssignmentsResolvedCount: number = 0;
	measureAssignmentsClosedCount: number = 0;

	currentItemsFilter: AssignmentFilter;
	currentItemsProperty: keyof Assignment;
	currentfirstLabel: string;
	currentSecondLabel: string;
	currentThirdLabel: string;

	filterObjectScheduledExpiring = new AssignmentFilter([], this.dashboardUtils.filterObjectScheduledExpiringFacets, NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE);
	filterObjectScheduledCompleted = new AssignmentFilter([], this.dashboardUtils.filterObjectScheduledCompletedFacets, NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE);
	filterObjectScheduledExpired = new AssignmentFilter([], this.dashboardUtils.filterObjectScheduledExpiredFacets, NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE);
	filterObjectMeasureCreated = new AssignmentFilter([], this.dashboardUtils.filterObjectMeasureCreatedFacets, NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE);
	filterObjectMeasureResolved = new AssignmentFilter([], this.dashboardUtils.filterObjectMeasureResolvedFacets, NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE);
	filterObjectMeasureClosed = new AssignmentFilter([], this.dashboardUtils.filterObjectMeasureClosedFacets, NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE);

	savedAssignments: Assignment[][] = [];

	private modalCloseSubscription = new Subscription();

	chartType: ChartType = 'bar';

	chartOptions = this.dashboardUtils.chartOptions;

	chartData: ChartData<'bar', number[]> = {
		datasets: [],
		labels: []
	};

	utils = Utils;

	currentTodayValue: string = '';

	filterObjects: FilterObject[] = [];

	filterOpened: boolean = false;

	isSmallScreen: boolean = false;

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	@ViewChild('filterInput') filterInput: ElementRef;

	get hasSelectedFilters() {
		return this.filterObjects.some(filter => filter.hasSelectedItems);
	}

	get selectedFilters() {
		return this.filterObjects.filter(filter => filter.hasSelectedItems);
	}

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private assignmentService: AssignmentService,
		private localStorageService: LocalStorageService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngAfterViewInit() {
		this.removeTypingBorder();
		this.setViewMode(true);
		this.subscriptions.add(
			fromEvent(window, 'resize')
				.pipe(
					debounceTime(NumberUtils.WINDOW_RESIZE_WAIT_TIME),
					takeUntil(this.destroyed$)
				)
				.subscribe(__ => {
					this.setViewMode(this.isSmallScreen !== Utils.isSmallScreenSize());
				})
		);
		this.chartOptions.scales['x'].ticks.color = (context) => {
			return this.dashboardUtils.isCurrent(context, this.currentTodayValue) ? ColorUtils.GREEN_COLOR : ColorUtils.MAIN_FONT_COLOR;
		}
		this.chartOptions.scales['y'].ticks.color = (context) => {
			return this.dashboardUtils.isCurrent(context, this.currentTodayValue) ? ColorUtils.GREEN_COLOR : ColorUtils.MAIN_FONT_COLOR;
		}
		this.chart.render();

		this.pendingCounts = true;
		this.pendingItems = true;
		const [templateTypes, facilities, categories, schedules, groups, assignees, choices] = await Promise.all([
			this.assignmentService.getTemplateTypes(this.selectedOrganization.id),
			this.assignmentService.getFacilities(),
			this.assignmentService.getCategories(this.selectedOrganization.id),
			this.assignmentService.getSchedules(this.selectedOrganization.id),
			this.assignmentService.getUserGroups(this.selectedOrganization.id),
			this.assignmentService.getUsers(this.selectedOrganization.id),
			this.assignmentService.getChoices(this.selectedOrganization.id)
		]);

		this.filterObjects = [
			new FilterObject(
				this.translationService.instant('TemplateTypes'),
				templateTypes.map(item => new FilterItem(item.id, item.name)),
				'TemplateTypes'
			),
			new FilterObject(
				this.translationService.instant('Facilities'),
				facilities.map(item => new FilterItem(item.id, item.name)),
				'Facilities'
			),
			new FilterObject(
				this.translationService.instant('Categories'),
				categories.map(item => new FilterItem(item.id, item.name)),
				'Categories'
			),
			new FilterObject(
				this.translationService.instant('Schedules'),
				schedules.map(item => new FilterItem(item.id, item.name)),
				'Schedules'
			),
			new FilterObject(
				this.translationService.instant('Groups'),
				groups.map(item => new FilterItem(item.id, item.name)),
				'Groups'
			),
			new FilterObject(
				this.translationService.instant('Assignees'),
				assignees.map(item => new FilterItem(item.id, item.fullName ? item.fullName : item.email)),
				'Assignees'
			),
			new FilterObject(
				this.translationService.instant('Choices'),
				choices.map(item => new FilterItem(item.id, item.value)),
				'Choices'
			)
		];

		this.getStoredValuesOrDefault();

		await this.fetchAllCounts();
		this.getItems();
	}

	private setViewMode(shouldUpdateChart: boolean) {
		if (Utils.isSmallScreenSize()) {
			this.chartOptions.scales['y'].ticks.display = true;
			this.chartOptions.scales['x'].ticks.display = false;
			this.chartOptions.indexAxis = 'y';
			this.chartOptions.plugins.datalabels.align = 'left';
			this.isSmallScreen = true;
		} else {
			this.chartOptions.scales['y'].ticks.display = false;
			this.chartOptions.scales['x'].ticks.display = true;
			this.chartOptions.indexAxis = 'x';
			this.chartOptions.plugins.datalabels.align = 'bottom';
			this.isSmallScreen = false;
		}

		if (shouldUpdateChart) {
			this.chart.render();
		}
	}

	async removeFilter(selectedFilter: FilterObject) {
		const filterObject = this.filterObjects.find(filterObject => filterObject.title === selectedFilter.title);
		if (filterObject) {
			filterObject.items.forEach(item => item.selected = false);
			this.doRemoveFilter(filterObject.facet);
			this.setStoredDashboardConfig();
			this.clearAndFetch();
		}
	}

	async handleFilterClick(item: FilterItem, filterObject: FilterObject) {
		item.selected = !item.selected;

		const values = filterObject.items.filter(item => item.selected).map(value => value.id).join();

		if (values) {
			this.doAddFilter(filterObject.facet, values);
		} else {
			this.doRemoveFilter(filterObject.facet);
		}

		this.setStoredDashboardConfig();
		this.clearAndFetchWithDebounce();
	}

	private clearAndFetchWithDebounce = Utils.debounce(() => this.clearAndFetch(), NumberUtils.DEFAULT_DEBOUNCE_TIME);

	private async clearAndFetch() {
		this.clearChartData();
		await this.fetchAllCounts();
		this.setCurrentItemsVariables();
		this.getItems();
	}

	private getStoredConfig() {
		const storedStorage = this.localStorageService.getItem(LocalStorageKey.DashboardStorage) as DashboardStorage;
		const storedStorageInstance = new DashboardStorage(storedStorage ? storedStorage : {});
		return storedStorageInstance.dashboardConfigs.find(storedConfig => storedConfig.organizationId === this.selectedOrganization.id && storedConfig.userId === this.loggedInUser.id);

	}

	private getStoredValuesOrDefault() {
		const storedConfig = this.getStoredConfig();
		if (storedConfig) {
			const storedConfigInstance = new DashboardConfig(storedConfig);

			if (storedConfigInstance.dashboardFilter) {
				storedConfigInstance.dashboardFilter.keyValuePairs.forEach(pair => {
					const filterObject = this.filterObjects.find(filterObject => filterObject.facet === pair.key);
					if (filterObject) {
						this.doAddFilter(pair.key, pair.value);
						filterObject.items.forEach(item => item.selected = pair.value.includes(item.id))
					}
				});
			}

			if (storedConfigInstance.dashboardTimespan) {
				this.setTimeSpan(storedConfigInstance.dashboardTimespan);
			} else {
				this.setTimeSpan(this.timeSpanEnum.ThisWeek);
			}

			if (storedConfigInstance.dashboardAssignmentState) {
				this.setAssignmentState(storedConfigInstance.dashboardAssignmentState);
			} else {
				this.setAssignmentState(this.assignmentStateEnum.CompletedAssignments);
			}
		} else {
			this.setTimeSpan(this.timeSpanEnum.ThisWeek);
			this.setAssignmentState(this.assignmentStateEnum.CompletedAssignments);
		}

		// Update chart
		this.prepareFiltersAndLabels();
		this.setCurrentItemsVariables();
	}


	private doAddFilter(facet: string, values: string) {
		const keyValuePair = new KeyValuePair(facet, values);
		this.filterObjectScheduledExpiring.addFacet(keyValuePair);
		this.filterObjectScheduledCompleted.addFacet(keyValuePair);
		this.filterObjectScheduledExpired.addFacet(keyValuePair);
		this.filterObjectMeasureCreated.addFacet(keyValuePair);
		this.filterObjectMeasureResolved.addFacet(keyValuePair);
		this.filterObjectMeasureClosed.addFacet(keyValuePair);
	}

	private doRemoveFilter(facet: string) {
		const keyValuePair = new KeyValuePair(facet, '');
		this.filterObjectScheduledExpiring.removeFacet(keyValuePair);
		this.filterObjectScheduledCompleted.removeFacet(keyValuePair);
		this.filterObjectScheduledExpired.removeFacet(keyValuePair);
		this.filterObjectMeasureCreated.removeFacet(keyValuePair);
		this.filterObjectMeasureResolved.removeFacet(keyValuePair);
		this.filterObjectMeasureClosed.removeFacet(keyValuePair);
	}


	private setStoredDashboardConfig() {
		const filterKeyValuePairs = [];
		const filterObjectsWithSelectedItems = this.filterObjects.filter(filterObject => filterObject.hasSelectedItems);
		filterObjectsWithSelectedItems.forEach(filterObject => {
			filterKeyValuePairs.push(
				new KeyValuePair(filterObject.facet, filterObject.selectedItemIds)
			);
		});
		const storedFilter = new StoredFilter({
			keyValuePairs: filterKeyValuePairs
		});

		const dashboardConfig = new DashboardConfig({
			organizationId: this.selectedOrganization.id,
			userId: this.loggedInUser.id,
			dashboardFilter: storedFilter,
			dashboardTimespan: this.selectedTimeSpan,
			dashboardAssignmentState: this.selectedAssignmentState
		});

		const storedStorage = this.localStorageService.getItem(LocalStorageKey.DashboardStorage) as DashboardStorage;
		const storedStorageInstance = new DashboardStorage(storedStorage ? storedStorage : {});

		const storedConfig = storedStorageInstance.dashboardConfigs.find(storedConfig => storedConfig.organizationId === this.selectedOrganization.id && storedConfig.userId === this.loggedInUser.id);
		if (storedConfig) {
			Object.assign(storedConfig, dashboardConfig);
			this.localStorageService.setItem(LocalStorageKey.DashboardStorage, storedStorageInstance);
		} else {
			storedStorageInstance.dashboardConfigs.push(dashboardConfig);
			this.localStorageService.setItem(LocalStorageKey.DashboardStorage, storedStorageInstance);
		}
	}

	async changeTimeSpan(timeSpan: TimeSpan) {
		if (timeSpan !== this.selectedTimeSpan) {
			this.setTimeSpan(timeSpan);
			this.clearChartData();
			this.prepareFiltersAndLabels();
			this.setStoredDashboardConfig();
			await this.fetchAllCounts();
			this.setCurrentItemsVariables();
			this.getItems();
		}
	}

	async changeAssignmentState(assignmentState: AssignmentState) {
		if (assignmentState !== this.selectedAssignmentState) {
			this.setAssignmentState(assignmentState);
			this.clearChartData();
			this.setCurrentItemsVariables();
			this.setStoredDashboardConfig();
			this.getItems();
		}
	}

	private setTimeSpan(timeSpan: TimeSpan) {
		this.selectedTimeSpan = timeSpan;
	}

	private prepareFiltersAndLabels() {
		this.currentChartLabels = [];

		if (this.selectedTimeSpan === this.timeSpanEnum.ThisDay) {
			const now = moment();
			const steps = 24;

			for (let i = 0; i < steps; i++) {
				this.currentChartLabels.push({
					labelFirst: `${((steps - i) - 1).toString().padStart(2, "0")}:00`,
					labelSecond: '',
					value: ((steps - i) - 1).toString().padStart(2, "0")
				});
			}

			const dateString = `${now.format('YYYY')}-${now.format('MM')}-${now.format('DD')}_${now.format('YYYY')}-${now.format('MM')}-${now.format('DD')}`;
			this.adjustFiltersDate(dateString);

			this.currentTodayValue = `${moment().format('HH').padStart(2, "0")}:00`;

		} else if (this.selectedTimeSpan === this.timeSpanEnum.ThisWeek) {
			const now = moment();
			const steps = 7;
			const startOfWeek = now.startOf('isoWeek').format('DD');
			const endOfWeek = now.endOf('isoWeek').format('DD');

			for (let i = 0; i < steps; i++) {
				this.currentChartLabels.push({
					labelFirst: `${now.isoWeekday((steps - i)).format('dddd')}`.toUpperCase(),
					labelSecond: now.date((parseInt(endOfWeek) - i)).format('D/M'),
					value: (parseInt(endOfWeek) - i).toString().padStart(2, "0")
				});
			}

			const dateString = `${now.format('YYYY')}-${now.format('MM')}-${startOfWeek}_${now.format('YYYY')}-${now.format('MM')}-${endOfWeek}`;
			this.adjustFiltersDate(dateString);

			this.currentTodayValue = moment().format('dddd').toUpperCase();

		} else if (this.selectedTimeSpan === this.timeSpanEnum.ThisMonth) {
			const now = moment();
			const steps = now.daysInMonth();

			for (let i = 0; i < steps; i++) {
				this.currentChartLabels.push({
					labelFirst: (steps - i).toString(),
					labelSecond: `${now.date((steps - i)).format('ddd')}`.toUpperCase(),
					value: (steps - i).toString().padStart(2, "0")
				});
			}

			const dateString = `${now.format('YYYY')}-${now.format('MM')}-01_${now.format('YYYY')}-${now.format('MM')}-${steps.toString().padStart(2, "0")}`;
			this.adjustFiltersDate(dateString);

			this.currentTodayValue = moment().format('D');

		} else if (this.selectedTimeSpan === this.timeSpanEnum.ThisYear) {
			const now = moment();
			const steps = 12;

			for (let i = 0; i < steps; i++) {
				this.currentChartLabels.push({
					labelFirst: `${now.month((steps - 1) - i).format('MMMM')}`.toUpperCase(),
					labelSecond: '',
					value: (steps - i).toString().padStart(2, "0")
				});
			}

			const dateString = `${now.format('YYYY')}-01-01_${now.format('YYYY')}-12-31`;
			this.adjustFiltersDate(dateString);

			this.currentTodayValue = moment().format('MMMM').toUpperCase();
		}

		this.currentChartLabels.reverse();
		this.chartData.labels = this.currentChartLabels.map(labelObject => labelObject.labelSecond ? [labelObject.labelFirst, labelObject.labelSecond] : labelObject.labelFirst);
		this.chart.update();
	}

	private adjustFiltersDate(dateString: string) {
		this.setFilterFacet(
			this.filterObjectScheduledExpiring,
			new KeyValuePair(
				StringUtils.EXPIRES_BETWEEN_KEY,
				dateString
			),
			RoutesUtils.scheduledOngoing
		);

		this.setFilterFacet(
			this.filterObjectScheduledCompleted,
			new KeyValuePair(
				StringUtils.ARCHIVED_BETWEEN_KEY,
				dateString
			),
			RoutesUtils.scheduledArchived
		);

		this.setFilterFacet(
			this.filterObjectScheduledExpired,
			new KeyValuePair(
				StringUtils.ARCHIVED_BETWEEN_KEY,
				dateString
			),
			RoutesUtils.scheduledArchived
		);

		this.setFilterFacet(
			this.filterObjectMeasureCreated,
			new KeyValuePair(
				StringUtils.CREATED_BETWEEN_KEY,
				dateString
			),
			RoutesUtils.measureOngoing
		);

		this.setFilterFacet(
			this.filterObjectMeasureResolved,
			new KeyValuePair(
				StringUtils.ARCHIVED_BETWEEN_KEY,
				dateString
			),
			RoutesUtils.measureArchived
		);

		this.setFilterFacet(
			this.filterObjectMeasureClosed,
			new KeyValuePair(
				StringUtils.ARCHIVED_BETWEEN_KEY,
				dateString
			),
			RoutesUtils.measureArchived
		);
	}

	private setFilterFacet(filter: AssignmentFilter, facet: KeyValuePair, context: string) {
		filter.addFacet(facet);
		filter.context = context;
	}

	private async fetchAllCounts() {
		this.pendingCounts = true;
		try {
			const [saExpiring, saCompleted, saExpired, maCreated, maResolved, maClosed] = await Promise.all([
				this.assignmentService.getFilteredIds(this.filterObjectScheduledExpiring),
				this.assignmentService.getFilteredIds(this.filterObjectScheduledCompleted),
				this.assignmentService.getFilteredIds(this.filterObjectScheduledExpired),
				this.assignmentService.getFilteredIds(this.filterObjectMeasureCreated),
				this.assignmentService.getFilteredIds(this.filterObjectMeasureResolved),
				this.assignmentService.getFilteredIds(this.filterObjectMeasureClosed)
			]);
			this.pendingCounts = false;
			this.scheduledAssignmentsExpiringCount = saExpiring.length;
			this.scheduledAssignmentsCompletedCount = saCompleted.length;
			this.scheduledAssignmentsExpiredCount = saExpired.length;
			this.measureAssignmentsCreatedCount = maCreated.length;
			this.measureAssignmentsResolvedCount = maResolved.length;
			this.measureAssignmentsClosedCount = maClosed.length;

		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}

	private clearChartData() {
		this.pendingItems = true;
		this.chartData.datasets = [];
		this.chart.update();
		this.chart.render();
		this.savedAssignments = [];
	}

	private setAssignmentState(assignmentState: AssignmentState) {
		this.selectedAssignmentState = assignmentState;
	}

	private setCurrentItemsVariables() {
		if (this.selectedAssignmentState === this.assignmentStateEnum.ExpiringAssignments) {
			this.currentItemsFilter = this.filterObjectScheduledExpiring;
			this.currentItemsProperty = this.propertyStrings.dueDate;
			this.currentfirstLabel = this.translationService.instant('TemplateType');
			this.currentSecondLabel = this.translationService.instant('Facility');
			this.currentThirdLabel = this.translationService.instant('Expires');
		} else if (this.selectedAssignmentState === this.assignmentStateEnum.CompletedAssignments) {
			this.currentItemsFilter = this.filterObjectScheduledCompleted;
			this.currentItemsProperty = this.propertyStrings.completed;
			this.currentfirstLabel = this.translationService.instant('TemplateType');
			this.currentSecondLabel = this.translationService.instant('Facility');
			this.currentThirdLabel = this.translationService.instant('Completed');
		} else if (this.selectedAssignmentState === this.assignmentStateEnum.ExpiredAssignments) {
			this.currentItemsFilter = this.filterObjectScheduledExpired;
			this.currentItemsProperty = this.propertyStrings.dueDate;
			this.currentfirstLabel = this.translationService.instant('TemplateType');
			this.currentSecondLabel = this.translationService.instant('Facility');
			this.currentThirdLabel = this.translationService.instant('Expired');
		} else if (this.selectedAssignmentState === this.assignmentStateEnum.CreatedMeasures) {
			this.currentItemsFilter = this.filterObjectMeasureCreated;
			this.currentItemsProperty = this.propertyStrings.created;
			this.currentfirstLabel = this.translationService.instant('Facility');
			this.currentSecondLabel = this.translationService.instant('Entity');
			this.currentThirdLabel = this.translationService.instant('Created');
		} else if (this.selectedAssignmentState === this.assignmentStateEnum.ResolvedMeasures) {
			this.currentItemsFilter = this.filterObjectMeasureResolved;
			this.currentItemsProperty = this.propertyStrings.completed;
			this.currentfirstLabel = this.translationService.instant('Facility');
			this.currentSecondLabel = this.translationService.instant('Entity');
			this.currentThirdLabel = this.translationService.instant('Resolved');
		} else if (this.selectedAssignmentState === this.assignmentStateEnum.ClosedMeasures) {
			this.currentItemsFilter = this.filterObjectMeasureClosed;
			this.currentItemsProperty = this.propertyStrings.completed;
			this.currentfirstLabel = this.translationService.instant('Facility');
			this.currentSecondLabel = this.translationService.instant('Entity');
			this.currentThirdLabel = this.translationService.instant('Closed');
		}
	}

	private async getItems() {
		this.pendingItems = true;
		try {
			const assignments = await this.assignmentService.getFilteredDashboard(this.currentItemsFilter);
			this.pendingItems = false;

			if (assignments.length) {
				this.applyDataToChart(assignments);
			}

		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}

	private applyDataToChart(assignments: Assignment[]) {
		this.chartData.datasets[0] = {
			label: this.translationService.instant(this.selectedAssignmentState),
			backgroundColor: ColorUtils.GREEN_COLOR,
			borderColor: ColorUtils.GREEN_COLOR,
			data: [],
			minBarLength: 30,
			datalabels: {
				display: false
			},
			borderRadius: 4,
			maxBarThickness: 30
		};

		this.currentChartLabels.forEach((labelObject, index) => {
			let assignmentsForLabel: Assignment[] = [];

			if (this.selectedTimeSpan === this.timeSpanEnum.ThisDay) {
				assignmentsForLabel = assignments.filter(assignment => moment(assignment[this.currentItemsProperty.toString()]).format('HH:mm').split(':')[0] === labelObject.value);
			} else if (this.selectedTimeSpan === this.timeSpanEnum.ThisWeek) {
				assignmentsForLabel = assignments.filter(assignment => moment(assignment[this.currentItemsProperty.toString()]).format('YYYY-MM-DD').split('-')[2] === labelObject.value);
			} else if (this.selectedTimeSpan === this.timeSpanEnum.ThisMonth) {
				assignmentsForLabel = assignments.filter(assignment => moment(assignment[this.currentItemsProperty.toString()]).format('YYYY-MM-DD').split('-')[2] === labelObject.value);
			} else if (this.selectedTimeSpan === this.timeSpanEnum.ThisYear) {
				assignmentsForLabel = assignments.filter(assignment => moment(assignment[this.currentItemsProperty.toString()]).format('YYYY-MM-DD').split('-')[1] === labelObject.value);
			}

			if (assignmentsForLabel.length) {
				this.chartData.datasets[0].data[index] = assignmentsForLabel.length;
				this.savedAssignments[index] = assignmentsForLabel;
			}
		});
		this.chart.update();
	}

	handleChartClick({ active }: { event?: ChartEvent; active?: object[]; }) {
		if (active.length) {
			const index = active[0]['index'];
			const assignments = this.savedAssignments[index];
			if (assignments.length) {
				assignments.sortByProperty('facilityName');
				this.bsModalRef = this.modalService.show(
					ViewAssignmentsModalComponent,
					{
						initialState: {
							assignments: assignments,
							property: this.currentItemsProperty,
							firstLabel: this.currentfirstLabel,
							secondLabel: this.currentSecondLabel,
							thirdLabel: this.currentThirdLabel
						},
						...ConfigUtils.MODAL_CONFIG_MEDIUM
					}
				);
				this.modalCloseSubscription = this.bsModalRef.content.closed$
					.pipe(
						takeUntil(this.destroyed$)
					)
					.subscribe((wasClosed: boolean) => {
						if (wasClosed) {
							this.bsModalRef?.hide();
							this.bsModalRef = null;
							this.modalCloseSubscription.unsubscribe();
						}
					});
			}
		}
	}

	openInNewTab(urlToOpen: string) {
		const url = `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${urlToOpen}`;
		window.open(url, '_blank');
	}

	focusFilterSearchInput() {
		setTimeout(() => {
			this.filterInput.nativeElement.focus();
		});
	}

	private removeTypingBorder() {
		setTimeout(() => {
			const el = document.body.querySelector('.greeting');
			if (el) {
				el.classList.add('no-border');
			}
		}, 2000);
	}

	ngOnDestroy() {
		this.modalCloseSubscription.unsubscribe();
		super.ngOnDestroy();
	}
}
