import { ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Choice } from 'app-inspection/choice/choice';
import { ChoicesComponent } from 'app-inspection/template-type/create-edit-template-type/tabs/handle-tasks/choices/choices.component';
import { SimpleTableColumn } from './simple-table-column';

export class SimpleTableChoicesColumn<T> extends SimpleTableColumn<T> {

	readonly getChoices: (item: T) => Choice[];

	constructor(
		label: string,
		getChoices: (item: T) => Choice[],
		sanitizer: DomSanitizer,
		viewContainerRef: ViewContainerRef,
		secondaryLabel: string) {
		super(
			label,
			sanitizer,
			viewContainerRef,
			secondaryLabel
		);
		this.getChoices = getChoices;
	}

	renderValue(item: T) {
		const componentRef = this.viewContainerRef.createComponent(ChoicesComponent);
		const choices = this.getChoices(item);
		choices.sortByProperty(new Choice({}).propertyStrings.value);
		componentRef.instance.handleItems = choices;
		componentRef.instance.readonlyItems = true;
		componentRef.instance.hideRules = true;
		componentRef.instance.triggerViewChanges();
		this.viewContainerRef.clear();
		const choicesDiv = componentRef.location.nativeElement.childNodes[0] as HTMLDivElement;
		return this.sanitizer.bypassSecurityTrustHtml(choicesDiv.outerHTML);
	}
}
