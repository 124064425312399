import { Directive } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';

@Directive(
	{
		selector: '[inputTrimmer]'
	})
export class InputTrimmerDirective {
	constructor(ngControl: NgControl) {
		this.trimValueAccessor(ngControl.valueAccessor);
	}

	trimValueAccessor(valueAccessor: ControlValueAccessor) {
		const original = valueAccessor.registerOnChange;
		valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
			return original.call(valueAccessor, (value: unknown) => {
				if (typeof value === 'string') {
					if (value.trim().length === 0) {
						valueAccessor.writeValue(value.trim());
					}
					value = value.trim();
				}
				return fn(value);
			});
		};
	}
}
