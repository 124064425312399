<div class="choices-wrapper">
	<div class="choices apx-scrollbar" *ngIf="!hideChoices">
		<div class="choice" *ngFor="let choice of handleItems; trackBy: trackByFn"
			[ngClass]="{
				'readonly': readonlyItems
			}"
			title="{{ !readonlyItems ? ('Remove' | translate) : choice.value }}"
			(click)="!readonlyItems ? remove(choice) : null">
				<div class="choice-rules-wrapper" *ngIf="!hideRules">
					<div class="choice-rule {{rule.icon}}" *ngFor="let rule of choice.rulesReadonly"
						[title]="rule.title | translate">
					</div>
				</div>
				<div class="choice-meta-wrapper">
					<span class="choice-value">
						{{choice.value}}
					</span>
					<span class="choice-color"
						[style.backgroundColor]="choice.color">
					</span>
				</div>
		</div>
	</div>

	<ng-container *ngIf="!readonlyItems && !hideAddButton">
		<div class="action bi-plus-circle-dotted" *ngIf="!useButtonAction"
			[ngClass]="{
				'disabled': disableAction
			}"
			title="{{ actionTitle }}"
			(click)="toggleListChoiceModal()">
		</div>
		<button *ngIf="useButtonAction && !isMediumScreenSize()"
			type="button"
			class="btn btn-success"
			[ngClass]="{
				'disabled': disableAction
			}"
			title="{{ actionTitle }}"
			(click)="toggleListChoiceModal()">
				{{ actionTitle }}
		</button>
		<button *ngIf="useButtonAction && isMediumScreenSize()"
			type="button"
			class="btn btn-success bi-check2-circle"
			[ngClass]="{
				'disabled': disableAction
			}"
			title="{{ actionTitle }}"
			(click)="toggleListChoiceModal()">
		</button>

	</ng-container>
</div>
