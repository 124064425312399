import { SweetAlertIcon, SweetAlertInput, SweetAlertOptions } from 'sweetalert2';
import { TranslationService } from '../translation/translation.service';

export class SwalConfig {

	title: string;
	text: string;
	html: string;
	icon: SweetAlertIcon;
	cancelButtonText: string;
	confirmButtonText: string;
	showConfirmButton: boolean = true;
	showCancelButton: boolean = true;
	reverseButtons: boolean = true;
	allowOutsideClick: boolean = false;
	focusCancel: boolean = true;
	buttonsStyling: boolean = false;
	input: SweetAlertInput;
	inputValue: string = '';
	inputPlaceholder: string = '';
	inputOptions: Object;
	customClass: Object = {
		cancelButton: 'btn-transparent',
		confirmButton: 'btn-danger',
	}

	constructor(private translationService: TranslationService) { }

	getDelete(swalConf?: Partial<SwalConfig>): SweetAlertOptions {
		if (swalConf) {
			Object.assign(this, swalConf);
		}

		const swal = this.getDefaults();
		swal.title = this.title ? this.title : `${this.translationService.instant('Note')}!`;
		swal.text = this.text ? this.text : `${this.translationService.instant('DeleteStatement')}!`;
		swal.customClass = {
			cancelButton: 'btn btn-transparent',
			confirmButton: 'btn btn-danger'
		};

		if (swalConf.customClass) {
			swal.customClass = { ...swal.customClass, ...swalConf.customClass };
		}
		return swal;
	}

	getInfo(swalConf?: Partial<SwalConfig>): SweetAlertOptions {
		if (swalConf) {
			Object.assign(this, swalConf);
		}

		const swal = this.getDefaults();
		swal.title = this.title ? this.title : `${this.translationService.instant('Note')}!`
		swal.text = this.text ? this.text : '';
		swal.icon = 'info';
		swal.customClass = {
			confirmButton: 'btn btn-info'
		};

		if (swalConf.customClass) {
			swal.customClass = { ...swal.customClass, ...swalConf.customClass };
		}
		return swal;
	}

	getQuestion(swalConf?: Partial<SwalConfig>): SweetAlertOptions {
		if (swalConf) {
			Object.assign(this, swalConf);
		}

		const swal = this.getDefaults();
		swal.title = this.title ? this.title : '';
		swal.text = this.text ? this.text : '';
		swal.icon = 'question';
		swal.customClass = {
			confirmButton: 'btn btn-info'
		};

		if (swalConf.customClass) {
			swal.customClass = { ...swal.customClass, ...swalConf.customClass };
		}
		return swal;
	}

	getAreYouSure(swalConf?: Partial<SwalConfig>): SweetAlertOptions {
		if (swalConf) {
			Object.assign(this, swalConf);
		}
		const swal = this.getDefaults();
		swal.title = this.title ? this.title : `${this.translationService.instant('Are you sure')}`;

		if (swalConf.customClass) {
			swal.customClass = { ...swal.customClass, ...swalConf.customClass };
		}
		return swal;
	}

	getUnsavedChanges(swalConf?: Partial<SwalConfig>): SweetAlertOptions {
		if (swalConf) {
			Object.assign(this, swalConf);
		}
		const swal = this.getDefaults();
		swal.title = this.title ? this.title : `${this.translationService.instant('Note')}!`;
		swal.text = this.text ? this.text : `${this.translationService.instant('UnsavedChangesStatement')}!`
		swal.icon = 'info';
		swal.customClass = {
			confirmButton: 'btn btn-info'
		};

		if (swalConf.customClass) {
			swal.customClass = { ...swal.customClass, ...swalConf.customClass };
		}
		return swal;

	}

	getBlank(swalConf?: Partial<SwalConfig>): SweetAlertOptions {
		if (swalConf) {
			Object.assign(this, swalConf);
		}
		const swal = this.getDefaults();
		swal.icon = 'info';
		swal.customClass = {
			confirmButton: 'btn btn-info'
		};

		if (swalConf.customClass) {
			swal.customClass = { ...swal.customClass, ...swalConf.customClass };
		}
		return swal;
	}

	private getDefaults(): SweetAlertOptions {
		return {
			icon: 'error',
			showConfirmButton: this.showConfirmButton,
			showCancelButton: this.showCancelButton,
			confirmButtonText: this.confirmButtonText ? this.confirmButtonText : `${this.translationService.instant('Yes')}`,
			cancelButtonText: this.cancelButtonText ? this.cancelButtonText : this.translationService.instant('No'),
			reverseButtons: this.reverseButtons,
			allowOutsideClick: this.allowOutsideClick,
			focusCancel: this.focusCancel,
			buttonsStyling: this.buttonsStyling,
			customClass: this.customClass,
			html: this.html,
			input: this.input,
			inputValue: this.inputValue,
			inputPlaceholder: this.inputPlaceholder,
			inputOptions: this.inputOptions
		}
	}
}