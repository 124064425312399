<simple-change-tab
	[form]="form">
		<div class="change-section">
			<div class="change-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-input
				[label]="'Name' | translate"
				[name]="propertyStrings.name"
				[form]="form"
				[maxlength]="100"
				[serverErrors]="serverErrors"
				[focusOnInit]="!isEdit">
			</simple-form-input>

			<simple-form-select
				[label]="'Status' | translate"
				[name]="propertyStrings.active"
				[form]="form"
				[items]="selectableStatusItems"
				[redBackground]="!changeModel.active"
				[serverErrors]="serverErrors">
			</simple-form-select>
		</div>

		<div class="change-section">
			<div class="change-section-header">
				{{ 'PublishingSettings' | translate }}
			</div>
			<simple-form-select
				[label]="'PublishingType' | translate"
				[name]="propertyStrings.publishingType"
				[form]="form"
				[items]="selectablePublishingTypeItems"
				[serverErrors]="serverErrors">
			</simple-form-select>

			<simple-form-select *ngIf="changeModel.publishingType !== publishingType.Once"
				[label]="'Interval' | translate"
				[name]="propertyStrings.interval"
				[form]="form"
				[items]="selectableIntervalItems"
				[serverErrors]="serverErrors">
			</simple-form-select>

			<simple-form-select *ngIf="changeModel.publishingType === publishingType.Weekly"
				[label]="'Weekdays' | translate"
				[name]="propertyStrings.weekdaysInternal"
				[form]="form"
				[items]="selectableWeekdayItems"
				[multiple]="true"
				[sortOnAdd]="true"
				[serverErrors]="serverErrors">
			</simple-form-select>

			<simple-form-input
				[label]="'ExpirationTimeForAssignments' | translate"
				[name]="propertyStrings.daysDelay"
				[form]="form"
				[serverErrors]="serverErrors">
			</simple-form-input>
		</div>
		<div class="change-section">
			<div class="change-section-header">
				{{ 'TimeSettings' | translate }}
			</div>
			<div class="dates-wrapper">
				<simple-form-date-picker
					[label]="'StartDate' | translate"
					[dateString]="changeModel.startDate"
					[errorMessage]="startDateErrorMessage"
					(onDateChange)="handleStartDateChange($event)">
				</simple-form-date-picker>

				<simple-form-date-picker *ngIf="changeModel.publishingType !== publishingType.Once"
					[ngClass]="{
						'disabled': !shouldValidateEndDate
					}"
					[label]="('EndDate' | translate) + ' (' + (('Optional' | translate) | lowercase) + ')'"
					[dateString]="changeModel.endDate ?? ''"
					[errorMessage]="endDateErrorMessage"
					[displayClearButton]="!!changeModel.endDate"
					(onDateChange)="handleEndDateChange($event)">
				</simple-form-date-picker>
			</div>
			<simple-form-time-input
				[label]="'PublishingTime' | translate"
				[name]="propertyStrings.publishingTime"
				[form]="form"
				[serverErrors]="serverErrors"
				(onTimeChange)="handleTimeChange()">
			</simple-form-time-input>
		</div>
		<div class="change-section">
			<div class="change-section-header">
				{{ 'Preview' | translate }}
			</div>
			<timeline
				[items]="changeModel.publicationDates"
				[previewCount]="changeModel.previewCount">
			</timeline>
			<simple-form-select *ngIf="changeModel.publicationDates.length"
				[label]="'NumberOfPublicationsDisplayed' | translate"
				[name]="propertyStrings.previewCount"
				[form]="form"
				[items]="selectablePreviewCountItems">
			</simple-form-select>
		</div>
</simple-change-tab>
