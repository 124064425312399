import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'simple-item',
	templateUrl: './simple-item.component.html',
	styleUrls: ['./simple-item.component.less']
})
export class SimpleItemComponent<T extends { id: string, selected: boolean, errorMessages: string[], url: string }> {

	hovered: boolean;

	@Input() item: T;
	@Input() noBorder: boolean;
	@Input() hideRemoveButton: boolean;
	@Input() hideSelectButton: boolean;
	@Input() url: string;
	@Input() name: string;
	@Input() height: number;
	@Input() shouldDisableLink: boolean;

	@Output() onRemove = new EventEmitter<T>();
	@Output() onSelect = new EventEmitter<T>();

	remove(item: T) {
		this.onRemove.emit(item);
	}

	select(item: T) {
		this.onSelect.emit(item);
	}
}
