<simple-change-tab
	[form]="form">
	<div class="change-section">
		<div class="change-section-header">
			{{ 'General' | translate }}
		</div>
		<simple-form-input
			[label]="'IssueDetails' | translate"
			[name]="propertyStrings.issueDetails"
			[form]="form"
			[maxlength]="500"
			[useTextarea]="true"
			[serverErrors]="serverErrors">
		</simple-form-input>

		<simple-form-select
			[label]="'Priority' | translate"
			[name]="propertyStrings.priority"
			[form]="form"
			[items]="selectablePriorityItems"
			[disabled]="priorityIsDisabled"
			[serverErrors]="serverErrors">
		</simple-form-select>
		<simple-form-info *ngIf="priorityIsDisabled"
			[firstText]="'Choice' | translate"
			[linkText]="choice.value"
			[linkHref]="disabledPriorityLink"
			[secondText]="'DisabledPriorityInfoText' | translate"
			[name]="choice.value">
		</simple-form-info>

		<simple-form-select
			[label]="'GeoFencing' | translate"
			[name]="propertyStrings.geoControlled"
			[form]="form"
			[items]="selectableGeoFencingItems"
			[disabled]="geoFencingIsDisabled"
			[serverErrors]="serverErrors">
		</simple-form-select>

		<simple-form-info *ngIf="geoFencingIsDisabled"
			[firstText]="'Facility' | translate"
			[linkText]="changeModel.name"
			[linkHref]="disabledGeoFencingLink"
			[secondText]="'DisabledGeoFencingInfoText' | translate"
			[name]="changeModel.name">
		</simple-form-info>
	</div>
</simple-change-tab>