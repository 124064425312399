import { Injectable } from '@angular/core';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, interval, ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserSupportFile } from './user-support';

@Injectable()
export class UserSupportService {

	file = new BehaviorSubject<UserSupportFile>(null);
	fetching = new BehaviorSubject<boolean>(false);

	private subscriptions = new Subscription();
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

	constructor(
		private hostedHttpClient: HostedHttpClientService,
		private translationService: TranslationService,
		private toastrService: ToastrService) { }

	async initiateFetch() {
		if (!this.file.value && !this.fetching.value) {
			this.fetchUserSupportFile();
			this.subscriptions.add(
				interval(NumberUtils.USER_SUPPORT_FILE_POLLING_TIME)
					.pipe(
						takeUntil(this.destroyed$)
					).subscribe(async () => {
						this.fetchUserSupportFile();
					})
			);
		}
	}

	private async fetchUserSupportFile() {
		this.fetching.next(true);
		try {
			const file = await this.hostedHttpClient.fetch('https://inquinova.com/admin.index.json', {
				method: 'GET',
				headers: {
					accept: 'application/json',
				}
			}).then(res => new UserSupportFile(res, this.translationService.currentLang));

			this.fetching.next(false);
			this.file.next(file);

		} catch (errorResponse) {
			this.fetching.next(false);
			this.toastrService.info(this.translationService.instant('TheUserSupportIsCurrentlyUnavailable'));
			Utils.logError(errorResponse);
		}

		// https://inquinova.com/admin.index.json
		// ../assets/admin-local.index.json // Use when testing, if index file in production is empty or broken
	}
}
