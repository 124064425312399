<div class="form-errors" *ngIf="shouldDisplayErrors()">
	<div *ngIf="isSpecificError('required')">
		&bull; {{ 'ThisFieldNeedsToBeFilled' | translate }}
	</div>
	<div *ngIf="isSpecificError('maxlength')">
		&bull; {{ 'ThisFieldNeedsToBeShortened' | translate }}
	</div>
	<div *ngIf="isSpecificError('latitude')">
		&bull; {{ 'LatitudeErrorText' | translate }}
	</div>
	<div *ngIf="isSpecificError('longitude')">
		&bull; {{ 'LongitudeErrorText' | translate }}
	</div>
	<div *ngIf="isSpecificError('radius')">
		&bull; {{ 'RadiusErrorText' | translate }}
	</div>
	<div *ngIf="isSpecificError('emails')">
		&bull; {{ 'EmailsErrorText' | translate }}
	</div>
	<div *ngIf="isSpecificError('email')">
		&bull; {{ 'EmailErrorText' | translate }}
	</div>
	<div *ngIf="isSpecificError('phone')">
		&bull; {{ 'PhoneErrorText' | translate }}
	</div>
	<div *ngIf="isSpecificError('phoneForRegion')">
		&bull; {{ 'InvalidPhoneNumberForRegionErrorText' | translate }}
	</div>
	<div *ngIf="isSpecificError('notAPhoneNumber')">
		&bull; {{ 'NotAPhoneNumberErrorText' | translate }}
	</div>
	<div *ngIf="isSpecificError('organizationNumber')">
		&bull; {{ 'OrganizationNumberErrorText' | translate }}
	</div>
	<div *ngIf="isSpecificError('zipCode')">
		&bull; {{ 'ZipCodeErrorText' | translate }}
	</div>
	<div *ngIf="isSpecificError('passwordsDoNotMatch')">
		&bull; {{ 'PasswordsDoNotMatchText' | translate }}
	</div>
	<div *ngIf="isSpecificError('passwordRequiresDigit')">
		&bull; {{ 'PasswordRequiresDigitText' | translate }}
	</div>
	<div *ngIf="isSpecificError('passwordRequiresLower')">
		&bull; {{ 'PasswordRequiresLowerText' | translate }}
	</div>
	<div *ngIf="isSpecificError('passwordRequiresNonAlphanumeric')">
		&bull; {{ 'PasswordRequiresNonAlphanumericText' | translate }}
	</div>
	<div *ngIf="isSpecificError('passwordRequiresUpper')">
		&bull; {{ 'PasswordRequiresUpperText' | translate }}
	</div>
	<div *ngIf="isSpecificError('passwordRequiresLengthSixOrMore')">
		&bull; {{ 'PasswordRequiresLengthSixOrMoreText' | translate }}
	</div>
	<div *ngIf="isSpecificError('number')">
		&bull; {{ 'NumberErrorText' | translate }}
	</div>
	<div *ngIf="isSpecificError('url')">
		&bull; {{ 'UrlErrorText' | translate }}
	</div>
	<div *ngIf="isSpecificError('serverError')">
		<div *ngFor="let message of getServerErrorMessages()">
			&bull; {{ message.value }}
		</div>
	</div>
</div>
