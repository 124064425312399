import { NgModule } from '@angular/core';
import { SharedModule } from 'app-core/shared-core/shared.module';
import { UserGroupModule } from 'app-core/user-group/user-group.module';
import { GenerateReportComponent } from './generated/generate-report.component';
import { GenerateReportTabComponent } from './generated/tabs/generate-report-tab.component';
import { StaticReportComponent } from './static/static-report.component';
import { StaticReportTabComponent } from './static/tabs/static-report-tab.component';

@NgModule({
	imports: [
		SharedModule,
		UserGroupModule
	],
	declarations: [
		GenerateReportComponent,
		GenerateReportTabComponent,
		StaticReportComponent,
		StaticReportTabComponent
	],
	exports: [
		GenerateReportComponent,
		GenerateReportTabComponent,
		StaticReportComponent,
		StaticReportTabComponent
	]
})
export class ReportModule { }
