
<!-- TABLE BODY -->
<cdk-virtual-scroll-viewport itemSize="{{ isCompactView ? config.compactRowHeight : config.standardRowHeight }}" minBufferPx="999" maxBufferPx="1000"
	class="st-body apx-scrollbar firefox-no-anchor" scrollShadow
	[ngClass]="{
		'compact': isCompactView,
		'in-modal': isInModal,
		'has-search-or-filter': hasActiveSearchOrFilter,
		'filters-opened': filterOpened,
		'empty-list': !dataSource?.rows$.value.length
	}">
		<div class="st-fake-row-top"></div>
		<div class="st-row" *cdkVirtualFor="let row of dataSource; cdkVirtualForTrackBy: trackByFn"
			[ngClass]="{
				'selected': row.selected,
				'unselectable': row.unselectable,
				'affected': row.affected,
				'added': row.added,
				'modified': row.modified
			}"
			[ngStyle]="{
				'height': (isCompactView ? config.compactRowHeight : config.standardRowHeight) + 'px'
			}"
			(click)="rowClick(row)"
			(dblclick)="rowDoubleClick(row)">
				<div class="st-number-wrapper">
					<div class="st-number">
						{{ row.index }}
					</div>
				</div>

				<!-- Standard view -->
				<ng-container *ngIf="!isCompactView">
					<div class="st-section" *ngFor="let column of config.columns; trackBy: trackByFn"
						[style.maxWidth]="column.maxWidth && column !== config.columns[0] ? column.maxWidth : ''">
							<div class="st-value" *ngIf="column.useDivSwitching">
								<div *ngIf="divSwitcher"
									[innerHTML]="(column | renderValue: row.data)">
								</div>
								<div *ngIf="!divSwitcher"
									[innerHTML]="(column | renderValue: row.data)">
								</div>
							</div>
							<div class="st-value" *ngIf="!column.useDivSwitching"
								[innerHTML]="(column | renderValue: row.data)">
							</div>
					</div>

					<!-- Actions inside a popover -->
					<div class="st-section actions">
						<ng-container *ngIf="!config.hideRowActions && !row.unselectable">
							<div class="st-actions-menu bi-three-dots-vertical" #rowActionsPop="bs-popover"
								placement="left"
								container="body"
								containerClass="standard-menu-popover"
								[popover]="rowActionTemplate"
								[outsideClick]="true"
								[ngClass]="{
									'opened': row.actionsOpened,
									'disabled': !config.getRowActions(row).length
								}"
								[title]="'OpenMenu' | translate"
								(onShown)="row.actionsOpened = true"
								(onHidden)="row.actionsOpened = false"
								(click)="popoverOpened(); !config.getRowActions(row).length ? $event.stopImmediatePropagation() : $event.stopPropagation(); closeOthers(rowActionsPop)"
								(dblclick)="$event.stopImmediatePropagation()">
							</div>
							<ng-template #rowActionTemplate>
								<div class="standard-menu-item" *ngFor="let action of config.getRowActions(row); trackBy: trackByFn"
									[title]="action.name"
									[ngClass]="{
										'red-highlight': action.isDeleteAction
									}"
									[cdkCopyToClipboard]="action.isReferenceUrlAction ? row.referenceUrl : null"
									(click)="action.keepOpen ? null : rowActionsPop.hide(); actionClick(action, row); $event.stopImmediatePropagation()">
										{{ action.name }}
								</div>
							</ng-template>
						</ng-container>
						<div class="st-checkbox" *ngIf="!row.unselectable"
							(mouseover)="row.hovered = true"
							(mouseleave)="row.hovered = false"
							[ngClass]="{
								'disabled': row.unselectable,
								'bi-circle': config.isSingleSelect && !row.selected,
								'bi-circle-fill': config.isSingleSelect && row.selected,

								'bi-square': !row.hovered && !row.selected && !config.isSingleSelect,
								'bi-check-square': row.hovered && !row.selected && !config.isSingleSelect,
								'bi-check-square-fill': row.selected && !config.isSingleSelect
							}"
							[title]="row.selected
								? (row.added || row.modified || row.affected) ? ('UnselectExtraInfo' | translate) : ('Unselect' | translate)
								: (row.added || row.modified || row.affected) ? ('SelectExtraInfo' | translate) : ('Select' | translate)"
							(click)="checkboxClick($event, row); $event.stopImmediatePropagation()"
							(dblclick)="$event.stopImmediatePropagation()">
						</div>
					</div>
				</ng-container>

				<!-- Compact view -->
				<ng-container *ngIf="isCompactView">
					<div class="st-section" *ngFor="let column of config.columns; trackBy: trackByFn"
						[ngStyle]="{ 'height': config.compactSectionHeight + 'px' }">
							<div class="st-value column-header">
								{{ column.label }}
								<div class="secondary" *ngIf="column.secondaryLabel">
									{{ column.secondaryLabel }}
								</div>
							</div>
							<div class="st-value" *ngIf="column.useDivSwitching">
								<div *ngIf="divSwitcher"
									[innerHTML]="(column | renderValue: row.data)">
								</div>
								<div *ngIf="!divSwitcher"
									[innerHTML]="(column | renderValue: row.data)">
								</div>
							</div>
							<div class="st-value" *ngIf="!column.useDivSwitching"
								[innerHTML]="(column | renderValue: row.data)">
							</div>
					</div>

					<!-- Actions as separate icons -->
					<div class="st-section actions"
						[ngStyle]="{ 'height': config.standardRowHeight + 'px' }">
							<ng-container *ngIf="!config.hideRowActions && !row.unselectable">
								<div class="st-action-icon {{ action.iconName }}" *ngFor="let action of config.getRowActions(row); trackBy: trackByFn"
									id="{{ row.id + action.iconName}}"
									[title]="action.name"
									[cdkCopyToClipboard]="action.isReferenceUrlAction ? row.referenceUrl : null"
									(click)="popoverOpened(); actionClick(action, row); $event.stopImmediatePropagation()"
									(dblclick)="$event.stopImmediatePropagation()">
								</div>
							</ng-container>
							<div class="st-checkbox" *ngIf="!row.unselectable"
								(mouseover)="row.hovered = true"
								(mouseleave)="row.hovered = false"
								[ngClass]="{
									'disabled': row.unselectable,
									'single-select': config.isSingleSelect,

									'bi-square': !row.hovered && !row.selected,
									'bi-check-square': row.hovered && !row.selected,
									'bi-check-square-fill': row.selected
								}"
								[title]="row.selected ? ('Unselect' | translate) : ('Select' | translate)"
								(click)="checkboxClick($event, row); $event.stopImmediatePropagation()"
								(dblclick)="$event.stopPropagation()">
							</div>
					</div>
				</ng-container>
		</div>

		<!-- No data -->
		<ng-container *ngIf="!dataSource?.rows$.value.length">

			<!-- Dummy -->
			<ng-container *ngIf="pending">
				<div class="st-row dummy" *ngFor="let item of [].constructor(config.dummyRowCount); trackBy: trackByFn"
					[ngStyle]="{
						'height': (isCompactView ? config.compactRowHeight : config.standardRowHeight) + 'px'
					}">

					<!-- Standard view -->
					<ng-container *ngIf="!isCompactView">
						<div class="st-section" *ngFor="let column of config.columns; trackBy: trackByFn"
							[style.maxWidth]="column.maxWidth && column !== config.columns[0] ? column.maxWidth : ''">
								<div class="st-value">

								</div>
						</div>
						<div class="st-section actions">

						</div>
					</ng-container>

					<!-- Compact view -->
					<ng-container *ngIf="isCompactView">
						<div class="st-section" *ngFor="let column of config.columns; trackBy: trackByFn"
							[ngStyle]="{ 'height': config.compactSectionHeight + 'px' }">
								<div class="st-value column-header">

								</div>
								<div class="st-value">

								</div>
						</div>
						<div class="st-section actions"
							[ngStyle]="{ 'height': config.standardRowHeight + 'px' }">

						</div>
					</ng-container>
				</div>
			</ng-container>

			<!-- Empty -->
			<ng-container *ngIf="!pending">
				<div class="st-row empty"
					[ngStyle]="{
						'height': (isCompactView ? config.compactRowHeight : config.standardRowHeight) + 'px'
					}">
						<div class="st-section">
							<div class="st-value">
								{{ "NoDataWasFound" | translate }}
							</div>
						</div>
				</div>
			</ng-container>

		</ng-container>
		<div class="st-fake-row-bottom"></div>
</cdk-virtual-scroll-viewport>