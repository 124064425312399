import { KeyValuePair } from 'app-core/shared-core/filter';
import { SimpleFilterInputItem } from '../simple-filter-input-item';

export enum SimpleFilterInputType {
	MultiSelect = 'MultiSelect',
	SingleSelect = 'SingleSelect',
	MultiSelectWithSearch = 'MultiSelectWithSearch',
	SingleSelectWithSearch = 'SingleSelectWithSearch',
	ListModal = 'ListModal',
	DateSelect = 'DateSelect',
	DateRangeSelect = 'DateRangeSelect'
}

export class SimpleFilterInput {
	id: string;
	label: string;
	type: SimpleFilterInputType;
	transformValues: (values: string) => KeyValuePair[];
	items: SimpleFilterInputItem[] = [];
	selectedItems: SimpleFilterInputItem[] = [];
	opened: boolean;
	openedSingle: boolean;
	dateValue: Date;
	dateRangeValue: Date[] = [];

	constructor(
		id: string,
		label: string,
		type: SimpleFilterInputType,
		transformValues: (values: string) => KeyValuePair[],
		...items: SimpleFilterInputItem[]) {
		this.id = id;
		this.label = label;
		this.type = type;
		this.transformValues = transformValues;
		this.items = items;
	}
}
