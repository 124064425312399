<simple-crud-modal
	[titleText]="'Media' | translate"
	[closeText]="'Close' | translate"
	(onClose)="close()">
		<div class="mwiv-navigation nav-left" *ngIf="hasPrev"
			title="{{ 'Previous' | translate }}"
			(click)="prev()">
				<img width="20" src="{{ media.BTN_ARROW_LEFT}}" />
		</div>
		<div class="mwiv-navigation nav-right" *ngIf="hasNext"
			title="{{ 'Next' | translate }}"
			(click)="next()">
				<img width="20" src="{{ media.BTN_ARROW_RIGHT}}" />
		</div>
		<div class="mwiv-container">
			<ng-container *ngIf="(currentItem$ | async) as currentItem">
				<div class="mwiv-item"
					[title]="currentItem.title">
						<extensive-image *ngIf="currentItem.isImage"
							[imageUrl]="currentItem.url"
							[blob]="currentItem.blob ?? null"
							[useContain]="true"
							[displayLoader]="true">
						</extensive-image>
						<extensive-pdf *ngIf="currentItem.isPDF"
							[pdfUrl]="currentItem.url"
							[blob]="currentItem.blob ?? null"
							[displayLoader]="true">
						</extensive-pdf>
						<div class="mwiv-item-footer">
							<div class="title">
								{{ currentItem.title }}
							</div>
							<div class="mwiv-item-action" *ngIf="!currentItem.blob"
								[title]="'Download' | translate"
								(click)="download()">
							</div>
						</div>
				</div>
			</ng-container>
		</div>
</simple-crud-modal>
