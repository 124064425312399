import { ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RuleObject, SimpleRulesComponent } from 'app-core/shared-core/simple-components/various/rules/simple-rules.component';
import { SimpleTableColumn } from './simple-table-column';

export class SimpleTableRulesColumn<T> extends SimpleTableColumn<T> {

	readonly getRules: (item: T) => RuleObject[];

	constructor(
		label: string,
		getRules: (item: T) => RuleObject[],
		sanitizer: DomSanitizer,
		viewContainerRef: ViewContainerRef,
		secondaryLabel: string) {
		super(
			label,
			sanitizer,
			viewContainerRef,
			secondaryLabel
		);
		this.getRules = getRules;
	}

	renderValue(item: T) {
		const componentRef = this.viewContainerRef.createComponent(SimpleRulesComponent);
		const rules = this.getRules(item);
		componentRef.instance.rules = rules;
		componentRef.instance.triggerViewChanges();
		this.viewContainerRef.clear();
		const rulesDiv = componentRef.location.nativeElement.childNodes[0] as HTMLDivElement;
		return this.sanitizer.bypassSecurityTrustHtml(rulesDiv.outerHTML);
	}
}
