import { DEFAULT_LANGUAGE, User } from '../../user/user';
import { StringUtils } from './string-utils';
import { LogLevel, Utils } from './utils';

export const VALIDATION_STRINGS = {
	SERVER_ERRORS: 'serverErrors',
	SETTINGS_PROP: 'Settings',
	PREFIX_INTERVAL: 'interval',
	PREFIX_SINGLE: 'single'
};

export enum SortDirection {
	Ascending = -1,
	Descending = 1
}

export { };

declare global {
	interface Object {
		isOrgAdmin(org: string): boolean;
		isSuperAdmin(): boolean;
		canEdit(orgFriendlyUrl: string): boolean;
		hasActiveMemberships(): boolean;
		isOrgMember(org: string): boolean;
		isUserOrgAdmin(): boolean;
		isUserOrgAdminActive(): boolean;
		isMembershipMember(organizationId: number): boolean;
		isMembershipMemberActive(organizationId: number): boolean;
		isMembershipAdmin(organizationId: number): boolean;
		isMembershipAdminActive(organizationId: number): boolean;
		isAdminInMoreThanOneOrganization(): boolean;
		getModelPropertyFromString(key?: any, mappingProperties?: any): Array<string>;
		isEmptyObject(): boolean;
		trySpyOnProperty(property: string, accessType?: "get" | "set"): jasmine.Spy;


		/**
		 * Returns an objects keys containing a given value. The value can be either
		 * a simple string, object, array or an item in an array.
		 * @param value Any type of value
		 */
		getKeysByValue(value: any): string[];
	}

	interface Array<T> {
		remove(o: T): Array<T>;
		diff(o: T, predicate: T): Array<T>;
		formatAsCountryCode(): Array<T>;
		toCommaSeparatedList(onlyCommas?: boolean): Array<T>;
		getDictName(o: T): string;
		findObject(predicate: (search: T) => boolean): T;
		equals(array: any[], comparisonFn?: (arr: any[], item: any) => boolean): boolean;
		flatten<T>(): T[];

		/**
		 * Sorts an array by given property by a given locale (for now just Sweden). A second property can be passed
		 * separated with a blank, a dot, a comma or a pipe, if sorting should be done based on a nested
		 * object (e.g. Object Title, Object.Title, Object,Title, Object|Title).
		 * @param property Property to sort by (e.g. Object Title, Object.Title, Object,Title, Object|Title)
		 * @param sortDirection SortDirection, Ascending or Descending (defaults to Descending)
		 */
		sortByProperty(property: string, sortDirection?: SortDirection): void;
		inArray(comparer): void;
		pushIfNotExist(element, comparer): void;
		pushOrReplace(element, comparer): void;
	}

	interface String {
		isNullOrEmpty: () => boolean;
		format: () => string;
		formatBold: (...params: any[]) => string;
		formatItalic: (...params: any[]) => string;
		humanizeString: (skipLowerCasing?: boolean) => string;
		upperCaseEachWord: () => string;
		toHtmlBreakLine(): string;
	}
}


/**
 * String extensions
 */

// Determines if a string is null or empty
if (!String.prototype.isNullOrEmpty) {
	String.prototype.isNullOrEmpty = function () {
		return this === null || typeof this === 'undefined' || !this.trim().length;
	};
}

// Formats a string equivalent to the String.Format in C#
if (!String.prototype.format) {
	String.prototype.format = function (...params: any[]) {
		let s = this,
			i = params.length;

		while (i--) {
			s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), params[i]);
		}

		return s;
	};
}

// Formats a string equivalent to the String.Format in C# with strong tags
if (!String.prototype.formatBold) {
	String.prototype.formatBold = function (...params: any[]) {
		let stringToFormat = this;
		const count = (stringToFormat.match(/{/g) || []).length;
		for (let j = 0; j < count; j++) {
			if (params && params.length) {
				let i = params.length;
				while (i--) {
					stringToFormat = stringToFormat.replace(new RegExp('\\{' + i + '\\}', 'gm'), '<strong class="strong">' + params[i] + '</strong>');
					stringToFormat = stringToFormat.replace(new RegExp('\\[' + i + '\\]', 'gm'), params[i]);
				}
			} else {
				stringToFormat = stringToFormat.replace('{', '<strong class="strong">').replace('}', '</strong>');
			}
		}
		return stringToFormat;
	};
}

// Formats a string equivalent to the String.Format in C# with strong tags
if (!String.prototype.formatItalic) {
	String.prototype.formatItalic = function (...params: any[]) {
		let stringToFormat = this;
		const count = (stringToFormat.match(/\/\//g) || []).length;
		for (let j = 0; j < count; j++) {
			if (params && params.length) {
				let i = params.length;
				while (i--) {
					stringToFormat = stringToFormat.replace(new RegExp('\\/\\/' + i + '\\/\\/', 'gm'), '<span class="italic">' + params[i] + '</span>');
				}
			} else {
				stringToFormat = stringToFormat.replace('//', '<span class="italic">').replace('//', '</span>');
			}
		}
		return stringToFormat;
	};
}

// Humanizes a string sentence from eg. "this WILL be HUMANized" -> "This will be humanized"
if (!String.prototype.humanizeString) {
	String.prototype.humanizeString = function (skipLowerCasing?: boolean) {
		const string = skipLowerCasing ? this : this.toLowerCase();
		return string.charAt(0).toUpperCase() + string.slice(1);
	};
}

// Makes a string compact without white spaces and line breaks
if (!String.prototype.upperCaseEachWord) {
	String.prototype.upperCaseEachWord = function () {
		return this.replace(/^\n|\n$/g, '');
	};
}

// Converts a string with \n in it to <br>
if (!String.prototype.toHtmlBreakLine) {
	String.prototype.toHtmlBreakLine = function (): string {
		return this.replace(/(?:\r\n|\r|\n)/g, '<br>');
	};
}

/**
 * User extensions
 */

// Determines if a user has role admin for any organization
if (!User.prototype.hasOwnProperty('isOrgAdmin')) {
	Object.defineProperty(User.prototype, 'isOrgAdmin', {
		value: function isOrgAdmin(org: string) {
			return this.organization.friendlyUrl === org &&
				StringUtils.ADMIN_ROLES.some(r => this.roles.indexOf(r) >= 0)
				|| !!this.organizationMemberships.findObject(om =>
					om.organization.friendlyUrl === org &&
					om.status === StringUtils.ACTIVE &&
					StringUtils.ADMIN_ROLES.some(r => om.roles.indexOf(r) >= 0));
		}
	});
}

// Determines if a user has role super admin regardless of organization
if (!User.prototype.hasOwnProperty('isSuperAdmin')) {
	Object.defineProperty(User.prototype, 'isSuperAdmin', {
		value: function isSuperAdmin() {
			return StringUtils.SUPER_ADMIN_ROLES.some(r => this.roles.indexOf(r) >= 0)
				|| !!this.organizationMemberships.findObject(om =>
					StringUtils.SUPER_ADMIN_ROLES.some(r => om.roles.indexOf(r) >= 0));
		}
	});
}

// Determines if a user has edit permissions.
if (!User.prototype.hasOwnProperty('canEdit')) {
	Object.defineProperty(User.prototype, 'canEdit', {
		value: function canEdit(orgFriendlyUrl: string) {
			return this.isOrgAdmin(orgFriendlyUrl) || this.isSuperAdmin();
		}
	});
}

// Determines if a user has any active organization memberships
if (!User.prototype.hasOwnProperty('hasActiveMemberships')) {
	Object.defineProperty(User.prototype, 'hasActiveMemberships', {
		value: function hasActiveMemberships() {
			return !!this.organizationMemberships.findObject(om => om.status === StringUtils.ACTIVE);
		}
	});
}

// Determines if a user is a member of an organization regardless of role
if (!User.prototype.hasOwnProperty('isOrgMember')) {
	Object.defineProperty(User.prototype, 'isOrgMember', {
		value: function isOrgMember(org: string) {
			return this.organization.friendlyUrl === org
				|| !!this.organizationMemberships.findObject(om =>
					om.organization.friendlyUrl === org &&
					om.status === StringUtils.ACTIVE);
		}
	});
}

// NEW USER EXTENSIONS TO MATCH REQUIREMENTS FOR PERMISSIONS

// Determines if a user is admin in his/her user organization.
if (!User.prototype.hasOwnProperty('isUserOrgAdmin')) {
	Object.defineProperty(User.prototype, 'isUserOrgAdmin', {
		value: function isUserOrgAdmin() {
			return StringUtils.ADMIN_ROLES.some(role => this.roles.indexOf(role) >= 0);
		}
	});
}

if (!User.prototype.hasOwnProperty('isUserOrgAdminActive')) {
	Object.defineProperty(User.prototype, 'isUserOrgAdminActive', {
		value: function isUserOrgAdminActive() {
			return StringUtils.ADMIN_ROLES.some(role => this.roles.indexOf(role) >= 0
				&& this.status === StringUtils.ACTIVE);
		}
	});
}

// Determines if a user is member of a specified membership organization.
if (!User.prototype.hasOwnProperty('isMembershipMember')) {
	Object.defineProperty(User.prototype, 'isMembershipMember', {
		value: function isMembershipMember(organizationId: number) {
			return !!this.organizationMemberships.findObject(membership => membership.organization.id === organizationId);
		}
	});
}

if (!User.prototype.hasOwnProperty('isMembershipMemberActive')) {
	Object.defineProperty(User.prototype, 'isMembershipMemberActive', {
		value: function isMembershipMemberActive(organizationId: number) {
			return !!this.organizationMemberships.findObject(membership => membership.organization.id === organizationId
				&& membership.status === StringUtils.ACTIVE);
		}
	});
}

// Determines if a user is admin in a specified membership organization.
if (!User.prototype.hasOwnProperty('isMembershipAdmin')) {
	Object.defineProperty(User.prototype, 'isMembershipAdmin', {
		value: function isMembershipAdmin(organizationId: number) {
			return !!this.organizationMemberships.findObject(membership => membership.organization.id === organizationId
				&& StringUtils.ADMIN_ROLES.some(role => membership.roles.indexOf(role) >= 0));
		}
	});
}

if (!User.prototype.hasOwnProperty('isMembershipAdminActive')) {
	Object.defineProperty(User.prototype, 'isMembershipAdminActive', {
		value: function isMembershipAdminActive(organizationId: number) {
			return !!this.organizationMemberships.findObject(membership => membership.organization.id === organizationId
				&& StringUtils.ADMIN_ROLES.some(role => membership.roles.indexOf(role) >= 0)
				&& membership.status === StringUtils.ACTIVE);
		}
	});
}

if (!User.prototype.hasOwnProperty('isAdminInMoreThanOneOrganization')) {
	Object.defineProperty(User.prototype, 'isAdminInMoreThanOneOrganization', {
		value: function isAdminInMoreThanOneOrganization() {
			let count = this.isUserOrgAdminActive() ? 1 : 0;
			count += this.organizationMemberships.filter(membership => StringUtils.ADMIN_ROLES.some(role => membership.roles.indexOf(role) >= 0)
				&& membership.status === StringUtils.ACTIVE).length;
			return count > 1;
		}
	});
}


/**
 * Object extensions
 */

// Gets the model property for the server validation
if (!Object.prototype.hasOwnProperty('getModelPropertyFromStringCore')) {
	Object.defineProperty(Object.prototype, 'getModelPropertyFromStringCore', {
		value: function getModelPropertyFromStringCore(key?: string, mappingProperties?: any): Array<string> {
			const propOriginal = key.charAt(0).toLowerCase() + key.substr(1);
			const prop = key.charAt(0).toLowerCase() + key.substr(1) + VALIDATION_STRINGS.SETTINGS_PROP;
			const propIntervalPrefix = VALIDATION_STRINGS.PREFIX_INTERVAL + key.charAt(0).toUpperCase() + key.substr(1) + VALIDATION_STRINGS.SETTINGS_PROP;
			const propSinglePrefix = VALIDATION_STRINGS.PREFIX_SINGLE + key.charAt(0).toUpperCase() + key.substr(1) + VALIDATION_STRINGS.SETTINGS_PROP;


			if (mappingProperties) {
				const props = [];
				Object.keys(mappingProperties).forEach(prop => {
					if (mappingProperties[prop] && mappingProperties[prop].length) {
						mappingProperties[prop].forEach(innerProp => {
							if (innerProp === propOriginal && typeof this[innerProp] !== 'undefined') {
								if (props.indexOf(prop) === -1) {
									props.push(prop);
								}
							}
						});
					}
				});

				if (props.length) {
					return props;
				}
			}

			if (typeof this[propIntervalPrefix] !== 'undefined' || typeof this[propSinglePrefix] !== 'undefined') {
				if (typeof this[propIntervalPrefix] !== 'undefined' && typeof this[propSinglePrefix] !== 'undefined') {
					return [propIntervalPrefix, propSinglePrefix];
				} else if (typeof this[propIntervalPrefix] !== 'undefined') {
					return [propIntervalPrefix];
				} else if (typeof this[propSinglePrefix] !== 'undefined') {
					return [propSinglePrefix];
				}
			} else if (typeof this[prop] !== 'undefined') {
				return [prop];
			} else if (typeof this[propOriginal] !== 'undefined') {
				return [propOriginal];
			} else {
				return [];
			}
		}
	});
}

// Determines if an object is empty.
if (!Object.prototype.hasOwnProperty('isEmptyObject')) {
	Object.defineProperty(Object.prototype, 'isEmptyObject', {
		value: function isEmptyObject() {
			for (const key in this) {
				if (this.hasOwnProperty(key)) {
					return false;
				}
			}
			return true;
		}
	});
}

if (!Object.prototype.hasOwnProperty('trySpyOnProperty')) {
	Object.defineProperty(Object.prototype, 'trySpyOnProperty', {
		value: function trySpyOnProperty(property: string, accessType?: 'get' | 'set'): jasmine.Spy {
			const obj = this;
			const theAccessType: 'get' | 'set' = accessType || 'get';

			if (!obj.hasOwnProperty(property)) {
				throw new TypeError(`Object '${obj.constructor.name}' doesn't implement property '${property}'.`);
			}

			if (!Object.getOwnPropertyDescriptor(obj, property)[theAccessType]) {
				const accessor = {
					[theAccessType]: () => { }
				};
				Object.defineProperty(obj, property, accessor);
			}

			return spyOnProperty(obj, property as any, theAccessType);
		}
	});
}

// Gets an objects key that contains given value
if (!Object.prototype.hasOwnProperty('getKeysByValue')) {
	Object.defineProperty(Object.prototype, 'getKeysByValue', {
		value: function getKeysByValue(propValue: any): string[] {
			let foundKey = false;
			const keys = [];

			for (const key in this) {
				if (this.hasOwnProperty(key)) {
					const prop = this[key];

					const isArrayCheck = (valueToCheck: any) =>
						valueToCheck instanceof Array || Array.isArray(valueToCheck);
					const isObjectCheck = (valueToCheck: any) =>
						valueToCheck instanceof Object || typeof valueToCheck === 'object';
					const isFunctionCheck = (valueToCheck: any) =>
						valueToCheck && {}.toString.call(valueToCheck) === '[object Function]';

					const isArrayComparison =
						(isArrayCheck(prop)) && (isArrayCheck(propValue));
					const isArrayIncludesCheck =
						(isArrayCheck(prop)) && !(isArrayCheck(propValue));
					const isObjectComparison =
						(isObjectCheck(prop)) && (isObjectCheck(propValue));
					const isFunctionComparison =
						(isFunctionCheck(prop)) && (isFunctionCheck(propValue));

					if (isFunctionComparison) {
						foundKey = prop.toString() === propValue.toString();
					} else if (isArrayComparison) {
						foundKey = prop === propValue || prop.equals(propValue);
					} else if (isArrayIncludesCheck) {
						foundKey = prop.includes(propValue);
					} else if (isObjectComparison) {
						foundKey = prop === propValue || Utils.isEqual(prop, propValue);
					} else {
						foundKey = prop === propValue;
					}
				}

				if (foundKey) {
					keys.push(key);
				}
			}

			return keys;
		}
	});
}


/**
 * Array extensions
 */
if (!Array.prototype.diff) {
	Array.prototype.diff = function (right: any[], predicate: (a: any, b: any) => boolean) {
		return this.filter(a => right.find(b => predicate(a, b)) === undefined);
	};
}

if (!Array.prototype.remove) {
	Array.prototype.remove = function <T>(elem: T) {
		return this.filter(e => e !== elem);
	};
}

// Formats dictionary as +46 (123...)
if (!Array.prototype.formatAsCountryCode) {
	Array.prototype.formatAsCountryCode = function () {
		const array = this;
		for (let i = 0; i < array.length; i++) {
			array[i].text = '+' + array[i].id + ' (' + array[i].text + ')';
		}
		return array;
	};
}

if (!Array.prototype.toCommaSeparatedList) {
	Array.prototype.toCommaSeparatedList = function (onlyCommas?: boolean) {
		if (onlyCommas) {
			return this.join(', ');
		} else {
			return this.join(', ').replace(/,(?!.*,)/gmi, ' &');
		}
	};
}

// Gets the name of dictionary item
// in an array based on given parameter
if (!Array.prototype.getDictName) {
	Array.prototype.getDictName = function <T>(elem: T) {
		return this.find(country => country.id === elem).text;
	};
}

// Finds an object in an array of
// objects based on given parameter
if (!Array.prototype.findObject) {
	Array.prototype.findObject = function (predicate) {
		if (this == null) {
			throw new TypeError('Array.prototype.findObject called on null or undefined');
		}
		if (typeof predicate !== 'function') {
			throw new TypeError('Predicate must be a function');
		}
		const list = Object(this);
		const length = +list.length || 0;
		const thisArg = arguments[1];
		let value;

		for (let i = 0; i < length; i++) {
			value = list[i];
			if (predicate.call(thisArg, value, i, list)) {
				return value;
			}
		}
		return undefined;
	};
}

if (!Array.prototype.equals) {
	Array.prototype.equals = function (array: any[], comparisonFn?: (arr: any[], item: any) => boolean) {
		if (this.length !== array.length) {
			return false;
		}

		if (typeof comparisonFn !== 'function') {
			comparisonFn = (arr, item) => {
				return arr.indexOf(item) !== -1;
			};
		}

		return this.filter((item: any) =>
			comparisonFn(array, item)
		).length === array.length;
	};
}

if (!Array.prototype.flatten) {
	Array.prototype.flatten = function <T>(): T[] {
		const array = this;

		if ((array as unknown as T[]).every((val) => !Array.isArray(val))) {
			return (array as unknown as T[]).slice();
		}

		return array.reduce((flat, toFlatten) => {
			return flat.concat(Array.isArray(toFlatten)
				? toFlatten.flatten()
				: toFlatten);
		}, []);
	}
}

/**
 * Sorts an array by given property by a given locale (for now just Sweden). A second property can be passed
 * separated with a blank, a dot, a comma or a pipe, if sorting should be done based on a nested
 * object (e.g. Object Title, Object.Title, Object,Title, Object|Title).
 * @param property Property to sort by (e.g. Object Title, Object.Title, Object,Title, Object|Title)
 * @param sortDirection SortDirection, Ascending or Descending (defaults to Descending)
 */
if (!Array.prototype.sortByProperty) {
	Array.prototype.sortByProperty = function (property: string, sortDirection: SortDirection = SortDirection.Descending): void {
		if (this == null) {
			Utils.logMessage('Array.prototype.sortByProperty called on null or undefined', this.loggedInUser, LogLevel.Error);
			return;
		}
		if (property == null) {
			Utils.logMessage('Array.prototype.sortByProperty called with null or undefined property', this.loggedInUser, LogLevel.Error);
			return;
		}
		if ((typeof property !== 'string' && !Array.isArray(property))
			|| (Array.isArray(property) && property.some(o => typeof o !== 'string'))) {
			Utils.logMessage(`Array.prototype.sortByProperty called with a non-string, a non-array property/properties or wrong delimiter (${property})`, this.loggedInUser, LogLevel.Error);
			return;
		}

		const splittedProp = property.split(/[\s.,|]+/);
		const prop = splittedProp.length > 1 ? splittedProp : property;

		// Do not proceed if the collection is undefined or empty
		if (!this.length) {
			return;
		}

		if (Array.isArray(prop) && splittedProp.length > 2) {
			Utils.logMessage(`Array.prototype.sortByProperty called with more than two level properties (${property})`, this.loggedInUser, LogLevel.Error);
			return;
		}
		if (Array.isArray(prop) && splittedProp.length === 2) {
			if (!this[0].hasOwnProperty(splittedProp[0]) || this[0][splittedProp[0]] === null) {
				Utils.logMessage(`Array.prototype.sortByProperty called with a non-existing first level property/properties (${property})`, this.loggedInUser, LogLevel.Error);
				return;
			}
			if (!this[0][splittedProp[0]].hasOwnProperty(splittedProp[1]) || this[0][splittedProp[0]][splittedProp[1]] === null) {
				Utils.logMessage(`Array.prototype.sortByProperty called with a non-existing second level property/properties (${property})`, this.loggedInUser, LogLevel.Error);
				return;
			}
		}
		if (!Array.isArray(prop) && !this[0].hasOwnProperty(prop)) {
			Utils.logMessage(`Array.prototype.sortByProperty called with a non-existing property/properties (${property})`, this.loggedInUser, LogLevel.Error);
			return;
		}

		this.sort(function (a, b) {
			let i = 0;
			while (i < splittedProp.length) {
				a = a[splittedProp[i]];
				b = b[splittedProp[i]];
				i++;
			}

			// Return 0 for null values to avoid exception for localeComapre
			if (a === null || b === null) {
				return 0;
			}

			if (typeof a === 'boolean' && typeof b === 'boolean') {
				if (a < b) {
					return -1 * sortDirection;
				} else if (a > b) {
					return 1 * sortDirection;
				} else {
					return 0;
				}
			} else {
				if (a.localeCompare(b, DEFAULT_LANGUAGE, { numeric: true }) < b.localeCompare(a, DEFAULT_LANGUAGE, { numeric: true })) {
					return -1 * sortDirection;
				} else if (a.localeCompare(b, DEFAULT_LANGUAGE, { numeric: true }) > b.localeCompare(a, DEFAULT_LANGUAGE, { numeric: true })) {
					return 1 * sortDirection;
				} else {
					return 0;
				}
			}
		});
	};
}

if (!Array.prototype.inArray) {
	Array.prototype.inArray = function (comparer) {
		for (let i = 0; i < this.length; i++) {
			if (comparer(this[i])) {
				return true;
			}
		}
		return false;
	};
}

if (!Array.prototype.pushIfNotExist) {
	Array.prototype.pushIfNotExist = function (element, comparer) {
		if (!this.inArray(comparer)) {
			this.push(element);
		}
	};
}

if (!Array.prototype.pushOrReplace) {
	Array.prototype.pushOrReplace = function (element, comparer) {
		for (let i = 0; i < this.length; i++) {
			if (comparer(this[i])) {
				this[i] = element;
				return;
			}
		}
		this.push(element);
	};
}
