import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';
import { RuleObject } from 'app-core/shared-core/simple-components/various/rules/simple-rules.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { ListUserComponent } from 'app-core/user/list-user/list-user.component';
import { Subscriber, User } from 'app-core/user/user';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CreateEditSubscriberComponent } from './create-edit-subscriber/create-edit-subscriber.component';

@Component({
	selector: 'handle-subscribers-tab',
	templateUrl: './handle-subscribers-tab.component.html',
	styleUrls: ['./handle-subscribers-tab.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandleSubscribersTabComponent extends SimpleHandleTabDirective<Subscriber> {

	globalDummyUser = new Subscriber({});

	// Special ovverride due to using Subscriber.
	bsModalRef: BsModalRef<any>;

	@Input() isManualMeasureContext: boolean;
	@Input() displayUrgentIcon: boolean;

	toggleListUserModal() {
		this.bsModalRef = this.modalService.show(
			ListUserComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: this.readonlyListModal,
					hideTableHeaderActions: this.readonlyListModal,
					hideTableRowActions: this.readonlyListModal,
					idsToSetAsSelected: this.handleItems.filter(item => !!item.userId).map(item => item.userId)
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	toggleSubscriberModal(item?: Subscriber) {
		if (!item || !item.userId) {
			this.bsModalRef = this.modalService.show(
				CreateEditSubscriberComponent,
				{
					initialState: {
						editModelId: item ? item.id : '',
						disableTabIndexUrl: true,
						subscriber: item,
						allOtherSubscribers: item ? this.handleItems.filter(hi => hi.id !== item.id) : this.handleItems
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM
				}
			);
			this.subscribeToCrudModalContent();
		}
	}

	itemListIsValid() {
		const allSubscribersHaveAtLeastOneNotificationRule = !this.handleItems.length ||
			this.handleItems.every(subscriber => {
				if (this.isManualMeasureContext) {
					return subscriber.measureCreated
						|| subscriber.measureClosed
						|| subscriber.measureCompleted
						|| subscriber.urgentMeasureCreated;
				} else {
					return subscriber.scheduledCreated
						|| subscriber.scheduledExpired
						|| subscriber.scheduledCompleted
						|| subscriber.measureCreated
						|| subscriber.measureClosed
						|| subscriber.measureCompleted
						|| subscriber.urgentMeasureCreated;
				}
			});
		return allSubscribersHaveAtLeastOneNotificationRule;
	}

	override handleUpdated(updatedItems: Subscriber[]) {
		updatedItems.forEach(item => {
			const existingItem = this.handleItems.find(i => i.id === item.id);
			if (existingItem) {
				Object.assign(existingItem, item);
			} else {
				item.id = Utils.getRandomNumberString();
				this.handleItems = [...this.handleItems, item];
			}
		});
		this.emitModelsChange();
		this.closeModal();
	}

	override emitModelsChange() {
		this.handleItems.sortByProperty(this.propertyStrings.email);
		super.emitModelsChange();
	}

	protected validate(subscribers: Subscriber[]) {
		subscribers.forEach(subscriber => {
			let hasAtLeastOneNotificationRule: boolean;
			if (this.isManualMeasureContext) {
				hasAtLeastOneNotificationRule = subscriber.measureCreated
					|| subscriber.measureClosed
					|| subscriber.measureCompleted
					|| subscriber.urgentMeasureCreated;
			} else {
				hasAtLeastOneNotificationRule = subscriber.scheduledCreated
					|| subscriber.scheduledExpired
					|| subscriber.scheduledCompleted
					|| subscriber.measureCreated
					|| subscriber.measureClosed
					|| subscriber.measureCompleted
					|| subscriber.urgentMeasureCreated;
			}
			subscriber.errorMessages = [];
			if (!hasAtLeastOneNotificationRule) {
				subscriber.errorMessages.push('TheSubscriberNeedsAtLeastOneNotificationRule');
			}
		});
	}

	initiateGlobalNotifications() {
		const selectedItems = this.handleItems.filter(model => model.selected === true);
		this.globalDummyUser.scheduledCreated = selectedItems.every(item => item.scheduledCreated);
		this.globalDummyUser.scheduledExpired = selectedItems.every(item => item.scheduledExpired);
		this.globalDummyUser.scheduledCompleted = selectedItems.every(item => item.scheduledCompleted);
		this.globalDummyUser.measureCreated = selectedItems.every(item => item.measureCreated);
		this.globalDummyUser.measureClosed = selectedItems.every(item => item.measureClosed);
		this.globalDummyUser.measureCompleted = selectedItems.every(item => item.measureCompleted);
		this.globalDummyUser.urgentMeasureCreated = selectedItems.every(item => item.urgentMeasureCreated);
	}

	handleNotificationClick(item: Subscriber, notification: RuleObject) {
		const propertyName = this.getPropertyName(notification);
		item[propertyName] = !item[propertyName];
		this.validate([item]);
	}

	handleNotificationClickGlobal(notification: RuleObject) {
		const propertyName = this.getPropertyName(notification);
		this.globalDummyUser[propertyName] = !this.globalDummyUser[propertyName];
		const selectedItems = this.handleItems.filter(model => model.selected === true);
		selectedItems.forEach(item => item[propertyName] = this.globalDummyUser[propertyName]);
		this.validate(selectedItems);
	}

	isMediumScreenSize() {
		return Utils.isMediumScreenSize();
	}

	private getPropertyName(notification: RuleObject) {
		const index = notification.title.indexOf('Singular') === -1 ? notification.title.length : notification.title.indexOf('Singular');
		return Utils.uncapitalizeFirstLetter(notification.title.substring(0, index));
	}

	override handleConfirmed(confirmedItems: User[]) {
		const mappedConfirmedItems = confirmedItems.map(confirmedItem => {
			const existingItem = this.handleItems.find(item => item.userId === confirmedItem.id);
			if (existingItem) {
				return existingItem;
			} else {
				return new Subscriber({
					id: Utils.getRandomNumberString(),
					email: confirmedItem.email,
					culture: confirmedItem.culture,
					userId: confirmedItem.id
				});
			}
		});
		let externalItems = this.handleItems.filter(item => !item.userId);
		mappedConfirmedItems.forEach(item => {
			const existingInExternal = externalItems.find(externalItem => externalItem.email === item.email);
			if (existingInExternal) {
				item.scheduledCreated = existingInExternal.scheduledCreated;
				item.scheduledExpired = existingInExternal.scheduledExpired;
				item.scheduledCompleted = existingInExternal.scheduledCompleted;
				item.measureCreated = existingInExternal.measureCreated;
				item.measureClosed = existingInExternal.measureClosed;
				item.measureCompleted = existingInExternal.measureCompleted;
				item.urgentMeasureCreated = existingInExternal.urgentMeasureCreated;
			}
		});
		externalItems = externalItems.filter(item => !mappedConfirmedItems.find(mappedItem => mappedItem.email === item.email));
		this.handleItems = [...this.constructItemList(this.handleItems, mappedConfirmedItems), ...externalItems];
		this.setAllAreSelected();
		this.setSomeAreSelected();
		this.emitModelsChange();
		this.closeModal();
	}

	override constructItemList(currentItemList: Subscriber[], confirmedItemList: Subscriber[]) {
		// Reduce to only confirmed items.
		currentItemList = currentItemList
			.filter(currentItem => confirmedItemList.find(confirmedItem => confirmedItem.userId === currentItem.userId));

		// Apply only new items.
		currentItemList = currentItemList
			.concat(confirmedItemList
				.filter(confirmedItem => !currentItemList.find(currentItem => currentItem.userId === confirmedItem.userId)));

		return currentItemList;
	}

	getItems() {
		return this.searchValue
			? this.handleItems.filter(item => item.email.toLowerCase().includes(this.searchValue.toLowerCase()))
			: this.handleItems;
	}
}
