<simple-change-tab
	[form]="form">
		<div class="change-section">
			<div class="change-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-input
				[label]="'EmailAddress' | translate"
				[name]="propertyStrings.email"
				[form]="form"
				[serverErrors]="serverErrors"
				[focusOnInit]="!isEdit">
			</simple-form-input>
		</div>

		<div class="change-section">
			<div class="change-section-header">
				{{ 'Settings' | translate }}
			</div>
			<simple-form-select
				[label]="'Language' | translate"
				[name]="propertyStrings.culture"
				[form]="form"
				[items]="selectableCultureItems"
				[serverErrors]="serverErrors">
			</simple-form-select>
		</div>
</simple-change-tab>
