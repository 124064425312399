import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { ScheduleForViewing } from 'app-inspection/schedule/schedule';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { User } from '../user';
import { UserService } from '../user.service';
import { ChangeDetailsTabComponent } from './tabs/change-details/change-details-tab.component';
import { ViewDetailsTabComponent } from './tabs/view-details/view-details-tab.component';

@Component({
	templateUrl: './create-edit-user.component.html'
})
export class CreateEditUserComponent extends SimpleCreateEditModalDirective<User> implements OnInit {

	isInvite: boolean = false;

	initialDocuments: MediaWidgetItem[] = [];
	currentDocuments: MediaWidgetItem[] = [];

	schedules: ScheduleForViewing[] = [];

	isLoggedInUser: boolean = false;

	@ViewChild(ChangeDetailsTabComponent) changeUserDetailsTabComponent: ChangeDetailsTabComponent;
	@ViewChild(ViewDetailsTabComponent) viewUserDetailsTabComponent: ViewDetailsTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private userService: UserService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.isLoggedInUser = this.editModelId === this.loggedInUser.id;

		this.pending = true;
		try {
			if (!this.userService.cultureSettings) {
				this.userService.cultureSettings = await this.userService.getCultureSettings();
			}

			if (!this.userService.roles.length) {
				this.userService.roles = await this.userService.getRoles();
			}

			if (this.isEdit) {
				this.initialModel = this.isLoggedInUser ? new User(this.loggedInUser) : await this.userService.get(this.editModelId);

				if (this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)) {
					this.schedules = await this.userService.getSchedules(this.editModelId);
				}

				this.assignCultureSettings();

				this.initialModel.selectedOrganizationId = this.isLoggedInUser ? this.loggedInUser.organization.id : this.selectedOrganization.id;
				this.initialModel.rolesTemp = this.isLoggedInUser ? this.loggedInUser.roles : this.initialModel.getCurrentRoles(this.selectedOrganization.id);
				this.initialModel.statusTemp = this.isLoggedInUser ? this.loggedInUser.status : this.initialModel.getCurrentStatus(this.selectedOrganization.id);
				this.initialModel.membershipOrganizationId = !this.isLoggedInUser && this.selectedOrganization.id !== this.initialModel.organization.id ? this.selectedOrganization.id : null;

				this.modifiedModel = new User(this.getUniqueVariant(this.initialModel));

				if ((this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl) && this.canChangeDetails()) || this.isLoggedInUser) {
					this.initialDocuments = await this.userService.getPdfs(this.initialModel.id);
					this.currentDocuments = JSON.parse(JSON.stringify(this.initialDocuments));
				}

				this.pending = false;

				setTimeout(() => {
					this.activateTabFromUrl();
					this.triggerAllValidation();
				}, 0);
			} else {

				if (this.isInvite) {
					// Abort if not admin.
					if (!this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)) {
						this.displayErrorMessage(`${this.translationService.instant(StringUtils.NO_PERMISSION)}!`);
						this.closeWithDelay();
						return;
					}
				} else {
					// Abort if not superadmin.
					if (!this.loggedInUser.isSuperAdmin()) {
						this.displayErrorMessage(`${this.translationService.instant(StringUtils.NO_PERMISSION)}!`);
						this.closeWithDelay();
						return;
					}
				}

				this.initialModel = new User({});
				this.pending = false;

				this.assignCultureSettings();

				this.initialModel.organization = this.selectedOrganization;
				this.initialModel.organizationId = this.selectedOrganization.id;
				this.initialModel.selectedOrganizationId = this.selectedOrganization.id;
				this.initialModel.rolesTemp = [this.initialModel.selectableRoles.find(role => role.id === 'User').id];

				this.modifiedModel = new User(this.getUniqueVariant(this.initialModel));
			}

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	private assignCultureSettings() {
		this.initialModel.selectableCountryCodes = this.userService.cultureSettings.countryCodes;
		this.initialModel.selectableCountries = this.userService.cultureSettings.countries;
		this.initialModel.selectableTimeZones = this.userService.cultureSettings.timeZones;
		this.initialModel.selectableCultures = this.userService.cultureSettings.cultures;
		this.initialModel.selectableRoles = this.userService.roles;
	}

	canChangeDetails() {
		if (this.userToEditIsLoggedInUser()) {
			return true;
		} else {
			if (this.loggedInUser.isSuperAdmin()) {
				return true;
			} else {
				if (this.isUserOrgForUser()) {
					if (this.modifiedModel?.isSuperAdmin()) {
						return false;
					} else {
						return true;
					}
				} else {
					return false;
				}
			}
		}
	}

	canChangeAuthorization() {
		if (this.userToEditIsLoggedInUser()) {
			return false;
		} else {
			if (this.loggedInUser.isSuperAdmin()) {
				return true;
			} else {
				if (this.isUserOrgForUser()) {
					if (this.modifiedModel?.isSuperAdmin()) {
						return false;
					} else {
						return true;
					}
				} else {
					if (this.modifiedModel?.isSuperAdmin()) {
						return false;
					} else {
						return true;
					}
				}
			}
		}
	}

	private userToEditIsLoggedInUser() {
		return this.modifiedModel && this.modifiedModel.id === this.loggedInUser.id;
	}

	private isUserOrgForUser() {
		return this.modifiedModel && this.modifiedModel.organization.id === this.selectedOrganization.id;
	}

	handleValueChanges() {
		if (this.changeUserDetailsTabComponent) {
			const rolesTempCtrl = this.changeUserDetailsTabComponent.form.get(this.propertyStrings.rolesTemp);
			const statusTempCtrl = this.changeUserDetailsTabComponent.form.get(this.propertyStrings.statusTemp);
			const primary = this.modifiedModel.organization.id === this.selectedOrganization.id;
			const membership = this.modifiedModel.organizationMemberships.find(membership => membership.organization.id === this.selectedOrganization.id);
			if (primary) {
				this.modifiedModel.roles = rolesTempCtrl.value;
				if (statusTempCtrl) {
					this.modifiedModel.status = statusTempCtrl.value;
				}
			} else if (membership) {
				membership.roles = rolesTempCtrl.value;
				if (statusTempCtrl) {
					membership.status = statusTempCtrl.value;
				}
			}

			if (!this.isInvite) {
				const firstname = this.changeUserDetailsTabComponent.form.get(this.propertyStrings.firstname);
				const lastname = this.changeUserDetailsTabComponent.form.get(this.propertyStrings.lastname);
				if (!firstname?.value && !lastname?.value) {
					this.modifiedModel.fullName = '';
				} else {
					this.modifiedModel.fullName = `${firstname?.value} ${lastname?.value}`;
				}
			}
		}
	}

	changeDetailsIsValid() {
		return this.changeUserDetailsTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeUserDetailsTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.userService.update(this.modifiedModel.toPayloadObject(this.selectedOrganization.id, this.isLoggedInUser))
				: this.isInvite
					? await this.userService.invite(this.modifiedModel.toPayloadObject(this.selectedOrganization.id, this.isLoggedInUser))
					: await this.userService.create(this.modifiedModel.toPayloadObject(this.selectedOrganization.id, this.isLoggedInUser));

			if (((this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl) && this.canChangeDetails()) || this.isLoggedInUser) && this.mediaHasChanged()) {
				const pdfsToUpload = this.currentDocuments.filter(document => !!document.blob);
				const pdfsToDelete = this.initialDocuments.filter(document => !this.currentDocuments.some(cd => cd.id === document.id));

				if (pdfsToUpload.length) {
					await this.userService.uploadPdfs(this.modifiedModel.id, pdfsToUpload);
				}

				if (pdfsToDelete.length) {
					await this.userService.deletePdfs(this.modifiedModel.id, this.modifiedModel.organization.id, pdfsToDelete.map(pdf => pdf.id));
				}
			}

			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeUserDetailsTabComponent.setServerErrors(this.serverErrors);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeUserDetailsTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: User) {
		return new User(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.firstname !== this.initialModel.firstname
			|| this.modifiedModel.lastname !== this.initialModel.lastname
			|| this.modifiedModel.email !== this.initialModel.email
			|| this.modifiedModel.contactCountryCode !== this.initialModel.contactCountryCode
			|| this.modifiedModel.contactPhoneNumber !== this.initialModel.contactPhoneNumber
			|| this.modifiedModel.country !== this.initialModel.country
			|| this.modifiedModel.certificates !== this.initialModel.certificates
			|| this.modifiedModel.timeZone !== this.initialModel.timeZone
			|| this.modifiedModel.culture !== this.initialModel.culture
			|| !Utils.isEqual(this.modifiedModel.roles, this.initialModel.roles)
			|| this.modifiedModel.status !== this.initialModel.status
			|| !Utils.isEqual(this.modifiedModel.organizationMemberships, this.initialModel.organizationMemberships)
			|| this.mediaHasChanged();
	}

	private mediaHasChanged() {
		const currentMedia = this.stripActionsOpened(this.currentDocuments);
		const initialMedia = this.stripActionsOpened(this.initialDocuments);
		return !Utils.isEqual(currentMedia, initialMedia);
	}

	private stripActionsOpened(items: MediaWidgetItem[]) {
		return items.map(item => {
			const { actionsOpened, ...theRest } = item;
			return theRest as MediaWidgetItem;
		});
	}

	hasUnsavedChanges() {
		return this.modifiedModel && this.detailsHasChanged();
	}
}
