import { Component, Input } from '@angular/core';

@Component({
	selector: 'simple-form-info',
	templateUrl: './simple-form-info.component.html',
	styleUrls: ['./simple-form-info.component.less']
})
export class SimpleFormInfoComponent {
	@Input() firstText: string;
	@Input() linkText: string;
	@Input() linkHref: string;
	@Input() secondText: string;
	@Input() name: string;
}
