<simple-crud-modal
	[titleText]="'Organizations' | translate"
	[closeText]="'Close' | translate"
	[isLoading]="pending"
	(onClose)="close()">
		<inspire-tree
			[customClass]="'custom-inspire-tree'"
			(nodeSelected)="onNodeSelected($event)"
			(dataLoad)="onGetTreeData($event)">
		</inspire-tree>
</simple-crud-modal>
