import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { HelpComponent } from './help.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		PopoverModule
	],
	declarations: [
		HelpComponent
	],
	exports: [
		HelpComponent
	]
})
export class HelpModule { }
