import { CrudItem } from 'app-core/shared-core/simple-components/crud/crud-item';
import { InspectionColor } from 'app-core/shared-core/tools/utils';
import { RuleObject } from 'app-core/shared-core/simple-components/various/rules/simple-rules.component';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';

export class Choice extends CrudItem<Choice> {
	value: string = '';
	color: string = InspectionColor.DarkRed;
	isError: boolean = false;
	isDefault: boolean = false;
	isUnspecified: boolean = false;
	allowPriority: boolean = false;

	get rulesReadonly() {
		const rules: RuleObject[] = [];
		if (this.isError) {
			rules.push(new RuleObject({
				icon: 'bi bi-exclamation-circle red',
				title: 'IsError'
			}));
		}
		if (this.isDefault) {
			rules.push(new RuleObject({
				icon: 'bi bi-check-circle green',
				title: 'IsDefault'
			}));
		}
		if (this.isUnspecified) {
			rules.push(new RuleObject({
				icon: 'bi bi-slash-circle blue',
				title: 'IsUnspecified'
			}));
		}
		if (this.allowPriority) {
			rules.push(new RuleObject({
				icon: 'bi bi-arrow-up-circle red',
				title: 'AllowsPriority'
			}));
		}
		return rules;
	}

	constructor(choice: Partial<Choice>) {
		super();
		Object.assign(this, choice);
		this.setUrl(RoutesUtils.choices, RoutesUtils.choice);
	}

	toPayloadObject() {
		return new ChoicePayload({
			id: this.id,
			value: this.value,
			color: this.color,
			isError: this.isError,
			isDefault: this.isDefault,
			isUnspecified: this.isUnspecified,
			allowPriority: this.allowPriority
		});
	}
}

export class ChoicePayload extends Choice {
	constructor(choicePayload: Partial<ChoicePayload>) {
		super(choicePayload);
		Object.assign(this, choicePayload);
	}
}
