import {
	Component, OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleDeleteModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-delete-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { User } from '../user';
import { UserService } from '../user.service';

@Component({
	templateUrl: './delete-users.component.html'
})
export class DeleteUsersComponent extends SimpleDeleteModalDirective<User> implements OnInit {

	hideText: boolean = false;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private userService: UserService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		await this.getDeleteData();
	}

	async getDeleteData() {
		this.pending = true;
		try {
			const response = await this.userService.getDeleteData(this.deleteModelIds, this.selectedOrganization.id);
			this.affectableIds = response.data.itemIds;
			this.pending = false;
		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	override submit() {
		if (this.affectableIdsAreValid()) {
			super.submit();
		}
	}

	affectableIdsAreValid() {
		return !this.useAffectableIds || !!this.affectableIds.length;
	}

	protected async delete() {
		this.pending = true;
		try {
			const response = await this.userService.deleteWithOrgId(this.deleteModelIds[0], this.selectedOrganization.id);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected async deleteRange() {
		this.pending = true;
		try {
			const response = await this.userService.deleteRangeWithOrgId(this.affectableIds, this.selectedOrganization.id);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}
}
