import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { GoogleMapsComponent, LocationObject } from 'app-core/shared-core/google-maps/google-maps.component';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { RegexUtils } from 'app-core/shared-core/tools/regex-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Facility } from 'app-inspection/facility/facility';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'change-details-tab',
	templateUrl: './change-details-tab.component.html',
	styleUrls: ['./change-details-tab.component.less']
})
export class ChangeDetailsTabComponent extends SimpleChangeTabDirective<Facility> implements OnInit {

	selectableStatusItems: FormSelectItem[] = [];
	selectableAccessibilityItems: FormSelectItem[] = [];

	markdownTextAreaIsFocused: boolean = false;
	displayGeoInfoText: boolean = false;

	address: string = '';

	@ViewChild(GoogleMapsComponent) googleMapsComponent: GoogleMapsComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableStatusItems();
		this.setSelectableAccessibilityItems();

		this.displayGeoInfoText = this.changeModel.hasGeoControlledTemplates;

		this.form = this.formBuilder.group({
			[this.propertyStrings.name]: [this.changeModel.name,
			[Validators.required, Validators.maxLength(150)]
			],
			[this.propertyStrings.description]: [this.changeModel.description,
			Validators.maxLength(500)
			],
			[this.propertyStrings.status]: [this.changeModel.status,
			Validators.required
			],
			[this.propertyStrings.streetName]: [this.changeModel.streetName,
			Validators.maxLength(50)
			],
			[this.propertyStrings.streetNumber]: [this.changeModel.streetNumber,
			Validators.maxLength(15)
			],
			[this.propertyStrings.zipCode]: [this.changeModel.zipCode,
			Validators.maxLength(15)
			],
			[this.propertyStrings.city]: [this.changeModel.city,
			Validators.maxLength(25)
			],
			[this.propertyStrings.latitude]: [this.changeModel.latitude,
			this.latitudePattern
			],
			[this.propertyStrings.longitude]: [this.changeModel.longitude,
			this.longitudePattern
			],
			[this.propertyStrings.radius]: [this.changeModel.radius,
			this.radiusPattern
			],
			[this.propertyStrings.accessible]: [this.changeModel.accessible,
			Validators.required
			],
			[this.propertyStrings.infoLink]: [this.changeModel.infoLink,
			[
				Validators.maxLength(250),
				this.urlPattern
			]
			],
			[this.propertyStrings.externalId]: [this.changeModel.externalId,
			Validators.maxLength(50)
			]
		}, { validators: this.positionValidator });

		if (this.changeModel.hasGeoControlledTemplates) {
			this.form.setValidators(this.positionValidatorAlwaysRequired);
		}

		super.ngOnInit();

		if (this.changeModel.streetName && (this.changeModel.city || this.changeModel.zipCode)) {
			this.buildAddress(
				this.changeModel.streetName,
				this.changeModel.streetNumber,
				this.changeModel.zipCode,
				this.changeModel.city
			);
		}
	}

	handleMarkerMove(locationObject: LocationObject) {
		this.form.get(this.changeModel.propertyStrings.latitude).setValue(locationObject.latitude.toString());
		this.form.get(this.changeModel.propertyStrings.longitude).setValue(locationObject.longitude.toString());
		this.form.get(this.changeModel.propertyStrings.radius).setValue(locationObject.radius.toString());
	}

	isExtraSmallScreenSize() {
		return Utils.isExtraSmallScreenSize();
	}

	handleExtraInfoChange(text: string) {
		this.changeModel.extraInfo = text.trim();
	}

	handleMarkdownTextAreaFocus(focused: boolean) {
		this.markdownTextAreaIsFocused = focused;
	}

	protected async getAddressFromLocation() {
		if (this.validLocationData()) {
			const addressObject = await this.googleMapsComponent.getAddressFromLocation();
			if (addressObject) {
				this.form.get(this.changeModel.propertyStrings.streetName).setValue(addressObject.streetName ? addressObject.streetName : '');
				this.form.get(this.changeModel.propertyStrings.streetNumber).setValue(addressObject.streetNumber ? addressObject.streetNumber : '');
				this.form.get(this.changeModel.propertyStrings.zipCode).setValue(addressObject.zipCode ? addressObject.zipCode : '');
				this.form.get(this.changeModel.propertyStrings.city).setValue(addressObject.city ? addressObject.city : '');
			}
		}
	}

	protected async getLocationFromAddress() {
		if (this.validAddressData()) {
			const locationObject = await this.googleMapsComponent.getLocationFromAddress();
			if (locationObject) {
				const location = new google.maps.LatLng(parseFloat(locationObject.latitude), parseFloat(locationObject.longitude));
				this.googleMapsComponent.emitLocation(location);
			}
		}
	}

	protected async getCurrentLocation() {
		const locationObject = await this.googleMapsComponent.getCurrentLocation();
		if (locationObject) {
			const location = new google.maps.LatLng(parseFloat(locationObject.latitude), parseFloat(locationObject.longitude));
			this.googleMapsComponent.emitLocation(location);
		}
	}

	protected validLocationData() {
		const latitude = this.form.get(this.changeModel.propertyStrings.latitude);
		const longitude = this.form.get(this.changeModel.propertyStrings.longitude);
		const radius = this.form.get(this.changeModel.propertyStrings.radius);
		return !!latitude.value && !!latitude.valid && !!longitude.value && !!longitude.valid && !!radius.value && !!radius.valid;
	}

	protected validAddressData() {
		const streetName = this.form.get(this.changeModel.propertyStrings.streetName);
		const streetNumber = this.form.get(this.changeModel.propertyStrings.streetNumber);
		const zipCode = this.form.get(this.changeModel.propertyStrings.zipCode);
		const city = this.form.get(this.changeModel.propertyStrings.city);
		return !!streetName.value && !!streetName.valid && !!streetNumber.value && !!streetNumber.valid && !!zipCode.value && !!zipCode.valid && !!city.value && city.valid;
	}

	buildAddress(streetName: string, streetNumber: string, zipCode: string, city: string) {
		let address = '';
		if (streetName) {
			address += streetName;
		}
		if (streetNumber) {
			address += ' ' + streetNumber;
		}
		if (zipCode) {
			address += ', ' + zipCode;
		}
		if (city) {
			address += ' ' + city;
		}
		this.address = address;
	}

	private setSelectableStatusItems() {
		this.selectableStatusItems = [
			new FormSelectItem(true, this.translationService.instant(StringUtils.ACTIVE)),
			new FormSelectItem(false, this.translationService.instant(StringUtils.INACTIVE)),
		];
	}

	private setSelectableAccessibilityItems() {
		this.selectableAccessibilityItems = [
			new FormSelectItem(true, this.translationService.instant(StringUtils.YES)),
			new FormSelectItem(false, this.translationService.instant(StringUtils.NO)),
		];
	}

	private latitudePattern(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.LATITUDE).test(control.value)) {
			return { latitude: true };
		} else {
			return null;
		}
	}

	private longitudePattern(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.LONGITUDE).test(control.value)) {
			return { longitude: true };
		} else {
			return null;
		}
	}

	private radiusPattern(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.RADIUS).test(control.value)) {
			return { radius: true };
		} else {
			return null;
		}
	}

	private urlPattern(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.URL).test(control.value)) {
			return { url: true };
		} else {
			return null;
		}
	}

	private positionValidator(form: FormGroup): ValidationErrors {
		const latitude = form.get('latitude');
		const longitude = form.get('longitude');
		const radius = form.get('radius');

		if (latitude.value && (!longitude.value || !radius.value)) {
			if (!longitude.value) {
				longitude.setErrors({ required: true });
				longitude.markAsTouched();
			}
			if (!radius.value) {
				radius.setErrors({ required: true });
				radius.markAsTouched();
			}
		} else if (longitude.value && (!latitude.value || !radius.value)) {
			if (!latitude.value) {
				latitude.setErrors({ required: true });
				latitude.markAsTouched();
			}
			if (!radius.value) {
				radius.setErrors({ required: true });
				radius.markAsTouched();
			}
		} else if (radius.value && (!latitude.value || !longitude.value)) {
			if (!latitude.value) {
				latitude.setErrors({ required: true });
				latitude.markAsTouched();
			}
			if (!longitude.value) {
				longitude.setErrors({ required: true });
				longitude.markAsTouched();
			}
		} else if (!latitude.value && !longitude.value && !radius.value) {
			latitude.setErrors(null);
			longitude.setErrors(null);
			radius.setErrors(null);
		}
		return null;
	}

	private positionValidatorAlwaysRequired(form: FormGroup): ValidationErrors {
		const latitude = form.get('latitude');
		const longitude = form.get('longitude');
		const radius = form.get('radius');

		if (!latitude.value) {
			latitude.setErrors({ required: true });
		}
		if (!longitude.value) {
			longitude.setErrors({ required: true });
		}
		if (!radius.value) {
			radius.setErrors({ required: true });
		}
		return null;
	}
}
