<div class="mw-item" #parentDiv
	[title]="'Open' | translate"
	[style.width]="itemSize + 'px'"
	[style.height]="itemSize + 'px'"
	(click)="open()">
		<div class="mw-item-selected-image" *ngIf="item.isImage && item.default">
			{{ 'SelectedSingularis' | translate }}
		</div>
		<div class="mw-item-new-item" *ngIf="item.blob">
			{{ 'New' | translate }}
		</div>
		<extensive-image *ngIf="item.isImage"
			[imageUrl]="item.url"
			[imageStorageSize]="parentDiv | getImageStorageSize"
			[blob]="item.blob ?? null"
			[displayLoader]="true">
		</extensive-image>
		<extensive-pdf *ngIf="item.isPDF"
			[pdfUrl]="item.url"
			[blob]="item.blob ?? null"
			[thumbnailVersion]="true"
			[displayLoader]="true">
		</extensive-pdf>
</div>

<div class="mw-item-footer"
	[title]="item.title"
	[ngClass]="{
		'pinned': item.actionsOpened,
		'has-menu-button': !viewOnly
	}">
		<div class="title">
			{{ item.title }}
		</div>
		<ng-container *ngIf="!viewOnly">
			<div class="options-menu bi-three-dots-vertical" #popover="bs-popover"
				title="{{ 'OpenMenu' | translate }}"
				[popover]="popTemplate"
				[ngClass]="{
					'opened': item.actionsOpened
				}"
				container="body"
				placement="left"
				containerClass="standard-menu-popover"
				[outsideClick]="true"
				(onShown)="item.actionsOpened = true"
				(onHidden)="item.actionsOpened = false">
			</div>
			<ng-template #popTemplate>
				<div class="standard-menu-item" *ngIf="item.isImage && canToggleDefault"
					[ngClass]="{
						'disabled': item.default
					}"
					(click)="popover.hide(); setAsDefault()">
						{{ "SelectAsCoverImage" | translate }}
				</div>
				<div class="standard-menu-item" *ngIf="!item.blob"
					(click)="popover.hide(); download()">
						{{ "Download" | translate }}
				</div>
				<div class="standard-menu-item"
					(click)="remove()">
						{{ "Remove" | translate }}
				</div>
			</ng-template>
		</ng-container>
</div>
