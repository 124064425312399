import { Directive } from '@angular/core';
import { BackendResponse } from 'app-core/shared-core/abstract-components/service/base.service';
import { SimpleCrudDirective } from '../simple-crud.directive';

@Directive()
export abstract class SimpleDeleteModalDirective<T extends { id: string }> extends SimpleCrudDirective<T> {

	deleteModelIds: string[] = [];
	affectableIds: string[] = [];
	useAffectableIds: boolean = false;

	get isSingleDelete() {
		return this.deleteModelIds.length === 1;
	}

	getSelectedCountText(text: string) {
		const count = this.useAffectableIds ? this.affectableIds.length : this.deleteModelIds.length;
		return `${text} (${count})`;
	}

	submit() {
		if (this.isSingleDelete) {
			this.delete();
		} else {
			this.deleteRange();
		}
	}

	handleSuccessResponse(response: BackendResponse, customMessage?: string) {
		if (response.data?.backgroundJobStarted) {
			this.displaySuccessMessage(customMessage);
		} else {
			this.displaySuccessMessage(response.successMessage);
		}
		this.deleted$.next(this.deleteModelIds);
	}

	protected abstract delete(): void;
	protected abstract deleteRange(): void;
}
