<simple-crud-modal
	[titleText]="titleText | translate"
	[closeText]="'Close' | translate"
	(onClose)="close()">
		<div class="wrapper">
			<div class="header">
				<div class="section">
					<div class="text">
						{{ firstLabel }}
					</div>
					<div class="text secondary">
						{{ secondLabel }}
					</div>
				</div>
				<div class="section">
					<div class="text">
						{{ thirdLabel }}
					</div>
				</div>
			</div>
			<div class="item-wrapper">
				<div class="item" *ngFor="let assignment of assignments">
					<div class="info">
						<div class="text" [title]="assignment.dueDate ? assignment.name : assignment.facilityName">
							{{ assignment.dueDate ? assignment.name : assignment.facilityName }}
						</div>
						<div class="text secondary" [title]="assignment.dueDate ? assignment.facilityName : assignment.entityName">
							{{ assignment.dueDate ? assignment.facilityName : assignment.entityName }}
						</div>
					</div>
					<div class="info flex">
						<div class="text date" [title]="assignment[property] | datex">
							{{ assignment[property] | datex }}
						</div>
						<simple-link-button
							[url]="assignment.url"
							[name]="assignment.name">
						</simple-link-button>
					</div>
				</div>
			</div>
		</div>
</simple-crud-modal>