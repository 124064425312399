<ng-container *ngIf="shouldDisplay">
	<div class="notice-icon" #noticeIconPop="bs-popover"
		[ngClass]="iconClass"
		[title]="titleText"
		[placement]="placement"
		[containerClass]="'ni-menu-popover'"
		[popover]="noticeIconTemplate"
		[outsideClick]="true"
		[style.top]="topPx"
		[style.left]="rightPx ? null : leftPx"
		[style.right]="rightPx"
		[style.position]="position"
		(click)="shouldStopPropagation ? $event.stopPropagation() : null">
	</div>

	<ng-template #noticeIconTemplate>
		<div class="ni-menu-item">
			{{ noticeText }}
		</div>
	</ng-template>
</ng-container>
