<div class="timeline-wrapper">
	<div class="data-wrapper" *ngIf="items.length">
		<ng-container *ngFor="let item of items; let index = index">
			<div class="timeline-year" *ngIf="shouldDisplayYear(item)"
				[ngClass]="{
					'visible': item.display
				}"
				title="{{ item.yearString }}"
				style="--delay: {{index * 0.1}}s">
					<div class="text">
						{{ item.yearString }}
					</div>
					<div class="line"></div>
			</div>
			<div class="timeline-item" *ngIf="index < previewCount"
				[ngClass]="{
					'visible': item.display
				}"
				title="{{ item.infoText }}"
				style="--delay: {{index * 0.1}}s">
					<div class="text-wrapper">
						<div class="title">
							{{ item.dayAndMonthString }}
						</div>
						<div class="subtitle">
							{{ item.dayNameString }}
						</div>
					</div>
					<div class="line"></div>
					<div class="dot"></div>
			</div>
		</ng-container>

		<div class="timeline"
			[ngClass]="{
				'visible': items.length
			}">
		</div>
	</div>

	<div class="empty-state" *ngIf="!items.length">
		{{ 'NoPublicationsText' | translate }}
	</div>
</div>
