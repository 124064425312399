import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { canActivateFn } from 'app-core/auth/auth-guard/auth-guard.service';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { ErrorComponent } from '../../app-core/error/error.component';
import { ListAssignmentMeasureArchivedComponent } from './list-assignment/measure/archived/list-assignment-measure-archived.component';
import { ListAssignmentMeasureOngoingComponent } from './list-assignment/measure/ongoing/list-assignment-measure-ongoing.component';
import { ListAssignmentScheduledArchivedComponent } from './list-assignment/scheduled/archived/list-assignment-scheduled-archived.component';
import { ListAssignmentScheduledOngoingComponent } from './list-assignment/scheduled/ongoing/list-assignment-scheduled-ongoing.component';

@NgModule({
	imports: [
		RouterModule.forChild([

			// When only '/assignments'.
			// Redirect to ongoing.
			{
				path: RoutesUtils.empty,
				pathMatch: 'full',
				redirectTo: RoutesUtils.scheduledOngoing
			},
			{
				path: RoutesUtils.scheduledOngoing,
				component: ListAssignmentScheduledOngoingComponent,
				canActivate: [canActivateFn],
				data: {
					title: 'OngoingScheduledAssignments'
				}
			},
			{
				path: RoutesUtils.scheduledArchived,
				component: ListAssignmentScheduledArchivedComponent,
				canActivate: [canActivateFn],
				data: {
					title: 'ArchivedScheduledAssignments'
				}
			},
			{
				path: RoutesUtils.measureOngoing,
				component: ListAssignmentMeasureOngoingComponent,
				canActivate: [canActivateFn],
				data: {
					title: 'OngoingMeasureAssignments'
				}
			},
			{
				path: RoutesUtils.measureArchived,
				component: ListAssignmentMeasureArchivedComponent,
				canActivate: [canActivateFn],
				data: {
					title: 'ArchivedMeasureAssignments'
				}
			},

			// When no route matches.
			// Show the page not found component.
			{
				path: RoutesUtils.notSpecified,
				component: ErrorComponent,
				canActivate: [canActivateFn],
				data: {
					title: 'PageNotFound',
					isInvalid: true
				}
			}
		])
	],
	exports: [
		RouterModule
	]
})
export class AssignmentRoutingModule { }
