import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorTypeKey } from './error-type';

@Injectable()
export class ErrorService implements OnDestroy {
	errorOfType$ = new BehaviorSubject<ErrorTypeKey>(ErrorTypeKey.Default);

	ngOnDestroy() {
		this.errorOfType$.complete();
	}
}
