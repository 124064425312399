import { NgModule } from '@angular/core';
import { MediaWidgetComponent } from './widget/media-widget.component';
import { MediaWidgetItemComponent } from './widget/item/media-widget-item.component';
import { MediaWidgetItemViewerComponent } from './widget/item/viewer/media-widget-item-viewer.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PipesModule } from 'app-core/shared-core/pipes/pipes.module';
import { SimpleComponentsModule } from 'app-core/shared-core/simple-components/simple-components.module';
import { DotsLoaderModule } from 'app-core/shared-core/dots-loader/dots-loader.module';
import { HandleMediaTabComponent } from './handle-media/handle-media-tab.component';
import { ExtensiveMediaModule } from './extensive-image/extensive-media.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		PopoverModule,
		PipesModule,
		SimpleComponentsModule,
		DotsLoaderModule,
		ExtensiveMediaModule
	],
	declarations: [
		MediaWidgetItemComponent,
		MediaWidgetComponent,
		MediaWidgetItemViewerComponent,
		HandleMediaTabComponent
	],
	exports: [
		MediaWidgetComponent,
		MediaWidgetItemViewerComponent,
		HandleMediaTabComponent
	]
})
export class MediaModule { }
