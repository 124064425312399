import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';
import { RuleObject } from 'app-core/shared-core/simple-components/various/rules/simple-rules.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { ListUserComponent } from 'app-core/user/list-user/list-user.component';
import { User } from 'app-core/user/user';
import { ScheduleGroup, ScheduleUser } from 'app-inspection/schedule/schedule';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'handle-schedule-users-tab',
	templateUrl: './handle-schedule-users-tab.component.html',
	styleUrls: ['./handle-schedule-users-tab.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandleScheduleUsersTabComponent extends SimpleHandleTabDirective<ScheduleUser> {

	globalDummyUser = new ScheduleUser({});

	// Special ovverride due to using ScheduleUser.
	bsModalRef: BsModalRef<any>;

	@Input() selectedScheduleGroups: ScheduleGroup[] = [];

	toggleListUserModal() {
		this.bsModalRef = this.modalService.show(
			ListUserComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: this.readonlyListModal,
					hideTableHeaderActions: this.readonlyListModal,
					hideTableRowActions: this.readonlyListModal,
					idsToSetAsSelected: this.handleItems.map(item => item.user).map(item => item.id)
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	itemListIsValid() {
		return true;
	}

	override emitModelsChange() {
		this.handleItems.sortByProperty('user fullName');
		super.emitModelsChange();
	}

	protected validate() { }

	initiateGlobalRules() {
		const selectedItems = this.handleItems.filter(model => model.selected === true);
		this.globalDummyUser.canPerformScheduled = selectedItems.every(item => item.canPerformScheduled);
		this.globalDummyUser.canPerformMeasure = selectedItems.every(item => item.canPerformMeasure);
		this.globalDummyUser.canAssign = selectedItems.every(item => item.canAssign);
	}

	handleRuleClick(item: ScheduleUser, rule: RuleObject) {
		const propertyName = Utils.uncapitalizeFirstLetter(rule.title);
		item[propertyName] = !item[propertyName];

		if (propertyName === this.propertyStrings.canAssign && item.canAssign) {
			item.canPerformScheduled = true;
		} else if (propertyName === this.propertyStrings.canPerformScheduled && !item.canPerformScheduled) {
			item.canAssign = false;
		}
	}

	handleRuleClickGlobal(rule: RuleObject) {
		const propertyName = Utils.uncapitalizeFirstLetter(rule.title);
		this.globalDummyUser[propertyName] = !this.globalDummyUser[propertyName];
		const selectedItems = this.handleItems.filter(model => model.selected === true);
		selectedItems.forEach(item => item[propertyName] = this.globalDummyUser[propertyName]);

		if (propertyName === this.propertyStrings.canAssign && this.globalDummyUser.canAssign) {
			this.globalDummyUser.canPerformScheduled = true;
			selectedItems.forEach(item => item.canPerformScheduled = true);
		} else if (propertyName === this.propertyStrings.canPerformScheduled && !this.globalDummyUser.canPerformScheduled) {
			this.globalDummyUser.canAssign = false;
			selectedItems.forEach(item => item.canAssign = false);
		}
	}

	isMediumScreenSize() {
		return Utils.isMediumScreenSize();
	}

	override handleConfirmed(confirmedItems: User[]) {
		const mappedConfirmedItems = confirmedItems.map(confirmedItem => {
			const existingItem = this.handleItems.find(item => item.user.id === confirmedItem.id);
			if (existingItem) {
				return existingItem;
			} else {
				return new ScheduleUser({
					user: confirmedItem,
					userId: confirmedItem.id
				});
			}
		});
		this.handleItems = this.constructItemList(this.handleItems, mappedConfirmedItems);
		this.setAllAreSelected();
		this.setSomeAreSelected();
		this.emitModelsChange();
		this.closeModal();
	}

	override constructItemList(currentItemList: ScheduleUser[], confirmedItemList: ScheduleUser[]) {
		// Reduce to only confirmed items.
		currentItemList = currentItemList
			.filter(currentItem => confirmedItemList.find(confirmedItem => confirmedItem.user.id === currentItem.user.id));

		// Apply only new items.
		currentItemList = currentItemList
			.concat(confirmedItemList
				.filter(confirmedItem => !currentItemList.find(currentItem => currentItem.user.id === confirmedItem.user.id)));

		return currentItemList;
	}

	override remove(itemToRemove: ScheduleUser) {
		this.handleItems = this.handleItems.filter(model => model.user.id !== itemToRemove.user.id);
		this.setAllAreSelected();
		this.setSomeAreSelected();
		this.emitModelsChange();
	}

	userExistsInAnySelectedScheduleGroup(scheduleUser: ScheduleUser) {
		return this.selectedScheduleGroups.some(scheduleGroup => scheduleGroup.userGroup.users.some(user => user.id === scheduleUser.user.id));
	}

	getItems() {
		return this.searchValue
			? this.handleItems.filter(item => item.user.fullName.toLowerCase().includes(this.searchValue.toLowerCase())
				|| item.user.email.toLowerCase().includes(this.searchValue.toLowerCase()))
			: this.handleItems;
	}
}
