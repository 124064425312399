export abstract class SimpleAction {

	readonly name: string;
	readonly iconName: string;

	constructor(
		name: string,
		iconName: string) {
		this.name = name;
		this.iconName = iconName;
	}
}
