import {
	Component,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { Client } from 'app-core/organization/organization';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { AssignmentTemplate } from 'app-inspection/assignment-template/assignment-template';
import { AssignmentTemplateService } from 'app-inspection/assignment-template/assignment-template.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HandleClientTabComponent } from './tabs/handle-client-tab.component';

@Component({
	templateUrl: './handle-client.component.html'
})
export class HandleClientComponent extends SimpleBulkEditModalDirective<AssignmentTemplate> implements OnInit {

	@ViewChild(HandleClientTabComponent) handleClientTabComponent: HandleClientTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private assignmentTemplateService: AssignmentTemplateService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			const clients = await this.assignmentTemplateService.getClientsFromItems(this.editModelIds, this.selectedOrganization.id);
			const selectableClients = await this.assignmentTemplateService.getClients(this.selectedOrganization.id);
			this.pending = false;

			this.initialModel = new AssignmentTemplate({
				client: clients.length ? new Client(clients[0]) : null,
				selectableClients: selectableClients
			});

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	changeDetailsIsValid() {
		return this.handleClientTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.handleClientTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = await this.assignmentTemplateService.updateClient(this.editModelIds, this.initialModel.clientId, this.selectedOrganization.id);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.handleClientTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: AssignmentTemplate) {
		return new AssignmentTemplate(item);
	}

	hasUnsavedChanges() {
		return false;
	}
}
