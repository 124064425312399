<div class="statistics"
	[ngClass]="{
		'not-clickable': error,
		'refreshing': refreshing
	}"
	title="{{
		!error && !refreshing
			? ('ShowStatistics' | translate) + ' - ' + statisticsPreview?.refreshed
			: ''
	}}"
	(click)="!refreshing && !error ? openModal(statisticsModal) : null">
	<i class="icon"></i>

	<ng-container *ngTemplateOutlet="previewTemplate"></ng-container>
	<ng-container *ngTemplateOutlet="pendingTemplate"></ng-container>
	<ng-container *ngTemplateOutlet="errorTemplate"></ng-container>

	<div *ngIf="!refreshing"
		class="refresh"
		[ngClass]="{'disabled': refreshing}"
		title="{{
			!error && statisticsPreview?.refreshed
				? ('RefreshStatistics' | translate) + ' - ' + statisticsPreview?.refreshed
				: ('RefreshStatistics' | translate)
		}}"
		(click)="refreshStatistics($event)">
		<i class="icon"></i>
	</div>
</div>

<!-- Preview template -->
<ng-template #previewTemplate>
	<ng-container *ngIf="!refreshing && !error;">
		<div *ngIf="statisticsPreview != null"
			class="statistics-data preview"
			[ngClass]="{ 'near-time-data': statisticsPreview.lastDay }">
			<div class="assignment">
				<span class="type">
					<b>
						{{
							statisticsPreview.lastDay
								? (statisticsTypesEnum[statisticsPreview.type] + "AssignmentStatistics" | translate)
								: ("Statistics" | translate)
						}}
					</b>
				</span>

				<span class="vertical-lign"></span>

				<ng-container *ngIf="!statisticsPreview.lastDay;">
					<span class="empty-text">{{ 'NoCompletedAssignmentsToday' | translate }}</span>
				</ng-container>

				<ng-container>
					<span *ngIf="statisticsPreview.highestDayTotal" class="today-date"
						title="{{ 'BestDaySoFar' | translate }}">
						&#9733;
					</span>
					<span class="value mobile" [ngClass]="{
						'has-value': statisticsPreview.lastDay,
						'first': statisticsPreview.lastDay && statisticsPreview.lastHour}">
							{{ "LastDay" | translate }}: {{ statisticsPreview.lastDay.toLocaleString(locale) }}
					</span>
					<span class="value has-value" *ngIf="statisticsPreview.lastHour">
						{{ "LastHour" | translate }}: {{ statisticsPreview.lastHour.toLocaleString(locale) }}
					</span>
				</ng-container>
			</div>
			<ng-container *ngFor="let newData of newData$ | async">
				<div *ngIf="newData?.type === statisticsTypesEnum.Completed && newData?.value > 0"
					class="new-data-notification {{ statisticsTypesEnum[newData.type] }} {{ newData.class }}"
					title="{{ newData.value + ' ' + newData.title }}"
					[ngClass]="{
						'small': newData.value < 100,
						'medium': newData.value >= 100,
						'large': newData.value >= 1000
					}">
						<span>{{ newData.value }}</span>
				</div>
			</ng-container>
		</div>
	</ng-container>
</ng-template>

<!-- Pending template -->
<ng-template #pendingTemplate>
	<ng-container *ngIf="refreshing && !error;">
		<span>
			{{ "FetchingStatistics" | translate }}...
		</span>
	</ng-container>
</ng-template>

<!-- Error template -->
<ng-template #errorTemplate>
	<ng-container *ngIf="error">
		<span>
			{{ error | translate }}...
		</span>
		<span class="mobile">
			{{ error + "Mobile" | translate }}...
		</span>
	</ng-container>
</ng-template>

<!-- Statistics modal -->
<ng-template #statisticsModal>
	<ng-container>
		<statistics-modal
			class="statistics-modal"
			[statistics]="statistics"
			(onClose)="closeModal()"
			(refresh)="refreshStatistics($event)">
		</statistics-modal>
	</ng-container>
</ng-template>