import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { UserSupportService } from 'app-core/user-support/user-support.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { NotificationMessageModalComponent } from '../modal/notification-message-modal.component';
import { NotificationMessage } from '../notification-message';
import { NotificationMessageDirective } from '../notification-message.directive';

@Component({
	selector: 'important-message',
	templateUrl: './important-message.component.html',
	styleUrls: ['./important-message.component.less']
})
export class ImportantMessageComponent extends NotificationMessageDirective implements OnInit, OnDestroy {

	currentMessage: NotificationMessage;
	messageVisible: boolean = false;
	repeat: boolean = false;

	private currentIndex: number = 0;
	private preventMouseEvents: boolean = false;
	private animationTimer: NodeJS.Timeout;
	private displayTimer: NodeJS.Timeout;

	constructor(
		protected userSupportService: UserSupportService,
		private modalService: BsModalService) {
		super(
			userSupportService
		);
	}

	async ngOnInit() {
		super.ngOnInit();
		document.addEventListener('visibilitychange', () => {
			if (this.repeat) {
				if (document.hidden) {
					this.clearTimers();
				} else {
					this.switchMessage();
				}
			}
		});
	}

	protected mapData() {
		this.notificationMessages = this.file.notificationMessages.filter(message => message.important && this.shouldDisplay(message));
		if (this.notificationMessages.length) {
			this.repeat = this.notificationMessages.length > 1;
			this.switchMessage();
		}
	}

	openNotificationMessageModal() {
		if (!this.bsModalRef) {
			this.bsModalRef = this.modalService.show(
				NotificationMessageModalComponent,
				{
					initialState: {
						notificationMessage: this.currentMessage
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM
				}
			);
			this.modalCloseSubscription = this.bsModalRef.content.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe((wasClosed: boolean) => {
					if (wasClosed) {
						this.closeModal();
					}
				});
		}
	}

	private switchMessage() {
		this.preventMouseEvents = true;
		this.messageVisible = false;
		clearTimeout(this.animationTimer);
		this.animationTimer = setTimeout(() => {
			const nextIndex = this.currentIndex + 1;
			if (this.notificationMessages[nextIndex]) {
				this.currentIndex = nextIndex;
			} else {
				this.currentIndex = 0;
			}
			this.currentMessage = this.notificationMessages[this.currentIndex];
			this.preventMouseEvents = false;
			this.messageVisible = true;
			if (this.repeat) {
				this.callToSwitchMessageAfterDelay();
			}
		}, NumberUtils.IMPORTANT_MESSAGE_ANIMATION_TIME);
	}

	private callToSwitchMessageAfterDelay() {
		clearTimeout(this.displayTimer);
		this.displayTimer = setTimeout(() => {
			this.switchMessage();
		}, NumberUtils.IMPORTANT_MESSAGE_DISPLAY_TIME);
	}

	private clearTimers() {
		clearTimeout(this.animationTimer);
		clearTimeout(this.displayTimer);
	}

	handleMouseEnter() {
		if (!this.preventMouseEvents) {
			this.clearTimers();
		}
	}

	handleMouseLeave() {
		if (!this.preventMouseEvents) {
			this.callToSwitchMessageAfterDelay();
		}
	}

	handleNextMessageClick() {
		this.switchMessage();
	}

	ngOnDestroy() {
		this.clearTimers();
		document.removeAllListeners();
		super.ngOnDestroy();
	}
}
