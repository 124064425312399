import { inject, Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Params, Router,
	RouterStateSnapshot
} from '@angular/router';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { LoggedInUser } from 'app-core/user/user';
import { AppInsights } from 'applicationinsights-js';
import { environment } from '../../../environments/environment';
import { AppMonitoringService } from '../../shared-core/app-monitoring/app-monitoring.service';
import { LocalStorageKey, LocalStorageService } from '../../shared-core/local-storage/local-storage.service';
import { AuthService } from '../auth.service';
import { AuthGuardHelper } from './auth-guard-helper';

const ADMIN_ROUTES = [
	RoutesUtils.assignmentTemplates,
	RoutesUtils.facilities,
	RoutesUtils.entities,
	RoutesUtils.schedules,
	RoutesUtils.userGroups,
	RoutesUtils.dashboard
];

const SUPERADMIN_ROUTES = [
	RoutesUtils.templateTypes,
	RoutesUtils.tasks,
	RoutesUtils.choices,
	RoutesUtils.administration
];

/**
 * A service that guards our routes, a user can not access a route without the appropriate permissions.
 */
@Injectable()
export class AuthGuardService {
	private params: Params;
	private url: string;
	private isInvalidRoute: boolean;
	private loggedInUser: LoggedInUser;

	constructor(
		private authService: AuthService,
		private router: Router,
		private authGuardHelper: AuthGuardHelper,
		private localStorageService: LocalStorageService,
		private appMonitoringService: AppMonitoringService) {
		this.loggedInUser = this.authService.loggedInUser;

		if (this.loggedInUser) {
			AppInsights.setAuthenticatedUserContext(this.loggedInUser.id);
		}
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		this.params = route.params;
		this.url = state.url;
		this.isInvalidRoute = route.data.isInvalid;
		return this.hasPermission();
	}

	private hasPermission(): Promise<boolean> {
		return new Promise(resolve => {
			if (this.loggedInUser) {
				this.authGuardHelper.resolveOrgParam(this.params.org, this.url, this.isInvalidRoute)
					.then(shouldProceed => {
						if (shouldProceed) {
							if (this.isRestrictedRoute()) {
								if (this.userIsAllowed()) {
									// Allow, the user has the correct permission.
									resolve(true);
									this.appMonitoringService.logPageView('Name', this.url);
								} else {
									// Deny, the user does not have the correct permission.
									// A redirect to profile will be made.
									resolve(false);
								}
							} else {
								// Allow, the route is open for all.
								resolve(true);
								this.appMonitoringService.logPageView('Name', this.url);
							}
						} else {
							// Deny, an organization redirect will be made.
							resolve(false);
						}
					})
					.catch(response => {
						if (!environment.production) {
							console.warn(response);
						}
						this.authService.startLogout();
					});
			} else {
				// Store the first url the user tried to visit in order to use it for redirect later after a successful login.
				if (!this.localStorageService.getItem(LocalStorageKey.RedirectUrl)) {
					this.localStorageService.setItem(LocalStorageKey.RedirectUrl, this.url);
				}
				// Redirect to login.
				this.authService.startLogin();
				resolve(false);
			}
		});
	}

	private isRestrictedRoute() {
		return this.isAdminRoute() || this.isSuperAdminRoute();
	}

	// Permission checks for restricted routes.
	private userIsAllowed() {
		if (this.isAdminRoute() && (this.loggedInUser.isOrgAdmin(this.params.org) || this.loggedInUser.isSuperAdmin())) {
			return true;
		} else if (this.isSuperAdminRoute() && this.loggedInUser.isSuperAdmin()) {
			return true;
		} else {
			this.performRedirect(`/${RoutesUtils.profile}`);
			return false;
		}
	}

	private performRedirect(destination: string) {
		destination = this.params.org + destination;
		this.router.navigate([destination]);
	}

	private isAdminRoute() {
		return ADMIN_ROUTES.some(route => this.url.includes(route));
	}

	private isSuperAdminRoute() {
		return SUPERADMIN_ROUTES.some(route => this.url.includes(route));
	}
}

export const canActivateFn: CanActivateFn =
	(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		return inject(AuthGuardService).canActivate(route, state);
	};
