import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
	ChangeDetectionStrategy, Component, EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ReplaySubject, Subscription, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SimpleListDataSource } from '../../simple-list-data-source';
import { SimpleTableConfig } from '../simple-table-config';
import { SimpleTableRow } from './simple-table-row';
import { SimpleTableRowAction } from './simple-table-row-action';

@Component({
	selector: 'simple-table-body',
	templateUrl: './simple-table-body.component.html',
	styleUrls: ['./simple-table-body.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTableBodyComponent<T extends { id: string }> implements OnInit, OnDestroy {

	protected subscriptions = new Subscription();
	protected destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

	divSwitcher: boolean = false;

	@Input() isInModal: boolean;
	@Input() isCompactView: boolean;
	@Input() pending: boolean;
	@Input() config: SimpleTableConfig<T>;
	@Input() dataSource: SimpleListDataSource<T>;
	@Input() hasActiveSearchOrFilter: boolean;
	@Input() filterOpened: boolean;

	@Output() onActionClick = new EventEmitter<[SimpleTableRowAction<T>, SimpleTableRow<T>]>();
	@Output() onCheckboxClick = new EventEmitter<[MouseEvent, SimpleTableRow<T>]>();
	@Output() onRowClick = new EventEmitter<SimpleTableRow<T>>();
	@Output() onRowDoubleClick = new EventEmitter<SimpleTableRow<T>>();
	@Output() onPopoverOpen = new EventEmitter();

	@ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

	@ViewChildren('rowActionsPop') popovers: PopoverDirective[];

	ngOnInit() {
		if (this.config.columns.some(column => column.useDivSwitching)) {
			this.initiateDivSwitching();
		}
	}

	popoverOpened() {
		this.onPopoverOpen.emit();
	}

	actionClick(action: SimpleTableRowAction<T>, row: SimpleTableRow<T>) {
		this.onActionClick.emit([action, row]);
	}

	checkboxClick(event: MouseEvent, row: SimpleTableRow<T>) {
		if (!row.unselectable) {
			this.onCheckboxClick.emit([event, row]);
		}
	}

	rowClick(row: SimpleTableRow<T>) {
		if (!row.unselectable) {
			this.onRowClick.emit(row);
		}
	}

	rowDoubleClick(row: SimpleTableRow<T>) {
		this.onRowDoubleClick.emit(row);
	}

	closeOthers(clickedPopover: PopoverDirective) {
		this.popovers.filter(popover => popover.popoverId !== clickedPopover.popoverId).forEach(popover => popover.hide());

		// Closes memberpopover in view popover if any.
		const viewMembersPopoverMenu = document.body.querySelector('.vm-menu-popover') as HTMLElement;
		if (viewMembersPopoverMenu) {
			viewMembersPopoverMenu.remove();
		}

		// Closes headerActionsPopover if any.
		const headerActionsPopoverMenu = document.body.querySelector('.header-actions-popover') as HTMLElement;
		if (headerActionsPopoverMenu) {
			headerActionsPopoverMenu.remove();
		}
	}

	initiateDivSwitching() {
		this.subscriptions.add(
			interval(5000)
				.pipe(
					takeUntil(this.destroyed$)
				).subscribe(() => {
					this.divSwitcher = !this.divSwitcher;
					this.dataSource.rows$.next([...this.dataSource.rows$.value]);
				})
		);
	}

	trackByFn(index: number) {
		return index;
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this.subscriptions.unsubscribe();
	}
}
