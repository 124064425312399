import { NgModule } from '@angular/core';
import { MediaModule } from 'app-core/media/media-module';
import { SharedModule } from 'app-core/shared-core/shared.module';
import { CreateEditEntityComponent } from './create-edit-entity/create-edit-entity.component';
import { ChangeDetailsTabComponent } from './create-edit-entity/tabs/change-details/change-details-tab.component';
import { ViewDetailsTabComponent } from './create-edit-entity/tabs/view-details/view-details-tab.component';
import { DeleteEntitiesComponent } from './delete-entities/delete-entities.component';
import { EntityRoutingModule } from './entity-routing.module';
import { ListEntityComponent } from './list-entity/list-entity.component';
import { SetAccessibilityEntityComponent } from './list-entity/set-accessibility/set-accessibility-entity.component';
import { SetAccessibilityTabComponent } from './list-entity/set-accessibility/tabs/set-accessibility-tab.component';
import { SetStatusEntityComponent } from './list-entity/set-status/set-status-entity.component';
import { SetStatusTabComponent } from './list-entity/set-status/tabs/set-status-tab.component';

@NgModule({
	imports: [
		SharedModule,
		EntityRoutingModule,
		MediaModule
	],
	declarations: [
		ListEntityComponent,
		CreateEditEntityComponent,
		ChangeDetailsTabComponent,
		DeleteEntitiesComponent,
		SetStatusEntityComponent,
		SetStatusTabComponent,
		SetAccessibilityEntityComponent,
		SetAccessibilityTabComponent,
		ViewDetailsTabComponent
	]
})
export class EntityModule { }
