<simple-change-tab [form]="form">
    <div class="change-section">
        <div class="change-section-header">
            {{ "General" | translate }}
        </div>
        <simple-form-select
            [label]="'TemplateType' | translate"
            [name]="propertyStrings.templateTypeId"
            [form]="form"
            [disabled]="isEdit"
            [items]="selectableTemplateTypeItems"
            [searchable]="true"
            [serverErrors]="serverErrors"
        >
        </simple-form-select>

        <simple-form-select
            [label]="'Categories' | translate"
            [name]="propertyStrings.categoryIds"
            [form]="form"
            [items]="selectableCategoryItems"
            [multiple]="true"
            [searchable]="true"
            [addable]="true"
            [useStrictSearch]="true"
            [serverErrors]="serverErrors"
            (onAddNewItem)="handleAddNewItemInSelect($event)"
        >
        </simple-form-select>

        <simple-form-select
            [label]="'Client' | translate"
            [name]="propertyStrings.clientId"
            [form]="form"
            [disabled]="clientsIsDisabled"
            [items]="selectableClientItems"
            [searchable]="true"
            [serverErrors]="serverErrors"
        >
        </simple-form-select>

        <simple-form-info
            *ngIf="clientsIsDisabled"
            [firstText]="'Organization' | translate"
            [linkText]="selectedOrganization.orgName"
            [linkHref]="disabledClientLink"
            [secondText]="'DisabledClientInfoText' | translate"
            [name]="selectedOrganization.orgName"
        >
        </simple-form-info>

        <simple-form-select
            [label]="'GeoFencing' | translate"
            [name]="propertyStrings.geoControlled"
            [form]="form"
            [items]="selectableGeoFencingItems"
            [disabled]="geoFencingIsDisabled"
            [serverErrors]="serverErrors"
        >
        </simple-form-select>

        <simple-form-info
            *ngIf="geoFencingIsDisabled"
            [firstText]="'Facility' | translate"
            [linkText]="changeModel.facility.name"
            [linkHref]="disabledGeoFencingLink"
            [secondText]="'DisabledGeoFencingInfoText' | translate"
            [name]="changeModel.facility.name"
        >
        </simple-form-info>
    </div>
</simple-change-tab>
