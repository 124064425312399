<simple-handle-tab
	[headerTitleText]="'AddedAssignees' | translate"
	[headerSubtitleText]="headerSubtitleText"
	[headerAddButtonText]="'AddAssignees' | translate"
	[hideAddButton]="hideHeaderAddButton"
	[hideRemoveSelected]="hideHeaderRemoveSelected"
	[hideSelectAll]="hideHeaderSelectAll"
	[allAreSelected]="allAreSelected"
	[disableRemoveSelected]="!someAreSelected"
	[disableSelectAll]="!getItems().length"
	[selectedCount]="getSelectedCount()"
	[disableSearch]="!handleItems.length"
	(onAddButtonClick)="toggleListUserModal()"
	(onRemoveSelected)="removeSelected()"
	(onSelectAll)="selectAll()"
	(onSearch)="handleSearch($event)">
		<ng-container slot="itemList">
			<cdk-virtual-scroll-viewport itemSize="{{ itemSize }}" minBufferPx="999" maxBufferPx="1000" class="apx-scrollbar" scrollShadow>
				<simple-item *cdkVirtualFor="let item of getItems(); let index = index; cdkVirtualForTrackBy: trackByFn"
					[item]="item"
					[noBorder]="index === handleItems.length - 1"
					[hideRemoveButton]="readonlyItems"
					[hideSelectButton]="readonlyItems"
					[url]="getUrl(item)"
					[name]="item.fullName ? item.fullName : item.email"
					[height]="itemSize"
					(onRemove)="remove($event)"
					(onSelect)="select($event)">
						<ng-container slot="content">
							<div class="image-details-wrapper">
								<div class="item-image" #parentDiv>
									<extensive-image
										[imageUrl]="item.profileImageUrl"
										[imageStorageSize]="parentDiv | getImageStorageSize"
										[rounded]="true"
										[placeholderImageType]="'user'">
									</extensive-image>
								</div>
								<div class="details">
									<div class="name" title="{{ item.fullName }}" *ngIf="item.fullName">
										{{ item.fullName }}
									</div>
									<div class="description" *ngIf="item.email"
										[ngClass]="{
											'only': !item.fullName
										}"
										title="{{ item.email }}" >
											{{ item.email }}
									</div>
								</div>
							</div>
							<div class="status">
								<simple-status-label
									[text]="item.getCurrentStatus(selectedOrganization.id)">
								</simple-status-label>
							</div>
						</ng-container>
				</simple-item>
				<simple-no-items *ngIf="!handleItems.length && !searchValue"
					[title]="'NoAssigneesAdded' | translate">
				</simple-no-items>
				<simple-no-items *ngIf="!getItems().length && searchValue"
					[title]="'NoResultsMatchingSearch' | translate">
				</simple-no-items>
			</cdk-virtual-scroll-viewport>
		</ng-container>
</simple-handle-tab>
