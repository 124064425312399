import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { Organization } from 'app-core/organization/organization';
import { EntityService } from 'app-inspection/entity/entity.service';
import { TemplateType } from 'app-inspection/template-type/template-type';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { HostedHttpRequest } from '../hosted-http-request';
import { FormSelectItem } from '../simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleCrudDirective } from '../simple-components/crud/simple-crud.directive';
import { SwalConfig } from '../swal/swal-config.component';
import { RoutesUtils } from '../tools/routes-utils';
import { StringUtils } from '../tools/string-utils';
import { TranslationService } from '../translation/translation.service';
import { AdditionalInfo, AdditionalInfoActionName, AdditionalInfoModel, AdditionalInfoReason, TemplateTypeFacilityEntitySettings } from './additional-info';
import { AdditionalInfoHandlerComponent } from './additional-info-handler/additional-info-handler.component';

@Component({
	selector: 'additional-info',
	templateUrl: './additional-info.component.html',
	styleUrls: ['./additional-info.component.less']
})
export class AdditionalInfoComponent extends SimpleCrudDirective<any> implements OnInit, AfterViewChecked, OnDestroy {

	additionalInfo: AdditionalInfo[];
	selectedData: string[] = [];
	isAllSelected: boolean = true;
	reasons = AdditionalInfoReason;
	isButtonEnabled: boolean = false;
	getOriginalRequest: () => HostedHttpRequest;
	retry: (request?: HostedHttpRequest) => Promise<Object>;
	resume: (body?: Object) => void;
	cancel: (body?: Object) => void;
	data: { created: any[] };
	error: { additionalInfo: AdditionalInfo[] };
	additionalInfoTitle: string;
	additionalInfoDescription: string;
	currentReason: string;
	selectedOrganization: Organization;
	shouldDisplayTriggerButton: boolean = false;
	submitting: boolean = false;
	reasonTemplateTypeHasTemplatesExists: boolean = false;
	isRefreshingData: boolean = false;
	triggerButtonIsUpdate: boolean = false;
	triggerButtonText: string;
	dropDownItems: FormSelectItem[] = [];
	facilityOrEntities: string;

	subscriptions = new Subscription();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

	closed$ = new BehaviorSubject<boolean>(false);

	@ViewChild('additionalInfoHandler') additionalInfoHandler: AdditionalInfoHandlerComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private entityService: EntityService,
		private cdRef: ChangeDetectorRef) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.triggerButtonText = this.translationService.instant('Delete');
		this.selectedOrganization = this.authService.selectedOrganization;
		this.initiateReasonWithProperties();
		this.dropDownItems = [
			new FormSelectItem(TemplateTypeFacilityEntitySettings.FACILITY, this.translationService.instant(TemplateTypeFacilityEntitySettings.FACILITY)),
			new FormSelectItem(TemplateTypeFacilityEntitySettings.ENTITIES, this.translationService.instant(TemplateTypeFacilityEntitySettings.ENTITIES)),
			new FormSelectItem(TemplateTypeFacilityEntitySettings.BOTH, this.translationService.instant(TemplateTypeFacilityEntitySettings.BOTH)),
		];
		this.initialDropdownValue();
	}

	ngAfterViewChecked() {
		this.cdRef.detectChanges();
	}

	initiateReasonWithProperties() {
		if (this.additionalInfo) {
			this.additionalInfo.forEach(additionalInfoRow => {
				additionalInfoRow.title = `${additionalInfoRow.reason}Title`;
				additionalInfoRow.description = `${additionalInfoRow.reason}Description`;
				this.setUrl(additionalInfoRow);

				this.additionalInfoTitle = additionalInfoRow.title;
				this.additionalInfoDescription = additionalInfoRow.description;
				this.currentReason = additionalInfoRow.reason;

				if (additionalInfoRow.reason === AdditionalInfoReason.FacilityHasTemplates
					|| additionalInfoRow.reason === AdditionalInfoReason.TemplateTypeHasTemplates
					|| additionalInfoRow.reason === AdditionalInfoReason.ClientHasAssignmentTemplate
					|| additionalInfoRow.reason === AdditionalInfoReason.ClientHasActiveAssignment
					|| additionalInfoRow.reason === AdditionalInfoReason.TemplateHasActiveAssignment) {

					this.shouldDisplayTriggerButton = true;
					if (additionalInfoRow.reason !== AdditionalInfoReason.ClientHasAssignmentTemplate
						&& additionalInfoRow.reason !== AdditionalInfoReason.ClientHasActiveAssignment
						&& additionalInfoRow.reason !== AdditionalInfoReason.TemplateHasActiveAssignment) {
						this.triggerButtonIsUpdate = true;
						this.triggerButtonText = this.translationService.instant('Update');
						additionalInfoRow.shouldHaveCheckboxes = true;
						this.setRowsCheckboxes(additionalInfoRow);
					}

					if (additionalInfoRow.reason === AdditionalInfoReason.TemplateTypeHasTemplates) {
						this.reasonTemplateTypeHasTemplatesExists = true;
						// this.setSettingsAndCheckActions(additionalInfoRow);
					}

					if (additionalInfoRow.reason === AdditionalInfoReason.TemplateHasActiveAssignment) {
						additionalInfoRow.hideSelectAll = true;
						additionalInfoRow.shouldHaveCheckboxes = false;
						this.triggerButtonText = this.translationService.instant('TryAgain');
					}
				} else {
					additionalInfoRow.shouldHaveCheckboxes = false;
				}
			});
		}
	}

	initialDropdownValue() {
		if (this.shouldDisplayTriggerButton) {
			this.setDropdownValue(TemplateTypeFacilityEntitySettings.FACILITY);
		}
	}

	setUrl(additionalInfoRow: AdditionalInfo) {
		const url: string = `${this.selectedOrganization.friendlyUrl}/`;
		let routeContext: string;
		let tab: number = 1;

		for (const relatedDataItem of additionalInfoRow.relatedData) {
			switch (additionalInfoRow.reason) {
				case AdditionalInfoReason.FacilityHasTemplates:
					routeContext = `${RoutesUtils.assignmentTemplates}?${RoutesUtils.assignmentTemplate}=${RoutesUtils.modalPrefixValueEdit}_${relatedDataItem.id}&${RoutesUtils.modalTabParam}=5`;
					break;

				case AdditionalInfoReason.TemplateMissingDefaultRequiredTask:
					tab = relatedDataItem.templateEntityMissingDefaultRequiredTask ? 5 : 3;
					routeContext = `${RoutesUtils.assignmentTemplates}?${RoutesUtils.assignmentTemplate}=${RoutesUtils.modalPrefixValueEdit}_${relatedDataItem.id}&${RoutesUtils.modalTabParam}=${tab}`;
					break;

				case AdditionalInfoReason.TemplateMissingErrorChoice:
					tab = relatedDataItem.templateEntityMissingErrorChoice ? 5 : 3;
					routeContext = `${RoutesUtils.assignmentTemplates}?${RoutesUtils.assignmentTemplate}=${RoutesUtils.modalPrefixValueEdit}_${relatedDataItem.id}&${RoutesUtils.modalTabParam}=${tab}`;
					break;

				case AdditionalInfoReason.TemplateHasOnlyErrorChoice:
					tab = relatedDataItem.templateEntityHasOnlyErrorChoice ? 5 : 3;
					routeContext = `${RoutesUtils.assignmentTemplates}?${RoutesUtils.assignmentTemplate}=${RoutesUtils.modalPrefixValueEdit}_${relatedDataItem.id}&${RoutesUtils.modalTabParam}=${tab}`;
					break;

				case AdditionalInfoReason.TaskHasTemplates:
				case AdditionalInfoReason.ChoiceHasTemplates:
				case AdditionalInfoReason.ClientHasAssignmentTemplate:
					tab = relatedDataItem.clientId ? 1 : 3;
					routeContext = `${RoutesUtils.assignmentTemplates}?${RoutesUtils.assignmentTemplate}=${RoutesUtils.modalPrefixValueEdit}_${relatedDataItem.id}&${RoutesUtils.modalTabParam}=${tab}`;
					break;

				case AdditionalInfoReason.AssignmentTemplateHasActiveAssignment:
				case AdditionalInfoReason.TemplateHasActiveAssignment:
				case AdditionalInfoReason.TaskChoiceHasActiveAssignment:
				case AdditionalInfoReason.UserGroupHasActiveAssignment:
				case AdditionalInfoReason.ActiveAssignmentHasNoAssigneesOrGroups:
				case AdditionalInfoReason.ClientHasActiveAssignment:
				case AdditionalInfoReason.ScheduledResultPriorityRemoved:
					routeContext = `${RoutesUtils.assignments}/${relatedDataItem.isMeasure ? RoutesUtils.measureOngoing : RoutesUtils.scheduledOngoing}?${RoutesUtils.assignment}=${(relatedDataItem.additionalInfoActionName === AdditionalInfoActionName.DELETE ? RoutesUtils.modalPrefixValueDelete : RoutesUtils.modalPrefixValueEdit)}_${relatedDataItem.id}`;
					break;

				case AdditionalInfoReason.AssignmentHasResultsWithMedia:
				case AdditionalInfoReason.AssignmentHasResultsWithComments:
					routeContext = `${RoutesUtils.assignments}/${RoutesUtils.scheduledOngoing}?${RoutesUtils.assignment}=${RoutesUtils.modalPrefixValueDelete}_${relatedDataItem.id}`;
					break;

				case AdditionalInfoReason.MeasureHasPriorityWithoutPriorityChoice:
					routeContext = `${RoutesUtils.assignments}/${RoutesUtils.measureOngoing}?${RoutesUtils.assignment}=${RoutesUtils.modalPrefixValueDelete}_${relatedDataItem.id}`;
					break;

				case AdditionalInfoReason.TaskChoiceHasActiveMeasures:
					routeContext = `${RoutesUtils.assignments}/${RoutesUtils.measureOngoing}?${RoutesUtils.assignment}=${RoutesUtils.modalPrefixValueDelete}_${relatedDataItem.id}`;
					break;

				case AdditionalInfoReason.ScheduleHasBeenPaused:
					routeContext = `${RoutesUtils.schedules}?${RoutesUtils.schedule}=${RoutesUtils.modalPrefixValueEdit}_${relatedDataItem.id}&${RoutesUtils.modalTabParam}=2`;
					break;

				case AdditionalInfoReason.ScheduleIsEmpty:
				case AdditionalInfoReason.ScheduleHasNoValidFlags:
					routeContext = `${RoutesUtils.schedules}?${RoutesUtils.schedule}=${RoutesUtils.modalPrefixValueEdit}_${relatedDataItem.id}&${RoutesUtils.modalTabParam}=3`;
					break;

				case AdditionalInfoReason.TemplateTypeHasNoTaskChoices:
				case AdditionalInfoReason.TemplateTypeHasTaskWithMoreThanOneStandardChoice:
				case AdditionalInfoReason.TemplateTypeTaskHasOnlyUnspecifiedChoices:
				case AdditionalInfoReason.TemplateTypeManualMeasureHasNoErrorChoice:
				case AdditionalInfoReason.TemplateTypeHasDefaultTaskWithNoDefaultChoice:
					routeContext = `${RoutesUtils.templateTypes}?${RoutesUtils.templateType}=${RoutesUtils.modalPrefixValueEdit}_${relatedDataItem.id}&${RoutesUtils.modalTabParam}=2`;
					break;

				case AdditionalInfoReason.ScheduleHasInactiveAssignmentTemplates:
				case AdditionalInfoReason.TemplateTypeHasTemplates:
					routeContext = `${RoutesUtils.assignmentTemplates}?${RoutesUtils.assignmentTemplate}=${RoutesUtils.modalPrefixValueEdit}_${relatedDataItem.id}&${RoutesUtils.modalTabParam}=3`;
					break;

				case AdditionalInfoReason.ScheduleHasInactiveUsers:
					routeContext = `${RoutesUtils.users}/${relatedDataItem.id}`;
					break;

				case AdditionalInfoReason.ScheduleHasEmptyScheduleGroups:
					routeContext = `${RoutesUtils.userGroups}?${RoutesUtils.userGroup}=${RoutesUtils.modalPrefixValueEdit}_${relatedDataItem.id}&${RoutesUtils.modalTabParam}=1`;
					break;

				default:
					routeContext = '';
					break;
			}

			relatedDataItem.url = url.concat(routeContext);
		}
	}

	changeTitleAndDescription(additionalInfoRow: AdditionalInfo) {
		if (additionalInfoRow.reason === AdditionalInfoReason.TemplateTypeHasTemplates) {
			additionalInfoRow.title = `${additionalInfoRow.reason}OptionalTitle`;
			additionalInfoRow.description = `${additionalInfoRow.reason}OptionalDescription`;
		}
	}

	toggleButtonEnabled(value: boolean) {
		this.isButtonEnabled = value;
	}

	isAllChecked() {
		const allChecked = this.selectedData.length === this.additionalInfo
			.reduce((acc, current) => current.shouldHaveCheckboxes ? acc += current.relatedData.length : acc, 0);
		return allChecked;
	}

	toggleAll() {
		this.additionalInfo.forEach(additionalInfoRow => {
			if (additionalInfoRow.shouldHaveCheckboxes) {

				if (!this.isAllChecked()) {
					this.additionalInfoHandler.clearSelection();
				}

				additionalInfoRow.relatedData
					.filter(obj => obj.rowShouldHaveCheckbox)
					.forEach(obj => {
						this.additionalInfoHandler.selectRow(obj);
					});
			}
			this.setSelectedData(this.selectedData);
		});

	}

	setSelectedData(selectedData: string[]) {
		this.selectedData = selectedData;
		this.isAllSelected = this.isAllChecked();

		const checkbox = document.getElementById('global-checkbox') as HTMLInputElement;
		if (this.isAllSelected) {
			checkbox.checked = true;
		} else {
			checkbox.checked = false;
		}
	}

	setInitialDropdownValue(additionalInfoRow: AdditionalInfo) {
		if (this.shouldDisplayTriggerButton) {
			additionalInfoRow.relatedData.forEach(obj => {
				obj.facilityOrEntities = TemplateTypeFacilityEntitySettings.FACILITY;
			});
		}
	}

	setDropdownValue(event: TemplateTypeFacilityEntitySettings, additionalInfoRow?: AdditionalInfo) {
		this.facilityOrEntities = event;

		if (additionalInfoRow) {
			additionalInfoRow.relatedData.forEach(row => {
				row.facilityOrEntities = this.facilityOrEntities;
				this.additionalInfoHandler.setShouldShowMultiSelectForRow(row);
			})
		}
	}

	// setSettingsAndCheckActions(additionalInfoRow: AdditionalInfo) {
	// additionalInfoRow.relatedData.map(relatedDataRow => {
	// 	relatedDataRow.facilityEntitiesSettings = new InputSettings({
	// 		brand: StringUtils.DROPDOWN,
	// 		placeholder: this.translationService.instant(TemplateTypeFacilityEntitySettings.FACILITY_ENTITY),
	// 		items: [
	// 			new TemplateTypeFacilityEntitySettingsObject({
	// 				id: TemplateTypeFacilityEntitySettings.FACILITY,
	// 				text: this.translationService.instant(TemplateTypeFacilityEntitySettings.FACILITY)
	// 			}),
	// 			new TemplateTypeFacilityEntitySettingsObject({
	// 				id: TemplateTypeFacilityEntitySettings.ENTITIES,
	// 				text: this.translationService.instant(TemplateTypeFacilityEntitySettings.ENTITIES)
	// 			}),
	// 			new TemplateTypeFacilityEntitySettingsObject({
	// 				id: TemplateTypeFacilityEntitySettings.BOTH,
	// 				text: this.translationService.instant(TemplateTypeFacilityEntitySettings.BOTH)
	// 			})
	// 		],
	// 		messages: Object.assign({}, this.inputTranslations)
	// 	});

	// 	relatedDataRow.multiSelectSettings = new InputSettings({
	// 		brand: StringUtils.TEXTAREA,
	// 		placeholder: this.translationService.instant(TemplateTypeFacilityEntitySettings.DESCRIPTION),
	// 		label: this.translationService.instant(TemplateTypeFacilityEntitySettings.DESCRIPTION),
	// 		messages: Object.assign({}, this.inputTranslations),
	// 		autoComplete: 'off',
	// 		readOnly: true
	// 	});

	// 	relatedDataRow.errorMessage = '';
	// });
	// this.setInitialDropdownValue(additionalInfoRow);
	// }

	setRowsCheckboxes(additionalInfoRow: AdditionalInfo) {
		additionalInfoRow.relatedData.forEach(relatedDataRow => {
			relatedDataRow.rowShouldHaveCheckbox = true;

			if (additionalInfoRow.reason === this.reasons.TemplateTypeHasTemplates && relatedDataRow.additionalInfoActionName !== 'Add') {
				relatedDataRow.rowShouldHaveCheckbox = false;
			}
		});
	}

	async refreshModalData() {
		this.isRefreshingData = true;
		this.retry();
	}

	triggerUpdate() {
		if (this.isButtonEnabled) {
			this.submitting = true;
			this.isButtonEnabled = false;
			for (const additionalInfoRow of this.additionalInfo) {
				// FacilityHasTemplates
				if (additionalInfoRow.reason === AdditionalInfoReason.FacilityHasTemplates) {
					this.handleUpdateForReasonFacilityHasTemplates();
				}
				// TemplateTypeHasTemplates
				if (additionalInfoRow.reason === AdditionalInfoReason.TemplateTypeHasTemplates) {
					this.handleUpdateForReasonTemplateTypeHasTemplate(additionalInfoRow);
				}
				// ClientHasAssignmentTemplate OR ClientHasActiveAssignment
				if (additionalInfoRow.reason === AdditionalInfoReason.ClientHasAssignmentTemplate
					|| additionalInfoRow.reason === AdditionalInfoReason.ClientHasActiveAssignment) {
					this.handleDeleteForReasonWithClient(additionalInfoRow); // TODO. Fix for these reasons
				}
				// TemplateHasActiveAssignment (triggers, for example, when removing entity which has a measure)
				if (additionalInfoRow.reason === AdditionalInfoReason.TemplateHasActiveAssignment) {
					this.handleRetryForReasonTemplateHasActiveAssignment();
				}
			}
		}
	}

	async handleUpdateForReasonFacilityHasTemplates() {
		let model: AdditionalInfoModel;

		try {
			const newData: Object[] = [];
			let entityObject: {
				entityId: string,
				assignmentTemplateIds: string[]
			};

			for (const newEntity of this.data.created) {
				entityObject = {
					entityId: newEntity.id,
					assignmentTemplateIds: this.selectedData
				};
				newData.push(entityObject);
			}
			model = new AdditionalInfoModel({
				data: newData,
				validationHelperTranslation: 'BackgroundJobStartedUpdateAssTemplateWithEntity'
			});
			const result = await this.entityService.updateAssignmentTemplates(model.data);
			// this.handleBackgroundJob(result, model);
			this.displaySuccessMessage(result.successMessage);
			this.resume();
			this.closed$.next(true);
			this.submitting = false;
			this.isButtonEnabled = true;
		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
			this.submitting = false;
		}
	}

	async handleUpdateForReasonTemplateTypeHasTemplate(additionalInfoRow: AdditionalInfo) {
		// Reset the hasError flag each time we try to do an update.
		additionalInfoRow.relatedData.forEach(row => row.errorMessage = '');

		const templateType = Object.assign(new TemplateType({}), this.getOriginalRequest().data).toPayloadObject(this.selectedOrganization.id);
		const taskChoices = (this.getOriginalRequest().data as TemplateType).taskChoices;
		templateType.taskChoices = taskChoices;

		if (this.selectedData.length >= 1) {
			// Filter the rows that is selected. Then map the data for those rows.
			const updateAssignmentTemplates = additionalInfoRow.relatedData
				.filter(row => this.selectedData.includes(row.id))
				.map(row => ({
					assignmentTemplateId: row.id,
					assignToFacility: (row.facilityOrEntities === TemplateTypeFacilityEntitySettings.FACILITY || row.facilityOrEntities === TemplateTypeFacilityEntitySettings.BOTH) ? true : false,
					assignToEntities: (row.facilityOrEntities === TemplateTypeFacilityEntitySettings.ENTITIES || row.facilityOrEntities === TemplateTypeFacilityEntitySettings.BOTH) ? true : false,
					newEntityIds: row.entities
						.filter(entity => row.multiSelectValues.includes(entity.name))
						.map(entity => entity.id)
				}));
			templateType.updateAssignmentTemplates = updateAssignmentTemplates;
		}

		try {
			const response = await this.retry(this.getOriginalRequest()
				.merge({
					endpoint: `${StringUtils.TEMPLATE_TYPE}/${templateType.id}`,
					data: templateType
				})
			);
			this.resume(response);
			this.closed$.next(true);
			this.submitting = false;
			this.isButtonEnabled = true;
		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
			this.submitting = false;
			Object.getOwnPropertyNames(errorResponse.error.errors).forEach(errorAssignmentTemplateId => {
				const row = additionalInfoRow.relatedData.find(currentRow => currentRow.id === errorAssignmentTemplateId);
				if (row) {
					row.errorMessage = errorResponse.error.errors[errorAssignmentTemplateId].join('<br>');
				}
			});
		}
	}

	async handleDeleteForReasonWithClient(additionalInfoRow: AdditionalInfo) {
		const clientId = additionalInfoRow.relatedData[0].clientId;
		try {
			const response = await this.retry(this.getOriginalRequest()
				.merge({
					endpoint: `${StringUtils.CLIENT}/${clientId}`,
					useCoreApi: true
				})
			);
			this.resume(response);
			this.closed$.next(true);
			this.submitting = false;
			this.isButtonEnabled = true;
		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
			this.submitting = false;
		}
	}

	async handleRetryForReasonTemplateHasActiveAssignment() {
		try {
			const response = await this.retry();
			this.closed$.next(true);
			this.submitting = false;
			this.isButtonEnabled = true;
		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
			this.submitting = false;
		}
	}

	// handleBackgroundJob(result: any, model: AdditionalInfoModel) {
	// this.validationHelper.handleBackgroundJobResponse(
	// 	result,
	// 	this.translationService.instant(model.validationHelperTranslation)
	// );
	// }

	close() {
		if (this.shouldDisplayTriggerButton && this.triggerButtonIsUpdate) {
			// A confirmation dialog before closing this modal.
			Swal.fire(
				new SwalConfig(this.translationService).getAreYouSure({
					text: this.translationService.instant('AdditionalInfoQuestion'),
					icon: 'info',
					customClass: {
						confirmButton: 'btn btn-info'
					}
				}))
				.then(result => {
					if (result.value) {
						if (!this.closed$.value) {
							this.closed$.next(true);
							this.cancel({
								error: {
									errors: {
										AdditionalInfoCancel: [`${this.translationService.instant('AdditionalInfoCancel')}`]
									}
								}
							});
						}
					}
				});
		} else {
			if (!this.closed$.value) {
				this.closed$.next(true);
				this.cancel();
			}
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this.subscriptions.unsubscribe();
		this.closed$.complete();
	}
}
