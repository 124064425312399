<simple-crud-modal
	[titleText]="'ProfileImage' | translate"
	[closeText]="'Close' | translate"
	[submitText]="'Update' | translate"
	[disableSubmit]="!hasUnsavedChanges() || (changeImageComponent?.uploadedFile && !changeImageComponent?.imageIsLoaded) || changeImageComponent?.exceededMaxLimit"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<simple-change-image *ngIf="modifiedModel"
			[imageUrl]="modifiedModel.profileImageUrl"
			[rounded]="true"
			(onClear)="modifiedModel.profileImageUrl = null">
		</simple-change-image>
</simple-crud-modal>
