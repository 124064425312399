import { Component, ElementRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleListAction } from 'app-core/shared-core/simple-components/list/actions/simple-list-action';
import { SimpleListDirective } from 'app-core/shared-core/simple-components/list/simple-list.directive';
import { SimpleTableRowActionDelete, SimpleTableRowActionEdit } from 'app-core/shared-core/simple-components/list/table/body/simple-table-row-action';
import { SimpleTableChoicesColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-choices-column';
import { EventsContent, SimpleTableEventsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-events-column';
import { SimpleTableRulesColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-rules-column';
import { SimpleTableEmptyState } from 'app-core/shared-core/simple-components/list/table/empty-state/simple-table-empty-state';
import { SimpleFilterInput, SimpleFilterInputType } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-input-settings';
import { SimpleFilterInputItem } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-input-item';
import { SimpleRetainService } from 'app-core/shared-core/simple-components/list/table/filter/simple-retain.service';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { KeyValuePair } from '../../../app-core/shared-core/filter';
import { SimpleFilterConfig, SortObject } from '../../../app-core/shared-core/simple-components/list/table/filter/simple-filter-config';
import { SimpleTableHeaderActionDelete } from '../../../app-core/shared-core/simple-components/list/table/head/simple-table-header-action';
import { SimpleTableConfig } from '../../../app-core/shared-core/simple-components/list/table/simple-table-config';
import { Choice } from '../choice';
import { ChoiceFilter, ChoiceService } from '../choice.service';
import { CreateEditChoiceComponent } from '../create-edit-choice/create-edit-choice.component';
import { DeleteChoicesComponent } from '../delete-choices/delete-choices.component';

@Component({
	selector: 'list-choice',
	templateUrl: '../../../app-core/shared-core/simple-components/list/simple-list-shared-template.html'
})
export class ListChoiceComponent extends SimpleListDirective<Choice> {

	// Override filterObject to match backend.
	readonly filterObject = new ChoiceFilter();

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected sanitizer: DomSanitizer,
		protected choiceService: ChoiceService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected retainService: SimpleRetainService,
		protected elementRef: ElementRef,
		protected viewContainerRef: ViewContainerRef) {
		super(
			authService,
			modalService,
			toastrService,
			translationService,
			choiceService,
			route,
			router,
			retainService,
			elementRef,
			viewContainerRef,
			RoutesUtils.choice
		);
	}

	protected configureListActions() {
		this.setListActions(
			new SimpleListAction(
				this.translationService.instant('Create'),
				this.translationService.instant('NewChoice'),
				StringUtils.icons.new,
				() => {
					if (!this.isInModal) {
						this.setCrudParams(RoutesUtils.modalPrefixValueNew);
					} else {
						this.openModalByActionName(RoutesUtils.modalPrefixValueNew);
					}
				}
			)
		);
	}

	protected configureTableFilter(config: SimpleFilterConfig<Choice>) {
		const rulesKey = StringUtils.RULES_KEY;
		const createdKey = StringUtils.CREATED_KEY;
		const updatedKey = StringUtils.UPDATED_KEY;
		if (!this.isInModal) {
			this.retainService.setCurrentRetainEntries({
				search: null,
				sort: null,
				[rulesKey]: null,
				[createdKey]: null,
				[updatedKey]: null
			});
		}

		config.setFilterInputs(
			new SimpleFilterInput(
				rulesKey,
				this.translationService.instant(rulesKey),
				SimpleFilterInputType.MultiSelect,
				(values: string) => {
					const keyValuePairs: KeyValuePair[] = [];
					if (values) {
						keyValuePairs.push(new KeyValuePair(rulesKey, values));
					} else {
						keyValuePairs.push(new KeyValuePair(rulesKey, ''));
					}
					return keyValuePairs;
				},
				new SimpleFilterInputItem(
					StringUtils.IS_ERROR,
					this.translationService.instant(StringUtils.IS_ERROR)
				),
				new SimpleFilterInputItem(
					StringUtils.IS_DEFAULT,
					this.translationService.instant(StringUtils.IS_DEFAULT)
				),
				new SimpleFilterInputItem(
					StringUtils.IS_UNSPECIFIED,
					this.translationService.instant(StringUtils.IS_UNSPECIFIED)
				),
				new SimpleFilterInputItem(
					StringUtils.ALLOWS_PRIORITY,
					this.translationService.instant(StringUtils.ALLOWS_PRIORITY)
				)
			),
			new SimpleFilterInput(
				createdKey,
				this.translationService.instant(createdKey),
				SimpleFilterInputType.DateSelect,
				(values: string) => {
					const keyValuePairs: KeyValuePair[] = [];
					if (values) {
						keyValuePairs.push(new KeyValuePair(createdKey, values));
					} else {
						keyValuePairs.push(new KeyValuePair(createdKey, ''));
					}
					return keyValuePairs;
				}
			),
			new SimpleFilterInput(
				updatedKey,
				this.translationService.instant(updatedKey),
				SimpleFilterInputType.DateSelect,
				(values: string) => {
					const keyValuePairs: KeyValuePair[] = [];
					if (values) {
						keyValuePairs.push(new KeyValuePair(updatedKey, values));
					} else {
						keyValuePairs.push(new KeyValuePair(updatedKey, ''));
					}
					return keyValuePairs;
				}
			)
		);
	}

	protected configureTableSort(config: SimpleFilterConfig<Choice>) {
		config.setSortObjects(
			new SortObject(
				this.propertyStrings.value,
				this.translationService.instant('Name'),
				true
			),
			new SortObject(
				this.propertyStrings.created,
				this.translationService.instant('Created')
			),
			new SortObject(
				this.propertyStrings.updated,
				this.translationService.instant('Updated')
			)
		);
	}

	protected configureTableColumns(config: SimpleTableConfig<Choice>) {
		config.setColumns(
			new SimpleTableChoicesColumn(
				this.translationService.instant('Name'),
				(item) => [item],
				this.sanitizer,
				this.viewContainerRef,
				this.translationService.instant('Color')
			),
			new SimpleTableRulesColumn(
				this.translationService.instant('Rules'),
				(item) => {
					return item.rulesReadonly;
				},
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableEventsColumn(
				this.translationService.instant('Events'),
				(item) => [
					new EventsContent(`${this.translationService.instant('Created')} ${Utils.getFormattedDateStringFromString(item.created, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.created, 'bi-file-earmark-plus'),
					Utils.dateIsAfter(item.updated, item.created)
						? new EventsContent(`${this.translationService.instant('Updated')} ${Utils.getFormattedDateStringFromString(item.updated, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.updated, 'bi-file-earmark-text')
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			)
		);
	}

	protected configureTableActions(config: SimpleTableConfig<Choice>) {

		// HEADER
		config.setHeaderActions(
			new SimpleTableHeaderActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${selectedIds.join()}`);
				}
			)
		);

		// ROW
		config.setRowActions(
			new SimpleTableRowActionEdit(
				this.translationService.instant('Open'),
				StringUtils.icons.handle,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					}
				}
			),
			new SimpleTableRowActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					}
				}
			)
		);
	}

	protected configureTableEmptyState(config: SimpleTableConfig<Choice>) {
		config.emptyState = new SimpleTableEmptyState(
			'Choices',
			StringUtils.icons.choice
		);
	}

	protected setPropertiesAndOpenViewObjectsPopover() { }

	protected openModalByActionName(value: string) {
		const [actionName, idString] = value.split('_');
		this.shouldClearAfterUpdate = !this.isInModal && this.isBulkAction(actionName);
		if (actionName === RoutesUtils.modalPrefixValueNew) {
			this.bsModalRef = this.modalService.show(
				CreateEditChoiceComponent,
				{
					initialState: {
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueEdit) {
			this.bsModalRef = this.modalService.show(
				CreateEditChoiceComponent,
				{
					initialState: {
						editModelId: idString,
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueDelete) {
			this.bsModalRef = this.modalService.show(
				DeleteChoicesComponent,
				{
					initialState: {
						deleteModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_SMALL
				}
			);
		}
		this.subscribeToCrudModalContent();
	}

	protected import(file: File) { }
	protected export(selectedIds: string[]) { }
}
