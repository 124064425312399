import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Choice } from 'app-inspection/choice/choice';
import { TemplateType } from 'app-inspection/template-type/template-type';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'change-details-tab',
	templateUrl: './change-details-tab.component.html',
	styleUrls: ['./change-details-tab.component.less']
})
export class ChangeDetailsTabComponent extends SimpleChangeTabDirective<TemplateType> implements OnInit {

	selectableTemplateBaseTypeItems: FormSelectItem[] = [];

	notificationChoiceErrorMessages: string[] = [];

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableTemplateBaseTypeItems();

		this.form = this.formBuilder.group({
			[this.propertyStrings.name]: [this.changeModel.name,
			[Validators.required, Validators.maxLength(100)]
			],
			[this.propertyStrings.abbreviation]: [this.changeModel.abbreviation,
			[Validators.required, Validators.maxLength(10)]
			],
			[this.propertyStrings.templateBaseTypeId]: [this.changeModel.templateBaseTypeId,
			Validators.required
			]
		});
		super.ngOnInit();
	}

	handleChoicesChange(choices: Choice[]) {
		this.changeModel.notificationChoice = choices[0];
		if (this.changeModel.notificationChoice) {
			this.validate();
		} else {
			this.notificationChoiceErrorMessages = [];
			this.changeModel.notificationChoiceId = null;
		}
	}

	validate() {
		const isAnErrorChoice = this.changeModel.notificationChoice.isError;
		const existsInSelectedChoices = this.changeModel.tasks.flatMap(tasks => tasks.choices).some(choice => choice.id === this.changeModel.notificationChoice.id);
		this.notificationChoiceErrorMessages = [];
		if (!isAnErrorChoice) {
			this.notificationChoiceErrorMessages.push('TheChoiceMustBeAnErrorChoice');
		}
		if (!existsInSelectedChoices) {
			this.notificationChoiceErrorMessages.push('TheChoiceMustExistInTheSelectedChoices');
		}
	}

	private setSelectableTemplateBaseTypeItems() {
		this.selectableTemplateBaseTypeItems = this.changeModel.selectableTemplateBaseTypes.map(baseType => new FormSelectItem(baseType.id, baseType.name));
	}
}
