
export class MediaUtils {
	// Base
	public static EXT_SVG = '.svg';
	public static EXT_PNG = '.png';
	public static EXT_JPG = '.jpg';


	// Graphic media
	public static BASE_URL_GRAPHICS = 'assets/images/graphics/';
	public static GRAPHICS_APIRIX_LOGO_HEIGHT_32 = `${MediaUtils.BASE_URL_GRAPHICS}${'apirix_platform_logo_height_32'}${MediaUtils.EXT_PNG}`;
	public static GRAPHICS_APIRIX_LOGO_HEIGHT_48 = `${MediaUtils.BASE_URL_GRAPHICS}${'apirix_platform_logo_height_48'}${MediaUtils.EXT_PNG}`;
	public static GRAPHICS_APIRIX_LOGO_ORIGINAL_PNG = `${MediaUtils.BASE_URL_GRAPHICS}${'apirix_platform_logo_original'}${MediaUtils.EXT_PNG}`;
	public static GRAPHICS_APIRIX_LOGO_ORIGINAL_SVG = `${MediaUtils.BASE_URL_GRAPHICS}${'apirix_platform_logo_original'}${MediaUtils.EXT_SVG}`;
	public static GRAPHICS_APIRIX_ICON = `${MediaUtils.BASE_URL_GRAPHICS}${'apirix-icon'}${MediaUtils.EXT_PNG}`;
	public static GRAPHICS_BACKGROUND_SIGNIN = `${MediaUtils.BASE_URL_GRAPHICS}${'background_signin'}${MediaUtils.EXT_JPG}`;
	public static GRAPHICS_BACKGROUND_GRAPHIC = `${MediaUtils.BASE_URL_GRAPHICS}${'png_background_graphic'}${MediaUtils.EXT_PNG}`;
	public static GRAPHICS_PLACEHOLDER_GRAPHIC = `${MediaUtils.BASE_URL_GRAPHICS}${'png_placeholder_graphic'}${MediaUtils.EXT_PNG}`;
	public static GRAPHICS_ERROR_IMAGE_BROKEN_LINK = `${MediaUtils.BASE_URL_GRAPHICS}${'error-image-broken-link'}${MediaUtils.EXT_SVG}`;

	// App Icons
	public static GRAPHICS_INSPECTION_ICON = `${MediaUtils.BASE_URL_GRAPHICS}${'inspection-icon'}${MediaUtils.EXT_PNG}`;

	// Icons base
	public static BASE_URL_ICONS = 'assets/images/icons/';

	// Input icons
	public static INPUT_BASE = `${MediaUtils.BASE_URL_ICONS}${'input/'}`;
	public static INPUT_DESCRIPTION = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_description'}${MediaUtils.EXT_SVG}`;
	public static INPUT_DESCRIPTION_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_description_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_CUSTOMER = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_customer'}${MediaUtils.EXT_SVG}`;
	public static INPUT_CUSTOMER_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_customer_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_CUSTOMER_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_customer_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_INVOICE = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_invoice'}${MediaUtils.EXT_SVG}`;
	public static INPUT_INVOICE_ELECTRONIC = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_invoice_electronic'}${MediaUtils.EXT_SVG}`;
	public static INPUT_INVOICE_ELECTRONIC_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_invoice_electronic_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_INVOICE_ELECTRONIC_FOCUSED = `
		${MediaUtils.INPUT_BASE}${'ic_24x24_input_invoice_electronic_focused'}${MediaUtils.EXT_SVG}
	`;
	public static INPUT_INVOICE_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_invoice_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_INVOICE_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_invoice_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_LANGUAGE = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_language'}${MediaUtils.EXT_SVG}`;
	public static INPUT_LANGUAGE_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_language_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_LOCATION = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_location'}${MediaUtils.EXT_SVG}`;
	public static INPUT_LOCATION_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_location_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_LOCATION_LATITUDE = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-locale-latitude'}${MediaUtils.EXT_SVG}`;
	public static INPUT_LOCATION_LATITUDE_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-locale-latitude-focus'}${MediaUtils.EXT_SVG}`;
	public static INPUT_LOCATION_LATITUDE_ERROR = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-locale-latitude-error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_MAIL = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_mail'}${MediaUtils.EXT_SVG}`;
	public static INPUT_MAIL_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_mail_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_MAIL_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_mail_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_MEDIA_SEARCH = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_media_search'}${MediaUtils.EXT_SVG}`;
	public static INPUT_MEDIA_SEARCH_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_media_search_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_NAME_FIRST = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_name_first'}${MediaUtils.EXT_SVG}`;
	public static INPUT_NAME_FIRST_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_name_first_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_NAME_FIRST_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_name_first_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_NAME_FIRST_LAST = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_name_last'}${MediaUtils.EXT_SVG}`;
	public static INPUT_NAME_FIRST_LAST_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_name_last_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_NAME_FIRST_LAST_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_name_last_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_organization'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_organization_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_NAME = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_organization_name'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_NAME_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_organization_name_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_NAME_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_organization_name_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_NUMBER = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_organization_number'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_NUMBER_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_organization_number_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_NUMBER_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_organization_number_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_NAME_NEW = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-organization'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_NAME_NEW_ERROR = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-organization-error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_NAME_NEW_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-organization-focus'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_NUMBER_NEW = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-organization-nr'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_NUMBER_NEW_ERROR = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-organization-nr-error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ORGANIZATION_NUMBER_NEW_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-organization-nr-focus'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PASS_CONFIRM = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_pass_confirm'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PASS_CONFIRM_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_pass_confirm_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PASS_CONFIRM_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_pass_confirm_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PASS_DEFAULT = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_pass_default'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PASS_DEFAULT_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_pass_default_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PASS_DEFAULT_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_pass_default_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PASS_NEW = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_pass_new'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PASS_NEW_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_pass_new_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PASS_NEW_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_pass_new_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PASS_OLD = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_pass_old'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PASS_OLD_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_pass_old_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PASS_OLD_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_pass_old_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PHONE = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_phone'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PHONE_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_phone_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_PHONE_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_phone_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ROLE = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_role'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ROLE_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_role_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ROLE_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_role_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_TAGS = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_tags'}${MediaUtils.EXT_SVG}`;
	public static INPUT_TAGS_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_tags_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_TIMEZONE = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_timezone'}${MediaUtils.EXT_SVG}`;
	public static INPUT_TIMEZONE_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_timezone_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_TITLE = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_title'}${MediaUtils.EXT_SVG}`;
	public static INPUT_TITLE_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_title_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_USER_SEARCH = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_user_search'}${MediaUtils.EXT_SVG}`;
	public static INPUT_USER_SEARCH_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_user_search_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_VAT = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_vat'}${MediaUtils.EXT_SVG}`;
	public static INPUT_VAT_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_vat_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_VAT_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_vat_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_VERIFY = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_verify'}${MediaUtils.EXT_SVG}`;
	public static INPUT_VERIFY_ERROR = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_verify_error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_VERIFY_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_verify_focused'}${MediaUtils.EXT_SVG}`;

	public static INPUT_ENTITY = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_entity'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ENTITY_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_entity_focused'}${MediaUtils.EXT_SVG}`;

	public static INPUT_ENTITY_PARENT = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_entity_parent'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ENTITY_PARENT_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_entity_parent_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ENTITY_STATUS = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_entity_status'}${MediaUtils.EXT_SVG}`;
	public static INPUT_ENTITY_STATUS_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_entity_status_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_FACILITY = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_facility'}${MediaUtils.EXT_SVG}`;
	public static INPUT_FACILITY_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_facility_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_FACILITY_SEARCH = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_facility_search'}${MediaUtils.EXT_SVG}`;
	public static INPUT_FACILITY_SEARCH_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_facility_search_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_DATE = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_date'}${MediaUtils.EXT_SVG}`;
	public static INPUT_DATE_FOCUS = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_date_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_STATUS = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_status'}${MediaUtils.EXT_SVG}`;
	public static INPUT_STATUS_FOCUS = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_status_focused'}${MediaUtils.EXT_SVG}`;
	public static INPUT_SEARCH = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_search'}${MediaUtils.EXT_SVG}`;
	public static INPUT_SEARCH_FOCUS = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_search_focus'}${MediaUtils.EXT_SVG}`;
	public static INPUT_DATE_END = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-date-end'}${MediaUtils.EXT_SVG}`;
	public static INPUT_DATE_END_FOCUS = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-date-end-focus'}${MediaUtils.EXT_SVG}`;
	public static INPUT_DATE_START = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-date-start'}${MediaUtils.EXT_SVG}`;
	public static INPUT_DATE_START_FOCUS = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-date-start-focus'}${MediaUtils.EXT_SVG}`;
	public static INPUT_TYPE = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-type'}${MediaUtils.EXT_SVG}`;
	public static INPUT_TYPE_FOCUS = `${MediaUtils.INPUT_BASE}${'ic-24-x-24-type-focus'}${MediaUtils.EXT_SVG}`;
	public static INPUT_CODE = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_code'}${MediaUtils.EXT_SVG}`;
	public static INPUT_CODE_FOCUSED = `${MediaUtils.INPUT_BASE}${'ic_24x24_input_code_focused'}${MediaUtils.EXT_SVG}`;

	// Button icons
	public static BTN_BASE = `${MediaUtils.BASE_URL_ICONS}${'button/'}`;
	public static BTN_ACTIVITY_DISABLE = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_activity_disable'}${MediaUtils.EXT_SVG}`;
	public static BTN_ACTIVITY_DISABLE_LIGHT = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_activity_disable_light'}${MediaUtils.EXT_SVG}`;
	public static BTN_ACTIVITY_DISABLE_LIGHTER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_activity_disable_lighter'}${MediaUtils.EXT_SVG}`;
	public static BTN_ACTIVITY_ENABLE = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_activity_enable'}${MediaUtils.EXT_SVG}`;
	public static BTN_ACTIVITY_ENABLE_LIGHT = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_activity_enable_light'}${MediaUtils.EXT_SVG}`;
	public static BTN_ACTIVITY_ENABLE_LIGHTER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_activity_enable_lighter'}${MediaUtils.EXT_SVG}`;
	public static BTN_ADD = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_add'}${MediaUtils.EXT_SVG}`;
	public static BTN_ADD_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_add_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_ADD_ORGANIZATION = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_add_organization'}${MediaUtils.EXT_SVG}`;
	public static BTN_ADD_ORGANIZATION_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_add_organization_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_ADD_ASSIGNEE = `${MediaUtils.BTN_BASE}${'assignee_add'}${MediaUtils.EXT_SVG}`;
	public static BTN_ADD_ASSIGNEE_HOVER = `${MediaUtils.BTN_BASE}${'assignee_add_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_ADD_USER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_add_user'}${MediaUtils.EXT_SVG}`;
	public static BTN_ADD_USER_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_add_user_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_ARROW_LEFT = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_arrow-left'}${MediaUtils.EXT_SVG}`;
	public static BTN_ARROW_RIGHT = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_arrow-right'}${MediaUtils.EXT_SVG}`;
	public static BTN_CHECK_DISABLED = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_check_disabled'}${MediaUtils.EXT_SVG}`;
	public static BTN_CHECK_ENABLED = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_check_enabled'}${MediaUtils.EXT_SVG}`;
	public static BTN_CHECK_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_check_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_DELETE = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_delete'}${MediaUtils.EXT_SVG}`;
	public static BTN_DELETE_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_delete_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_EDIT = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_edit'}${MediaUtils.EXT_SVG}`;
	public static BTN_EDIT_BORDER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_edit_border'}${MediaUtils.EXT_SVG}`;
	public static BTN_EDIT_BORDER_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_edit_border_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_EDIT_ERROR = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_edit_error'}${MediaUtils.EXT_SVG}`;
	public static BTN_EDIT_ERROR_DARK = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_edit_error_dark'}${MediaUtils.EXT_SVG}`;
	public static BTN_ERASE = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_erase'}${MediaUtils.EXT_SVG}`;
	public static BTN_ERASE_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_erase_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_HELP = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_help'}${MediaUtils.EXT_SVG}`;
	public static BTN_HELP_ERROR = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_help_error'}${MediaUtils.EXT_SVG}`;
	public static BTN_HOME = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_home'}${MediaUtils.EXT_SVG}`;
	public static BTN_MEDIA_ADD = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_media_add'}${MediaUtils.EXT_SVG}`;
	public static BTN_MEDIA_ADD_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_media_add_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_RADIO_DISABLED = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_radio_disabled'}${MediaUtils.EXT_SVG}`;
	public static BTN_RADIO_ENABLED = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_radio_enabled'}${MediaUtils.EXT_SVG}`;
	public static BTN_RADIO_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_radio_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_SEARCH = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_search'}${MediaUtils.EXT_SVG}`;
	public static BTN_SORT_DOWN = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_sort_down'}${MediaUtils.EXT_SVG}`;
	public static BTN_SORT_DOWN_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_sort_down_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_SORT_UP = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_sort_up'}${MediaUtils.EXT_SVG}`;
	public static BTN_SORT_UP_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_sort_up_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_STATUS_USER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_status_user'}${MediaUtils.EXT_SVG}`;
	public static BTN_STATUS_USER_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_status_user_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_TOGGLE_GRID = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_toggle-grid'}${MediaUtils.EXT_SVG}`;
	public static BTN_TOGGLE_GRID_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_toggle-grid_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_TOGGLE_LIST = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_toggle-list'}${MediaUtils.EXT_SVG}`;
	public static BTN_TOGGLE_LIST_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_toggle-list_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_WARNING = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_warning'}${MediaUtils.EXT_SVG}`;
	public static BTN_MAIL_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_mail_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_FACILITY_ADD = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_facility-add'}${MediaUtils.EXT_SVG}`;
	public static BTN_FACILITY_ADD_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_facility-add_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_FACILITY_EXPORT = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_facility_export'}${MediaUtils.EXT_SVG}`;
	public static BTN_FACILITY_EXPORT_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_facility_export_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_FACILITY_IMPORT = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_facility_import'}${MediaUtils.EXT_SVG}`;
	public static BTN_FACILITY_IMPORT_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_facility_import_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_MEDIA_LIBRARY = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_media-library'}${MediaUtils.EXT_SVG}`;
	public static BTN_MEDIA_LIBRARY_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_media-library_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_MEDIA_CROP = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_media-crop'}${MediaUtils.EXT_SVG}`;
	public static BTN_MEDIA_CROP_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_media-crop_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_ENTITY_ADD = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_entity-add'}${MediaUtils.EXT_SVG}`;
	public static BTN_ENTITY_ADD_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_entity-add_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_ENTITY_EXPORT = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_entity-export'}${MediaUtils.EXT_SVG}`;
	public static BTN_ENTITY_EXPORT_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_entity-export_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_ENTITY_IMPORT = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_entity-import'}${MediaUtils.EXT_SVG}`;
	public static BTN_ENTITY_IMPORT_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_entity-import_hover'}${MediaUtils.EXT_SVG}`;

	public static BTN_ADD_NEW = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_add-new'}${MediaUtils.EXT_SVG}`;
	public static BTN_ADD_NEW_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_add-new_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_EXPORT = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_export'}${MediaUtils.EXT_SVG}`;
	public static BTN_EXPORT_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_export_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_IMPORT = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_import'}${MediaUtils.EXT_SVG}`;
	public static BTN_IMPORT_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_import_hover'}${MediaUtils.EXT_SVG}`;

	public static BTN_DETAILS = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_details'}${MediaUtils.EXT_SVG}`;
	public static BTN_DETAILS_HOVER = `${MediaUtils.BTN_BASE}${'ic_20x20_btn_details_hover'}${MediaUtils.EXT_SVG}`;

	// Button icons with background
	public static BTN_BG_CHECK = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_check'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_CHECK_FOCUS = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_check_focus'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_CHECK_HOVER = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_check_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_CLOSE = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_close'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_CLOSE_HOVER = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_close_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_DELETE = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_delete'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_DELETE_HOVER = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_delete_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_DOWNLOAD = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_download'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_DOWNLOAD_HOVER = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_download_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_EDIT = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_edit'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_EDIT_HOVER = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_edit_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_MEDIA = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_media'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_MEDIA_HOVER = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_media_hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_MEDIA_CROP = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_media-crop'}${MediaUtils.EXT_SVG}`;
	public static BTN_BG_MEDIA_CROP_HOVER = `${MediaUtils.BTN_BASE}${'ic_22x22_btn_media-crop_hover'}${MediaUtils.EXT_SVG}`;



	// Misc icons
	public static MISC_BASE = `${MediaUtils.BASE_URL_ICONS}${'misc/'}`;
	public static MISC_ARROW_RIGHT = `${MediaUtils.MISC_BASE}${'ic_8x8_arrow_right'}${MediaUtils.EXT_SVG}`;
	public static MISC_ARROW_DOWN = `${MediaUtils.MISC_BASE}${'ic_12x12_arrow_down'}${MediaUtils.EXT_SVG}`;
	public static MISC_CLOSE = `${MediaUtils.MISC_BASE}${'ic_12x12_close'}${MediaUtils.EXT_SVG}`;
	public static MISC_LESS = `${MediaUtils.MISC_BASE}${'ic_12x12_less'}${MediaUtils.EXT_SVG}`;
	public static MISC_MORE = `${MediaUtils.MISC_BASE}${'ic_12x12_more'}${MediaUtils.EXT_SVG}`;
	public static MISC_SAVE = `${MediaUtils.MISC_BASE}${'ic_12x12_save'}${MediaUtils.EXT_SVG}`;
	public static MISC_BTN_DISABLED = `${MediaUtils.MISC_BASE}${'ic_32x32_button_disabled'}${MediaUtils.EXT_SVG}`;
	public static MISC_BTN_ENABLED = `${MediaUtils.MISC_BASE}${'ic_32x32_button_enabled'}${MediaUtils.EXT_SVG}`;
	public static MISC_CAMERA = `${MediaUtils.MISC_BASE}${'ic_38x38_camera'}${MediaUtils.EXT_SVG}`;
	public static MISC_PDF = `${MediaUtils.MISC_BASE}${'ic_38x38_pdf'}${MediaUtils.EXT_SVG}`;
	public static MISC_UPLOAD = `${MediaUtils.MISC_BASE}${'ic_68x68_upload'}${MediaUtils.EXT_SVG}`;
	public static MISC_CALENDAR = `${MediaUtils.MISC_BASE}${'ic-20-x-20-calendar'}${MediaUtils.EXT_SVG}`;
	public static MISC_CALENDAR_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-calendar-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_COPY = `${MediaUtils.MISC_BASE}${'ic-20-x-20-copy'}${MediaUtils.EXT_SVG}`;
	public static MISC_COPY_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-copy-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_CROP = `${MediaUtils.MISC_BASE}${'ic-20-x-20-crop'}${MediaUtils.EXT_SVG}`;
	public static MISC_CROP_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-crop-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_CROP_DARK = `${MediaUtils.MISC_BASE}${'ic-20-x-20-crop-dark'}${MediaUtils.EXT_SVG}`;
	public static MISC_CROP_DARK_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-crop-dark-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_DELETE = `${MediaUtils.MISC_BASE}${'ic-20-x-20-delete'}${MediaUtils.EXT_SVG}`;
	public static MISC_DELETE_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-delete-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_DELETE_DARK = `${MediaUtils.MISC_BASE}${'ic-20-x-20-delete-dark'}${MediaUtils.EXT_SVG}`;
	public static MISC_DELETE_DARK_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-delete-dark-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_DISCARD = `${MediaUtils.MISC_BASE}${'ic-20-x-20-discard'}${MediaUtils.EXT_SVG}`;
	public static MISC_DISCARD_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-discard-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_DISCARD_DARK = `${MediaUtils.MISC_BASE}${'ic-20-x-20-discard-dark'}${MediaUtils.EXT_SVG}`;
	public static MISC_DISCARD_DARK_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-discard-dark-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_DOWNLOAD = `${MediaUtils.MISC_BASE}${'ic-20-x-20-download'}${MediaUtils.EXT_SVG}`;
	public static MISC_DOWNLOAD_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-download-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_DOWNLOAD_DARK = `${MediaUtils.MISC_BASE}${'ic-20-x-20-download-dark'}${MediaUtils.EXT_SVG}`;
	public static MISC_DOWNLOAD_DARK_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-download-dark-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_EDIT_FULL = `${MediaUtils.MISC_BASE}${'ic-20-x-20-edit-full'}${MediaUtils.EXT_SVG}`;
	public static MISC_EDIT_FULL_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-edit-full-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_EDIT_FULL_DARK = `${MediaUtils.MISC_BASE}${'ic-20-x-20-edit-full-dark'}${MediaUtils.EXT_SVG}`;
	public static MISC_EDIT_FULL_DARK_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-edit-full-dark-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_PHOTO = `${MediaUtils.MISC_BASE}${'ic-20-x-20-photo'}${MediaUtils.EXT_SVG}`;
	public static MISC_PHOTO_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-photo-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_PHOTO_DARK = `${MediaUtils.MISC_BASE}${'ic-20-x-20-photo-dark'}${MediaUtils.EXT_SVG}`;
	public static MISC_PHOTO_DARK_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-photo-dark-hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_PUBLISH = `${MediaUtils.MISC_BASE}${'ic-20-x-20-publish'}${MediaUtils.EXT_SVG}`;
	public static BTN_PUBLISH_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-publish-hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_REPORT = `${MediaUtils.MISC_BASE}${'ic-20-x-20-report'}${MediaUtils.EXT_SVG}`;
	public static BTN_REPORT_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-report-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_TYPE = `${MediaUtils.MISC_BASE}${'ic-24-x-24-type'}${MediaUtils.EXT_SVG}`;
	public static MISC_TYPE_FOCUS = `${MediaUtils.MISC_BASE}${'ic-24-x-24-type-focus'}${MediaUtils.EXT_SVG}`;
	public static MISC_USER_ASSIGNEE = `${MediaUtils.MISC_BASE}${'ic-24-x-24-user-assignee'}${MediaUtils.EXT_SVG}`;
	public static MISC_USER_ASSIGNEE_HOVER = `${MediaUtils.MISC_BASE}${'ic-24-x-24-user-assignee-hover'}${MediaUtils.EXT_SVG}`;
	public static INPUT_USER_SUBSCRIBER = `${MediaUtils.MISC_BASE}${'ic-24-x-24-user-subscriber'}${MediaUtils.EXT_SVG}`;
	public static INPUT_USER_SUBSCRIBER_ERROR = `${MediaUtils.MISC_BASE}${'ic-24-x-24-user-subscriber-error'}${MediaUtils.EXT_SVG}`;
	public static INPUT_USER_SUBSCRIBER_FOCUS = `${MediaUtils.MISC_BASE}${'ic-24-x-24-user-subscriber-focus'}${MediaUtils.EXT_SVG}`;
	public static BTN_PAUSE = `${MediaUtils.MISC_BASE}${'ic-20-x-20-pause'}${MediaUtils.EXT_SVG}`;
	public static BTN_PAUSE_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-pause-hover'}${MediaUtils.EXT_SVG}`;
	public static BTN_RESUME = `${MediaUtils.MISC_BASE}${'ic-20-x-20-resume'}${MediaUtils.EXT_SVG}`;
	public static BTN_RESUME_HOVER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-resume-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_REQUIRED = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-required'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_REQUIRED_FOCUS = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-required-focus'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_COMMENT = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-comment'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_COMMENT_FOCUS = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-comment-focus'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_MEDIA = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-media'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_MEDIA_FOCUS = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-media-focus'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_DEFAULT = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-default'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_DEFAULT_FOCUS = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-default-focus'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_UNSPECIFIED_FOCUS = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-unspecified-focus'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_ERROR = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-error'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_ERROR_FOCUS = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-error-focus'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_PRIORITY = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-allow-priority'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_PRIORITY_FOCUS = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-allow-priority-focus'}${MediaUtils.EXT_SVG}`;
	public static MISC_RULE_PRIORITY_FOCUS_2 = `${MediaUtils.MISC_BASE}${'ic-20-x-20-rule-allow-priority-focus-2'}${MediaUtils.EXT_SVG}`;
	public static MISC_MORE_NEW = `${MediaUtils.MISC_BASE}${'ic-20-x-20-more'}${MediaUtils.EXT_SVG}`;
	public static MISC_MORE_NEW_FOCUS = `${MediaUtils.MISC_BASE}${'ic-20-x-20-more-hover'}${MediaUtils.EXT_SVG}`;
	public static MISC_FILTER = `${MediaUtils.MISC_BASE}${'ic-20-x-20-filter'}${MediaUtils.EXT_SVG}`;
	public static MISC_FILTER_FOCUS = `${MediaUtils.MISC_BASE}${'ic-20-x-20-filter-focus'}${MediaUtils.EXT_SVG}`;
	public static MISC_TASK = `${MediaUtils.MISC_BASE}${'ic-24-x-24-task'}${MediaUtils.EXT_SVG}`;
	public static MISC_TASK_FOCUS = `${MediaUtils.MISC_BASE}${'ic-24-x-24-task-focus'}${MediaUtils.EXT_SVG}`;
	public static MISC_TASK_ERROR = `${MediaUtils.MISC_BASE}${'ic-24-x-24-task-error'}${MediaUtils.EXT_SVG}`;
	public static MISC_TASK_INFO = `${MediaUtils.MISC_BASE}${'ic-24-x-24-task-info'}${MediaUtils.EXT_SVG}`;

	// Social icons
	public static SOCIAL_BASE = `${MediaUtils.BASE_URL_ICONS}${'social/'}`;
	public static SOCIAL_BASE_INTERPOLATION = `${MediaUtils.BASE_URL_ICONS}${'social/ic_24x24_social_'}`;
	public static SOCIAL_FACEBOOK = `${MediaUtils.SOCIAL_BASE}${'ic_24x24_social_facebook'}${MediaUtils.EXT_SVG}`;
	public static SOCIAL_FACEBOOK_DISABLED = `${MediaUtils.SOCIAL_BASE}${'ic_24x24_social_facebook_disabled'}${MediaUtils.EXT_SVG}`;
	public static SOCIAL_GOOGLE = `${MediaUtils.SOCIAL_BASE}${'ic_24x24_social_google'}${MediaUtils.EXT_SVG}`;
	public static SOCIAL_GOOGLE_DISABLED = `${MediaUtils.SOCIAL_BASE}${'ic_24x24_social_google_disabled'}${MediaUtils.EXT_SVG}`;
	public static SOCIAL_MICROSOFT = `${MediaUtils.SOCIAL_BASE}${'ic_24x24_social_microsoft'}${MediaUtils.EXT_SVG}`;
	public static SOCIAL_MICROSOFT_DISABLED = `${MediaUtils.SOCIAL_BASE}${'ic_24x24_social_microsoft_disabled'}${MediaUtils.EXT_SVG}`;
	public static SOCIAL_TWITTER = `${MediaUtils.SOCIAL_BASE}${'ic_24x24_social_twitter'}${MediaUtils.EXT_SVG}`;
	public static SOCIAL_TWITTER_DISABLED = `${MediaUtils.SOCIAL_BASE}${'ic_24x24_social_twitter_disabled'}${MediaUtils.EXT_SVG}`;

	// Wizard icons
	public static WIZ_BASE = `${MediaUtils.BASE_URL_ICONS}${'wizard/'}`;

	public static WIZARD_META = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-meta'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_META_FOCUS = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-meta-focus'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_META_HOVER = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-meta-hover'}${MediaUtils.EXT_SVG}`;

	public static WIZARD_MEDIA = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-media'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_MEDIA_FOCUS = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-media-focus'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_MEDIA_HOVER = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-media-hover'}${MediaUtils.EXT_SVG}`;

	public static WIZARD_ENTITY = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-entity'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_ENTITY_FOCUS = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-entity-focus'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_ENTITY_HOVER = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-entity-hover'}${MediaUtils.EXT_SVG}`;

	public static WIZARD_DONE = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-done'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_DONE_FOCUS = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-done-focus'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_DONE_HOVER = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-done-hover'}${MediaUtils.EXT_SVG}`;

	public static WIZARD_FACILITY = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-facility'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_FACILITY_FOCUS = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-facility-focus'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_FACILITY_HOVER = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-facility-hover'}${MediaUtils.EXT_SVG}`;

	public static WIZARD_SUBSCRIBER = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-user-subscriber'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_SUBSCRIBER_FOCUS = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-user-subscriber-focus'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_SUBSCRIBER_HOVER = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-user-subscriber-hover'}${MediaUtils.EXT_SVG}`;

	public static WIZARD_FACILITY_ENTITY = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-facilityentity'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_FACILITY_ENTITY_FOCUS = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-facilityentity-focus'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_FACILITY_ENTITY_HOVER = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-facilityentity-hover'}${MediaUtils.EXT_SVG}`;

	public static WIZARD_ASSIGNEE = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-user-assignee'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_ASSIGNEE_FOCUS = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-user-assignee-focus'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_ASSIGNEE_HOVER = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-user-assignee-hover'}${MediaUtils.EXT_SVG}`;

	public static WIZARD_TASK = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-task'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_TASK_FOCUS = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-task-focus'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_TASK_HOVER = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-task-hover'}${MediaUtils.EXT_SVG}`;

	public static WIZARD_ASSIGNMENT_TEMPLATE = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-assignment-template'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_ASSIGNMENT_TEMPLATE_FOCUS = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-assignment-template-focus'}${MediaUtils.EXT_SVG}`;
	public static WIZARD_ASSIGNMENT_TEMPLATE_HOVER = `${MediaUtils.WIZ_BASE}${'ic-34-x-34-assignment-template-hover'}${MediaUtils.EXT_SVG}`;

	public static PLACEHOLDER_IMAGE_USER = `assets/images/user_and_org/user${MediaUtils.EXT_SVG}`;
	public static PLACEHOLDER_IMAGE_ORGANIZATION = `assets/images/user_and_org/organization${MediaUtils.EXT_SVG}`;
}
