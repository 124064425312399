import { Component, Input } from '@angular/core';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { MediaWidgetItemViewerComponent } from 'app-core/media/widget/item/viewer/media-widget-item-viewer.component';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';
import { ColorUtils } from 'app-core/shared-core/tools/color-utils';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { Choice } from 'app-inspection/choice/choice';
import { Entity } from 'app-inspection/entity/entity';
import { ListEntityComponent } from 'app-inspection/entity/list-entity/list-entity.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IssueModalEntityComponent } from '../../issue-modal-entity/issue-modal-entity.component';

@Component({
	selector: 'handle-entities-tab-measure',
	templateUrl: './handle-entities-tab.component.html',
	styleUrls: ['./handle-entities-tab.component.less']
})
export class HandleEntitiesTabMeasureComponent extends SimpleHandleTabDirective<Entity> {

	colorUtils = ColorUtils;

	bsModalRef: BsModalRef<any>;

	@Input() facilityId: string;
	@Input() onlyActiveEntitiesInList: boolean;
	@Input() choice: Choice;

	toggleListEntityModal() {
		this.bsModalRef = this.modalService.show(
			ListEntityComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: this.readonlyListModal,
					hideTableHeaderActions: this.readonlyListModal,
					hideTableRowActions: this.readonlyListModal,
					idsToSetAsSelected: this.handleItems.map(item => item.id),
					facilityId: this.facilityId,
					facilityIds: [this.facilityId],
					onlyActiveEntities: this.onlyActiveEntitiesInList,
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	setupHover(div: HTMLDivElement) {
		const backgroundImageUrl = div.style.backgroundImage.slice(4, -1).replace(/"/g, "");
		DomUtils.showLargerImageOnHover(div, false, backgroundImageUrl);

		// Click
		const substringedImageUrl = backgroundImageUrl.substring(0, backgroundImageUrl.lastIndexOf('\/'));
		div.addEventListener('click',
			(event) => {
				event.stopPropagation();
				const item = new MediaWidgetItem({
					isImage: true,
					url: substringedImageUrl.slice(substringedImageUrl.lastIndexOf('\/') + 1)
				});
				if (this.bsModalRef) {
					this.closeModal();
				}
				setTimeout(() => {
					if (!this.bsModalRef) {
						this.bsModalRef = this.modalService.show(
							MediaWidgetItemViewerComponent,
							{
								initialState: {
									item: item,
									collection: [item]
								},
								...ConfigUtils.MODAL_CONFIG_LARGE_WITH_OUTSIDE_CLICK
							}
						);

						this.subscribeToCrudModalContent();
					}
				});
			});
	}

	itemListIsValid() {
		return this.handleItems?.every(entity => entity.issueDetails.length > 0);
	}

	openEntityIssueModal(entity: Entity) {
		this.bsModalRef = this.modalService.show(
			IssueModalEntityComponent,
			{
				initialState: {
					initialModel: new Entity(this.getUniqueVariant(entity)),
					modifiedModel: new Entity(this.getUniqueVariant(entity)),
					choice: this.choice,
					disableTabIndexUrl: true
				},
				...ConfigUtils.MODAL_CONFIG_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	openMediaTab(event: MouseEvent, entity: Entity) {
		event.stopImmediatePropagation();
		this.openEntityIssueModal(entity);
		setTimeout(() => {
			(this.bsModalRef.content as IssueModalEntityComponent).tabsetComponent.tabs[1].active = true;
		});
	}

	getXMoreParam(entity: Entity) {
		return {
			x: entity.issueMedia.length - 5
		};
	}

	override handleUpdated(items: Entity[]) {
		items.forEach(item => {
			const existingItem = this.handleItems.find(entity => entity.id === item.id);
			if (existingItem) {
				Object.assign(existingItem, item);
				this.validate([existingItem]);
			}
		});
		this.onUpdated.emit(items);
		this.closeModal();
	}

	protected override getUniqueVariant(data: Entity) {
		const { issueMedia, ...theRest } = data;
		const stringified = JSON.stringify(theRest);
		const object = JSON.parse(stringified);
		object.issueMedia = issueMedia.slice();
		return object;
	}

	override emitModelsChange() {
		this.handleItems.sortByProperty(this.propertyStrings.name);
		super.emitModelsChange();
	}

	protected validate(entities: Entity[]) {
		entities.forEach(entity => {
			entity.errorMessages = [];
			if (!entity.issueDetails) {
				entity.errorMessages.push('IssueDetailsMustBeAdded');
			}
		});
	}

	getItems() {
		return this.searchValue
			? this.handleItems.filter(item => item.name.toLowerCase().includes(this.searchValue.toLowerCase())
				|| item.description.toLowerCase().includes(this.searchValue.toLowerCase()))
			: this.handleItems;
	}
}
