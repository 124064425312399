import { Component, Input } from '@angular/core';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { Organization } from 'app-core/organization/organization';
import { SimpleViewTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/view/simple-view-tab.directive';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { User } from 'app-core/user/user';
import { ScheduleForViewing } from 'app-inspection/schedule/schedule';
import { environment } from 'environments/environment';

@Component({
	selector: 'view-details-tab',
	templateUrl: './view-details-tab.component.html',
	styleUrls: ['./view-details-tab.component.less']
})
export class ViewDetailsTabComponent extends SimpleViewTabDirective<User> {

	@Input() documents: MediaWidgetItem[] = [];
	@Input() schedules: ScheduleForViewing[];

	get organizations() {
		const organizations = [this.viewModel.organization, ...this.viewModel.organizationMemberships.map(membership => membership.organization)];
		const selectedOrg = organizations.find(organization => organization.id === this.selectedOrganization.id);
		if (selectedOrg) {
			selectedOrg.isSelectedOrg = true;
		}
		return organizations;
	}

	getCountryName() {
		const country = this.viewModel.selectableCountries.find(c => c.id === this.viewModel.country);
		if (country) {
			return country.text;
		} else {
			return '';
		}
	}

	getTimeZoneName() {
		const timeZone = this.viewModel.selectableTimeZones.find(tz => tz.id === this.viewModel.timeZone);
		if (timeZone) {
			return timeZone.text;
		} else {
			return '';
		}
	}

	getCultureName() {
		const culture = this.viewModel.selectableCultures.find(c => c.id === this.viewModel.culture);
		if (culture) {
			return culture.text;
		} else {
			return '';
		}
	}

	getFormattedRoles(roles: string[]) {
		return this.translationService.translateArray(roles).toCommaSeparatedList();
	}

	getOrgName(organization: Organization) {
		return new Organization(organization).orgName;
	}

	selectOrganization(organization: Organization, allowed: boolean) {
		if (organization.id !== this.selectedOrganization.id && allowed) {
			const linkWord = this.viewModel.getCurrentStatus(organization.id) === StringUtils.PENDING ? 'resendEmail' : 'edit';
			const url = `${environment.coreUrl}/${organization.friendlyUrl}/users?user=${linkWord}_${this.viewModel.id}&tab=1`;
			window.location.href = url;
		}
	}
}
