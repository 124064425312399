import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { ToastrService } from 'ngx-toastr';
import { ColorUtils } from '../tools/color-utils';
import { TranslationService } from '../translation/translation.service';

@Component({
	selector: 'google-maps-component',
	templateUrl: './google-maps.component.html',
	styleUrls: ['./google-maps.component.less'],
})
export class GoogleMapsComponent implements AfterViewInit {

	options: google.maps.MapOptions;

	map: google.maps.Map;
	marker: google.maps.Marker;
	markerCircle: google.maps.Circle;

	showMapLoader: boolean = true;

	readonly defaultMarginTop: number = 0;
	readonly defaultHeight: number = 200;
	readonly defaultRadius: number = 250;

	readonly fallbackLatitude: string = '60.1282';
	readonly fallbackLongitude: string = '18.6435';

	@Input() marginTop: number;
	@Input() height: number;
	@Input() latitude: string;
	@Input() longitude: string;
	@Input() radius: string;
	@Input() address: string;
	@Input() country: string;
	@Input() name: string;
	@Input() isStatic: boolean;

	@Output() onMarkerPositionChanged = new EventEmitter<LocationObject>();

	@ViewChild('mapContainer') mapContainer: any;

	constructor(
		private translationService: TranslationService,
		private geoCoder: MapGeocoder,
		private toastrService: ToastrService,
		private elementRef: ElementRef) { }

	async ngAfterViewInit() {
		this.setSize();
		if (this.latitude && this.longitude) {
			this.initMap(this.latitude, this.longitude, true);
		} else if (this.address) {
			const locationObject = await this.getLocationFromAddress();
			if (locationObject) {
				this.initMap(locationObject.latitude, locationObject.longitude);
			} else {
				this.initMapWithFallbackLocation();
			}
		} else if (this.country) {
			const locationObject = await this.getLocationFromCountry();
			if (locationObject) {
				this.initMap(locationObject.latitude, locationObject.longitude);
			} else {
				this.initMapWithFallbackLocation();
			}
		} else {
			this.initMapWithFallbackLocation();
		}
	}

	private initMapWithFallbackLocation() {
		this.initMap(this.fallbackLatitude, this.fallbackLongitude);
	}

	private initMap(latitude: string, longitude: string, shouldDisplayMarker?: boolean) {
		setTimeout(() => {
			this.setSharedOptions();

			if (this.isStatic) {
				this.setStaticOptions();
			}

			const location = new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude));
			this.options.center = location;

			this.createMap();
			this.createMarker();
			this.createCircle();

			if (shouldDisplayMarker) {
				this.displayMarker();
				this.determineZoom();
			}

			this.showMapLoader = false;

			this.updateLocation(location);
		}, 1000);
	}

	private setSharedOptions() {
		this.options = new MapConfig({}).options;
	}

	private setStaticOptions() {
		this.options.scrollwheel = false;
		this.options.zoomControl = false;
		this.options.keyboardShortcuts = false;
		this.options.draggableCursor = 'pointer';
		this.options.draggingCursor = 'default';
		this.options.gestureHandling = 'none';
		this.options.fullscreenControl = false;
	}

	private setSize() {
		const marginTop = this.marginTop ? this.marginTop : this.defaultMarginTop;
		const height = this.height ? this.height : this.defaultHeight;
		this.elementRef.nativeElement.style.setProperty('margin-top', marginTop + 'px');
		this.elementRef.nativeElement.style.setProperty('height', height + 'px');
	}

	private createMap() {
		this.map = new google.maps.Map(this.mapContainer.nativeElement, this.options);
		this.map.addListener('click', (event: any) => {
			this.mapClick(event);
		});
	}

	private createMarker() {
		const path = "M148.5,0C87.43,0,37.747,49.703,37.747,110.797c0,91.026,99.729,179.905,103.976,183.645 c1.936,1.705,4.356,2.559,6.777,2.559c2.421,0,4.841-0.853,6.778-2.559c4.245-3.739,103.975-92.618,103.975-183.645 C259.253,49.703,209.57,0,148.5,0z M148.5,79.693c16.964,0,30.765,13.953,30.765,31.104c0,17.151-13.801,31.104-30.765,31.104 c-16.964,0-30.765-13.953-30.765-31.104C117.735,93.646,131.536,79.693,148.5,79.693z";
		this.marker = new google.maps.Marker({
			position: this.map.getCenter(),
			zIndex: 2,
			icon: {
				path: path,
				anchor: new google.maps.Point(297 / 2, 297),
				fillColor: ColorUtils.GREEN_COLOR,
				fillOpacity: 1,
				strokeOpacity: 0,
				scale: 0.1,
			},
			map: this.map,
			visible: false
		});

		if (this.isStatic) {
			this.marker.setDraggable(false);
			this.marker.setCursor('pointer');
			this.marker.addListener('click', () => {
				this.openMapInNewTab();
			});
		} else {
			this.marker.setDraggable(true);
			this.marker.setCursor('grab');

			this.marker.addListener('mouseover', () => {
				this.setLabelOnMarker();
			});
			this.marker.addListener('mouseout', () => {
				this.removeLabelOnMarker();
			});
			this.marker.addListener('dragend', (event: any) => {
				this.emitLocation(event.latLng);
			});
		}
	}

	private createCircle() {
		this.markerCircle = new google.maps.Circle({
			strokeColor: ColorUtils.GREEN_COLOR,
			strokeOpacity: 0.8,
			strokeWeight: 0.5,
			fillColor: ColorUtils.GREEN_COLOR,
			fillOpacity: 0.10,
			map: this.map,
			center: this.marker.getPosition(),
			radius: this.radius ? parseInt(this.radius) : 0,
			draggable: false,
			clickable: false,
			editable: false,
			zIndex: 1,
			visible: false
		});
	}

	public async getAddressFromLocation() {
		this.showMapLoader = true;
		try {
			const response = await this.geoCoder.geocode({ location: this.marker.getPosition() }).toPromise();
			if (response.status === google.maps.GeocoderStatus.OK) {
				let result: google.maps.GeocoderResult;
				if (response.results.find((result) => result.types.includes('street_address'))) {
					result = response.results.find((result) => result.types.includes('street_address'));
				} else if (response.results.find((result) => result.types.includes('premise'))) {
					result = response.results.find((result) => result.types.includes('premise'));
				} else if (response.results.find((result) => result.types.includes('route'))) {
					result = response.results.find((result) => result.types.includes('route'));
				} else if (response.results.find((result) => result.types.includes('postal_code'))) {
					result = response.results.find((result) => result.types.includes('postal_code'));
				} else if (response.results.find((result) => result.types.includes('plus_code'))) {
					result = response.results.find((result) => result.types.includes('plus_code'));
				}
				if (result) {
					const addressObject = new AddressObject({
						streetName: result.address_components.filter((component) => component.types.includes('route'))[0]?.long_name,
						streetNumber: result.address_components.filter((component) => component.types.includes('street_number'))[0]?.long_name,
						zipCode: result.address_components.filter((component) => component.types.includes('postal_code'))[0]?.long_name,
						city: result.address_components.filter((component) => component.types.includes('postal_town'))[0]?.long_name
					});
					this.showMapLoader = false;
					return addressObject;
				} else {
					this.toastrService.error(this.translationService.instant('NoAddressCouldBeFoundFromLocation'));
					this.showMapLoader = false;
					return null;
				}
			} else if (response.status === google.maps.GeocoderStatus.ZERO_RESULTS) {
				this.toastrService.error(this.translationService.instant('NoAddressCouldBeFoundFromLocation'));
				this.showMapLoader = false;
				return null;
			} else {
				this.toastrService.error(this.translationService.instant('SomethingWentWrongWhenGettingAddressFromLocation'));
				this.showMapLoader = false;
				return null;
			}
		} catch (errorResponse) {
			this.toastrService.error(this.translationService.instant('SomethingWentWrongWhenGettingAddressFromLocation'));
			this.showMapLoader = false;
			return null;
		}
	}

	public async getLocationFromAddress() {
		this.showMapLoader = true;
		try {
			const response = await this.geoCoder.geocode({ address: this.address }).toPromise();
			if (response.status === google.maps.GeocoderStatus.OK) {
				if (response.results[0].geometry.location.lat() && response.results[0].geometry.location.lng()) {
					const locationObject = new LocationObject({
						latitude: response.results[0].geometry.location.lat().toString(),
						longitude: response.results[0].geometry.location.lng().toString(),
						radius: this.radius ? this.radius : this.defaultRadius.toString()
					});
					this.showMapLoader = false;
					return locationObject;
				} else {
					this.toastrService.error(this.translationService.instant('NoLocationCouldBeFoundFromAddress'));
					this.showMapLoader = false;
					return null;
				}
			} else if (response.status === google.maps.GeocoderStatus.ZERO_RESULTS) {
				this.toastrService.error(this.translationService.instant('NoLocationCouldBeFoundFromAddress'));
				this.showMapLoader = false;
				return null;
			} else {
				this.toastrService.error(this.translationService.instant('SomethingWentWrongWhenGettingLocationFromAddress'));
				this.showMapLoader = false;
				return null;
			}
		} catch (errorResponse) {
			this.toastrService.error(this.translationService.instant('SomethingWentWrongWhenGettingLocationFromAddress'));
			this.showMapLoader = false;
			return null;
		}
	}

	private async getLocationFromCountry() {
		this.showMapLoader = true;
		try {
			const response = await this.geoCoder.geocode({
				componentRestrictions: {
					country: this.country
				}
			}).toPromise();

			if (response.status === google.maps.GeocoderStatus.OK) {
				const locationObject = new LocationObject({
					latitude: response.results[0].geometry.location.lat().toString(),
					longitude: response.results[0].geometry.location.lng().toString(),
					radius: this.radius ? this.radius : this.defaultRadius.toString()
				});
				this.showMapLoader = false;
				return locationObject;
			} else if (response.status === google.maps.GeocoderStatus.ZERO_RESULTS) {
				this.toastrService.error(this.translationService.instant('NoLocationCouldBeFoundFromCountry'));
				this.showMapLoader = false;
				return null;
			} else {
				this.toastrService.error(this.translationService.instant('SomethingWentWrongWhenGettingLocationFromCountry'));
				this.showMapLoader = false;
				return null;
			}
		} catch (errorResponse) {
			this.toastrService.error(this.translationService.instant('SomethingWentWrongWhenGettingLocationFromCountry'));
			this.showMapLoader = false;
			return null;
		}
	}

	public async getCurrentLocation() {
		this.showMapLoader = true;
		return new Promise<LocationObject>((resolve) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position) => {
					const locationObject = new LocationObject({
						latitude: position.coords.latitude.toString(),
						longitude: position.coords.longitude.toString(),
						radius: this.radius ? this.radius : this.defaultRadius.toString()
					});
					this.showMapLoader = false;
					resolve(locationObject);
				}, () => {
					this.toastrService.error(this.translationService.instant('SomethingWentWrongWhenGettingCurrentLocation'));
					this.showMapLoader = false;
					resolve(null);
				}, { enableHighAccuracy: true });
			} else {
				this.toastrService.error(this.translationService.instant('SomethingWentWrongWhenGettingCurrentLocation'));
				console.warn('Your browser does not support geolocation API');
				this.showMapLoader = false;
				resolve(null);
			}
		});
	}

	public updateLocation(latLng: google.maps.LatLng) {
		this.marker.setPosition(latLng);
		this.map.panTo(latLng);

		if (this.radius) {
			this.markerCircle.setCenter(latLng);
			this.markerCircle.setRadius(parseFloat(this.radius));
		}
	}

	public emitLocation(latLng: google.maps.LatLng) {
		this.onMarkerPositionChanged.emit(new LocationObject({
			latitude: latLng.lat().toString(),
			longitude: latLng.lng().toString(),
			radius: this.radius ? this.radius : this.defaultRadius.toString()
		}));
	}

	public displayMarker() {
		this.marker.setVisible(true);
		if (this.radius) {
			this.markerCircle.setVisible(true);
		}
	}

	mapClick(event: any) {
		if (this.isStatic) {
			this.openMapInNewTab();
		} else {
			this.emitLocation(event.latLng);
		}
	}

	public determineZoom() {
		const zoomLevel = this.map.getZoom();
		if (zoomLevel < 10) {
			this.map.setZoom(10);
		}
	}

	private openMapInNewTab() {
		const url = 'https://www.google.com/maps/search/?api=1&query=' + this.marker?.getPosition().lat() + ',' + this.marker?.getPosition().lng();
		const link = document.createElement('a');
		link.href = url;
		link.target = '_blank';
		link.click();
	}

	private setLabelOnMarker() {
		const label = {
			text: this.name ? this.name : `[${this.translationService.instant('Name')}]`,
			className: 'custom-label'
		} as google.maps.MarkerLabel;
		this.marker.setLabel(label);
	}

	private removeLabelOnMarker() {
		this.marker.setLabel(null);
	}
}

export class MapConfig {
	static apiKey: string = 'AIzaSyABSbHXrjhjU___sJ6D0HfADh1wEGLGZHk';

	options: google.maps.MapOptions = {
		mapId: '7d9d537a3e8ba477',
		zoom: 5,
		maxZoom: 18,
		minZoom: 4,
		controlSize: 22,
		streetViewControl: false,
		clickableIcons: false,
		disableDoubleClickZoom: true,
		draggableCursor: 'crosshair',
		draggingCursor: 'move',
		gestureHandling: 'cooperative',
		restriction: {
			latLngBounds: {
				north: 85,
				south: -85,
				west: -180,
				east: 180,
			},
			strictBounds: false,
		}
	};

	constructor(config: Partial<MapConfig>) {
		Object.assign(this, config);
	}
}

export class LocationObject {
	latitude: string = '';
	longitude: string = '';
	radius: string = '';

	constructor(location: Partial<LocationObject>) {
		Object.assign(this, location);
	}
}

export class AddressObject {
	streetName: string;
	streetNumber: string;
	zipCode: string;
	city: string;

	constructor(address: Partial<AddressObject>) {
		Object.assign(this, address);
	}
}
