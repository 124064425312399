import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Choice } from 'app-inspection/choice/choice';
import { Task } from 'app-inspection/task/task';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../app-core/auth/auth.service';
import { StringUtils } from '../../../app-core/shared-core/tools/string-utils';
import { TemplateType } from '../template-type';
import { TemplateTypeService } from '../template-type.service';
import { ChangeDetailsTabComponent } from './tabs/change-details/change-details-tab.component';
import { HandleTasksTabComponent } from './tabs/handle-tasks/handle-tasks-tab.component';

@Component({
	templateUrl: './create-edit-template-type.component.html'
})
export class CreateEditTemplateTypeComponent extends SimpleCreateEditModalDirective<TemplateType> implements OnInit {

	@ViewChild(ChangeDetailsTabComponent) changeTemplateTypeDetailsTabComponent: ChangeDetailsTabComponent;
	@ViewChild(HandleTasksTabComponent) handleTasksTabComponent: HandleTasksTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private templateTypeService: TemplateTypeService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			if (this.isEdit) {
				this.initialModel = await this.templateTypeService.get(this.editModelId);
				this.pending = false;

				this.initialModel.selectableTemplateBaseTypes.push(this.initialModel.templateBaseType);

				this.modifiedModel = new TemplateType(this.getUniqueVariant(this.initialModel));

				setTimeout(() => {
					this.activateTabFromUrl();
					this.triggerAllValidation();
				}, 0);
			} else {
				const templateBaseTypes = await this.templateTypeService.getTemplateBaseTypes(true, this.selectedOrganization.id);

				// Abort if no template base types.
				if (!templateBaseTypes.length) {
					this.displayErrorMessage(`${this.translationService.instant(StringUtils.NO_TEMPLATE_BASE_TYPE_ERROR)}!`);
					this.closeWithDelay();
					return;
				}
				this.pending = false;

				const standardTemplateBaseType = templateBaseTypes.find(templateBaseType => Utils.isStandard(templateBaseType));
				this.initialModel = new TemplateType({
					templateBaseTypeId: standardTemplateBaseType.id,
					selectableTemplateBaseTypes: templateBaseTypes
				});

				this.modifiedModel = new TemplateType(this.getUniqueVariant(this.initialModel));

				setTimeout(() => {
					this.activateTabFromUrl();
				}, 0);
			}

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	handleUpdatedChoices(updatedChoices: Choice[]) {
		if (this.modifiedModel.notificationChoice) {
			const choiceToUpdateFrom = updatedChoices.find(choice => choice.id === this.modifiedModel.notificationChoice.id);
			if (choiceToUpdateFrom) {
				Object.assign(this.modifiedModel.notificationChoice, choiceToUpdateFrom);
				this.changeTemplateTypeDetailsTabComponent.validate();
			}
		}
	}

	handleDeletedChoices(deletedChoiceIds: string[]) {
		if (this.modifiedModel.notificationChoice && deletedChoiceIds.includes(this.modifiedModel.notificationChoice.id)) {
			this.modifiedModel.notificationChoice = undefined;
			this.changeTemplateTypeDetailsTabComponent.notificationChoiceErrorMessages = [];
		}
	}

	handleChanges() {
		if (this.modifiedModel.notificationChoice) {
			this.changeTemplateTypeDetailsTabComponent.validate();
		}
	}

	changeDetailsIsValid() {
		return this.changeTemplateTypeDetailsTabComponent?.formIsValid() && (!this.modifiedModel.notificationChoice || (this.modifiedModel.notificationChoice.isError && this.modifiedModel.tasks.flatMap(task => task.choices).some(choice => choice.id === this.modifiedModel.notificationChoice.id)));
	}

	changeDetailsHasErrors() {
		return this.changeTemplateTypeDetailsTabComponent?.formHasErrors() || this.changeTemplateTypeDetailsTabComponent?.notificationChoiceErrorMessages.length > 0;
	}

	handleTasksIsValid() {
		return this.handleTasksTabComponent?.itemListIsValid();
	}

	handleTasksHasErrors() {
		return this.handleTasksTabComponent?.itemListHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid() && this.handleTasksIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.templateTypeService.updateWithAffectedEntities(this.modifiedModel.toPayloadObject(this.selectedOrganization.id))
				: await this.templateTypeService.create(this.modifiedModel.toPayloadObject(this.selectedOrganization.id));

			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeTemplateTypeDetailsTabComponent.setServerErrors(this.serverErrors);
				this.goToTab(1);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeTemplateTypeDetailsTabComponent?.triggerValidation();
		this.handleTasksTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: TemplateType) {
		return new TemplateType(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.name !== this.initialModel.name
			|| this.modifiedModel.abbreviation !== this.initialModel.abbreviation
			|| this.modifiedModel.templateBaseTypeId !== this.initialModel.templateBaseTypeId
			|| !Utils.isEqual(this.modifiedModel.notificationChoice, this.initialModel.notificationChoice);
	}

	private tasksHasChanged() {
		const currentTasks = this.stripSelected(this.modifiedModel.tasks);
		const initialTasks = this.stripSelected(this.initialModel.tasks);
		return !Utils.isEqual(currentTasks, initialTasks);
	}

	private stripSelected(items: Task[]) {
		return items.map(item => {
			const { selected, ...theRest } = item;
			return theRest as Task;
		});
	}

	private inlineListHasUnsavedChanges() {
		return this.handleTasksTabComponent?.inlineListHasUnsavedChanges();
	}

	hasUnsavedChanges() {
		return this.modifiedModel && (this.detailsHasChanged() || this.tasksHasChanged() || this.inlineListHasUnsavedChanges());
	}
}
