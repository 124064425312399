import { NgModule } from '@angular/core';
import { MediaModule } from 'app-core/media/media-module';
import { SharedModule } from 'app-core/shared-core/shared.module';
import { FacilityModule } from 'app-inspection/facility/facility.module';
import { TemplateTypeModule } from 'app-inspection/template-type/template-type.module';
import { AssignmentTemplateRoutingModule } from './assignment-template-routing.module';
import { CreateEditAssignmentTemplateComponent } from './create-edit-assignment-template/create-edit-assignment-template.component';
import { ChangeDetailsTabComponent } from './create-edit-assignment-template/tabs/change-details/change-details-tab.component';
import { HandleFacilitiesTabComponent } from './create-edit-assignment-template/tabs/handle-facilities/handle-facilities-tab.component';
import { DeleteAssignmentTemplatesComponent } from './delete-assignment-templates/delete-assignment-templates.component';
import { HandleCategoriesComponent } from './list-assignment-template/handle-categories/handle-categories.component';
import { HandleCategoriesTabComponent } from './list-assignment-template/handle-categories/tabs/handle-categories-tab.component';
import { HandleClientComponent } from './list-assignment-template/handle-client/handle-client.component';
import { HandleClientTabComponent } from './list-assignment-template/handle-client/tabs/handle-client-tab.component';
import { ListAssignmentTemplateComponent } from './list-assignment-template/list-assignment-template.component';
import { SetGeoFencingComponent } from './list-assignment-template/set-geo-fencing/set-geo-fencing.component';
import { SetGeoFencingTabComponent } from './list-assignment-template/set-geo-fencing/tabs/set-geo-fencing-tab.component';

@NgModule({
	imports: [
		SharedModule,
		AssignmentTemplateRoutingModule,
		MediaModule,
		TemplateTypeModule,
		FacilityModule
	],
	declarations: [
		ListAssignmentTemplateComponent,
		CreateEditAssignmentTemplateComponent,
		ChangeDetailsTabComponent,
		HandleFacilitiesTabComponent,
		DeleteAssignmentTemplatesComponent,
		HandleCategoriesComponent,
		HandleCategoriesTabComponent,
		HandleClientComponent,
		HandleClientTabComponent,
		SetGeoFencingComponent,
		SetGeoFencingTabComponent,
	],
	exports: [
		HandleFacilitiesTabComponent
	]
})
export class AssignmentTemplateModule { }
