import { ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Category, SimpleCategoriesComponent } from 'app-core/shared-core/simple-components/various/categories/simple-categories.component';
import { SimpleTableColumn } from './simple-table-column';

export class SimpleTableCategoriesColumn<T> extends SimpleTableColumn<T> {

	readonly getCategories: (item: T) => Category[];

	constructor(
		label: string,
		getCategories: (item: T) => Category[],
		sanitizer: DomSanitizer,
		viewContainerRef: ViewContainerRef,
		secondaryLabel: string) {
		super(
			label,
			sanitizer,
			viewContainerRef,
			secondaryLabel
		);
		this.getCategories = getCategories;
	}

	renderValue(item: T) {
		const componentRef = this.viewContainerRef.createComponent(SimpleCategoriesComponent);
		const categories = this.getCategories(item);
		componentRef.instance.categories = categories;
		componentRef.instance.triggerViewChanges();
		this.viewContainerRef.clear();
		const categoriesDiv = componentRef.location.nativeElement.childNodes[0] as HTMLDivElement;
		return this.sanitizer.bypassSecurityTrustHtml(categoriesDiv.outerHTML);
	}
}
