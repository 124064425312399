import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { RegexUtils } from 'app-core/shared-core/tools/regex-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { InvitedUser } from 'app-core/user/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'password-tab',
	templateUrl: './password-tab.component.html'
})
export class PasswordTabComponent extends SimpleChangeTabDirective<InvitedUser> implements OnInit {

	@Input() otherUserId: string;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		if (this.otherUserId) {
			this.form = this.formBuilder.group({
				[this.propertyStrings.newPassword]: [this.changeModel.newPassword,
				[
					Validators.required,
					this.passwordDigitValidator,
					this.passwordLowerValidator,
					this.passwordUpperValidator,
					this.passwordSpecialValidator,
					this.passwordLengthValidator
				]
				],
				[this.propertyStrings.confirmPassword]: [this.changeModel.confirmPassword,
				Validators.required]
			}, { validators: this.passwordMatchValidator });
		} else {
			this.form = this.formBuilder.group({
				[this.propertyStrings.oldPassword]: [this.changeModel.oldPassword,
				Validators.required],
				[this.propertyStrings.newPassword]: [this.changeModel.newPassword,
				[
					Validators.required,
					this.passwordDigitValidator,
					this.passwordLowerValidator,
					this.passwordUpperValidator,
					this.passwordSpecialValidator,
					this.passwordLengthValidator
				]
				],
				[this.propertyStrings.confirmPassword]: [this.changeModel.confirmPassword,
				Validators.required]
			}, { validators: this.passwordMatchValidator });
		}
		super.ngOnInit();
	}

	private passwordMatchValidator(form: FormGroup): ValidationErrors {
		const newPassword = form.get('newPassword');
		const confirmPassword = form.get('confirmPassword');

		if (newPassword.value && confirmPassword.value && newPassword.value !== confirmPassword.value) {
			confirmPassword.setErrors({ passwordsDoNotMatch: true });
		}

		if (newPassword.value && confirmPassword.value && newPassword.value === confirmPassword.value) {
			confirmPassword.setErrors(null);
		}
		return null;
	}

	private passwordDigitValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_DIGIT).test(control.value)) {
			return { passwordRequiresDigit: true };
		} else {
			return null;
		}
	}

	private passwordLowerValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_LOWER).test(control.value)) {
			return { passwordRequiresLower: true };
		} else {
			return null;
		}
	}

	private passwordUpperValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_UPPER).test(control.value)) {
			return { passwordRequiresUpper: true };
		} else {
			return null;
		}
	}

	private passwordSpecialValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_SPECIAL).test(control.value)) {
			return { passwordRequiresNonAlphanumeric: true };
		} else {
			return null;
		}
	}

	private passwordLengthValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_LENGTH).test(control.value)) {
			return { passwordRequiresLengthSixOrMore: true };
		} else {
			return null;
		}
	}
}
