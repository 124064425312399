import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleChangeBulkTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/bulk/simple-change-bulk-tab.directive';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { AssignmentTemplate } from 'app-inspection/assignment-template/assignment-template';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'handle-client-tab',
	templateUrl: './handle-client-tab.component.html'
})
export class HandleClientTabComponent extends SimpleChangeBulkTabDirective<AssignmentTemplate> implements OnInit {

	selectableClientItems: FormSelectItem[] = [];

	clientsIsDisabled: boolean = false;
	disabledClientLink: string = '';

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableClientItems();

		this.clientsIsDisabled = !this.selectableClientItems.length;
		this.disabledClientLink = this.selectedOrganization.url;

		this.form = this.formBuilder.group({
			[this.propertyStrings.clientId]: [this.changeModel.clientId]
		});
		super.ngOnInit();
	}

	private setSelectableClientItems() {
		this.selectableClientItems = this.changeModel.selectableClients.map(client => new FormSelectItem(client.id, `${client.name} (${client.email})`));
	}
}
