import { Component, ViewChild } from '@angular/core';
import { HandleMediaTabComponent } from 'app-core/media/handle-media/handle-media-tab.component';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { ConversionHelper } from 'app-core/shared-core/tools/conversion-helper';
import { Choice } from 'app-inspection/choice/choice';
import { Facility } from 'app-inspection/facility/facility';
import { IssueDetailsFacilityTabComponent } from './tabs/issue-details-facility/issue-details-facility-tab.component';

@Component({
	selector: 'issue-modal-facility',
	templateUrl: './issue-modal-facility.component.html',
	styleUrls: ['./issue-modal-facility.component.less']
})
export class IssueModalFacilityComponent extends SimpleCreateEditModalDirective<Facility> {

	choice: Choice;

	protected createOrUpdate(): void { }

	@ViewChild(IssueDetailsFacilityTabComponent) issueDetailsTabComponent: IssueDetailsFacilityTabComponent;
	@ViewChild(HandleMediaTabComponent) handleMediaTabComponent: HandleMediaTabComponent;

	everythingIsValid(): boolean {
		return this.issueDetailsIsValid();
	}

	issueDetailsIsValid() {
		return this.issueDetailsTabComponent?.formIsValid();
	}

	issueDetailsHasErrors() {
		return this.issueDetailsTabComponent?.formHasErrors();
	}

	handleIssueMedia(event: MediaWidgetItem[]) {
		/**
		 * Backend want base64 for images on manual measures,
		 * 	so I added a new property, 'base64' on MediaWidgetItems and set that property immediately
		 **/
		this.modifiedModel.issueMedia = event;
		this.modifiedModel.issueMedia.forEach(async item => {
			item.base64 = await ConversionHelper.blobToBase64asPromise(item.blob);
		});
	}

	private detailsHasChanged() {
		return this.modifiedModel.issueDetails !== this.initialModel.issueDetails
			|| this.modifiedModel.priority !== this.initialModel.priority
			|| this.modifiedModel.geoControlled !== this.initialModel.geoControlled
			|| this.modifiedModel.issueMedia.length !== this.initialModel.issueMedia.length;
	}

	hasUnsavedChanges() {
		return this.detailsHasChanged();
	}

	override submit(): void {
		if (this.everythingIsValid()) {
			this.updated$.next([this.modifiedModel]);
		} else {
			this.triggerAllValidation();
		}
	}

	protected triggerAllValidation(): void {
		this.issueDetailsTabComponent.triggerValidation();
	}

	protected instantiateModel(item: Facility) {
		return new Facility(item);
	}
}
