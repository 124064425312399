import { Component, Input } from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { Organization } from 'app-core/organization/organization';
import { User } from 'app-core/user/user';
import { environment } from 'environments/environment';
import { StringUtils } from '../tools/string-utils';

@Component({
	selector: 'view-members',
	templateUrl: './view-members.component.html',
	styleUrls: ['./view-members.component.less']
})
export class ViewMembersComponent {

	actionsOpened: boolean = false;
	loggedInUser: User;
	selectedOrganization: Organization;

	@Input() members: User[];
	@Input() shouldStopPropagation: boolean = false;

	constructor(authService: AuthService) {
		this.loggedInUser = authService.loggedInUser;
		this.selectedOrganization = authService.selectedOrganization;

		this.canEdit = authService.canEdit();
	}

	navigateTo(user: User) {
		const linkWord = user.getCurrentStatus(this.selectedOrganization.id) === StringUtils.PENDING ? 'resendEmail' : 'edit';
		const url = `${environment.coreUrl}/${this.selectedOrganization.friendlyUrl}/users?user=${linkWord}_${user.id}&tab=1`;

		window.open(url, '_blank');
	}
}
