import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { LoggedInUser, User } from 'app-core/user/user';
import { TemplateBaseType } from 'app-inspection/template-type/template-type';
import { isEqual } from 'lodash';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { NumberUtils } from './number-utils';
import { StringUtils } from './string-utils';

export const DEFAULT_DISPLAY_DATE_FORMAT = 'lll';
export const ALT_DISPLAY_DATE_FORMAT = 'LLL';
export const FILTER_DISPLAY_DATE_FORMAT = 'LL';

export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD h:mm:ss';
export const ALT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const ALT_DATE_TIME_FORMAT_WITHOUT_SECONDS = 'YYYY-MM-DD HH:mm';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_FORMAT_TIME = 'HH:mm:ss';
export const DEFAULT_DATE_FORMAT_EXPORT = 'YYYY-MM-DD';
export const DEFAULT_TIME_FORMAT = 'HH:mm';


// TODO ta bort oanvänt skit!
/**
 * A const enum that includes all non-printable string values one can expect from $event.key.
 * For example, this enum includes values like "CapsLock", "Backspace", and "AudioVolumeMute",
 * but does not include values like "a", "A", "#", "é", or "¿".
 * Auto generated from MDN: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values#Speech_recognition_keys
 */
export const enum KEYS {
	/** The user agent wasn't able to map the event's virtual keycode to a specific key value. This can happen due to hardware or software constraints, or because of constraints around the platform on which the user agent is running. */
	Unidentified = 'Unidentified',

	/** The Alt (Alternative) key. */
	Alt = 'Alt',

	/** The AltGr or AltGraph (Alternate Graphics) key. Enables the ISO Level 3 shift modifier (where Shift is the level 2 modifier). */
	AltGraph = 'AltGraph',

	/** The Caps Lock key. Toggles the capital character lock on and off for subsequent input. */
	CapsLock = 'CapsLock',

	/** The Control, Ctrl, or Ctl key. Allows typing control characters. */
	Control = 'Control',

	/** The Fn (Function modifier) key. Used to allow generating function key (F1-F15, for instance) characters on keyboards without a dedicated function key area. Often handled in hardware so that events aren't generated for this key. */
	Fn = 'Fn',

	/** The FnLock or F-Lock (Function Lock) key.Toggles the function key mode described by "Fn" on and off. Often handled in hardware so that events aren't generated for this key. */
	FnLock = 'FnLock',

	/** The Meta key. Allows issuing special command inputs. This is the Windows logo key, or the Command or ⌘ key on Mac keyboards. */
	Meta = 'Meta',

	/** The NumLock (Number Lock) key. Toggles the numeric keypad between number entry some other mode (often directional arrows). */
	NumLock = 'NumLock',

	/** The Scroll Lock key. Toggles beteen scrolling and cursor movement modes. */
	ScrollLock = 'ScrollLock',

	/** The Shift key. Modifies keystrokes to allow typing upper (or other) case letters, and to support typing punctuation and other special characters. */
	Shift = 'Shift',

	/** The Enter or ↵ key (sometimes labeled Return). */
	Enter = 'Enter',

	/** The Horizontal Tab key, Tab. */
	Tab = 'Tab',

	/** The down arrow key. */
	ArrowDown = 'ArrowDown',

	/** The left arrow key. */
	ArrowLeft = 'ArrowLeft',

	/** The right arrow key. */
	ArrowRight = 'ArrowRight',

	/** The up arrow key. */
	ArrowUp = 'ArrowUp',

	/** The End key. Moves to the end of content. */
	End = 'End',

	/** The Home key. Moves to the start of content. */
	Home = 'Home',

	/** The Page Down (or PgDn) key. Scrolls down or displays the next page of content. */
	PageDown = 'PageDown',

	/** The Page Up (or PgUp) key. Scrolls up or displays the previous page of content. */
	PageUp = 'PageUp',

	/** The Backspace key. This key is labeled Delete on Mac keyboards. */
	Backspace = 'Backspace',

	/** The Esc (Escape) key. Typically used as an exit, cancel, or "escape this operation" button. Historically, the Escape character was used to signal the start of a special control sequence of characters called an "escape sequence." */
	Escape = 'Escape',

	/** The first general-purpose function key, F1. */
	F1 = 'F1',

	/** The F2 key. */
	F2 = 'F2',

	/** The F3 key. */
	F3 = 'F3',

	/** The F4 key. */
	F4 = 'F4',

	/** The F5 key. */
	F5 = 'F5',

	/** The F6 key. */
	F6 = 'F6',

	/** The F7 key. */
	F7 = 'F7',

	/** The F8 key. */
	F8 = 'F8',

	/** The F9 key. */
	F9 = 'F9',

	/** The F10 key. */
	F10 = 'F10',

	/** The F11 key. */
	F11 = 'F11',

	/** The F12 key. */
	F12 = 'F12',

	/** The numeric keypad's multiplication key, *. */
	Multiply = 'Multiply',

	/** The numeric keypad's addition key, +. */
	Add = 'Add',

	/** The numeric keypad's division key, /. */
	Divide = 'Divide',

	/** The numeric keypad's subtraction key, -. */
	Subtract = 'Subtract',

	/** The numeric keypad's places separator character (in the United States, this is a comma, but elsewhere it is frequently a period). */
	Separator = 'Separator',

	/** Space key. */
	Space = 'Space',

	b = 'b',
	i = 'i',
	u = 'u',
	d = 'd',
	k = 'k',
	c = 'c',
	v = 'v',
	x = 'x',
	a = 'a'
}

export const INTERVAL_TYPES = {
	WEEKLY: 'Weekly',
	MONTHLY: 'Monthly',
	ANNUALLY: 'Annually'
};

export enum FilterAction {
	PreRenderFilters = 'preRenderFilters',
	ManipulateUrl = 'manipulateUrl'
}

export enum LogLevel {
	Info = 'info',
	Warning = 'warning',
	Error = 'error'
}

export enum InspectionColor {
	DarkRed = '#cb1214',
	DarkOrange = '#ee7203',
	Orange = '#ff9800',
	Grey = '#9d9d9c',
	SoftBlue = '#2a94b5',
	Blue = '#1e88e5',
	SoftGreen = '#81cb12',
	Green = '#61b232'
}

export enum Culture {
	sv = 'sv',
	en = 'en',
	no = 'no'
}

export class EventListener {
	type: Event;
	listener: EventListenerOrEventListenerObject;
	target: EventTarget;

	constructor(type: Event, listener: EventListenerOrEventListenerObject, target: EventTarget) {
		this.type = type;
		this.listener = listener;
		this.target = target;
	}
}

export const coordinateSystems = {
	'+proj=tmerc +lat_0=0 +lon_0=18.05779 +k=0.99999425 +x_0=100178.1808 +y_0=-6500614.7836 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'ST74',
	'SWEREF99': {
		'+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 TM',
		'+proj=tmerc +lat_0=0 +lon_0=22.5563333333333 +k=1.0000049 +x_0=1500121.846 +y_0=-672.557 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 / RT90 5 gon O emulation',
		'+proj=tmerc +lat_0=0 +lon_0=23.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 23 15',
		'+proj=tmerc +lat_0=0 +lon_0=11.30625 +k=1.000006 +x_0=1500025.141 +y_0=-667.282 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 / RT90 7.5 gon V emulation',
		'+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 12 00',
		'+proj=tmerc +lat_0=0 +lon_0=13.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 13 30',
		'+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 15 00',
		'+proj=tmerc +lat_0=0 +lon_0=16.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 16 30',
		'+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 18 00',
		'+proj=tmerc +lat_0=0 +lon_0=18.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 18 45',
		'+proj=tmerc +lat_0=0 +lon_0=14.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 14 15',
		'+proj=tmerc +lat_0=0 +lon_0=20.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 20 15',
		'+proj=tmerc +lat_0=0 +lon_0=18.0563 +k=1.0000054 +x_0=1500083.521 +y_0=-668.844 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 / RT90 0 gon emulation',
		'+proj=tmerc +lat_0=0 +lon_0=13.5562666666667 +k=1.0000058 +x_0=1500044.695 +y_0=-667.13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 / RT90 5 gon V emulation'
	},
	'RT90': {
		'+proj=longlat +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +no_defs +type=crs': 'RT90',
		'+proj=tmerc +lat_0=0 +lon_0=22.5582777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +units=m +no_defs +type=crs': 'RT90 5 gon O',
		'+proj=tmerc +lat_0=0 +lon_0=22.5563333333333 +k=1.0000049 +x_0=1500121.846 +y_0=-672.557 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 / RT90 5 gon O emulation',
		'+proj=tmerc +lat_0=0 +lon_0=11.3082777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +units=m +no_defs +type=crs': 'RT90 7.5 gon V',
		'+proj=tmerc +lat_0=0 +lon_0=11.30625 +k=1.000006 +x_0=1500025.141 +y_0=-667.282 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 / RT90 7.5 gon V emulation',
		'+proj=tmerc +lat_0=0 +lon_0=13.5582777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +units=m +no_defs +type=crs': 'RT90 5 gon V',
		'+proj=tmerc +lat_0=0 +lon_0=15.8082777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +units=m +no_defs +type=crs': 'RT90 2.5 gon V',
		'+proj=tmerc +lat_0=0 +lon_0=18.0582777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +units=m +no_defs +type=crs': 'RT90 0 gon',
		'+proj=tmerc +lat_0=0 +lon_0=20.3082777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,0.855,-2.141,7.023,0 +units=m +no_defs +type=crs': 'RT90 2.5 gon O',
		'+proj=tmerc +lat_0=0 +lon_0=18.0563 +k=1.0000054 +x_0=1500083.521 +y_0=-668.844 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 / RT90 0 gon emulation',
		'+proj=tmerc +lat_0=0 +lon_0=13.5562666666667 +k=1.0000058 +x_0=1500044.695 +y_0=-667.13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs': 'SWEREF99 / RT90 5 gon V emulation'
	}
};

/**
 * Reusable helpers to parse different kinds of data.
 */
export class Utils {
	private static _eventListeners: EventListener[] = [];

	/**
	 * Logs a message to the console if the site is not running in
	 * production mode or the current user is a SuperAdmin
	 * @param message Message to log
	 * @param user The currently logged in user
	 * @param level Level of the message
	 */
	public static logMessage(message: string, user: LoggedInUser = null, level: LogLevel = LogLevel.Info) {
		if (!environment.production || (user !== null && user.isSuperAdmin())) {
			switch (level) {
				case LogLevel.Info:
					console.log(message);
					break;

				case LogLevel.Warning:
					console.warn(message);
					break;

				case LogLevel.Error:
					console.error(message);
					break;

				default:
					console.log(message);
					break;
			}
		}
	}

	/**
	 * Determine if an array contains one or more items from another
	 * array, only for simple arrays containing primitive types.
	 * @param haystack The array to search
	 * @param array The array providing items to check for in the haystack.
	 * @return {boolean} True|False if haystack contains at least one item from arr
	 */
	public static findOne(haystack: Array<any>, array: Array<any>, caseSensitive: boolean = false): boolean {
		if (!haystack || !array) {
			return false;
		}

		return array.some((item) =>
			haystack.some((hay) => {
				if (!caseSensitive) {
					if ((typeof hay === 'string' || hay instanceof String) &&
						(typeof item === typeof hay)) {
						return hay.toLowerCase() === item.toLowerCase();
					}
				}
				return hay === item;
			})
		);
	}

	/**
	 * Inserts a node after another
	 * @param newNode the new node
	 * @param referenceNode the node to be inserted after
	 */
	public static insertAfter(newNode, referenceNode) {
		referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
	}

	/**
	 * Logging error if the environment supports it
	 * @param messages
	 */
	public static logError(message?: any, ...optionalParams: any[]) {
		if (!environment.production) {
			if (optionalParams.length) {
				console.error(message, optionalParams);
			} else {
				console.error(message);
			}
		}
	}

	/**
	 * Logging warning if the environment supports it
	 * @param messages
	 */
	public static logWarning(message?: any, ...optionalParams: any[]) {
		if (!environment.production) {
			if (optionalParams.length) {
				console.warn(message, optionalParams);
			} else {
				console.warn(message);
			}
		}
	}

	/**
	 * Parse a date.
	 * @external Depends on MomentJS
	 */
	public static parseDate(value: string, format: string): Date {
		const date = moment(value, format);

		if (!date.isValid()) {
			throw new Error(`Could not parse a date string "${value}"!`);
		}

		return date.toDate();
	}

	public static parseDateOfBirth(value: string, format: string): string {
		if (typeof value === 'string') {
			const dobDate = moment(value, format);

			if (dobDate.isValid()) {
				return dobDate.format(format);
			}
		}

		return null;
	}

	public static formatDate(value: any, format = DEFAULT_DATE_TIME_FORMAT): string {
		let valueString = value;
		if (typeof valueString !== 'string') {
			const valueDate = new Date(value);

			// Check if valid Date
			if (isNaN(valueDate.getTime())) {
				return '';
			}
			valueString = valueDate.toISOString();
		}

		const date = moment(valueString);
		if (date.isValid()) {
			return date.format(format).toString();
		}

		return '';
	}

	public static uppercaseEachFirst(str) {
		if (typeof str === 'undefined') {
			return str;
		}

		return str.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}

	/**
	 * Binds to keyup events and evaluates if it's the Enter key.
	 * @param Event, key up event.
	 */
	public static wasProcessed(event: any) {
		return event.key !== undefined &&
			event.key === StringUtils.ENTER;
	}

	/**
	 * Binds to keyup events and evaluates if it's the Escape key.
	 * @param Event, key up event.
	 */
	public static wasCancelled(event: any) {
		return event.key !== undefined &&
			event.key === StringUtils.ESCAPE;
	}

	/**
	 * Binds to keyup events and evaluates if it should blur a field.
	 * @param Event, key up event.
	 */
	public static shouldBlur(event: any) {
		return this.wasProcessed(event) ||
			this.wasCancelled(event);
	}

	// Modify a string escaping all regexp characters.
	// This way when a RegExp instance is created based on user-input,
	// it does not crash the app, should it contain special symbols.
	// Taken from: http://stackoverflow.com/a/3561711/186787
	public static escapeRegExp(str: string) {
		return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
	}

	// Returns the viewport with and height as an object
	public static getViewportSize(): any {
		const win = window,
			d = document,
			e = d.documentElement,
			g = d.getElementsByTagName('body')[0],
			w = win.innerWidth || e.clientWidth || g.clientWidth,
			h = win.innerHeight || e.clientHeight || g.clientHeight;

		return { width: w, height: h };
	}

	// Get base URL for a page
	public static getBaseUrl(url: string) {
		// If url beginswith /v2, remove it
		// url = url.indexOf('/v2') ? url.replace('/v2', '') : url;

		// If url has any query params, remove then
		const hasParams = url.indexOf('?');
		url = url.substring(0, hasParams !== -1 ? hasParams : url.length);
		return url;
	}

	// Returns empty promise
	public static emptyPromise(val = null) {
		return new Promise<any>((resolve) => { resolve(val); });
	}

	public static sortNumberArray(array: number[]) {
		return array.sort(function (a, b) {
			if (a < b) {
				return -1;
			}
			if (a > b) {
				return 1;
			}
			return 0;
		});
	}

	/**
	 * Finds the closest number in an array by given number
	 * @param number Number to evaluate against array
	 * @param array Array to evaualte number against
	 */
	public static closestNumber(number: number, array: number[]) {
		let curr = array[0];
		let diff = Math.abs(number - curr);
		for (let val = 0; val < array.length; val++) {
			const newdiff = Math.abs(number - array[val]);
			if (newdiff < diff) {
				diff = newdiff;
				curr = array[val];
			}
		}
		return curr;
	}

	public static nameof<T>(key: keyof T, instance?: T): keyof T {
		return key;
	}

	public static groupBy(array, key) {
		return array.reduce((result, currentValue) => {
			// If an array already present for key, push it to the array. Else create an array and push the object
			(result[currentValue[key]] = result[currentValue[key]] || []).push(
				currentValue
			);
			// Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
			return result;
		}, {}); // empty object is the initial value for result object
	}

	public static groupdByPropertyNavigation(array, property) {
		const hash = {},
			props = property.split('.');

		for (var i = 0; i < array.length; i++) {
			const key = props.reduce((acc, prop) => acc && acc[prop], array[i]);

			if (!hash[key]) {
				hash[key] = [];
			}

			hash[key].push(array[i]);
		}
		return hash;
	}

	/**
	 * Produce an array that contains every item shared between all the passed-in array.
	 */
	public static intersection(array1: number[], array2: number[]) {
		let idx1 = 0;
		let idx2 = 0;
		const result: number[] = [];

		// Sort both arrays first -- will compare values to find the intersection.
		array1 = Utils.sortNumberArray(array1);
		array2 = Utils.sortNumberArray(array2);

		while (idx1 < array1.length && idx2 < array2.length) {
			if (array1[idx1] < array2[idx2]) {
				idx1++;
			} else if (array1[idx1] > array2[idx2]) {
				idx2++;
			} else {
				result.push(array1[idx1]);
				idx1++;
				idx2++;
			}
		}

		return result;
	}

	/**
	 * Compares two arrays of any type.
	 * @param array1
	 * @param array2
	 * @param comparisonFn -- an optional function used to compare each value in both arrays.
	 * 	If not provided, uses the standard indexOf function.
	 * @returns true/false
	 */
	public static arraysEqual(array1: any[], array2: any[], comparisonFn?: (arr: any[], item: any) => boolean) {
		if (array1.length !== array2.length) {
			return false;
		}

		if (typeof comparisonFn !== 'function') {
			comparisonFn = (array, item) => {
				return array.indexOf(item) !== -1;
			};
		}

		return array1.filter(function (item) {
			return comparisonFn(array2, item);
		}).length === array2.length;
	}

	/**
	 * Return a version of one array that does not contain another array.
	 */
	public static difference(array1: number[], array2: number[]) {
		return array1.filter(el => {
			return array2.indexOf(el) === -1;
		});
	}

	/**
	 * Deletes all properties from a given object, filters from another object if given.
	 */
	public static emptyObject(obj: {}, excludeObj: {} = null) {
		for (const prop in obj) {
			if (obj.hasOwnProperty(prop)
				&& (excludeObj === null || !excludeObj.hasOwnProperty(prop))) {
				delete obj[prop];
			}
		}
	}

	/**
	* Deletes all properties from a given object.
	*/
	public static convertObjectToArray(objectLiteral: {}) {
		const piece1 = Object.keys(objectLiteral);
		const piece2 = Object.values(objectLiteral);
		const result = [];
		for (let i = 0; i < piece1.length; i++) {
			result.push([piece1[i], piece2[i]]);
		}
		return result;
	}


	/**
	 * Places an array-item last in an array
	 */
	public static placeLast(obj: {}, collection: any[]) {
		const index = collection.indexOf(obj);
		if (index !== -1) {
			collection.splice(index, 1);
			collection.push(obj);
		}
	}

	/**
	 * Convert string list to an checkbox object
	 */
	public static convertToCheckboxObject(selectedCollection: string[], collection: any) {
		const checkboxObject = {};
		if (collection.length) {
			collection.forEach(item => {
				const index = selectedCollection.indexOf(item.id.toLowerCase());
				checkboxObject[item.id] = index !== -1 ? true : false;
			});
			return checkboxObject;
		} else {
			return selectedCollection;
		}
	}

	/**
	 * Checks if string is a json object
	 */
	public static isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	/**
	 * Returns an array of string values from a desired list of properties of an object.
	 */
	public static getPropertyValuesAsArray(properties: any[], object: any) {
		return Object.keys(object).filter(key => {
			return properties.indexOf(key) !== -1;
		}).map(key => {
			if (object[key]) {
				return object[key].toString();
			} else {
				return '';
			}
		}).sort();
	}

	/**
	 * Transforms an object to all lower case properties
	 * @param obj The object containing the properties
	 */
	public static lowerCaseProps(obj: object): object {
		if (!obj) {
			return;
		}

		let key;
		const keys = Object.keys(obj);
		let n = keys.length;
		const newobj = {};
		while (n--) {
			key = keys[n];
			newobj[key.toLowerCase()] = obj[key];
		}
		return newobj;
	}

	/**
	 * Returns the result from comparing two arrays or two objects.
	 */
	public static isEqual(first: any, second: any) {
		return isEqual(first, second);
	}

	public static findDuplicates(array: any[]) {
		let sortedArray = array.slice().sort();

		let results = [];
		for (let i = 0; i < sortedArray.length - 1; i++) {
			if (sortedArray[i + 1] == sortedArray[i]) {
				results.push(sortedArray[i]);
			}
		}
		return results;
	}

	/**
	 * Converts a BufferArray (UTF8Array) to a string
	 * @param data string
	 */
	public static stringFromUTF8Array(data: any) {
		const extraByteMap = [1, 1, 1, 1, 2, 2, 3, 0];
		const count = data.length;
		let stringOutput: string = '';

		for (let index = 0; index < count;) {
			let ch = data[index++];
			// tslint:disable-next-line:no-bitwise
			if (ch & 0x80) {
				// tslint:disable-next-line:no-bitwise
				let extra = extraByteMap[(ch >> 3) & 0x07];
				// tslint:disable-next-line:no-bitwise
				if (!(ch & 0x40) || !extra || ((index + extra) > count)) {
					return null;
				}

				// tslint:disable-next-line:no-bitwise
				ch = ch & (0x3F >> extra);
				for (; extra > 0; extra -= 1) {
					const chx = data[index++];
					// tslint:disable-next-line:no-bitwise
					if ((chx & 0xC0) !== 0x80) {
						return null;
					}

					// tslint:disable-next-line:no-bitwise
					ch = (ch << 6) | (chx & 0x3F);
				}
			}

			stringOutput += String.fromCharCode(ch);
		}

		return stringOutput;
	}

	public static setLockedColumnType(column: any) {
		column.type = 'locked-column';
	}

	// Compares two date strings and returns true if the first is after the second.
	public static dateIsAfter(dateString: string, dateStringToCompareWith: string) {
		return dateString && dateStringToCompareWith
			&& Date.parse(dateString) > Date.parse(dateStringToCompareWith);
	}

	public static isFutureDate(date: string | Date): boolean {
		return moment(date).isAfter();
	}

	public static convertToHumanDate(date: string): string {
		return moment(date).fromNow();
	}

	public static isTodayDate(date: string): boolean {
		return moment(date).isSame(moment(), 'day');
	}

	public static safe<T>(func: () => T, backup: T): T {
		try {
			return func();
		} catch (e) {
			return backup;
		}
	}

	public static capitalizeFirstLetter(value: string) {
		if (typeof value === 'string') {
			return value.charAt(0).toUpperCase() + value.slice(1);
		} else {
			return '';
		}
	}

	public static uncapitalizeFirstLetter(value: string) {
		if (typeof value === 'string') {
			return value.charAt(0).toLowerCase() + value.slice(1);
		} else {
			return '';
		}
	}

	public static getComponentTemplate(component: any): string {
		return component['__annotations__'][0]['template'];
	}

	public static getComponentStyles(component: any): string {
		return component['__annotations__'][0]['styles'][0].toString();
	}

	// Replaces an array while keeping its reference.
	public static replaceArray(oldArr: any[], newArr: any[]) {
		oldArr.splice(0, oldArr.length, ...newArr);
	}

	/**
	 * Returns the proper transitionend event for the current browser
	 */
	public static getTransitionEndEventName(): string {
		const transitions = {
			'transition': 'transitionend',
			'OTransition': 'oTransitionEnd',
			'MozTransition': 'transitionend',
			'WebkitTransition': 'webkitTransitionEnd'
		};

		const bodyStyle = document.body.style;

		for (const transition in transitions) {
			if (bodyStyle[transition] !== undefined) {
				return transitions[transition];
			}
		}
	}

	/**
	 * Returns the proper animationend event for the current browser
	 */
	public static getAnimationEndEventName(): string {
		const animations = {
			"animation": "animationend",
			"OAnimation": "oAnimationEnd",
			"MozAnimation": "animationend",
			"WebkitAnimation": "webkitAnimationEnd"
		}

		const bodyStyle = document.body.style;

		for (const animation in animations) {
			if (bodyStyle[animation] !== undefined) {
				return animations[animation];
			}
		}
	}

	/**
	 * Removes all EventListeners added to the _eventListeners array
	 */
	public static removeEventListeners() {
		for (const event of this._eventListeners) {
			event.target.removeEventListener(event.type.type, event.listener, true);
			event.target.removeEventListener(event.type.type, event.listener, false);
		}

		this._eventListeners = [];
	}

	// Generic collapse content function. As long as there is a div with class name collapse-wrapper which has
	// a div with class collapsed, this function can be used to animate the collapse and expand.
	/**
	 * Generic collapse content function. As long as there is a div with class name collapse-wrapper which has
	 * a div with class collapsed, this function can be used to animate the collapse and expand.
	 * @param name Name attribute of element
	 * @param animate Indicates if an animation should be done or a recalculation of collapsed elements
	 */
	public static collapseElement(name: string, animate = true) {
		const target = this.findCollapsable(name);
		if (!target) {
			return;
		}

		// TSLint complains about target.offsetHeight. But this is needed for the animation to work properly. What it does is it lets the dom
		// recalculate the height including padding. (https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetHeight)
		// tslint:disable-next-line: no-unused-expression
		target.offsetHeight;

		// Listen for animation end events depending on browser usage
		// and set target as collapsed when closed
		const transitionEndEventName = this.getTransitionEndEventName();
		if (!target['eventHandler']) {
			target['eventHandler'] = () => {
				if (target.style.marginTop === `-${target.clientHeight}px`
					&& !target.classList.contains('collapsed')) {
					target.classList.add('collapsed');
					target.classList.remove('expanded');
				}
			};
			target.addEventListener(transitionEndEventName, target['eventHandler']);
			this._eventListeners.push(new EventListener(new Event(transitionEndEventName), target['eventHandler'], target));
		}

		// If no animation should be ran, a recalculation of the top margin is done based
		// on current size, used when initializing sections and on resize
		if (!animate) {
			if (target.classList.contains('collapsed')) {
				requestAnimationFrame(() => target.style.marginTop = `-${target.clientHeight}px`);
			}
			return;
		}

		if (target.classList.contains('collapsed')) {
			target.classList.remove('collapsed');
			target.classList.add('expanded');
			requestAnimationFrame(() => target.style.marginTop = '0px');
		} else {
			requestAnimationFrame(() => target.style.marginTop = `-${target.clientHeight}px`);
		}
	}

	// Check if the collapsable element is open (expanded) or not
	public static isExpanded(name: string): boolean {
		const target = this.findCollapsable(name);
		return target && !target.classList.contains('collapsed');
	}

	// Look for the child directly beneath the collapse-wrapper.
	public static findCollapsable(name: string) {
		const target = document.querySelector(`.collapse-wrapper > .collapsed[name="${name}"], .collapse-wrapper > .expanded[name="${name}"]`) as any;
		if (!target) {
			this.logMessage(`Cannot find ${name} as a direct descendent of a .collapse-wrapper`, null, LogLevel.Error);
			return null;
		} else {
			return target;
		}
	}

	/**
	 * This function checks the value from apx-inputs model change and returns the correct boolean.
	 * It is used for schedules, entities and facilities. It is called from the pipe function: status-is-active.
	 * @param status
	 */
	public static isActive(status: any) {
		if ((typeof status === 'boolean' && !status) || (typeof status === 'string' && status === 'false')) {
			return false;
		} else {
			return true;
		}
	}

	// Returns the number of unique items of any kind in any kind of iterable.
	public static getUniqueCount(iterable: any) {
		return new Set(iterable).size;
	}

	public static getDefaultRole(loggedInUser: LoggedInUser) {
		if (loggedInUser.isSuperAdmin()) {
			return 'Administrator';
		} else {
			return 'User';
		}
	}

	public static getRandomNumberString() {
		return Math.random().toString();
	}

	public static removeModalTabIndex() {
		// Fixes the issue with dropdowns closing when trying to scroll in them inside a modal.
		// Targeting the open modal only.
		const modalEl = document.body.querySelector('MODAL-CONTAINER');
		if (modalEl) {
			modalEl.removeAttribute('tabindex');
		}
	}

	// Can be called after opening a modal to make sure it will display on top.
	public static bringModalToFront() {
		const modalEls = document.body.getElementsByClassName('modal');

		let highest = 0;
		Array.from(modalEls).forEach(modal => {
			const zIndex = getComputedStyle(modal).zIndex;
			if (zIndex) {
				const parsedZIndex = parseInt(zIndex, 10);
				highest = parsedZIndex > highest ? parsedZIndex : highest;
			}
		});

		const modalEl = modalEls[modalEls.length - 1];
		if (modalEl) {
			(modalEl as HTMLElement).style.zIndex = (highest + 1).toString();
		}
	}

	public static getAllOrganizationsFromUser(user: User) {
		return new Array().concat(user.organization, user.organizationMemberships.map(membership => membership.organization));
	}

	public static getCapitalsFromText(text: string) {
		let capitals = '';
		for (let i = 0; i < text.length; i++) {
			if (text[i].match(/[A-Z]/)) {
				capitals += text[i];
			}
		}
		return capitals;
	}

	public static isExtraSmallScreenSize() {
		return window.innerWidth < NumberUtils.BOOTSTRAP_SM_MIN;
	}

	public static isSmallScreenSize() {
		return window.innerWidth < NumberUtils.BOOTSTRAP_MD_MIN;
	}

	public static isMediumScreenSize() {
		return window.innerWidth < NumberUtils.BOOTSTRAP_LG_MIN;
	}

	public static isLargeScreenSize() {
		return window.innerWidth >= NumberUtils.BOOTSTRAP_LG_MIN && window.innerWidth < NumberUtils.BOOTSTRAP_LG_LARGEX2_MIN;
	}

	public static isStandard(templateBaseType: TemplateBaseType) {
		return templateBaseType.name === 'Standard';
	}

	public static getUniqueEntriesOnly(...array: string[]) {
		return [...new Set(array)];
	}

	public static getAdjustedMedia(mediaItems: MediaWidgetItem[]) {
		return mediaItems.map(m => {
			const {
				actionsOpened, ...theRest
			} = m;

			return theRest as MediaWidgetItem;
		});
	}

	public static getDay(dateString: string) {
		return moment(dateString).format('D');
	}

	public static getMonthName(dateString: string) {
		return moment(dateString).format('MMMM');
	}

	public static getDayName(dateString: string) {
		return moment(dateString).format('dddd');
	}

	public static getYear(dateString: string) {
		return moment(dateString).format('YYYY');
	}

	public static getFormattedDateStringFromString(dateString: string, format: string) {
		return moment(dateString).format(format);
	}

	/**
	 * Code copied from https://github.com/fitzgen/glob-to-regexp/blob/master/index.js
	 */
	public static globToRegExp(glob: any, opts: any) {
		if (typeof glob !== 'string') {
			throw new TypeError('Expected a string');
		}

		var str = String(glob);

		// The regexp we are building, as a string.
		var reStr = "";

		// Whether we are matching so called "extended" globs (like bash) and should
		// support single character matching, matching ranges of characters, group
		// matching, etc.
		var extended = opts ? !!opts.extended : false;

		// When globstar is _false_ (default), '/foo/*' is translated a regexp like
		// '^\/foo\/.*$' which will match any string beginning with '/foo/'
		// When globstar is _true_, '/foo/*' is translated to regexp like
		// '^\/foo\/[^/]*$' which will match any string beginning with '/foo/' BUT
		// which does not have a '/' to the right of it.
		// E.g. with '/foo/*' these will match: '/foo/bar', '/foo/bar.txt' but
		// these will not '/foo/bar/baz', '/foo/bar/baz.txt'
		// Lastely, when globstar is _true_, '/foo/**' is equivelant to '/foo/*' when
		// globstar is _false_
		var globstar = opts ? !!opts.globstar : false;

		// If we are doing extended matching, this boolean is true when we are inside
		// a group (eg {*.html,*.js}), and false otherwise.
		var inGroup = false;

		// RegExp flags (eg "i" ) to pass in to RegExp constructor.
		var flags = opts && typeof (opts.flags) === "string" ? opts.flags : "";

		var c;
		for (var i = 0, len = str.length; i < len; i++) {
			c = str[i];

			switch (c) {
				case "/":
				case "$":
				case "^":
				case "+":
				case ".":
				case "(":
				case ")":
				case "=":
				case "!":
				case "|":
					reStr += "\\" + c;
					break;

				case "?":
					if (extended) {
						reStr += ".";
						break;
					}

				case "[":
				case "]":
					if (extended) {
						reStr += c;
						break;
					}

				case "{":
					if (extended) {
						inGroup = true;
						reStr += "(";
						break;
					}

				case "}":
					if (extended) {
						inGroup = false;
						reStr += ")";
						break;
					}

				case ",":
					if (inGroup) {
						reStr += "|";
						break;
					}
					reStr += "\\" + c;
					break;

				case "*":
					// Move over all consecutive "*"'s.
					// Also store the previous and next characters
					var prevChar = str[i - 1];
					var starCount = 1;
					while (str[i + 1] === "*") {
						starCount++;
						i++;
					}
					var nextChar = str[i + 1];

					if (!globstar) {
						// globstar is disabled, so treat any number of "*" as one
						reStr += ".*";
					} else {
						// globstar is enabled, so determine if this is a globstar segment
						var isGlobstar = starCount > 1                      // multiple "*"'s
							&& (prevChar === "/" || prevChar === undefined)   // from the start of the segment
							&& (nextChar === "/" || nextChar === undefined)   // to the end of the segment

						if (isGlobstar) {
							// it's a globstar, so match zero or more path segments
							reStr += "((?:[^/]*(?:\/|$))*)";
							i++; // move over the "/"
						} else {
							// it's not a globstar, so only match one path segment
							reStr += "([^/]*)";
						}
					}
					break;

				default:
					reStr += c;
			}
		}

		// When regexp 'g' flag is specified don't
		// constrain the regular expression with ^ & $
		if (!flags || !~flags.indexOf('g')) {
			reStr = "^" + reStr + "$";
		}

		return new RegExp(reStr, flags);
	};

	public static getToday(format: string) {
		return moment(new Date()).format(format);
	}

	public static convertObjectKeysToLowerCase(object: Object) {
		return Object.fromEntries(
			Object.entries(object)
				.map(([key, val]) => [key.toLowerCase(), val])
		);
	}

	public static debounce(func: Function, time: number) {
		let timer: NodeJS.Timeout;
		return (...args: any[]) => {
			clearTimeout(timer);
			timer = setTimeout(() => { func.apply(this, args); }, time);
		};
	}
}
