import { ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SimpleTableColumn } from './simple-table-column';

export class SimpleTableEventsColumn<T> extends SimpleTableColumn<T> {

	readonly getContent: (item: T) => EventsContent[];

	constructor(
		label: string,
		getContent: (item: T) => EventsContent[],
		sanitizer: DomSanitizer,
		viewContainerRef: ViewContainerRef,
		secondaryLabel: string) {
		super(
			label,
			sanitizer,
			viewContainerRef,
			secondaryLabel
		);
		this.getContent = getContent;
		this.maxWidth = '180px';
	}

	renderValue(item: T) {
		const content = this.getContent(item);

		const wrapper = document.createElement('div');
		wrapper.classList.add('text-wrapper');
		wrapper.style.display = 'flex';
		wrapper.style.flexDirection = 'column';
		wrapper.style.rowGap = '5px';

		if (content.filter(item => item).length > 2) {
			wrapper.style.rowGap = '0';
		}

		content.forEach((item) => {
			if (item) {
				const mainDiv = document.createElement('div');

				mainDiv.innerHTML = item.titleText;
				mainDiv.title = item.titleText;
				mainDiv.style.overflow = 'hidden';
				mainDiv.style.textOverflow = 'ellipsis';
				mainDiv.style.fontSize = '12px';

				wrapper.append(mainDiv);
			}
		});

		return this.sanitizer.bypassSecurityTrustHtml(wrapper.outerHTML);
	}
}

export class EventsContent {
	titleText: string;
	dateString: string;
	icon: string;

	constructor(
		titleText: string,
		dateString: string,
		icon: string) {
		this.titleText = titleText;
		this.dateString = dateString;
		this.icon = icon;
	}
}
