import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app-core/auth/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { UserGroup } from 'app-core/user-group/user-group';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { Router } from '@angular/router';

@Component({
	selector: 'change-details-tab',
	templateUrl: './change-details-tab.component.html'
})
export class ChangeDetailsTabComponent extends SimpleChangeTabDirective<UserGroup> implements OnInit {

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
			super(
				authService,
				modalService,
				router,
				toastrService,
				translationService
			);
	}

	ngOnInit() {
		this.form = this.formBuilder.group({
			[this.propertyStrings.name]: [this.changeModel.name,
				[Validators.required, Validators.maxLength(100)]
			]
		});
		super.ngOnInit();
	}
}
