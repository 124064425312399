import {
	Component
} from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SimpleDeleteModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-delete-modal.directive';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AssignmentService } from '../assignment.service';
import { Assignment } from '../assignment';

@Component({
	templateUrl: './delete-assignments.component.html'
})
export class DeleteAssignmentsComponent extends SimpleDeleteModalDirective<Assignment> {

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private assignmentService: AssignmentService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	protected async delete() {
		this.pending = true;
		try {
			const response = await this.assignmentService.deleteWithOrgId(this.deleteModelIds[0], this.selectedOrganization.id);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected async deleteRange() {
		this.pending = true;
		try {
			const response = await this.assignmentService.deleteRangeWithOrgId(this.deleteModelIds, this.selectedOrganization.id);
			this.handleSuccessResponse(response, this.translationService.instant('BackgroundJobStartedDeleteTasks'));

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}
}
