import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Schedule } from 'app-inspection/schedule/schedule';
import { ScheduleService } from 'app-inspection/schedule/schedule.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	templateUrl: './handle-schedule-groups-and-schedule-users.component.html'
})
export class HandleScheduleGroupsAndScheduleUsersComponent extends SimpleBulkEditModalDirective<Schedule> implements OnInit {

	isMeasure: boolean;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private scheduleService: ScheduleService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			const schedules = await this.scheduleService.getScheduleGroupsAndScheduleUsers(this.editModelIds, this.selectedOrganization.id);
			this.pending = false;

			const allScheduleGroups = schedules.flatMap(item => item.scheduleGroups);
			const allScheduleUsers = schedules.flatMap(item => item.scheduleUsers);
			const allUniqueScheduleGroups = [];
			const allUniqueScheduleUsers = [];

			const map = new Map();
			for (const item of allScheduleGroups) {
				if (!map.has(item.userGroup.id)) {
					map.set(item.userGroup.id, true);
					allUniqueScheduleGroups.push(item);
				}
			}
			for (const item of allScheduleUsers) {
				if (!map.has(item.user.id)) {
					map.set(item.user.id, true);
					allUniqueScheduleUsers.push(item);
				}
			}

			this.initialModel = new Schedule({
				scheduleGroups: allUniqueScheduleGroups,
				scheduleUsers: allUniqueScheduleUsers
			});

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	everythingIsValid() {
		return true;
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = await this.scheduleService.updateScheduleGroupsAndScheduleUsers(
				this.editModelIds,
				this.initialModel.scheduleGroups,
				this.initialModel.scheduleUsers
			);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() { }

	protected instantiateModel(item: Schedule) {
		return new Schedule(item);
	}

	hasUnsavedChanges() {
		return false;
	}
}
