<div class="extensive-image-wrapper" [ngClass]="{'loadmask': isLoading}">
	<div class="extensive-image-div" #mainDivEl></div>

	<div class="extensive-image-div-secondary" #secondaryDivEl></div>

	<dots-loader *ngIf="displayLoader && isLoading"></dots-loader>

	<img class="extensive-image-img" #imgEl
		(load)="setImage()"
		(error)="setErrorImage()"
		hidden
	/>
</div>
