<simple-crud-modal
	[titleText]="headerTitle"
	[closeText]="'Close' | translate"
	[submitText]="loggedInUser.canEdit(selectedOrganization.friendlyUrl) && !isArchivedScheduledRoute() && !isArchivedMeasureRoute() ? ('Update' | translate) : ''"
	[disableSubmit]="!everythingIsValid() || !hasUnsavedChanges()"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab
				[customClass]="'valid'"
				(selectTab)="setTabIndexInUrl(1)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-search'"
						[title]="'Overview' | translate"
						[helpText]="modifiedModel.isMeasureAssignment()
							? modifiedModel.isArchivedAssignment()
								? ('MeasureAssignmentArchivedViewDetailsHelpText' | translate)
								: ('MeasureAssignmentViewDetailsHelpText' | translate)
							: ('AssignmentViewDetailsHelpText' | translate)">
					</simple-tab-heading>
				</ng-template>
				<view-details-tab
					[viewModel]="modifiedModel"
					(onTabSelect)="goToTab($event)">
				</view-details-tab>
			</tab>
			<tab *ngIf="loggedInUser.canEdit(selectedOrganization.friendlyUrl) && !modifiedModel.isArchivedAssignment()"
				[customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(2)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-card-text'"
						[title]="'Details' | translate"
						[helpText]="modifiedModel.isMeasureAssignment() ? ('MeasureAssignmentChangeDetailsHelpText' | translate) : ('AssignmentChangeDetailsHelpText' | translate)">
					</simple-tab-heading>
				</ng-template>
				<change-details-tab
					[changeModel]="modifiedModel"
					[choice]="modifiedModel.choice">
				</change-details-tab>
			</tab>
			<tab #handleTab="tab" *ngIf="!modifiedModel.isArchivedAssignment()"
				[customClass]="'valid'"
				(selectTab)="setTabIndexInUrl(3)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-people'"
						[title]="'Groups' | translate"
						[helpText]="'AssignmentHandleGroupsHelpText' | translate"
						[displayCount]="modifiedModel.assigneeGroups.length">
					</simple-tab-heading>
				</ng-template>
				<handle-assignee-groups-tab
					[handleItems]="modifiedModel.assigneeGroups"
					[headerSubtitleText]="'AddedGroupsSubtitleAssignment' | translate"
					[readonlyListModal]="true"
					[isMeasure]="modifiedModel.isMeasureAssignment()"
					[hideHeaderAddButton]="modifiedModel.isArchivedAssignment() || !loggedInUser.canEdit(selectedOrganization.friendlyUrl)"
					[hideHeaderRemoveSelected]="modifiedModel.isArchivedAssignment() || !loggedInUser.canEdit(selectedOrganization.friendlyUrl)"
					[hideHeaderSelectAll]="modifiedModel.isArchivedAssignment() || !loggedInUser.canEdit(selectedOrganization.friendlyUrl)"
					[readonlyItems]="modifiedModel.isArchivedAssignment() || !loggedInUser.canEdit(selectedOrganization.friendlyUrl)"
					[wasSelected]="handleTab.active"
					[hideNotificationButton]="modifiedModel.isArchivedAssignment() || !loggedInUser.canEdit(selectedOrganization.friendlyUrl)"
					(onModelsChange)="modifiedModel.assigneeGroups = $event">
				</handle-assignee-groups-tab>
			</tab>
			<tab #handleTab2="tab" *ngIf="!modifiedModel.isArchivedAssignment()"
				[customClass]="'valid'"
				(selectTab)="setTabIndexInUrl(4)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-person'"
						[title]="'Assignees' | translate"
						[helpText]="'AssignmentHandleUsersHelpText' | translate"
						[displayCount]="modifiedModel.assignees.length">
					</simple-tab-heading>
				</ng-template>
				<handle-assignees-tab
					[handleItems]="modifiedModel.assignees"
					[headerSubtitleText]="'AddedUsersSubtitleAssignment' | translate"
					[selectedAssigneeGroups]="modifiedModel.assigneeGroups"
					[readonlyListModal]="true"
					[isMeasure]="modifiedModel.isMeasureAssignment()"
					[hideHeaderAddButton]="modifiedModel.isArchivedAssignment() || !loggedInUser.canEdit(selectedOrganization.friendlyUrl)"
					[hideHeaderRemoveSelected]="modifiedModel.isArchivedAssignment() || !loggedInUser.canEdit(selectedOrganization.friendlyUrl)"
					[hideHeaderSelectAll]="modifiedModel.isArchivedAssignment() || !loggedInUser.canEdit(selectedOrganization.friendlyUrl)"
					[readonlyItems]="modifiedModel.isArchivedAssignment() || !loggedInUser.canEdit(selectedOrganization.friendlyUrl)"
					[wasSelected]="handleTab2.active"
					[hideNotificationButton]="modifiedModel.isArchivedAssignment() || !loggedInUser.canEdit(selectedOrganization.friendlyUrl)"
					(onModelsChange)="modifiedModel.assignees = $event">
				</handle-assignees-tab>
			</tab>
		</tabset>
</simple-crud-modal>
