<simple-change-tab
	[form]="form">
		<div class="change-section">
			<div class="change-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-input
				[label]="'System' | translate"
				[name]="propertyStrings.system"
				[form]="form"
				[serverErrors]="serverErrors">
			</simple-form-input>
			<simple-form-select
				[label]="'Status' | translate"
				[name]="propertyStrings.active"
				[form]="form"
				[items]="selectableStatusItems"
				[redBackground]="!changeModel.active"
				[serverErrors]="serverErrors">
			</simple-form-select>
			<simple-form-input
				[label]="'ServiceAccountEmail' | translate"
				[name]="propertyStrings.serviceAccountEmail"
				[form]="form"
				[serverErrors]="serverErrors">
			</simple-form-input>
			<simple-form-input
				[label]="'Username' | translate"
				[name]="propertyStrings.username"
				[form]="form"
				[serverErrors]="serverErrors">
			</simple-form-input>
			<simple-form-input *ngIf="isEdit"
				[label]="'OldPassword' | translate"
				[name]="propertyStrings.password"
				[form]="form"
				[serverErrors]="serverErrors"
				[usePasswordType]="true">
			</simple-form-input>
			<simple-form-input
				[label]="'NewPassword' | translate"
				[name]="propertyStrings.newPassword"
				[form]="form"
				[serverErrors]="serverErrors"
				[usePasswordType]="true">
			</simple-form-input>
			<simple-form-input
				[label]="'ConfirmPassword' | translate"
				[name]="propertyStrings.confirmPassword"
				[form]="form"
				[serverErrors]="serverErrors"
				[usePasswordType]="true">
			</simple-form-input>
			<simple-form-select
				[label]="'ExternalEmailParenthesis' | translate"
				[name]="propertyStrings.emails"
				[form]="form"
				[items]="selectableEmailItems"
				[multiple]="true"
				[searchable]="true"
				[addable]="true"
				[serverErrors]="serverErrors">
			</simple-form-select>
		</div>
</simple-change-tab>
