<simple-crud-modal
	[titleText]="('Group' | translate) + (modifiedModel?.name ? ' ─ ' + modifiedModel?.name : '')"
	[closeText]="'Close' | translate"
	[submitText]="(isEdit ? 'Update' : 'Create') | translate"
	[disableSubmit]="!everythingIsValid() || !hasUnsavedChanges()"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab
				[customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(1)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-card-text'"
							[title]="'Details' | translate"
							[helpText]="isEdit ? ('UserGroupChangeDetailsEditHelpText' | translate) : ('UserGroupChangeDetailsHelpText' | translate)">
						</simple-tab-heading>
					</ng-template>
					<change-details-tab
						[changeModel]="modifiedModel">
					</change-details-tab>
			</tab>
			<tab #handleTab="tab"
				[customClass]="handleUsersIsValid() ? 'valid' : handleUsersHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(2)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-person'"
							[title]="'Assignees' | translate"
							[helpText]="isEdit ? ('UserGroupHandleUsersEditHelpText' | translate) : ('UserGroupHandleUsersHelpText' | translate)"
							[displayCount]="modifiedModel.users.length">
						</simple-tab-heading>
					</ng-template>
					<handle-users-tab
						[handleItems]="modifiedModel.users"
						[headerSubtitleText]="'AddedUsersSubtitleUserGroup' | translate"
						[wasSelected]="handleTab.active"
						(onModelsChange)="modifiedModel.users = $event">
					</handle-users-tab>
			</tab>
		</tabset>
</simple-crud-modal>
