import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdministrationComponent } from './administration/administration.component';
import { canActivateFn } from './auth/auth-guard/auth-guard.service';
import { CallbackComponent } from './auth/callback.component';
import { RenewTokenComponent } from './auth/renew-token.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ErrorComponent } from './error/error.component';
import { MyOrganizationComponent } from './organization/my-organization/my-organization.component';
import { RoutesUtils } from './shared-core/tools/routes-utils';
import { UrlUtils } from './shared-core/tools/url-utils';
import { ProfileComponent } from './user/my-profile/profile.component';

const routes: Routes = [

	// The initial route on every app start.
	// Redirect to our default route.
	{
		path: RoutesUtils.empty,
		pathMatch: 'full',
		redirectTo: UrlUtils.DEFAULT_REDIRECT
	},

	// All our regular routes, both with or without org prefix.
	// The authguard will always make sure an org prefix is validated and set.
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.assignments}`,
		loadChildren: () => import('../app-inspection/assignment/assignment.module').then(m => m.AssignmentModule)
	},
	{
		path: RoutesUtils.assignments,
		loadChildren: () => import('../app-inspection/assignment/assignment.module').then(m => m.AssignmentModule)
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.assignmentTemplates}`,
		loadChildren: () => import('../app-inspection/assignment-template/assignment-template.module').then(m => m.AssignmentTemplateModule)
	},
	{
		path: RoutesUtils.assignmentTemplates,
		loadChildren: () => import('../app-inspection/assignment-template/assignment-template.module').then(m => m.AssignmentTemplateModule)
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.schedules}`,
		loadChildren: () => import('../app-inspection/schedule/schedule.module').then(m => m.ScheduleModule)
	},
	{
		path: RoutesUtils.schedules,
		loadChildren: () => import('../app-inspection/schedule/schedule.module').then(m => m.ScheduleModule)
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.facilities}`,
		loadChildren: () => import('../app-inspection/facility/facility.module').then(m => m.FacilityModule)
	},
	{
		path: RoutesUtils.facilities,
		loadChildren: () => import('../app-inspection/facility/facility.module').then(m => m.FacilityModule)
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.entities}`,
		loadChildren: () => import('../app-inspection/entity/entity.module').then(m => m.EntityModule)
	},
	{
		path: RoutesUtils.entities,
		loadChildren: () => import('../app-inspection/entity/entity.module').then(m => m.EntityModule)
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.users}`,
		loadChildren: () => import('./user/user.module').then(m => m.UserModule)
	},
	{
		path: RoutesUtils.users,
		loadChildren: () => import('./user/user.module').then(m => m.UserModule)
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.userGroups}`,
		loadChildren: () => import('./user-group/user-group.module').then(m => m.UserGroupModule)
	},
	{
		path: RoutesUtils.userGroups,
		loadChildren: () => import('./user-group/user-group.module').then(m => m.UserGroupModule)
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.templateTypes}`,
		loadChildren: () => import('../app-inspection/template-type/template-type.module').then(m => m.TemplateTypeModule)
	},
	{
		path: RoutesUtils.templateTypes,
		loadChildren: () => import('../app-inspection/template-type/template-type.module').then(m => m.TemplateTypeModule)
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.tasks}`,
		loadChildren: () => import('../app-inspection/task/task.module').then(m => m.TaskModule)
	},
	{
		path: RoutesUtils.tasks,
		loadChildren: () => import('../app-inspection/task/task.module').then(m => m.TaskModule)
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.choices}`,
		loadChildren: () => import('../app-inspection/choice/choice.module').then(m => m.ChoiceModule)
	},
	{
		path: RoutesUtils.choices,
		loadChildren: () => import('../app-inspection/choice/choice.module').then(m => m.ChoiceModule)
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.organization}`,
		component: MyOrganizationComponent,
		canActivate: [canActivateFn]
	},
	{
		path: RoutesUtils.organization,
		component: MyOrganizationComponent,
		canActivate: [canActivateFn]
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.administration}`,
		component: AdministrationComponent,
		canActivate: [canActivateFn]
	},
	{
		path: RoutesUtils.administration,
		component: AdministrationComponent,
		canActivate: [canActivateFn]
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.profile}`,
		component: ProfileComponent,
		canActivate: [canActivateFn]
	},
	{
		path: RoutesUtils.profile,
		component: ProfileComponent,
		canActivate: [canActivateFn]
	},
	{
		path: `${RoutesUtils.orgParam}/${RoutesUtils.dashboard}`,
		component: DashboardComponent,
		canActivate: [canActivateFn]
	},
	{
		path: RoutesUtils.dashboard,
		component: DashboardComponent,
		canActivate: [canActivateFn]
	},

	// Invitation related routes.
	{
		path: RoutesUtils.invitation,
		loadChildren: () => import('./invitation/invitation.module').then(m => m.InvitationModule)
	},

	// Auth related routes.
	{
		path: RoutesUtils.callback,
		component: CallbackComponent,
	},
	{
		path: RoutesUtils.renewToken,
		component: RenewTokenComponent,
	},

	// When only an org prefix in the route.
	// Keep it and redirect to our default route.
	{
		path: RoutesUtils.orgParam,
		pathMatch: 'full',
		redirectTo: `${RoutesUtils.orgParam}/${UrlUtils.DEFAULT_REDIRECT}`
	},

	// When no route matches.
	// Show the page not found component.
	{
		path: RoutesUtils.notSpecified,
		component: ErrorComponent,
		canActivate: [canActivateFn],
		data: {
			title: 'PageNotFound',
			isInvalid: true
		}
	}
];

/**
 * All our application routes.
 */
@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})

export class AppRoutingModule { }
