import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { Choice } from 'app-inspection/choice/choice';
import { ListTaskComponent } from 'app-inspection/task/list-task/list-task.component';
import { Task } from 'app-inspection/task/task';
import { ChoicesComponent } from './choices/choices.component';

@Component({
	selector: 'handle-tasks-tab',
	templateUrl: './handle-tasks-tab.component.html',
	styleUrls: ['./handle-tasks-tab.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandleTasksTabComponent extends SimpleHandleTabDirective<Task> {

	@Input() readonlyChoices: boolean;
	@Input() includeChoicesInList: boolean;
	@Input() templateTypeIdForList: string;

	@Output() onUpdatedChoices = new EventEmitter<Choice[]>();
	@Output() onDeletedChoices = new EventEmitter<string[]>();

	@ViewChild(ChoicesComponent) choicesComponent: ChoicesComponent;

	toggleListTaskModal() {
		this.bsModalRef = this.modalService.show(
			ListTaskComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: this.readonlyListModal,
					hideTableHeaderActions: this.readonlyListModal,
					hideTableRowActions: this.readonlyListModal,
					idsToSetAsSelected: this.handleItems.map(item => item.id),
					includeChoices: this.includeChoicesInList,
					templateTypeId: this.templateTypeIdForList
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	itemListIsValid() {
		const alltasksHaveChoices = this.handleItems.length
			&& this.handleItems.every(task => !!task.choices.length);

		const allDefaultOrRequiredTasksHaveOneDefaultChoiceOnly = this.handleItems.filter(task => task.choices.length && (task.isDefault || task.isRequired))
			.every(task => task.choices.filter(choice => choice.isDefault).length === 1);

		const someTasksHasUnspecifiedChoicesOnly = this.handleItems.filter(task => task.choices.length)
			.some(task => task.choices.every(choice => choice.isUnspecified));

		return alltasksHaveChoices && allDefaultOrRequiredTasksHaveOneDefaultChoiceOnly && !someTasksHasUnspecifiedChoicesOnly;
	}

	inlineListHasUnsavedChanges() {
		return this.currentCrudModalComponent?.hasUnsavedChanges();
	}

	override handleUpdated(updatedItems: Task[]) {
		updatedItems.forEach(item => {
			const existingItem = this.handleItems.find(task => task.id === item.id);
			if (existingItem) {
				item.choices = existingItem.choices;
				Object.assign(existingItem, item);
				this.validate([existingItem]);
			}
		});
		this.emitModelsChange();
	}

	override emitModelsChange() {
		this.handleItems.sortByProperty(this.propertyStrings.text);
		super.emitModelsChange();
	}

	protected validate(tasks: Task[]) {
		tasks.forEach(task => {
			const hasChoices = task.choices.length;
			const requiredOrDefaultTaskHasNotONEDefaultChoice = (task.isRequired || task.isDefault) && task.choices.filter(choice => choice.isDefault).length !== 1;
			const taskHasUnspecifiedChoicesOnly = hasChoices && task.choices.every(choice => choice.isUnspecified);

			task.errorMessages = [];
			if (!hasChoices) {
				task.errorMessages.push('TheTaskNeedsAtLeastOneChoice');
			}
			if (requiredOrDefaultTaskHasNotONEDefaultChoice) {
				task.errorMessages.push('TheTaskNeedsONEDefaultChoice');
			}
			if (taskHasUnspecifiedChoicesOnly) {
				task.errorMessages.push('TheTaskMayNotHaveUnspecifiedChoicesOnly');
			}
		});
	}

	getChoicesGlobal() {
		let choicesGlobal: Choice[] = [];
		const selectedTasks = this.handleItems.filter(task => task.selected);
		if (selectedTasks.length) {
			if (this.areTaskChoicesTheSame(selectedTasks)) {
				selectedTasks.forEach(selectedTask => {
					choicesGlobal = [...choicesGlobal, ...selectedTask.choices];
				});
				const uniqueChoices = [];
				const map = new Map();
				for (const item of choicesGlobal) {
					if (!map.has(item.id)) {
						map.set(item.id, true);
						uniqueChoices.push(item);
					}
				}
				choicesGlobal = uniqueChoices;
			}
		}
		return choicesGlobal;
	}

	private areTaskChoicesTheSame(selectedTasks: Task[]) {
		if (selectedTasks.every(o => !o.choices.length)) {
			return false;
		}
		const sortingFunction = (a: any, b: any) => a.id === b.id ? 0 : a.id < b.id ? -1 : 1;
		const sortedChoices = selectedTasks[0].choices.slice().sort(sortingFunction);
		return selectedTasks.every(o =>
			o.choices.length === sortedChoices.length &&
			o.choices.slice().sort(sortingFunction)
				.every((c, i) => c.id === sortedChoices[i].id));
	}

	handleChoicesChange(task: Task, choices: Choice[]) {
		task.choices = choices;
		this.validate([task]);
		this.emitModelsChange();
	}

	handleChoicesChangeGlobal(choices: Choice[]) {
		const selectedTasks = this.handleItems.filter(task => task.selected);
		selectedTasks.forEach(task => task.choices = choices);
		this.validate(selectedTasks);
	}

	handleUpdatedChoices(updatedChoices: Choice[]) {
		const allChoices = this.handleItems.reduce((list, item) => [...list, ...item.choices], [] as Choice[]);
		allChoices.forEach(item => {
			const choiceToUpdateFrom = updatedChoices.find(choice => choice.id === item.id);
			if (choiceToUpdateFrom) {
				Object.assign(item, choiceToUpdateFrom);
			}
		});
		this.onUpdatedChoices.emit(updatedChoices);
	}

	handleDeletedChoices(deletedChoiceIds: string[]) {
		this.handleItems.forEach(item => {
			item.choices = item.choices.filter(choice => !deletedChoiceIds.includes(choice.id));
		});
		this.onDeletedChoices.emit(deletedChoiceIds);
	}

	getItems() {
		return this.searchValue
			? this.handleItems.filter(item => item.text.toLowerCase().includes(this.searchValue.toLowerCase())
				|| item.code.toLowerCase().includes(this.searchValue.toLowerCase()))
			: this.handleItems;
	}
}
