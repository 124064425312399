<div class="important-notification {{ currentMessage ? currentMessage.type : '' }}" *ngIf="notificationMessages.length"
	[ngClass]="{
		'visible': messageVisible,
		'opened': bsModalRef
	}"
	[title]="currentMessage ? currentMessage.title : ''"
	(click)="openNotificationMessageModal()"
	(mouseenter)="repeat ? handleMouseEnter() : null"
	(mouseleave)="repeat ? handleMouseLeave() : null">
		<div class="notification-message-icon {{ currentMessage ? currentMessage.icon : '' }}">
		</div>
		<div class="notification-message-text">
			<div class="title">
				{{ currentMessage ? currentMessage.title : '' }}
			</div>
			<div class="subtitle">
				{{ 'ClickToReadMore' | translate }}
			</div>
		</div>
		<div class="next-message bi-arrow-down-up" *ngIf="repeat"
			[title]="'NextMessage' | translate"
			(click)="handleNextMessageClick(); $event.stopImmediatePropagation()">
		</div>
</div>
