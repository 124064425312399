import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { RegexUtils } from 'app-core/shared-core/tools/regex-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DfResponsIntegration } from '../../../integration';

@Component({
	selector: 'change-df-respons-details-tab',
	templateUrl: './change-df-respons-details-tab.component.html'
})
export class ChangeDfResponsDetailsTabComponent extends SimpleChangeTabDirective<DfResponsIntegration> implements OnInit {

	selectableStatusItems: FormSelectItem[] = [];

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder,) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableStatusItems();

		this.form = this.formBuilder.group({
			[this.propertyStrings.email]: [this.changeModel.email,
			[Validators.required, this.emailValidator]
			],
			[this.propertyStrings.active]: [this.changeModel.active,
			Validators.required
			],
		});

		super.ngOnInit();
	}

	private emailValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.EMAIL).test(control.value)) {
			return { email: true };
		} else {
			return null;
		}
	}

	private setSelectableStatusItems() {
		this.selectableStatusItems = [
			new FormSelectItem(true, this.translationService.instant(StringUtils.ACTIVE)),
			new FormSelectItem(false, this.translationService.instant(StringUtils.INACTIVE)),
		];
	}
}
