<div class="view-section" *ngIf="errorResult"
	[ngClass]="{
		'left-margin': isMeasure
	}">
	<div class="view-section-header" *ngIf="!isMeasure">
		{{ 'Result' | translate }}
	</div>
	<div class="sub-header">
		{{ "Comment" | translate }}
	</div>
	<div class="text" *ngIf="errorResult.comment"
		[innerHTML]="errorResult.comment | lineBreaks">
	</div>
	<div class="dummy-text" *ngIf="!errorResult.comment">
		{{ 'NotAdded' | translate }}
	</div>
	<div class="title-wrapper">
		<div class="sub-header">
			{{ "Photos" | translate }}
		</div>
		<span class="file-counter" *ngIf="errorResult.images.length"
			(click)="downloadAll(errorResult.images)"
			title="{{'DownloadAll' | translate}}"
			placement="left">
			<span class="number">{{ errorResult.images.length | localeString }}</span>
			{{ (errorResult.images.length > 1 ? "Files" : "File") | translate }}
			<a class="bi bi-download"></a>
		</span>
	</div>
	<media-widget class="images" *ngIf="errorResult.images.length"
		[collection]="errorResult.images"
		[viewOnly]="true"
		[canToggleDefault]="false">
	</media-widget>
	<div class="dummy-text" *ngIf="!errorResult.images.length">
		{{ 'NotAdded' | translate }}
	</div>
</div>
<div class="view-section" *ngIf="resolvementResult"
	[ngClass]="{
		'left-margin': isMeasure
	}">
	<div class="view-section-header"
		[ngClass]="{
			'small-view-section-header': isMeasure
		}">
		{{ 'Resolvement' | translate }}
	</div>
	<div class="sub-header">
		{{ "Comment" | translate }}
	</div>
	<div class="text" *ngIf="resolvementResult.comment"
		[innerHTML]="resolvementResult.comment | lineBreaks">
	</div>
	<div class="dummy-text" *ngIf="!resolvementResult.comment">
		{{ 'NotAdded' | translate }}
	</div>
	<div class="title-wrapper">
		<div class="sub-header">
			{{ "Photos" | translate }}
		</div>
		<span class="file-counter" *ngIf="resolvementResult.images.length"
			(click)="downloadAll(resolvementResult.images)"
			title="{{'DownloadAll' | translate}}"
			placement="left">
			<span class="number">{{ resolvementResult.images.length | localeString }}</span>
			{{ (resolvementResult.images.length > 1 ? "Files" : "File") | translate }}
			<a class="bi bi-download"></a>
		</span>
	</div>
	<media-widget class="images" *ngIf="resolvementResult.images.length"
		[collection]="resolvementResult.images"
		[viewOnly]="true"
		[canToggleDefault]="false">
	</media-widget>
	<div class="dummy-text" *ngIf="!resolvementResult.images.length">
		{{ 'NotAdded' | translate }}
	</div>
</div>