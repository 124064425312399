export enum HostedHttpMethod {
	GET,
	PUT,
	POST,
	DELETE
}

export class HostedHttpRequest {
	endpoint: string;
	data: Object;
	params: {};
	headers: {};
	responseType: string;
	method: HostedHttpMethod;
	options: {};
	asObservable: boolean;
	useCoreApi: boolean;

	constructor(args: Partial<HostedHttpRequest>) {
		Object.assign(this, args);
	}

	merge(other: Partial<HostedHttpRequest>) {
		return Object.assign(new HostedHttpRequest(this), other);
	}
}
