import { NumberUtils } from '../../../tools/number-utils';
import { SimpleTableRow } from './body/simple-table-row';
import { SimpleTableRowAction } from './body/simple-table-row-action';
import { SimpleTableColumn } from './columns/simple-table-column';
import { SimpleTableEmptyState } from './empty-state/simple-table-empty-state';
import { SimpleTableHeaderAction } from './head/simple-table-header-action';

export class SimpleTableConfig<T extends { id: string }> {

	columns: SimpleTableColumn<T>[] = [];
	headerActions: SimpleTableHeaderAction[] = [];
	rowActions: SimpleTableRowAction<T>[] = [];

	hideHeaderActions: boolean;
	hideRowActions: boolean;
	isSingleSelect: boolean;

	standardRowHeight: number;
	compactRowHeight: number;
	compactSectionHeight: number;

	emptyState: SimpleTableEmptyState;

	readonly dummyRowCount = NumberUtils.TABLE_DUMMY_DATA_ROW_COUNT;

	setHideHeaderActions(value: boolean) {
		this.hideHeaderActions = value;
	}

	setHideRowActions(value: boolean) {
		this.hideRowActions = value;
	}

	setIsSingleSelect(value: boolean) {
		this.isSingleSelect = value;
	}

	setColumns(...columns: SimpleTableColumn<T>[]) {
		this.columns = columns.filter(column => column != null);
	}

	setHeaderActions(...headerActions: SimpleTableHeaderAction[]) {
		this.headerActions = headerActions;
	}

	setRowActions(...rowActions: SimpleTableRowAction<T>[]) {
		this.rowActions = rowActions;
	}

	getHeaderActions(): SimpleTableHeaderAction[] {
		return this.headerActions.filter(action => !action.hidden());
	}

	getRowActions(row: SimpleTableRow<T>): SimpleTableRowAction<T>[] {
		return this.rowActions.filter(action => !action.hidden(row));
	}
}
