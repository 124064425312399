<ng-container *ngIf="userGroups.length">
	<div class="item-header">
		{{ 'ConnectedGroups' | translate }}
	</div>
	<div class="item" *ngFor="let group of userGroups">
		<simple-link-button
			[url]="group.url"
			[name]="group.name"
			[linkText]="group.name"
			[shouldDisable]="disabled">
		</simple-link-button>
	</div>
</ng-container>
<ng-container *ngIf="assignmentTemplates.length">
	<div class="item-header">
		{{ 'ConnectedAssignmentTemplates' | translate }}
	</div>
	<div class="item" *ngFor="let assignmentTemplate of assignmentTemplates">
		<simple-link-button
			[url]="assignmentTemplate.url"
			[name]="assignmentTemplate.name"
			[linkText]="assignmentTemplate.name"
			[shouldDisable]="disabled">
		</simple-link-button>
	</div>
</ng-container>
<ng-container *ngIf="schedules.length">
	<div class="item-header"
		[ngClass]="{
			'with-margin-top': userGroups.length || assignmentTemplates.length
		}">
			{{ 'ConnectedSchedules' | translate }}
	</div>
	<div class="item" *ngFor="let schedule of schedules">
		<simple-link-button
			[url]="schedule.url"
			[name]="schedule.name"
			[linkText]="schedule.name"
			[shouldDisable]="disabled">
		</simple-link-button>
		<span class="dots" *ngIf="schedule.canPerformScheduled || schedule.canPerformMeasure || schedule.canAssign"></span>
		<div class="icons">
			<i class="ii-assignment green" *ngIf="schedule.canPerformScheduled"
				[title]="'CanPerformScheduled' | translate">
			</i>
			<i class="ii-measure red" *ngIf="schedule.canPerformMeasure"
				[title]="'CanPerformMeasure' | translate">
			</i>
			<i class="bi-people-fill blue" *ngIf="schedule.canAssign"
				[title]="'CanAssign' | translate">
			</i>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="facility">
	<div class="item-header">
		{{ 'ConnectedFacility' | translate }}
	</div>
	<div class="item">
		<simple-link-button
			[url]="facility.url"
			[name]="facility.name"
			[linkText]="facility.name"
			[shouldDisable]="disabled">
		</simple-link-button>
	</div>
</ng-container>
