import { Directive } from '@angular/core';
import { SimpleCreateEditModalDirective } from './simple-create-edit-modal.directive';

@Directive()
export abstract class SimpleBulkEditModalDirective<T extends { id: string }> extends SimpleCreateEditModalDirective<T> {

	editModelIds: string[] = [];
	affectableIds: string[] = [];
	useAffectableIds: boolean = false;

	getSelectedCountText(text: string) {
		const count = this.useAffectableIds ? this.affectableIds.length : this.editModelIds.length;
		return `${text} (${count})`;
	}

	override submit() {
		if (this.everythingIsValid() && this.affectableIdsAreValid()) {
			this.serverErrors = [];
			this.createOrUpdate();
		} else {
			this.triggerAllValidation();
		}
	}

	affectableIdsAreValid() {
		return !this.useAffectableIds || !!this.affectableIds.length;
	}

	override emit(items: T[]) {
		this.updated$.next(items);
	}
}
