import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent, NgSelectConfig } from '@ng-select/ng-select';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { SimpleFormControlDirective } from '../simple-form-control.directive';

@Component({
	selector: 'simple-form-select',
	templateUrl: './simple-form-select.component.html',
	styleUrls: ['./simple-form-select.component.less']
})
export class SimpleFormSelectComponent extends SimpleFormControlDirective {

	@Input() items: FormSelectItem[];
	@Input() multiple: boolean;
	@Input() searchable: boolean;
	@Input() addable: boolean;
	@Input() redBackground: boolean;
	@Input() sortOnAdd: boolean;
	@Input() useStrictSearch: boolean;

	@Output() onAddNewItem = new EventEmitter<FormSelectItem>();

	@ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

	constructor(
		config: NgSelectConfig,
		translationService: TranslationService) {
		super();
		config.clearAllText = translationService.instant('Clear');
		config.notFoundText = translationService.instant('NoDataWasFound');
		config.addTagText = translationService.instant('Add');
	}

	addTagFn(name: string) {
		const newItem = new FormSelectItem(name, name);
		newItem.newlyAdded = true;
		return newItem;
	}

	handleAdd(item: FormSelectItem) {
		if (item.newlyAdded) {
			this.onAddNewItem.emit(item);
		}
		if (this.sortOnAdd) {
			function compare(a: NgOption, b: NgOption) {
				if (a.index < b.index) {
					return -1;
				}
				if (a.index > b.index) {
					return 1;
				}
				return 0;
			}
			this.ngSelectComponent.selectedItems.sort(compare);
		}
	}

	searchFnStrict(term: string, item: { name: string }) {
		return item.name.includes(term);
	}

	searchFnLoose(term: string, item: { name: string }) {
		return item.name.toLowerCase().includes(term.toLowerCase());
	}
}

export class FormSelectItem {
	id: any;
	name: string;
	newlyAdded: boolean;

	constructor(
		id: any,
		name: string) {
		this.id = id;
		this.name = name;
	}
}
