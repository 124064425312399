import { NgModule } from '@angular/core';
import { MediaModule } from 'app-core/media/media-module';
import { SharedModule } from 'app-core/shared-core/shared.module';
import { TemplateTypeModule } from 'app-inspection/template-type/template-type.module';
import { CreateEditUserGroupComponent } from './create-edit-usergroup/create-edit-user-group.component';
import { ChangeDetailsTabComponent } from './create-edit-usergroup/tabs/change-details/change-details-tab.component';
import { HandleUsersTabComponent } from './create-edit-usergroup/tabs/handle-users/handle-users-tab.component';
import { DeleteUserGroupsComponent } from './delete-user-groups/delete-user-groups.component';
import { ListUserGroupComponent } from './list-user-group/list-user-group.component';
import { UserGroupRoutingModule } from './user-group-routing.module';

@NgModule({
	imports: [
		SharedModule,
		UserGroupRoutingModule,
		MediaModule,
		TemplateTypeModule
	],
	declarations: [
		ListUserGroupComponent,
		CreateEditUserGroupComponent,
		ChangeDetailsTabComponent,
		HandleUsersTabComponent,
		DeleteUserGroupsComponent
	],
	exports: [
		HandleUsersTabComponent
	]
})
export class UserGroupModule { }
