import {
	ChangeDetectionStrategy, Component, EventEmitter, Input,
	Output, ViewChild
} from '@angular/core';
import { KeyValuePair } from '../../../filter';
import { SimpleListAction } from '../actions/simple-list-action';
import { SimpleListDataSource } from '../simple-list-data-source';
import { SimpleTableBodyComponent } from './body/simple-table-body.component';
import { SimpleTableRow } from './body/simple-table-row';
import { SimpleTableRowAction } from './body/simple-table-row-action';
import { SimpleFilterInput } from './filter/input-settings/simple-filter-input-settings';
import { SimpleFilterConfig, SortObject } from './filter/simple-filter-config';
import { SimpleFilterComponent } from './filter/simple-filter.component';
import { SimpleTableHeadComponent } from './head/simple-table-head.component';
import { SimpleTableHeaderAction } from './head/simple-table-header-action';
import { SimpleTableConfig } from './simple-table-config';

@Component({
	selector: 'simple-table',
	templateUrl: './simple-table.component.html',
	styleUrls: ['./simple-table.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTableComponent<T extends { id: string }> {

	filterOpened: boolean = false;

	@Input() listActions: SimpleListAction[];
	@Input() hideListActions: boolean;
	@Input() filterConfig: SimpleFilterConfig<T>;
	@Input() totalCount: number;
	@Input() selectedCount: number;

	@Input() isInModal: boolean;
	@Input() isCompactView: boolean;
	@Input() pending: boolean;
	@Input() tableConfig: SimpleTableConfig<T>;
	@Input() dataSource: SimpleListDataSource<T>;
	@Input() allSelected: boolean;
	@Input() someSelected: boolean;
	@Input() sortProperty: string;
	@Input() sortLabel: string;
	@Input() sortReversed: boolean;
	@Input() hasSelectableData: boolean;
	@Input() searchValue: string;
	@Input() filterInputs: SimpleFilterInput[];

	@Output() onListActionClick = new EventEmitter<SimpleListAction>();
	@Output() onSearch = new EventEmitter<string>();
	@Output() onFilter = new EventEmitter<KeyValuePair[]>();
	@Output() onSearchClear = new EventEmitter();
	@Output() onFilterClear = new EventEmitter();
	@Output() onSelectedClear = new EventEmitter();

	@Output() onSort = new EventEmitter<SortObject<T>>();
	@Output() onHeaderActionClick = new EventEmitter<SimpleTableHeaderAction>();
	@Output() onHeaderCheckboxClick = new EventEmitter();
	@Output() onRowActionClick = new EventEmitter<[SimpleTableRowAction<T>, SimpleTableRow<T>]>();
	@Output() onRowCheckboxClick = new EventEmitter<[MouseEvent, SimpleTableRow<T>]>();
	@Output() onRowClick = new EventEmitter<SimpleTableRow<T>>();
	@Output() onRowDoubleClick = new EventEmitter<SimpleTableRow<T>>();

	@ViewChild(SimpleFilterComponent) simpleFilter: SimpleFilterComponent<T>;
	@ViewChild(SimpleTableBodyComponent) simpleTableBody: SimpleTableBodyComponent<T>;
	@ViewChild(SimpleTableHeadComponent) simpleTableHead: SimpleTableHeadComponent<T>;

	get activeSearch() {
		return this.searchValue;
	}

	get activeFilter() {
		return this.filterInputs.filter(input => input.selectedItems.length);
	}

	popoverOpened() {
		this.simpleTableHead.closePopover();
		this.simpleFilter.closeAllPopovers();
	}

	listActionClick(action: SimpleListAction) {
		this.onListActionClick.emit(action);
	}

	search(value: string) {
		this.onSearch.emit(value);
	}

	filter(keyValuePairs: KeyValuePair[]) {
		this.onFilter.emit(keyValuePairs);
	}

	toggleFilterOpened(value: boolean) {
		this.filterOpened = value;
	}

	clearSearch() {
		this.onSearchClear.emit();
	}

	clearFilter() {
		this.onFilterClear.emit();
	}

	clearSelected() {
		this.onSelectedClear.emit();
	}

	sort(sortObject: SortObject<T>) {
		this.onSort.emit(sortObject);
	}

	headerActionClick(action: SimpleTableHeaderAction) {
		this.onHeaderActionClick.emit(action);
	}

	headerCheckboxClick() {
		this.onHeaderCheckboxClick.emit();
	}

	rowActionClick([action, row]: [SimpleTableRowAction<T>, SimpleTableRow<T>]) {
		this.onRowActionClick.emit([action, row]);
	}

	rowCheckboxClick([event, row]: [MouseEvent, SimpleTableRow<T>]) {
		this.onRowCheckboxClick.emit([event, row]);
	}

	rowClick(row: SimpleTableRow<T>) {
		this.onRowClick.emit(row);
	}

	rowDoubleClick(row: SimpleTableRow<T>) {
		this.onRowDoubleClick.emit(row);
	}
}
