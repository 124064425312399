import { NgModule } from '@angular/core';
import { CanEditPipe } from './can-edit.pipe';
import { DatexPipe } from './datex.pipe';
import { GetImageStorageSize } from './get-image-storage-size.pipe';
import { IsSuperAdminPipe } from './is-super-admin.pipe';
import { LineBreaksPipe } from './line-breaks.pipe';
import { LocaleStringPipe } from './locale-string';
import { MarkdownToHTMLPipe } from './markdown-to-html.pipe';
import { ToArrayLengthPipe } from './range.pipe';
import { RenderValuePipe } from './render-value.pipe';
import { SanitizeHTMLPipe } from './sanitize-html.pipe';
import { StatusIsActivePipe } from './status-is-active.pipe';
import { ToBgImagePipe } from './to-background-image.pipe';
import { ToDateOrUndefinedPipe } from './to-date-or-undefined.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
	declarations: [
		DatexPipe,
		LineBreaksPipe,
		CanEditPipe,
		IsSuperAdminPipe,
		RenderValuePipe,
		TruncatePipe,
		StatusIsActivePipe,
		MarkdownToHTMLPipe,
		LocaleStringPipe,
		ToArrayLengthPipe,
		ToBgImagePipe,
		GetImageStorageSize,
		SanitizeHTMLPipe,
		ToDateOrUndefinedPipe
	],
	exports: [
		DatexPipe,
		LineBreaksPipe,
		CanEditPipe,
		IsSuperAdminPipe,
		RenderValuePipe,
		TruncatePipe,
		StatusIsActivePipe,
		MarkdownToHTMLPipe,
		LocaleStringPipe,
		ToArrayLengthPipe,
		ToBgImagePipe,
		GetImageStorageSize,
		SanitizeHTMLPipe,
		ToDateOrUndefinedPipe
	]
})
export class PipesModule { }
