<simple-crud-modal
	[titleText]="('TemplateType' | translate) + (modifiedModel?.name ? ' ─ ' + modifiedModel?.name : '')"
	[closeText]="'Close' | translate"
	[submitText]="(isEdit ? 'Update' : 'Create') | translate"
	[disableSubmit]="!everythingIsValid() || !hasUnsavedChanges()"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab
				[customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(1)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-card-text'"
							[title]="'Details' | translate"
							[helpText]="'TemplateTypeChangeDetailsHelpText' | translate">
						</simple-tab-heading>
					</ng-template>
					<change-details-tab
						[changeModel]="modifiedModel">
					</change-details-tab>
			</tab>
			<tab #handleTab="tab"
				[customClass]="handleTasksIsValid() ? 'valid' : handleTasksHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(2)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'ii-task'"
							[title]="'Tasks' | translate"
							[helpText]="'TemplateTypeHandleTasksHelpText' | translate"
							[displayCount]="modifiedModel.tasks.length">
						</simple-tab-heading>
					</ng-template>
					<handle-tasks-tab
						[handleItems]="modifiedModel.tasks"
						[headerSubtitleText]="'AddedTasksSubtitleTemplateType' | translate"
						[wasSelected]="handleTab.active"
						(onModelsChange)="modifiedModel.tasks = $event; handleChanges()"
						(onUpdatedChoices)="handleUpdatedChoices($event)"
						(onDeletedChoices)="handleDeletedChoices($event)">
					</handle-tasks-tab>
			</tab>
		</tabset>
</simple-crud-modal>
