<div class="header">
	<div class="info">
		<div class="title-wrapper">
			<div class="title"
				title="{{ headerTitleText }}">
					{{ headerTitleText }}
			</div>
			<div class="subtitle" *ngIf="headerSubtitleText"
				title="{{ headerSubtitleText }}">
					{{ headerSubtitleText }}
			</div>
		</div>
	</div>
	<div class="actions">
		<input type="search" class="search" *ngIf="!hideSearch"
			[ngClass]="{
				'disabled': disableSearch
			}"
			[(ngModel)]="searchValue"
			(ngModelChange)="handleSearch()"
			[title]="'SearchInAdded' | translate"
			placeholder="{{ 'SearchInAdded' | translate }}..."
		/>
		<div class="buttons">
			<button *ngIf="!hideAddButton && !isMediumScreenSize()"
				class="btn btn-success add-button"
				[ngClass]="{
					'disabled': disableAddButton
				}"
				title="{{ headerAddButtonText }}"
				(click)="handleAddButtonClick()">
					{{ headerAddButtonText }}
			</button>
			<button *ngIf="!hideAddButton && isMediumScreenSize()"
				class="btn btn-success add-button bi-plus-lg"
				[ngClass]="{
					'disabled': disableAddButton
				}"
				title="{{ headerAddButtonText }}"
				(click)="handleAddButtonClick()">
			</button>
			<ng-content select="[slot=headerActions]"></ng-content>
		</div>
		<div class="list-actions">
			<div class="action bi-x-lg" *ngIf="!hideRemoveSelected"
				[ngClass]="{
					'disabled': disableRemoveSelected
				}"
				title="{{'RemoveSelected' | translate}}"
				(click)="handleRemoveSelectedClick()">
			</div>
			<ng-content select="[slot=actionsAfterRemove]"></ng-content>
			<div class="action select-all" *ngIf="!hideSelectAll"
				(mouseover)="hovered = true"
				(mouseleave)="hovered = false"
				[ngClass]="{
					'disabled': disableSelectAll,
					'bi-square': !hovered && !allAreSelected && selectedCount === 0,
					'bi-check-square': hovered && !allAreSelected,
					'bi-check-square-fill': allAreSelected,
					'bi-dash-square': selectedCount > 0 && !allAreSelected && !hovered
				}"
				title="{{'SelectAll' | translate}}"
				(click)="handleSelectAllClick()">
				<div class="selected-count" *ngIf="selectedCount > 0">
					{{ selectedCount | localeString }}
				</div>
			</div>
		</div>
	</div>
</div>

<ng-content select="[slot=itemList]"></ng-content>
