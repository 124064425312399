import { Injectable } from '@angular/core';
import { BaseService } from 'app-core/shared-core/abstract-components/service/base.service';
import { Filter, KeyValuePair, SortDirection } from 'app-core/shared-core/filter';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { User } from 'app-core/user/user';
import { UserGroup } from './user-group';

@Injectable()
export class UserGroupService extends BaseService<UserGroup> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
		super(
			hostedHttpClient,
			RoutesUtils.userGroup
		);
		this.useCoreApi = true;
	}

	async getUsers(organizationId: number): Promise<User[]> {
		const filter = new Filter();
		filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		filter.addSort(
			new KeyValuePair(
				Utils.uppercaseEachFirst(Utils.nameof<User>('firstname')),
				SortDirection.Ascending
			)
		);
		return this.hostedHttpClient.get(`user?organization=${organizationId}`, filter.toPayloadObject(), true)
			.then(res => res.data.map((item: User) => new User(item)));
	}

	deleteUserGroupsWithAffectedData(userGroupIds: string[], organizationId: number) {
		const model = {
			itemIds: userGroupIds,
			organizationId: organizationId
		};
		return this.hostedHttpClient.delete(`userGroup/affectedData`, model, false);
	}

	instantiateModel(item: UserGroup) {
		return new UserGroup(item);
	}
}

export class UserGroupFilter extends Filter {
	toPayloadObject() {
		const tempFacets = {};
		const currentFacets = (Object.entries(this.facets) as [string, string][])
			.map(facet => {
				return {
					key: facet[0],
					value: facet[1]
				};
			});

		currentFacets.forEach(facet => {
			if (facet.key === StringUtils.ASSIGNEES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.USER_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.USER_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.HAS_USERS_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.HAS_USERS_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.HAS_USERS_KEY] = 'false';
				}
			}
		});

		Object.assign(this.facets, tempFacets);

		return {
			filter: JSON.stringify(this)
		};
	}
}
