<div class="wrapper" *ngIf="selectedOrganization">
	<div class="header">
		<div class="image" #parentDiv
			[ngClass]="{
				'clickable': loggedInUser | canEdit: selectedOrganization.friendlyUrl
			}"
			(click)="openChangeImageModal()">
				<extensive-image
					[imageUrl]="selectedOrganization.logotypeUrl"
					[imageStorageSize]="parentDiv | getImageStorageSize"
					[placeholderImageType]="'organization'"
					[displayLoader]="true">
				</extensive-image>
				<div class="image-overlay bi bi-camera-fill" *ngIf="(loggedInUser | canEdit: selectedOrganization.friendlyUrl)"></div>
		</div>
		<div class="details">
			<div class="primary"
				[title]="selectedOrganization.name">
					{{ selectedOrganization.name }}
			</div>
			<div class="secondary" *ngIf="selectedOrganization.publicName"
				[title]="selectedOrganization.publicName">
					{{ selectedOrganization.publicName }}
			</div>
		</div>
		<div class="actions" *ngIf="loggedInUser | isSuperAdmin">
			<div class="action bi-three-dots-vertical" #actionsPop="bs-popover"
				[popover]="popTemplate"
				container="body"
				containerClass="standard-menu-popover"
				placement="left"
				[outsideClick]="true"
				[ngClass]="{
					'opened': actionsOpened
				}"
				(onShown)="actionsOpened = true"
				(onHidden)="actionsOpened = false"
				title="{{ 'OpenMenu' | translate }}">
			</div>
			<ng-template #popTemplate>
				<div class="standard-menu-item"
					[title]="'NewOrganization' | translate"
					(click)="actionsPop.hide(); openDetailsModal(true)">
					{{ 'NewOrganization' | translate }}
				</div>
				<div class="standard-menu-item red-highlight"
					[title]="'Delete' | translate"
					(click)="actionsPop.hide(); deleteOrganization()">
					{{ 'Delete' | translate }}
				</div>
			</ng-template>
		</div>
		<div class="test-marker" *ngIf="selectedOrganization.isTest">
			{{ stringUtils.TEST }}
		</div>
	</div>
	<div class="main apx-scrollbar" scrollShadow>
		<div class="column">
			<div class="section with-margin-bottom">
				<div class="section-body">
					<div class="details"
						[ngClass]="{
							'clickable': loggedInUser | canEdit: selectedOrganization.friendlyUrl,
							'loadmask': fetchingCultureSettings
						}"
						[title]="(loggedInUser | canEdit: selectedOrganization.friendlyUrl) ? ('Open' | translate) : ''"
						(click)="openDetailsModal()">
							<div class="info">
								<div class="header-text">
									{{ 'General' | translate }}
								</div>
								<div class="text bi bi-globe">
									{{ selectedOrganization.name }}
								</div>
								<div class="text bi bi-megaphone" *ngIf="selectedOrganization.publicName">
									{{ selectedOrganization.publicName }}
								</div>
								<div class="text bi bi-archive">
									{{ selectedOrganization.organizationNumber }}
								</div>
								<div class="text bi bi-envelope">
									{{ selectedOrganization.emailAddress }}
								</div>
								<div class="phone-wrapper bi bi-telephone" *ngIf="selectedOrganization.phoneNumber">
									<div class="country-code" *ngIf="selectedOrganization.countryCode">
										(+{{ selectedOrganization.countryCode }})
									</div>
									<div class="number" *ngIf="selectedOrganization.phoneNumber">
										{{ selectedOrganization.phoneNumber }}
									</div>
								</div>
								<div class="text bi bi-person" *ngIf="selectedOrganization.operationsManager">
									{{ selectedOrganization.operationsManager }}
								</div>
								<div class="header-text with-margin-top" *ngIf="selectedOrganization.streetAddress">
									{{ 'Address' | translate }}
								</div>
								<div class="address" *ngIf="selectedOrganization.streetAddress">
									<div class="text bi bi-mailbox">
										{{ selectedOrganization.streetAddress }}
									</div>
									<div class="city-wrapper bi bi-geo">
										<div class="zip-code">
											{{ selectedOrganization.zipCode }}
										</div>
										<div class="city">
											{{ selectedOrganization.city }}
										</div>
									</div>
									<div class="text bi bi-flag">
										{{ getCountryName() }}
									</div>
								</div>
								<div class="header-text with-margin-top">
									{{ 'Settings' | translate }}
								</div>
								<div class="text bi bi-translate">
									{{ getCultureName() }}
								</div>
								<div class="header-text with-margin-top" *ngIf="loggedInUser | isSuperAdmin">
									{{ 'ForSuperAdmins' | translate }}
								</div>
								<div class="text bi bi-layers" *ngIf="(loggedInUser | isSuperAdmin)">
									{{ selectedOrganization.parentOrganization ? selectedOrganization.parentOrganization.orgName : ('None' | translate) }}
								</div>
								<div class="text bi bi-code-square" *ngIf="loggedInUser | isSuperAdmin">
									{{ selectedOrganization.isTest ? ('Yes' | translate) : ('No' | translate) }}
								</div>
							</div>
							<div class="icon bi bi-pencil" *ngIf="loggedInUser | canEdit: selectedOrganization.friendlyUrl"></div>
							<dots-loader *ngIf="fetchingCultureSettings"></dots-loader>
					</div>
				</div>
			</div>
			<ng-container *ngIf="!isSmallScreenSize()">
				<ng-container *ngTemplateOutlet="reportConfigTemplate" >
				</ng-container>
			</ng-container>
		</div>
		<div class="column">
			<div class="section with-margin-bottom">
				<div class="section-header">
					<input type="search" class="search"
						[ngClass]="{
							'disabled': !clients.length
						}"
						[(ngModel)]="clientSearchValue"
						[title]="'SearchClients' | translate"
						placeholder="{{ 'SearchClients' | translate }}..."
					/>
					<button type="button" *ngIf="loggedInUser | canEdit: selectedOrganization.friendlyUrl"
						class="btn btn-transparent bi bi-plus-lg"
						[title]="'Create' | translate"
						(click)="openClientModal()">
					</button>
				</div>
				<div class="section-body">
					<div class="clients" scrollShadow
						[ngClass]="{
							'loadmask': fetchingClients
						}">
							<div class="info" *ngIf="clients.length">
								<div class="header-text">
									{{ 'Clients' | translate }}
								</div>
							</div>
							<div class="client" *ngFor="let client of getClients()">
								<div class="info">
									<div class="header-text">
										{{ 'General' | translate }}
									</div>
									<div class="text bi bi-globe">
										{{ client.name }}
									</div>
									<div class="text bi bi-envelope">
										{{ client.email }}
									</div>
									<div class="phone-wrapper bi bi-telephone" *ngIf="client.number">
										<div class="country-code" *ngIf="client.countryCode">
											(+{{ client.countryCode }})
										</div>
										<div class="number" *ngIf="client.number">
											{{ client.number }}
										</div>
									</div>
									<div class="text bi bi-person" *ngIf="client.operationsManager">
										{{ client.operationsManager }}
									</div>
								</div>
								<div class="actions" *ngIf="loggedInUser | canEdit: selectedOrganization.friendlyUrl">
									<button class="btn btn-transparent edit-button bi bi-pencil"
										[title]="'Open' | translate"
										(click)="openClientModal(client)">
									</button>
									<button class="btn btn-transparent delete-button bi bi-trash"
										[title]="'Delete' | translate"
										(click)="deleteClient(client)">
									</button>
								</div>
							</div>
							<div class="dummy-text" *ngIf="!clients.length && !fetchingClients">
								{{ 'NoClientsAdded' | translate }}
							</div>
							<dots-loader *ngIf="fetchingClients"></dots-loader>
					</div>
				</div>
			</div>
			<ng-container *ngIf="isSmallScreenSize()">
				<ng-container *ngTemplateOutlet="reportConfigTemplate" >
				</ng-container>
			</ng-container>
			<div class="section with-margin-bottom" *ngIf="(loggedInUser | canEdit: selectedOrganization.friendlyUrl)">
				<div class="section-body">
					<div class="action-buttons" scrollShadow
						[ngClass]="{
							'loadmask': fetchingTemplateTypes
						}">
						<div class="info" *ngIf="templateTypes.length">
							<div class="header-text">
								{{ 'TemplateTypes' | translate }}
							</div>
						</div>
						<button type="button" *ngFor="let templateType of templateTypes"
							class="btn btn-transparent"
							[title]="'Open' | translate"
							(click)="openTemplateTypeModal(templateType)">
								{{ templateType.name }}
								<div class="icon-wrapper">
									<div *ngIf="templateType.assignmentDescription" class="icon bi bi-card-text"></div>
									<div class="icon bi bi-pencil"></div>
								</div>
						</button>
						<div class="dummy-text" *ngIf="!templateTypes.length && !fetchingTemplateTypes">
							{{ 'NoTemplateTypesAdded' | translate }}
						</div>
						<dots-loader *ngIf="fetchingTemplateTypes"></dots-loader>
					</div>
				</div>
			</div>
			<div class="section">
				<div class="section-body">
					<div class="action-buttons"
						[ngClass]="{
							'disabled': !(loggedInUser | isSuperAdmin),
							'loadmask': fetchingIntegrations
						}">
							<div class="info">
								<div class="header-text">
									{{ 'Integrations' | translate }}
								</div>
							</div>
							<button type="button"
								class="btn btn-transparent"
								[title]="'Open' | translate"
								(click)="openIntegrationModal('DfRespons')">
									{{ 'DFRespons' | translate }}
									<div class="icon-wrapper">
										<div class="icon bi"
											[ngClass]="{
												'bi-toggle-on': hasDfRespons(),
												'bi-toggle-off': !hasDfRespons(),
												'active': hasDfRespons() && dfResponsIsActive(),
												'inactive': hasDfRespons() && !dfResponsIsActive()
											}">
										</div>
										<div class="icon bi bi-pencil"></div>
									</div>
							</button>
							<!-- TODO fundera på designstruktur, inte nödvändigt tills vidare -->
							<!-- <button type="button" *ngIf="(loggedInUser | isSuperAdmin) && hasDfRespons()"
								class="btn btn-transparent bi-trash"
								[title]="'Delete' | translate"
								(click)="deleteIntegration('DfRespons')">
							</button> -->
							<button type="button"
								class="btn btn-transparent"
								[title]="'Open' | translate"
								(click)="openIntegrationModal('InfraControl')">
									{{ 'InfraControl' | translate }}
									<div class="icon-wrapper">
										<div class="icon bi"
											[ngClass]="{
												'bi-toggle-on': hasInfraControl(),
												'bi-toggle-off': !hasInfraControl(),
												'active': hasInfraControl() && infraControlIsActive(),
												'inactive': hasInfraControl() && !infraControlIsActive()
											}">
										</div>
										<div class="icon bi bi-pencil"></div>
									</div>
							</button>
							<!-- TODO fundera på designstruktur, inte nödvändigt tills vidare -->
							<!-- <button type="button" *ngIf="(loggedInUser | isSuperAdmin) && hasInfraControl()"
								class="btn btn-transparent bi-trash"
								[title]="'Delete' | translate"
								(click)="deleteIntegration('InfraControl')">
							</button> -->
							<dots-loader *ngIf="fetchingIntegrations"></dots-loader>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #reportConfigTemplate>
	<div class="section with-margin-bottom">
		<div class="section-body">
			<div class="action-buttons"
				[ngClass]="{
					'disabled': !(loggedInUser | canEdit: selectedOrganization.friendlyUrl),
					'loadmask': fetchingIntegrations
				}">
					<div class="info">
						<ng-container *ngIf="hasSSEN">
							<div class="header-text">
								{{ 'AnnualSafetyInspection' | translate }} & {{ 'InstallationInspection' | translate }}
							</div>

							<button type="button"
								class="btn btn-transparent"
								[title]="'Open' | translate"
								(click)="openSSENReportModal(ssenReportIntegrationTypes.InAccordanceWith)">
									{{ 'InAccordanceWith' | translate }}
									<div class="icon-wrapper">
										<div *ngIf="ssenIntegration?.inAccordanceWith" class="icon bi bi-card-text"></div>
										<div class="icon bi bi-pencil"></div>
									</div>
							</button>

							<button type="button"
								class="btn btn-transparent"
								[title]="'Open' | translate"
								(click)="openSSENReportModal(ssenReportIntegrationTypes.Scope)">
									{{ 'Scope' | translate }}
									<div class="icon-wrapper">
										<div *ngIf="ssenIntegration?.scope" class="icon bi bi-card-text"></div>
										<div class="icon bi bi-pencil"></div>
									</div>
							</button>

							<button type="button"
								class="btn btn-transparent"
								[title]="'Open' | translate"
								(click)="openSSENReportModal(ssenReportIntegrationTypes.RiskAnalysis)">
									{{ 'RiskAnalysis' | translate }}
									<div class="icon-wrapper">
										<div *ngIf="ssenIntegration?.riskAnalysis" class="icon bi bi-card-text"></div>
										<div class="icon bi bi-pencil"></div>
									</div>
							</button>

							<button type="button"
								class="btn btn-transparent"
								[title]="'Open' | translate"
								(click)="openSSENReportModal(ssenReportIntegrationTypes.ErrorCodeExplanation)">
									{{ 'ErrorCodeExplanation' | translate }}
									<div class="icon-wrapper">
										<div *ngIf="ssenIntegration?.errorCodeExplanation" class="icon bi bi-card-text"></div>
										<div class="icon bi bi-pencil"></div>
									</div>
							</button>

							<button type="button"
								class="btn btn-transparent"
								[title]="'Open' | translate"
								(click)="openSSENReportModal(ssenReportIntegrationTypes.SerenoReference)">
									{{ 'RiseReference' | translate }}
									<div class="icon-wrapper">
										<div *ngIf="ssenIntegration?.serenoReference" class="icon bi bi-card-text"></div>
										<div class="icon bi bi-pencil"></div>
									</div>
							</button>
						</ng-container>
						<div class="dummy-text" *ngIf="!hasSSEN && !fetchingIntegrations">
							{{ 'SsenIntegrationInfoText' | translate }}
						</div>
					</div>
					<dots-loader *ngIf="fetchingIntegrations"></dots-loader>
			</div>
		</div>
	</div>
</ng-template>
