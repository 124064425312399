import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { MediaResolver } from 'app-core/media/resolver/media-resolver';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { SimpleReadModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-read-modal.directive';
import { FileHelper } from 'app-core/shared-core/tools/file-helper';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Result } from 'app-inspection/assignment/assignment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'result-modal',
	templateUrl: './result-modal.component.html'
})
export class ResultModalComponent extends SimpleReadModalDirective<Result> {

	errorResult: Result;
	resolvementResult: Result;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private mediaResolver: MediaResolver,
		private fileHelper: FileHelper) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async downloadAll(items: MediaWidgetItem[]) {
		const itemsToConvert = items.map(item => {
			return {
				title: item.title,
				url: this.mediaResolver.resolve(item.url),
				isImage: item.isImage
			} as MediaWidgetItem;
		});
		const succeeded = await this.fileHelper.convertToZipAndDownload(itemsToConvert);
		if (!succeeded) {
			this.toastrService.error(this.translationService.instant(StringUtils.SOME_FILES_COULD_NOT_BE_DOWNLOADED_ERROR));
		}
	}
}
