import { ComponentRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ExtensiveImageComponent } from 'app-core/media/extensive-image/extensive-image.component';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { MediaWidgetItemViewerComponent } from 'app-core/media/widget/item/viewer/media-widget-item-viewer.component';
import { ColorUtils } from 'app-core/shared-core/tools/color-utils';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { MediaUtils } from 'app-core/shared-core/tools/media-utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { SimpleTableColumn } from './simple-table-column';

export class SimpleTableThumbnailMainSecondaryColumn<T> extends SimpleTableColumn<T> {

	readonly getThumbnailTextContent: (item: T) => ThumbnailTextContent;

	bsModalRef: BsModalRef;

	private modalCloseSubscription = new Subscription();

	constructor(
		label: string,
		getThumbnailTextContent: (item: T) => ThumbnailTextContent,
		sanitizer: DomSanitizer,
		viewContainerRef: ViewContainerRef,
		secondaryLabel: string,
		private modalService: BsModalService) {
		super(
			label,
			sanitizer,
			viewContainerRef,
			secondaryLabel
		);
		this.getThumbnailTextContent = getThumbnailTextContent;
	}

	renderValue(item: T) {
		const content = this.getThumbnailTextContent(item);

		const wrapper = document.createElement('div');
		wrapper.classList.add('thumbnail-text-wrapper');
		wrapper.style.display = 'flex';
		wrapper.style.alignItems = 'center';
		wrapper.classList.add(content.identification);

		const thumbnailDiv = document.createElement('div');
		thumbnailDiv.style.width = '38px';
		thumbnailDiv.style.height = '38px';
		thumbnailDiv.style.flex = 'none';
		thumbnailDiv.style.marginRight = '10px';

		const componentRef = this.viewContainerRef.createComponent(ExtensiveImageComponent);
		componentRef.instance.imageUrl = content.thumbnail;
		componentRef.instance.imageStorageSize = 38;
		componentRef.instance.rounded = content.rounded;
		componentRef.instance.placeholderImageType = content.placeholderImageType;
		componentRef.instance.refresh();

		this.viewContainerRef.clear();

		const extensiveImageDiv = componentRef.location.nativeElement.childNodes[0];
		thumbnailDiv.append(extensiveImageDiv);

		const textWrapperDiv = document.createElement('div');
		textWrapperDiv.style.display = 'flex';
		textWrapperDiv.style.flexDirection = 'column';
		textWrapperDiv.style.overflow = 'hidden';

		if (content.mainText) {
			const textDiv = document.createElement('div');
			textDiv.innerHTML = content.mainText;
			textDiv.style.overflow = 'hidden';
			textDiv.style.textOverflow = 'ellipsis';
			textDiv.title = content.mainText;
			textWrapperDiv.append(textDiv);
		}

		if (content.secondaryText) {
			const secondaryTextDiv = document.createElement('div');
			secondaryTextDiv.innerHTML = content.secondaryText;
			secondaryTextDiv.style.overflow = 'hidden';
			secondaryTextDiv.style.textOverflow = 'ellipsis';
			secondaryTextDiv.title = content.secondaryText;
			secondaryTextDiv.style.fontSize = '12px';
			secondaryTextDiv.style.color = ColorUtils.A_BIT_DARKER_SECONDARY_FONT_COLOR;
			textWrapperDiv.append(secondaryTextDiv);
		}

		wrapper.append(thumbnailDiv, textWrapperDiv);

		// Needed due to the async nature of the img emitters in extensive image.
		this.applyAfterEmits(componentRef, content);

		return this.sanitizer.bypassSecurityTrustHtml(wrapper.outerHTML);
	}

	private applyAfterEmits(componentRef: ComponentRef<ExtensiveImageComponent>, content: ThumbnailTextContent) {
		componentRef.instance.img.onerror = () => {
			const elements = document.getElementsByClassName(content.identification) as HTMLCollectionOf<HTMLDivElement>;
			if (elements.length) {
				const wrapper = elements[0];
				const div = wrapper.querySelector('.extensive-image-div') as HTMLDivElement;
				if (div) {
					div.style.backgroundImage = `url(${MediaUtils.GRAPHICS_ERROR_IMAGE_BROKEN_LINK})`;
					div.classList.add('error-image-style');
				}
			}
		};

		componentRef.instance.img.onload = () => {
			const elements = document.getElementsByClassName(content.identification) as HTMLCollectionOf<HTMLDivElement>;
			if (elements.length) {
				const wrapper = elements[0];
				const div = wrapper.querySelector('.extensive-image-div') as HTMLDivElement;

				DomUtils.showLargerImageOnHover(div, content.rounded, componentRef.instance.img.src);
				// Click
				div.addEventListener('click',
					(event) => {
						event.stopPropagation();
						const item = new MediaWidgetItem({
							isImage: true,
							url: componentRef.instance.imageUrl
						});
						if (this.bsModalRef) {
							this.closeModal();
						}
						setTimeout(() => {
							if (!this.bsModalRef) {
								this.bsModalRef = this.modalService.show(
									MediaWidgetItemViewerComponent,
									{
										initialState: {
											item: item,
											collection: [item]
										},
										...ConfigUtils.MODAL_CONFIG_LARGE_WITH_OUTSIDE_CLICK
									}
								);

								const component = this.bsModalRef.content;
								this.modalCloseSubscription = component.closed$
									.subscribe((wasClosed: boolean) => {
										if (wasClosed) {
											this.closeModal();
										}
									});
							}
						});
					});
			}
		};
	}

	private closeModal() {
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
		this.modalCloseSubscription.unsubscribe();
	}
}

export class ThumbnailTextContent {
	thumbnail: string;
	mainText: string;
	identification: string;
	secondaryText: string;
	rounded: boolean;
	placeholderImageType: string;

	constructor(
		thumbnail: string,
		mainText: string,
		identification: string,
		secondaryText?: string,
		rounded?: boolean,
		placeholderImageType?: string) {
		this.thumbnail = thumbnail;
		this.mainText = mainText;
		this.identification = identification;
		this.secondaryText = secondaryText ? secondaryText : '';
		this.rounded = rounded ? rounded : false;
		this.placeholderImageType = placeholderImageType;
	}
}
