<div class="wrapper" *ngIf="loggedInUser">
	<div class="header">
		<div class="image clickable" #parentDiv
			(click)="openChangeImageModal()">
				<extensive-image
					[imageUrl]="loggedInUser.profileImageUrl"
					[imageStorageSize]="parentDiv | getImageStorageSize"
					[placeholderImageType]="'user'"
					[displayLoader]="true"
					[rounded]="true">
				</extensive-image>
				<div class="image-overlay bi bi-camera-fill u-rounded"></div>
		</div>
		<div class="details">
			<div class="primary"
				[title]="loggedInUser.fullName">
					{{ loggedInUser.fullName }}
			</div>
			<div class="secondary"
				[title]="loggedInUser.email">
					{{ loggedInUser.email }}
			</div>
		</div>
		<div class="actions">
			<div class="action bi-gear-fill" *ngIf="(loggedInUser | isSuperAdmin)"
				[title]="'Administrate' | translate"
				(click)="navigateToAdministrationPage()">
			</div>
			<div class="action bi-three-dots-vertical" #actionsPop="bs-popover"
				[popover]="popTemplate"
				container="body"
				containerClass="standard-menu-popover"
				placement="left"
				[outsideClick]="true"
				[ngClass]="{
					'opened': actionsOpened
				}"
				(onShown)="actionsOpened = true"
				(onHidden)="actionsOpened = false"
				title="{{ 'OpenMenu' | translate }}">
			</div>
			<ng-template #popTemplate>
				<div class="standard-menu-item"
					[title]="'GetUserData' | translate"
					(click)="actionsPop.hide(); getUserData()">
					{{ 'GetUserData' | translate }}
				</div>
				<div class="standard-menu-item"
					[title]="'Signout' | translate"
					(click)="actionsPop.hide(); logout()">
					{{ 'Signout' | translate }}
				</div>
			</ng-template>
		</div>
	</div>
	<div class="main apx-scrollbar" scrollShadow>
		<div class="column">
			<div class="section with-margin-bottom">
				<div class="section-body">
					<div class="details clickable"
						[ngClass]="{
							'loadmask': fetchingCultureSettings || fetchingDocuments
						}"
						[title]="'Open' | translate"
						(click)="openDetailsModal()">
							<div class="info">
								<div class="header-text">
									{{ 'General' | translate }}
								</div>
								<div class="text bi bi-person">
									{{ loggedInUser.fullName }}
								</div>
								<div class="text bi bi-envelope">
									{{ loggedInUser.email }}
								</div>
								<div class="phone-wrapper bi bi-telephone" *ngIf="loggedInUser.contactPhoneNumber">
									<div class="country-code" *ngIf="loggedInUser.contactCountryCode">
										(+{{ loggedInUser.contactCountryCode }})
									</div>
									<div class="number" *ngIf="loggedInUser.contactPhoneNumber">
										{{ loggedInUser.contactPhoneNumber }}
									</div>
								</div>
								<div class="text bi bi-flag">
									{{ getCountryName() }}
								</div>
								<div class="header-text with-margin-top">
									{{ 'Settings' | translate }}
								</div>
								<div class="text bi bi-translate">
									{{ getCultureName() }}
								</div>
								<div class="text bi bi-clock">
									{{ getTimeZoneName() }}
								</div>
								<div class="header-text with-margin-top" *ngIf="loggedInUser.certificates || documents.length">
									{{ 'Certificate' | translate }}
								</div>
								<div class="text bi bi-pass" *ngIf="loggedInUser.certificates">
									{{ loggedInUser.certificates }}
								</div>
								<div class="documents apx-scrollbar" *ngIf="documents.length">
									<div class="document" *ngFor=" let document of documents">
										<extensive-pdf
											[pdfUrl]="document.url"
											[thumbnailVersion]="true">
										</extensive-pdf>
									</div>
								</div>
							</div>
							<div class="icon bi bi-pencil"></div>
							<dots-loader *ngIf="fetchingCultureSettings || fetchingDocuments"></dots-loader>
					</div>
				</div>
			</div>
			<ng-container *ngIf="!isSmallScreenSize()">
				<ng-container *ngTemplateOutlet="connectionsTemplate" >
				</ng-container>
			</ng-container>
		</div>
		<div class="column">
			<div class="section with-margin-bottom">
				<div class="section-body">
					<div class="organizations" scrollShadow>
						<div class="header-text">
							{{ 'MainOrganization' | translate }}
						</div>
						<div class="organization"
							[ngClass]="{
								'highlighted': loggedInUser.organization.id === selectedOrganization.id
							}">
								<div class="image" #parentDiv>
									<extensive-image
										[imageUrl]="loggedInUser.organization.logotypeUrl"
										[imageStorageSize]="parentDiv | getImageStorageSize"
										[placeholderImageType]="'organization'"
										[displayLoader]="true">
									</extensive-image>
								</div>
								<div class="info">
									<div class="name">
										{{ getOrgName(loggedInUser.organization) }}
									</div>
									<div class="roles">
										{{ getFormattedRoles(loggedInUser.roles) }}
									</div>
									<div class="status">
										<simple-status-label
											[text]="loggedInUser.status">
										</simple-status-label>
									</div>
								</div>
								<div class="actions-wrapper">
									<div class="actions">
										<button class="btn btn-transparent select-button bi bi-arrow-repeat"
											[ngClass]="{
												'disabled': loggedInUser.organization.id === selectedOrganization.id || (!(loggedInUser | isSuperAdmin) && loggedInUser.status === stringUtils.PENDING)
											}"
											[title]="'Select' | translate"
											(click)="selectOrganization(loggedInUser.organization, loggedInUser.isSuperAdmin() || loggedInUser.status !== stringUtils.PENDING)">
										</button>
									</div>
									<div class="invite-date">
										{{ 'InvitedOn' | translate }} {{ loggedInUser.inviteDate | datex: 'LL' }}
									</div>
								</div>
								<div class="test-marker" *ngIf="(loggedInUser | isSuperAdmin) && loggedInUser.organization.isTest">
									{{ stringUtils.TEST }}
								</div>
						</div>
						<div class="header-text with-margin-top" *ngIf="loggedInUser.organizationMemberships.length">
							{{ 'Memberships' | translate }}
						</div>
						<div class="organization" *ngFor="let membership of loggedInUser.organizationMemberships"
							[ngClass]="{
								'highlighted': membership.organization.id === selectedOrganization.id
							}">
								<div class="image" #parentDiv>
									<extensive-image
										[imageUrl]="membership.organization.logotypeUrl"
										[imageStorageSize]="parentDiv | getImageStorageSize"
										[placeholderImageType]="'organization'"
										[displayLoader]="true">
									</extensive-image>
								</div>
								<div class="info">
									<div class="name">
										{{ getOrgName(membership.organization) }}
									</div>
									<div class="roles">
										{{ getFormattedRoles(membership.roles) }}
									</div>
									<div class="status">
										<simple-status-label
											[text]="membership.status">
										</simple-status-label>
									</div>
								</div>
								<div class="actions-wrapper">
									<div class="actions">
										<button class="btn btn-transparent select-button bi bi-arrow-repeat"
											[ngClass]="{
												'disabled': membership.organization.id === selectedOrganization.id || (!(loggedInUser | isSuperAdmin) && membership.status === stringUtils.PENDING)
											}"
											[title]="'Select' | translate"
											(click)="selectOrganization(membership.organization, loggedInUser.isSuperAdmin() || membership.status !== stringUtils.PENDING)">
										</button>
										<button class="btn btn-transparent delete-button bi bi-person-x-fill"
											[ngClass]="{
												'disabled': membership.status === stringUtils.PENDING
											}"
											[title]="'Leave' | translate"
											(click)="leaveMembership(membership)">
										</button>
									</div>
									<div class="invite-date">
										{{ 'InvitedOn' | translate }} {{ membership.inviteDate | datex: 'LL' }}
									</div>
								</div>
								<div class="test-marker" *ngIf="(loggedInUser | isSuperAdmin) && membership.organization.isTest">
									{{ stringUtils.TEST }}
								</div>
						</div>
					</div>
				</div>
			</div>
			<ng-container *ngIf="isSmallScreenSize()">
				<ng-container *ngTemplateOutlet="connectionsTemplate" >
				</ng-container>
			</ng-container >
			<div class="section">
				<div class="section-body">
					<div class="info">
						<div class="header-text">
							{{ 'Security' | translate }}
						</div>
					</div>
					<div class="action-buttons">
						<button type="button"
							class="btn btn-transparent"
							[title]="'Open' | translate"
							(click)="openChangePasswordModal()">
								{{ 'ChangePassword' | translate }}
								<div class="icon bi bi-pencil"></div>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #connectionsTemplate>
	<div class="section with-margin-bottom">
		<div class="connections-wrapper"
			[ngClass]="{
				'loadmask': fetchingSchedules
			}">
				<simple-view-connections
					[userGroups]="loggedInUser.userGroups"
					[schedules]="schedules"
					[disabled]="!(loggedInUser | canEdit: selectedOrganization.friendlyUrl)">
				</simple-view-connections>
				<div class="dummy-text" *ngIf="!loggedInUser.userGroups.length && !schedules.length && !fetchingSchedules">
					{{ 'NoConnectionsYet' | translate }}
				</div>
				<dots-loader *ngIf="fetchingSchedules"></dots-loader>
		</div>
	</div>
</ng-template>
