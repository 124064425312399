import { Injectable } from '@angular/core';
import { LocalStorageKey, LocalStorageService } from 'app-core/shared-core/local-storage/local-storage.service';
import { NotificationMessage } from './notification-message/notification-message';
import { UserConfig } from './user-config';
import { UserSupportStorage } from './user-support';

@Injectable()
export class UserSupportLocalStorageService extends LocalStorageService {

	private userSupportStorage: UserSupportStorage;

	setInitialUserConfig(userConfig: UserConfig) {
		this.initUserSupportStorage();
		const existingUserConfig = this.getExistingUserConfig(userConfig);
		if (existingUserConfig) {
			Object.assign(userConfig, existingUserConfig);
		} else {
			this.setNewUserConfig(userConfig);
		}
	}

	updateUserConfig(userConfig: UserConfig) {
		const existingUserConfig = this.getExistingUserConfig(userConfig);
		if (existingUserConfig) {
			Object.assign(existingUserConfig, userConfig);
			this.saveToStorage();
		} else {
			this.setNewUserConfig(userConfig);
		}
	}

	setHasBeenOpenedState(notificationMessages: NotificationMessage[], userConfig: UserConfig) {
		const existingUserConfig = this.getExistingUserConfig(userConfig);
		if (existingUserConfig) {
			notificationMessages
				.forEach(message => message.hasBeenOpened = existingUserConfig.openedNotificationIds
					.some(id => id === message.id)
				);
		}
		return notificationMessages;
	}

	private getExistingUserConfig(userConfig: UserConfig) {
		return this.userSupportStorage.userConfigs.find(existingUserConfig => existingUserConfig.userId === userConfig.userId);
	}

	private setNewUserConfig(userConfig: UserConfig) {
		const newUserConfig = new UserConfig(userConfig);
		this.userSupportStorage.userConfigs.push(newUserConfig);
		this.saveToStorage();
	}

	private saveToStorage() {
		this.setItem(LocalStorageKey.UserSupport, this.userSupportStorage);
		this.initUserSupportStorage();
	}

	private initUserSupportStorage() {
		const storage = this.getItem(LocalStorageKey.UserSupport);
		this.userSupportStorage = storage ? new UserSupportStorage(storage) : new UserSupportStorage({});
	}
}
