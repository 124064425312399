import { CrudItem } from 'app-core/shared-core/simple-components/crud/crud-item';
import { RuleObject } from 'app-core/shared-core/simple-components/various/rules/simple-rules.component';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { UserGroupOnUser } from 'app-core/user-group/user-group';
import { UserConfig } from 'app-core/user-support/user-config';
import { Organization } from '../organization/organization';
import { StringUtils } from '../shared-core/tools/string-utils';

export const SUPPORTED_LANGUAGES = ['sv', 'no', 'en'];
export const DEFAULT_LANGUAGE = 'sv';

export class User extends CrudItem<User> {
	email: string = '';
	firstname: string = '';
	lastname: string = '';
	country: string = '';
	timeZone: string = '';
	timeZoneIANA: string = '';
	culture: string = '';
	organization: Organization;
	profileImageUrl: string = '';
	roles: string[] = [];
	contactCountryCode: string = null; // Needs to be instantiated with null instead of empty as it is used for the ng-select lib, and is optional.
	contactPhoneNumber: string = '';
	certificates: string = '';
	organizationId: number;
	status: string = StringUtils.ACTIVE;
	organizationMemberships: OrganizationMembership[] = [];
	inviteDate: string = '';
	exists: boolean = false;
	selectedOrganizationId: number;
	deleted: string = '';
	membershipOrganizationId: number;
	fullName?: string = '';
	selectableCountryCodes: UserSettingsItem[] = [];
	selectableCountries: UserSettingsItem[] = [];
	selectableTimeZones: UserSettingsItem[] = [];
	selectableCultures: UserSettingsItem[] = [];
	selectableRoles: UserSettingsItem[] = [];
	userGroups: UserGroupOnUser[] = [];
	rolesTemp: string[] = [];
	statusTemp: string = StringUtils.ACTIVE;
	password: string = '';
	confirmPassword: string = '';

	constructor(user: Partial<User>) {
		super();
		Object.assign(this, user);
		this.mapData();
		this.setUrl(RoutesUtils.users, RoutesUtils.user);
	}

	private mapData() {
		this.mapOrganization();
		this.mapOrganizationMembership();
		this.mapFullName();
		this.mapUserGroups();
		this.contactCountryCode = this.contactCountryCode === '0' ? null : this.contactCountryCode;
	}

	private mapOrganization() {
		if (this.organization) {
			this.organization = new Organization(this.organization);
		}
	}

	private mapOrganizationMembership() {
		this.organizationMemberships.sortByProperty('status');
		this.organizationMemberships = this.organizationMemberships.map(membership => new OrganizationMembership(membership));
	}

	private mapFullName() {
		if (this.firstname && this.lastname) {
			this.fullName = `${this.firstname} ${this.lastname}`;
		}
	}

	private mapUserGroups() {
		this.userGroups = this.userGroups.map(group => new UserGroupOnUser(group));
	}

	toPayloadObject(selectedOrgId: number, isLoggedInUser: boolean) {
		return new UserPayload({
			id: this.id,
			firstname: this.firstname,
			lastname: this.lastname,
			email: this.email,
			contactCountryCode: this.contactCountryCode,
			contactPhoneNumber: this.contactPhoneNumber,
			country: this.country,
			culture: this.culture,
			timeZone: this.timeZone,
			organizationId: this.organizationId,
			roles: isLoggedInUser ? this.roles : this.getCurrentRoles(selectedOrgId),
			status: isLoggedInUser ? this.status : this.getCurrentStatus(selectedOrgId),
			membershipOrganizationId: this.membershipOrganizationId ? this.membershipOrganizationId : null,
			certificates: this.certificates,
			password: this.password
		});
	}

	getCurrentRoles(selectedOrgId: number) {
		if (this.organization) {
			if (selectedOrgId === this.organization.id) {
				return this.roles;
			} else {
				const membership = this.getMembership(selectedOrgId);
				if (membership) {
					return membership.roles;
				} else {
					return [];
				}
			}
		} else {
			return [];
		}
	}

	getCurrentInviteDate(selectedOrgId: number) {
		if (this.organization) {
			if (selectedOrgId === this.organization.id) {
				return this.inviteDate;
			} else {
				const membership = this.getMembership(selectedOrgId);
				if (membership) {
					return membership.inviteDate;
				} else {
					return '';
				}
			}
		} else {
			return '';
		}
	}

	getCurrentStatus(selectedOrgId: number) {
		if (this.organization) {
			if (selectedOrgId === this.organization.id) {
				return this.status;
			} else {
				const membership = this.getMembership(selectedOrgId)
				if (membership) {
					return membership.status;
				} else {
					return '';
				}
			}
		} else {
			return '';
		}
	}

	getCurrentDeleted(selectedOrgId: number) {
		if (this.organization) {
			if (selectedOrgId === this.organization.id) {
				return this.deleted;
			} else {
				const membership = this.getMembership(selectedOrgId);
				if (membership) {
					return membership.deleted;
				} else {
					return 'deleted';
				}
			}
		} else {
			return 'deleted';
		}
	}

	private getMembership(selectedOrgId: number) {
		return this.organizationMemberships.find(ms => (ms.organization ? ms.organization.id : ms.organizationId) === selectedOrgId);
	}
}

export class UserPayload extends User {
	constructor(userPayload: Partial<UserPayload>) {
		super(userPayload);
		Object.assign(this, userPayload);
	}
}

export class LoggedInUser extends User {
	language: string = DEFAULT_LANGUAGE;
	userConfig: UserConfig;

	constructor(loggedInUser: Partial<LoggedInUser>) {
		super(loggedInUser);
		Object.assign(this, loggedInUser);

		let language = this.culture.split('-')[0].toLowerCase();
		language = language === 'nb' ? language = 'no' : language;
		if (SUPPORTED_LANGUAGES.indexOf(language) > -1) {
			this.language = language;
		}

		this.userConfig = new UserConfig({ userId: this.id });
		this.userGroups = this.userGroups.map(group => new UserGroupOnUser(group));
	}
}

export class Subscriber extends User {
	hovered: boolean = false;
	scheduledCreated: boolean = false;
	scheduledExpired: boolean = false;
	scheduledCompleted: boolean = false;
	measureCreated: boolean = false;
	measureClosed: boolean = false;
	measureCompleted: boolean = false;
	urgentMeasureCreated: boolean = false;
	userId: string = null;

	get notificationsEditableInScheduledAssignment() {
		const notifications: RuleObject[] = [];
		if (this.scheduledCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus green',
				title: 'ScheduledCreated'
			}));
		}
		if (!this.scheduledCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus',
				title: 'ScheduledCreated'
			}));
		}
		if (this.scheduledExpired) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-data reverse green',
				title: 'ScheduledExpired'
			}));
		}
		if (!this.scheduledExpired) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-data reverse',
				title: 'ScheduledExpired'
			}));
		}
		if (this.scheduledCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check green',
				title: 'ScheduledCompleted'
			}));
		}
		if (!this.scheduledCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check',
				title: 'ScheduledCompleted'
			}));
		}
		if (this.measureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus red',
				title: 'MeasureCreated'
			}));
		}
		if (!this.measureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus',
				title: 'MeasureCreated'
			}));
		}
		if (this.measureClosed) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-x red',
				title: 'MeasureClosed'
			}));
		}
		if (!this.measureClosed) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-x',
				title: 'MeasureClosed'
			}));
		}
		if (this.measureCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check red',
				title: 'MeasureCompleted'
			}));
		}
		if (!this.measureCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check',
				title: 'MeasureCompleted'
			}));
		}
		return notifications;
	}

	get notificationsEditableInScheduledAssignmentWithUrgent() {
		const notifications: RuleObject[] = [];
		if (this.scheduledCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus green',
				title: 'ScheduledCreated'
			}));
		}
		if (!this.scheduledCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus',
				title: 'ScheduledCreated'
			}));
		}
		if (this.scheduledExpired) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-data reverse green',
				title: 'ScheduledExpired'
			}));
		}
		if (!this.scheduledExpired) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-data reverse',
				title: 'ScheduledExpired'
			}));
		}
		if (this.scheduledCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check green',
				title: 'ScheduledCompleted'
			}));
		}
		if (!this.scheduledCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check',
				title: 'ScheduledCompleted'
			}));
		}
		if (this.measureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus red',
				title: 'MeasureCreated'
			}));
		}
		if (!this.measureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus',
				title: 'MeasureCreated'
			}));
		}
		if (this.measureClosed) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-x red',
				title: 'MeasureClosed'
			}));
		}
		if (!this.measureClosed) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-x',
				title: 'MeasureClosed'
			}));
		}
		if (this.measureCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check red',
				title: 'MeasureCompleted'
			}));
		}
		if (!this.measureCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check',
				title: 'MeasureCompleted'
			}));
		}
		if (this.urgentMeasureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus-fill red',
				title: 'UrgentMeasureCreated'
			}));
		}
		if (!this.urgentMeasureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus-fill',
				title: 'UrgentMeasureCreated'
			}));
		}
		return notifications;
	}

	get notificationsEditableInScheduledAssignmentOnlyScheduled() {
		const notifications: RuleObject[] = [];
		if (this.scheduledCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus green',
				title: 'ScheduledCreated'
			}));
		}
		if (!this.scheduledCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus',
				title: 'ScheduledCreated'
			}));
		}
		if (this.scheduledExpired) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-data reverse green',
				title: 'ScheduledExpired'
			}));
		}
		if (!this.scheduledExpired) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-data reverse',
				title: 'ScheduledExpired'
			}));
		}
		if (this.scheduledCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check green',
				title: 'ScheduledCompleted'
			}));
		}
		if (!this.scheduledCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check',
				title: 'ScheduledCompleted'
			}));
		}
		return notifications;
	}

	get notificationsEditableInScheduledAssignmentOnlyMeasure() {
		const notifications: RuleObject[] = [];
		if (this.measureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus red',
				title: 'MeasureCreated'
			}));
		}
		if (!this.measureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus',
				title: 'MeasureCreated'
			}));
		}
		if (this.measureClosed) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-x red',
				title: 'MeasureClosed'
			}));
		}
		if (!this.measureClosed) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-x',
				title: 'MeasureClosed'
			}));
		}
		if (this.measureCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check red',
				title: 'MeasureCompleted'
			}));
		}
		if (!this.measureCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check',
				title: 'MeasureCompleted'
			}));
		}
		return notifications;
	}

	get notificationsEditableInScheduledAssignmentOnlyMeasureWithUrgent() {
		const notifications: RuleObject[] = [];
		if (this.measureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus red',
				title: 'MeasureCreated'
			}));
		}
		if (!this.measureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus',
				title: 'MeasureCreated'
			}));
		}
		if (this.measureClosed) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-x red',
				title: 'MeasureClosed'
			}));
		}
		if (!this.measureClosed) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-x',
				title: 'MeasureClosed'
			}));
		}
		if (this.measureCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check red',
				title: 'MeasureCompleted'
			}));
		}
		if (!this.measureCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check',
				title: 'MeasureCompleted'
			}));
		}
		if (this.urgentMeasureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus-fill red',
				title: 'UrgentMeasureCreated'
			}));
		}
		if (!this.urgentMeasureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus-fill',
				title: 'UrgentMeasureCreated'
			}));
		}
		return notifications;
	}

	get notificationsEditableInManualMeasureAssignment() {
		const notifications: RuleObject[] = [];
		if (this.measureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus red',
				title: 'MeasureCreatedSingular'
			}));
		}
		if (!this.measureCreated) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-plus',
				title: 'MeasureCreatedSingular'
			}));
		}
		if (this.measureClosed) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-x red',
				title: 'MeasureClosedSingular'
			}));
		}
		if (!this.measureClosed) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-x',
				title: 'MeasureClosedSingular'
			}));
		}
		if (this.measureCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check red',
				title: 'MeasureCompletedSingular'
			}));
		}
		if (!this.measureCompleted) {
			notifications.push(new RuleObject({
				icon: 'bi-clipboard-check',
				title: 'MeasureCompletedSingular'
			}));
		}
		return notifications;
	}

	constructor(subscriber?: Partial<Subscriber>) {
		super(subscriber);
		Object.assign(this, subscriber);
	}
}

export class CultureSettings {
	countries: UserSettingsItem[] = [];
	countryCodes: UserSettingsItem[] = [];
	cultures: UserSettingsItem[] = [];
	timeZones: UserSettingsItem[] = [];

	constructor(cultureSettings: Partial<CultureSettings>) {
		Object.assign(this, cultureSettings);
		this.mapItems();
	}

	mapItems() {
		this.countries = this.countries.map(country => new UserSettingsItem(country));
		this.countryCodes = this.countryCodes.map(countryCode => new UserSettingsItem(countryCode));
		this.cultures = this.cultures.map(culture => new UserSettingsItem(culture));
		this.timeZones = this.timeZones.map(timeZone => new UserSettingsItem(timeZone));
		this.timeZones.forEach(timeZone => {
			const utc = timeZone.text.slice(1, timeZone.text.indexOf(')'));
			let cities = timeZone.text.slice(timeZone.text.indexOf(' ') + 1);
			cities = `${cities}`;
			timeZone.text = `${utc} - ${cities}`;
			return timeZone;
		});
	}
}

export class UserSettingsItem {
	id: string = '';
	text: string = '';

	constructor(userSettingsItem: Partial<UserSettingsItem>) {
		Object.assign(this, userSettingsItem);
	}
}

export class InvitedUser extends User {
	code: string;
	oldPassword: string = '';
	newPassword: string = '';

	constructor(invitedUser: Partial<InvitedUser>) {
		super(invitedUser);
		Object.assign(this, invitedUser);
	}

	toPayloadObject() {
		return new InvitedUser({
			firstname: this.firstname,
			lastname: this.lastname,
			newPassword: this.newPassword,
			confirmPassword: this.confirmPassword,
			code: this.code
		});
	}
}

export class OrganizationMembership {
	organization: Organization;
	roles: string[] = [];
	status: string = StringUtils.ACTIVE;
	deleted: string = '';
	inviteDate: string;
	actionsOpened: boolean;
	organizationId: number;

	constructor(organizationMembership: Partial<OrganizationMembership>) {
		Object.assign(this, organizationMembership);
		if (this.organization) {
			this.organization = new Organization(this.organization);
		}
	}
}
