import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MediaUtils } from 'app-core/shared-core/tools/media-utils';
import { MediaResolver } from '../resolver/media-resolver';

/**
 * This component works as a wrapper for the pdf viewer. It will resolve a pdf based on passed in url and options.
 * It also has added extensive functionality like error handling if a pdf fails to load.
 */
@Component({
	selector: 'extensive-pdf',
	templateUrl: './extensive-pdf.component.html',
	styleUrls: ['./extensive-pdf.component.less']
})
export class ExtensivePdfComponent implements OnInit, OnChanges {

	fullUrl: string;
	isLoading: boolean;

	@Input() pdfUrl: string;
	@Input() blob: Blob;
	@Input() thumbnailVersion: boolean;
	@Input() displayLoader: boolean;

	@ViewChild('errorDivEl', { read: ElementRef, static: true }) errorDivEl: ElementRef;

	constructor(
		private mediaResolver: MediaResolver) { }

	get errorDiv(): HTMLDivElement {
		return this.errorDivEl.nativeElement;
	}

	ngOnInit() {
		if (this.displayLoader) {
			this.isLoading = true;
		}
	}

	ngOnChanges() {
		this.refresh();
	}

	refresh() {
		if (this.blob) {
			const blobUrl = URL.createObjectURL(this.blob);
			this.fullUrl = blobUrl;
		} else {
			const resolvedUrl = this.mediaResolver.resolve(this.pdfUrl);
			this.fullUrl = resolvedUrl;
		}
	}

	setErrorImage() {
		this.errorDiv.style.backgroundImage = `url(${MediaUtils.GRAPHICS_ERROR_IMAGE_BROKEN_LINK})`;
		this.errorDiv.classList.add('visible');
		if (this.displayLoader) {
			this.isLoading = false;
		}
	}

	setPdf() {
		this.errorDiv.classList.remove('visible');
		if (this.displayLoader) {
			this.isLoading = false;
		}
	}
}
