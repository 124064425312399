import { Injectable } from '@angular/core';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { Filter } from 'app-core/shared-core/filter';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Schedule } from 'app-inspection/schedule/schedule';
import { BaseService } from '../shared-core/abstract-components/service/base.service';
import { HostedHttpClientService } from '../shared-core/hosted-httpclient.service';
import { PostImage } from '../shared-core/post-image';
import { CultureSettings, User, UserPayload, UserSettingsItem } from './user';

@Injectable()
export class UserService extends BaseService<User> {

	cultureSettings: CultureSettings;
	roles: UserSettingsItem[] = [];

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
		super(
			hostedHttpClient,
			RoutesUtils.user
		);
		this.useCoreApi = true;
	}

	deleteWithOrgId(id: string, orgId: number) {
		return this.hostedHttpClient.delete(`organization/${orgId}/${this.prefix}/${id}`, {}, true);
	}

	getDeleteData(itemIds: string[], orgId: number) {
		const model = {
			itemIds: itemIds
		};
		return this.hostedHttpClient.post(`organization/${orgId}/${this.prefix}/deleteCount`, model, true);
	}

	deleteRangeWithOrgId(itemIds: string[], orgId: number) {
		const model = {
			itemIds: itemIds
		};
		return this.hostedHttpClient.delete(`organization/${orgId}/${this.prefix}`, model, true);
	}

	getStatusData(itemIds: string[], status: boolean, orgId: number) {
		const model = {
			itemIds: itemIds,
			active: status,
			organizationId: orgId
		};
		return this.hostedHttpClient.post(`${this.prefix}/updatestatusdata`, model, true);
	}

	updateStatusWithOrgId(itemIds: string[], status: boolean, orgId: number) {
		const model = {
			itemIds: itemIds,
			active: status,
			organizationId: orgId
		};
		return this.hostedHttpClient.put(`${this.prefix}/statuses`, model, true);
	}

	updateProfileImage(id: string, img: PostImage) {
		const formData = new FormData();
		formData.append('file', img.blob, img.name);
		return this.hostedHttpClient.putWithHeaders(`${this.prefix}/${id}/profilepicture`, formData, { ContentType: 'upload-file' }, true);
	}

	invite(item: User) {
		return this.hostedHttpClient.post(`${this.prefix}/invite`, item, true);
	}

	register(userId: string, userCulture: string, user: UserPayload) {
		return this.hostedHttpClient.put(`${this.prefix}/${userId}/activate?culture=${userCulture}`, user, true);
	}

	handleInvitation(email: string, code: string, culture: string, acceptInvitation: boolean) {
		return this.hostedHttpClient.put(`organizationMember/invitation`, { email: email, code: code, culture: culture, acceptInvitation: acceptInvitation }, true);
	}

	resendInvite(user: UserPayload, orgId: number) {
		return this.hostedHttpClient.post(`${this.prefix}/${user.id}/resendinvite/${orgId}`, { email: user.email }, true);
	}

	updatePassword(oldP: string, newP: string, confirmP: string) {
		const model = {
			oldPassword: oldP,
			newPassword: newP,
			confirmPassword: confirmP
		};
		return this.hostedHttpClient.put(`${this.prefix}/password`, model, true);
	}

	updatePasswordWithOtherUserId(newP: string, confirmP: string, otherUserId: string) {
		const model = {
			newPassword: newP,
			confirmPassword: confirmP,
			id: otherUserId
		};
		return this.hostedHttpClient.put(`${this.prefix}/setpassword`, model, true);
	}

	getCultureSettings(): Promise<CultureSettings> {
		return this.hostedHttpClient.get(`${this.prefix}/culturesettings`, {}, true)
			.then(res => new CultureSettings(res.data));
	}

	getRoles(): Promise<UserSettingsItem[]> {
		return this.hostedHttpClient.get('role', {}, true)
			.then(res => res.data.map((role: UserSettingsItem) => new UserSettingsItem(role)));
	}

	deleteLoggedInUser() {
		// return this.hostedHttpClient.delete(`${this.prefix}/currentUser`);
		return new Promise((resolve, reject) => {
			reject({ errorMessage: 'Not implemented yet' });
		});
	}

	getPersonalData() {
		return this.hostedHttpClient.get(`${this.prefix}/personal-info`, {});
	}

	getPdfs(id: string): Promise<MediaWidgetItem[]> {
		return this.hostedHttpClient.get(`${this.prefix}/${id}/media`, {})
			.then(res => res.data.map(item => new MediaWidgetItem(item)));
	}

	uploadPdfs(id: string, items: MediaWidgetItem[]) {
		const formData = new FormData();
		items.forEach(item => formData.append('file', item.blob, item.title));
		return this.hostedHttpClient.postWithHeaders(`${this.prefix}/${id}/media/upload`, formData, { ContentType: 'upload-file' });
	}

	deletePdfs(id: string, orgId: number, itemIds: string[]) {
		const model = {
			userId: id,
			organizationId: orgId,
			itemIds: itemIds
		};
		return this.hostedHttpClient.delete(`${this.prefix}/media`, model);
	}

	async getSchedules(id: string) {
		return this.hostedHttpClient.get(`${this.prefix}/${id}/schedule`, {})
			.then(res => res.data.map((item: Schedule) => new Schedule(item)));
	}

	instantiateModel(item: User) {
		return new User(item);
	}
}

export class UserFilter extends Filter {
	toPayloadObject() {
		const tempFacets = {};
		const currentFacets = (Object.entries(this.facets) as [string, string][])
			.map(facet => {
				return {
					key: facet[0],
					value: facet[1]
				};
			});

		currentFacets.forEach(facet => {
			if (facet.key === StringUtils.STATUSES_KEY) {
				const statuses = facet.value;
				tempFacets[StringUtils.STATUS_KEY] = statuses;
			} else if (facet.key === StringUtils.STATUS_KEY) {
				delete this.facets[facet.key];
			}
		});

		Object.assign(this.facets, tempFacets);

		return {
			filter: JSON.stringify(this)
		};
	}
}
