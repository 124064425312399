<simple-crud-modal
	[titleText]="'DfRespons' | translate"
	[closeText]="'Close' | translate"
	[submitText]="(isEdit ? 'Update' : 'Create') | translate"
	[disableSubmit]="!everythingIsValid() || !hasUnsavedChanges()"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab [customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-card-text'"
						[title]="'Details' | translate"
						[helpText]="'DfResponsChangeDetailsHelpText' | translate">
					</simple-tab-heading>
				</ng-template>
				<change-df-respons-details-tab
					[changeModel]="modifiedModel">
				</change-df-respons-details-tab>
			</tab>
		</tabset>
</simple-crud-modal>
