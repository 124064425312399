import { KeyValuePair } from '../../../../filter';
import { SimpleFilterInput } from './input-settings/simple-filter-input-settings';

export class SimpleFilterConfig<T> {

	initialFacets: KeyValuePair[] = [];
	filterInputs: SimpleFilterInput[] = [];

	sortObjects: SortObject<T>[] = [];

	hideSearch: boolean = false;

	setInitialFacets(...initialFacets: KeyValuePair[]) {
		this.initialFacets = initialFacets;
	}

	setFilterInputs(...filterInputs: SimpleFilterInput[]) {
		this.filterInputs = filterInputs.filter(filterInput => filterInput != null);
	}

	setSortObjects(...sortObjects: SortObject<T>[]) {
		this.sortObjects = sortObjects.filter(sortObject => sortObject != null);
	}
}

export class SortObject<T> {
	sortProperty: string;
	label: string;
	shouldBeDefault: boolean;
	reversed: boolean;

	constructor(sortProperty: keyof T, label: string, shouldBeDefault?: boolean, reversed?: boolean) {
		this.sortProperty = sortProperty ? sortProperty as string : '';
		this.label = label;
		this.shouldBeDefault = shouldBeDefault ? shouldBeDefault : false;
		this.reversed = reversed ? reversed : false;
	}
}
