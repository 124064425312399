import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'simple-rules',
	templateUrl: './simple-rules.component.html',
	styleUrls: ['./simple-rules.component.less']
})
export class SimpleRulesComponent {

	@Input() rules: RuleObject[];
	@Input() editable: boolean;
	@Input() shouldStopPropagationOnClick: boolean;

	@Output() onRuleClick = new EventEmitter<RuleObject>();

	constructor(private cdRef: ChangeDetectorRef) { }

	triggerViewChanges() {
		this.cdRef.detectChanges();
	}

	handleRuleClick(rule: RuleObject, event: MouseEvent) {
		if (this.shouldStopPropagationOnClick) {
			event.stopImmediatePropagation();
		}
		this.onRuleClick.emit(rule);
	}
}

export class RuleObject {
	icon: string = '';
	title: string = '';

	constructor(ruleObject: Partial<RuleObject>) {
		Object.assign(this, ruleObject);
	}
}
