<simple-handle-tab
	[headerTitleText]="'AddedAssignmentTemplates' | translate"
	[headerSubtitleText]="headerSubtitleText"
	[headerAddButtonText]="'AddAssignmentTemplates' | translate"
	[allAreSelected]="allAreSelected"
	[disableRemoveSelected]="!someAreSelected"
	[disableSelectAll]="!getItems().length"
	[selectedCount]="getSelectedCount()"
	[disableSearch]="!handleItems.length"
	(onAddButtonClick)="toggleListAssignmentTemplateModal()"
	(onRemoveSelected)="removeSelected()"
	(onSelectAll)="selectAll()"
	(onSearch)="handleSearch($event)">
		<ng-container slot="itemList">
			<cdk-virtual-scroll-viewport itemSize="{{ itemSize }}" minBufferPx="999" maxBufferPx="1000" class="apx-scrollbar" scrollShadow>
				<simple-item *cdkVirtualFor="let item of getItems(); let index = index; cdkVirtualForTrackBy: trackByFn"
					[item]="item"
					[noBorder]="index === handleItems.length - 1"
					[hideRemoveButton]="readonlyItems"
					[hideSelectButton]="readonlyItems"
					[url]="item.url"
					[name]="item.name"
					[height]="itemSize"
					(onRemove)="remove($event)"
					(onSelect)="select($event)">
						<ng-container slot="content">
							<div class="details">
								<div class="name" title="{{ item.templateType.name }}" *ngIf="item.templateType">
									{{ item.templateType.name }}
								</div>
								<div class="description" title="{{ item.facility.name }}" *ngIf="item.facility">
									{{ item.facility.name }}
								</div>
							</div>
							<div class="publish-directly-text" *ngIf="item.publishDirectly"
								[title]="'PublishesDirectly' | translate">
									{{ 'PublishesDirectly' | translate }}
							</div>
							<div class="icons">
								<div class="bi bi-bounding-box-circles" *ngIf="item.geoControlled"
									title="{{ 'Geofencing' | translate }}">
								</div>
							</div>
							<div class="status">
								<simple-status-label
									[text]="item.hasActiveTemplateObjects ? 'Active' : 'Inactive'">
								</simple-status-label>
							</div>
						</ng-container>
						<ng-container slot="actions">
							<div class="quantity-counter">
								<button class="btn btn-transparent decrease-btn"
									[title]="'DecreaseQuantity' | translate"
									(click)="decreaseQuantity(item)">
									-
								</button>
								<input type="number" class="value"
									[ngModel]="item.quantity"
									[title]="'ChangeQuantity' | translate"
									(ngModelChange)="handleModelChange(item, $event)"
									(focus)="handleFocus(item)"
									(blur)="handleBlur(item)"
									(wheel)="handleWheel(item, $event)"
									min="1"
								/>
								<button class="btn btn-transparent increase-btn"
									[title]="'IncreaseQuantity' | translate"
									(click)="increaseQuantity(item)">
									+
								</button>
							</div>
							<div class="publish-directly" *ngIf="loggedInUser | isSuperAdmin"
								[title]="'PublishDirectly' | translate"
								(click)="togglePublishDirectly(item)">
									<div class="bi icon"
										[ngClass]="{
											'bi-file-earmark-arrow-up': !item.publishDirectly,
											'bi-file-earmark-arrow-up-fill': item.publishDirectly
										}">
									</div>
							</div>
						</ng-container>
				</simple-item>
				<simple-no-items *ngIf="!handleItems.length && !searchValue"
					[title]="'NoAssignmentTemplatesAdded' | translate">
				</simple-no-items>
				<simple-no-items *ngIf="!getItems().length && searchValue"
					[title]="'NoResultsMatchingSearch' | translate">
				</simple-no-items>
			</cdk-virtual-scroll-viewport>
		</ng-container>
</simple-handle-tab>
