<simple-change-tab
	[form]="form">
		<div class="change-section" *ngIf="canChangeDetails">
			<div class="change-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-input *ngIf="isEdit || !isInvite"
				[label]="'Firstname' | translate"
				[name]="propertyStrings.firstname"
				[form]="form"
				[maxlength]="25"
				[serverErrors]="serverErrors"
				[focusOnInit]="!isEdit && !isInvite">
			</simple-form-input>

			<simple-form-input *ngIf="isEdit || !isInvite"
				[label]="'Lastname' | translate"
				[name]="propertyStrings.lastname"
				[form]="form"
				[maxlength]="25"
				[serverErrors]="serverErrors">
			</simple-form-input>

			<simple-form-input
				[label]="'EmailAddress' | translate"
				[name]="propertyStrings.email"
				[form]="form"
				[serverErrors]="serverErrors"
				[focusOnInit]="!isEdit && isInvite">
			</simple-form-input>

			<div class="form-control-wrapper" *ngIf="isEdit && canChangeDetails">
				<simple-form-select
					[label]="'CountryCode' | translate"
					[name]="propertyStrings.contactCountryCode"
					[form]="form"
					[items]="selectableCountryCodeItems"
					[serverErrors]="serverErrors">
				</simple-form-select>

				<simple-form-input class="stretch"
					[label]="'PhoneNumber' | translate"
					[name]="propertyStrings.contactPhoneNumber"
					[form]="form"
					[serverErrors]="serverErrors">
				</simple-form-input>
			</div>

			<simple-form-select *ngIf="isEdit"
				[label]="'Country' | translate"
				[name]="propertyStrings.country"
				[form]="form"
				[items]="selectableCountryItems"
				[searchable]="true"
				[serverErrors]="serverErrors">
			</simple-form-select>
		</div>

		<div class="change-section" *ngIf="isEdit && canChangeDetails">
			<div class="change-section-header">
				{{ 'Settings' | translate }}
			</div>
			<simple-form-select
				[label]="'Language' | translate"
				[name]="propertyStrings.culture"
				[form]="form"
				[items]="selectableCultureItems"
				[serverErrors]="serverErrors">
			</simple-form-select>

			<simple-form-select
				[label]="'Timezone' | translate"
				[name]="propertyStrings.timeZone"
				[form]="form"
				[items]="selectableTimeZoneItems"
				[searchable]="true"
				[serverErrors]="serverErrors">
			</simple-form-select>
		</div>

		<div class="change-section" *ngIf="isEdit && canChangeDetails">
			<div class="change-section-header">
				{{ 'Certificate' | translate }}
			</div>
			<simple-form-input
				[label]="'CertificateNumber' | translate"
				[name]="propertyStrings.certificates"
				[form]="form"
				[maxlength]="50"
				[serverErrors]="serverErrors">
			</simple-form-input>
			<simple-form-info
				[firstText]="'CertificateInfoText' | translate">
			</simple-form-info>
			<media-widget
				[collection]="documents"
				[onlyPdfUpload]="true"
				[useHeaderInBottom]="true"
				(onCollectionChange)="handleMediaCollectionChange($event)">
			</media-widget>
		</div>

		<div class="change-section" *ngIf="canChangeAuthorization">
			<div class="change-section-header">
				{{ 'Authorization' | translate }}
			</div>
			<simple-form-select
				[label]="'Role' | translate"
				[name]="propertyStrings.rolesTemp"
				[form]="form"
				[items]="selectableRoleItems"
				[multiple]="true"
				[serverErrors]="serverErrors">
			</simple-form-select>

			<simple-form-select *ngIf="isEdit"
				[label]="'Status' | translate"
				[name]="propertyStrings.statusTemp"
				[form]="form"
				[items]="selectableStatusItems"
				[redBackground]="changeModel.statusTemp === stringUtils.INACTIVE"
				[serverErrors]="serverErrors">
			</simple-form-select>
		</div>

		<div class="change-section" *ngIf="!isEdit && !isInvite">
			<div class="change-section-header">
				{{ 'Security' | translate }}
			</div>
			<simple-form-input
				[label]="'Password' | translate"
				[name]="propertyStrings.password"
				[form]="form"
				[serverErrors]="serverErrors"
				[usePasswordType]="true">
			</simple-form-input>

			<simple-form-input
				[label]="'ConfirmPassword' | translate"
				[name]="propertyStrings.confirmPassword"
				[form]="form"
				[serverErrors]="serverErrors"
				[usePasswordType]="true">
			</simple-form-input>
		</div>
</simple-change-tab>
