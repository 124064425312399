
<!-- Displays the list as a regular page -->
<ng-container *ngIf="!isInModal">
	<ng-container *ngTemplateOutlet="listContent">
	</ng-container>
</ng-container>

<!-- Displays the list inside a modal  -->
<ng-container *ngIf="isInModal">
	<simple-list-modal
		[listReference]="listReference">
		<ng-container *ngTemplateOutlet="listContent">
		</ng-container>
	</simple-list-modal>
</ng-container>


<!-- The list -->
<ng-template #listContent>
	<simple-table
		[listActions]="getListActions()"
		[hideListActions]="hideListActions"
		[filterConfig]="filterConfig"
		[totalCount]="totalCount"
		[selectedCount]="selectedIds.size"
		[isInModal]="isInModal"
		[isCompactView]="isCompactView"
		[pending]="pending"
		[tableConfig]="tableConfig"
		[dataSource]="dataSource"
		[allSelected]="allSelected"
		[someSelected]="someSelected"
		[sortProperty]="sortProperty"
		[sortLabel]="sortLabel"
		[sortReversed]="sortReversed"
		[hasSelectableData]="hasSelectableData()"
		[searchValue]="filterObject.search"
		[filterInputs]="filterConfig.filterInputs"
		(onListActionClick)="handleListActionClick($event)"
		(onSearch)="handleSearch($event)"
		(onFilter)="handleFilter($event)"
		(onSearchClear)="handleSearchClear()"
		(onFilterClear)="handleFilterClear()"
		(onSelectedClear)="handleSelectedClear()"
		(onSort)="handleSort($event)"
		(onHeaderActionClick)="handleHeaderActionClick($event)"
		(onHeaderCheckboxClick)="handleHeaderCheckboxClick()"
		(onRowActionClick)="handleRowActionClick($event)"
		(onRowCheckboxClick)="handleRowCheckboxClick($event)"
		(onRowClick)="handleRowClick($event)"
		(onRowDoubleClick)="handleRowDoubleClick($event)">
	</simple-table>
	<input #importInput type="file"
		class="u-display-none"
		[accept]="acceptedMediaTypesForImport"
		(change)="handleImportInputChange($event)" />
</ng-template>
