import { Injectable } from '@angular/core';
import { BaseService } from 'app-core/shared-core/abstract-components/service/base.service';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Filter, KeyValuePair, SortDirection } from '../../app-core/shared-core/filter';
import { Utils } from '../../app-core/shared-core/tools/utils';
import { Facility } from '../facility/facility';
import { Entity } from './entity';

@Injectable()
export class EntityService extends BaseService<Entity> {

	facilityId: string;

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
		super(
			hostedHttpClient,
			RoutesUtils.entity
		);
	}

	getFiltered(filter: Filter) {
		if (this.facilityId) {
			const facilityId = this.facilityId === 'useNull' ? null : this.facilityId;
			return this.getFilteredFromFacility(facilityId, filter);
		} else {
			return super.getFiltered(filter);
		}
	}

	getFilteredIds(filter: Filter) {
		if (this.facilityId) {
			const facilityId = this.facilityId === 'useNull' ? null : this.facilityId;
			return this.getFilteredIdsFromFacility(facilityId, filter);
		} else {
			return super.getFilteredIds(filter);
		}
	}

	updateAssignmentTemplates(entityObjects: object[]) {
		return this.hostedHttpClient.put(`${this.prefix}/assignmenttemplates`, entityObjects);
	}

	async getFilteredFromFacility(facilityId: string, filter: Filter): Promise<Entity[]> {
		return this.hostedHttpClient.get(`${this.prefix}/filtered/${facilityId}`, filter.toPayloadObject())
			.then(res => res.data.map((item: Entity) => this.instantiateModel(item)));
	}

	async getFilteredIdsFromFacility(facilityId: string, filter: Filter): Promise<string[]> {
		return this.hostedHttpClient.get(`${this.prefix}/ids/${facilityId}`, filter.toPayloadObject())
			.then(res => res.data);
	}

	async getFacilities(): Promise<Facility[]> {
		const filter = new Filter();
		filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		filter.addSort(
			new KeyValuePair(
				Utils.uppercaseEachFirst(Utils.nameof<Facility>('name')),
				SortDirection.Ascending
			)
		);
		return this.hostedHttpClient.get(`facility/filteredac`, filter.toPayloadObject())
			.then(res => res.data.map((item: Facility) => new Facility(item)));
	}

	updateAccessibility(itemIds: string[], accessible: boolean) {
		const model = {
			itemIds: itemIds,
			accessible: accessible
		};
		return this.hostedHttpClient.put(`${this.prefix}/accessible`, model, this.useCoreApi);
	}

	instantiateModel(item: Entity) {
		return new Entity(item);
	}
}

export class EntityFilter extends Filter {
	toPayloadObject() {
		const tempFacets = {};
		const currentFacets = (Object.entries(this.facets) as [string, string][])
			.map(facet => {
				return {
					key: facet[0],
					value: facet[1]
				};
			});

		currentFacets.forEach(facet => {
			if (facet.key === StringUtils.HAS_FACILITY_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.HAS_FACILITY_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.HAS_FACILITY_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.LOCATION_DATA_KEY) {
				const coordinates = facet.value;
				if (coordinates === StringUtils.YES) {
					tempFacets[StringUtils.HAS_POSITION_KEY] = 'true';
				} else if (coordinates === StringUtils.NO) {
					tempFacets[StringUtils.HAS_POSITION_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.HAS_POSITION_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.FACILITIES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.FACILITY_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.FACILITY_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.STATUS_KEY) {
				const status = facet.value;
				if (status === StringUtils.ACTIVE) {
					tempFacets[StringUtils.STATUS_KEY] = 'true';
				} else if (status === StringUtils.INACTIVE) {
					tempFacets[StringUtils.STATUS_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.ACCESSIBILITY_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.ACCESSIBLE_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.ACCESSIBLE_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.ACCESSIBLE_KEY) {
				delete this.facets[facet.key];
			}
		});

		Object.assign(this.facets, tempFacets);

		return {
			filter: JSON.stringify(this)
		};
	}
}
