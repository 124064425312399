<simple-crud-modal
	[titleText]="'Schedule' | translate"
	[closeText]="'Close' | translate"
	[submitText]="getSelectedCountText('Update' | translate)"
	[disableSubmit]="!everythingIsValid() || !affectableIdsAreValid()"
	[isLoading]="pending"
	[displayDummy]="!initialModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="initialModel">
			<tab #handleTab="tab" [customClass]="'valid'">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-people'"
						[title]="'Groups' | translate"
						[helpText]="'ScheduleHandleGroupsBulkHelpText' | translate"
						[displayCount]="initialModel.scheduleGroups.length">
					</simple-tab-heading>
				</ng-template>
				<handle-schedule-groups-tab
					[handleItems]="initialModel.scheduleGroups"
					[headerSubtitleText]="'AddedGroupsSubtitleScheduleMultiple' | translate"
					[readonlyListModal]="true"
					[wasSelected]="handleTab.active"
					(onModelsChange)="initialModel.scheduleGroups= $event">
				</handle-schedule-groups-tab>
			</tab>
			<tab #handleTab2="tab" [customClass]="'valid'">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-person'"
						[title]="'Assignees' | translate"
						[helpText]="'ScheduleHandleUsersBulkHelpText' | translate"
						[displayCount]="initialModel.scheduleUsers.length">
					</simple-tab-heading>
				</ng-template>
				<handle-schedule-users-tab
					[handleItems]="initialModel.scheduleUsers"
					[headerSubtitleText]="'AddedUsersSubtitleScheduleMultiple' | translate"
					[selectedScheduleGroups]="initialModel.scheduleGroups"
					[readonlyListModal]="true"
					[wasSelected]="handleTab2.active"
					(onModelsChange)="initialModel.scheduleUsers= $event">
				</handle-schedule-users-tab>
			</tab>
		</tabset>
</simple-crud-modal>
