import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { OrganizationService } from 'app-core/organization/organization.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Integration, IntegrationTypes, SSENReportIntegration } from '../integration';
import { IntegrationService } from '../integration.service';
import { ChangeDetailsTabComponent } from './tabs/change-details-tab.component';

@Component({
	templateUrl: './edit-ssen-report.component.html'
})
export class EditSSENReportComponent extends SimpleCreateEditModalDirective<SSENReportIntegration> implements OnInit {

	integrations: Integration[] = [];

	type: string;

	@ViewChild(ChangeDetailsTabComponent) changeDetailsTabComponent: ChangeDetailsTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private integrationService: IntegrationService,
		private organizationService: OrganizationService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			this.integrations = await this.integrationService.getIntegrations(this.selectedOrganization.id);
			this.integrations.sortByProperty(new Integration({}).propertyStrings.name);

			const ssenIntegration = this.integrations.find(integration => integration.type === IntegrationTypes.SSENReport);
			const templateTypes = await this.organizationService.getTemplateTypes(this.selectedOrganization.id);
			const firstSSEN = templateTypes.find(tt => tt.templateBaseType.name === 'SS-EN');

			this.initialModel = new SSENReportIntegration((ssenIntegration as any));

			if (firstSSEN) {
				this.initialModel.scope = firstSSEN.scope;
				this.initialModel.templateBaseTypeId = firstSSEN.templateBaseType.id;
			}
			this.pending = false;

			this.modifiedModel = new SSENReportIntegration(this.getUniqueVariant(this.initialModel));

			setTimeout(() => {
				this.triggerAllValidation();
			}, 0);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	changeDetailsIsValid() {
		return this.modifiedModel && !!this.modifiedModel.inAccordanceWith && !!this.modifiedModel.riskAnalysis && !!this.modifiedModel.errorCodeExplanation;
	}

	changeDetailsHasErrors() {
		return this.changeDetailsTabComponent && this.changeDetailsTabComponent.errorText;
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = await this.integrationService.update(this.modifiedModel.toPayloadObject());
			if (this.modifiedModel.scope !== this.initialModel.scope) {
				const response2 = await this.organizationService.updateTemplateTypes(this.selectedOrganization.id, this.modifiedModel.toPayloadObject());
				const firstTemplateType = response2.data[0];
				response.data.scope = firstTemplateType.scope;
			}
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.changeDetailsTabComponent.triggerValidation();
	}

	protected instantiateModel(item: SSENReportIntegration) {
		return new SSENReportIntegration(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.inAccordanceWith !== this.initialModel.inAccordanceWith
			|| this.modifiedModel.scope !== this.initialModel.scope
			|| this.modifiedModel.riskAnalysis !== this.initialModel.riskAnalysis
			|| this.modifiedModel.errorCodeExplanation !== this.initialModel.errorCodeExplanation
			|| this.modifiedModel.serenoReference !== this.initialModel.serenoReference;
	}

	hasUnsavedChanges() {
		return this.modifiedModel && this.detailsHasChanged();
	}
}
