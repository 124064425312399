<simple-crud-modal
	[titleText]="'Statistics' | translate"
	[closeText]="'Close' | translate"
	[isLoading]="refreshing"
	(onClose)="closeModal()">
	<div class="extra-actions refresh-action-in-header" extraActions>
		<span class="refresh-date">{{ advancedStatisticsView?.refreshed }}</span>
		<div class="refresh"
			[ngClass]="{'disabled': refreshing}"
			title="{{ !refreshing ? ('RefreshStatistics' | translate) : '' }}"
			(click)=" !refreshing ? refresh.emit($event) : false">
			<i class="icon" [ngClass]="{'refreshing': refreshing}"></i>
		</div>
	</div>
	<div class="statistics in-modal">
		<ng-container *ngTemplateOutlet="dataTemplate"></ng-container>
		<ng-container *ngTemplateOutlet="pendingTemplate"></ng-container>
		<ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
	</div>
</simple-crud-modal>

<!-- Data template -->
<ng-template #dataTemplate>
	<ng-container *ngIf="!refreshing && !error;">
		<div *ngIf="advancedStatisticsView" class="statistics-data">
			<ng-container *ngFor="let dataByStatisticsType of advancedStatisticsView.assignmentStatisticsByType; trackBy: trackByFn">
				<ng-container *ngIf="dataByStatisticsType.data.length">
					<ng-container *ngTemplateOutlet="
						statisticsSection;
						context: {
							dataByStatisticsType: dataByStatisticsType,
							referenceType: 'Assignment'
						}"></ng-container>
				</ng-container>
			</ng-container>

			<ng-container *ngFor="let dataByStatisticsType of advancedStatisticsView.organizationStatisticsByType; trackBy: trackByFn">
				<ng-container *ngIf="dataByStatisticsType.data.length">
					<ng-container *ngTemplateOutlet="
						statisticsSection;
						context: {
							dataByStatisticsType: dataByStatisticsType,
							referenceType: 'Organization'
						}"></ng-container>
				</ng-container>
			</ng-container>

			<ng-container *ngFor="let dataByStatisticsType of advancedStatisticsView.userStatisticsByType; trackBy: trackByFn">
				<ng-container *ngIf="dataByStatisticsType.data.length">
					<ng-container *ngTemplateOutlet="
						statisticsSection;
						context: {
							dataByStatisticsType: dataByStatisticsType,
							referenceType: 'User'
						}"></ng-container>
				</ng-container>
			</ng-container>
		</div>
	</ng-container>

	<ng-template #statisticsSection let-dataByStatisticsType="dataByStatisticsType" let-referenceType="referenceType">
		<div class="section">
			<h4 class="type h5">
				{{ statisticsTypesEnum[dataByStatisticsType.type] + referenceType + "Statistics" | translate }}

				<ng-container *ngFor="let newData of newData$ | async; trackBy: trackByFn">
					<div *ngIf="newData?.type === dataByStatisticsType.type && newData.value > 0"
						class="label label-default new-data-notification {{ statisticsTypesEnum[dataByStatisticsType.type] | lowercase }} {{ newData.class }}"
						title="{{ newData.value + ' ' + newData.title }}">
						<span>{{ newData.value }} {{ (newData.value > 1 ? 'NewMultiple' : 'NewSingular') | translate }}</span>
					</div>
				</ng-container>
			</h4>

			<div class="statistics-by-dates"
				*ngIf="dataByStatisticsType.combinedYears.length ||
					dataByStatisticsType.combinedMonths.length ||
					dataByStatisticsType.combinedDays.length">
				<span class="header">{{ "HighestSoFar" | translate }}:</span>

				<a class="value-section year"
					title="{{ 'ShowAllYears' | translate }}"
					*ngIf="dataByStatisticsType.combinedYears.length"
					(click)="getStatisticsByDateData(
						dataByStatisticsType.combinedYears[0].combinedDiscriminators,
						dataByStatisticsType.combinedYears[0].type,
						dateTypeEnum.Year,
						statisticsByDateDataModal)">
					<span class="value-label">{{ "Year" | translate }}</span>
					<span class="value-data date">
						{{ dataByStatisticsType.combinedYears[0].date }}
					</span>
					<span class="value-data total">
						{{ dataByStatisticsType.combinedYears[0].total.toLocaleString(locale) }}
					</span>
					<span class="value-label">{{ "PiecesShort" | translate }}</span>
				</a>
				<a class="value-section month"
					title="{{ 'ShowAllMonths' | translate }}"
					*ngIf="dataByStatisticsType.combinedMonths.length"
					(click)="getStatisticsByDateData(
						dataByStatisticsType.combinedMonths[0].combinedDiscriminators,
						dataByStatisticsType.combinedMonths[0].type,
						dateTypeEnum.Month,
						statisticsByDateDataModal)">
					<span class="today-date"
						*ngIf="dataByStatisticsType.combinedMonths[0].isTodayDate"
						title="{{ 'BestMonthSoFar' | translate }}">
						&#9733;
					</span>
					<span class="value-label">{{ "Month" | translate }}</span>
					<span class="value-data date">
						{{ dataByStatisticsType.combinedMonths[0].date }}
					</span>
					<span class="value-data total">
						{{ dataByStatisticsType.combinedMonths[0].total.toLocaleString(locale) }}
					</span>
					<span class="value-label">{{ "PiecesShort" | translate }}</span>
				</a>
				<a class="value-section day"
					title="{{ 'ShowAllDays' | translate }}"
					*ngIf="dataByStatisticsType.combinedDays.length"
					(click)="getStatisticsByDateData(
						dataByStatisticsType.combinedDays[0].combinedDiscriminators,
						dataByStatisticsType.combinedDays[0].type,
						dateTypeEnum.Day,
						statisticsByDateDataModal)">
					<span class="today-date"
						*ngIf="dataByStatisticsType.combinedDays[0].isTodayDate"
						title="{{ 'BestDaySoFar' | translate }}">
						&#9733;
					</span>
					<span class="value-label">{{ "Day" | translate }}</span>
					<span class="value-data date">
						{{ dataByStatisticsType.combinedDays[0].date }}
					</span>
					<span class="value-data total">
						{{ dataByStatisticsType.combinedDays[0].total.toLocaleString(locale) }}
					</span>
					<span class="value-label">{{ "PiecesShort" | translate }}</span>
				</a>
			</div>

			<div class="section-inner" *ngFor="let dataByType of dataByStatisticsType.data; trackBy: trackByFn">
				<div class="section-inner-description" *ngIf="dataByStatisticsType.data[0] === dataByType">
					<div class="description-value description flex-none">
						<span class="desktop mobile">{{ 'Type' | translate }}</span>
					</div>
					<div class="description-value description flex-1" title="{{ spanTypesEnum[dataByType.data[0].type] | translate }}">
						<span>{{ spanTypesEnum[dataByType.data[0].type] + 'Short' | translate }}</span>
						<span class="mobile">{{ spanTypesEnum[dataByType.data[0].type] + 'ShortMobile' | translate }}</span>
					</div>
					<div class="description-value description flex-1" title="{{ spanTypesEnum[dataByType.data[1].type] | translate }}">
						<span>{{ spanTypesEnum[dataByType.data[1].type] + 'Short' | translate }}</span>
						<span class="mobile">{{ spanTypesEnum[dataByType.data[1].type] + 'ShortMobile' | translate }}</span>
					</div>
					<div class="description-value description flex-1" title="{{ spanTypesEnum[dataByType.data[2].type] | translate }}">
						<span>{{ spanTypesEnum[dataByType.data[2].type] + 'Short' | translate }}</span>
						<span class="mobile">{{ spanTypesEnum[dataByType.data[2].type] + 'ShortMobile' | translate }}</span>
					</div>
					<div class="description-value description flex-1" title="{{ spanTypesEnum[dataByType.data[3].type] | translate }}">
						<span>{{ spanTypesEnum[dataByType.data[3].type] + 'Short' | translate }}</span>
						<span class="mobile">{{ spanTypesEnum[dataByType.data[3].type] + 'ShortMobile' | translate }}</span>
					</div>
					<div class="description-value description flex-none" title="{{ spanTypesEnum[dataByType.data[4].type] | translate }}">
						<span class="desktop mobile">{{ spanTypesEnum[dataByType.data[4].type] | translate }}</span>
					</div>
				</div>

				<div class="section-inner-data">
					<div class="description-value type flex-none" title="{{ dataByType.type + 'Statistics' | translate }}">
						<i class="icon"
							[ngClass]="{
								'scheduled-assignment': dataByType.type === referenceTypesEnum[referenceTypesEnum.ScheduledAssignment],
								'measure-assignment': dataByType.type === referenceTypesEnum[referenceTypesEnum.MeasureAssignment],
								'organization': dataByType.type === referenceTypesEnum[referenceTypesEnum.Organization],
								'user': dataByType.type === referenceTypesEnum[referenceTypesEnum.User]
							}">
						</i>
					</div>
					<div class="description-value flex-1 flex-grow-none" *ngFor="let dataBySpanType of dataByType.data; trackBy: trackByFn"
						title="{{
							dataBySpanType.count
							&& (dataBySpanType.type !== spanTypesEnum.Total ||
								referenceType === 'Organization' ||
								referenceType === 'User')
								? ('ShowStatisticsRelatedData' | translate)
								: '' }}"
						[ngClass]="{
							'has-value': dataBySpanType.count,
							'can-get-related-data':
								dataBySpanType.count
								&& (dataBySpanType.type !== spanTypesEnum.Total ||
									referenceType === 'Organization' ||
									referenceType === 'User'),
							'first': dataBySpanType.type === spanTypesEnum.LastThirtyDays,
							'last': dataBySpanType.type === spanTypesEnum.Total
						}"
						(click)="dataBySpanType.count
							&& (dataBySpanType.type !== spanTypesEnum.Total ||
								referenceType === 'Organization' ||
								referenceType === 'User')
							&& !relatedDataButtonsDisabled
								? getRelatedData(
									dataByType.type,
									dataByStatisticsType.type,
									dataBySpanType.type,
									statisticsRelatedDataModal,
									dataBySpanType.identifier,
									referenceType === 'Organization' || referenceType === 'User'
										? 'x-large'
										: 'm-large')
								: null">
						<dots-loader class="data-loader" *ngIf="clickedRelatedStatistics === dataBySpanType.identifier"></dots-loader>
						<span class="value" *ngIf="clickedRelatedStatistics !== dataBySpanType.identifier">
							{{ dataBySpanType.count.toLocaleString(locale) }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</ng-template>

<!-- Pending template -->
<ng-template #pendingTemplate>
	<ng-container *ngIf="refreshing">
		<div class="empty-state">
			<div class="section dummy-section"></div>
			<div class="section dummy-section"></div>
			<div class="section dummy-section"></div>
			<div class="section dummy-section"></div>
		</div>
	</ng-container>
</ng-template>

<!-- Error template -->
<ng-template #errorTemplate>
	<ng-container *ngIf="error">
		<div class="empty-state">
			<h4>
				{{ error | translate }}...
			</h4>
		</div>
		<div class="section dummy-section"></div>
		<div class="section dummy-section"></div>
		<div class="section dummy-section"></div>
		<div class="section dummy-section"></div>
	</ng-container>
</ng-template>

<!-- Related statistics data modal -->
<ng-template #statisticsRelatedDataModal>
	<ng-container>
		<statistics-related-data-modal
			class="statistics-modal"
			[payload]="relatedDataPayload"
			(onClose)="closeDataModal()">
		</statistics-related-data-modal>
	</ng-container>
</ng-template>

<!-- Statistics by date modal -->
<ng-template #statisticsByDateDataModal>
	<ng-container>
		<statistics-by-date-data-modal
			class="statistics-modal"
			[payload]="statisticsByTypeAndDatePayload"
			(onClose)="closeDataModal()">
		</statistics-by-date-data-modal>
	</ng-container>
</ng-template>