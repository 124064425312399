import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getImageStorageSize',
	pure: false
})
export class GetImageStorageSize implements PipeTransform {
	latestWidth: string;
	latestHeight: string;
	width: number;
	height: number;

	transform(value: HTMLDivElement) {
		const styles = getComputedStyle(value);
		if ((styles.width !== this.latestWidth) || (styles.height !== this.latestHeight)) {
			this.latestWidth = styles.width;
			this.latestHeight = styles.height;
			this.width = Number(this.latestWidth.replace('px', ''));
			this.height = Number(this.latestHeight.replace('px', ''));
		}
		return this.width === this.height ? this.width : null;
	}
}
