<simple-crud-modal
	[titleText]="'Password' | translate"
	[closeText]="'Close' | translate"
	[submitText]="'Update' | translate"
	[disableSubmit]="!everythingIsValid()"
	[isLoading]="pending"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab [customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-card-text'"
						[title]="'Details' | translate"
						[helpText]="'PasswordHelpText' | translate">
					</simple-tab-heading>
				</ng-template>
				<password-tab
					[changeModel]="modifiedModel"
					[otherUserId]="otherUserId">
				</password-tab>
			</tab>
		</tabset>
</simple-crud-modal>
