<simple-handle-tab
	[headerTitleText]="'AddedFacilities' | translate"
	[headerSubtitleText]="headerSubtitleText"
	[headerAddButtonText]="'AddFacilities' | translate"
	[hideAddButton]="hideHeaderAddButton"
	[hideRemoveSelected]="hideHeaderRemoveSelected"
	[hideSelectAll]="hideHeaderSelectAll"
	[allAreSelected]="allAreSelected"
	[disableRemoveSelected]="!someAreSelected"
	[disableSelectAll]="!getItems().length"
	[selectedCount]="getSelectedCount()"
	[disableSearch]="!handleItems.length"
	[hideSearch]="hideHeaderSearch"
	(onAddButtonClick)="toggleListFacilityModal()"
	(onRemoveSelected)="removeSelected()"
	(onSelectAll)="selectAll()"
	(onSearch)="handleSearch($event)">
		<ng-container slot="itemList">
			<cdk-virtual-scroll-viewport itemSize="{{ itemSize }}" minBufferPx="999" maxBufferPx="1000" class="apx-scrollbar" scrollShadow>
				<simple-item *cdkVirtualFor="let item of getItems(); let index = index; cdkVirtualForTrackBy: trackByFn"
					[item]="item"
					[noBorder]="index === handleItems.length - 1"
					[hideRemoveButton]="readonlyItems"
					[hideSelectButton]="readonlyItems"
					[url]="item.url"
					[name]="item.name"
					[height]="itemSize"
					(onRemove)="remove($event)"
					(onSelect)="select($event)">
						<ng-container slot="content">
							<div class="image-details-wrapper">
								<div class="item-image" #parentDiv>
									<extensive-image
										[imageUrl]="item.thumbnailUrl"
										[imageStorageSize]="parentDiv | getImageStorageSize"
										(onImageLoaded)="setupHover($event)">
									</extensive-image>
								</div>
								<div class="details">
									<div class="name" title="{{ item.name }}" *ngIf="item.name">
										{{ item.name }}
									</div>
									<div class="description" title="{{item.description}}" *ngIf="item.description">
										{{ item.description }}
									</div>
								</div>
							</div>
							<div class="icons">
								<div class="bi bi-person-wheelchair" *ngIf="item.accessible"
									title="{{ 'Accessibility' | translate }}">
								</div>
								<div class="bi bi-geo-alt-fill" *ngIf="item.hasPosition"
									title="{{ 'LocationData' | translate }}">
								</div>
							</div>
							<div class="status">
								<simple-status-label
									[text]="item.status ? 'Active' : 'Inactive'">
								</simple-status-label>
							</div>
						</ng-container>
					</simple-item>
				<simple-no-items *ngIf="!handleItems.length && !searchValue"
					[title]="'NoFacilitiesAdded' | translate">
				</simple-no-items>
				<simple-no-items *ngIf="!getItems().length && searchValue"
					[title]="'NoResultsMatchingSearch' | translate">
				</simple-no-items>
			</cdk-virtual-scroll-viewport>
		</ng-container>
</simple-handle-tab>
