<form #formRef="ngForm" class="change-bulk apx-scrollbar" scrollShadow
	[formGroup]="form">
		<ng-content></ng-content>

		<div class="change-bulk-section" *ngIf="!hideText && affectableIdsCount !== undefined">
			<simple-affectable-count-message
				[affectableIdsCount]="affectableIdsCount"
				[selectedIdsCount]="selectedIdsCount">
			</simple-affectable-count-message>
		</div>
</form>
