import { Injectable } from '@angular/core';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { Report } from './report';
import { BaseService } from 'app-core/shared-core/abstract-components/service/base.service';
import { GeneratedReport } from './generated/generated-report';
import { StaticReport } from './static/static-report';
import { Subscriber } from 'app-core/user/user';

@Injectable()
export class ReportService extends BaseService<Report> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
		super(
			hostedHttpClient,
			RoutesUtils.assignment
		);
	}

	getGeneratedReport(report: GeneratedReport) {
		return this.hostedHttpClient.post(`${this.prefix}/report`, report);
	}

	getStaticReports(report: StaticReport) {
		return this.hostedHttpClient.post(`${this.prefix}/staticreports`, report);
	}

	getReportData(itemIds: string[], orgId: number) {
		const model = {
			itemIds: itemIds
		};
		return this.hostedHttpClient.post(`${this.prefix}/reportscount?organization=${orgId}`, model);
	}

	getSubscribers(itemIds: string[], orgId: number): Promise<Subscriber[]> {
		const model = {
			itemIds: itemIds
		};
		return this.hostedHttpClient.post(`${this.prefix}/subscribers?organization=${orgId}`, model)
			.then(res => res.data.map((item: Subscriber) => new Subscriber(item)));
	}

	instantiateModel(item: Report) {
		return new Report(item);
	}
}
