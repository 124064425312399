import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { Result } from 'app-inspection/assignment/assignment';

@Component({
	selector: 'assessment-content',
	templateUrl: './assessment-content.component.html',
	styleUrls: ['./assessment-content.component.less']
})
export class AssessmentContentComponent {

	@Input() isMeasure: boolean = false;
	@Input() errorResult: Result;
	@Input() resolvementResult: Result;
	@Input() wasClosed: boolean;
	@Input() isCompleted: boolean;

	@Output() onAssessmentClicked = new EventEmitter();

	constructor() { }

	assessmentClicked() {
		this.onAssessmentClicked.emit();
	}

	getMeasureUrl() {
		return `${this.getCorrectRoute()}?${RoutesUtils.assignment}=${RoutesUtils.modalPrefixValueEdit}_${this.errorResult.measureAssignmentId}&${RoutesUtils.modalTabParam}=1`;
	}

	getCorrectRoute() {
		if (this.errorResult.solved) {
			return `${RoutesUtils.assignments}/${RoutesUtils.measureArchived}`;
		} else {
			return `${RoutesUtils.assignments}/${RoutesUtils.measureOngoing}`;
		}
	}
}
