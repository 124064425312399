<simple-change-tab
	[form]="form">
		<div class="change-section">
			<div class="change-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-input
				[label]="'Name' | translate"
				[name]="propertyStrings.name"
				[form]="form"
				[maxlength]="150"
				[serverErrors]="serverErrors"
				[focusOnInit]="!isEdit">
			</simple-form-input>

			<simple-form-input
				[label]="'Description' | translate"
				[name]="propertyStrings.description"
				[form]="form"
				[maxlength]="500"
				[serverErrors]="serverErrors"
				[useTextarea]="true">
			</simple-form-input>

			<simple-form-select
				[label]="'Accessibility' | translate"
				[name]="propertyStrings.accessible"
				[form]="form"
				[items]="selectableAccessibilityItems"
				[serverErrors]="serverErrors">
			</simple-form-select>

			<simple-form-select
				[label]="'Status' | translate"
				[name]="propertyStrings.status"
				[form]="form"
				[items]="selectableStatusItems"
				[redBackground]="!changeModel.status"
				[serverErrors]="serverErrors">
			</simple-form-select>

			<simple-form-input
				[label]="'InfoLink' | translate"
				[name]="propertyStrings.infoLink"
				[form]="form"
				[maxlength]="250"
				[serverErrors]="serverErrors">
			</simple-form-input>

			<simple-form-input *ngIf="selectedOrganization.hasDfIntegration || selectedOrganization.hasInfraControlIntegration"
				[label]="'ExternalId' | translate"
				[name]="propertyStrings.externalId"
				[form]="form"
				[maxlength]="50"
				[serverErrors]="serverErrors">
			</simple-form-input>

			<div class="label-wrapper"
				[ngClass]="{
					'focused': markdownTextAreaIsFocused
				}">
				<label>{{ 'ExtraInfo' | translate }}</label>
				<label>({{ 'Optional' | translate | lowercase }})</label>
			</div>
			<markdown-editor
				[initialTextareaValue]="changeModel.extraInfo"
				[toolbarAsPopover]="isExtraSmallScreenSize()"
				(onTextChange)="handleExtraInfoChange($event)"
				(onFocus)="handleMarkdownTextAreaFocus($event)">
			</markdown-editor>
		</div>

		<div class="change-section">
			<div class="change-section-header">
				{{ 'Adress' | translate }}
			</div>
			<simple-form-input
				[label]="'StreetName' | translate"
				[name]="propertyStrings.streetName"
				[form]="form"
				[maxlength]="50"
				[serverErrors]="serverErrors">
			</simple-form-input>

			<simple-form-input
				[label]="'StreetNumber' | translate"
				[name]="propertyStrings.streetNumber"
				[form]="form"
				[maxlength]="15"
				[serverErrors]="serverErrors">
			</simple-form-input>

			<div class="form-control-wrapper">
				<simple-form-input
					[label]="'ZipCode' | translate"
					[name]="propertyStrings.zipCode"
					[form]="form"
					[maxlength]="15"
					[serverErrors]="serverErrors">
				</simple-form-input>

				<simple-form-input class="stretch"
					[label]="'City' | translate"
					[name]="propertyStrings.city"
					[form]="form"
					[maxlength]="25"
					[serverErrors]="serverErrors">
				</simple-form-input>
			</div>
		</div>

		<div class="change-section">
			<div class="change-section-header">
				{{ 'LocationData' | translate }}
			</div>
			<div class="location-map-wrapper">
				<div class="location-wrapper">
					<div class="input-wrapper">
						<simple-form-input
							[label]="'Latitude' | translate"
							[name]="propertyStrings.latitude"
							[form]="form"
							[serverErrors]="serverErrors">
						</simple-form-input>

						<simple-form-input
							[label]="'Longitude' | translate"
							[name]="propertyStrings.longitude"
							[form]="form"
							[serverErrors]="serverErrors">
						</simple-form-input>

						<simple-form-input
							[label]="'Radius' | translate"
							[name]="propertyStrings.radius"
							[form]="form"
							[serverErrors]="serverErrors">
						</simple-form-input>
					</div>

					<div class="map-buttons">
						<button class="btn btn-transparent bi-postcard"
							[ngClass]="{
								'disabled': !validLocationData()
							}"
							(click)="getAddressFromLocation()">
							{{ 'GetAddressFromLocation' | translate }}
						</button>
						<button class="btn btn-transparent bi-geo-alt-fill"
							[ngClass]="{
								'disabled': !validAddressData()
							}"
							(click)="getLocationFromAddress()">
							{{ 'GetLocationFromAddress' | translate }}
						</button>
						<button class="btn btn-transparent bi-geo-alt-fill"
							(click)="getCurrentLocation()">
							{{ 'GetCurrentLocation' | translate }}
						</button>
					</div>
				</div>

				<div class="map-wrapper">
					<google-maps-component
						[marginTop]="22"
						[height]="352"
						[latitude]="changeModel.latitude"
						[longitude]="changeModel.longitude"
						[radius]="changeModel.radius"
						[address]="address"
						[country]="loggedInUser.country"
						[name]="changeModel.name"
						(onMarkerPositionChanged)="handleMarkerMove($event)">
					</google-maps-component>
				</div>
			</div>
			<simple-form-info *ngIf="displayGeoInfoText"
				[firstText]="'RequiredHasGeoControlledTemplates' | translate">
			</simple-form-info>
		</div>
</simple-change-tab>
