import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'localeString'
})
export class LocaleStringPipe implements PipeTransform {
	transform(value: number): string {
		return value.toLocaleString();
	}
}
