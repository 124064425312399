<simple-change-bulk-tab
	[form]="form"
	[selectedIdsCount]="selectedIdsCount">
		<div class="change-bulk-section">
			<div class="change-bulk-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-date-picker
				[label]="'DueDate' | translate"
				[dateString]="changeModel.dueDate"
				[errorMessage]="dueDateErrorMessage"
				(onDateChange)="handleDueDateChange($event)">
			</simple-form-date-picker>
			<simple-form-time-input
				[label]="'DueTime' | translate"
				[name]="propertyStrings.dueTime"
				[form]="form"
				[serverErrors]="serverErrors"
				(onTimeChange)="handleTimeChange()">
			</simple-form-time-input>
		</div>
</simple-change-bulk-tab>
