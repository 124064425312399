import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { Client } from 'app-core/organization/organization';
import { OrganizationService } from 'app-core/organization/organization.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { UserService } from 'app-core/user/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ChangeClientDetailsTabComponent } from './tabs/change-client-details-tab.component';

@Component({
	templateUrl: './create-edit-client.component.html'
})
export class CreateEditClientComponent extends SimpleCreateEditModalDirective<Client> implements OnInit {

	@ViewChild(ChangeClientDetailsTabComponent) changeClientDetailsTabComponent: ChangeClientDetailsTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private organizationService: OrganizationService,
		private userService: UserService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			if (!this.userService.cultureSettings) {
				this.userService.cultureSettings = await this.userService.getCultureSettings();
			}

			if (this.isEdit) {
				this.initialModel = await this.organizationService.getClient(this.editModelId);
				this.pending = false;

				this.assignCultureSettings();

				this.modifiedModel = new Client(this.getUniqueVariant(this.initialModel));

				setTimeout(() => {
					this.triggerAllValidation();
				}, 0);
			} else {
				this.initialModel = new Client({
					organizationId: this.selectedOrganization.id
				});
				this.pending = false;

				this.assignCultureSettings();

				this.modifiedModel = new Client(this.getUniqueVariant(this.initialModel));
			}
		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	private assignCultureSettings() {
		this.initialModel.selectableCountryCodes = this.userService.cultureSettings.countryCodes;
		this.initialModel.selectableCountries = this.userService.cultureSettings.countries;
	}

	changeDetailsIsValid() {
		return this.changeClientDetailsTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeClientDetailsTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.organizationService.updateClient(this.modifiedModel.toPayloadObject())
				: await this.organizationService.createClient(this.modifiedModel.toPayloadObject());

			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeClientDetailsTabComponent.setServerErrors(this.serverErrors);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeClientDetailsTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Client) {
		return new Client(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.name !== this.initialModel.name
			|| this.modifiedModel.organizationNumber !== this.initialModel.organizationNumber
			|| this.modifiedModel.operationsManager !== this.initialModel.operationsManager
			|| this.modifiedModel.emailAddress !== this.initialModel.emailAddress
			|| this.modifiedModel.countryCode !== this.initialModel.countryCode
			|| this.modifiedModel.phoneNumber !== this.initialModel.phoneNumber
			|| this.modifiedModel.streetAddress !== this.initialModel.streetAddress
			|| this.modifiedModel.zipCode !== this.initialModel.zipCode
			|| this.modifiedModel.city !== this.initialModel.city
			|| this.modifiedModel.country !== this.initialModel.country;
	}

	hasUnsavedChanges() {
		return this.modifiedModel && this.detailsHasChanged();
	}
}
