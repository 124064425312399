<simple-view-tab>
	<div class="view-section">
		<div class="image-wrapper">
			<div class="image" #parentDiv>
				<extensive-image
					[imageUrl]="thumbnailUrl"
					[blob]="blob ? blob : null"
					[imageStorageSize]="parentDiv | getImageStorageSize"
					[displayLoader]="true">
				</extensive-image>
			</div>
			<div class="details">
				<div class="primary"
					[title]="viewModel.name">
						{{ viewModel.name }}
				</div>
				<div class="secondary" *ngIf="viewModel.description"
					[title]="viewModel.description">
						{{ viewModel.description }}
				</div>
			</div>
		</div>
	</div>
	<div class="view-section">
		<div class="information">
			<div class="header-text">
				{{ 'General' | translate }}
			</div>
			<div class="icon-text-wrapper">
				<div class="icon bi ii-facility" [title]="'Name' | translate"></div>
				<div class="text" title="{{ 'Name' | translate }}: {{ viewModel.name }}">
					{{ viewModel.name }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.description">
				<div class="icon bi bi-file-text"  [title]="'Description' | translate"></div>
				<div class="text" title="{{'Description' | translate}}: {{viewModel.description}}">
					{{ viewModel.description }}
				</div>
			</div>

			<div class="icon-text-wrapper">
				<div class="icon bi bi-person-wheelchair" [title]="'Accessibility' | translate"></div>
				<div class="text" title="{{ 'Accessibility' | translate }}: {{ (viewModel.accessible ? 'Yes' : 'No') | translate }}">
					{{ (viewModel.accessible ? 'Yes' : 'No') | translate }}
				</div>
			</div>
			<div class="icon-text-wrapper">
				<div class="icon bi bi-power" [title]="'Status' | translate"></div>
				<div class="status">
					<simple-status-label
						[text]="viewModel.status ? 'Active' : 'Inactive'"
						[title]="viewModel.status ?  'Active' : 'Inactive'">
					</simple-status-label>
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.infoLink">
				<div class="icon bi bi-link-45deg" [title]="'InfoLink' | translate"></div>
				<a class="text"
					title="{{ viewModel.infoLink }}"
					target="_blank"
					href="{{ viewModel.infoLink }}">
					{{ viewModel.infoLink }}
				</a>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.externalId && (selectedOrganization.hasDfIntegration || selectedOrganization.hasInfraControlIntegration)">
				<div class="icon bi bi-database-fill" [title]="'ExternalId' | translate"></div>
				<div class="text" title="{{ 'ExternalId' | translate }}: {{ viewModel.externalId }}">
					{{ viewModel.externalId }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.extraInfo">
				<div class="icon bi bi-card-text" [title]="'ExtraInfo' | translate"></div>
				<div class="text" title="{{ ('ExtraInfo' | translate) }}: {{ viewModel.extraInfo }}">
					<div class="markdown-viewer-wrapper"
						(click)="openExtraInfo()">
						<markdown-viewer
							[readModelInput]="viewModel.extraInfo">
						</markdown-viewer>
						<div class="hover-mask">
							{{ 'ShowMore' | translate }}
						</div>
					</div>
				</div>
			</div>
			<div class="header-text with-margin-top" *ngIf="viewModel.streetName || viewModel.streetNumber || viewModel.zipCode || viewModel.city">
				{{ 'Address' | translate }}
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.streetName || viewModel.streetNumber">
				<div class="icon bi bi-mailbox" title="{{ 'StreetName' | translate }} {{ 'and' | translate }} {{ 'StreetNumber' | translate }}"></div>
				<div class="text" title="{{ viewModel.streetName }} {{ viewModel.streetNumber }}">
					{{ viewModel.streetName }} {{ viewModel.streetNumber }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.zipCode || viewModel.city">
				<div class="icon bi bi-geo" title="{{ 'ZipCode' | translate }} {{ 'and' | translate }} {{ 'City' | translate }}"></div>
				<div class="city-wrapper">
					<div class="zip-code" [title]="viewModel.zipCode">
						{{ viewModel.zipCode }}
					</div>
					<div class="city" [title]="viewModel.city">
						{{ viewModel.city }}
					</div>
				</div>
			</div>
			<div class="header-text with-margin-top" *ngIf="viewModel.latitude && viewModel.longitude && viewModel.radius">
				{{ 'LocationData' | translate }}
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.latitude && viewModel.longitude && viewModel.radius">
				<div class="icon bi bi-geo-alt-fill" title="{{ 'Latitude' | translate }}"></div>
				<div class="text" title="{{ 'Latitude' | translate }}: {{ viewModel.latitude }}">
					{{ viewModel.latitude }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.latitude && viewModel.longitude && viewModel.radius">
				<div class="icon bi bi-geo-alt-fill" title="{{ 'Longitude' | translate }}"></div>
				<div class="text" title="{{ 'Longitude' | translate }}: {{ viewModel.longitude }}">
					{{ viewModel.longitude }}
				</div>
			</div>
			<div class="icon-text-wrapper" *ngIf="viewModel.latitude && viewModel.longitude && viewModel.radius">
				<div class="icon bi bi-record-circle" title="{{ 'Radius' | translate }}"></div>
				<div class="text" title="{{ 'Radius' | translate }}: {{ viewModel.radius }}">
					{{ viewModel.radius }}
				</div>
			</div>
		</div>
		<div class="map-wrapper" [hidden]="!viewModel.latitude || !viewModel.longitude || !viewModel.radius">
			<google-maps-component
				[latitude]="viewModel.latitude"
				[longitude]="viewModel.longitude"
				[radius]="viewModel.radius"
				[name]="viewModel.name"
				[isStatic]="true">
			</google-maps-component>
		</div>
	</div>
	<div class="view-section">
		<div class="connections-wrapper">
			<simple-view-connections
				[assignmentTemplates]="viewModel.assignmentTemplates"
				[schedules]="viewModel.schedules">
			</simple-view-connections>
			<div class="dummy-text" *ngIf="!viewModel.assignmentTemplates.length && !viewModel.schedules.length">
				{{ 'NoConnectionsYet' | translate }}
			</div>
		</div>
	</div>
</simple-view-tab>