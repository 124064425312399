import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { SwalConfig } from 'app-core/shared-core/swal/swal-config.component';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Choice } from 'app-inspection/choice/choice';
import { Entity } from 'app-inspection/entity/entity';
import { Facility } from 'app-inspection/facility/facility';
import { HandleSubscribersTabComponent } from 'app-inspection/schedule/create-edit-schedule/tabs/handle-subscribers/handle-subscribers-tab.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { AssignmentService } from '../assignment.service';
import { ManualMeasure } from '../manual-measure';
import { HandleEntitiesTabMeasureComponent } from './tabs/handle-entities-tab/handle-entities-tab.component';
import { HandleFacilityTabComponent } from './tabs/handle-facility/handle-facility-tab.component';

@Component({
	selector: 'create-manual-measure',
	templateUrl: './create-manual-measure.component.html'
})
export class CreateManualMeasureComponent extends SimpleCreateEditModalDirective<ManualMeasure> implements OnInit {

	@ViewChild(HandleFacilityTabComponent) handleFacilityTabComponent: HandleFacilityTabComponent;
	@ViewChild(HandleEntitiesTabMeasureComponent) handleEntitiesTabMeasureComponent: HandleEntitiesTabMeasureComponent;
	@ViewChild(HandleSubscribersTabComponent) handleSubscribersTabComponent: HandleSubscribersTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private assignmentService: AssignmentService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		try {
			this.pending = true;
			const templateTypes = await this.assignmentService.getTemplateTypes(this.selectedOrganization.id);
			const manualMeasureType = templateTypes.find(tt => tt.isManualMeasure);
			const facilities = await this.assignmentService.getFacilities();

			// Abort if no manualMeasure template type or facilities.
			if (!manualMeasureType || !facilities.length) {
				this.displayErrorMessage(`${this.translationService.instant(StringUtils.NO_MANUAL_MEASURE_TEMPLATE_TYPE_ERROR)}!`);
				this.closeWithDelay();
				return;
			}

			this.pending = false;

			this.initialModel = new ManualMeasure({});
			const choices = manualMeasureType.taskChoices[0].task.choices;
			const errorChoice = choices.find(choice => choice.isError);
			this.initialModel.choice = errorChoice ? new Choice(errorChoice) : new Choice(choices[0]);
			this.modifiedModel = new ManualMeasure(this.getUniqueVariant(this.initialModel));

			setTimeout(() => {
				this.activateTabFromUrl();
			}, 0);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	everythingIsValid(): boolean {
		return ((this.modifiedModel && this.modifiedModel.entities.length === 0 && this.handleFacilityIsValid())
			|| (this.modifiedModel && this.modifiedModel.entities.length > 0 && this.handleEntitiesIsValid()))
			&& this.handleSubscribersIsValid();
	}

	protected async createOrUpdate() {
		if (!this.modifiedModel.assigneeGroups.length && !this.modifiedModel.assignees.length) {
			return Swal.fire(new SwalConfig(this.translationService).getInfo({
				text: this.translationService.instant('ManualMeasureIsEmptyDescription'),
				confirmButtonText: this.translationService.instant('IUnderstand'),
				cancelButtonText: this.translationService.instant('Cancel')
			})).then(async result => {
				if (result.value) {
					this.performCreateOrUpdate();
				}
			});
		} else {
			this.performCreateOrUpdate();
		}
	}

	async performCreateOrUpdate() {
		this.pending = true;
		try {
			const response = await this.assignmentService.createManualMeasure(this.modifiedModel.toPayloadObject(this.selectedOrganization.id));
			this.handleSuccessResponse(response);
		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		if (this.modifiedModel.entities.length) {
			this.handleEntitiesTabMeasureComponent?.triggerValidation();
		} else {
			this.validateFacility();
		}
		this.handleSubscribersTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: ManualMeasure): ManualMeasure {
		return new ManualMeasure(item);
	}

	setFacility(facilities: Facility[]) {
		this.modifiedModel.facility = facilities.length ? facilities[0] : null;
		this.clearEntities();
	}

	assignFacility(facilities: Facility[]) {
		Object.assign(this.modifiedModel.facility, facilities[0]);

		if (this.modifiedModel.entities.length) {
			this.clearFacilityError();
		} else {
			this.validateFacility();
		}
	}

	handleOnUpdatedEntities() {
		if (this.modifiedModel.entities.length) {
			this.clearFacilityError();
		} else {
			this.validateFacility();
		}
	}

	handleEntityChange(entities: Entity[]) {
		this.modifiedModel.entities = entities;

		if (this.modifiedModel.entities.length) {
			this.clearFacilityError();
		} else {
			this.validateFacility();
		}
	}

	clearFacilityError() {
		if (this.modifiedModel.facility) {
			this.modifiedModel.facility.errorMessages = [];
		}
	}

	validateFacility() {
		this.clearFacilityError();
		if (this.modifiedModel.facility && !this.modifiedModel.facility.issueDetails) {
			this.modifiedModel.facility.errorMessages.push('IssueDetailsMustBeAddedFacility');
		}
		if (!this.modifiedModel.facility) {
			this.handleFacilityTabComponent.triggerValidation();
		}
	}

	detailsHasChanged() {
		return !!this.modifiedModel.facility
			|| !!this.modifiedModel.entities.length
			|| !!this.modifiedModel.assigneeGroups.length
			|| !!this.modifiedModel.assignees.length
			|| !!this.modifiedModel.subscribers.length
	}

	clearEntities() {
		this.modifiedModel.entities = [];
	}

	hasUnsavedChanges(): boolean {
		return this.modifiedModel && this.detailsHasChanged();
	}

	handleFacilityIsValid() {
		return this.handleFacilityTabComponent?.itemListIsValid() || !!this.modifiedModel.entities.length;
	}

	handleFacilityHasErrors() {
		return this.handleFacilityTabComponent?.itemListHasErrors();
	}

	handleEntitiesIsValid() {
		return this.handleEntitiesTabMeasureComponent?.itemListIsValid();
	}

	handleEntitiesHasErrors() {
		return this.handleEntitiesTabMeasureComponent?.itemListHasErrors();
	}

	handleSubscribersIsValid() {
		return this.handleSubscribersTabComponent?.itemListIsValid();
	}

	handleSubscribersHasErrors() {
		return this.handleSubscribersTabComponent?.itemListHasErrors();
	}
}
