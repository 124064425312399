<simple-crud-modal
[titleText]="'StatisticsByDateData' | translate"
[submitText]="'ToTheTopOfList' | translate"
[closeText]="'Close' | translate"
[disableSubmit]="!!externalError || !!internalError || refreshing"
[isLoading]="refreshing"
(onClose)="closeModal()"
(onSubmit)="scrollToTop()">
	<div class="extra-actions refresh-action-in-header" extraActions *ngIf="!externalError && !internalError && newDataAvailable">
		<span class="refresh-text">{{ "StatisticsUpdatedNewData" | translate }}</span>
		<div class="refresh"
			[ngClass]="{'disabled': refreshing }"
			title="{{ !refreshing ? ('RefreshStatistics' | translate) : '' }}"
			(click)="updateData()">
			<i class="icon" [ngClass]="{'refreshing': refreshing}"></i>
		</div>
	</div>
	<div class="statistics by-date-data in-modal">
		<ng-container *ngTemplateOutlet="dataTemplate"></ng-container>
		<ng-container *ngTemplateOutlet="pendingTemplate"></ng-container>
	</div>
</simple-crud-modal>

<!-- Data template -->
<ng-template #dataTemplate>
	<ng-container *ngIf="!refreshing && !externalError && !internalError && !loading">
		<div class="statistics-data">
			<div class="section">
				<h4 class="h4" [innerHTML]="header"></h4>
				<div class="type-navigation date-types btn-group"
					[ngClass]="{ 'date-types': payload.discriminators.length > 1 }">
					<ng-container *ngFor="let type of dateTypesEnumKeys">
						<button
							type="button"
							class="btn btn-primary"
							[ngClass]="{ 'active': activeDateType === type }"
							(click)="changeDateType(dateTypesEnum[type])">
								{{ dateTypesEnum[type] | translate }}
							</button>
					</ng-container>
				</div>
				<div class="type-navigation reference-types btn-group" *ngIf="payload.discriminators.length > 1">
					<ng-container *ngFor="let type of payload.discriminators">
						<button
							type="button"
							class="btn btn-primary"
							[ngClass]="{ 'active': activeReferenceTypes.includes(type) }"
							(click)="changeReferenceType([type])">
								{{ referenceTypesEnum[type] + "Statistics" | translate }}
							</button>
					</ng-container>
				</div>

				<div class="section-inner" *ngIf="dataToRender">
					<h5 class="h4 type">
						<div class="type-content">
							{{ dataToRender.types | translate }}
						</div>
					</h5>
					<div class="section-inner-description graph desktop" *ngIf="activeSort; let activeSort">
						<div class="description-value description date lighter flex-none"
							[ngClass]="{ 'active': activeSort.option === sortOptionsEnum[dataToRender.dateType] }"
							title="{{ ((activeSort.direction === sortDirectionsEnum.Ascending
									? 'SortDescendingOn'
									: 'SortAscendingOn')
								| translate) + ' ' + (dataToRender.dateType | translate).toLowerCase() }}"
							(click)="sort({
								option: sortOptionsEnum[dataToRender.dateType],
								direction: activeSort.option === sortOptionsEnum[dataToRender.dateType] &&
									activeSort.direction === sortDirectionsEnum.Ascending
										? sortDirectionsEnum.Descending
										: sortDirectionsEnum.Ascending })">
							<span class="header">{{ dataToRender.dateType | translate }}</span>
							<i class="icon {{
								sortDirectionsEnum[activeSort.option === sortOptionsEnum[dataToRender.dateType]
									? activeSort.direction
									: sortDirectionsEnum.Ascending].toLowerCase()
							}}"></i>
						</div>
						<div class="description-value description total lighter flex-none"
							[ngClass]="{ 'active': activeSort.option === sortOptionsEnum[spanTypesEnum.Total] }"
							title="{{ ((activeSort.direction === sortDirectionsEnum.Ascending
								? 'SortDescendingOn'
								: 'SortAscendingOn')
							| translate) + ' ' + (spanTypesEnum[spanTypesEnum.Total] | translate).toLowerCase() }}"
							(click)="sort({
								option: sortOptionsEnum[spanTypesEnum.Total],
								direction: activeSort.option === sortOptionsEnum[spanTypesEnum.Total] &&
									activeSort.direction === sortDirectionsEnum.Ascending
										? sortDirectionsEnum.Descending
										: sortDirectionsEnum.Ascending })">
							<span class="header">{{ spanTypesEnum[spanTypesEnum.Total] | translate }}</span><i class="icon {{
								sortDirectionsEnum[activeSort.option === sortOptionsEnum[spanTypesEnum.Total]
									? activeSort.direction
									: sortDirectionsEnum.Ascending].toLowerCase()
							}}"></i>
						</div>
					</div>
					<div *ngFor="let data of dataToRender.data;"
						class="section-inner-data graph">
						<div class="description-value date flex-none">
							<div>{{ data.date }}</div>
						</div>
						<div class="description-value total flex-none"
							[ngClass]="{ 'not-initialized': !data.renderConfig.initialized }"
							[ngStyle]="{ 'width': data.renderConfig.width + '%' }">
							<div [ngClass]="{ 'outside-bar': data.renderConfig.numbersFromBar !== 0 }"
								[ngStyle]="{ 'right': data.renderConfig.numbersFromBar + 'px' }">
								{{ data.total.toLocaleString(locale) }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</ng-template>

<!-- Pending template -->
<ng-template #pendingTemplate>
	<ng-container *ngIf="refreshing">
		<div class="empty-state">
			<h4>
				{{ "CalculatingStatistics" | translate }}...
			</h4>
		</div>
	</ng-container>
</ng-template>