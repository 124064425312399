<simple-change-tab
	[form]="form">
		<div class="change-section" >
			<div class="change-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-input
				[label]="'EmailAddress' | translate"
				[name]="propertyStrings.email"
				[form]="form"
				[disabled]="changeModel.exists"
				[serverErrors]="serverErrors">
			</simple-form-input>
			<simple-form-info *ngIf="changeModel.exists"
				[firstText]="'UserExistsInOtherOrg' | translate">
			</simple-form-info>
		</div>
		<div class="change-section">
			<div class="invite-date">
				{{ "LastSentAt" | translate }} {{ changeModel.inviteDate | datex }}.
				<BR>
				{{ 'LinkValidInfo' | translate }}.
			</div>
		</div>
</simple-change-tab>
