import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { HandleMediaTabComponent } from 'app-core/media/handle-media/handle-media-tab.component';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { BackendResponse } from 'app-core/shared-core/abstract-components/service/base.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Entity } from 'app-inspection/entity/entity';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Facility } from '../facility';
import { FacilityService } from '../facility.service';
import { UpdateAssignmentTemplateWithEntitiesComponent } from '../update-assignment-templates-with-entities/update-assignment-templates-with-entities.component';
import { ChangeDetailsTabComponent } from './tabs/change-details/change-details-tab.component';
import { HandleEntitiesTabComponent } from './tabs/handle-entities/handle-entities-tab.component';
import { ViewDetailsTabComponent } from './tabs/view-details/view-details-tab.component';

@Component({
	templateUrl: './create-edit-facility.component.html'
})
export class CreateEditFacilityComponent extends SimpleCreateEditModalDirective<Facility> implements OnInit {

	bsModalRef: BsModalRef<any>;
	entityIdsToRemove: string[] = [];

	@ViewChild(ViewDetailsTabComponent) viewFacilityDetailsTabComponent: ViewDetailsTabComponent;
	@ViewChild(ChangeDetailsTabComponent) changeFacilityDetailsTabComponent: ChangeDetailsTabComponent;
	@ViewChild(HandleEntitiesTabComponent) handleEntitiesTabComponent: HandleEntitiesTabComponent;
	@ViewChild(HandleMediaTabComponent) handleMediaTabComponent: HandleMediaTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private facilityService: FacilityService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		if (this.isEdit) {
			this.pending = true;
			try {
				this.initialModel = await this.facilityService.get(this.editModelId);
				this.pending = false;

				this.modifiedModel = new Facility(this.getUniqueVariant(this.initialModel));

				setTimeout(() => {
					this.activateTabFromUrl();
					this.triggerAllValidation();
				}, 0);

			} catch (errorResponse) {
				this.pending = false;
				this.handleErrorResponse(errorResponse);
				this.closeWithDelay();
			}
		} else {
			this.initialModel = new Facility({});
			this.modifiedModel = new Facility(this.getUniqueVariant(this.initialModel));

			setTimeout(() => {
				this.activateTabFromUrl();
			}, 0);
		}
	}

	handleValueChanges() {
		if (this.changeFacilityDetailsTabComponent) {
			const latCtrl = this.changeFacilityDetailsTabComponent.form.get(this.propertyStrings.latitude);
			const longCtrl = this.changeFacilityDetailsTabComponent.form.get(this.propertyStrings.longitude);
			const radiusCtrl = this.changeFacilityDetailsTabComponent.form.get(this.propertyStrings.radius);

			if (latCtrl.value && latCtrl.valid && longCtrl.value && longCtrl.valid && radiusCtrl.value && radiusCtrl.valid) {
				this.refreshMap(latCtrl.value.replace(',', '.'), longCtrl.value.replace(',', '.'), radiusCtrl.value);
			}

			const streetNameCtrl = this.changeFacilityDetailsTabComponent.form.get(this.propertyStrings.streetName);
			const streetNumberCtrl = this.changeFacilityDetailsTabComponent.form.get(this.propertyStrings.streetNumber);
			const zipCodeCtrl = this.changeFacilityDetailsTabComponent.form.get(this.propertyStrings.zipCode);
			const cityCtrl = this.changeFacilityDetailsTabComponent.form.get(this.propertyStrings.city);

			if (streetNameCtrl.value && streetNameCtrl.valid
				&& streetNumberCtrl.value && streetNumberCtrl.valid
				&& zipCodeCtrl.value && zipCodeCtrl.valid
				&& cityCtrl.value && cityCtrl.valid) {
				this.refreshAddress(streetNameCtrl.value, streetNumberCtrl.value, zipCodeCtrl.value, cityCtrl.value);
			}
		}
	}

	private refreshMap(lat: string, long: string, radius: string) {
		const latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(long));

		this.changeFacilityDetailsTabComponent.googleMapsComponent.radius = radius;
		this.changeFacilityDetailsTabComponent.googleMapsComponent.updateLocation(latLng);
		this.changeFacilityDetailsTabComponent.googleMapsComponent.displayMarker();
		this.changeFacilityDetailsTabComponent.googleMapsComponent.determineZoom();

		if (this.isEdit && this.viewFacilityDetailsTabComponent.googleMapsComponent) {
			this.viewFacilityDetailsTabComponent.googleMapsComponent.radius = radius;
			this.viewFacilityDetailsTabComponent.googleMapsComponent.updateLocation(latLng);
			this.viewFacilityDetailsTabComponent.googleMapsComponent.displayMarker();
			this.viewFacilityDetailsTabComponent.googleMapsComponent.determineZoom();
		}
	}

	private refreshAddress(streetName: string, streetNumber: string, zipCode: string, city: string) {
		this.changeFacilityDetailsTabComponent.buildAddress(streetName, streetNumber, zipCode, city);
	}

	changeDetailsIsValid() {
		return this.changeFacilityDetailsTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeFacilityDetailsTabComponent?.formHasErrors();
	}

	handleEntitiesIsValid() {
		return this.handleEntitiesTabComponent?.itemListIsValid();
	}

	handleEntitiesHasErrors() {
		return this.handleEntitiesTabComponent?.itemListHasErrors();
	}

	handleMediaIsValid() {
		return this.handleMediaTabComponent?.itemListIsValid();
	}

	handleMediaHasErrors() {
		return this.handleMediaTabComponent?.itemListHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid() && this.handleEntitiesIsValid() && this.handleMediaIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.facilityService.update(this.modifiedModel.toPayloadObject(this.selectedOrganization.id))
				: await this.facilityService.create(this.modifiedModel.toPayloadObject(this.selectedOrganization.id));

			const facilityId = response.data.id;

			if (this.mediaHasChanged()) {
				await this.handleMediaChange(facilityId, response);
			}

			if (this.entitiesHasChanged()) {
				await this.handleEntitiesChange(facilityId, response);
			} else {
				this.handleSuccessResponse(response);
			}

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeFacilityDetailsTabComponent.setServerErrors(this.serverErrors);
				this.goToTab(1);
			}
		}
	}

	protected async handleMediaChange(facilityId: string, originalResponse: BackendResponse) {
		const uploadMediaPromises: Promise<BackendResponse>[] = [];
		const existingMedia: MediaWidgetItem[] = [];
		try {
			this.modifiedModel.media.forEach(item => {
				if (item.blob) {
					const uploadMediaPromise = this.facilityService.uploadMedia(facilityId, item).toPromise();
					uploadMediaPromises.push(uploadMediaPromise);
				} else {
					existingMedia.push(item);
				}
			});

			if (this.isEdit) {
				const uploadMediaResponses = await Promise.all(uploadMediaPromises);

				const uploadedMedia = uploadMediaResponses.map(obj => {
					const data = JSON.parse(obj['response']).data;
					return new MediaWidgetItem(data);
				});

				const defaultIsAmongUploaded = uploadedMedia.some(item => item.default);
				if (defaultIsAmongUploaded) {
					existingMedia.forEach(item => item.default = false);
				}

				const allMedia = existingMedia.concat(uploadedMedia);

				const response = await this.facilityService.assignMedia(facilityId, allMedia);
				this.displaySuccessMessage(response.successMessage);
			} else {
				await Promise.all(uploadMediaPromises);
			}
		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.handleSuccessResponse(originalResponse);
		}
	}

	private hasAddedEntity() {
		return this.modifiedModel.entities.some(ent => !this.initialModel.entities.find(existingEnt => existingEnt.id === ent.id));
	}

	handleEntities(entities: Entity[]) {
		this.modifiedModel.entities = entities
		this.entityIdsToRemove = this.entityIdsToRemove.filter(entityId => !this.modifiedModel.entities.some(entity => entity.id === entityId));
	}

	protected async handleEntitiesChange(facilityId: string, originalResponse: BackendResponse) {
		const entityIds = this.modifiedModel.entities.map(entity => entity.id);

		try {
			if (this.isEdit && this.hasAddedEntity()) {
				const assignmentTemplates = await this.facilityService.getAssignmentTemplatesWithEntities(facilityId);

				if (assignmentTemplates && assignmentTemplates.length) {
					this.bsModalRef = this.modalService.show(
						UpdateAssignmentTemplateWithEntitiesComponent,
						{
							initialState: {
								assignmentTemplatesToUpdate: assignmentTemplates,
							},
							...ConfigUtils.MODAL_CONFIG_LARGE,
						}
					)

					const assTempWithEntities = (this.bsModalRef.content as UpdateAssignmentTemplateWithEntitiesComponent);
					this.subscriptions.add(
						assTempWithEntities.onUpdate
							.pipe(
								takeUntil(this.destroyed$)
							)
							.subscribe(async updatedItems => {
								this.closeModal();
								try {
									const assigmentTemplateIds = updatedItems.map(at => at.id);
									this.updateEntitiesAndHandleResponse(facilityId, entityIds, assigmentTemplateIds, this.entityIdsToRemove, originalResponse);

								} catch (errorResponse) {
									this.pending = false;
									this.handleErrorResponse(errorResponse);
								}
							})
					);
				} else {
					this.updateEntitiesAndHandleResponse(facilityId, entityIds, [], this.entityIdsToRemove, originalResponse);
				}
			} else {
				this.updateEntitiesAndHandleResponse(facilityId, entityIds, [], this.entityIdsToRemove, originalResponse);
			}
		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.handleSuccessResponse(originalResponse);
		}
	}

	async updateEntitiesAndHandleResponse(facilityId: string, entityIds: string[], assigmentTemplateIds: string[], entityIdsToRemove: string[], originalResponse: BackendResponse) {
		try {
			const response = await this.facilityService.updateEntities(facilityId, entityIds, assigmentTemplateIds, entityIdsToRemove);
			this.displaySuccessMessage(response.successMessage);
			this.handleSuccessResponse(originalResponse);
		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.handleSuccessResponse(originalResponse);
		}
	}

	async deleteEntities(ids: string[]) {
		this.entityIdsToRemove = [...this.entityIdsToRemove, ...ids];
	}

	protected closeModal() {
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
	}

	protected triggerAllValidation() {
		this.changeFacilityDetailsTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Facility) {
		return new Facility(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.name !== this.initialModel.name
			|| this.modifiedModel.description !== this.initialModel.description
			|| this.modifiedModel.streetName !== this.initialModel.streetName
			|| this.modifiedModel.streetNumber !== this.initialModel.streetNumber
			|| this.modifiedModel.zipCode !== this.initialModel.zipCode
			|| this.modifiedModel.city !== this.initialModel.city
			|| this.modifiedModel.latitude !== this.initialModel.latitude
			|| this.modifiedModel.longitude !== this.initialModel.longitude
			|| this.modifiedModel.radius !== this.initialModel.radius
			|| this.modifiedModel.infoLink !== this.initialModel.infoLink
			|| this.modifiedModel.externalId !== this.initialModel.externalId
			|| this.modifiedModel.status !== this.initialModel.status
			|| this.modifiedModel.accessible !== this.initialModel.accessible
			|| this.modifiedModel.extraInfo !== this.initialModel.extraInfo;
	}

	private entitiesHasChanged() {
		const currentEntities = this.stripSelected(this.modifiedModel.entities);
		const initialEntities = this.stripSelected(this.initialModel.entities);
		return !Utils.isEqual(currentEntities.map(entity => entity.id), initialEntities.map(entity => entity.id)) || !!this.entityIdsToRemove.length;
	}

	private mediaHasChanged() {
		const currentMedia = this.stripActionsOpened(this.modifiedModel.media);
		const initialMedia = this.stripActionsOpened(this.initialModel.media);
		return !Utils.isEqual(currentMedia, initialMedia);
	}

	private stripSelected(items: Entity[]) {
		return items.map(item => {
			const { selected, ...theRest } = item;
			return theRest as Entity;
		});
	}

	private stripActionsOpened(items: MediaWidgetItem[]) {
		return items.map(item => {
			const { actionsOpened, ...theRest } = item;
			return theRest as MediaWidgetItem;
		});
	}

	private inlineListHasUnsavedChanges() {
		return this.handleEntitiesTabComponent?.inlineListHasUnsavedChanges();
	}

	hasUnsavedChanges() {
		return this.modifiedModel && (this.detailsHasChanged() || this.entitiesHasChanged() || this.mediaHasChanged() || this.inlineListHasUnsavedChanges());
	}
}
