import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { takeUntil } from 'rxjs/operators';
import { SimpleCrudDirective } from '../../../simple-crud.directive';

@Directive()
export abstract class SimpleChangeTabDirective<T extends { id: string }> extends SimpleCrudDirective<T> implements OnInit {

	form: FormGroup;
	serverErrors: KeyValuePair[] = [];

	@Input() changeModel: T;

	@Output() onValueChange = new EventEmitter();

	get isEdit() {
		return !!this.changeModel.id;
	}

	ngOnInit() {
		if (this.form) {
			this.initReactiveForm();
		}
	}

	initReactiveForm() {
		this.subscriptions.add(
			this.form.valueChanges
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(_ => {
					Object.entries(this.form.controls).forEach(([name, control]) => {
						if (!control.disabled) {
							this.changeModel[name] = control.value;
						}
					});

					this.onValueChange.emit();
				})
		);
	}

	formIsValid() {
		return this.form.valid || Object.keys(this.form.controls).every(key => {
			const formControl = this.form.get(key);
			return formControl.disabled;
		});
	}

	formHasErrors() {
		return Object.keys(this.form.controls).some(key => {
			const formControl = this.form.get(key);
			return formControl.invalid && (formControl.dirty || formControl.touched);
		});
	}

	triggerValidation() {
		Object.keys(this.form.controls).forEach(key => {
			const formControl = this.form.get(key);
			formControl.markAsTouched();
		});
	}

	setServerErrors(serverErrors: KeyValuePair[]) {
		this.serverErrors = serverErrors;
		const unmappedErrors: KeyValuePair[] = [];
		serverErrors.forEach(error => {
			const control = this.form.get(error.key);
			if (control) {
				control.setErrors({ serverError: true });
			} else {
				unmappedErrors.push(error);
			}
		});

		if (unmappedErrors.length) {
			const headline = `${this.translationService.instant('TheFollowingErrorsCouldNotBeMapped')}:<BR>`;
			const errors = unmappedErrors.map(error => `&bull; ${error.value}`).join('<BR>');
			this.toastrService.error(`${headline}${errors}`);
		}
	}
}
