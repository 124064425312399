import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
	selector: 'simple-categories',
	templateUrl: './simple-categories.component.html',
	styleUrls: ['./simple-categories.component.less']
})
export class SimpleCategoriesComponent {

	@Input() categories: Category[];

	constructor(private cdRef: ChangeDetectorRef) { }

	triggerViewChanges() {
		this.cdRef.detectChanges();
	}
}

export class Category {
	id: string = '';
	name: string = '';

	constructor(category: Partial<Category>) {
		Object.assign(this, category);
	}
}
