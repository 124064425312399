import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { BackendResponse } from 'app-core/shared-core/abstract-components/service/base.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Choice } from 'app-inspection/choice/choice';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AssigneeGroup, Assignment } from '../assignment';
import { AssignmentService } from '../assignment.service';
import { ChangeDetailsTabComponent } from './tabs/change-details/change-details-tab.component';
import { HandleAssigneeGroupsTabComponent } from './tabs/handle-assignee-groups/handle-assignee-groups-tab.component';
import { HandleAssigneesTabComponent } from './tabs/handle-assignees/handle-assignees-tab.component';

@Component({
	selector: 'detailed-assignment',
	templateUrl: './detailed-assignment.component.html',
})
export class DetailedAssignmentComponent extends SimpleCreateEditModalDirective<Assignment> implements OnInit {

	@ViewChild(ChangeDetailsTabComponent) changeAssignmentDetailsTabComponent: ChangeDetailsTabComponent;
	@ViewChild(HandleAssigneeGroupsTabComponent) handleAssigneeGroupsTabComponent: HandleAssigneeGroupsTabComponent;
	@ViewChild(HandleAssigneesTabComponent) handleAssigneesTabComponent: HandleAssigneesTabComponent;

	translateParam: Object;
	headerTitle: string;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private assignmentService: AssignmentService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.headerTitle =
			this.router.url.includes(RoutesUtils.measureArchived)
				? this.translationService.instant('ArchivedMeasure')
				: this.router.url.includes(RoutesUtils.measureOngoing)
					? this.translationService.instant('OngoingMeasure')
					: this.router.url.includes(RoutesUtils.scheduledArchived)
						? this.translationService.instant('ArchivedAssignment')
						: this.translationService.instant('OngoingAssignment');
		this.pending = true;
		try {
			this.initialModel = await this.assignmentService.get(this.editModelId);
			if (this.initialModel.isMeasureAssignment()) {
				this.initialModel.choice = new Choice(this.initialModel.errorResult.task.choice);
				this.initialModel.status = this.initialModel.status === StringUtils.ACTIVE ? StringUtils.NOT_STARTED : this.initialModel.status;
			}
			this.pending = false;

			this.modifiedModel = new Assignment(this.getUniqueVariant(this.initialModel));

			if (this.isIncorrectRouteForDataType()) {
				this.displayErrorMessage(`${this.translationService.instant(StringUtils.WRONG_ASSIGNMENT_LIST_ERROR)}!`);
				this.close();
				this.router.navigate([`${this.selectedOrganization.friendlyUrl}/${this.modifiedModel.getCorrectRoute()}`], { queryParamsHandling: 'merge', replaceUrl: true });
				return;
			}

			this.setTranslateParam();

			setTimeout(() => {
				this.activateTabFromUrl();
				this.triggerAllValidation();
			}, 0);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			let response: BackendResponse;
			if (this.shouldUpdateDueDate()) {
				const [date, _] = this.modifiedModel.dueDate.split(' ');
				const newDueDate = `${date} ${this.modifiedModel.dueTime}`;
				response = await this.assignmentService.updateDueDate([this.modifiedModel.id], newDueDate);
			}
			if (this.shouldUpdateAssigneesOrAssigneeGroups()) {
				response = await this.assignmentService.updateAssigneeGroupsAndAssignees([this.modifiedModel.id], this.modifiedModel.assigneeGroups, this.modifiedModel.assignees, this.modifiedModel.isMeasureAssignment());
			}
			if (this.shouldUpdatePriorityAndAdminComment()) {
				response = await this.assignmentService.update(this.modifiedModel.toAdminCommentPriorityPayloadObject());
			}
			if (this.shouldUpdateStatus()) {
				response = await this.assignmentService.updateMeasureStatus([this.modifiedModel.id], this.modifiedModel.status, this.modifiedModel.notes, this.selectedOrganization.id);
			}

			response.successMessage = this.translationService.instant('TheAssignmentHasBeenUpdated');
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	setTranslateParam() {
		this.translateParam = {
			date: Utils.formatDate(this.modifiedModel.completed, 'LLL')
		};
	}

	isIncorrectRouteForDataType() {
		if (this.modifiedModel.isArchivedAssignment()) {
			if (this.modifiedModel.isMeasureAssignment()) {
				if (this.isOngoingScheduledRoute() || this.isArchivedScheduledRoute() || this.isOngoingMeasureRoute()) {
					return true;
				}
			} else {
				if (this.isOngoingMeasureRoute() || this.isArchivedMeasureRoute() || this.isOngoingScheduledRoute()) {
					return true;
				}
			}
		} else {
			if (this.modifiedModel.isMeasureAssignment()) {
				if (this.isOngoingScheduledRoute() || this.isArchivedScheduledRoute() || this.isArchivedMeasureRoute()) {
					return true;
				}
			} else {
				if (this.isOngoingMeasureRoute() || this.isArchivedMeasureRoute() || this.isArchivedScheduledRoute()) {
					return true;
				}
			}
		}
		return false;
	}

	isOngoingScheduledRoute() {
		return this.router.url.includes(RoutesUtils.scheduledOngoing);
	}

	isArchivedScheduledRoute() {
		return this.router.url.includes(RoutesUtils.scheduledArchived);
	}

	isOngoingMeasureRoute() {
		return this.router.url.includes(RoutesUtils.measureOngoing);
	}

	isArchivedMeasureRoute() {
		return this.router.url.includes(RoutesUtils.measureArchived);
	}

	isSSEN() {
		return this.modifiedModel.isSSEN();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid()
			&& this.handleGroupsIsValid()
			&& this.handleUsersIsValid();
	}

	changeDetailsIsValid() {
		return this.changeAssignmentDetailsTabComponent?.formIsValid()
			&& !this.changeAssignmentDetailsTabComponent?.dueDateErrorMessage;
	}

	changeDetailsHasErrors() {
		return this.changeAssignmentDetailsTabComponent?.formHasErrors()
			|| this.changeAssignmentDetailsTabComponent?.dueDateErrorMessage;
	}

	shouldUpdateStatus() {
		return this.modifiedModel.status !== this.initialModel.status;
	}

	shouldUpdatePriorityAndAdminComment() {
		return this.modifiedModel.priority !== this.initialModel.priority
			|| this.modifiedModel.adminComment !== this.initialModel.adminComment;
	}

	shouldUpdateDueDate() {
		return this.modifiedModel.dueDate !== this.initialModel.dueDate
			|| this.modifiedModel.dueTime !== this.initialModel.dueTime;
	}

	shouldUpdateAssigneesOrAssigneeGroups() {
		return !Utils.isEqual(this.stripActionsOpened(this.modifiedModel.assigneeGroups), this.stripActionsOpened(this.initialModel.assigneeGroups))
			|| !Utils.isEqual(this.modifiedModel.assignees, this.initialModel.assignees);
	}

	detailsHasChanged() {
		if (this.modifiedModel.isMeasureAssignment()) {
			return this.shouldUpdateStatus()
				|| this.shouldUpdatePriorityAndAdminComment()
				|| this.shouldUpdateAssigneesOrAssigneeGroups();
		} else {
			return this.shouldUpdateDueDate()
				|| this.shouldUpdateAssigneesOrAssigneeGroups();
		}
	}

	private stripActionsOpened(items: AssigneeGroup[]) {
		return items.map(item => {
			const { actionsOpened, ...theRest } = item;
			return theRest as AssigneeGroup;
		});
	}

	hasUnsavedChanges() {
		return this.modifiedModel && this.detailsHasChanged();
	}

	handleGroupsIsValid() {
		return this.handleAssigneeGroupsTabComponent?.itemListIsValid();
	}

	handleGroupsHasErrors() {
		return this.handleAssigneeGroupsTabComponent?.itemListHasErrors();
	}

	handleUsersIsValid() {
		return this.handleAssigneesTabComponent?.itemListIsValid();
	}

	handleUsersHasErrors() {
		return this.handleAssigneesTabComponent?.itemListHasErrors();
	}

	protected triggerAllValidation(): void {
		this.changeAssignmentDetailsTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Assignment): Assignment {
		return new Assignment(item);
	}
}
