import { ChangeDetectorRef, Component, ViewChildren } from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { ExtensiveImageComponent } from 'app-core/media/extensive-image/extensive-image.component';
import { Organization } from 'app-core/organization/organization';
import { UserGroup } from 'app-core/user-group/user-group';
import { LoggedInUser, User } from 'app-core/user/user';
import { AssignmentTemplate } from 'app-inspection/assignment-template/assignment-template';
import { Assignee, AssigneeGroup } from 'app-inspection/assignment/assignment';
import { Entity } from 'app-inspection/entity/entity';
import { Schedule } from 'app-inspection/schedule/schedule';
import { environment } from 'environments/environment';
import { RuleObject } from '../simple-components/various/rules/simple-rules.component';
import { StringUtils } from '../tools/string-utils';

@Component({
	selector: 'view-objects',
	templateUrl: './view-objects.component.html',
	styleUrls: ['./view-objects.component.less'],
})
export class ViewObjectsComponent {

	loggedInUser: LoggedInUser;
	selectedOrganization: Organization;

	groups: UserGroup[] = [];
	assigneeGroups: AssigneeGroup[] = [];
	users: User[] = [];
	assignees: Assignee[] = [];
	assignmentTemplates: AssignmentTemplate[] = [];
	schedules: Schedule[] = [];
	entities: Entity[] = [];
	type: ViewObjectsType;
	rowId: string = '';

	groupRules: GroupOrAssigneeRulesObject[] = [];
	assigneeRules: GroupOrAssigneeRulesObject[] = [];

	@ViewChildren(ExtensiveImageComponent) extensiveImages: ExtensiveImageComponent[] = [];

	constructor(
		private cdRef: ChangeDetectorRef,
		authService: AuthService) {
		this.loggedInUser = authService.loggedInUser;
		this.selectedOrganization = authService.selectedOrganization;

		this.canEdit = authService.canEdit();
	}

	navigateTo(url: string, user?: User) {
		var realUrl = `${environment.coreUrl}/${this.selectedOrganization.friendlyUrl}/${url}`;
		if (user) {
			const linkWord = user.getCurrentStatus(this.selectedOrganization.id) === StringUtils.PENDING ? 'resendEmail' : 'edit';
			realUrl = `${environment.coreUrl}/${this.selectedOrganization.friendlyUrl}/users?user=${linkWord}_${user.id}&tab=1`;
		}

		window.open(realUrl, '_blank');
	}

	triggerViewChanges() {
		this.cdRef.detectChanges();
	}
}

export class GroupOrAssigneeRulesObject {
	id: string;
	rules: RuleObject[];

	constructor(obj: { id: string, rules: RuleObject[] }) {
		this.id = obj.id;
		this.rules = obj.rules;
	}
}

export enum ViewObjectsType {
	groupsAndUsers = 'groupsAndUsers',
	users = 'users',
	assignmentTemplate = 'assignmentTemplate',
	schedule = 'schedule',
	entity = 'entity'
}
