<div class="help-actions-menu" #helpPop="bs-popover"
	placement="bottom"
	container="body"
	containerClass="help-menu-popover"
	[outsideClick]="true"
	[popover]="!fetching ? helpTemplate : null"
	[ngClass]="{
		'opened': popoverOpened,
		'in-modal': isInModal
	}"
	[title]="'UserSupport' | translate"
	(onShown)="popoverOpened = true"
	(onHidden)="popoverOpened = false">
		<i class="bi bi-question-circle-fill" *ngIf="!fetching"></i>
		<i class="bi bi-arrow-repeat" *ngIf="fetching"></i>
</div>
<ng-template #helpTemplate>
	<div class="help-menu-item" *ngFor="let helpArticle of helpArticles; trackBy: trackByFn"
		[title]="helpArticle.title"
		(click)="navigateToWiki(helpArticle); helpPop.hide();">
		<div class="help-article-wrapper">
			<div class="icon-title-wrapper">
				<div class="help-article-icon {{ helpArticle.icon }}">
				</div>
				<div class="help-article-title">
					{{ helpArticle.title }}
				</div>
			</div>
		</div>
	</div>
	<div class="help-menu-item dummy" *ngIf="!helpArticles.length">
		<div class="help-article-wrapper">
			<div class="icon-title-wrapper">
				<div class="help-article-icon bi-info-circle">
				</div>
				<div class="help-article-title">
					{{ 'NoArticles' | translate }}
				</div>
			</div>
		</div>
	</div>
	<div class="help-menu-item"
		[title]="'VisitWiki' | translate"
		(click)="navigateToWiki(); helpPop.hide();">
		<div class="help-article-wrapper">
			<div class="icon-title-wrapper">
				<div class="help-article-icon bi-server">
				</div>
				<div class="help-article-title">
					{{ 'VisitWiki' | translate }}
				</div>
			</div>
		</div>
	</div>
</ng-template>
