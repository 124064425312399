import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { Assignment } from 'app-inspection/assignment/assignment';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { AssignmentService } from 'app-inspection/assignment/assignment.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SetStatusTabComponent } from './tabs/set-status-tab.component';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';

@Component({
	templateUrl: './set-status-assignment.component.html'
})
export class SetStatusAssignmentComponent extends SimpleBulkEditModalDirective<Assignment> implements OnInit {

	@ViewChild(SetStatusTabComponent) setAssignmentStatusTabComponent: SetStatusTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private assignmentService: AssignmentService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.initialModel = new Assignment({});
	}

	changeDetailsIsValid() {
		return this.setAssignmentStatusTabComponent?.formIsValid() && this.initialModel.status !== StringUtils.ACTIVE;
	}

	changeDetailsHasErrors() {
		return this.setAssignmentStatusTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = await this.assignmentService.updateMeasureStatus(
				this.editModelIds,
				this.initialModel.status,
				this.initialModel.notes,
				this.selectedOrganization.id
			);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.setAssignmentStatusTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Assignment) {
		return new Assignment(item);
	}

	hasUnsavedChanges() {
		return false;
	}
}
