import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { MediaWidgetItemViewerComponent } from 'app-core/media/widget/item/viewer/media-widget-item-viewer.component';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';
import { SwalConfig } from 'app-core/shared-core/swal/swal-config.component';
import { ColorUtils } from 'app-core/shared-core/tools/color-utils';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { Entity } from 'app-inspection/entity/entity';
import { ListEntityComponent } from 'app-inspection/entity/list-entity/list-entity.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
	selector: 'handle-entities-tab',
	templateUrl: './handle-entities-tab.component.html',
	styleUrls: ['./handle-entities-tab.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandleEntitiesTabComponent extends SimpleHandleTabDirective<Entity> {

	colorUtils = ColorUtils;

	currentFacilityName: string;

	actionsOpened: boolean = false;

	bsModalRef: BsModalRef;

	@Input() facilityId: string;
	@Input() facilityIds: string[] = [];
	@Input() displayFacilityNames: boolean = false;
	@Input() usePopoverRemoveButton: boolean = false;

	@Output() onDeleteEntities = new EventEmitter<string[]>();

	toggleListEntityModal() {
		this.bsModalRef = this.modalService.show(
			ListEntityComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: this.readonlyListModal,
					hideTableHeaderActions: this.readonlyListModal,
					hideTableRowActions: this.readonlyListModal,
					idsToSetAsSelected: this.handleItems.map(item => item.id),
					facilityId: this.facilityId,
					facilityIds: this.facilityIds
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	setupHover(div: HTMLDivElement) {
		const backgroundImageUrl = div.style.backgroundImage.slice(4, -1).replace(/"/g, "");
		DomUtils.showLargerImageOnHover(div, false, backgroundImageUrl);

		// Click
		const substringedImageUrl = backgroundImageUrl.substring(0, backgroundImageUrl.lastIndexOf('\/'));
		div.addEventListener('click',
			(event) => {
				event.stopPropagation();
				const item = new MediaWidgetItem({
					isImage: true,
					url: substringedImageUrl.slice(substringedImageUrl.lastIndexOf('\/') + 1)
				});
				if (this.bsModalRef) {
					this.closeModal();
				}
				setTimeout(() => {
					if (!this.bsModalRef) {
						this.bsModalRef = this.modalService.show(
							MediaWidgetItemViewerComponent,
							{
								initialState: {
									item: item,
									collection: [item]
								},
								...ConfigUtils.MODAL_CONFIG_LARGE_WITH_OUTSIDE_CLICK
							}
						);

						this.subscribeToCrudModalContent();
					}
				});
			});
	}

	itemListIsValid() {
		return true;
	}

	inlineListHasUnsavedChanges() {
		return this.currentCrudModalComponent?.hasUnsavedChanges();
	}

	override emitModelsChange() {
		if (this.displayFacilityNames) {
			this.handleItems.sortByProperty(`${this.propertyStrings.facility} ${this.propertyStrings.name}`);
		} else {
			this.handleItems.sortByProperty(this.propertyStrings.name);
		}
		super.emitModelsChange();
	}

	protected validate() { }

	shouldDisplayFacilityName(item: Entity) {
		if (item.facility.name !== this.currentFacilityName) {
			this.currentFacilityName = item.facility.name;
			return true;
		} else {
			return false;
		}
	}

	getItems() {
		return this.searchValue
			? this.handleItems.filter(item => item.name.toLowerCase().includes(this.searchValue.toLowerCase())
				|| item.description.toLowerCase().includes(this.searchValue.toLowerCase()))
			: this.handleItems;
	}

	deleteFromOrg(item: Entity) {
		const ids = [item.id]
		this.onDeleteEntities.emit(ids);
		this.remove(item);
	}

	deleteSelectedFromOrg() {
		const selectedItems = this.handleItems.filter(item => item.selected === true);
		Swal.fire(
			new SwalConfig(this.translationService).getDelete({
				cancelButtonText: this.translationService.instant('Cancel'),
				confirmButtonText: `${this.translationService.instant('Delete')} (${selectedItems.length})`
			})
		).then(async result => {
			if (result.value) {
				const ids = selectedItems.map(item => item.id);
				this.onDeleteEntities.emit(ids);
				this.removeSelected();
			}
		});
	}
}
