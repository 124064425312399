import { Injectable } from '@angular/core';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { Task, TaskPayload } from './task';
import { BaseService } from '../../app-core/shared-core/abstract-components/service/base.service';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { Filter } from 'app-core/shared-core/filter';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';

@Injectable()
export class TaskService extends BaseService<Task> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
			super(
				hostedHttpClient,
				RoutesUtils.task
			);
	}

	createWithOrgId(item: TaskPayload, organizationId: number) {
		return this.hostedHttpClient.post(`${this.prefix}?organization=${organizationId}`, [item]);
	}

	updateWithOrgId(item: TaskPayload, organizationId: number) {
		return this.hostedHttpClient.put(`${this.prefix}?organization=${organizationId}`, [item]);
	}

	instantiateModel(item: Task) {
		return new Task(item);
	}
}

export class TaskFilter extends Filter {
	includeChoices: boolean;

	toPayloadObject() {
		const tempFacets = {};
		const currentFacets = (Object.entries(this.facets) as [string, string][])
			.map(facet => {
				return {
					key: facet[0],
					value: facet[1]
				};
			});

		currentFacets.forEach(facet => {
			if (facet.key === StringUtils.RULES_KEY) {
				const rules = facet.value.split(',');
				rules.forEach(rule => {
					if (rule === StringUtils.IS_REQUIRED) {
						tempFacets[StringUtils.IS_REQUIRED_KEY] = 'true';
					} else if (rule === StringUtils.ALLOWS_COMMENT) {
						tempFacets[StringUtils.ALLOW_COMMENT_KEY] = 'true';
					} else if (rule === StringUtils.ALLOWS_MEDIA) {
						tempFacets[StringUtils.ALLOW_MEDIA_KEY] = 'true';
					} else if (rule === StringUtils.IS_DEFAULT) {
						tempFacets[StringUtils.IS_DEFAULT_KEY] = 'true';
					}
				});
			} else if (facet.key === StringUtils.IS_REQUIRED_KEY
				|| facet.key === StringUtils.ALLOW_COMMENT_KEY
				|| facet.key === StringUtils.ALLOW_MEDIA_KEY
				|| facet.key === StringUtils.IS_DEFAULT_KEY) {
					delete this.facets[facet.key];
			}
		});

		Object.assign(this.facets, tempFacets);

		// Exclude includeChoices property from the filterObject.
		const { includeChoices, ...theRest } = this;

		if (this.includeChoices) {
			return {
				filter: JSON.stringify(theRest),
				includeChoices: this.includeChoices
			};
		} else {
			return {
				filter: JSON.stringify(theRest)
			};
		}
	}
}
