import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'simple-change-tab',
	templateUrl: './simple-change-tab.component.html',
	styleUrls: ['./simple-change-tab.component.less']
})
export class SimpleChangeTabComponent {
	@Input() form: FormGroup;
	@Input() hideRequiredInfoText: boolean;
}
