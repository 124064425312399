import { Component } from '@angular/core';
import { SimpleReadModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-read-modal.directive';
import { MarkdownObject } from '../markdown-viewer.component';

@Component({
	templateUrl: './markdown-viewer-modal.component.html'
})
export class MarkdownViewerModalComponent extends SimpleReadModalDirective<MarkdownObject>  {
	titleText: string;
}
