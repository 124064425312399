import { Injectable } from '@angular/core';
import { BaseService } from 'app-core/shared-core/abstract-components/service/base.service';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { SwalConfig } from 'app-core/shared-core/swal/swal-config.component';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Assignment } from 'app-inspection/assignment/assignment';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Injectable()
export class AdministrationService extends BaseService<any> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService,
		private translationService: TranslationService,
		private toastr: ToastrService) {
		super(
			hostedHttpClient,
			RoutesUtils.admin
		);
	}

	async getAssignmentsWithMissingReports(): Promise<Assignment[]> {
		return this.hostedHttpClient.get(`${this.prefix}/missingreport`, {})
			.then(res => res.data.map((item: Assignment) => new Assignment(item)));
	}

	recreateReports(itemIds: string[], orgId: number) {
		const model = {
			itemIds: itemIds,
			organizationId: orgId
		};
		return this.hostedHttpClient.post(`${this.prefix}/recreatereport`, model);
	}

	async reactivateAssignment(assignmentId: string, newDueDate: string) {
		const model = {
			newDueDate: newDueDate
		};
		return this.hostedHttpClient.put(`${this.prefix}/reactivateassignment/${assignmentId}`, model);
	}

	async openRecreateReportSwal(assignmentId: string, orgId: number, event: () => void) {
		Swal.fire(
			new SwalConfig(this.translationService).getInfo({
				title: this.translationService.instant('RecreateAssignmentReport'),
				text: this.translationService.instant('RecreateAssignmentReportDescription'),
				cancelButtonText: this.translationService.instant('Cancel'),
				confirmButtonText: this.translationService.instant('RecreateConfirm')
			})
		).then(async result => {
			if (result.value) {
				this.toastr.info('Återskapning av rapporten är påbörjad');
				try {
					const response = await this.recreateReports([assignmentId], orgId);
					if (response.data.succeeded.length) {
						this.toastr.success('Rapporten är nu återskapad');
						event();
					} else {
						this.toastr.error('Det gick ej att återskapa rapporten');
					}

				} catch (errorResponse) {
					this.toastr.error('Det gick ej att återskapa rapporten');
					const messages1 = errorResponse.error?.errors['Report'] as Array<string>;
					if (messages1) {
						console.error(messages1.join());
					}
					const messages2 = errorResponse.error?.errors['Assignment'] as Array<string>;
					if (messages2) {
						console.error(messages2.join());
					}
				}
			}
		});
	}

	async openRecreateAssignmentSwal(assignmentId: string, newDueDate: string, event: () => void, isMeasure: boolean = false) {
		Swal.fire(
			new SwalConfig(this.translationService).getInfo({
				title: this.translationService.instant(isMeasure ? 'RecreateMeasureAssignment' : 'RecreateScheduledAssignment'),
				text: this.translationService.instant('RecreateAssignmentDescription'),
				cancelButtonText: this.translationService.instant('Cancel'),
				confirmButtonText: this.translationService.instant('RecreateConfirm')
			})
		).then(async result => {
			if (result.value) {
				this.toastr.info('Återskapning av uppdraget är påbörjad');
				try {
					await this.reactivateAssignment(assignmentId, newDueDate);
					this.toastr.success('Uppdraget är nu återskapat');
					event();

				} catch (errorResponse) {
					this.toastr.error('Det gick ej att återskapa uppdraget');
					const messages = errorResponse.error?.errors['Assignment'] as Array<string>;
					if (messages.length) {
						console.error(messages.join());
					}
				}
			}
		});
	}

	instantiateModel(_: any) {
		return null;
	}
}
