import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { MarkdownObject } from 'app-core/shared-core/markdown-viewer/markdown-viewer.component';
import { MarkdownViewerModalComponent } from 'app-core/shared-core/markdown-viewer/modal/markdown-viewer-modal.component';
import { SimpleViewTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/view/simple-view-tab.directive';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { User } from 'app-core/user/user';
import { Assignment, Result } from 'app-inspection/assignment/assignment';
import { StaticReportComponent } from 'app-inspection/assignment/report/static/static-report.component';
import { Entity } from 'app-inspection/entity/entity';
import { Facility } from 'app-inspection/facility/facility';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'view-details-tab',
	templateUrl: './view-details-tab.component.html',
	styleUrls: ['./view-details-tab.component.less']
})
export class ViewDetailsTabComponent extends SimpleViewTabDirective<Assignment> implements OnInit {

	completedTranslateParam: Object;
	resolvedTranslateParam: Object;
	createdTranslateParam: Object;
	numOfFacilityErrors: number;
	numOfEntityErrors: number;
	stringUtils = StringUtils;

	resolvementResult: Result;

	// todo behövs det overrida om ev. fixar typerna sen?
	bsModalRef: BsModalRef<any>;

	@Output() onTabSelect = new EventEmitter<number>();

	ngOnInit(): void {
		this.setTranslateParam();
		this.countErrors();
		this.viewModel.completedByUser = new User(this.viewModel.completedByUser);

		if (this.viewModel.isMeasureAssignment()) {
			this.setInspectedTranslateParam();
			this.setCreatedTranslateParam();

			this.viewModel.errorResult.objectName = this.isResultOnEntity() ? this.viewModel.entities[0].name : this.viewModel.facility.name;
			this.viewModel.createdByUser = new User(this.viewModel.errorAssignment.completedByUser);
		}

		this.resolvementResult = this.isResultOnEntity() ? this.viewModel.entities[0].results[0] : this.viewModel.facility.results[0];
	}

	setTranslateParam() {
		this.completedTranslateParam = {
			date: Utils.formatDate(this.viewModel.isMeasureAssignment() ? this.viewModel.errorAssignment.completed : this.viewModel.completed, 'lll')
		};
	}

	setInspectedTranslateParam() {
		this.resolvedTranslateParam = {
			date: Utils.formatDate(this.viewModel.completed, DEFAULT_DISPLAY_DATE_FORMAT)
		};
	}

	setCreatedTranslateParam() {
		this.createdTranslateParam = {
			date: Utils.formatDate(this.viewModel.created, DEFAULT_DISPLAY_DATE_FORMAT)
		};
	}

	getResultImages(media: MediaWidgetItem[]) {
		return media.filter(item => item.isImage);
	}

	getTranslateAmountParam(number: number) {
		return {
			amount: number
		};
	}

	countErrors() {
		if (!this.viewModel.isMeasureAssignment()) {
			this.viewModel.entities
				.filter(e => e.results)
				.forEach(entity => {
					entity.numberOfErrors = 0;
					entity.results
						.filter(r => r.task.choice.isError)
						.forEach(() => entity.numberOfErrors += 1);
				});
			this.viewModel.facility.numberOfErrors = 0;
			this.viewModel.facility.results
				.filter(r => r.task.choice.isError)
				.forEach(() => this.viewModel.facility.numberOfErrors += 1);
		}
	}

	getAmountOfSolvedFacility(facility: Facility) {
		return facility.results.filter(result => result.solved !== null).length;
	}

	getAmountOfSolvedEntity(entity: Entity) {
		return entity.results.filter(result => result.solved !== null).length;
	}

	isResultOnEntity() {
		return this.viewModel.isMeasureAssignment() && !!this.viewModel.entities.length;
	}

	assignmentHasResult() {
		return (this.isResultOnEntity() && (!!this.viewModel.entities.length && !!this.viewModel.entities[0].results.length))
			|| (!this.isResultOnEntity() && !!this.viewModel.facility.results.length);
	}

	assignmentHasStatusSaved() {
		return (Utils.dateIsAfter(this.viewModel.lastUpdated, this.viewModel.firstUpdated)
			&& (this.viewModel.dueDate && !this.assignmentHasStatusExpired() || !this.viewModel.dueDate)
			&& !this.assignmentHasStatusCompleted());
	}

	assignmentHasStatusExpired() {
		return this.viewModel.isExpired;
	}

	assignmentHasStatusCompleted() {
		return !!this.viewModel.completed;
	}

	assignmentHasStatusClosed() {
		return this.viewModel.status === StringUtils.CLOSED;
	}

	goToGroupsTab() {
		this.onTabSelect.emit((this.viewModel.isArchivedAssignment() || !this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)) ? 2 : 3);
	}

	goToAssigneesTab() {
		this.onTabSelect.emit((this.viewModel.isArchivedAssignment() || !this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)) ? 3 : 4);
	}

	getCreatedByUserUrl() {
		const pendingUrl = `${RoutesUtils.users}?${RoutesUtils.user}=${RoutesUtils.modalPrefixValueResendEmail}_${this.viewModel.createdByUser.id}&tab=1`;
		return this.viewModel.createdByUser.getCurrentStatus(this.selectedOrganization.id) === StringUtils.PENDING ? pendingUrl : this.viewModel.createdByUser.id === this.loggedInUser.id ? RoutesUtils.profile : this.viewModel.createdByUser.url;
	}

	getCompletedByUserUrl() {
		const pendingUrl = `${RoutesUtils.users}?${RoutesUtils.user}=${RoutesUtils.modalPrefixValueResendEmail}_${this.viewModel.completedByUser.id}&tab=1`;
		return this.viewModel.completedByUser.getCurrentStatus(this.selectedOrganization.id) === StringUtils.PENDING ? pendingUrl : this.viewModel.completedByUser.id === this.loggedInUser.id ? RoutesUtils.profile : this.viewModel.completedByUser.url;
	}

	openScope() {
		this.bsModalRef = this.modalService.show(
			MarkdownViewerModalComponent,
			{
				initialState: {
					readModel: new MarkdownObject({
						content: this.viewModel.assignmentTemplate.templateType.scope
					})
				},
				...ConfigUtils.MODAL_CONFIG_M_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	getReport() {
		this.bsModalRef = this.modalService.show(
			StaticReportComponent,
			{
				initialState: {
					editModelIds: [this.viewModel.id],
					disableTabIndexUrl: true,
					useAffectableIds: true,
					hideText: true,
					isMeasure: this.viewModel.isMeasureAssignment()
				},
				...ConfigUtils.MODAL_CONFIG_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	getHumanDueDate() {
		let humanDate = Utils.convertToHumanDate(this.viewModel.dueDate);
		humanDate = humanDate.charAt(0).toUpperCase() + humanDate.slice(1);
		return humanDate;
	}

	isTodayAndFuture() {
		return Utils.isTodayDate(this.viewModel.dueDate) && Utils.isFutureDate(this.viewModel.dueDate);
	}
}
