import { Directive, OnDestroy, OnInit } from '@angular/core';
import { SimpleReadModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-read-modal.directive';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { DEFAULT_DATE_FORMAT } from 'app-core/shared-core/tools/utils';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { UserSupportDirective } from '../user-support.directive';
import { UserSupportService } from '../user-support.service';
import { NotificationMessage } from './notification-message';

@Directive()
export abstract class NotificationMessageDirective extends UserSupportDirective implements OnInit, OnDestroy {

	notificationMessages: NotificationMessage[] = [];

	bsModalRef: BsModalRef<SimpleReadModalDirective<NotificationMessage>>;

	protected modalCloseSubscription = new Subscription();

	constructor(
		protected userSupportService: UserSupportService) {
		super(
			userSupportService
		);
	}

	async ngOnInit() {
		super.initiateFileSubscription();
	}

	protected shouldDisplay(message: NotificationMessage) {
		const today = moment().format(DEFAULT_DATE_FORMAT);
		return message.startDate <= today && (!message.endDate || message.endDate >= today);
	}

	protected closeModal() {
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef?.hide();
		this.bsModalRef = null;
		this.modalCloseSubscription.unsubscribe();
	}

	protected abstract mapData(): void;

	ngOnDestroy() {
		this.modalCloseSubscription.unsubscribe();
		super.ngOnDestroy();
	}
}
