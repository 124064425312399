import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app-core/auth/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { Router } from '@angular/router';
import { User } from 'app-core/user/user';
import { RegexUtils } from 'app-core/shared-core/tools/regex-utils';

@Component({
	selector: 'resend-email-tab',
	templateUrl: './resend-email-tab.component.html',
	styleUrls: ['./resend-email-tab.component.less']
})
export class ResendEmailTabComponent extends SimpleChangeTabDirective<User> implements OnInit {

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.form = this.formBuilder.group({
			[this.propertyStrings.email]: [this.changeModel.email,
			[Validators.required, this.emailValidator]
			]
		});
		super.ngOnInit();
	}

	private emailValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.EMAIL).test(control.value)) {
			return { email: true };
		} else {
			return null;
		}
	}
}
