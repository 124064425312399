export class RegexUtils {
	public static EMAIL = /^[a-zåäöA-ZÅÄÖ0-9._%+-]+@[a-zåäöA-ZÅÄÖ0-9.-]+\.[a-zåäöA-ZÅÄÖ]{2,}$/i;
	public static EMAIL_FIRST = /^[a-zåäöA-ZÅÄÖ0-9._%+-]+@.*/i;
	public static EMAIL_AT = /@/;
	public static EMAIL_DOMAIN = /@[a-zåäöA-ZÅÄÖ0-9.-]+.\./i;
	public static EMAIL_LAST = /\.[a-zåäöA-ZÅÄÖ]{2,}$/i;

	public static PHONE = /^[^a-zåäöA-ZÅÄÖ][0-9-+(). ]{3,8}$/;
	public static PHONE_NO_LETTERS = /^[^a-zåäöA-ZÅÄÖ][0-9-+(). ]{0,}$/;
	public static PHONE_LENGTH = /^.{3,20}$/;
	public static PHONE_SE = /^SE$/;
	public static PHONE_GB = /^GB$/;

	public static PASSWORD = /^(?=.*[a-zåäö])(?=.*[A-ZÅÄÖ])(?=.*\d)(?=.*[$@$!%*?&])[A-ZÅÄÖa-zåäö\d$@$!%*?&]{6,}/;
	public static PASSWORD_DIGIT = /[\d]/;
	public static PASSWORD_LOWER = /[a-zåäö]/;
	public static PASSWORD_UPPER = /[A-ZÅÄÖ]/;
	public static PASSWORD_SPECIAL = /[$@$!%*?&^.]/;
	public static PASSWORD_LENGTH = /.{6,}/;

	public static ORGANIZATION_NUMBER = /^(\d{1})(\d{5})[-]?(\d{4})$/;
	public static ORGANIZATION_NUMBER_LENGTH = /^(.{1})(.{5})(\-?)(.{4})$/;

	public static VAT = /^((SE)?[0-9]{12})$|^((GB)?([0-9]{9}([0-9]{3})?$|^[A-Z]{2}[0-9]{3}))$|^((NO)?[0-9]{9}(MVA))$|^((CO)?[0-9]{10})$/;
	public static VAT_SE = /^((SE)?[0-9]{12})$/;
	public static VAT_GB = /^((GB)?([0-9]{9}([0-9]{3})?$|^[A-Z]{2}[0-9]{3}))$/;
	public static VAT_NO = /^((NO)?[0-9]{9}(MVA))$/;
	public static VAT_CO = /^((CO)?[0-9]{10})$/;

	public static LATITUDE = /^[-+]?([1-8]?\d((\.|,)\d+)?|90((\.|,)0+)?)$/;
	public static LONGITUDE = /^[-+]?(180((\.|,)0+)?|((1[0-7]\d)|([1-9]?\d))((\.|,)\d+)?)$/;

	public static NUMBER = /^[0-9]*$/;
	public static RADIUS = /^(10[0-9]|1[1-9]\d|[2-9]\d\d|1000)$/;

	public static URL = /^https?:\/\/(www\.)?[-a-zåäöA-ZÅÄÖ0-9@:%._\+~#=]{2,256}\.[a-zåäö]{2,6}\b([-a-zåäöA-Z0-9@:%_\+.~#?&//=]*)/;
}
