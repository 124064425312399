import {
	Component,
	ViewChild,
	OnInit
} from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Address, Organization, Phone } from '../organization';
import { OrganizationService } from '../organization.service';
import { ChangeDetailsTabComponent } from './tabs/change-details-tab.component';
import { UserService } from 'app-core/user/user.service';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';

@Component({
	templateUrl: './create-edit-organization.component.html'
})
export class CreateEditOrganizationComponent extends SimpleCreateEditModalDirective<Organization> implements OnInit {

	@ViewChild(ChangeDetailsTabComponent) changeOrganizationDetailsTabComponent: ChangeDetailsTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private organizationService: OrganizationService,
		private userService: UserService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			if (!this.userService.cultureSettings) {
				this.userService.cultureSettings = await this.userService.getCultureSettings();
			}

			if (this.isEdit) {
				this.initialModel = this.editModelId === this.selectedOrganization.id ? this.selectedOrganization : await this.organizationService.get(this.editModelId);
				this.pending = false;

				this.assignCultureSettings();

				this.modifiedModel = new Organization(this.getUniqueVariant(this.initialModel));

				setTimeout(() => {
					this.triggerAllValidation();
				}, 0);
			} else {
				this.initialModel = new Organization({
					parentId: this.selectedOrganization.id,
					parentOrganization: this.selectedOrganization,
					culture: 'sv-SE'
				});
				this.pending = false;

				this.assignCultureSettings();

				this.modifiedModel = new Organization(this.getUniqueVariant(this.initialModel));
			}

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	private assignCultureSettings() {
		this.initialModel.selectableCountryCodes = this.userService.cultureSettings.countryCodes;
		this.initialModel.selectableCountries = this.userService.cultureSettings.countries;
		this.initialModel.selectableCultures = this.userService.cultureSettings.cultures;
	}

	changeDetailsIsValid() {
		return this.changeOrganizationDetailsTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeOrganizationDetailsTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.organizationService.update(this.modifiedModel.toPayloadObject())
				: await this.organizationService.create(this.modifiedModel.toPayloadObject());

			if (this.isEdit) {
				if (this.modifiedModel.emailAddress !== this.initialModel.emailAddress) {
					this.modifiedModel.emailObject.emailAddress = this.modifiedModel.emailAddress;
					this.modifiedModel.emailObject.isDefault = true;
					await this.organizationService.updateEmail(this.modifiedModel.emailObject);
				}
				if (this.modifiedModel.countryCode !== this.initialModel.countryCode || this.modifiedModel.phoneNumber !== this.initialModel.phoneNumber) {
					if (this.initialModel.phoneNumber) {
						if (this.modifiedModel.phoneNumber) {
							this.modifiedModel.phoneObject.countryCode = this.modifiedModel.countryCode;
							this.modifiedModel.phoneObject.number = this.modifiedModel.phoneNumber;
							await this.organizationService.updatePhone(this.modifiedModel.phoneObject);
						} else {
							await this.organizationService.deletePhone(this.modifiedModel.phoneObject.id);
						}
					} else {
						this.modifiedModel.phoneObject = new Phone({
							countryCode: this.modifiedModel.countryCode,
							number: this.modifiedModel.phoneNumber,
							organizationId: this.selectedOrganization.id
						});
						await this.organizationService.createPhone(this.modifiedModel.phoneObject);
					}
				}
				if (this.modifiedModel.streetAddress !== this.initialModel.streetAddress || this.modifiedModel.zipCode !== this.initialModel.zipCode
					|| this.modifiedModel.city !== this.initialModel.city || this.modifiedModel.country !== this.initialModel.country) {
					if (this.initialModel.streetAddress) {
						if (this.modifiedModel.streetAddress) {
							this.modifiedModel.addressObject.streetAddress = this.modifiedModel.streetAddress;
							this.modifiedModel.addressObject.zipCode = this.modifiedModel.zipCode;
							this.modifiedModel.addressObject.city = this.modifiedModel.city;
							this.modifiedModel.addressObject.country = this.modifiedModel.country;
							await this.organizationService.updateAddress(this.modifiedModel.addressObject);
						} else {
							await this.organizationService.deleteAddress(this.modifiedModel.addressObject.id);
						}
					} else {
						this.modifiedModel.addressObject = new Address({
							streetAddress: this.modifiedModel.streetAddress,
							zipCode: this.modifiedModel.zipCode,
							city: this.modifiedModel.city,
							country: this.modifiedModel.country,
							organizationId: this.selectedOrganization.id
						});
						await this.organizationService.createAddress(this.modifiedModel.addressObject);
					}
				}
			}

			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeOrganizationDetailsTabComponent.setServerErrors(this.serverErrors);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeOrganizationDetailsTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Organization) {
		return new Organization(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.name !== this.initialModel.name
			|| this.modifiedModel.publicName !== this.initialModel.publicName
			|| this.modifiedModel.organizationNumber !== this.initialModel.organizationNumber
			|| this.modifiedModel.operationsManager !== this.initialModel.operationsManager
			|| this.modifiedModel.emailAddress !== this.initialModel.emailAddress
			|| this.modifiedModel.countryCode !== this.initialModel.countryCode
			|| this.modifiedModel.phoneNumber !== this.initialModel.phoneNumber
			|| this.modifiedModel.streetAddress !== this.initialModel.streetAddress
			|| this.modifiedModel.zipCode !== this.initialModel.zipCode
			|| this.modifiedModel.city !== this.initialModel.city
			|| this.modifiedModel.country !== this.initialModel.country
			|| this.modifiedModel.culture !== this.initialModel.culture
			|| this.modifiedModel.isTest !== this.initialModel.isTest;
	}

	hasUnsavedChanges() {
		return this.modifiedModel && this.detailsHasChanged();
	}
}
