<div class="item"
	[ngClass]="{
		'no-border': noBorder,
		'has-error': item.errorMessages.length,
		'valid': isValid
	}">
		<div class="item-content">
			<ng-content select="[slot=content]"></ng-content>
			<div class="actions">
				<ng-content select="[slot=actions]"></ng-content>
				<simple-link-button *ngIf="url && name"
					[url]="url"
					[name]="name"
					[shouldStopPropagation]="true">
				</simple-link-button>
				<div class="action bi-trash" *ngIf="!hideRemoveButton"
					title="{{'Remove' | translate}}"
					(click)="$event.stopImmediatePropagation(); remove(item)">
				</div>
			</div>
		</div>
		<ng-content select="[slot=contentExtra]"></ng-content>
		<div class="item-messages" *ngIf="item.errorMessages.length">
			<div class="item-message" *ngFor="let message of item.errorMessages">
				&bull; {{ message | translate }}
			</div>
		</div>
</div>
