import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { DotsLoaderModule } from 'app-core/shared-core/dots-loader/dots-loader.module';
import { CanEditPipe } from 'app-core/shared-core/pipes/can-edit.pipe';
import { GlobalOrganizationService } from '../organization/global-organization/global-organization.service';
import { HostedHttpClientService } from '../shared-core/hosted-httpclient.service';
import { AuthGuardHelper } from './auth-guard/auth-guard-helper';
import { AuthGuardService } from './auth-guard/auth-guard.service';
import { AuthService } from './auth.service';
import { CallbackComponent } from './callback.component';
import { RenewTokenComponent } from './renew-token.component';

/**
 * This modules takes care of the authentication of a user.
 * - AuthService handles all actions related to the current user, it signs the user in and out and handles authentication against the IdentityServer.
 * - AuthGuard is preventing users to access unpermitted routes.
 *
 * Sidenotes:
 * Providing the module one (for the app root) to make sure
 * those services are singletons.
 * Read: https://angular.io/docs/ts/latest/guide/ngmodule.html#!#shared-module
 */
@NgModule({
	imports: [
		HttpClientModule,
		DotsLoaderModule
	],
	declarations: [
		CallbackComponent,
		RenewTokenComponent
	],
	exports: [
		CallbackComponent
	],
	providers: [
		CanEditPipe
	]
})
export class AuthModule {
	public static forRoot(): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [
				HostedHttpClientService,
				AuthService,
				AuthGuardService,
				AuthGuardHelper,
				GlobalOrganizationService
			]
		};
	}

	constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
		if (parentModule) {
			throw new Error('AuthModule is already loaded. Import it in the AppModule only!');
		}
	}
}
