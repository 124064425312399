<simple-crud-modal
	[titleText]="'UserReal' | translate"
	[closeText]="'Close' | translate"
	[submitText]="'Send' | translate"
	[disableSubmit]="!everythingIsValid()"
	[isLoading]="pending"
	[displayDummy]="!initialModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="initialModel">
			<tab [customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi bi-card-text'"
						[title]="'Details' | translate"
						[helpText]="'UserChangeDetailsHelpText' | translate">
					</simple-tab-heading>
				</ng-template>
				<resend-email-tab
					[changeModel]="initialModel">
				</resend-email-tab>
			</tab>
		</tabset>
</simple-crud-modal>
