import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { MediaWidgetItemViewerComponent } from 'app-core/media/widget/item/viewer/media-widget-item-viewer.component';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';
import { ColorUtils } from 'app-core/shared-core/tools/color-utils';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { Facility } from 'app-inspection/facility/facility';
import { ListFacilityComponent } from 'app-inspection/facility/list-facility/list-facility.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'handle-facilities-tab',
	templateUrl: './handle-facilities-tab.component.html',
	styleUrls: ['./handle-facilities-tab.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandleFacilitiesTabComponent extends SimpleHandleTabDirective<Facility> {

	colorUtils = ColorUtils;

	bsModalRef: BsModalRef;

	@Input() displayOnlyFacilitiesWithPositionDataInList: boolean;
	@Input() useSingleSelectionInList: boolean;

	toggleListFacilityModal() {
		this.bsModalRef = this.modalService.show(
			ListFacilityComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: this.readonlyListModal,
					hideTableHeaderActions: this.readonlyListModal,
					hideTableRowActions: this.readonlyListModal,
					onlyFacilitiesWithPositionData: this.displayOnlyFacilitiesWithPositionDataInList,
					idsToSetAsSelected: this.handleItems.map(item => item.id),
					isSingleSelectTable: this.useSingleSelectionInList
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	setupHover(div: HTMLDivElement) {
		const backgroundImageUrl = div.style.backgroundImage.slice(4, -1).replace(/"/g, "");
		DomUtils.showLargerImageOnHover(div, false, backgroundImageUrl);

		// Click
		const substringedImageUrl = backgroundImageUrl.substring(0, backgroundImageUrl.lastIndexOf('\/'));
		div.addEventListener('click',
			(event) => {
				event.stopPropagation();
				const item = new MediaWidgetItem({
					isImage: true,
					url: substringedImageUrl.slice(substringedImageUrl.lastIndexOf('\/') + 1)
				});
				if (this.bsModalRef) {
					this.closeModal();
				}
				setTimeout(() => {
					if (!this.bsModalRef) {
						this.bsModalRef = this.modalService.show(
							MediaWidgetItemViewerComponent,
							{
								initialState: {
									item: item,
									collection: [item]
								},
								...ConfigUtils.MODAL_CONFIG_LARGE_WITH_OUTSIDE_CLICK
							}
						);

						this.subscribeToCrudModalContent();
					}
				});
			});
	}

	itemListIsValid() {
		return true;
	}

	override emitModelsChange() {
		this.handleItems.sortByProperty(this.propertyStrings.name);
		super.emitModelsChange();
	}

	protected validate() { }

	getItems() {
		return this.searchValue
			? this.handleItems.filter(item => item.name.toLowerCase().includes(this.searchValue.toLowerCase())
				|| item.description.toLowerCase().includes(this.searchValue.toLowerCase()))
			: this.handleItems;
	}
}
