import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { TaskService } from 'app-inspection/task/task.service';
import { Task } from 'app-inspection/task/task';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { ChangeDetailsTabComponent } from './tabs/change-details-tab.component';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
	templateUrl: './create-edit-task.component.html'
})
export class CreateEditTaskComponent extends SimpleCreateEditModalDirective<Task> implements OnInit {

	@ViewChild(ChangeDetailsTabComponent) changeTaskDetailsTabComponent: ChangeDetailsTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private taskService: TaskService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		if (this.isEdit) {
			this.pending = true;
			try {
				this.initialModel = await this.taskService.get(this.editModelId);
				this.pending = false;

				this.modifiedModel = new Task(this.getUniqueVariant(this.initialModel));

				setTimeout(() => {
					this.triggerAllValidation();
				}, 0);

			} catch (errorResponse) {
				this.pending = false;
				this.handleErrorResponse(errorResponse);
				this.closeWithDelay();
			}
		} else {
			this.initialModel = new Task({});
			this.modifiedModel = new Task(this.getUniqueVariant(this.initialModel));
		}
	}

	changeDetailsIsValid() {
		return this.changeTaskDetailsTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeTaskDetailsTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.taskService.updateWithOrgId(this.modifiedModel.toPayloadObject(), this.selectedOrganization.id)
				: await this.taskService.createWithOrgId(this.modifiedModel.toPayloadObject(), this.selectedOrganization.id);

			const customMessage = this.isEdit
				? this.translationService.instant('BackgroundJobStartedEditTasks')
				: this.translationService.instant('BackgroundJobStartedCreateTasks');

			this.handleSuccessResponse(response, customMessage);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeTaskDetailsTabComponent.setServerErrors(this.serverErrors);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeTaskDetailsTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Task) {
		return new Task(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.text !== this.initialModel.text
			|| this.modifiedModel.code !== this.initialModel.code
			|| this.modifiedModel.description !== this.initialModel.description
			|| this.modifiedModel.isRequired !== this.initialModel.isRequired
			|| this.modifiedModel.isDefault !== this.initialModel.isDefault
			|| this.modifiedModel.allowComment !== this.initialModel.allowComment
			|| this.modifiedModel.allowMedia !== this.initialModel.allowMedia;
	}

	hasUnsavedChanges() {
		return this.modifiedModel && this.detailsHasChanged();
	}
}
