<div class="form-info">
	<ng-container *ngIf="firstText">
		{{ firstText }}
	</ng-container>
	<simple-link-button *ngIf="linkText && linkHref"
		[url]="linkHref"
		[linkText]="linkText"
		[name]="name">
	</simple-link-button>
	<ng-container *ngIf="secondText">
		{{ secondText }}
	</ng-container>
</div>
