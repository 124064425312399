import { NgModule } from '@angular/core';
import { CopyClipboardDirective } from './copy-to-clipboard.directive';
import { InputTrimmerDirective } from './input-trimmer.directive';
import { ScrollShadowDirective } from './scroll-shadow.directive';

@NgModule({
	declarations: [
		CopyClipboardDirective,
		ScrollShadowDirective,
		InputTrimmerDirective
	],
	exports: [
		CopyClipboardDirective,
		ScrollShadowDirective,
		InputTrimmerDirective
	]
})
export class DirectivesModule { }
