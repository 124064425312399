import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SimpleListAction } from '../../actions/simple-list-action';
import { SimpleTableEmptyState } from './simple-table-empty-state';

@Component({
	selector: 'simple-table-empty-state',
	templateUrl: './simple-empty-state.component.html',
	styleUrls: ['./simple-empty-state.component.less']
})
export class SimpleEmptyStateComponent {

	@Input() emptyState: SimpleTableEmptyState;
	@Input() isInModal: boolean;
	@Input() hasActiveSearchOrFilter: boolean;
	@Input() listActions: SimpleListAction[];
	@Input() hideListActions: boolean;
	@Input() filterOpened: boolean;

	@Output() onListActionClick = new EventEmitter<SimpleListAction>();

	handleListActionClick(action: SimpleListAction) {
		this.onListActionClick.emit(action);
	}
}
