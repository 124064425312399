import { Component, ComponentRef, ElementRef, SecurityContext, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { ExportService } from 'app-core/shared-core/export/export.service';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { ImportActionKey, ImportExportModelKey } from 'app-core/shared-core/import/import-helper';
import { ImportService } from 'app-core/shared-core/import/import.service';
import { MarkdownObject } from 'app-core/shared-core/markdown-viewer/markdown-viewer.component';
import { MarkdownViewerModalComponent } from 'app-core/shared-core/markdown-viewer/modal/markdown-viewer-modal.component';
import { SimpleListAction } from 'app-core/shared-core/simple-components/list/actions/simple-list-action';
import { SimpleListDirective } from 'app-core/shared-core/simple-components/list/simple-list.directive';
import { SimpleTableRowAction, SimpleTableRowActionDelete, SimpleTableRowActionEdit, SimpleTableRowActionKeepOpen } from 'app-core/shared-core/simple-components/list/table/body/simple-table-row-action';
import { EventsContent, SimpleTableEventsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-events-column';
import { IconContent, SimpleTableIconsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-icons-column';
import { SimpleTableStatusColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-status-column';
import { SimpleTableTextColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-text-column';
import { SimpleTableThumbnailMainSecondaryColumn, ThumbnailTextContent } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-thumbnail-main-secondary-column';
import { SimpleTableEmptyState } from 'app-core/shared-core/simple-components/list/table/empty-state/simple-table-empty-state';
import { SimpleFilterHelperTemplateType } from 'app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-template-type';
import { SimpleFilterInput, SimpleFilterInputType } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-input-settings';
import { SimpleFilterListModalInput } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-list-modal-input-settings';
import { SimpleFilterInputItem } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-input-item';
import { SimpleRetainService } from 'app-core/shared-core/simple-components/list/table/filter/simple-retain.service';
import { SimpleTableHeaderAction, SimpleTableHeaderActionDelete } from 'app-core/shared-core/simple-components/list/table/head/simple-table-header-action';
import { TextContent } from 'app-core/shared-core/simple-components/various/text-content/simple-text-content.component';
import { SwalConfig } from 'app-core/shared-core/swal/swal-config.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils, coordinateSystems } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { ViewObjectsComponent, ViewObjectsType } from 'app-core/shared-core/view-objects/view-objects.component';
import { AssignmentService } from 'app-inspection/assignment/assignment.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { SimpleFilterConfig, SortObject } from '../../../app-core/shared-core/simple-components/list/table/filter/simple-filter-config';
import { SimpleTableConfig } from '../../../app-core/shared-core/simple-components/list/table/simple-table-config';
import { CreateEditFacilityComponent } from '../create-edit-facility/create-edit-facility.component';
import { DeleteFacilitiesComponent } from '../delete-facilities/delete-facilities.component';
import { Facility } from '../facility';
import { FacilityFilter, FacilityService } from '../facility.service';
import { SetAccessibilityFacilityComponent } from './set-accessibility/set-accessibility-facility.component';
import { SetStatusFacilityComponent } from './set-status/set-status-facility.component';

@Component({
	selector: 'list-facility',
	templateUrl: '../../../app-core/shared-core/simple-components/list/simple-list-shared-template.html',
	providers: [SimpleFilterHelperTemplateType]
})
export class ListFacilityComponent extends SimpleListDirective<Facility> {

	// Override filterObject to match backend.
	readonly filterObject = new FacilityFilter();

	shouldShowEntitiesDeletionOption: boolean = false;

	// When used inside a modal.
	onlyActiveFacilities: boolean;
	onlyFacilitiesWithPositionData: boolean;

	componentRef: ComponentRef<ViewObjectsComponent>;

	// Override bsModalRef to be able to open ViewDirectives
	bsModalRef: BsModalRef<any>;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected sanitizer: DomSanitizer,
		protected facilityService: FacilityService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected retainService: SimpleRetainService,
		protected elementRef: ElementRef,
		protected viewContainerRef: ViewContainerRef,
		private importService: ImportService<Facility>,
		private exportService: ExportService<Facility>,
		private assignmentService: AssignmentService,
		private simpleFilterHelperTemplateType: SimpleFilterHelperTemplateType) {
		super(
			authService,
			modalService,
			toastrService,
			translationService,
			facilityService,
			route,
			router,
			retainService,
			elementRef,
			viewContainerRef,
			RoutesUtils.facility
		);
	}

	protected configureListActions() {
		this.setListActions(
			new SimpleListAction(
				this.translationService.instant('Create'),
				this.translationService.instant('NewFacility'),
				StringUtils.icons.new,
				() => {
					if (!this.isInModal) {
						this.setCrudParams(RoutesUtils.modalPrefixValueNew);
					} else {
						this.openModalByActionName(RoutesUtils.modalPrefixValueNew);
					}
				}
			),
			new SimpleListAction(
				this.translationService.instant('Import'),
				this.translationService.instant('Import'),
				StringUtils.icons.import,
				() => {
					this.importInput.nativeElement.click();
				},
				() => {
					return !this.loggedInUser.isSuperAdmin();
				}
			),
			new SimpleListAction(
				this.translationService.instant('Export'),
				this.translationService.instant('Export'),
				StringUtils.icons.export,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.export(selectedIds);
				},
				() => {
					return !this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl);
				}
			)
		);

		if (this.loggedInUser.isSuperAdmin()) {

			// Check if has any imports to start monitoring.
			this.importService.hasImportInProgress(ImportExportModelKey.Facility, ImportActionKey.ImportFacilities)
				.then(inProgress => {
					if (inProgress) {
						this.importService.monitorProgress(ImportExportModelKey.Facility, ImportActionKey.ImportFacilities, this);
					}
				})
				.catch(response => {
					this.handleErrorResponse(response);
				});
		}
	}

	protected async configureTableFilter(config: SimpleFilterConfig<Facility>) {
		if (this.onlyFacilitiesWithPositionData || this.onlyActiveFacilities) {
			const initialFacets = [];
			if (this.onlyFacilitiesWithPositionData) {
				initialFacets.push(new KeyValuePair(StringUtils.LOCATION_DATA_KEY, StringUtils.YES));
			}
			if (this.onlyActiveFacilities) {
				initialFacets.push(new KeyValuePair(StringUtils.STATUS_KEY, 'true'));
			}
			config.setInitialFacets(
				...initialFacets
			);
		}

		const locationDataKey = StringUtils.LOCATION_DATA_KEY;
		const accessibleKey = StringUtils.ACCESSIBILITY_KEY;
		const createdKey = StringUtils.CREATED_KEY;
		const updatedKey = StringUtils.UPDATED_KEY;
		const statusKey = StringUtils.STATUS_KEY;
		const templateTypesKey = StringUtils.TEMPLATE_TYPES_KEY;
		const hasAssignmentTemplatesKey = StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY;
		const hasSchedulesKey = StringUtils.HAS_SCHEDULES_KEY;
		if (!this.isInModal) {
			this.retainService.setCurrentRetainEntries({
				search: null,
				sort: null,
				[locationDataKey]: null,
				[accessibleKey]: null,
				[createdKey]: null,
				[updatedKey]: null,
				[statusKey]: null,
				[templateTypesKey]: null,
				[hasAssignmentTemplatesKey]: null,
				[hasSchedulesKey]: null
			});
		}

		try {
			const [templateTypes] = await Promise.all([
				this.assignmentService.getTemplateTypes(this.selectedOrganization.id)
			]);

			const convertedTemplateTypes = templateTypes.filter(templateType => !templateType.isManualMeasure).map(templateType => new SimpleFilterInputItem(templateType.id, templateType.name));



			config.setFilterInputs(
				new SimpleFilterInput(
					createdKey,
					this.translationService.instant(createdKey),
					SimpleFilterInputType.DateSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(createdKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(createdKey, ''));
						}
						return keyValuePairs;
					}
				),
				new SimpleFilterInput(
					updatedKey,
					this.translationService.instant(updatedKey),
					SimpleFilterInputType.DateSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(updatedKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(updatedKey, ''));
						}
						return keyValuePairs;
					}
				),
				new SimpleFilterInput(
					accessibleKey,
					this.translationService.instant(accessibleKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(accessibleKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(accessibleKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.YES,
						this.translationService.instant(StringUtils.YES)
					),
					new SimpleFilterInputItem(
						StringUtils.NO,
						this.translationService.instant(StringUtils.NO)
					)
				),
				new SimpleFilterInput(
					locationDataKey,
					this.translationService.instant(locationDataKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(locationDataKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(locationDataKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.YES,
						this.translationService.instant(StringUtils.YES)
					),
					new SimpleFilterInputItem(
						StringUtils.NO,
						this.translationService.instant(StringUtils.NO)
					)
				),
				new SimpleFilterListModalInput(
					templateTypesKey,
					this.translationService.instant(templateTypesKey),
					SimpleFilterInputType.ListModal,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(templateTypesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(templateTypesKey, ''));
						}
						return keyValuePairs;
					},
					(filterInput: SimpleFilterListModalInput) => {
						this.simpleFilterHelperTemplateType.openListModal(filterInput, templateTypes, this);
					},
					convertedTemplateTypes
				),
				new SimpleFilterInput(
					hasAssignmentTemplatesKey,
					this.translationService.instant(hasAssignmentTemplatesKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(hasAssignmentTemplatesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(hasAssignmentTemplatesKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.YES,
						this.translationService.instant(StringUtils.YES)
					),
					new SimpleFilterInputItem(
						StringUtils.NO,
						this.translationService.instant(StringUtils.NO)
					)
				),
				new SimpleFilterInput(
					hasSchedulesKey,
					this.translationService.instant(hasSchedulesKey),
					SimpleFilterInputType.SingleSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(hasSchedulesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(hasSchedulesKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.YES,
						this.translationService.instant(StringUtils.YES)
					),
					new SimpleFilterInputItem(
						StringUtils.NO,
						this.translationService.instant(StringUtils.NO)
					)
				),
				!this.onlyActiveFacilities ?
					new SimpleFilterInput(
						statusKey,
						this.translationService.instant(statusKey),
						SimpleFilterInputType.SingleSelect,
						(values: string) => {
							const keyValuePairs: KeyValuePair[] = [];
							if (values) {
								keyValuePairs.push(new KeyValuePair(statusKey, values));
							} else {
								keyValuePairs.push(new KeyValuePair(statusKey, ''));
							}
							return keyValuePairs;
						},
						new SimpleFilterInputItem(
							StringUtils.ACTIVE,
							this.translationService.instant(StringUtils.ACTIVE)
						),
						new SimpleFilterInputItem(
							StringUtils.INACTIVE,
							this.translationService.instant(StringUtils.INACTIVE)
						)
					)
					: null
			);

			if (!this.isInModal) {
				// Needs to be called here due to the data fetch delay.
				this.setSelectedFilterItems();
			}

		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}

	protected configureTableSort(config: SimpleFilterConfig<Facility>) {
		config.setSortObjects(
			new SortObject(
				this.propertyStrings.name,
				this.translationService.instant('Name'),
				true
			),
			new SortObject(
				this.propertyStrings.description,
				this.translationService.instant('Description')
			),
			new SortObject(
				this.propertyStrings.city,
				this.translationService.instant('City')
			),
			new SortObject(
				this.propertyStrings.created,
				this.translationService.instant('Created')
			),
			new SortObject(
				this.propertyStrings.updated,
				this.translationService.instant('Updated')
			),
			new SortObject(
				this.propertyStrings.accessible,
				this.translationService.instant('Accessibility')
			),
			new SortObject(
				this.propertyStrings.hasPosition,
				this.translationService.instant('LocationData')
			),
			new SortObject(
				this.propertyStrings.status,
				this.translationService.instant('Status')
			)
		);
	}

	protected configureTableColumns(config: SimpleTableConfig<Facility>) {
		config.setColumns(
			new SimpleTableThumbnailMainSecondaryColumn(
				this.translationService.instant('Name'),
				(item) => new ThumbnailTextContent(
					item.thumbnailUrl,
					item.name,
					item.id
				),
				this.sanitizer,
				this.viewContainerRef,
				'',
				this.modalService
			),
			new SimpleTableTextColumn(
				this.translationService.instant('Description'),
				(item) => new TextContent(
					item.description,
					'',
					'',
					''
				),
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableTextColumn(
				this.translationService.instant('Address'),
				(item) => new TextContent(
					item.streetName && item.streetNumber && item.city
						? `${item.streetName} ${item.streetNumber}`
						: item.city,
					item.streetName && item.streetNumber && item.city
						? `${item.zipCode} ${item.city}`
						: '',
					'',
					''
				),
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableEventsColumn(
				this.translationService.instant('Events'),
				(item) => [
					new EventsContent(`${this.translationService.instant('Created')} ${Utils.getFormattedDateStringFromString(item.created, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.created, 'bi-file-earmark-plus'),
					Utils.dateIsAfter(item.updated, item.created)
						? new EventsContent(`${this.translationService.instant('Updated')} ${Utils.getFormattedDateStringFromString(item.updated, DEFAULT_DISPLAY_DATE_FORMAT)}`, item.updated, 'bi-file-earmark-text')
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableIconsColumn(
				'',
				(item) => [
					item.accessible
						? new IconContent('bi-person-wheelchair', '', this.translationService.instant('Accessibility'))
						: null,
					!!item.latitude && !!item.longitude
						? new IconContent('bi-geo-alt-fill', '', this.translationService.instant('LocationData'))
						: null,
					!!item.extraInfo
						? new IconContent('bi-card-heading', '', `${this.translationService.instant('ExtraInfo')}: ${this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(item.extraInfo)).toString()}`)
						: null,
					item.entities.length
						? new IconContent('ii-entities', item.entities.length.toString(), `${this.translationService.instant('Entities')}: ${item.entities.length.toString()}`)
						: null,
					item.assignmentTemplates.length
						? new IconContent('bi-map', item.assignmentTemplates.length.toString(), `${this.translationService.instant('AssignmentTemplates')}: ${item.assignmentTemplates.length.toString()}`)
						: null,
					item.schedules.length
						? new IconContent('bi-calendar-week', item.schedules.length.toString(), `${this.translationService.instant('Schedules')}: ${item.schedules.length.toString()}`)
						: null
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			!this.onlyActiveFacilities ?
				new SimpleTableStatusColumn(
					this.translationService.instant('Status'),
					(item) => item.status ?
						StringUtils.ACTIVE
						: StringUtils.INACTIVE,
					this.sanitizer,
					this.viewContainerRef,
					''
				)
				: null
		);
	}

	protected configureTableActions(config: SimpleTableConfig<Facility>) {

		// HEADER
		config.setHeaderActions(
			new SimpleTableHeaderAction(
				this.translationService.instant('SetAccessibility'),
				StringUtils.icons.setAccessibility,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueBulkSetAccessibility}_${selectedIds.join()}`);
				}
			),
			new SimpleTableHeaderAction(
				this.translationService.instant('SetStatus'),
				StringUtils.icons.setStatus,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueBulkSetStatus}_${selectedIds.join()}`);
				}
			),
			new SimpleTableHeaderActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.shouldShowEntitiesDeletionOption = true;
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${selectedIds.join()}`);
				}
			)
		);

		// ROW
		config.setRowActions(
			new SimpleTableRowActionEdit(
				this.translationService.instant('Open'),
				StringUtils.icons.handle,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					}
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('ViewExtraInfo'),
				StringUtils.icons.extraInfo,
				(row) => {
					this.setAsAffected([row.id]);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueViewMarkdown}_${row.id}`, row.data);
				},
				(row) => {
					return !row.data.extraInfo;
				}
			),
			new SimpleTableRowActionKeepOpen(
				this.translationService.instant('ViewEntities'),
				StringUtils.icons.entity,
				(row) => {
					this.setAsAffected([row.id]);

					this.prepareViewObjectsPopover(row.data, ViewObjectsType.entity, 1, row.id);
				},
				(row) => {
					return !row.data.entities.length;
				}
			),
			new SimpleTableRowActionKeepOpen(
				this.translationService.instant('ViewAssignmentTemplates'),
				StringUtils.icons.assignmentTemplate,
				(row) => {
					this.setAsAffected([row.id]);

					const entitiesExists = row.data.entities.length;
					this.prepareViewObjectsPopover(
						row.data,
						ViewObjectsType.assignmentTemplate,
						entitiesExists
							? 2
							: 1,
						row.id
					);
				},
				(row) => {
					return !row.data.assignmentTemplates.length;
				}
			),
			new SimpleTableRowActionKeepOpen(
				this.translationService.instant('ViewSchedules'),
				StringUtils.icons.schedule,
				(row) => {
					this.setAsAffected([row.id]);

					const entitiesExists = row.data.entities.length;
					const assignmentTemplatesExists = row.data.assignmentTemplates.length;
					this.prepareViewObjectsPopover(
						row.data,
						ViewObjectsType.schedule,
						entitiesExists
							? assignmentTemplatesExists
								? 3
								: 2
							: 1,
						row.id
					);
				},
				(row) => {
					return !row.data.schedules.length;
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('SetAccessibility'),
				StringUtils.icons.setAccessibility,
				(row) => {
					this.setAsAffected([row.id]);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueSetAccessibility}_${row.id}`);
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('SetStatus'),
				StringUtils.icons.setStatus,
				(row) => {
					this.setAsAffected([row.id]);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueSetStatus}_${row.id}`);
				}
			),
			new SimpleTableRowActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				(row) => {
					this.setAsAffected([row.id]);

					this.shouldShowEntitiesDeletionOption = !!row.data.entities.length;

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					}
				}
			)
		);
	}

	protected configureTableEmptyState(config: SimpleTableConfig<Facility>) {
		config.emptyState = new SimpleTableEmptyState(
			'Facilities',
			StringUtils.icons.facility
		);
	}

	protected setPropertiesAndOpenViewObjectsPopover(item: Facility, type: ViewObjectsType, rowClickNumber: number, rowId: string) {
		this.componentRef = this.viewContainerRef.createComponent(ViewObjectsComponent);
		this.componentRef.instance.rowId = rowId;
		this.componentRef.instance.type = type;

		switch (type) {
			case ViewObjectsType.assignmentTemplate:
				this.componentRef.instance.assignmentTemplates = item.assignmentTemplates;
				this.componentRef.instance.triggerViewChanges();
				this.openViewObjectsPopover(this.componentRef, rowClickNumber, rowId, StringUtils.icons.assignmentTemplate);
				break;
			case ViewObjectsType.schedule:
				this.componentRef.instance.schedules = item.schedules;
				this.componentRef.instance.triggerViewChanges();
				this.openViewObjectsPopover(this.componentRef, rowClickNumber, rowId, StringUtils.icons.schedule);
				break;
			case ViewObjectsType.entity:
				this.componentRef.instance.entities = item.entities;
				this.componentRef.instance.triggerViewChanges();
				this.openViewObjectsPopover(this.componentRef, rowClickNumber, rowId, StringUtils.icons.entity);
				break;
		}
	}

	protected openModalByActionName(value: string, facility?: Facility) {
		const [actionName, idString] = value.split('_');
		this.shouldClearAfterUpdate = !this.isInModal && this.isBulkAction(actionName);
		if (actionName === RoutesUtils.modalPrefixValueNew) {
			this.bsModalRef = this.modalService.show(
				CreateEditFacilityComponent,
				{
					initialState: {
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_M_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueEdit) {
			this.bsModalRef = this.modalService.show(
				CreateEditFacilityComponent,
				{
					initialState: {
						editModelId: idString,
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_M_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueBulkSetAccessibility || actionName === RoutesUtils.modalPrefixValueSetAccessibility) {
			this.bsModalRef = this.modalService.show(
				SetAccessibilityFacilityComponent,
				{
					initialState: {
						editModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueViewMarkdown) {
			this.bsModalRef = this.modalService.show(
				MarkdownViewerModalComponent,
				{
					initialState: {
						titleText: this.translationService.instant('ExtraInfo'),
						readModel: new MarkdownObject({
							content: facility ? facility.extraInfo : ''
						})
					},
					...ConfigUtils.MODAL_CONFIG_M_LARGE
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueBulkSetStatus || actionName === RoutesUtils.modalPrefixValueSetStatus) {
			this.bsModalRef = this.modalService.show(
				SetStatusFacilityComponent,
				{
					initialState: {
						editModelIds: idString.split(',')
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueDelete) {
			this.bsModalRef = this.modalService.show(
				DeleteFacilitiesComponent,
				{
					initialState: {
						deleteModelIds: idString.split(','),
						shouldShowEntitiesDeletionOption: this.shouldShowEntitiesDeletionOption
					},
					...ConfigUtils.MODAL_CONFIG_SMALL
				}
			);
		}
		this.subscribeToCrudModalContent();
	}

	protected import(file: File) {
		this.importService.hasImportInProgress(ImportExportModelKey.Facility, ImportActionKey.ImportFacilities)
			.then(async inProgress => {
				if (inProgress) {
					this.toastrService.error(`${this.translationService.instant(StringUtils.IMPORT_IN_PROGRESS)}!`);
				} else {
					let { value: coordinatSystem } = await Swal.fire(new SwalConfig(this.translationService).getInfo({
						title: this.translationService.instant('ChooseCoordinateSystem'),
						text: this.translationService.instant('ChooseCoordinateSystemStatement'),
						confirmButtonText: this.translationService.instant('Import'),
						cancelButtonText: this.translationService.instant('Cancel'),
						input: 'select',
						inputOptions: {
							doNotConvert: 'WGS84',
							...coordinateSystems,
						},
					}));

					if (coordinatSystem) {
						if (coordinatSystem === 'doNotConvert') {
							coordinatSystem = '';
						}
						this.importService.import(file, ImportExportModelKey.Facility, ImportActionKey.ImportFacilities, this, coordinatSystem as string);
					}
				}
			})
			.catch(response => {
				this.handleErrorResponse(response);
			});
	}

	protected export(selectedIds: string[]) {
		this.exportService.export(selectedIds, ImportExportModelKey.Facility, this);
	}
}
