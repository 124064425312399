import { Injectable } from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { HostedHttpClientService } from '../hosted-httpclient.service';
import { ImportExportModelKey } from '../import/import-helper';
import { ORG_QUERY_PARAM } from '../import/import.service';
import { SimpleListDirective } from '../simple-components/list/simple-list.directive';
import { FileData, FileHelper } from '../tools/file-helper';

@Injectable()
export class ExportService<T extends { id: string, url: string, selected: boolean }> {

	constructor(
		private hostedHttpClient: HostedHttpClientService,
		private fileHelper: FileHelper,
		private authService: AuthService) { }

	export(selectedIds: string[], modelName: ImportExportModelKey, listReference: SimpleListDirective<T>) {

		// Build endpoint.
		const selectedOrg = this.authService.selectedOrganization;
		const endpoint = `${modelName}/export${ORG_QUERY_PARAM}${selectedOrg.id.toString()}`;

		// Prepare payload.
		const propertyName = `${modelName}Ids`;
		const data = { [propertyName]: selectedIds };

		// Perform request.
		listReference.pending = true;
		this.hostedHttpClient.post(endpoint, data)
			.then(response => {
				listReference.pending = false;

				// this.validationHelper.showMessage({ // todo toastr?
				// 	succeeded: true,
				// 	successMessage: response.successMessage
				// });

				this.fileHelper.handleFile(new FileData(response.data.file));
			})
			.catch(response => {
				listReference.pending = false;
				//his.validationHelper.handleErrors(response, {}); // todo hantera fel!
			});
	}
}
