export class StringUtils {
	// Auto save brands.
	public static INPUT = 'INPUT';
	public static AUTO_SIZE_INPUT = 'AUTO_SIZE_INPUT';
	public static SELECT = 'SELECT';
	public static RADIO = 'RADIO';
	public static SWITCH = 'SWITCH';
	public static CHECKBOX = 'CHECKBOX';
	public static DROPDOWN = 'DROPDOWN';
	public static MULTI_SEARCH = 'MULTI_SEARCH';
	public static READ_ONLY = 'READ_ONLY';
	public static DROPDOWN_ICON = 'DROPDOWN_ICON';
	public static DROPDOWN_SWITCH = 'DROPDOWN_SWITCH';
	public static MEDIA = 'MEDIA';
	public static TEXTAREA = 'TEXTAREA';

	// Apx filter input brands.
	public static DATE_FILTER = 'DATE_FILTER';
	public static DATE_RANGE_FILTER = 'DATE_RANGE_FILTER';
	public static MULTI_FILTER = 'MULTI_FILTER';
	public static DROPDOWN_FILTER = 'DROPDOWN_FILTER';
	public static STRING_FILTER = 'STRING_FILTER';

	// Popover positions
	public static POPOVER_TOP = 'top';
	public static POPOVER_RIGHT = 'right';
	public static POPOVER_BOTTOM = 'bottom';
	public static POPOVER_LEFT = 'left';

	// Auto save input types.
	public static TEXT = 'text';
	public static NUMBER = 'number';
	public static PASSWORD = 'password';
	public static EMAIL = 'email';
	public static URL = 'url';
	public static COLOR = 'color';
	public static TEL = 'tel';
	public static TIME = 'time';

	// Auto save keys
	public static ENTER = 'Enter';
	public static ESCAPE = 'Escape';

	// Other keys
	public static TAB = 'Tab';
	public static ARROW_LEFT = 'ArrowLeft';
	public static ARROW_RIGHT = 'ArrowRight';

	// Crud message keys.
	public static NO_PERMISSION_TO_EDIT = 'No permission to edit';
	public static DELETED_ORGANIZATION = 'Organization was deleted';
	public static THIS_CAN_NOT_BE_UNDONE = 'This can not be undone';
	public static DELETE_USER = 'Delete user';
	public static DELETED_USER = 'User deleted';
	public static LEAVE_ORGANIZATION = 'Leave organization';
	public static ADD = 'Add';
	public static REMOVE = 'Remove';
	public static ARE_YOU_SURE = 'Are you sure';
	public static YES_KEEP_USERS = 'Yes, keep users';
	public static MAIN_ORG_WILL_CHANGE_OTHER = 'Main org will change other';
	public static USERS_TO_BE_REMOVED = 'UsersToBeRemoved';
	public static USERS_IN_OTHER_ORGS_REMAIN = 'UsersInOtherOrganizationsRemain';
	public static MAIN_ORG_WILL_CHANGE = 'Main org will change';
	public static SELECTED_ORG_NOT_FOUND = 'SelectedOrgNotFound';
	public static NO_OTHER_MAIN_ORG = 'No other main org';
	public static NOT_YOUR_MAIN_ORG = 'Not your main org';
	public static INCLUDING_YOURSELF = 'IncludingYourself';
	public static SUB_ORGS_WILL_BE_REMOVED = 'Sub orgs will be removed';
	public static DELETE_CONFIRM = 'DeleteConfirm';
	public static YES_DELETE_IT = 'Yes, delete it';
	public static YES_LEAVE_IT = 'Yes, leave it';
	public static NO = 'No';
	public static NOT_A_VALID_FILE_FORMAT = 'Not a valid file format';
	public static FILE_IS_TOO_LARGE = 'File is too large';
	public static DISABLE_PROVIDER = 'Disable provider';
	public static YES_DISABLE_IT = 'Yes, disable it';
	public static PROVIDER_DISABLED = 'Provider disabled';
	public static ADD_USER = 'Add user';
	public static FOLLOWING_NEEDS_TO_BE_FULFILLED = 'Following needs to be fulfilled';
	public static A_VALID_PASSWORD_NEEDS_AT_LEAST = 'A valid password needs at least';
	public static ONE_OF_THE_FOLOWING = 'Fullfill one of the following';

	public static FIRST_PART_CAN_NOT_CONTAIN_SPECIAL_CHARACTERS_OTHER_THAN = 'First part can not contain special characters other than';
	public static MUST_CONTAIN_A = 'Must contain a';
	public static DOMAIN_CAN_NOT_CONTAIN_SPECIAL_CHARACTERS_OTHER_THAN = 'Domain can not contain special characters other than';
	public static LAST_PART_MUST_BE_BETWEEN_2_AND_4_CHARACTERS = 'Last part must be between 2 and 4 letters and no special characters';
	public static BETWEEN_2_AND_15_CHARACTERS = 'Between 2 and 20 characters';
	public static NO_ALPHABETICAL_CHARACTERS = 'No alphabetical characters';
	public static INVALID_PHONE_FOR_REGION = 'Invalid phone number for region';
	public static VALID_PHONE_FOR_REGION = 'Valid phone number for region';

	public static SIX_CHARACTERS = '6 characters';
	public static ONE_DIGIT = '1 digit';
	public static ONE_LOWERCASE = '1 lowercase';
	public static ONE_UPPERCASE = '1 uppercase';
	public static ONE_NON_ALPHANUMERICAL = '1 non alphanumerical';

	public static SOMETHING_WENT_WRONG = 'SomethingWentWrong';
	public static PLEASE_TRY_AGAIN = 'Please try again';
	public static YOU_ARE_ALREADY_LOGGED_IN = 'You are already logged in';
	public static NO_PERMISSION = 'You do not have permission to view the page';
	public static NO_PERMISSION_TO_INVITE_SUPERADMINS = 'You do not have permission to invite superadmins';

	public static ERROR_REQUEST_FAILED = 'ErrorRequestFailed';
	public static ERROR_NOT_FOUND = 'ErrorNotFound';

	public static SERVER_ERROR = 'ServerError';
	public static SERVER_ERRORS = 'serverErrors';

	public static ADDITIONAL_INFO_CANCEL = 'AdditionalInfoCancel';

	// Register page info text.
	public static YOU_HAVE_BEEN_INVITED_TO_JOIN = 'You have been invited to join';
	public static ON_THE_INQUINOVA_PLATFORM = 'on the Inquinova platform';
	public static FILL_IN_AND_SUBMIT = 'fill in and submit the form to create a new account';

	public static ZIPCODE_FIRST = 'zipcode_validation';
	public static ZIPCODE_SECOND = '5 digits, e.g.';
	public static ZIPCODE_FORMAT = 'Have following format';

	public static VAT_SE = 'VAT_SE';
	public static VAT_GB = 'VAT_GB';
	public static VAT_NO = 'VAT_NO';
	public static VAT_CO = 'VAT_CO';

	public static INVALID_VALUE = 'The value is invalid';
	public static ONLY_DIGITS = 'It can only contain digits';

	public static ORGANIZATION_NUMBER_LENGTH = 'It must contain 10 digits';
	public static ORGANIZATION_NUMBER_TITLE = 'The number is invalid';

	// Default header logo url
	public static DEFAULT_HEADER_LOGO = '/assets/images/graphics/platform-logo-inquinova.svg';
	public static DEFAULT_HEADER_LOGO_SMALL = '/assets/images/icons/favicon/android-icon-48x48.png';

	// User Roles
	public static USER_ROLES = [
		'User'
	];
	public static ADMIN_ROLES = [
		'Administrator'
	];
	public static SUPER_ADMIN_ROLES = [
		'SuperAdmin',
		'Developer',
		'Production'
	];

	// External account providers
	public static EXTERNAL_LOGIN_PROVIDERS = [
		'Facebook',
		'Twitter',
		'Google',
		'Microsoft'
	];

	// Statuses.
	public static ACTIVE = 'Active';
	public static INACTIVE = 'Inactive';
	public static PENDING = 'Pending';
	public static STARTED = 'Started';
	public static COMPLETED = 'Completed';
	public static EXPIRED = 'Expired';
	public static CLOSED = 'Closed';
	public static RESOLVED = 'Resolved';
	public static NOT_STARTED = 'NotStarted';

	public static TEST = 'Test';
	public static INCOMPLETED = 'InCompleted';

	public static CHOSEN = 'Chosen';

	// Endpoint model names.
	public static ENTITY = 'entity';
	public static FACILITY = 'facility';
	public static TASK = 'task';
	public static CHOICE = 'choice';
	public static TASK_TYPE = 'taskType';
	public static ASSIGNMENT_TEMPLATE = 'assignmentTemplate';
	public static TEMPLATE_BASE_TYPE = 'templateBaseType';
	public static SCHEDULE = 'schedule';
	public static SCHEDULES = 'schedules';
	public static ASSIGNMENT = 'assignment';
	public static TEMPLATE_TYPE = 'templateType';
	public static USER_GROUP = 'userGroup';
	public static CATEGORY = 'category';
	public static STATISTICS = 'statistics';
	public static CLIENT = 'client';

	public static ASSIGNMENT_TEMPLATES_LABEL = 'AssignmentTemplates';
	public static HAS_ASSIGNMENT_TEMPLATES = 'HasAssignmentTemplates';
	public static NO_ASSIGNMENT_TEMPLATES = 'NoAssignmentTemplates';

	// Task properties.
	public static TYPE = 'type';
	public static SHOW_PREVIOUS_ANSWER = 'showPreviousAnswer';
	public static ORDER = 'order';
	public static TEMPLATETYPE_ID = 'templateTypeId';
	public static ORGANIZATION_ID = 'organizationId';

	// Task property labels.
	public static TYPE_LABEL = 'Type';
	public static REQUIRED_LABEL = 'Required';
	public static ALLOW_COMMENTS_LABEL = 'Allow comments';
	public static ALLOW_MEDIA_LABEL = 'Allow media';

	// Phone types.
	public static phoneTypes = {
		switchboard: 'Switchboard',
		hotline: 'Hotline',
		mobile: 'Mobile',
		work: 'Work Phone',
		home: 'Home Phone',
		other: 'Other Phone'
	};

	public static icons = {
		generateErrorReport: 'bi-journal-arrow-down',
		download: 'bi-download',
		user: 'bi-person',
		group: 'bi-people',
		handle: 'bi-pencil',
		copyReference: 'bi-back',
		setDueDate: 'bi-calendar-week',
		setPrio: 'bi-arrow-up-square',
		setStatus: 'bi-power',
		setAccessibility: 'bi-person-wheelchair',
		delete: 'bi-trash',
		assignmentTemplate: 'bi-map',
		new: 'bi-plus-lg',
		client: 'bi-globe',
		category: 'bi-tags',
		geo: 'bi-bounding-box-circles',
		import: 'bi-box-arrow-in-left',
		export: 'bi-box-arrow-right',
		email: 'bi-envelope',
		schedule: 'bi-calendar-week',
		templateType: 'ii-template-type',
		entity: 'ii-entities',
		facility: 'ii-facility',
		scheduledAssignment: 'ii-assignment',
		measureAssignment: 'ii-measure',
		task: 'ii-task',
		choice: 'bi-check2-circle',
		recreateAssignment: 'bi-repeat',
		recreateReport: 'bi-journal-arrow-up',
		newUser: 'bi-person-plus-fill',
		extraInfo: 'bi-card-heading'
	};

	public static INVALID_PHONE = 'InvalidPhone';

	// Various.
	public static YES = 'Yes';
	public static ROLES = 'roles';
	public static STATUS = 'status';
	public static STATUS_LABEL = 'Status';
	public static PRIORITY_LABEL = 'Priority';
	public static PARENT = 'parent';
	public static DATE_LABEL = 'Date';
	public static DATE = 'date';
	public static DATETIME = 'datetime';
	public static TAGS = 'tags';
	public static CATEGORIES = 'categories';
	public static CREATED = 'created';
	public static UPDATED = 'updated';
	public static MODIFIED = 'modified';
	public static ADDED = 'added';
	public static IMPORT = 'import';
	public static BACKGROUND_JOB = 'backgroundJob';
	public static FACILITY_ID = 'facilityId';
	public static FACILITY_IDS = 'facilityIds';
	public static FACILITY_LABEL = 'Facility';
	public static CHOICE_IDS = 'choiceIds';
	public static DESCRIPTION = 'description';
	public static DESCRIPTION_LABEL = 'Description';
	public static COMMENT_LABEL = 'Comment';
	public static NAME = 'name';
	public static LATITUDE = 'latitude';
	public static LONGITUDE = 'longitude';
	public static RADIUS = 'radius';
	public static CODE = 'code';
	public static FIRST_NAME = 'firstname';
	public static LAST_NAME = 'lastname';
	public static TEMPLATE_TYPE_IDS = 'templateTypeIds';
	public static COMPLETED_BY_USER = 'completedByUser';
	public static COMPLETED_BY_USER_IDS = 'completedByUserIds';
	public static ABBREVIATION = 'abbreviation';
	public static DISABLED_GEO_FENCING_INFO_TEXT = 'DisabledGeoFencingInfoText';
	public static OPEN_IN_NEW_TAB = 'OpenInNewTab';
	public static ADD_GEO_DATA_FOR_ASSIGNMENT = 'AddGeoDataForAssignment';
	public static FILE = 'File';
	public static FILES = 'Files';
	public static YES_CLOSE = 'YesClose';

	public static MEASURE_ASSIGNMENT = 'MeasureAssignment';
	public static MANUAL_MEASURE_ASSIGNMENT = 'ManualMeasureAssignment';

	public static BASE_TYPE_SSEN = 'SS-EN';
	public static BASE_TYPE_STANDARD = 'Standard';
	public static BASE_TYPE_MANUAL_MEASURE = 'Manual Measure';

	public static SU = 'Su';
	public static MO = 'Mo';
	public static TU = 'Tu';
	public static WE = 'We';
	public static TH = 'Th';
	public static FR = 'Fr';
	public static SA = 'Sa';

	// List to render all weekdays and keep track on ids in create- and edit schedule wizards.
	public static WEEKDAYS = [
		{ id: 1, text: 'Monday' },
		{ id: 2, text: 'Tuesday' },
		{ id: 3, text: 'Wednesday' },
		{ id: 4, text: 'Thursday' },
		{ id: 5, text: 'Friday' },
		{ id: 6, text: 'Saturday' },
		{ id: 0, text: 'Sunday' },
	];

	public static ASSIGNMENTS = 'assignments';
	public static ARCHIVED = 'Archived';
	public static ONGOING = 'Ongoing';

	public static CREATED_FACET = 'Created';
	public static LAST_UPDATED_FACET = 'LastUpdated';
	public static DUE_DATE_FACET = 'DueDate';
	public static ARCHIVED_FACET = 'Archived';
	public static FROM_FACET = 'From';
	public static TO_FACET = 'To';
	public static BETWEEN = 'Between';
	public static DEFAULT_PAGE_TITLE = 'InquinovaPlatform';

	public static APP_TYPE_INSPECTION = 'inspection';

	// Related to Integration.
	public static SYSTEM = 'System';
	public static EMAIL_SUBSCRIPTION_REQUIRED = 'EmailSubscriptionRequired';
	public static USERNAME = 'Username';
	public static NEW_PASSWORD = 'New password';
	public static OLD_PASSWORD = 'Old password';
	public static INVALID_PASSWORD = 'InvalidPassword';
	public static CONFIRM_PASSWORD = 'Confirm password';
	public static INTEGRATIONUSER = 'IntegrationUser';
	public static ENABLE_INTEGRATION = 'ActivateIntegration';
	public static INVALID_EMAIL = 'InvalidEmail';
	public static ON = 'On';
	public static OFF = 'Off';
	public static NEW = 'new';
	public static CONFIRM = 'confirm';
	public static SERVICE_ACCOUNT_EMAIL = 'ServiceAccountEmail';
	public static OLD_VALUE = 'OldValue';
	public static EMAILS = 'Emails';
	public static RISE_REFERENCE = 'RiseReference';
	public static ERROR_CODE_EXPLANATION = 'ErrorCodeExplanation';
	public static SCOPE = 'Scope';
	public static IN_ACCORDANCE_WITH = 'InAccordanceWith';
	public static RISK_ANALYSIS = 'RiskAnalysis';

	public static TITLE_LABEL = 'Title';
	public static MANUFACTURER_LABEL = 'Manufacturer';
	public static ASSEMBLER_LABEL = 'Assembler';
	public static ASSEMBLY_YEAR_LABEL = 'AssemblyYear';
	public static TYPE_DESIGNATION_LABEL = 'TypeDesignation';
	public static EXTERNAL_ID_LABEL = 'ExternalId';
	public static HEADER_LABEL = 'Header';

	// A CSS class that displayes a transparent mask over an element.
	public static CSS_LOADMASK = 'loadmask';

	// ERROR codes
	public static DOUBLE_CLICK_ERROR = 'double-click';

	// Import error messages.
	public static BAD_FILE_FORMAT = 'Bad file format';
	public static COULD_NOT_READ_THE_FILE = 'Could not read the file';
	public static INVALID_HEADERS = 'The headers are not valid for this import';
	public static COULD_NOT_BE_GENERATED = 'Excel file could not be generated';
	public static EMPTY_FILE_ERROR = 'EmptyFileError';

	// Import notifications.
	public static IMPORTING = 'Importing';
	public static IMPORT_IN_PROGRESS = 'An import is in progress';
	public static FINISHED = 'Finished';
	public static IMPORT_SUCCEEDED = 'The import succeeded';
	public static ABORTED = 'Aborted';
	public static SOME_ROWS_HAD_ERRORS = 'Some rows had errors';
	public static DOWNLOAD_ERROR_LIST = 'Download error list';
	public static CLOSE = 'Close';

	// Background job actions.
	public static UPDATE_ENTITY_RELATIONS = 'UpdateEntityRelations';
	public static CREATE_ASSIGNMENT_TEMPLATES = 'CreateAssignmentTemplates';
	public static DELETE_ASSIGNMENT_TEMPLATES = 'DeleteAssignmentTemplates';
	public static DELETE_TASKS = 'DeleteTasks';
	public static DELETE_CHOICES = 'DeleteChoices';

	// Report generation error messages.
	public static GENERATE_SUMMARY_ERROR = 'GenerateSummaryError';
	public static GENERATE_ERROR_REPORT_ERROR = 'GenerateErrorReportError';
	public static GET_REPORT_ERROR = 'GetReportError';

	// Report formats.
	public static PDF = 'PDF';
	public static EXCEL = 'Excel';

	// Refresh token messages.
	public static SYNC_STARTED = 'SyncStartedMessage';
	public static SYNC_DONE = 'SyncFinishedMessage';

	// Facet keys
	public static MEDIA_TYPE_FACET = 'MediaTypes';

	// Custom success messages.
	public static VERIFICATION_CODE_SENT = 'Verification code sent';

	// Custom error messages.
	public static NO_TEMPLATE_TYPE_ERROR = 'NoTemplateTypeError';
	public static NO_TEMPLATE_BASE_TYPE_ERROR = 'NoTemplateBaseTypeError';
	public static NO_MANUAL_MEASURE_TEMPLATE_TYPE_ERROR = 'NoManualMeasureTemplateTypeError';
	public static WRONG_ASSIGNMENT_LIST_ERROR = 'WrongAssignmentListError';
	public static SOME_FILES_COULD_NOT_BE_DOWNLOADED_ERROR = 'SomeFilesCouldNotBeDownloadedError';
	public static SCHEDULE_NOT_EDITABLE = 'ScheduleNotEditable';

	public static ID_NOT_FOUND_IN_LIST = 'IdNotFoundInListError';

	// Exports and reports.
	public static EXCEL_FILE_ENDING = '.xlsx';
	public static PDF_FILE_ENDING = '.pdf';
	public static ZIP_FILE_ENDING = '.zip';

	// Filter
	public static STATUS_CONDITION_KEY = 'StatusCondition';
	public static HAS_FACILITY_KEY = 'HasFacility';
	public static HAS_POSITION_KEY = 'HasPosition';
	public static GEO_CONTROLLED_KEY = 'GeoControlled';
	public static GEO_FENCING_KEY = 'GeoFencing';
	public static ACCESSIBILITY_KEY = 'Accessibility';
	public static ACCESSIBLE_KEY = 'Accessible';
	public static LOCATION_DATA_KEY = 'LocationData';
	public static TEMPLATE_TYPE_ID_KEY = 'TemplateTypeId';
	public static TEMPLATE_TYPES_KEY = 'TemplateTypes';
	public static TEMPLATE_TYPE_IDS_KEY = 'TemplateTypeIds';
	public static ROLES_KEY = 'Roles';
	public static TYPE_KEY = 'Type';
	public static STATUSES_KEY = 'Statuses';
	public static STATUS_KEY = 'Status';
	public static ACTIVE_KEY = 'Active';
	public static CREATED_KEY = 'Created';
	public static UPDATED_KEY = 'Updated';
	public static CREATED_BETWEEN_KEY = 'CreatedBetween';
	public static EXPIRES_BETWEEN_KEY = 'ExpiresBetween';
	public static DUE_DATE_KEY = 'DueDate';
	public static ARCHIVED_BETWEEN_KEY = 'ArchivedBetween';
	public static ARCHIVED_KEY = 'Archived';
	public static BASE_TYPES_KEY = 'BaseTypes';
	public static TEMPLATE_BASE_TYPE_IDS_KEY = 'TemplateBaseTypeIds';
	public static FACILITIES_KEY = 'Facilities';
	public static FACILITY_IDS_KEY = 'FacilityIds';
	public static RULES_KEY = 'Rules';
	public static USERS_KEY = 'Users';
	public static ASSIGNEES_KEY = 'Assignees';
	public static USER_IDS_KEY = 'UserIds';
	public static HAS_USERS_KEY = 'HasUsers';
	public static HAS_ASSIGNEES_KEY = 'HasAssignees';
	public static HAS_SCHEDULE_USERS_KEY = 'HasScheduleUsers';
	public static HAS_SCHEDULES_KEY = 'HasSchedules';
	public static HAS_ASSIGNMENT_TEMPLATES_KEY = 'HasAssignmentTemplates';
	public static HAS_GROUPS_KEY = 'HasGroups';
	public static HAS_ASSIGNEE_GROUPS_KEY = 'HasAssigneeGroups';
	public static HAS_SCHEDULE_GROUPS_KEY = 'HasScheduleGroups';
	public static PRIORITIZED_KEY = 'Prioritized';
	public static HAS_PRIORITY_KEY = 'HasPriority';
	public static HAS_ACTIVE_TEMPLATE_OBJECTS_KEY = 'HasActiveTemplateObjects';
	public static SCHEDULES_KEY = 'Schedules';
	public static SCHEDULE_IDS_KEY = 'ScheduleIds';
	public static ASSIGNMENT_TEMPLATES_KEY = 'AssignmentTemplates';
	public static ASSIGNMENT_TEMPLATE_IDS_KEY = 'AssignmentTemplateIds';
	public static CLIENTS_KEY = 'Clients';
	public static CLIENT_IDS_KEY = 'ClientIds';
	public static CATEGORIES_KEY = 'Categories';
	public static CATEGORY_IDS_KEY = 'CategoryIds';
	public static ISSUE_NUMBERS_KEY = 'IssueNumbers';
	public static GROUPS_KEY = 'Groups';
	public static USER_GROUP_IDS_KEY = 'UserGroupIds';
	public static CHOICES_KEY = 'Choices';
	public static CHOICE_IDS_KEY = 'ChoiceIds';
	public static COMPLETED_BY_KEY = 'CompletedBy';
	public static RESOLVED_BY_KEY = 'ResolvedBy';
	public static HANDLED_BY_KEY = 'HandledBy';
	public static COMPLETED_BY_USER_IDS_KEY = 'CompletedByUserIds';
	public static CREATED_BY_KEY = 'CreatedBy';
	public static CREATED_BY_USER_IDS_KEY = 'CreatedByUserIds';
	public static TASKS_KEY = 'Tasks';
	public static TASK_IDS_KEY = 'TaskIds';
	public static ENTITIES_KEY = 'Entities';
	public static ERROR_ENTITY_IDS_KEY = 'ErrorEntityIds';
	public static INTEGRATIONS_KEY = 'Integrations';
	public static INTEGRATION_IDS_KEY = 'IntegrationIds';
	public static IS_MEASURE_KEY = 'IsMeasure';
	public static IS_MANUAL_MEASURE_KEY = 'IsManualMeasure';
	public static IS_COMPLETED_KEY = 'IsCompleted';
	public static IS_EXPIRED_KEY = 'IsExpired';
	public static IS_CLOSED_KEY = 'IsClosed';
	public static IS_STARTED_KEY = 'IsStarted';
	public static IS_ACTIVE_KEY = 'IsActive';

	public static IS_REQUIRED_KEY = 'IsRequired';
	public static ALLOW_COMMENT_KEY = 'AllowComment';
	public static ALLOW_MEDIA_KEY = 'AllowMedia';
	public static IS_DEFAULT_KEY = 'IsDefault';
	public static IS_ERROR_KEY = 'IsError';
	public static IS_UNSPECIFIED_KEY = 'IsUnspecified';
	public static ALLOW_PRIORITY_KEY = 'AllowPriority';

	public static IS_REQUIRED = 'IsRequired';
	public static ALLOWS_COMMENT = 'AllowsComment';
	public static ALLOWS_MEDIA = 'AllowsMedia';
	public static IS_DEFAULT = 'IsDefault';
	public static IS_ERROR = 'IsError';
	public static IS_UNSPECIFIED = 'IsUnspecified';
	public static ALLOWS_PRIORITY = 'AllowsPriority';

	/**
	 * Removes leading and trailing whitespaces and whitespaces in the middle
	 * @param string An input string
	 */
	public static trimAndRemoveWhitespaces(string: string) {
		if (typeof string !== 'string' || !string) {
			string = '';
		}

		return string.trim().replace(/\s+/g, ' ');
	}
}
