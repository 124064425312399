<simple-change-tab
	[form]="form">
		<div class="change-section">
			<div class="change-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-input
				[label]="'Name' | translate"
				[name]="propertyStrings.value"
				[form]="form"
				[maxlength]="3"
				[serverErrors]="serverErrors"
				[focusOnInit]="!isEdit">
			</simple-form-input>

			<simple-form-color-picker
				[label]="'Color' | translate"
				[colorString]="changeModel.color"
				(onColorChange)="handleColorChange($event)">
			</simple-form-color-picker>
		</div>

		<div class="change-section">
			<div class="change-section-header">
				{{ 'Rules' | translate }}
			</div>
			<simple-form-checkbox
				[label]="'IsError' | translate"
				[name]="propertyStrings.isError"
				[form]="form"
				[serverErrors]="serverErrors">
			</simple-form-checkbox>

			<simple-form-checkbox
				[label]="'IsDefault' | translate"
				[name]="propertyStrings.isDefault"
				[form]="form"
				[serverErrors]="serverErrors">
			</simple-form-checkbox>

			<simple-form-checkbox
				[label]="'IsUnspecified' | translate"
				[name]="propertyStrings.isUnspecified"
				[form]="form"
				[serverErrors]="serverErrors">
			</simple-form-checkbox>

			<simple-form-checkbox
				[label]="'AllowsPriority' | translate"
				[name]="propertyStrings.allowPriority"
				[form]="form"
				[serverErrors]="serverErrors">
			</simple-form-checkbox>
		</div>
</simple-change-tab>
