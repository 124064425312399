import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { ListUserComponent } from 'app-core/user/list-user/list-user.component';
import { User } from 'app-core/user/user';

@Component({
	selector: 'handle-users-tab',
	templateUrl: './handle-users-tab.component.html',
	styleUrls: ['./handle-users-tab.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandleUsersTabComponent extends SimpleHandleTabDirective<User> {

	toggleListUserModal() {
		this.bsModalRef = this.modalService.show(
			ListUserComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: this.readonlyListModal,
					hideTableHeaderActions: this.readonlyListModal,
					hideTableRowActions: this.readonlyListModal,
					idsToSetAsSelected: this.handleItems.map(item => item.id)
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	itemListIsValid() {
		return true;
	}

	inlineListHasUnsavedChanges() {
		return this.currentCrudModalComponent?.hasUnsavedChanges();
	}

	override emitModelsChange() {
		this.handleItems.sortByProperty(this.propertyStrings.fullName);
		super.emitModelsChange();
	}

	protected validate() { }

	getUrl(item: User) {
		const pendingUrl = `${RoutesUtils.users}?${RoutesUtils.user}=${RoutesUtils.modalPrefixValueResendEmail}_${item.id}&tab=1`;
		return item.getCurrentStatus(this.selectedOrganization.id) === StringUtils.PENDING ? pendingUrl : item.id === this.loggedInUser.id ? RoutesUtils.profile : item.url;
	}

	getItems() {
		return this.searchValue
			? this.handleItems.filter(item => item.fullName.toLowerCase().includes(this.searchValue.toLowerCase())
				|| item.email.toLowerCase().includes(this.searchValue.toLowerCase()))
			: this.handleItems;
	}
}
