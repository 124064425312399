<simple-crud-modal
	[titleText]="'Facility' | translate"
	[closeText]="'Close' | translate"
	[submitText]="getSelectedCountText('Delete' | translate)"
	[isDelete]="true"
	[isLoading]="pending"
	(onClose)="close()"
	(onSubmit)="submit()">
		<simple-delete
			[selectedIdsCount]="deleteModelIds.length">
			<div extraContent *ngIf="shouldShowEntitiesDeletionOption"
				class="extra-content"
				(click)="toggleRemoveEntities()">
				<div class="checkbox">
					<div class="checkbox-wrapper">
						<input
							id="remove-entities-checkbox"
							type="checkbox"
							[checked]="shouldDeleteEntities"/>
						<span class="checkmark"></span>
					</div>
				</div>
				<div class="text">
					{{ 'AlsoDeleteAllEntities' | translate }}
				</div>
			</div>
		</simple-delete>
</simple-crud-modal>
