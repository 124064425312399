import { ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ColorUtils } from 'app-core/shared-core/tools/color-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Choice } from 'app-inspection/choice/choice';
import { SimpleTableColumn } from './simple-table-column';

export class SimpleTableAssessmentColumn<T> extends SimpleTableColumn<T> {

	readonly getChoices: (item: T) => Choice[];
	readonly getEntityName: (item: T) => string;
	readonly getTaskText: (item: T) => string;
	readonly translationService: TranslationService;

	constructor(
		label: string,
		getChoices: (item: T) => Choice[],
		getEntityName: (item: T) => string,
		getTaskText: (item: T) => string,
		sanitizer: DomSanitizer,
		viewContainerRef: ViewContainerRef,
		secondaryLabel: string,
		translationService: TranslationService) {
		super(
			label,
			sanitizer,
			viewContainerRef,
			secondaryLabel
		);
		this.getChoices = getChoices;
		this.getEntityName = getEntityName;
		this.getTaskText = getTaskText;
		this.translationService = translationService;
	}

	renderValue(item: T) {
		const choice = this.getChoices(item)[0];

		const wrapper = document.createElement('div');
		wrapper.classList.add('assessment-wrapper');
		wrapper.style.display = 'flex';
		wrapper.style.alignItems = 'center';
		wrapper.style.position = 'relative';

		const choiceDiv = document.createElement('div');
		choiceDiv.style.width = '38px';
		choiceDiv.style.height = '38px';
		choiceDiv.style.border = `1px solid ${ColorUtils.MAIN_BORDER_COLOR}`;
		choiceDiv.style.borderRadius = '30px';
		choiceDiv.style.display = 'flex';
		choiceDiv.style.alignItems = 'center';
		choiceDiv.style.justifyContent = 'center';
		choiceDiv.style.fontSize = '12px';
		choiceDiv.style.textTransform = 'uppercase';
		choiceDiv.style.flex = 'none';
		choiceDiv.style.position = 'relative';
		choiceDiv.innerHTML = choice.value;
		choiceDiv.title = choice.value;

		const colorDiv = document.createElement('div');
		colorDiv.style.width = '10px';
		colorDiv.style.height = '10px';
		colorDiv.style.borderRadius = '100%';
		colorDiv.style.position = 'absolute';
		colorDiv.style.bottom = '0';
		colorDiv.style.right = '0';
		colorDiv.style.backgroundColor = choice.color;
		choiceDiv.append(colorDiv);

		wrapper.append(choiceDiv);

		const entityName = this.getEntityName(item);
		const taskText = this.getTaskText(item);

		const textDiv = document.createElement('div');
		textDiv.style.marginLeft = '10px';
		textDiv.style.overflow = 'hidden';

		if (entityName) {
			const entityNameDiv = document.createElement('div');
			entityNameDiv.innerHTML = entityName;
			entityNameDiv.title = entityName;
			entityNameDiv.style.overflow = 'hidden';
			entityNameDiv.style.textOverflow = 'ellipsis';
			textDiv.append(entityNameDiv);
		}

		const taskTextDiv = document.createElement('div');
		taskTextDiv.innerHTML = taskText;
		taskTextDiv.title = taskText;
		taskTextDiv.style.overflow = 'hidden';
		taskTextDiv.style.textOverflow = 'ellipsis';
		textDiv.append(taskTextDiv);

		wrapper.append(textDiv);

		return this.sanitizer.bypassSecurityTrustHtml(wrapper.outerHTML);
	}
}
