import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleViewTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/view/simple-view-tab.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Result } from 'app-inspection/assignment/assignment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'result-component',
	templateUrl: './result.component.html',
	styleUrls: ['./result.component.less']
})
export class ResultComponent extends SimpleViewTabDirective<Result> {

	@Input() errorResult: Result;
	@Input() resolvementResult: Result;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}
}
