<simple-crud-modal
	[titleText]="listReference.tableConfig.emptyState.identifier | translate"
	[closeText]="'Close' | translate"
	[submitText]="('Confirm' | translate) + ' (' + listReference.selectedIds.size + ')'"
	[disableSubmit]="hasConfirmed"
	(onClose)="close()"
	(onSubmit)="confirm()">
		<ng-content></ng-content>
</simple-crud-modal>

<!-- TODO disabla confirm vid alla laddningar?....kan ju ta lång tid -->
