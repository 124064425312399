import { Directive } from '@angular/core';
import { SimpleCrudDirective } from 'app-core/shared-core/simple-components/crud/simple-crud.directive';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { takeUntil } from 'rxjs/operators';
import { SimpleListDirective } from '../../../simple-list.directive';
import { SimpleFilterListModalInput } from '../input-settings/simple-filter-list-modal-input-settings';
import { SimpleFilterInputItem } from '../simple-filter-input-item';

@Directive()
export abstract class SimpleFilterHelperDirective<T extends { id: string, url: string, selected: boolean }> extends SimpleCrudDirective<T> {

	protected subscribeToListModalContent(filterInput: SimpleFilterListModalInput, listReference: SimpleListDirective<T>) {
		this.currentCrudModalComponent = this.bsModalRef.content;
		this.subscriptions.add(
			this.currentCrudModalComponent.confirmed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(confirmedItems => {
					if (confirmedItems) {
						filterInput.selectedItems = this.mapToFilterInputItems(confirmedItems);
						listReference.triggerFilter(filterInput);
						this.closeModal(filterInput);
					}
				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(wasClosed => {
					if (wasClosed) {
						this.closeModal(filterInput);
					}
				})
		);
	}

	private closeModal(filterInput: SimpleFilterListModalInput) {
		filterInput.opened = false;
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
	}

	protected getCurrentSelecteditems(filterInput: SimpleFilterListModalInput, items: T[]) {
		return items.filter(item => filterInput.selectedItems.find(selectedItem => selectedItem.id === item.id));
	}

	protected abstract mapToFilterInputItems(items: T[]): SimpleFilterInputItem[];
}
