import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleChangeBulkTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/bulk/simple-change-bulk-tab.directive';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { Category } from 'app-core/shared-core/simple-components/various/categories/simple-categories.component';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { AssignmentTemplate } from 'app-inspection/assignment-template/assignment-template';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'handle-categories-tab',
	templateUrl: './handle-categories-tab.component.html'
})
export class HandleCategoriesTabComponent extends SimpleChangeBulkTabDirective<AssignmentTemplate> implements OnInit {

	selectableCategoryItems: FormSelectItem[] = [];

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableCategoryItems();

		this.form = this.formBuilder.group({
			[this.propertyStrings.categoryIds]: [this.changeModel.categoryIds]
		});
		super.ngOnInit();
	}

	private setSelectableCategoryItems() {
		this.selectableCategoryItems = this.changeModel.selectableCategories.map(category => new FormSelectItem(category.id, category.name));
	}

	handleAddNewItemInSelect(item: FormSelectItem) {
		const category = new Category({
			id: item.id,
			name: item.name
		});
		this.changeModel.selectableCategories.push(category);
	}
}
