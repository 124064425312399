import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { Organization } from 'app-core/organization/organization';
import { OrganizationService } from 'app-core/organization/organization.service';
import { PostImage } from 'app-core/shared-core/post-image';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { SimpleChangeImageComponent } from 'app-core/shared-core/simple-components/various/change-image/simple-change-image.component';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	templateUrl: './logotype-image.component.html'
})
export class LogotypeImageComponent extends SimpleCreateEditModalDirective<Organization> implements OnInit {

	@ViewChild(SimpleChangeImageComponent) changeImageComponent: SimpleChangeImageComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private organizationService: OrganizationService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			this.initialModel = this.editModelId === this.selectedOrganization.id ? this.selectedOrganization : await this.organizationService.get(this.editModelId);
			this.modifiedModel = this.getUniqueVariant(this.initialModel);
			this.pending = false;

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	everythingIsValid() {
		return true;
	}

	triggerAllValidation() { }

	async createOrUpdate() {
		this.pending = true;
		try {
			let image: PostImage;
			if (!this.modifiedModel.logotypeUrl && !this.changeImageComponent.uploadedFile) {
				image = new PostImage('', new Blob());
			} else {
				image = new PostImage(this.changeImageComponent.uploadedFile.name, this.changeImageComponent.croppedImage);
			}

			const response = await this.organizationService.updateLogotype(this.initialModel.id, image);
			this.initialModel.logotypeUrl = response.data.logotypeUrl;
			response.data = this.initialModel;

			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected instantiateModel(organization: Organization) {
		return new Organization(organization);
	}

	hasUnsavedChanges() {
		return this.changeImageComponent && (this.changeImageComponent.imageUrl !== this.changeImageComponent.initialImageUrl || !!this.changeImageComponent.uploadedFile);
	}
}
