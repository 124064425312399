import { SortDirection } from 'app-core/shared-core/tools/extensions';

export enum ReferenceTypes {
	ScheduledAssignment,
	MeasureAssignment,
	Organization,
	User,
	OrganizationAndUser,
	Combined
}

export enum StatisticsTypes {
	Completed,
	Created,
	Deleted,
	DueDate,
	Combined
}

export enum SpanTypes {
	LastThirtyDays,
	LastDay,
	LastHour,
	LastHalfHour,
	Total
}

export enum DateTypes {
	Year = 'Year',
	Month = 'Month',
	Day = 'Day'
}

export enum SortOptions {
	Year = 'Year',
	Month = 'Month',
	Day = 'Day',
	Total = 'Total'
}

export class Statistics {
	assignments: ReferenceStatisticsData[];
	organizations: ReferenceStatisticsData[];
	users: ReferenceStatisticsData[];
	refreshed: string;

	constructor(statistics: Partial<Statistics>) {
		Object.assign(this, statistics);
	}
}

export class StatisticsPreview {
	type: StatisticsTypes;
	lastDay: number;
	lastHour: number;
	refreshed: string;
	highestDayTotal = false;

	constructor(statisticsData: Partial<StatisticsPreview>) {
		Object.assign(this, statisticsData);
	}
}


/**
 * View models for the statistics-modal.component
 */
export class StatisticsAdvancedView {
	assignmentStatisticsByType: StatisticsDataByStatisticsType[] = [];
	organizationStatisticsByType: StatisticsDataByStatisticsType[] = [];
	userStatisticsByType: StatisticsDataByStatisticsType[] = [];
	refreshed: string;

	constructor(statisticsData: Partial<StatisticsAdvancedView>) {
		Object.assign(this, statisticsData);
	}
}

export class StatisticsDataByStatisticsType {
	type: StatisticsTypes;
	discriminators: ReferenceTypes[];
	data: StatisticsDataByReferenceType[] = [];
	combinedYears: StatisticsByDateData[];
	combinedMonths: StatisticsByDateData[];
	combinedDays: StatisticsByDateData[];

	constructor(statisticsData: Partial<StatisticsDataByStatisticsType>) {
		Object.assign(this, statisticsData);
	}
}

export class StatisticsDataByReferenceType {
	type: ReferenceTypes;
	data: StatisticsDataBySpanType[] = [];
	year: StatisticsByDateData[];
	month: StatisticsByDateData[];
	day: StatisticsByDateData[];

	constructor(statisticsData: Partial<StatisticsDataByReferenceType>) {
		Object.assign(this, statisticsData);
	}
}

export class StatisticsDataByReferenceTypeToRender {
	types: string;
	dateType: DateTypes;
	active: boolean;
	data: StatisticsByDateData[];

	constructor(data: Partial<StatisticsDataByReferenceTypeToRender>) {
		Object.assign(this, data);
	}
}

export class StatisticsByTypeAndDate extends StatisticsDataByReferenceType {
	combinedYear: StatisticsByDateData[];
	combinedMonth: StatisticsByDateData[];
	combinedDay: StatisticsByDateData[];

	constructor(data: Partial<StatisticsByTypeAndDate>) {
		super(data);
	}
}

export class StatisticsDataBySpanType {
	type: SpanTypes;
	count: number;
	identifier: number;

	constructor(statisticsData: Partial<StatisticsDataBySpanType>) {
		Object.assign(this, statisticsData);
	}
}

export class StatisticsData {
	type: StatisticsTypes;
	lastThirtyDays: number;
	lastDay: number;
	lastHour: number;
	lastHalfHour: number;
	total: number;
	year: StatisticsByDateData[];
	month: StatisticsByDateData[];
	day: StatisticsByDateData[];
	refreshed: string;

	constructor(statisticsData: Partial<StatisticsData>) {
		Object.assign(this, statisticsData);
	}
}

export class StatisticsByDateDataRenderConfig {
	width: number = 0;
	numbersFromBar: number = 0;
	initialized: boolean = false;
}

export class StatisticsByDateData {
	date: string;
	dateType: DateTypes;
	discriminator: ReferenceTypes;
	total: number;
	type: StatisticsTypes;
	combinedDiscriminators: ReferenceTypes[];
	renderConfig: StatisticsByDateDataRenderConfig = new StatisticsByDateDataRenderConfig();
	isTodayDate = false;

	constructor(statisticsByDateData: Partial<StatisticsByDateData>) {
		Object.assign(this, statisticsByDateData);
	}
}

export class ReferenceStatisticsData extends StatisticsData {
	discriminator: ReferenceTypes;
	identifier: number;

	constructor(statisticsData: Partial<ReferenceStatisticsData>) {
		super(statisticsData);
		Object.assign(this, statisticsData);
	}
}

export class StatisticsOrganizations {
	organizations: StatisticsOrganizationData[] = [];
	payload: StatisticsPayload;

	constructor(organizationStatistics: Partial<StatisticsOrganizations>) {
		Object.assign(this, organizationStatistics);
	}
}

export class OrganizationAndUserCommonData {
	created: string;
	updated: string;
	createdById: string;
	updatedById: string;
	createdByName: string;
	createdByNameShort: string;
	updatedByName: string;
	updatedByNameShort: string;
	url: string;
	linkTitle: string;

	constructor(data: Partial<OrganizationAndUserCommonData>) {
		Object.assign(this, data);
	}
}

export class StatisticsOrganizationData extends OrganizationAndUserCommonData {
	id: number;
	name: string;
	logotypeUrl: string;
	friendlyUrl: string;
	publicName: string;
	integrations: string[];

	constructor(organizationStatisticsData: Partial<StatisticsOrganizationData>) {
		super(organizationStatisticsData);
		Object.assign(this, organizationStatisticsData);
	}
}

export class StatisticsUsers {
	users: StatisticsUserData[] = [];
	payload: StatisticsPayload;

	constructor(userStatistics: Partial<StatisticsUsers>) {
		Object.assign(this, userStatistics);
	}
}

export class StatisticsUserData extends OrganizationAndUserCommonData {
	id: string;
	email: string;
	firstname: string;
	lastname: string;
	profileImageUrl: string;
	organizationId: number;
	organizationFriendlyUrl: string;
	organizationName: string;
	organizationPublicName: string;
	role: string;
	lastSync: string;
	lastSignInPlatform: string;
	lastSignInDate: string;

	constructor(userStatisticsData: Partial<StatisticsUserData>) {
		super(userStatisticsData);
		Object.assign(this, userStatisticsData);
	}
}

export class StatisticsAssignmentsByOrganizationName {
	name: string;
	isMeasure: boolean;
	isManualMeasure: boolean;
	friendlyOrganizationName: string;
	assignments: StatisticsAssignmentsData[] = [];

	constructor(statisticsData: Partial<StatisticsAssignmentsByOrganizationName>) {
		Object.assign(this, statisticsData);
	}
}

export class StatisticsUsersByOrganizationName {
	name: string;
	friendlyOrganizationName: string;
	users: StatisticsUserData[] = [];

	constructor(statisticsData: Partial<StatisticsUsersByOrganizationName>) {
		Object.assign(this, statisticsData);
	}
}

export class StatisticsAssignmentsData {
	id: string;
	name: string;
	date: string;
	organizationId: number;
	templateTypeId: string;
	isManualMeasure: boolean;
	organizationName: string;
	friendlyOrganizationName: string;
	url: string;
	linkTitle: string;
	updated: string;
	actionById: string;
	updatedById: string;
	actionByName: string;
	actionByNameShort: string;
	updatedByName: string;
	updatedByNameShort: string;

	constructor(statisticsData: Partial<StatisticsAssignmentsData>) {
		Object.assign(this, statisticsData);
	}
}

export class StatisticsAssignments {
	assignments: StatisticsAssignmentsData[] = [];
	payload: StatisticsPayload;

	constructor(statisticsData: Partial<StatisticsAssignments>) {
		Object.assign(this, statisticsData);
	}
}

export class StatisticsPayload {
	discriminator: ReferenceTypes;
	type: StatisticsTypes;
	span: SpanTypes;

	constructor(statisticsData: Partial<StatisticsPayload>) {
		Object.assign(this, statisticsData);
	}
}

export class StatisticsByDateAndTypePayload extends StatisticsDataByStatisticsType {
	dateType: DateTypes;
	sort: StatisticsSort;
}

export class StatisticsSort {
	option: SortOptions;
	direction: SortDirection;

	constructor(sort: Partial<StatisticsSort>) {
		Object.assign(this, sort);
	}
}

export class StatisticsGraphError {
	reference: 'DateType' | 'ReferenceType';
	error: string;

	constructor(error: Partial<StatisticsGraphError>) {
		Object.assign(this, error);
	}
}

export class StatisticsByDataReloadData {
	value: 'in' | 'out' | 'none';
	data: StatisticsByDateAndTypePayload;

	constructor(value: 'in' | 'out' | 'none', data: StatisticsByDateAndTypePayload = null) {
		this.value = value;
		this.data = data;
	}
}

export class NewDataNotification {
	class: 'in' | 'out';
	title: string = '';
	type: StatisticsTypes;
	value: number;

	constructor(data: Partial<NewDataNotification>) {
		Object.assign(this, data);
	}
}
