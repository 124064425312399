import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleComponentsModule } from '../simple-components/simple-components.module';
import { InspireTreeNodesComponent } from './inspire-tree-nodes.component';
import { InspireTreeComponent } from './inspire-tree.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		SimpleComponentsModule
	],
	declarations: [
		InspireTreeComponent,
		InspireTreeNodesComponent
	],
	exports: [
		InspireTreeComponent
	]
})
export class InspireTreeModule { }
