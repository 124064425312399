import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ColorUtils } from 'app-core/shared-core/tools/color-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';

@Component({
	selector: 'simple-status-label',
	templateUrl: './simple-status-label.component.html',
	styleUrls: ['./simple-status-label.component.less']
})
export class SimpleStatusLabelComponent {

	stringUtils = StringUtils;

	@Input() text: string;

	constructor(
		private cdRef: ChangeDetectorRef) { }

	getStatusColor() {
		return ColorUtils.getStatusColor(this.text);
	}

	triggerViewChanges() {
		this.cdRef.detectChanges();
	}
}
