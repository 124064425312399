import {
	ChangeDetectorRef, Component,
	OnDestroy, OnInit,
	TemplateRef, ViewChild
} from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { LocalStorageKey, LocalStorageService } from 'app-core/shared-core/local-storage/local-storage.service';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { ALT_DATE_TIME_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, ReplaySubject, Subscription, interval } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { NewDataNotification, ReferenceStatisticsData, Statistics, StatisticsByDateData, StatisticsPreview, StatisticsTypes } from './statistics';
import { StatisticsService } from './statistics.service';

@Component({
	selector: 'statistics',
	templateUrl: './statistics.component.html',
	styleUrls: ['./statistics.component.less'],
	providers: [StatisticsService]
})
export class StatisticsComponent implements OnInit, OnDestroy {

	refreshing: boolean = false;
	error: string = null;
	modalRef: BsModalRef;
	locale: string = 'sv-SE';
	statistics: Statistics;
	statisticsPreview: StatisticsPreview;
	newData$: Observable<NewDataNotification[]> = null;
	statisticsTypesEnum = StatisticsTypes;

	private _destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	private _subscriptions = new Subscription();
	private _statisticsSubscription = new Subscription();

	// @Output() onStatisticsModalToggle = new EventEmitter();

	@ViewChild('modal') modal: ModalDirective;

	constructor(
		private statisticsService: StatisticsService,
		private modalService: BsModalService,
		private cdRef: ChangeDetectorRef,
		private translationService: TranslationService,
		private authService: AuthService,
		private localstorage: LocalStorageService) { }

	ngOnInit() {
		if (!this.authService.loggedInUser.isSuperAdmin()) {
			return;
		}

		// Refresh statistics every 15 minutes (900000 ms)
		this._subscriptions.add(
			interval(900000)
				.pipe(
					takeUntil(this._destroyed$),
					startWith(0)
				).subscribe(() => {
					if (!this.refreshing) {
						this.loadStatistics(true);
					}
				})
		);

		this._subscriptions.add(
			this.statisticsService.refreshing$
				.pipe(takeUntil(this._destroyed$))
				.subscribe(state => {
					this.refreshing = state;
					this.cdRef.detectChanges();
				})
		);

		this._subscriptions.add(
			this.statisticsService.error$
				.pipe(takeUntil(this._destroyed$))
				.subscribe(state => {
					this.error = state;
					this.cdRef.detectChanges();
				})
		);

		// Notifications for new data
		this.newData$ = this.statisticsService
			.getNewData()
			.pipe(takeUntil(this._destroyed$));
	}

	refreshStatistics(event?: Event) {
		event?.stopPropagation();
		this.loadStatistics(true);
		this.statisticsService.resetStatisticsNewData();
	}

	async loadStatistics(isRefresh: boolean) {
		try {
			this.statisticsService.error$.next(null);
			this.statisticsService.refreshing$.next(true);
			this.statisticsService.refreshingDone$.next(false);

			const response = await this.statisticsService.getStatistics(isRefresh);
			this.statistics = new Statistics(response.data);
			this.statistics.refreshed = this.translationService.instant('StatisticsUpdated', {
				date: Utils.uppercaseEachFirst(Utils.formatDate(this.statistics.refreshed, ALT_DATE_TIME_FORMAT))
			});

			this.statisticsService.initializeNewDataNotifications();

			if (this.statistics) {
				const allNewcompleted = this.checkAllNewCompleted(this.statistics);
				const lastDay = this.checkLastDay(allNewcompleted);
				const highestDayTotal = this.gethighestDayTotal(allNewcompleted);
				this.statisticsPreview = new StatisticsPreview({
					type: StatisticsTypes.Completed,
					lastDay: lastDay,
					lastHour: allNewcompleted
						.map(o => o.lastHour)
						.reduce((sum, current) => sum + current, 0),
					refreshed: this.statistics.refreshed,
					highestDayTotal: this.isHighestDayTotal(highestDayTotal, lastDay)
				});
				this.setNewStatisticsData(this.statistics, isRefresh);
				setTimeout(() => {
					this.statisticsService.refreshing$.next(false);
					this.statisticsService.refreshingDone$.next(true);
				});
			}

		} catch (e) {
			this.statisticsService.error$.next('StatisticsUnavailable');
			this.statisticsService.refreshing$.next(false);
			this.statisticsService.refreshingDone$.next(true);
		}
	}

	openModal(modalTemplate: TemplateRef<any>) {
		this.modalRef = this.modalService.show(modalTemplate, {
			class: 'large',
			keyboard: false,
			ignoreBackdropClick: true
		});
		this.statisticsService.updateDelayOnStatisticsNewData();
	}

	closeModal() {
		if (this.modalRef) {
			DomUtils.hideLatestOpenedModal();
			this.modalRef.hide();
			this.modalRef = null;
		}
	}

	private checkAllNewCompleted(statistics: Statistics) {
		return statistics.assignments.filter(o => String(o.type) === StatisticsTypes[StatisticsTypes.Completed]);
	}

	private gethighestDayTotal(allNewcompleted: ReferenceStatisticsData[]) {
		const grouped = Utils.groupBy(
			allNewcompleted.flatMap(o => o.day),
			Utils.nameof<StatisticsByDateData>('date'));
		const highestDayTotal = Object.keys(grouped)
			.map(key => ({
				date: key,
				total: (grouped[key].length > 1
					? grouped[key].reduce((acc, curr) => acc.total + curr.total)
					: grouped[key][0].total) as Number
			}))
			.sort((a, b) => Number(b.total) - Number(a.total))[0];

		return highestDayTotal;
	}

	private checkLastDay(allNewcompleted: ReferenceStatisticsData[]) {
		return allNewcompleted.map(o => o.lastDay).reduce((sum, current) => sum + current, 0);
	}

	private isHighestDayTotal(highestDayTotal: { date: string, total: Number }, todayTotal: number) {
		return highestDayTotal.date === moment(new Date()).format('YYYY-MM-DD') &&
			highestDayTotal.total === Number(todayTotal);
	}

	private setNewStatisticsData(newStatistics: Statistics, isRefresh: boolean) {
		if (!!isRefresh) {
			const oldStatistics = this.localstorage.getItem(LocalStorageKey.Statistics) as Statistics;

			const diffs = [
				this.getDiffForType(oldStatistics, newStatistics, StatisticsTypes.Completed),
				this.getDiffForType(oldStatistics, newStatistics, StatisticsTypes.Created),
				this.getDiffForType(oldStatistics, newStatistics, StatisticsTypes.Deleted),
				this.getDiffForType(oldStatistics, newStatistics, StatisticsTypes.DueDate)
			];

			if (diffs.some(o => o !== null && o.value > 0)) {
				this.statisticsService.setNewDataNotifications(diffs);
			}
		}

		if (newStatistics) {
			this.localstorage.setItem(LocalStorageKey.Statistics, newStatistics);
		}
	}

	private getDiffForType(
		oldStatistics: Statistics,
		newStatistics: Statistics,
		type: StatisticsTypes) {
		const oldByType = oldStatistics?.assignments
			? oldStatistics?.assignments
				.filter(o => String(o?.type) === StatisticsTypes[type])
			: null;
		const newByType = newStatistics?.assignments
			? newStatistics?.assignments
				.filter(o => String(o?.type) === StatisticsTypes[type])
			: null;

		return this.tryGetDiffNotification(oldByType, newByType, type);
	}

	private tryGetDiffNotification(
		oldStatistics: ReferenceStatisticsData[] = null,
		newStatistics: ReferenceStatisticsData[] = null,
		type: StatisticsTypes): NewDataNotification {
		let newDataNotification: NewDataNotification = null;

		if (!!newStatistics && !!oldStatistics) {
			const oldTotalCount = oldStatistics.map(o => o.total)
				.reduce((sum, current) => sum + current, 0);
			const newTotalCount = newStatistics.map(o => o.total)
				.reduce((sum, current) => sum + current, 0);

			const diff = newTotalCount - oldTotalCount;
			const typeTranslatiion = this.translationService.instant(diff > 1
				? `Statistics${StatisticsTypes[type]}Multiple`
				: `Statistics${StatisticsTypes[type]}Singular`);
			newDataNotification = new NewDataNotification({
				class: diff > 0 ? 'in' : 'out',
				title: this.translationService.instant('NewStatisticsDataType',
					{ type: typeTranslatiion }).toLowerCase(),
				value: diff > 0 ? newTotalCount - oldTotalCount : 0,
				type: type
			});
		}

		return newDataNotification;
	}

	ngOnDestroy() {
		this._destroyed$.next(true);
		this._destroyed$.complete();
		this._statisticsSubscription.unsubscribe();
		this._subscriptions.unsubscribe();
		this.statisticsService.unsubscribeRelatedData();
	}
}
