import { Directive, Input } from '@angular/core';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { takeUntil } from 'rxjs/operators';
import { SimpleCrudDirective } from '../../../simple-crud.directive';

@Directive()
export abstract class SimpleViewTabDirective<T extends { id: string }> extends SimpleCrudDirective<T> {

	@Input() viewModel: T;

	protected subscribeToCrudModalContent() {
		this.currentCrudModalComponent = this.bsModalRef.content;
		this.subscriptions.add(
			this.currentCrudModalComponent.updated$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(updatedItems => {
					if (updatedItems && updatedItems.length) {
						this.closeModal();
					}
				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.handled$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(handledItems => {
					if (handledItems) {
						this.closeModal();
					}

				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(wasClosed => {
					if (wasClosed) {
						this.closeModal();
					}
				})
		);
	}

	closeModal() {
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
	}
}
