<simple-crud-modal
	[titleText]="'AssignmentReport' | translate"
	[closeText]="'Close' | translate"
	[submitText]="(tooLargeForDownload || sendReportByEmail ? 'Send' : 'Download') | translate"
	[disableSubmit]="!everythingIsValid() || !affectableIdsAreValid()"
	[isLoading]="pending"
	[displayDummy]="!initialModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="initialModel">
			<tab [customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-card-text'"
							[title]="'Details' | translate"
							[helpText]="helpText">
						</simple-tab-heading>
					</ng-template>
					<static-report-tab
						[changeModel]="initialModel"
						[affectableIdsCount]="affectableIds.length"
						[selectedIdsCount]="editModelIds.length"
						[subscribers]="subscribers"
						[tooLargeForDownload]="tooLargeForDownload"
						[sendReportByEmail]="sendReportByEmail"
						[hideText]="hideText"
						(onValueChange)="handleValueChanges()">
					</static-report-tab>
			</tab>
		</tabset>
</simple-crud-modal>
