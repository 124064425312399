import { NgModule } from '@angular/core';
import { SharedModule } from 'app-core/shared-core/shared.module';
import { ChoiceRoutingModule } from './choice-routing.module';
import { ListChoiceComponent } from './list-choice/list-choice.component';
import { CreateEditChoiceComponent } from './create-edit-choice/create-edit-choice.component';
import { DeleteChoicesComponent } from './delete-choices/delete-choices.component';
import { ChangeDetailsTabComponent } from './create-edit-choice/tabs/change-details-tab.component';

@NgModule({
	imports: [
		SharedModule,
		ChoiceRoutingModule
	],
	declarations: [
		ListChoiceComponent,
		CreateEditChoiceComponent,
		ChangeDetailsTabComponent,
		DeleteChoicesComponent
	]
})
export class ChoiceModule { }
