import { Directive, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { KeyValuePair } from 'app-core/shared-core/filter';

@Directive()
export abstract class SimpleFormControlDirective implements OnInit {

	focused: boolean = false;
	isRequired: boolean = false;

	@Input() label: string;
	@Input() name: string;
	@Input() form: FormGroup;
	@Input() disabled: boolean;
	@Input() maxlength: number;
	@Input() serverErrors: KeyValuePair[] = [];
	@Input() focusOnInit: boolean;

	@ViewChild('input', { read: ElementRef }) input: ElementRef;

	ngOnInit() {
		if (this.disabled) {
			this.getFormControl().disable();
		}
		this.isRequired = this.getFormControl().hasValidator(Validators.required);
	}

	hasErrors() {
		const formControl = this.getFormControl();
		return formControl.invalid && (formControl.dirty || formControl.touched);
	}

	getFormControl() {
		return this.form.get(this.name);
	}

	focusElement() {
		if (this.input) {
			setTimeout(() => {
				this.input.nativeElement.focus();
			}, 200);
		}
	}
}
