<div class="text-content" *ngIf="textContent">
	<div class="main"
		[ngClass]="{
			'clickable': !!textContent.mainUrl
		}"
		[title]="textContent.mainText">
			{{ textContent.mainText }}
	</div>
	<div class="secondary" *ngIf="textContent.secondaryText"
		[ngClass]="{
			'clickable': !!textContent.secondaryUrl
		}"
		[title]="textContent.secondaryText">
			{{ textContent.secondaryText }}
	</div>
</div>
