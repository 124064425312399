<simple-crud-modal
	[titleText]="'MeasureAssignment' | translate"
	[closeText]="'Close' | translate"
	[submitText]="'Create' | translate"
	[disableSubmit]="!everythingIsValid() || !hasUnsavedChanges()"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab #handleTab="tab"
				[customClass]="handleFacilityIsValid() ? 'valid' : handleFacilityHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(1)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'ii-facility'"
							[title]="'Facility' | translate"
							[helpText]="'FacilityChangeDetailsMeasureHelpText' | translate">
						</simple-tab-heading>
					</ng-template>
					<handle-facility-tab
						[readonlyListModal]="true"
						[useSingleSelectionInList]="true"
						[onlyActiveFacilitiesInList]="true"
						[handleItems]="modifiedModel.facility ? [modifiedModel.facility] : []"
						[choice]="modifiedModel.choice"
						[headerSubtitleText]="'AddedFacilitySubtitleMeasure' | translate"
						[wasSelected]="handleTab.active"
						(onModelsChange)="setFacility($event)"
						(onUpdated)="assignFacility($event)">
					</handle-facility-tab>
			</tab>
			<tab #handleTab2="tab"
				[customClass]="handleEntitiesIsValid() ? 'valid' : handleEntitiesHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(2)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'ii-entities'"
						[title]="'Entities' | translate"
						[helpText]="'EntitiesChangeDetailsMeasureHelpText' | translate"
						[displayCount]="modifiedModel.entities.length">
					</simple-tab-heading>
				</ng-template>
				<handle-entities-tab-measure
					[handleItems]="modifiedModel.entities"
					[readonlyListModal]="true"
					[choice]="modifiedModel.choice"
					[headerSubtitleText]="'AddedEntitiesSubtitleMeasure' | translate"
					[facilityId]="modifiedModel.facility ? modifiedModel.facility.id : null"
					[onlyActiveEntitiesInList]="true"
					[wasSelected]="handleTab2.active"
					(onModelsChange)="handleEntityChange($event)"
					(onUpdated)="handleOnUpdatedEntities()">
				</handle-entities-tab-measure>
			</tab>
			<tab #handleTab3="tab"
				[customClass]="'valid'"
				(selectTab)="setTabIndexInUrl(3)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi-people'"
						[title]="'Groups' | translate"
						[helpText]="'GroupsMeasureHelpText' | translate"
						[displayCount]="modifiedModel.assigneeGroups.length">
					</simple-tab-heading>
				</ng-template>
				<handle-assignee-groups-tab
					[handleItems]="modifiedModel.assigneeGroups"
					[isMeasure]="true"
					[headerSubtitleText]="'AddedGroupsSubtitleMeasure' | translate"
					[readonlyListModal]="true"
					[wasSelected]="handleTab3.active"
					[hideNotificationButton]="true"
					(onModelsChange)="modifiedModel.assigneeGroups = $event">
				</handle-assignee-groups-tab>
			</tab>
			<tab #handleTab4="tab"
				[customClass]="'valid'"
				(selectTab)="setTabIndexInUrl(4)">
				<ng-template tabHeading>
					<simple-tab-heading
						[icon]="'bi-person'"
						[title]="'Assignees' | translate"
						[helpText]="'UsersMeasureHelpText' | translate"
						[displayCount]="modifiedModel.assignees.length">
					</simple-tab-heading>
				</ng-template>
				<handle-assignees-tab
					[handleItems]="modifiedModel.assignees"
					[selectedAssigneeGroups]="modifiedModel.assigneeGroups"
					[isMeasure]="true"
					[headerSubtitleText]="'AddedUsersSubtitleMeasure' | translate"
					[readonlyListModal]="true"
					[wasSelected]="handleTab4.active"
					[hideNotificationButton]="true"
					(onModelsChange)="modifiedModel.assignees = $event">
				</handle-assignees-tab>
			</tab>
			<tab #handleTab5="tab"
				[customClass]="handleSubscribersIsValid() ? 'valid' : handleSubscribersHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(5)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-bell'"
							[title]="'Subscribers' | translate"
							[helpText]="'MeasureHandleSubscribersHelpText' | translate"
							[displayCount]="modifiedModel.subscribers.length">
						</simple-tab-heading>
					</ng-template>
					<handle-subscribers-tab
						[handleItems]="modifiedModel.subscribers"
						[headerSubtitleText]="'AddedSubscribersSubtitleMeasure' | translate"
						[readonlyListModal]="true"
						[wasSelected]="handleTab5.active"
						[isManualMeasureContext]="true"
						[bypassRequiredError]="true"
						(onModelsChange)="modifiedModel.subscribers = $event">
					</handle-subscribers-tab>
			</tab>
		</tabset>
</simple-crud-modal>
