import { Component, Input } from '@angular/core';

@Component({
	selector: 'simple-affectable-count-message',
	templateUrl: './simple-affectable-count-message.component.html',
	styleUrls: ['./simple-affectable-count-message.component.less']
})

export class SimpleAffectableCountMessageComponent { // todo byt plats på denna från tabs mapp om även används för delete!
	@Input() affectableIdsCount: number;
	@Input() selectedIdsCount: number;
}
