import { Injectable } from '@angular/core';
import { Client } from 'app-core/organization/organization';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { Category } from 'app-core/shared-core/simple-components/various/categories/simple-categories.component';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { Schedule } from 'app-inspection/schedule/schedule';
import { TemplateType } from 'app-inspection/template-type/template-type';
import { BaseService } from '../../app-core/shared-core/abstract-components/service/base.service';
import { Filter, KeyValuePair, SortDirection } from '../../app-core/shared-core/filter';
import { Facility } from '../facility/facility';
import { AssignmentTemplate } from './assignment-template';

@Injectable()
export class AssignmentTemplateService extends BaseService<AssignmentTemplate> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
		super(
			hostedHttpClient,
			RoutesUtils.assignmentTemplate
		);
	}

	async getTemplateTypes(organizationId: number, includeTaskChoices?: boolean): Promise<TemplateType[]> {
		if (includeTaskChoices) {
			return this.hostedHttpClient.get(`templateType/organization/${organizationId}?includeManualMeasure=true&includeTaskChoices=true`, {})
				.then(res => res.data.map((item: TemplateType) => new TemplateType(item)));
		} else {
			return this.hostedHttpClient.get(`templateType/organization/${organizationId}?includeManualMeasure=true`, {})
				.then(res => res.data.map((item: TemplateType) => new TemplateType(item)));
		}
	}

	async getFacilities(): Promise<Facility[]> {
		const filter = new Filter();
		filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		filter.addSort(
			new KeyValuePair(
				Utils.uppercaseEachFirst(Utils.nameof<Facility>('name')),
				SortDirection.Ascending
			)
		);
		return this.hostedHttpClient.get(`facility/filteredac`, filter.toPayloadObject())
			.then(res => res.data.map((item: Facility) => new Facility(item)));
	}

	async getSchedules(organizationId: number): Promise<Schedule[]> {
		const filter = new Filter();
		filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		filter.addSort(
			new KeyValuePair(
				Utils.uppercaseEachFirst(Utils.nameof<Schedule>('name')),
				SortDirection.Ascending
			)
		);
		return this.hostedHttpClient.get(`schedule?organization=${organizationId}`, filter.toPayloadObject())
			.then(res => res.data.map((item: Schedule) => new Schedule(item)));
	}

	async getCategories(organizationId: number): Promise<Category[]> {
		return this.hostedHttpClient.get(`organization/${organizationId}/category`, {})
			.then(res => res.data.map((item: Category) => new Category(item)));
	}

	async getCategoriesFromItems(itemIds: string[], organizationId: number): Promise<Category[]> {
		const model = {
			itemIds: itemIds
		};
		return this.hostedHttpClient.post(`category/byassignmenttemplates?organization=${organizationId}`, model)
			.then(res => res.data.map((item: Category) => new Category(item)));
	}

	async getClients(organizationId: number): Promise<Client[]> {
		return this.hostedHttpClient.get(`client?organization=${organizationId}`, {}, true)
			.then(res => res.data.map((item: Client) => new Client(item)));
	}

	async getClientsFromItems(itemIds: string[], organizationId: number): Promise<Client[]> {
		const model = {
			itemIds: itemIds
		};
		return this.hostedHttpClient.post(`client/byassignmenttemplates?organization=${organizationId}`, model)
			.then(res => res.data.map((item: Client) => new Client(item)));
	}

	updateCategories(itemIds: string[], categoryNames: string[], organizationId: number) {
		const model = {
			itemIds: itemIds,
			categoryNames: categoryNames
		};
		return this.hostedHttpClient.put(`${this.prefix}/categories?organization=${organizationId}`, model);
	}

	updateClient(itemIds: string[], clientId: string, organizationId: number) {
		const model = {
			itemIds: itemIds,
			clientId: clientId
		};
		return this.hostedHttpClient.put(`${this.prefix}/client?organization=${organizationId}`, model);
	}

	updateGeoFencing(itemIds: string[], value: boolean, organizationId: number) {
		const model = {
			itemIds: itemIds,
			geoControlled: value
		};
		return this.hostedHttpClient.put(`${this.prefix}/geofencing?organization=${organizationId}`, model);
	}

	getGeoFencingData(itemIds: string[], value: boolean, organizationId: number) {
		const model = {
			itemIds: itemIds,
			geoControlled: value
		};
		return this.hostedHttpClient.post(`${this.prefix}/geofencingids?organization=${organizationId}`, model);
	}

	instantiateModel(item: AssignmentTemplate) {
		return new AssignmentTemplate(item);
	}
}

export class AssignmentTemplateFilter extends Filter {
	toPayloadObject() {
		const tempFacets = {};
		const currentFacets = (Object.entries(this.facets) as [string, string][])
			.map(facet => {
				return {
					key: facet[0],
					value: facet[1]
				};
			});

		currentFacets.forEach(facet => {
			if (facet.key === StringUtils.TEMPLATE_TYPES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.TEMPLATE_TYPE_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.TEMPLATE_TYPE_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.CATEGORIES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.CATEGORY_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.CATEGORY_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.CLIENTS_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.CLIENT_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.CLIENT_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.FACILITIES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.FACILITY_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.FACILITY_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.HAS_SCHEDULES_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.HAS_SCHEDULES_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.HAS_SCHEDULES_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.SCHEDULES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.SCHEDULE_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.SCHEDULE_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.GEO_FENCING_KEY) {
				const geoFencing = facet.value;
				if (geoFencing === StringUtils.YES) {
					tempFacets[StringUtils.GEO_CONTROLLED_KEY] = 'true';
				} else if (geoFencing === StringUtils.NO) {
					tempFacets[StringUtils.GEO_CONTROLLED_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.GEO_CONTROLLED_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.STATUS_KEY) {
				const status = facet.value;
				if (status === StringUtils.ACTIVE) {
					tempFacets[StringUtils.HAS_ACTIVE_TEMPLATE_OBJECTS_KEY] = 'true';
				} else if (status === StringUtils.INACTIVE) {
					tempFacets[StringUtils.HAS_ACTIVE_TEMPLATE_OBJECTS_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.HAS_ACTIVE_TEMPLATE_OBJECTS_KEY) {
				delete this.facets[facet.key];
			}
		});

		Object.assign(this.facets, tempFacets);

		return {
			filter: JSON.stringify(this)
		};
	}
}
