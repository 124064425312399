<div class="wrapper">
	<div class="top-header">
		<div class="icon bi-graph-up-arrow"></div>
		<div class="text">
			{{ 'Dashboard' | translate }}
		</div>
	</div>
	<div class="header">
		<div class="greeting-wrapper">
			<div class="greeting">
				{{ dashboardUtils.getGreeting(translationService) }} {{ loggedInUser.fullName }}!
			</div>
		</div>
	</div>
	<div class="main apx-scrollbar" scrollShadow>
		<div class="column">
			<div class="section with-margin-bottom">
				<div class="section-body">
					<div class="filter-wrapper">
						<div class="selected-filters-wrapper">
							<div class="selected-filters">
								<div class="selected-filter" *ngFor="let selectedFilter of selectedFilters"
									title="{{ selectedFilter.title }}: {{ selectedFilter.selectedItemNames }}"
									(click)="removeFilter(selectedFilter)">
										<div class="title">
											{{ selectedFilter.title }}:
										</div>
										<div class="values">
											{{ selectedFilter.selectedItemNames }}
										</div>
										<div class="icon bi-x"></div>
								</div>
								<div class="no-filter-text" *ngIf="!hasSelectedFilters">
									{{ 'NoFiltersAdded' | translate }}...
								</div>
							</div>
							<button class="btn btn-transparent"
								[ngClass]="{
									'selected': hasSelectedFilters,
									'disabled': !filterObjects.length
								}"
								title="{{ 'Filter' | translate }}"
								(click)="filterOpened = !filterOpened">
									{{ 'Filter' }}
									<div class="icon smaller"
										[ngClass]="{
											'bi-chevron-down': !filterOpened,
											'bi-chevron-up': filterOpened
										}">
									</div>
							</button>
						</div>

						<div class="filter-object-wrapper" *ngIf="filterOpened">
							<div class="filter-object" *ngFor="let filterObject of filterObjects">
								<button class="btn btn-transparent" #filterPopover="bs-popover"
									[popover]="filterTemplate"
									containerClass="standard-menu-popover"
									placement="bottom"
									[outsideClick]="true"
									[ngClass]="{
										'opened': filterObject.opened,
										'selected': filterObject.hasSelectedItems
									}"
									(onShown)="filterObject.opened = true; focusFilterSearchInput()"
									(onHidden)="filterObject.opened = false; filterObject.searchValue = ''"
									title="{{ 'OpenMenu' | translate }}">
										{{ filterObject.title }}
								</button>
								<ng-template #filterTemplate>
									<input #filterInput class="filter-search"
										[placeholder]="'Search' | translate"
										[(ngModel)]="filterObject.searchValue" />
									<div class="clear-search bi-x" *ngIf="filterObject.searchValue"
										[title]="'Clear' | translate"
										(click)="$event.stopImmediatePropagation(); filterObject.searchValue = ''">
									</div>
									<div class="standard-menu-item" *ngFor="let item of filterObject.filteredItems"
										[ngClass]="{
											'selected': item.selected
										}"
										[title]="item.name"
										(click)="handleFilterClick(item, filterObject)">
											<div class="text">
												{{ item.name }}
											</div>
									</div>
								</ng-template>
							</div>
						</div>
					</div>
					<div class="chart">
						<div class="canvas-wrapper">
							<canvas baseChart
								[type]="chartType"
								[options]="chartOptions"
								[data]="chartData"
								[plugins]="chartPlugins"
								(chartClick)="handleChartClick($event)">
							</canvas>
							<div class="state-text" *ngIf="!chartData.datasets.length && !pendingItems && !pendingCounts">
								{{ 'NoDataWasFound' | translate }}...
							</div>
							<div class="state-text icon bi-arrow-repeat" *ngIf="pendingItems || pendingCounts">
							</div>
						</div>
						<div class="timespan">
							<button type="button"
								class="btn btn-transparent"
								[ngClass]="{
									'selected': selectedTimeSpan === timeSpanEnum.ThisDay
								}"
								[title]="'ThisDay' | translate"
								(click)="changeTimeSpan(timeSpanEnum.ThisDay)">
									{{ 'ThisDay' | translate }}
							</button>
							<button type="button"
								class="btn btn-transparent"
								[ngClass]="{
									'selected': selectedTimeSpan === timeSpanEnum.ThisWeek
								}"
								[title]="'ThisWeek' | translate"
								(click)="changeTimeSpan(timeSpanEnum.ThisWeek)">
									{{ 'ThisWeek' | translate }}
							</button>
							<button type="button"
								class="btn btn-transparent"
								[ngClass]="{
									'selected': selectedTimeSpan === timeSpanEnum.ThisMonth
								}"
								[title]="'ThisMonth' | translate"
								(click)="changeTimeSpan(timeSpanEnum.ThisMonth)">
									{{ 'ThisMonth' | translate }}
							</button>
							<button type="button"
								class="btn btn-transparent"
								[ngClass]="{
									'selected': selectedTimeSpan === timeSpanEnum.ThisYear
								}"
								[title]="'ThisYear' | translate"
								(click)="changeTimeSpan(timeSpanEnum.ThisYear)">
									{{ 'ThisYear' | translate }}
							</button>
						</div>
						<div class="state-buttons">
							<div class="button-wrapper">
								<button type="button"
									class="btn btn-transparent"
									[ngClass]="{
										'selected': selectedAssignmentState === assignmentStateEnum.ExpiringAssignments
									}"
									[title]="'ExpiringAssignments' | translate"
									(click)="changeAssignmentState(assignmentStateEnum.ExpiringAssignments)">
										<div class="text">
											{{ 'ExpiringAssignments' | translate }}
										</div>
										<div class="count"
											[ngClass]="{
												'bi-arrow-repeat': pendingCounts
											}">
											<ng-container *ngIf="!pendingCounts">
												({{ scheduledAssignmentsExpiringCount }})
											</ng-container>
										</div>
								</button>
								<button type="button"
									class="btn btn-transparent"
									[ngClass]="{
										'selected': selectedAssignmentState === assignmentStateEnum.CompletedAssignments
									}"
									[title]="'CompletedAssignments' | translate"
									(click)="changeAssignmentState(assignmentStateEnum.CompletedAssignments)">
										<div class="text">
											{{ 'CompletedAssignments' | translate }}
										</div>
										<div class="count"
											[ngClass]="{
												'bi-arrow-repeat': pendingCounts
											}">
											<ng-container *ngIf="!pendingCounts">
												({{ scheduledAssignmentsCompletedCount }})
											</ng-container>
										</div>
								</button>
								<button type="button"
									class="btn btn-transparent"
									[ngClass]="{
										'selected': selectedAssignmentState === assignmentStateEnum.ExpiredAssignments
									}"
									[title]="'ExpiredAssignments' | translate"
									(click)="changeAssignmentState(assignmentStateEnum.ExpiredAssignments)">
										<div class="text">
											{{ 'ExpiredAssignments' | translate }}
										</div>
										<div class="count"
											[ngClass]="{
												'bi-arrow-repeat': pendingCounts
											}">
											<ng-container *ngIf="!pendingCounts">
												({{ scheduledAssignmentsExpiredCount }})
											</ng-container>
										</div>
								</button>
							</div>
							<div class="button-wrapper">
								<button type="button"
									class="btn btn-transparent"
									[ngClass]="{
										'selected': selectedAssignmentState === assignmentStateEnum.CreatedMeasures
									}"
									[title]="'CreatedMeasures' | translate"
									(click)="changeAssignmentState(assignmentStateEnum.CreatedMeasures)">
										<div class="text">
											{{ 'CreatedMeasures' | translate }}
										</div>
										<div class="count"
											[ngClass]="{
												'bi-arrow-repeat': pendingCounts
											}">
											<ng-container *ngIf="!pendingCounts">
												({{ measureAssignmentsCreatedCount }})
											</ng-container>
										</div>
								</button>
								<button type="button"
									class="btn btn-transparent"
									[ngClass]="{
										'selected': selectedAssignmentState === assignmentStateEnum.ResolvedMeasures
									}"
									[title]="'ResolvedMeasures' | translate"
									(click)="changeAssignmentState(assignmentStateEnum.ResolvedMeasures)">
										<div class="text">
											{{ 'ResolvedMeasures' | translate }}
										</div>
										<div class="count"
											[ngClass]="{
												'bi-arrow-repeat': pendingCounts
											}">
											<ng-container *ngIf="!pendingCounts">
												({{ measureAssignmentsResolvedCount }})
											</ng-container>
										</div>
								</button>
								<button type="button"
									class="btn btn-transparent"
									[ngClass]="{
										'selected': selectedAssignmentState === assignmentStateEnum.ClosedMeasures
									}"
									[title]="'ClosedMeasures' | translate"
									(click)="changeAssignmentState(assignmentStateEnum.ClosedMeasures)">
										<div class="text">
											{{ 'ClosedMeasures' | translate }}
										</div>
										<div class="count"
											[ngClass]="{
												'bi-arrow-repeat': pendingCounts
											}">
											<ng-container *ngIf="!pendingCounts">
												({{ measureAssignmentsClosedCount }})
											</ng-container>
										</div>
								</button>
							</div>
						</div>
						<div class="separator"></div>
					</div>
				</div>
			</div>
	</div>
</div>
</div>
