import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toDateOrUndefined'
})
export class ToDateOrUndefinedPipe implements PipeTransform {
	transform(value: string): Date {
		return value ? new Date(value) : undefined;
	}
}
