<simple-handle-tab
	[headerTitleText]="modifiedTitle ? modifiedTitle : ('AddedMedia' | translate)"
	[headerSubtitleText]="headerSubtitleText"
	[headerAddButtonText]="modifiedAddButtonText ? modifiedAddButtonText :'AddMedia' | translate"
	[hideRemoveSelected]="true"
	[hideSelectAll]="true"
	[hideSearch]="true"
	(onAddButtonClick)="triggerUpload()">
		<ng-container slot="itemList">
			<media-widget
				[collection]="handleItems"
				[hideAddButton]="true"
				[viewOnly]="readonlyItems"
				[onlyImageUpload]="onlyImageUpload"
				[onlyPdfUpload]="onlyDocumentUpload"
				[canToggleDefault]="canToggleDefault"
				[modifiedDummyText]="modifiedDummyText"
				(onCollectionChange)="handleMediaCollectionChange($event)">
			</media-widget>
		</ng-container>
</simple-handle-tab>
