import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { ALT_DATE_TIME_FORMAT_WITHOUT_SECONDS, DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Assignment } from 'app-inspection/assignment/assignment';
import { Choice } from 'app-inspection/choice/choice';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'change-details-tab',
	templateUrl: './change-details-tab.component.html'
})
export class ChangeDetailsTabComponent extends SimpleChangeTabDirective<Assignment> implements OnInit {

	selectableStatusItems: FormSelectItem[] = [];
	selectablePriorityItems: FormSelectItem[] = [];

	disabledPriorityLink: string = '';
	priorityIsDisabled: boolean = false;

	dueDateErrorMessage: string = '';

	@Input() choice: Choice;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableStatusItems();
		this.setSelectablePriorityItems();

		this.priorityIsDisabled = !this.choice || !this.choice.allowPriority;
		this.disabledPriorityLink = this.loggedInUser.isSuperAdmin() && this.choice ? this.choice.url : '';

		if (this.changeModel.isMeasureAssignment()) {
			this.form = this.formBuilder.group({
				[this.propertyStrings.priority]: [
					this.changeModel.priority,
					Validators.required
				],
				[this.propertyStrings.status]: [
					this.changeModel.status === StringUtils.ACTIVE ? StringUtils.NOT_STARTED : this.changeModel.status,
					Validators.required
				],
				[this.propertyStrings.notes]: [
					this.changeModel.notes,
					Validators.required
				],
				[this.propertyStrings.adminComment]: [
					this.changeModel.adminComment,
					Validators.maxLength(500)
				]
			}, { validators: this.measureValidator });
		} else {
			this.form = this.formBuilder.group({
				[this.propertyStrings.dueTime]: [
					this.changeModel.dueTime,
					Validators.required
				]
			});
			this.setDueDateErrorMessage();

			// Refresh every 1 second in order for startdate message to display correctly.
			this.subscriptions.add(
				interval(1000)
					.pipe(takeUntil(this.destroyed$))
					.subscribe(() => {
						this.setDueDateErrorMessage();
					})
			);
		}
		super.ngOnInit();
	}

	private measureValidator(form: FormGroup): ValidationErrors {
		const status = form.get('status');
		const notes = form.get('notes');

		if (status.value !== StringUtils.NOT_STARTED && status.value !== StringUtils.STARTED && !notes.value) {
			notes.setErrors({ required: true });
		} else {
			notes.setErrors(null);
		}
		return null;
	}

	private setSelectableStatusItems() {
		const status = this.changeModel.status === StringUtils.STARTED ? StringUtils.STARTED : StringUtils.NOT_STARTED;
		this.selectableStatusItems = [
			new FormSelectItem(status, this.translationService.instant(status)),
			new FormSelectItem(StringUtils.COMPLETED, this.translationService.instant(this.changeModel.isMeasureAssignment ? StringUtils.RESOLVED : StringUtils.COMPLETED)),
			new FormSelectItem(StringUtils.CLOSED, this.translationService.instant(StringUtils.CLOSED))
		];
	}

	private setSelectablePriorityItems() {
		this.selectablePriorityItems = [
			new FormSelectItem(false, this.translationService.instant(StringUtils.NO)),
			new FormSelectItem(true, this.translationService.instant(StringUtils.YES))
		];
	}

	handleDueDateChange(dateValue: string) {
		this.changeModel.dueDate = dateValue;
		this.setDueDateErrorMessage();
	}

	handleTimeChange() {
		this.setDueDateErrorMessage();
	}

	private setDueDateErrorMessage() {
		if (this.dueDateIsBeforeToday()) {
			this.dueDateErrorMessage = `${this.translationService.instant('MustBeLaterThanToday', { date: Utils.getToday(DEFAULT_DISPLAY_DATE_FORMAT) })}`;
		} else {
			this.dueDateErrorMessage = '';
		}
	}

	private dueDateIsBeforeToday() {
		const [dueDateWithoutTime, _] = this.changeModel.dueDate.split(' ');
		const dueDateWithDueTime = moment(new Date(`${dueDateWithoutTime} ${this.changeModel.dueTime}`)).format(ALT_DATE_TIME_FORMAT_WITHOUT_SECONDS);
		return dueDateWithDueTime <= Utils.getToday(ALT_DATE_TIME_FORMAT_WITHOUT_SECONDS);
	}
}
