import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Entity } from 'app-inspection/entity/entity';
import { EntityService } from 'app-inspection/entity/entity.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SetAccessibilityTabComponent } from './tabs/set-accessibility-tab.component';

@Component({
	templateUrl: './set-accessibility-entity.component.html'
})
export class SetAccessibilityEntityComponent extends SimpleBulkEditModalDirective<Entity> implements OnInit {

	@ViewChild(SetAccessibilityTabComponent) setEntityAccessibilityTabComponent: SetAccessibilityTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private entityService: EntityService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.initialModel = new Entity({});
	}

	changeDetailsIsValid() {
		return this.setEntityAccessibilityTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.setEntityAccessibilityTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = await this.entityService.updateAccessibility(this.editModelIds, this.initialModel.accessible);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.setEntityAccessibilityTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Entity) {
		return new Entity(item);
	}

	hasUnsavedChanges() {
		return false;
	}
}
