import { ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ColorUtils } from 'app-core/shared-core/tools/color-utils';
import { SimpleTableColumn } from './simple-table-column';

export class SimpleTableIconsColumn<T> extends SimpleTableColumn<T> {

	readonly getIconsContent: (item: T) => IconContent[];

	constructor(
		label: string,
		getIconsContent: (item: T) => IconContent[],
		sanitizer: DomSanitizer,
		viewContainerRef: ViewContainerRef,
		secondaryLabel: string) {
		super(
			label,
			sanitizer,
			viewContainerRef,
			secondaryLabel
		);
		this.getIconsContent = getIconsContent;
		this.maxWidth = '180px';
	}

	renderValue(item: T) {
		const iconsContent = this.getIconsContent(item);

		const wrapper = document.createElement('div');
		wrapper.classList.add('column-icon-wrapper', 'apx-scrollbar');
		wrapper.style.display = 'flex';
		wrapper.style.rowGap = '5px';
		wrapper.style.columnGap = '10px';
		wrapper.style.flexWrap = 'wrap';
		wrapper.style.justifyContent = 'flex-end';

		iconsContent.forEach(iconContent => {
			if (iconContent) {
				const mainDiv = document.createElement('div');
				const firstDiv = document.createElement('div');
				const secondDiv = document.createElement('div');

				mainDiv.style.display = 'flex';
				mainDiv.style.alignItems = 'center';
				mainDiv.style.gap = '5px';
				mainDiv.style.overflow = 'hidden';
				mainDiv.title = iconContent.titleText;

				firstDiv.classList.add(iconContent.iconClass);
				firstDiv.style.fontSize = '15px';
				firstDiv.style.color = iconContent.color ? iconContent.color : ColorUtils.MAIN_FONT_COLOR;
				firstDiv.style.flex = 'none';
				firstDiv.style.display = 'flex';
				firstDiv.style.alignItems = 'center';
				firstDiv.style.justifyContent = 'center';

				mainDiv.append(firstDiv);

				if (iconContent.text) {
					secondDiv.innerHTML = iconContent.text;
					secondDiv.style.overflow = 'hidden';
					secondDiv.style.textOverflow = 'ellipsis';
					secondDiv.style.fontWeight = '600';
					secondDiv.style.fontSize = '12px';

					mainDiv.append(secondDiv);
				}

				wrapper.append(mainDiv);
			}
		});

		return this.sanitizer.bypassSecurityTrustHtml(wrapper.outerHTML);
	}
}

export class IconContent {
	iconClass: string;
	text: string;
	titleText: string;
	color: string;

	constructor(
		iconClass: string,
		text: string,
		titleText: string,
		color?: string) {
		this.iconClass = iconClass;
		this.text = text;
		this.titleText = titleText;
		this.color = color ? color : '';
	}
}
