<div class="modal-wrapper">
	<div class="header">
		<div class="header-icon"></div>
	</div>
	<div class="modal-body">
		<div class="title">
			{{ 'AssignmentTemplatesWithEntitiesTitle' | translate }}
		</div>
		<div class="description">
			{{ 'AssignmentTemplatesWithEntitiesDescription' | translate }}
		</div>
		<div class="select-all-action">
			<div class="select-all-text">{{ (areAllSelected() ? 'UnselectAll' : 'SelectAll') | translate }}</div>
			<div class="checkbox select-all-checkbox"
				title="{{ (areAllSelected() ? 'UnselectAll' : 'SelectAll') | translate }}"
				(mouseover)="selectAllHovered = true"
				(mouseleave)="selectAllHovered = false"
				[ngClass]="{
					'bi-square': !selectAllHovered && !areAllSelected() && !areSomeSelected(),
					'bi-dash-square': areSomeSelected() && !areAllSelected() && !selectAllHovered,
					'bi-check-square': selectAllHovered && !areAllSelected(),
					'bi-check-square-fill': areAllSelected()
				}"
				(click)="selectAll()">
			</div>
		</div>
		<div class="rows apx-scrollbar">
			<div class="assignment-template-row" *ngFor="let assignmentTemplate of assignmentTemplatesToUpdate">
				<a class="assignment-template"
					title="{{ ('GoToAssignmentTemplate' | translate) }} '{{ assignmentTemplate.name }}'"
					target="_blank"
					href="{{ assignmentTemplate.url }}">
					<div class="name-link-container">
						<div class="name">
							{{ assignmentTemplate.name }}
						</div>
						<div class="link-icon"></div>
					</div>
				</a>
				<div class="checkbox"
					title="{{ (assignmentTemplate.selected ? 'Unselect' : 'Select') | translate }}"
					(mouseover)="assignmentTemplate.hovered = true"
					(mouseleave)="assignmentTemplate.hovered = false"
					[ngClass]="{
						'bi-square': !assignmentTemplate.hovered && !assignmentTemplate.selected,
						'bi-check-square': assignmentTemplate.hovered && !assignmentTemplate.selected,
						'bi-check-square-fill': assignmentTemplate.selected
					}"
					(click)="selectRow(assignmentTemplate)">
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button"
			title="{{ 'Close' | translate }}"
			class="btn btn-transparent u-uppercase"
			(click)="close()">{{ "NoDontUpdateAssignmentTemplates" | translate }}
		</button>
		<button type="button"
			title="{{ 'Update' | translate }}"
			class="btn btn-success u-uppercase"
			[disabled]="!areSomeSelected() || !areAllSelected()"
			(click)="triggerUpdate()">{{ 'YesUpdateAssignmentTemplates' | translate }}
		</button>
	</div>
</div>