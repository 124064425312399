import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleChangeBulkTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/bulk/simple-change-bulk-tab.directive';
import { ALT_DATE_TIME_FORMAT_WITHOUT_SECONDS, DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Assignment } from 'app-inspection/assignment/assignment';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'set-due-date-tab',
	templateUrl: './set-due-date-tab.component.html'
})
export class SetDueDateTabComponent extends SimpleChangeBulkTabDirective<Assignment> implements OnInit {

	dueDateErrorMessage: string = '';

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.form = this.formBuilder.group({
			[this.propertyStrings.dueTime]: [
				this.changeModel.dueTime,
				Validators.required
			]
		});
		this.setDueDateErrorMessage();

		// Refresh every 1 second in order for startdate message to display correctly.
		this.subscriptions.add(
			interval(1000)
				.pipe(takeUntil(this.destroyed$))
				.subscribe(() => {
					this.setDueDateErrorMessage();
				})
		);
		super.ngOnInit();
	}

	handleDueDateChange(dateValue: string) {
		this.changeModel.dueDate = dateValue;
		this.setDueDateErrorMessage();
	}

	handleTimeChange() {
		this.setDueDateErrorMessage();
	}

	private setDueDateErrorMessage() {
		if (this.dueDateIsBeforeToday()) {
			this.dueDateErrorMessage = `${this.translationService.instant('MustBeLaterThanToday', { date: Utils.getToday(DEFAULT_DISPLAY_DATE_FORMAT) })}`;
		} else {
			this.dueDateErrorMessage = '';
		}
	}

	private dueDateIsBeforeToday() {
		const [dueDateWithoutTime, _] = this.changeModel.dueDate.split(' ');
		const dueDateWithDueTime = moment(new Date(`${dueDateWithoutTime} ${this.changeModel.dueTime}`)).format(ALT_DATE_TIME_FORMAT_WITHOUT_SECONDS);
		return dueDateWithDueTime <= Utils.getToday(ALT_DATE_TIME_FORMAT_WITHOUT_SECONDS);
	}
}
