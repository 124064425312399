import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { User } from 'app-core/user/user';
import { UserService } from 'app-core/user/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SetStatusTabComponent } from './tabs/set-status-tab.component';

@Component({
	templateUrl: './set-status-user.component.html'
})
export class SetStatusUserComponent extends SimpleBulkEditModalDirective<User> implements OnInit {

	@ViewChild(SetStatusTabComponent) setUserStatusTabComponent: SetStatusTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private userService: UserService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.initialModel = new User({});
		await this.getStatusData(this.initialModel.status);
	}

	private async getStatusData(value: string) {
		this.pending = true;
		try {
			const status = value === StringUtils.ACTIVE ? true : false;
			const response = await this.userService.getStatusData(this.editModelIds, status, this.selectedOrganization.id);
			this.affectableIds = response.data;
			this.pending = false;
		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	handleValueChanges() {
		if (this.setUserStatusTabComponent) {
			const statusCtrl = this.setUserStatusTabComponent.form.get(this.propertyStrings.status);
			this.getStatusData(statusCtrl.value);
		}
	}

	changeDetailsIsValid() {
		return this.setUserStatusTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.setUserStatusTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const ids = this.useAffectableIds ? this.affectableIds : this.editModelIds;
			const status = this.initialModel.status === StringUtils.ACTIVE ? true : false;
			const response = await this.userService.updateStatusWithOrgId(ids, status, this.selectedOrganization.id);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.setUserStatusTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: User) {
		return new User(item);
	}

	hasUnsavedChanges() {
		return false;
	}
}
