import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { DotsLoaderModule } from '../dots-loader/dots-loader.module';
import { GoogleMapsComponent } from './google-maps.component';

@NgModule({
	imports: [
		CommonModule,
		DotsLoaderModule,
		GoogleMapsModule,
	],
	declarations: [
		GoogleMapsComponent,
	],
	exports: [
		GoogleMapsComponent
	]
})
export class GoogleMapModule { }