export enum ErrorTypeKey {
	MovedPermanently = 301,
	BadRequest = 400,
	NotAuthorized = 401,
	Forbidden = 403,
	NotFound = 404,
	UnsupportedMediaType = 415,
	ServerError = 500,
	Default = 'None'
}

export class ErrorType {
	// Used to get the error type enum, based on Status Code from response.
	public static getErrorType(status: number): ErrorTypeKey {
		let errorType = ErrorTypeKey.Default;
		switch (status) {
			case 400:
				errorType = ErrorTypeKey.BadRequest;
				break;

			case 401:
				errorType = ErrorTypeKey.NotAuthorized;
				break;

			case 403:
				errorType = ErrorTypeKey.Forbidden;
				break;

			case 301:
			case 404:
				errorType = ErrorTypeKey.NotFound;
				break;

			case 415:
			case 500:
				errorType = ErrorTypeKey.ServerError;
				break;

			default:
				errorType = ErrorTypeKey.Default;
				break;
		}

		return errorType;
	}
}
