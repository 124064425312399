<simple-crud-modal
	[titleText]="'UserReal' | translate"
	[closeText]="'Close' | translate"
	[submitText]="getSelectedCountText('Delete' | translate)"
	[disableSubmit]="!affectableIdsAreValid()"
	[isDelete]="true"
	[isLoading]="pending"
	(onClose)="close()"
	(onSubmit)="submit()">
		<simple-delete
			[affectableIdsCount]="affectableIds.length"
			[selectedIdsCount]="deleteModelIds.length"
			[hideText]="hideText">
		</simple-delete>
</simple-crud-modal>
