import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { Organization } from 'app-core/organization/organization';
import { SimpleCrudDirective } from 'app-core/shared-core/simple-components/crud/simple-crud.directive';
import { SwalConfig } from 'app-core/shared-core/swal/swal-config.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { ScheduleForViewing } from 'app-inspection/schedule/schedule';
import { environment } from 'environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CreateEditUserComponent } from '../create-edit-user/create-edit-user.component';
import { OrganizationMembership, User } from '../user';
import { UserService } from '../user.service';
import { ProfileImageComponent } from './profile-image/profile-image.component';
import { PasswordComponent } from './security/password/password.component';

@Component({
	templateUrl: './profile.component.html',
	styleUrls: ['../../landing-page-shared-style.less']
})
export class ProfileComponent extends SimpleCrudDirective<User> implements OnInit {

	bsModalRef: BsModalRef<any>;

	documents: MediaWidgetItem[] = [];
	schedules: ScheduleForViewing[] = [];

	fetchingCultureSettings: boolean = false;
	fetchingDocuments: boolean = false;
	fetchingSchedules: boolean = false;

	actionsOpened: boolean = false;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private userService: UserService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.loggedInUser.userGroups = this.loggedInUser.userGroups.filter(group => group.organizationId === this.selectedOrganization.id);

		this.fetchingCultureSettings = true;
		this.fetchingDocuments = true;
		this.fetchingSchedules = true;

		try {
			if (!this.userService.cultureSettings) {
				this.userService.cultureSettings = await this.userService.getCultureSettings();
			}

			if (!this.userService.roles.length) {
				this.userService.roles = await this.userService.getRoles();
			}

			this.fetchingCultureSettings = false;
			this.assignCultureSettings();

			this.refreshDocuments();

			if (this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)) {
				this.refreshSchedules();
			} else {
				this.fetchingSchedules = false;
			}

		} catch (errorResponse) {
			this.fetchingCultureSettings = false;
			this.fetchingDocuments = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	private assignCultureSettings() {
		this.loggedInUser.selectableCountryCodes = this.userService.cultureSettings.countryCodes;
		this.loggedInUser.selectableCountries = this.userService.cultureSettings.countries;
		this.loggedInUser.selectableTimeZones = this.userService.cultureSettings.timeZones;
		this.loggedInUser.selectableCultures = this.userService.cultureSettings.cultures;
		this.loggedInUser.selectableRoles = this.userService.roles;
	}

	async getUserData() {
		try {
			const result = await this.userService.getPersonalData();
			this.displaySuccessMessage(result.successMessage);
		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}

	logout() {
		this.authService.startLogout();
	}

	getFormattedRoles(roles: string[]) {
		return this.translationService.translateArray(roles).toCommaSeparatedList();
	}

	getOrgName(organization: Organization) {
		return new Organization(organization).orgName;
	}

	getCountryName() {
		const country = this.loggedInUser.selectableCountries.find(c => c.id === this.loggedInUser.country);
		if (country) {
			return country.text;
		} else {
			return '';
		}
	}

	getTimeZoneName() {
		const timeZone = this.loggedInUser.selectableTimeZones.find(tz => tz.id === this.loggedInUser.timeZone);
		if (timeZone) {
			return timeZone.text;
		} else {
			return '';
		}
	}

	getCultureName() {
		const culture = this.loggedInUser.selectableCultures.find(c => c.id === this.loggedInUser.culture);
		if (culture) {
			return culture.text;
		} else {
			return '';
		}
	}

	openChangeImageModal() {
		this.bsModalRef = this.modalService.show(
			ProfileImageComponent,
			{
				initialState: {
					editModelId: this.loggedInUser.id,
					disableTabIndexUrl: true
				},
				...ConfigUtils.MODAL_CONFIG_MEDIUM,
				// ...this.closeInterceptorConfig()
			}
		);
		this.subscribeToCrudModalContent();
	}

	openDetailsModal() {
		this.bsModalRef = this.modalService.show(
			CreateEditUserComponent,
			{
				initialState: {
					editModelId: this.loggedInUser.id,
					disableTabIndexUrl: true
				},
				...ConfigUtils.MODAL_CONFIG_LARGE,
				// ...this.closeInterceptorConfig()
			}
		);
		this.subscribeToCrudModalContent(true);
	}

	openChangePasswordModal() {
		this.bsModalRef = this.modalService.show(
			PasswordComponent,
			{
				initialState: {
					disableTabIndexUrl: true
				},
				...ConfigUtils.MODAL_CONFIG_MEDIUM,
				// ...this.closeInterceptorConfig()
			}
		);
		this.subscribeToCrudModalContent();
	}

	protected subscribeToCrudModalContent(refreshDocuments?: boolean) {
		this.currentCrudModalComponent = this.bsModalRef.content;
		this.subscriptions.add(
			this.currentCrudModalComponent.updated$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(updatedItems => {
					if (updatedItems && updatedItems.length) {
						this.closeModal();

						if (updatedItems[0].culture !== this.loggedInUser.culture) {
							location.reload();
						} else {
							this.refresh();

							if (refreshDocuments) {
								this.refreshDocuments();
							}
						}
					}
				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(wasClosed => {
					if (wasClosed) {
						this.closeModalWithUnsavedChanges();
					}
				})
		);
	}

	closeModalWithUnsavedChanges() {
		if (this.hasUnsavedChanges()) {
			this.displayUnsavedChangesSwal()
				.then(result => {
					if (result.value) {
						this.closeModal();
					} else {
						// Set the closed value to false again as we have clicked the close button.
						this.currentCrudModalComponent.closed$.next(false);
					}
				});
		} else {
			this.closeModal();
		}
	}

	private closeModal() {
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
	}

	selectOrganization(organization: Organization, allowed: boolean) {
		if (organization.id !== this.selectedOrganization.id && allowed) {
			const url = `${environment.coreUrl}/${organization.friendlyUrl}/profile`;
			window.location.href = url;
		}
	}

	leaveMembership(membership: OrganizationMembership) {
		if (membership.status !== StringUtils.PENDING) {
			Swal.fire(
				new SwalConfig(this.translationService).getDelete({
					cancelButtonText: this.translationService.instant('Cancel'),
					confirmButtonText: this.translationService.instant('LeaveMembership')
				})
			).then(async result => {
				if (result.value) {
					try {
						const response = await this.userService.deleteWithOrgId(this.loggedInUser.id, membership.organization.id);
						this.displaySuccessMessage(response.successMessage);

						// If was the selected org, navigate to user org if not super admin.
						if (!this.loggedInUser.isSuperAdmin() && membership.organization.id === this.selectedOrganization.id) {
							await this.router.navigate([`/${this.loggedInUser.organization.friendlyUrl}/${RoutesUtils.profile}`], { replaceUrl: true });
							this.refresh();
						} else {
							this.refresh();
						}
					} catch (errorResponse) {
						this.handleErrorResponse(errorResponse);
					}
				}
			});
		}
	}

	private async refresh() {
		await this.authService.requestUser();
		this.loggedInUser = this.authService.loggedInUser;
		this.assignCultureSettings();
	}

	private async refreshDocuments() {
		this.fetchingDocuments = true;
		this.documents = await this.userService.getPdfs(this.loggedInUser.id);
		this.fetchingDocuments = false;
	}

	private async refreshSchedules() {
		this.fetchingSchedules = true;
		this.schedules = await this.userService.getSchedules(this.loggedInUser.id);
		this.fetchingSchedules = false;
	}

	isSmallScreenSize() {
		return Utils.isSmallScreenSize();
	}

	navigateToAdministrationPage() {
		this.router.navigate([`/${this.selectedOrganization.friendlyUrl}/${RoutesUtils.administration}`]);
	}
}
