import { NgModule } from '@angular/core';
import { MediaModule } from 'app-core/media/media-module';
import { SharedModule } from 'app-core/shared-core/shared.module';
import { UserGroupModule } from 'app-core/user-group/user-group.module';
import { TemplateTypeModule } from 'app-inspection/template-type/template-type.module';
import { CreateEditScheduleComponent } from './create-edit-schedule/create-edit-schedule.component';
import { ChangeDetailsTabComponent } from './create-edit-schedule/tabs/change-details/change-details-tab.component';
import { HandleAssignmentTemplatesTabComponent } from './create-edit-schedule/tabs/handle-assignment-templates/handle-assignment-templates-tab.component';
import { HandleScheduleGroupsTabComponent } from './create-edit-schedule/tabs/handle-schedule-groups/handle-schedule-groups-tab.component';
import { HandleScheduleUsersTabComponent } from './create-edit-schedule/tabs/handle-schedule-users/handle-schedule-users-tab.component';
import { CreateEditSubscriberComponent } from './create-edit-schedule/tabs/handle-subscribers/create-edit-subscriber/create-edit-subscriber.component';
import { ChangeSubscriberDetailsTabComponent } from './create-edit-schedule/tabs/handle-subscribers/create-edit-subscriber/tabs/change-subscriber-details-tab.component';
import { HandleSubscribersTabComponent } from './create-edit-schedule/tabs/handle-subscribers/handle-subscribers-tab.component';
import { DeleteSchedulesComponent } from './delete-schedules/delete-schedules.component';
import { HandleScheduleGroupsAndScheduleUsersComponent } from './list-schedule/handle-schedule-groups-and-schedule-users/handle-schedule-groups-and-schedule-users.component';
import { ListScheduleComponent } from './list-schedule/list-schedule.component';
import { SetStatusScheduleComponent } from './list-schedule/set-status/set-status-schedule.component';
import { SetStatusTabComponent } from './list-schedule/set-status/tabs/set-status-tab.component';
import { ScheduleRoutingModule } from './schedule-routing.module';
import { TimelineComponent } from './timeline/timeline.component';

@NgModule({
	imports: [
		SharedModule,
		ScheduleRoutingModule,
		UserGroupModule,
		TemplateTypeModule,
		MediaModule
	],
	declarations: [
		ListScheduleComponent,
		CreateEditScheduleComponent,
		ChangeDetailsTabComponent,
		TimelineComponent,
		HandleAssignmentTemplatesTabComponent,
		HandleScheduleGroupsTabComponent,
		HandleScheduleUsersTabComponent,
		HandleSubscribersTabComponent,
		CreateEditSubscriberComponent,
		ChangeSubscriberDetailsTabComponent,
		DeleteSchedulesComponent,
		HandleScheduleGroupsAndScheduleUsersComponent,
		SetStatusScheduleComponent,
		SetStatusTabComponent
	],
	exports: [
		HandleSubscribersTabComponent,
		CreateEditSubscriberComponent,
		ChangeSubscriberDetailsTabComponent
	]
})
export class ScheduleModule { }
