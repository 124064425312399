import { Component, Input } from '@angular/core';
import { UserGroup } from 'app-core/user-group/user-group';
import { AssignmentTemplate } from 'app-inspection/assignment-template/assignment-template';
import { Facility } from 'app-inspection/facility/facility';
import { ScheduleForViewing } from 'app-inspection/schedule/schedule';

@Component({
	selector: 'simple-view-connections',
	templateUrl: './simple-view-connections.component.html',
	styleUrls: ['./simple-view-connections.component.less']
})
export class SimpleViewConnectionsComponent {

	@Input() userGroups: UserGroup[] = [];
	@Input() assignmentTemplates: AssignmentTemplate[] = [];
	@Input() schedules: ScheduleForViewing[] = [];
	@Input() facility: Facility;
	@Input() disabled: boolean = false;
}
