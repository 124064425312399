import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { FileData, FileHelper } from 'app-core/shared-core/tools/file-helper';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { DEFAULT_DATE_FORMAT_EXPORT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Subscriber } from 'app-core/user/user';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../report.service';
import { GeneratedReport, GeneratedReportTypes } from './generated-report';
import { GenerateReportTabComponent } from './tabs/generate-report-tab.component';

@Component({
	templateUrl: './generate-report.component.html'
})
export class GenerateReportComponent extends SimpleBulkEditModalDirective<GeneratedReport> implements OnInit {

	isMeasure: boolean;
	isArchived: boolean;
	sort: {};

	tooLargeForDownload: boolean;
	sendReportByEmail: boolean;

	subscribers: Subscriber[] = [];

	@ViewChild(GenerateReportTabComponent) generateReportTabComponent: GenerateReportTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private reportService: ReportService,
		private fileHelper: FileHelper) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			this.subscribers = await this.reportService.getSubscribers(this.editModelIds, this.selectedOrganization.id);
			this.pending = false;

			this.tooLargeForDownload = this.editModelIds.length >= NumberUtils.MAX_ITEM_COUNT_FOR_REPORT_DOWNLOAD;
			this.sendReportByEmail = this.tooLargeForDownload;

			const defaultHeader = `${this.isMeasure
				? this.translationService.instant('ErrorReport')
				: this.translationService.instant('SummaryReport')} ${moment().format(DEFAULT_DATE_FORMAT_EXPORT)}`;

			this.initialModel = new GeneratedReport({
				header: defaultHeader
			});

			this.setLoggedInUserAsRecipient();

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	changeDetailsIsValid() {
		return this.generateReportTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.generateReportTabComponent?.formHasErrors();
	}

	private hasSubscribers() {
		return this.subscribers.some(subscriber => subscriber.selected);
	}

	private hasUsers() {
		return !!this.initialModel.users.length;
	}

	private hasExternalEmails() {
		return !!this.initialModel.emails.length;
	}

	everythingIsValid() {
		return this.changeDetailsIsValid()
			&& (!this.sendReportByEmail || (this.hasSubscribers() || this.hasUsers() || this.hasExternalEmails()));
	}

	protected async createOrUpdate() {
		const subscriberEmails = this.subscribers.filter(subscriber => subscriber.selected)
			.map(subscriber => subscriber.email);
		const userEmails = this.initialModel.users.map(user => user.email);
		const externalEmails = this.initialModel.emails;

		const allEmails = Utils.getUniqueEntriesOnly(...subscriberEmails, ...userEmails, ...externalEmails);

		const model = new GeneratedReport({
			itemIds: this.editModelIds,
			organizationId: this.selectedOrganization.id,
			header: this.initialModel.header,
			reportFileFormat: this.initialModel.reportFileFormat,
			emails: this.sendReportByEmail ? allEmails : [],
			reportType: this.isMeasure ? GeneratedReportTypes.Error : GeneratedReportTypes.Summary,
			status: this.isArchived ? StringUtils.COMPLETED : StringUtils.INCOMPLETED,
			sort: this.sort
		});

		this.pending = true;
		try {
			const response = await this.reportService.getGeneratedReport(model);
			this.displaySuccessMessage(response.successMessage);
			this.handled$.next(true);

			if (response.data) {
				this.fileHelper.handleFile(new FileData(response.data.file));
			}

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.generateReportTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: GeneratedReport) {
		return new GeneratedReport(item);
	}

	hasUnsavedChanges() {
		return false;
	}

	handleValueChanges() {
		if (this.generateReportTabComponent) {
			const sendReportByEmailCtrl = this.generateReportTabComponent.form.get('sendReportByEmail');
			this.sendReportByEmail = sendReportByEmailCtrl.value;

			if (this.sendReportByEmail) {
				this.generateReportTabComponent.setSelectableEmailItems();
			}
		}
	}

	private setLoggedInUserAsRecipient() {
		const loggedInUserInSubscribers = this.subscribers.find(subscriber => subscriber.email === this.loggedInUser.email);
		const loggedInUserIsInOrganization = this.loggedInUser.isOrgMember(this.selectedOrganization.friendlyUrl);

		if (!!loggedInUserInSubscribers) {
			loggedInUserInSubscribers.selected = true;
		} else if (loggedInUserIsInOrganization) {
			this.initialModel.users.push(this.loggedInUser);
		} else {
			this.initialModel.selectableEmails.push(this.loggedInUser.email);
			this.initialModel.emails.push(this.loggedInUser.email);
		}
	}
}
