<div class="side-menu"
	[ngClass]="{
		'collapsed': collapsed,
		'opened': menuOpenedMobile,
		'closed': !menuOpenedMobile
	}">
		<div class="header-item"
			[ngClass]="{
				'clickable': canSwitchOrganization()
			}"
			title="{{ canSwitchOrganization ? ('SwitchOrganization' | translate) : '' }}"
			(click)="toggleGlobalOrgModal()">
				<div class="org-logo" #parentDiv2>
					<extensive-image [imageUrl]="selectedOrganization.logotypeUrl"
						[imageStorageSize]="parentDiv2 | getImageStorageSize" [placeholderImageType]="'organization'">
					</extensive-image>
				</div>
				<div class="org-info">
					<div class="org-name">
						{{ selectedOrganization.orgName }}
					</div>
					<div class="org-roles">
						{{ getCurrentRole() }}
					</div>
				</div>
				<div class="org-icon bi-layers" *ngIf="canSwitchOrganization()">
				</div>
				<div class="test-marker" *ngIf="(loggedInUser | isSuperAdmin) && selectedOrganization.isTest">
					{{ stringUtils.TEST }}
				</div>
		</div>

		<div class="items-wrapper">

			<div class="items apx-scrollbar" scrollShadow>

				<a class="item" *ngIf="loggedInUser | canEdit: selectedOrganization.friendlyUrl"
					routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.dashboard}}"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.dashboard),
						'is-disabled': routeIsDisabled(routesUtils.dashboard)
					}"
					[title]="'Dashboard' | translate"
					(click)="handleItemClick()">
						<i class="link-icon bi-graph-up-arrow"></i>
						{{ 'Dashboard' | translate }}
				</a>

				<a class="item"
					routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.assignments}}/{{routesUtils.scheduledOngoing}}"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.assignments + '/' + routesUtils.scheduledOngoing) || routeIsActive(routesUtils.assignments + '/' + routesUtils.scheduledArchived),
						'is-disabled': routeIsDisabled(routesUtils.assignments + '/' + routesUtils.scheduledOngoing) || routeIsDisabled(routesUtils.assignments + '/' + routesUtils.scheduledArchived)
					}"
					[title]="'ScheduledAssignments' | translate"
					(click)="handleItemClick()">
						<i class="link-icon ii-assignment"></i>
						{{ 'ScheduledAssignments' | translate }}
				</a>

				<a class="item sub-item with-border-start"
					routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.assignments}}/{{routesUtils.scheduledOngoing}}"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.assignments + '/' + routesUtils.scheduledOngoing),
						'is-disabled': routeIsDisabled(routesUtils.assignments + '/' + routesUtils.scheduledOngoing),
						'is-visible': routeIsActive(routesUtils.assignments + '/' + routesUtils.scheduledOngoing) || routeIsActive(routesUtils.assignments + '/' + routesUtils.scheduledArchived)
					}"
					[title]="'Ongoing' | translate"
					(click)="handleItemClick()">
						<i class="link-icon bi-phone"></i>
						{{ 'Ongoing' | translate }}
						<div class="ribbon"></div>
				</a>

				<a class="item sub-item with-border-end"
					routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.assignments}}/{{routesUtils.scheduledArchived}}"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.assignments + '/' + routesUtils.scheduledArchived),
						'is-disabled': routeIsDisabled(routesUtils.assignments + '/' + routesUtils.scheduledArchived),
						'is-visible': routeIsActive(routesUtils.assignments + '/' + routesUtils.scheduledOngoing) || routeIsActive(routesUtils.assignments + '/' + routesUtils.scheduledArchived)
					}"
					[title]="'ArchivedPlural' | translate"
					(click)="handleItemClick()">
						<i class="link-icon bi-archive"></i>
						{{ 'ArchivedPlural' | translate }}
						<div class="ribbon"></div>
				</a>

				<a class="item"
					routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.assignments}}/{{routesUtils.measureOngoing}}"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.assignments + '/' + routesUtils.measureOngoing) || routeIsActive(routesUtils.assignments + '/' + routesUtils.measureArchived),
						'is-disabled': routeIsDisabled(routesUtils.assignments + '/' + routesUtils.measureOngoing) || routeIsDisabled(routesUtils.assignments + '/' + routesUtils.measureArchived)
					}"
					[title]="'MeasureAssignments' | translate"
					(click)="handleItemClick()">
						<i class="link-icon ii-measure"></i>
						{{ 'MeasureAssignments' | translate }}
				</a>

				<a class="item sub-item with-border-start"
					routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.assignments}}/{{routesUtils.measureOngoing}}"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.assignments + '/' + routesUtils.measureOngoing),
						'is-disabled': routeIsDisabled(routesUtils.assignments + '/' + routesUtils.measureOngoing),
						'is-visible': routeIsActive(routesUtils.assignments + '/' + routesUtils.measureOngoing) || routeIsActive(routesUtils.assignments + '/' + routesUtils.measureArchived)
					}"
					[title]="'Ongoing' | translate"
					(click)="handleItemClick()">
						<i class="link-icon bi-phone"></i>
						{{ 'Ongoing' | translate }}
						<div class="ribbon"></div>
				</a>

				<a class="item sub-item with-border-end"
					routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.assignments}}/{{routesUtils.measureArchived}}"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.assignments + '/' + routesUtils.measureArchived),
						'is-disabled': routeIsDisabled(routesUtils.assignments + '/' + routesUtils.measureArchived),
						'is-visible': routeIsActive(routesUtils.assignments + '/' + routesUtils.measureOngoing) || routeIsActive(routesUtils.assignments + '/' + routesUtils.measureArchived)
					}"
					[title]="'ArchivedPlural' | translate"
					(click)="handleItemClick()">
						<i class="link-icon bi-archive"></i>
						{{ 'ArchivedPlural' | translate }}
						<div class="ribbon"></div>
				</a>

				<a class="item" routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.schedules}}" *ngIf="loggedInUser | canEdit: selectedOrganization.friendlyUrl"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.schedules),
						'is-disabled': routeIsDisabled(routesUtils.schedules)
					}"
					[title]="'Schedules' | translate"
					(click)="handleItemClick()">
						<i class="link-icon bi-calendar-week"></i>
						{{ 'Schedules' | translate }}
						<div class="ribbon"></div>
				</a>

				<a class="item" routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.assignmentTemplates}}" *ngIf="loggedInUser | canEdit: selectedOrganization.friendlyUrl"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.assignmentTemplates),
						'is-disabled': routeIsDisabled(routesUtils.assignmentTemplates)
					}"
					[title]="'AssignmentTemplates' | translate"
					(click)="handleItemClick()">
						<i class="link-icon bi-map"></i>
						{{ 'AssignmentTemplates' | translate }}
						<div class="ribbon"></div>
				</a>

				<a class="item" routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.facilities}}" *ngIf="loggedInUser | canEdit: selectedOrganization.friendlyUrl"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.facilities),
						'is-disabled': routeIsDisabled(routesUtils.facilities)
					}"
					[title]="'Facilities' | translate"
					(click)="handleItemClick()">
						<i class="link-icon ii-facility"></i>
						{{ 'Facilities' | translate }}
						<div class="ribbon"></div>
				</a>

				<a class="item" routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.entities}}" *ngIf="loggedInUser | canEdit: selectedOrganization.friendlyUrl"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.entities),
						'is-disabled': routeIsDisabled(routesUtils.entities)
					}"
					[title]="'Entities' | translate"
					(click)="handleItemClick()">
						<i class="link-icon ii-entities"></i>
						{{ 'Entities' | translate }}
						<div class="ribbon"></div>
				</a>

				<a class="item" routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.userGroups}}" *ngIf="loggedInUser | canEdit: selectedOrganization.friendlyUrl"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.userGroups),
						'is-disabled': routeIsDisabled(routesUtils.userGroups)
					}"
					[title]="'Groups' | translate"
					(click)="handleItemClick()">
						<i class="link-icon bi-people"></i>
						{{ 'Groups' | translate }}
						<div class="ribbon"></div>
				</a>

				<a class="item" routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.users}}"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.users),
						'is-disabled': routeIsDisabled(routesUtils.users)
					}"
					[title]="'Users' | translate"
					(click)="handleItemClick()">
						<i class="link-icon bi-person"></i>
						{{ 'Users' | translate }}
						<div class="ribbon"></div>
				</a>

				<a class="item" routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.templateTypes}}" *ngIf="loggedInUser | isSuperAdmin"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.templateTypes),
						'is-disabled': routeIsDisabled(routesUtils.templateTypes)
					}"
					[title]="'TemplateTypes' | translate"
					(click)="handleItemClick()">
						<i class="link-icon ii-template-type"></i>
						{{ 'TemplateTypes' | translate }}
						<div class="ribbon"></div>
				</a>

				<a class="item" routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.tasks}}" *ngIf="loggedInUser | isSuperAdmin"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.tasks),
						'is-disabled': routeIsDisabled(routesUtils.tasks)
					}"
					[title]="'Tasks' | translate"
					(click)="handleItemClick()">
						<i class="link-icon ii-task"></i>
						{{ 'Tasks' | translate }}
						<div class="ribbon"></div>
				</a>

				<a class="item" routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.choices}}" *ngIf="loggedInUser | isSuperAdmin"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.choices),
						'is-disabled': routeIsDisabled(routesUtils.choices)
					}"
					[title]="'Choices' | translate"
					(click)="handleItemClick()">
						<i class="link-icon bi-check2-circle"></i>
						{{ 'Choices' | translate }}
						<div class="ribbon"></div>
				</a>

			</div>

			<div class="footer-items">
				<div class="collapse-button"
					[ngClass]="{
						'bi-chevron-double-right': collapsed,
						'bi-chevron-bar-left': !collapsed
					}"
					[title]="'ToggleMenu' | translate"
					(click)="toggleMenu()">
				</div>
				<a class="item organization" routerLink="/{{selectedOrganization.friendlyUrl}}/{{routesUtils.organization}}"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.organization),
						'is-disabled': routeIsDisabled(routesUtils.organization)
					}"
					[title]="'MyOrganization' | translate"
					(click)="handleItemClick()">
						<div class="org-icon bi-globe"></div>
						{{ 'MyOrganization' | translate }}
						<div class="ribbon"></div>
				</a>

			</div>
		</div>

</div>