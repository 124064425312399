import { Injectable } from '@angular/core';
import { Organization } from 'app-core/organization/organization';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { UrlUtils } from 'app-core/shared-core/tools/url-utils';
import { LoggedInUser } from 'app-core/user/user';
import * as appConfig from '../../../package.json';
import { FeedbackObject } from './feedback.component';

@Injectable()
export class FeedbackService {

	async createClickUpSpec(feedback: FeedbackObject, user: LoggedInUser, selectedOrganization: Organization) {
		return await fetch(`${UrlUtils.CLICKUP_INTERACTION}/${RoutesUtils.clickUpActions.postFeedback}`, {
			method: 'POST',
			body: JSON.stringify({
				url: window.location.href,
				selectedOrganizationId: selectedOrganization.id,
				selectedOrganizationName: selectedOrganization.name,
				organizationId: user.organizationId,
				organizationName: user.organization.name,
				userId: user.id,
				email: user.email,
				fullName: user.fullName,
				version: appConfig.version,
				userAgent: navigator.userAgent,
				description: feedback.description,
				screenshot: feedback.screenshot,
			}),
		});
	}
}
