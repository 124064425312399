import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { canActivateFn } from 'app-core/auth/auth-guard/auth-guard.service';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { ErrorComponent } from '../error/error.component';
import { MyOrganizationComponent } from './my-organization/my-organization.component';

/**
 * Navigation routes for the organization module
 */
@NgModule({
	imports: [
		RouterModule.forChild([
			{
				path: RoutesUtils.empty,
				component: MyOrganizationComponent,
				canActivate: [canActivateFn],
				data: {
					title: 'MyOrganization'
				}
			},

			// When no route matches.
			// Show the page not found component.
			{
				path: RoutesUtils.notSpecified,
				component: ErrorComponent,
				canActivate: [canActivateFn],
				data: {
					title: 'PageNotFound',
					isInvalid: true
				}
			}
		])
	],
	exports: [
		RouterModule
	]
})
export class OrganizationRoutingModule { }
