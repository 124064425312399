<div class="navigate-to"
	[ngClass]="{
		'disabled': shouldDisable,
		'disabled-text': shouldDisable && linkText
	}"
	title="{{ titleText }}"
	(click)="navigateTo($event)">
		<div class="bi bi-box-arrow-up-right icon" *ngIf="!linkText">
		</div>
		<a class="link" *ngIf="linkText">
			{{ linkText }}
		</a>
</div>
