import { SimpleAction } from '../../simple-action';
import { SimpleTableRow } from './simple-table-row';

export class SimpleTableRowAction<T extends { id: string }> extends SimpleAction {

	readonly event: (row: SimpleTableRow<T>) => void;
	readonly hidden: (row: SimpleTableRow<T>) => boolean;
	isEditAction: boolean;
	isReferenceUrlAction: boolean;
	isDeleteAction: boolean;
	keepOpen: boolean = false;

	constructor(
		name: string,
		iconName: string,
		event: (row: SimpleTableRow<T>) => void,
		hidden?: (row: SimpleTableRow<T>) => boolean) {
		super(
			name,
			iconName
		);
		this.event = event;
		this.hidden = hidden ? hidden : () => false;
	}
}

export class SimpleTableRowActionEdit<T extends { id: string }> extends SimpleTableRowAction<T> {
	isEditAction = true;
}

export class SimpleTableRowActionReferenceUrl<T extends { id: string }> extends SimpleTableRowAction<T> {
	isReferenceUrlAction = true;
}

export class SimpleTableRowActionDelete<T extends { id: string }> extends SimpleTableRowAction<T> {
	isDeleteAction = true;
}

export class SimpleTableRowActionKeepOpen<T extends { id: string }> extends SimpleTableRowAction<T> {
	keepOpen = true;
}
