import { Component, Input, ViewChild } from '@angular/core';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { MediaWidgetComponent } from 'app-core/media/widget/media-widget.component';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';

@Component({
	selector: 'handle-media-tab',
	templateUrl: './handle-media-tab.component.html'
})
export class HandleMediaTabComponent extends SimpleHandleTabDirective<MediaWidgetItem> {

	@Input() onlyImageUpload: boolean;
	@Input() onlyDocumentUpload: boolean;
	@Input() canToggleDefault: boolean;
	@Input() modifiedTitle: string = null;
	@Input() modifiedAddButtonText: string = null;
	@Input() modifiedDummyText: string = null;

	@ViewChild(MediaWidgetComponent) mediaWidgetComponent: MediaWidgetComponent;

	triggerUpload() {
		this.mediaWidgetComponent.fileInput.click();
	}

	itemListIsValid() {
		return true;
	}

	handleMediaCollectionChange(collection: MediaWidgetItem[]) {
		this.onModelsChange.emit(collection);
	}

	protected validate() { }

	getItems() {
		return this.handleItems;
	}
}
