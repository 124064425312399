import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import * as appConfig from '../../package.json';
import 'zone.js/plugins/zone-error'; // More detailed errors, they say.

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
	production: false,
	logger: false,
	baseUrl: RoutesUtils.base,
	clientId: 'WebAdmin',
	idServer: RoutesUtils.devEnv.idServer,
	coreApi: RoutesUtils.devEnv.coreApi,
	inspectionApi: RoutesUtils.devEnv.inspectionApi,
	coreUrl: RoutesUtils.devEnv.coreUrl,
	storageUrl: RoutesUtils.devEnv.storageUrl,
	appInsights: {
		instrumentationKey: '72161c3f-337c-47e1-89aa-d77876b28bf1'
	},
	cacheBusterHash: appConfig.version
};
