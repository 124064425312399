import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { HandleMediaTabComponent } from 'app-core/media/handle-media/handle-media-tab.component';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { BackendResponse } from 'app-core/shared-core/abstract-components/service/base.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Entity } from '../entity';
import { EntityService } from '../entity.service';
import { ChangeDetailsTabComponent } from './tabs/change-details/change-details-tab.component';
import { ViewDetailsTabComponent } from './tabs/view-details/view-details-tab.component';

@Component({
	templateUrl: './create-edit-entity.component.html'
})
export class CreateEditEntityComponent extends SimpleCreateEditModalDirective<Entity> implements OnInit {

	hideFacility: boolean;

	@ViewChild(ViewDetailsTabComponent) viewEntityDetailsTabComponent: ViewDetailsTabComponent;
	@ViewChild(ChangeDetailsTabComponent) changeEntityDetailsTabComponent: ChangeDetailsTabComponent;
	@ViewChild(HandleMediaTabComponent) handleMediaTabComponent: HandleMediaTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private entityService: EntityService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			if (this.isEdit) {
				const facilities = await this.entityService.getFacilities();

				this.initialModel = await this.entityService.get(this.editModelId);
				this.pending = false;

				this.initialModel.selectableFacilities = facilities;

				this.modifiedModel = new Entity(this.getUniqueVariant(this.initialModel));

				setTimeout(() => {
					this.activateTabFromUrl();
					this.triggerAllValidation();
				}, 0);
			} else {
				const facilities = await this.entityService.getFacilities();
				this.pending = false;

				this.initialModel = new Entity({
					selectableFacilities: facilities
				});

				this.modifiedModel = new Entity(this.getUniqueVariant(this.initialModel));

				setTimeout(() => {
					this.activateTabFromUrl();
				}, 0);
			}

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	handleValueChanges() {
		if (this.changeEntityDetailsTabComponent) {
			const latCtrl = this.changeEntityDetailsTabComponent.form.get(this.propertyStrings.latitude);
			const longCtrl = this.changeEntityDetailsTabComponent.form.get(this.propertyStrings.longitude);

			if (latCtrl.value && latCtrl.valid && longCtrl.value && longCtrl.valid) {
				this.refreshMap(latCtrl.value.replace(',', '.'), longCtrl.value.replace(',', '.'));
			}
		}
	}

	private refreshMap(lat: string, long: string) {
		const latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(long));

		this.changeEntityDetailsTabComponent.googleMapsComponent.updateLocation(latLng);
		this.changeEntityDetailsTabComponent.googleMapsComponent.displayMarker();
		this.changeEntityDetailsTabComponent.googleMapsComponent.determineZoom();

		if (this.isEdit && this.viewEntityDetailsTabComponent.googleMapsComponent) {
			this.viewEntityDetailsTabComponent.googleMapsComponent.updateLocation(latLng);
			this.viewEntityDetailsTabComponent.googleMapsComponent.displayMarker();
			this.viewEntityDetailsTabComponent.googleMapsComponent.determineZoom();
		}
	}

	changeDetailsIsValid() {
		return this.changeEntityDetailsTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeEntityDetailsTabComponent?.formHasErrors();
	}

	handleMediaIsValid() {
		return this.handleMediaTabComponent?.itemListIsValid();
	}

	handleMediaHasErrors() {
		return this.handleMediaTabComponent?.itemListHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid() && this.handleMediaIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.entityService.update(this.modifiedModel.toPayloadObject(this.selectedOrganization.id))
				: await this.entityService.create(this.modifiedModel.toPayloadObject(this.selectedOrganization.id));

			const customMessage = this.isEdit
				? this.translationService.instant('BackgroundJobStartedEditEntity')
				: this.translationService.instant('BackgroundJobStartedCreateEntity');

			let entityId = response.data.id;
			if (!response.data.backgroundJobStarted && response.data.created.length === 1) {
				entityId = response.data.created[0].id;
			}
			const uploadMediaPromises: Promise<BackendResponse>[] = [];
			const existingMedia: MediaWidgetItem[] = [];

			if (this.mediaHasChanged()) {
				this.modifiedModel.media.forEach(item => {
					if (item.blob) {
						const uploadMediaPromise = this.entityService.uploadMedia(entityId, item).toPromise();
						uploadMediaPromises.push(uploadMediaPromise);
					} else {
						existingMedia.push(item);
					}
				});

				if (this.isEdit) {
					const uploadMediaResponses = await Promise.all(uploadMediaPromises);

					const uploadedMedia = uploadMediaResponses.map(obj => {
						const data = JSON.parse(obj['response']).data;
						return new MediaWidgetItem(data);
					});

					const defaultIsAmongUploaded = uploadedMedia.some(item => item.default);
					if (defaultIsAmongUploaded) {
						existingMedia.forEach(item => item.default = false);
					}

					const allMedia = existingMedia.concat(uploadedMedia);

					await this.entityService.assignMedia(entityId, allMedia);
				} else {
					await Promise.all(uploadMediaPromises);
				}
				this.handleSuccessResponse(response, customMessage);
			} else {
				this.handleSuccessResponse(response, customMessage);
			}

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeEntityDetailsTabComponent.setServerErrors(this.serverErrors);
				this.goToTab(1);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeEntityDetailsTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Entity) {
		return new Entity(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.name !== this.initialModel.name
			|| this.modifiedModel.description !== this.initialModel.description
			|| this.modifiedModel.manufacturer !== this.initialModel.manufacturer
			|| this.modifiedModel.assembler !== this.initialModel.assembler
			|| this.modifiedModel.assemblyYear !== this.initialModel.assemblyYear
			|| this.modifiedModel.typeDesignation !== this.initialModel.typeDesignation
			|| this.modifiedModel.infoLink !== this.initialModel.infoLink
			|| this.modifiedModel.externalId !== this.initialModel.externalId
			|| this.modifiedModel.status !== this.initialModel.status
			|| this.modifiedModel.facilityId !== this.initialModel.facilityId
			|| this.modifiedModel.latitude !== this.initialModel.latitude
			|| this.modifiedModel.longitude !== this.initialModel.longitude
			|| this.modifiedModel.accessible !== this.initialModel.accessible
			|| this.modifiedModel.articleId !== this.initialModel.articleId
			|| this.modifiedModel.extraInfo !== this.initialModel.extraInfo;
	}

	private mediaHasChanged() {
		const currentMedia = this.stripActionsOpened(this.modifiedModel.media);
		const initialMedia = this.stripActionsOpened(this.initialModel.media);
		return !Utils.isEqual(currentMedia, initialMedia);
	}

	private stripActionsOpened(items: MediaWidgetItem[]) {
		return items.map(item => {
			const { actionsOpened, ...theRest } = item;
			return theRest as MediaWidgetItem;
		});
	}

	hasUnsavedChanges() {
		return this.modifiedModel && (this.detailsHasChanged() || this.mediaHasChanged());
	}
}
