import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCrudDirective } from 'app-core/shared-core/simple-components/crud/simple-crud.directive';
import { SwalConfig } from 'app-core/shared-core/swal/swal-config.component';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { AdministrationService } from './administration.service';

@Component({
	templateUrl: './administration.component.html',
	styleUrls: ['../landing-page-shared-style.less']
})
export class AdministrationComponent extends SimpleCrudDirective<any> {

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private administrationService: AdministrationService,
		private toastr: ToastrService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async openRecreateSwal() {
		this.pending = true;
		try {
			const assignments = await this.administrationService.getAssignmentsWithMissingReports();
			this.pending = false;

			Swal.fire(
				new SwalConfig(this.translationService).getInfo({
					title: this.translationService.instant('RecreateAssignmentReports'),
					text: `${assignments.length} st ${this.translationService.instant('ToRecreate')}`,
					cancelButtonText: assignments.length ? 'Cancel' : 'Close',
					confirmButtonText: this.translationService.instant('Recreate'),
					showConfirmButton: !!assignments.length
				})
			).then(async result => {
				if (result.value) {
					this.toastr.info('Återskapning av uppdragsrapporterna är påbörjad');
					try {
						const response = await this.administrationService.recreateReports(assignments.map(item => item.id), this.selectedOrganization.id);
						const message = `${response.data.succeeded.length} av ${response.data.succeeded.length + response.data.failed.length} uppdragsrapporter gick att återskapa. Det kan ta ett litet tag innan allt är helt färdigt.`;
						this.toastrService.success(message);

					} catch (errorResponse) {
						this.toastr.error('Det gick ej att återskapa rapporterna');
						const messages1 = errorResponse.error?.errors['Report'] as Array<string>;
						if (messages1) {
							console.error(messages1.join());
						}
						const messages2 = errorResponse.error?.errors['Assignment'] as Array<string>;
						if (messages2) {
							console.error(messages2.join());
						}
					}
				}
			});

		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}
}
