import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { User } from 'app-core/user/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../app-core/auth/auth.service';
import { UserGroup } from '../user-group';
import { UserGroupService } from '../user-group.service';
import { ChangeDetailsTabComponent } from './tabs/change-details/change-details-tab.component';
import { HandleUsersTabComponent } from './tabs/handle-users/handle-users-tab.component';

@Component({
	templateUrl: './create-edit-user-group.component.html'
})
export class CreateEditUserGroupComponent extends SimpleCreateEditModalDirective<UserGroup> implements OnInit {

	@ViewChild(ChangeDetailsTabComponent) changeUserGroupDetailsTabComponent: ChangeDetailsTabComponent;
	@ViewChild(HandleUsersTabComponent) handleUsersTabComponent: HandleUsersTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private userGroupService: UserGroupService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		if (this.isEdit) {
			this.pending = true;
			try {
				this.initialModel = await this.userGroupService.get(this.editModelId);
				this.pending = false;

				this.modifiedModel = new UserGroup(this.getUniqueVariant(this.initialModel));

				setTimeout(() => {
					this.activateTabFromUrl();
					this.triggerAllValidation();
				}, 0);

			} catch (errorResponse) {
				this.pending = false;
				this.handleErrorResponse(errorResponse);
				this.closeWithDelay();
			}
		} else {
			this.initialModel = new UserGroup({});
			this.modifiedModel = new UserGroup(this.getUniqueVariant(this.initialModel));

			setTimeout(() => {
				this.activateTabFromUrl();
			}, 0);
		}
	}

	changeDetailsIsValid() {
		return this.changeUserGroupDetailsTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.changeUserGroupDetailsTabComponent?.formHasErrors();
	}

	handleUsersIsValid() {
		return this.handleUsersTabComponent?.itemListIsValid();
	}

	handleUsersHasErrors() {
		return this.handleUsersTabComponent?.itemListHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid() && this.handleUsersIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.isEdit
				? await this.userGroupService.update(this.modifiedModel.toPayloadObject(this.selectedOrganization.id))
				: await this.userGroupService.create(this.modifiedModel.toPayloadObject(this.selectedOrganization.id));

			// Refresh the logged in user if was added or removed from a group, in order to display correct info on the profile page.
			const loggedInUserExistsInInitial = this.initialModel.users.find(user => user.id === this.loggedInUser.id);
			const loggedInUserExistsInModified = this.modifiedModel.users.find(user => user.id === this.loggedInUser.id);
			if ((loggedInUserExistsInInitial && !loggedInUserExistsInModified) || !loggedInUserExistsInInitial && loggedInUserExistsInModified) {
				this.authService.requestUser();
			}

			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.changeUserGroupDetailsTabComponent.setServerErrors(this.serverErrors);
				this.goToTab(1);
			}
		}
	}

	protected triggerAllValidation() {
		this.changeUserGroupDetailsTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: UserGroup) {
		return new UserGroup(item);
	}

	private detailsHasChanged() {
		return this.modifiedModel.name !== this.initialModel.name;
	}

	private usersHasChanged() {
		const currentUsers = this.stripSelected(this.modifiedModel.users);
		const initialUsers = this.stripSelected(this.initialModel.users);
		return !Utils.isEqual(currentUsers, initialUsers);
	}

	private stripSelected(items: User[]) {
		return items.map(item => {
			const { selected, ...theRest } = item;
			return theRest as User;
		});
	}

	private inlineListHasUnsavedChanges() {
		return this.handleUsersTabComponent?.inlineListHasUnsavedChanges();
	}

	hasUnsavedChanges() {
		return this.modifiedModel && (this.detailsHasChanged() || this.usersHasChanged() || this.inlineListHasUnsavedChanges());
	}
}
