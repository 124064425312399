import { PropertyNameGetter } from 'app-core/shared-core/tools/property-name-getter';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';

export class CrudItem<T> {
	id: string = '';
	created: string = '';
	updated: string = '';
	selected: boolean = false;
	actionsOpened: boolean = false;
	errorMessages: string[] = [];
	url: string = '';

	get propertyStrings() {
		return PropertyNameGetter.propertiesOf({} as new (args?: any) => T);
	}

	setUrl(domainRoute: string, prefix?: string) {
		if (prefix) {
			this.url = `${domainRoute}?${prefix}=${RoutesUtils.modalPrefixValueEdit}_${this.id}&${RoutesUtils.modalTabParam}=1`;
		} else {
			this.url = domainRoute;
		}
	}
}
