import { Component, ElementRef, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { SimpleListAction } from 'app-core/shared-core/simple-components/list/actions/simple-list-action';
import { SimpleListDirective } from 'app-core/shared-core/simple-components/list/simple-list.directive';
import { SimpleTableRowAction, SimpleTableRowActionDelete, SimpleTableRowActionEdit } from 'app-core/shared-core/simple-components/list/table/body/simple-table-row-action';
import { EventsContent, SimpleTableEventsColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-events-column';
import { SimpleTableStatusColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-status-column';
import { SimpleTableTextColumn } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-text-column';
import { SimpleTableThumbnailMainSecondaryColumn, ThumbnailTextContent } from 'app-core/shared-core/simple-components/list/table/columns/simple-table-thumbnail-main-secondary-column';
import { SimpleTableEmptyState } from 'app-core/shared-core/simple-components/list/table/empty-state/simple-table-empty-state';
import { SimpleFilterInput, SimpleFilterInputType } from 'app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-input-settings';
import { SimpleFilterInputItem } from 'app-core/shared-core/simple-components/list/table/filter/simple-filter-input-item';
import { SimpleRetainService } from 'app-core/shared-core/simple-components/list/table/filter/simple-retain.service';
import { SimpleTableHeaderAction, SimpleTableHeaderActionDelete } from 'app-core/shared-core/simple-components/list/table/head/simple-table-header-action';
import { TextContent } from 'app-core/shared-core/simple-components/various/text-content/simple-text-content.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { DEFAULT_DISPLAY_DATE_FORMAT, Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { environment } from 'environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SimpleFilterConfig, SortObject } from '../../shared-core/simple-components/list/table/filter/simple-filter-config';
import { SimpleTableConfig } from '../../shared-core/simple-components/list/table/simple-table-config';
import { CreateEditUserComponent } from '../create-edit-user/create-edit-user.component';
import { DeleteUsersComponent } from '../delete-users/delete-users.component';
import { PasswordComponent } from '../my-profile/security/password/password.component';
import { ResendEmailComponent } from '../resend-email/resend-email.component';
import { User } from '../user';
import { UserFilter, UserService } from '../user.service';
import { SetStatusUserComponent } from './set-status/set-status-user.component';

@Component({
	selector: 'list-user',
	templateUrl: '../../../app-core/shared-core/simple-components/list/simple-list-shared-template.html'
})
export class ListUserComponent extends SimpleListDirective<User> {

	// Override filterObject to match backend.
	readonly filterObject = new UserFilter();

	wasRowAction: boolean = false;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected sanitizer: DomSanitizer,
		protected userService: UserService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected retainService: SimpleRetainService,
		protected elementRef: ElementRef,
		protected viewContainerRef: ViewContainerRef) {
		super(
			authService,
			modalService,
			toastrService,
			translationService,
			userService,
			route,
			router,
			retainService,
			elementRef,
			viewContainerRef,
			RoutesUtils.user
		);
	}

	protected configureListActions() {
		this.setListActions(
			new SimpleListAction(
				this.translationService.instant('Create'),
				this.translationService.instant('NewUser'),
				StringUtils.icons.new,
				() => {
					if (!this.isInModal) {
						this.setCrudParams(RoutesUtils.modalPrefixValueNew);
					} else {
						this.openModalByActionName(RoutesUtils.modalPrefixValueNew);
					}
				},
				() => {
					return !this.loggedInUser.isSuperAdmin();
				}
			),
			new SimpleListAction(
				this.translationService.instant('Invite'),
				this.translationService.instant('Invite'),
				StringUtils.icons.email,
				() => {
					if (!this.isInModal) {
						this.setCrudParams(RoutesUtils.modalPrefixValueInvite);
					} else {
						this.openModalByActionName(RoutesUtils.modalPrefixValueInvite);
					}
				}
			)
		);
	}

	protected async configureTableFilter(config: SimpleFilterConfig<User>) {
		config.setInitialFacets(
			new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, 'Or')
		);

		const rolesKey = StringUtils.ROLES_KEY;
		const statusesKey = StringUtils.STATUSES_KEY;
		if (!this.isInModal) {
			this.retainService.setCurrentRetainEntries({
				search: null,
				sort: null,
				[rolesKey]: null,
				[statusesKey]: null
			});
		}

		try {
			const roles = await this.userService.getRoles();
			const convertedRoles = roles.map(role => new SimpleFilterInputItem(role.id, this.translationService.instant(role.id)));

			config.setFilterInputs(
				new SimpleFilterInput(
					rolesKey,
					this.translationService.instant(rolesKey),
					SimpleFilterInputType.MultiSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(rolesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(rolesKey, ''));
						}
						return keyValuePairs;
					},
					...convertedRoles
				),
				new SimpleFilterInput(
					statusesKey,
					this.translationService.instant(statusesKey),
					SimpleFilterInputType.MultiSelect,
					(values: string) => {
						const keyValuePairs: KeyValuePair[] = [];
						if (values) {
							keyValuePairs.push(new KeyValuePair(statusesKey, values));
						} else {
							keyValuePairs.push(new KeyValuePair(statusesKey, ''));
						}
						return keyValuePairs;
					},
					new SimpleFilterInputItem(
						StringUtils.ACTIVE,
						this.translationService.instant(StringUtils.ACTIVE)
					),
					new SimpleFilterInputItem(
						StringUtils.INACTIVE,
						this.translationService.instant(StringUtils.INACTIVE)
					),
					new SimpleFilterInputItem(
						StringUtils.PENDING,
						this.translationService.instant(StringUtils.PENDING)
					)
				)
			);

			if (!this.isInModal) {
				// Needs to be called here due to the data fetch delay.
				this.setSelectedFilterItems();
			}

		} catch (errorResponse) {
			this.handleErrorResponse(errorResponse);
		}
	}

	protected configureTableSort(config: SimpleFilterConfig<User>) {
		config.setSortObjects(
			new SortObject(
				this.propertyStrings.firstname,
				this.translationService.instant('Name'),
				true
			)
		);
	}

	protected configureTableColumns(config: SimpleTableConfig<User>) {
		config.setColumns(
			new SimpleTableThumbnailMainSecondaryColumn(
				this.translationService.instant('Name'),
				(item) => new ThumbnailTextContent(
					item.profileImageUrl,
					item.fullName,
					item.id,
					item.email,
					true,
					'user'
				),
				this.sanitizer,
				this.viewContainerRef,
				this.translationService.instant('Email'),
				this.modalService
			),
			new SimpleTableTextColumn(
				this.translationService.instant('Role'),
				(item) => new TextContent(
					this.translationService.translateArray(item.getCurrentRoles(this.selectedOrganization.id)).toCommaSeparatedList() as any,
					item.certificates,
					'',
					''
				),
				this.sanitizer,
				this.viewContainerRef,
				this.translationService.instant('CertificateNumber')
			),
			new SimpleTableEventsColumn(
				this.translationService.instant('Events'),
				(item) => [
					new EventsContent(`${this.translationService.instant('Invited')} ${Utils.getFormattedDateStringFromString(item.getCurrentInviteDate(this.selectedOrganization.id), DEFAULT_DISPLAY_DATE_FORMAT)}`, item.getCurrentInviteDate(this.selectedOrganization.id), 'bi-envelope')
				],
				this.sanitizer,
				this.viewContainerRef,
				''
			),
			new SimpleTableStatusColumn(
				this.translationService.instant('Status'),
				(item) => item.getCurrentStatus(this.selectedOrganization.id),
				this.sanitizer,
				this.viewContainerRef,
				''
			)
		);
	}

	protected configureTableActions(config: SimpleTableConfig<User>) {

		// HEADER
		config.setHeaderActions(
			new SimpleTableHeaderAction(
				this.translationService.instant('SetStatus'),
				StringUtils.icons.setStatus,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueBulkSetStatus}_${selectedIds.join()}`);
				}
			),
			new SimpleTableHeaderActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				() => {
					const selectedIds = Array.from(this.selectedIds.keys());
					this.setAsAffected(selectedIds);
					this.wasRowAction = false;
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${selectedIds.join()}`);
				},
			)
		);

		// ROW
		if (!this.isInModal) {
			this.hideTableRowActions = false;
			config.setHideRowActions(this.hideTableRowActions);
		}

		config.setRowActions(
			new SimpleTableRowActionEdit(
				this.translationService.instant('Open'),
				StringUtils.icons.handle,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueEdit}_${row.id}`);
					}
				},
				(row) => {
					return row.data.getCurrentStatus(this.selectedOrganization.id) === 'Pending' || row.data.id === this.loggedInUser.id;
				}
			),
			new SimpleTableRowActionEdit(
				this.translationService.instant('GoToProfile'),
				StringUtils.icons.handle,
				(row) => {
					this.setAsAffected([row.id]);
					const url = `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${RoutesUtils.profile}`;
					window.open(url, '_blank');
				},
				(row) => {
					return row.data.getCurrentStatus(this.selectedOrganization.id) === 'Pending' || row.data.id !== this.loggedInUser.id;
				}
			),
			new SimpleTableRowActionEdit(
				this.translationService.instant('ChangePassword'),
				StringUtils.icons.handle,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueChangePassword}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueChangePassword}_${row.id}`);
					}
				},
				(row) => {
					return !this.loggedInUser.isSuperAdmin() || row.data.isSuperAdmin() || row.data.getCurrentStatus(this.selectedOrganization.id) === 'Pending' || row.data.id === this.loggedInUser.id;
				}
			),
			new SimpleTableRowActionEdit(
				this.translationService.instant('SendVerificationLink'),
				StringUtils.icons.email,
				(row) => {
					this.setAsAffected([row.id]);

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueResendEmail}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueResendEmail}_${row.id}`);
					}
				},
				(row) => {
					return row.data.getCurrentStatus(this.selectedOrganization.id) !== 'Pending' || !this.authService.canEdit();
				}
			),
			new SimpleTableRowAction(
				this.translationService.instant('SetStatus'),
				StringUtils.icons.setStatus,
				(row) => {
					this.setAsAffected([row.id]);
					this.openModalByActionName(`${RoutesUtils.modalPrefixValueSetStatus}_${row.id}`);
				}
			),
			new SimpleTableRowActionDelete(
				this.translationService.instant('Delete'),
				StringUtils.icons.delete,
				(row) => {
					this.setAsAffected([row.id]);
					this.wasRowAction = true;

					if (!this.isInModal) {
						this.setCrudParams(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					} else {
						this.openModalByActionName(`${RoutesUtils.modalPrefixValueDelete}_${row.id}`);
					}
				},
				(row) => {
					if (this.loggedInUser.canEdit(this.selectedOrganization.friendlyUrl)) {
						if (row.data.id === this.loggedInUser.id) {
							return true;
						} else if (this.loggedInUser.isSuperAdmin()) {
							if (row.data.isSuperAdmin()) {
								return true;
							} else {
								return false;
							}
						} else {
							return row.data.isSuperAdmin() || row.data.id === this.loggedInUser.id;
						}
					} else {
						return true;
					}
				}
			)
		);
	}

	protected configureTableEmptyState(config: SimpleTableConfig<User>) {
		config.emptyState = new SimpleTableEmptyState(
			'Users',
			StringUtils.icons.user
		);
	}

	protected setPropertiesAndOpenViewObjectsPopover() { }

	protected openModalByActionName(value: string) {
		const [actionName, idString] = value.split('_');
		this.shouldClearAfterUpdate = !this.isInModal && this.isBulkAction(actionName);
		if (actionName === RoutesUtils.modalPrefixValueNew) {
			this.bsModalRef = this.modalService.show(
				CreateEditUserComponent,
				{
					initialState: {
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueInvite) {
			this.bsModalRef = this.modalService.show(
				CreateEditUserComponent,
				{
					initialState: {
						disableTabIndexUrl: this.isInModal,
						isInvite: true
					},
					...ConfigUtils.MODAL_CONFIG_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueEdit) {
			this.bsModalRef = this.modalService.show(
				CreateEditUserComponent,
				{
					initialState: {
						editModelId: idString,
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueResendEmail) {
			this.bsModalRef = this.modalService.show(
				ResendEmailComponent,
				{
					initialState: {
						editModelId: idString,
						disableTabIndexUrl: this.isInModal
					},
					...ConfigUtils.MODAL_CONFIG_LARGE,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueChangePassword) {
			this.bsModalRef = this.modalService.show(
				PasswordComponent,
				{
					initialState: {
						disableTabIndexUrl: this.isInModal,
						otherUserId: idString
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM,
					...this.closeInterceptorConfig()
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueBulkSetStatus || actionName === RoutesUtils.modalPrefixValueSetStatus) {
			this.bsModalRef = this.modalService.show(
				SetStatusUserComponent,
				{
					initialState: {
						editModelIds: idString.split(','),
						useAffectableIds: true
					},
					...ConfigUtils.MODAL_CONFIG_MEDIUM
				}
			);
		} else if (actionName === RoutesUtils.modalPrefixValueDelete) {
			this.bsModalRef = this.modalService.show(
				DeleteUsersComponent,
				{
					initialState: {
						deleteModelIds: idString.split(','),
						useAffectableIds: !this.wasRowAction,
						hideText: this.wasRowAction
					},
					...ConfigUtils.MODAL_CONFIG_SMALL
				}
			);
		}
		this.subscribeToCrudModalContent();
	}

	protected import(file: File) { }
	protected export(selectedIds: string[]) { }
}
