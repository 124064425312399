import { Pipe, PipeTransform } from '@angular/core';
import { LoggedInUser } from 'app-core/user/user';

@Pipe({
	name: 'canEdit'
})
export class CanEditPipe implements PipeTransform {
	transform(loggedInUser: LoggedInUser, friendlyUrl: string): any {
		return (
			(!!loggedInUser && !!friendlyUrl && loggedInUser.canEdit(friendlyUrl))
			|| loggedInUser.isSuperAdmin()
		);
	}
}
