import {
	Component, Input
} from '@angular/core';

@Component({
	selector: 'simple-delete',
	templateUrl: './simple-delete.component.html',
	styleUrls: ['./simple-delete.component.less']
})
export class SimpleDeleteComponent {
	@Input() selectedIdsCount: number;
	@Input() affectableIdsCount: number;
	@Input() hideText: boolean;
}
