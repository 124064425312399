import { Injectable } from '@angular/core';
import { Entity, EntityPayload } from 'app-inspection/entity/entity';
import { Facility } from 'app-inspection/facility/facility';
import { Task } from 'app-inspection/task/task';
import { indexOf } from 'lodash';
import proj4 from 'proj4';
import { KeyValuePair } from '../filter';
import { StringUtils } from '../tools/string-utils';
import { Utils } from '../tools/utils';

const CONTEXTS = {
	entity: [
		new KeyValuePair('1', Utils.nameof<Entity>('name').toString()),
		new KeyValuePair('2', Utils.nameof<Entity>('description').toString()),
		new KeyValuePair('3', Utils.nameof<Entity>('status').toString()),
		new KeyValuePair('4', Utils.nameof<EntityPayload>('facilityId').toString()), // TODO alltid payloadvarianten här?
		new KeyValuePair('5', Utils.nameof<Entity>('latitude').toString()),
		new KeyValuePair('6', Utils.nameof<Entity>('longitude').toString()),
		new KeyValuePair('7', Utils.nameof<Entity>('assembler').toString()),
		new KeyValuePair('8', Utils.nameof<Entity>('assemblyYear').toString()),
		new KeyValuePair('9', Utils.nameof<Entity>('typeDesignation').toString()),
		new KeyValuePair('10', Utils.nameof<Entity>('manufacturer').toString()),
		new KeyValuePair('11', Utils.nameof<Entity>('articleId').toString()),
		new KeyValuePair('12', Utils.nameof<Entity>('externalId').toString()),
		new KeyValuePair('13', Utils.nameof<Entity>('infoLink').toString()),
		new KeyValuePair('14', Utils.nameof<Entity>('accessible').toString()),
		new KeyValuePair('15', Utils.nameof<Entity>('extraInfo').toString()),
	],
	facility: [
		new KeyValuePair('1', Utils.nameof<Facility>('name').toString()),
		new KeyValuePair('2', Utils.nameof<Facility>('description').toString()),
		new KeyValuePair('3', Utils.nameof<Facility>('status').toString()),
		new KeyValuePair('4', Utils.nameof<Facility>('latitude').toString()),
		new KeyValuePair('5', Utils.nameof<Facility>('longitude').toString()),
		new KeyValuePair('6', Utils.nameof<Facility>('radius').toString()),
		new KeyValuePair('7', Utils.nameof<Facility>('streetName').toString()),
		new KeyValuePair('8', Utils.nameof<Facility>('streetNumber').toString()),
		new KeyValuePair('9', Utils.nameof<Facility>('city').toString()),
		new KeyValuePair('10', Utils.nameof<Facility>('zipCode').toString()),
		new KeyValuePair('11', Utils.nameof<Facility>('externalId').toString()),
		new KeyValuePair('12', Utils.nameof<Facility>('infoLink').toString()),
		new KeyValuePair('13', Utils.nameof<Facility>('accessible').toString()),
		new KeyValuePair('14', Utils.nameof<Facility>('extraInfo').toString()),
	],
	task: [
		new KeyValuePair('1', Utils.nameof<Task>('text').toString()),
		new KeyValuePair('2', Utils.nameof<Task>('code').toString()),
		new KeyValuePair('3', Utils.nameof<Task>('isRequired').toString()),
		new KeyValuePair('4', Utils.nameof<Task>('allowComment').toString()),
		new KeyValuePair('5', Utils.nameof<Task>('allowMedia').toString()),
		new KeyValuePair('6', Utils.nameof<Task>('isDefault').toString()),
		new KeyValuePair('7', 'type'),
		new KeyValuePair('8', Utils.nameof<Task>('description').toString()),
		// Utils.nameof<Task>('showPreviousAnswer').toString(),
		// Utils.nameof<Task>('organizationId').toString(),
		// Utils.nameof<Task>('type').toString(), This one is still needed? TODO
	]
};

// Import/export model keys that can be used.
export enum ImportExportModelKey {
	Facility = 'facility',
	Entity = 'entity',
	Task = 'task'
}

// Import action keys that can be used.
export enum ImportActionKey {
	ImportFacilities = 'ImportFacilities',
	ImportEntities = 'ImportEntities',
	ImportTasks = 'ImportTasks'
}

// Import job status keys that can be used.
export enum ImportJobStatusKey {
	InProgress = 'InProgress',
	Succeeded = 'Succeeded',
	Error = 'Error'
}

@Injectable()
export class ImportHelper {

	private propertyToIgnore: string;
	private context: KeyValuePair[];
	private headers: KeyValuePair[];
	private rows: string[][];

	generateData(workbookData: any[], modelName: ImportExportModelKey, coordinateSystem?: string, propertyToIgnore?: string) {

		const allConvertedObjects = [];

		// Assign optional parameter.
		this.propertyToIgnore = propertyToIgnore;

		// Decide which properties to work with.
		this.context = CONTEXTS[modelName].map(item => new KeyValuePair(item.key, item.value.toLowerCase()));
		const workSheet = workbookData[0];

		// Process one sheet at a time.
		// workbookData.forEach(workSheet => {

		// Separate headers and rows.
		this.headers = workSheet[0].map((item: any) => new KeyValuePair(indexOf(workSheet[0], item).toString(), item.toLowerCase()));
		this.rows = workSheet.slice(1);

		// Prepare for conversion.
		this.discardExtraColumns(modelName);
		this.establishOrder();

		if (this.isValidHeaders()) {

			// Convert rows and apply to the initial array.
			const convertedObjects = this.convertRowsToObjects(coordinateSystem);
			allConvertedObjects.push.apply(allConvertedObjects, convertedObjects);
		} else {
			throw StringUtils.INVALID_HEADERS;
		}
		// });

		return allConvertedObjects;
	}

	// Check if all context properties are included in the headers.
	private isValidHeaders() {
		return this.context.every(property => {
			return this.headers.map(header => header.key).includes(property.key);
		});
	}

	// Remove header and row value of all extra columns not specified in the context.
	private discardExtraColumns(modelName: ImportExportModelKey) {
		let extraHeaders = [];
		if (modelName === ImportExportModelKey.Facility) {
			extraHeaders = extraHeaders.concat(this.headers.slice(4, 5));	// Remove Updated
			extraHeaders = extraHeaders.concat(this.headers.slice(3, 4));	// Remove Created
			extraHeaders = extraHeaders.concat(this.headers.slice(0, 1));	// Remove Id
		} else if (modelName === ImportExportModelKey.Entity) {
			extraHeaders = extraHeaders.concat(this.headers.slice(4, 5));	// Remove Updated
			extraHeaders = extraHeaders.concat(this.headers.slice(3, 4));	// Remove Created
			extraHeaders = extraHeaders.concat(this.headers.slice(2, 3));	// Remove Facility.Name
		} else if (modelName === ImportExportModelKey.Task) {
			extraHeaders = extraHeaders.concat(this.headers.slice(7, 8));	// Remove OrganizationId
			extraHeaders = extraHeaders.concat(this.headers.slice(5, 6));	// Remove ShowPreviousAnswer
		}

		extraHeaders.forEach(header => {
			this.headers.splice(header.key, 1);
			this.rows.map(row => row.splice(header.key, 1));
		});

		for (let i = 0; i < this.headers.length; i++) {
			this.headers[i].key = (i + 1).toString();
		}
	}

	private cleanupEmptyValue(value: string) {
		if (new RegExp('^\s+$').test(value)) {
			return "";
		}
		return value;
	}

	// Make sure the context properties are in the same order as the headers.
	private establishOrder() {
		this.context.sort((a, b) => {
			const aIndex = parseInt(this.headers.find(header => header.key === a.key)?.key);
			const bIndex = parseInt(this.headers.find(header => header.key === b.key)?.key);
			return aIndex - bIndex;
		});
	}

	// Get row data and convert into objects.
	private convertRowsToObjects(coordinatSystem: string) {
		const objects = [];
		this.rows.filter(row => row.length)
			.forEach(row => {
				const newObject = {};
				row.forEach((value, index) => {
					const propertyName = this.context[index];

					// Ignore or set value.
					const shouldIgnore = !!this.propertyToIgnore && this.propertyToIgnore.toLowerCase() === propertyName.value;
					if (!shouldIgnore && value) {
						newObject[propertyName.value] = this.cleanupEmptyValue(value);
					}
				});

				// Only use non-empty objects.
				if (Object.keys(newObject).length) {
					if (!!coordinatSystem) {
						try {
							const wgs84Projection = '+proj=longlat +datum=WGS84 +no_defs';
							const x = newObject['longitude'];
							const y = newObject['latitude'];
							if (!!y && !!x) {
								const result = proj4(coordinatSystem, wgs84Projection, [x, y]);
								[newObject['longitude'], newObject['latitude']] = result;
							}
						} catch (e) {
							console.log(e);
						}
					}
					objects.push(newObject);
				}
			});
		return objects;
	}
}
