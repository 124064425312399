<div class="delete-wrapper">
	<div class="delete-question">
		{{ 'DeleteQuestion' | translate }}?
	</div>
	<div class="delete-statement">
		{{ 'DeleteStatement' | translate }}!
	</div>
	<ng-content select="[extraContent]"></ng-content>
	<div class="wrapper" *ngIf="!hideText && affectableIdsCount !== undefined">
		<simple-affectable-count-message
			[affectableIdsCount]="affectableIdsCount"
			[selectedIdsCount]="selectedIdsCount">
		</simple-affectable-count-message>
	</div>
</div>
