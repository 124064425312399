import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Choice } from 'app-inspection/choice/choice';
import { ListChoiceComponent } from 'app-inspection/choice/list-choice/list-choice.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'choices',
	templateUrl: './choices.component.html',
	styleUrls: ['./choices.component.less']
})
export class ChoicesComponent extends SimpleHandleTabDirective<Choice> {

	@Input() hideChoices: boolean;
	@Input() disableAction: boolean;
	@Input() actionTitle: string;
	@Input() useButtonAction: boolean;
	@Input() hideRules: boolean;
	@Input() hideAddButton: boolean;
	@Input() useSingleSelectionInList: boolean;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected cdRef: ChangeDetectorRef) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService,
			cdRef
		);
	}

	triggerViewChanges() {
		this.cdRef.detectChanges();
	}

	toggleListChoiceModal() {
		this.bsModalRef = this.modalService.show(
			ListChoiceComponent,
			{
				initialState: {
					isInModal: true,
					idsToSetAsSelected: this.handleItems.map(item => item.id),
					isSingleSelectTable: this.useSingleSelectionInList
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	itemListIsValid() {
		return true;
	}

	isMediumScreenSize() {
		return Utils.isMediumScreenSize();
	}

	override emitModelsChange() {
		this.handleItems.sortByProperty(this.propertyStrings.value);
		super.emitModelsChange();
	}

	protected validate() { }

	getItems() {
		return this.handleItems;
	}
}
