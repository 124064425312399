import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCrudDirective } from 'app-core/shared-core/simple-components/crud/simple-crud.directive';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ToastrService } from 'ngx-toastr';
import { KeyValuePair } from '../../../../filter';
import { NumberUtils } from '../../../../tools/number-utils';
import { SimpleListAction } from '../../actions/simple-list-action';
import { SimpleTableConfig } from '../simple-table-config';
import { SimpleFilterInput, SimpleFilterInputType } from './input-settings/simple-filter-input-settings';
import { SimpleFilterConfig, SortObject } from './simple-filter-config';

@Component({
	selector: 'simple-filter',
	templateUrl: './simple-filter.component.html',
	styleUrls: ['./simple-filter.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleFilterComponent<T extends { id: string }> extends SimpleCrudDirective<T> {

	searchOpened: boolean = false;
	filterOpened: boolean = false;
	sortOpened: boolean = false;

	@Input() listActions: SimpleListAction[];
	@Input() hideListActions: boolean;
	@Input() tableConfig: SimpleTableConfig<any>;
	@Input() filterConfig: SimpleFilterConfig<any>;
	@Input() activeSearch: string;
	@Input() activeFilter: SimpleFilterInput[];
	@Input() totalCount: number;
	@Input() selectedCount: number;
	@Input() sortProperty: string;
	@Input() sortLabel: string;
	@Input() sortReversed: boolean;
	@Input() isInModal: boolean;
	@Input() disableSearchFilterSort: boolean;

	@Output() onListActionClick = new EventEmitter<SimpleListAction>();
	@Output() onSearch = new EventEmitter<string>();
	@Output() onSort = new EventEmitter<SortObject<T>>();
	@Output() onFilter = new EventEmitter<KeyValuePair[]>();
	@Output() onSearchClear = new EventEmitter();
	@Output() onFilterClear = new EventEmitter();
	@Output() onSelectedClear = new EventEmitter();
	@Output() onFilterOpened = new EventEmitter<boolean>();

	@ViewChildren('searchPop') popoverSearch: PopoverDirective[];
	@ViewChildren('filterPop') popoverFilter: PopoverDirective[];
	@ViewChildren('sortPop') popoverSort: PopoverDirective[];

	@HostListener('document:mousedown', ['$event']) onGlobalClick(event: any): void {
		const datepickerElements = document.getElementsByClassName('bs-datepicker');

		if ((datepickerElements[0] && !this.elementRef.nativeElement.contains(datepickerElements[0]))) {
			return;
		}
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.filterOpened = false;
		}
	}


	constructor(
		private elementRef: ElementRef,
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService
	) {
		super(authService, modalService, router, toastrService, translationService);
	}

	// List actions

	handleListActionClick(action: SimpleListAction) {
		this.onListActionClick.emit(action);
	}

	// Search

	handleSearch(searchValue: string) {
		this.emitOnSearchWithDebounce(searchValue);
	}

	private emitOnSearchWithDebounce = Utils.debounce((searchValue: string) => this.onSearch.emit(searchValue), NumberUtils.DEFAULT_DEBOUNCE_TIME);

	handleClearSearch() {
		this.onSearchClear.emit();
		this.refresh();
	}

	getActiveSearchAsText() {
		return `${this.translationService.instant('Search').toUpperCase()}: ${this.activeSearch}`;
	}

	focusInput() {
		const searchInput = document.querySelector('.search-input') as HTMLElement;
		if (searchInput) {
			searchInput.focus();
		}
	}

	// Filter

	handleFilter(filterInput: SimpleFilterInput, emitDirectly?: boolean) {
		const values = filterInput.selectedItems.map(item => item.id).join();
		const keyValuePairs = filterInput.transformValues(values);
		if (emitDirectly) {
			this.onFilter.emit(keyValuePairs);
		} else {
			this.emitOnFilterWithDebounce(keyValuePairs);
		}
	}
	private emitOnFilterWithDebounce = Utils.debounce((keyValuePairs: KeyValuePair[]) => this.onFilter.emit(keyValuePairs), NumberUtils.DEFAULT_DEBOUNCE_TIME);


	toggleFilterOpened() {
		this.filterOpened = !this.filterOpened;
		this.onFilterOpened.emit(this.filterOpened);
	}

	handleClearFilter() {
		this.filterConfig.filterInputs.forEach(filterInput => {
			if (filterInput.type === SimpleFilterInputType.DateSelect) {
				filterInput.dateValue = null;
			} else if (filterInput.type === SimpleFilterInputType.DateRangeSelect) {
				filterInput.dateRangeValue = [];
			}
		});
		this.filterOpened = false;
		this.onFilterClear.emit();
		this.refresh();
	}

	getActiveFilterAsText() {
		return this.activeFilter.map(filterInput => {
			return `${filterInput.label.toUpperCase()}: ${filterInput.selectedItems.map(item => item.name).join(', ')}`;
		}).join('\n\n');
	}

	getActiveFilterSingleAsText(filterInput: SimpleFilterInput) {
		const extra = filterInput.selectedItems.length > 1 ? `(${filterInput.selectedItems.length}st)` : '';
		return `${filterInput.label.toUpperCase()}${extra}: ${filterInput.selectedItems.map(item => item.name).join(', ')}`;
	}

	handleClearFilterSingle(filterInput: SimpleFilterInput) {
		filterInput.selectedItems = [];
		if (filterInput.type === SimpleFilterInputType.DateSelect) {
			filterInput.dateValue = null;
		} else if (filterInput.type === SimpleFilterInputType.DateRangeSelect) {
			filterInput.dateRangeValue = [];
		}
		this.handleFilter(filterInput, true);

		this.refresh();
	}

	// Sort

	handleSort(sortObject: SortObject<any>) {
		this.onSort.emit(sortObject)
	}

	getSortedText() {
		return this.sortReversed ? 'SortedAscendingOn' : 'SortedDescendingOn';
	}

	getSortText(sortProperty: keyof any) {
		return sortProperty === this.sortProperty && !this.sortReversed ?
			'SortAscendingOn'
			: 'SortDescendingOn';
	}

	// Selected rows

	handleSelectedClear() {
		this.onSelectedClear.emit();
	}

	// Various

	closeAllPopovers() {
		this.closePopovers(this.popoverSearch);
		this.closePopovers(this.popoverFilter);
		this.closePopovers(this.popoverSort);
	}

	closeOtherPopovers(clickedPopover: PopoverDirective) {
		this.closePopovers(this.getOtherPopovers(this.popoverSearch, clickedPopover));
		this.closePopovers(this.getOtherPopovers(this.popoverFilter, clickedPopover));
		this.closePopovers(this.getOtherPopovers(this.popoverSort, clickedPopover));
	}

	private getOtherPopovers(popovers: PopoverDirective[], clickedPopover: PopoverDirective) {
		return popovers.filter(popover => popover.popoverId !== clickedPopover.popoverId)
	}

	private closePopovers(popovers: PopoverDirective[]) {
		popovers.forEach(popover => popover.hide());
	}

	private refresh() {
		this.filterConfig.filterInputs = [...this.filterConfig.filterInputs];
	}
}
