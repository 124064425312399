import { ClipboardModule } from '@angular/cdk/clipboard';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { FeedbackModule } from 'app-core/feedback/feedback.module';
import { ExtensiveMediaModule } from 'app-core/media/extensive-image/extensive-media.module';
import { HelpModule } from 'app-core/user-support/help/help.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DirectivesModule } from '../directives/directives.module';
import { DotsLoaderModule } from '../dots-loader/dots-loader.module';
import { MarkdownEditorModule } from '../markdown-editor/markdown-editor.module';
import { PipesModule } from '../pipes/pipes.module';
import { SimpleAffectableCountMessageComponent } from './crud/modal/affectable-count/simple-affectable-count-message.component';
import { SimpleCrudModalComponent } from './crud/modal/simple-crud-modal.component';
import { SimpleChangeBulkTabComponent } from './crud/modal/tabs/change/bulk/simple-change-bulk-tab.component';
import { SimpleFormCheckboxComponent } from './crud/modal/tabs/change/form/form-checkbox/simple-form-checkbox.component';
import { SimpleFormColorPickerComponent } from './crud/modal/tabs/change/form/form-color-picker/simple-form-color-picker.component';
import { SimpleFormDatePickerComponent } from './crud/modal/tabs/change/form/form-date-picker/simple-form-date-picker.component';
import { SimpleFormErrorComponent } from './crud/modal/tabs/change/form/form-error/simple-form-error.component';
import { SimpleFormInfoComponent } from './crud/modal/tabs/change/form/form-info/simple-form-info.component';
import { SimpleFormInputComponent } from './crud/modal/tabs/change/form/form-input/simple-form-input.component';
import { SimpleFormSelectComponent } from './crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleFormTimeInputComponent } from './crud/modal/tabs/change/form/form-time-input/simple-form-time-input.component';
import { SimpleChangeTabComponent } from './crud/modal/tabs/change/simple-change-tab.component';
import { SimpleItemExtendedComponent } from './crud/modal/tabs/handle/item/extended/simple-item-extended.component';
import { SimpleItemComponent } from './crud/modal/tabs/handle/item/simple-item.component';
import { SimpleNoItemsComponent } from './crud/modal/tabs/handle/no-items/simple-no-items.component';
import { SimpleHandleTabComponent } from './crud/modal/tabs/handle/simple-handle-tab.component';
import { SimpleHelpComponent } from './crud/modal/tabs/tab-heading/help/simple-help.component';
import { SimpleTabHeadingComponent } from './crud/modal/tabs/tab-heading/simple-tab-heading.component';
import { SimpleViewItemsTabComponent } from './crud/modal/tabs/view-items/simple-view-items-tab.component';
import { SimpleViewTabComponent } from './crud/modal/tabs/view/simple-view-tab.component';
import { SimpleListModalComponent } from './list/modal/simple-list-modal.component';
import { SimpleTableBodyComponent } from './list/table/body/simple-table-body.component';
import { SimpleEmptyStateComponent } from './list/table/empty-state/simple-empty-state.component';
import { SimpleFilterComponent } from './list/table/filter/simple-filter.component';
import { SimpleFilterSubComponent } from './list/table/filter/sub/simple-filter-sub.component';
import { SimpleSearchSubComponent } from './list/table/filter/sub/simple-search-sub.component';
import { SimpleTableHeadComponent } from './list/table/head/simple-table-head.component';
import { SimpleTableComponent } from './list/table/simple-table.component';
import { SimpleCategoriesComponent } from './various/categories/simple-categories.component';
import { SimpleChangeImageComponent } from './various/change-image/simple-change-image.component';
import { SimpleDeleteComponent } from './various/delete/simple-delete.component';
import { SimpleLinkButtonComponent } from './various/link-button/simple-link-button.component';
import { SimpleRulesComponent } from './various/rules/simple-rules.component';
import { SimpleStatusLabelComponent } from './various/status-label/simple-status-label.component';
import { SimpleTextContentComponent } from './various/text-content/simple-text-content.component';
import { SimpleViewConnectionsComponent } from './various/view-connections/simple-view-connections.component';

@NgModule({
	imports: [
		CommonModule,
		ScrollingModule,
		ModalModule,
		PopoverModule,
		TranslateModule,
		ClipboardModule,
		DotsLoaderModule,
		NgMultiSelectDropDownModule,
		BsDatepickerModule,
		PipesModule,
		DirectivesModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		ColorPickerModule,
		ImageCropperModule,
		ExtensiveMediaModule,
		HelpModule,
		FeedbackModule,
		MarkdownEditorModule
	],
	declarations: [
		SimpleListModalComponent,
		SimpleFilterComponent,
		SimpleFilterSubComponent,
		SimpleSearchSubComponent,
		SimpleTableComponent,
		SimpleTableHeadComponent,
		SimpleTableBodyComponent,
		SimpleCrudModalComponent,
		SimpleAffectableCountMessageComponent,
		SimpleFormErrorComponent,
		SimpleFormInputComponent,
		SimpleFormCheckboxComponent,
		SimpleFormSelectComponent,
		SimpleFormDatePickerComponent,
		SimpleChangeTabComponent,
		SimpleChangeBulkTabComponent,
		SimpleFormInfoComponent,
		SimpleItemComponent,
		SimpleItemExtendedComponent,
		SimpleHelpComponent,
		SimpleTabHeadingComponent,
		SimpleNoItemsComponent,
		SimpleFormTimeInputComponent,
		SimpleFormColorPickerComponent,
		SimpleViewTabComponent,
		SimpleViewItemsTabComponent,
		SimpleHandleTabComponent,
		SimpleStatusLabelComponent,
		SimpleRulesComponent,
		SimpleCategoriesComponent,
		SimpleLinkButtonComponent,
		SimpleDeleteComponent,
		SimpleChangeImageComponent,
		SimpleEmptyStateComponent,
		SimpleViewConnectionsComponent,
		SimpleTextContentComponent
	],
	exports: [
		SimpleListModalComponent,
		SimpleFilterComponent,
		SimpleFilterSubComponent,
		SimpleSearchSubComponent,
		SimpleTableComponent,
		SimpleCrudModalComponent,
		SimpleAffectableCountMessageComponent,
		SimpleFormErrorComponent,
		SimpleFormInputComponent,
		SimpleFormCheckboxComponent,
		SimpleFormSelectComponent,
		SimpleFormDatePickerComponent,
		SimpleChangeTabComponent,
		SimpleChangeBulkTabComponent,
		SimpleFormInfoComponent,
		SimpleItemComponent,
		SimpleItemExtendedComponent,
		SimpleHelpComponent,
		SimpleTabHeadingComponent,
		SimpleNoItemsComponent,
		SimpleFormTimeInputComponent,
		SimpleFormColorPickerComponent,
		SimpleViewTabComponent,
		SimpleViewItemsTabComponent,
		SimpleHandleTabComponent,
		SimpleStatusLabelComponent,
		SimpleCategoriesComponent,
		SimpleRulesComponent,
		SimpleLinkButtonComponent,
		SimpleDeleteComponent,
		SimpleChangeImageComponent,
		SimpleEmptyStateComponent,
		SimpleViewConnectionsComponent,
		SimpleTextContentComponent
	]
})
export class SimpleComponentsModule { }
