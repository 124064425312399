import { Directive, OnDestroy } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserSupportFile } from './user-support';
import { UserSupportService } from './user-support.service';

@Directive()
export abstract class UserSupportDirective implements OnDestroy {

	file: UserSupportFile;
	fetching: boolean = false;

	popoverOpened: boolean = false;

	protected subscriptions = new Subscription();
	protected destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

	constructor(
		protected userSupportService: UserSupportService) { }

	async initiateFileSubscription() {
		await this.userSupportService.initiateFetch();
		this.subscriptions.add(
			this.userSupportService.file
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(file => {
					if (file) {
						this.fileWasFetched(file);
					}
				})
		);

		this.subscriptions.add(
			this.userSupportService.fetching
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(fetching => {
					this.fetching = fetching;
				})
		);
	}

	private fileWasFetched(file: UserSupportFile) {
		this.file = file;
		this.mapData();
	}

	protected abstract mapData(): void;

	trackByFn(index: number) {
		return index;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.destroyed$.complete();
	}
}
