import {
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { MediaWidgetItem } from './media-widget-item';

@Component({
	selector: 'media-widget-item',
	templateUrl: 'media-widget-item.component.html',
	styleUrls: ['media-widget-item.component.less']
})
export class MediaWidgetItemComponent {

	@Input() item: MediaWidgetItem;
	@Input() canToggleDefault: boolean;
	@Input() viewOnly: boolean;
	@Input() itemSize: number;

	@Output() onOpen = new EventEmitter<MediaWidgetItem>();
	@Output() onToggleDefault = new EventEmitter<MediaWidgetItem>();
	@Output() onDownload = new EventEmitter<MediaWidgetItem>();
	@Output() onRemove = new EventEmitter<MediaWidgetItem>();

	open() {
		this.onOpen.emit(this.item);
	}

	setAsDefault() {
		this.onToggleDefault.emit(this.item);
	}

	download() {
		this.onDownload.emit(this.item);
	}

	remove() {
		this.onRemove.emit(this.item);
	}
}
