import { Injectable } from '@angular/core';
import { StringUtils } from './string-utils';


// A vendor file: loaded to the window object as part of Angular CLI build.
declare const XLSX: any;
declare const XLS: any;

/**
 * A service that parses XLSX files
 */
@Injectable()
export class ParseXLSXService {

    /**
     * Parses an XSLS file using js-xlsx library.
     * https://github.com/SheetJS/js-xlsx
     *
     * @param file A file coming from an HTML input field
     * @returns Returns a promise which, when resolved, returns an array of rows from each sheet in the file.
     */
	public parseFile(file: File): Promise<any> {
		return readExcelFile(file);
	}
}


// Taken from:
// https://github.com/SheetJS/SheetJS.github.io/blob/master/assets/js/dropsheet.js
const rABS = typeof FileReader !== 'undefined' &&
	typeof FileReader.prototype !== 'undefined' &&
	typeof FileReader.prototype.readAsBinaryString !== 'undefined';

const readtype = { type: rABS ? 'binary' : 'base64' };

function readExcelFile(file: File) {

	function fixdata(data: ArrayBuffer) {
		let o = '';
		let l = 0;
		const w = 10240;
		for (; l < data.byteLength / w; ++l) {
			o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
		}
		o += String.fromCharCode.apply(null, new Uint8Array(data.slice(o.length)));
		return o;
	}

	function processWorkbook(workbook: any, XL: any) {
		const workbookData = new Array();

		workbook.SheetNames.forEach((sheetName: string) => {
			const sheet = workbook.Sheets[sheetName];

			// (header: 1) means 'arrays in array' format.
			// (defval: '') - means we want an empty string when no value.
			const jsonArray = XL.utils.sheet_to_json(sheet, { header: 1, defval: '' });

			if (jsonArray.length) {
				workbookData.push(jsonArray);
			}
		});

		return workbookData;
	}

	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		const name = file.name;

		reader.onload = (e) => {
			let data = reader.result as any;
			let result: Array<any>;
			let dataString: string;

			if (!rABS) {
				dataString = fixdata(data);
				data = btoa(dataString);
			}

			let xls = [0xd0, 0x3c].indexOf(data.charCodeAt(0)) > -1;

			if (!xls && dataString) {
				xls = [0xd0, 0x3c].indexOf(dataString[0].charCodeAt(0)) > -1;
			}
			if (rABS && !xls && data.charCodeAt(0) !== 0x50) {
				reject(StringUtils.BAD_FILE_FORMAT);
			}

			try {
				if (xls) {
					const wb = XLS.read(data, readtype);
					result = processWorkbook(wb, XLS);
				} else {
					const wb = XLSX.read(data, readtype);
					result = processWorkbook(wb, XLSX);
				}

				resolve(result);
			} catch (ex) {
				reject(StringUtils.COULD_NOT_READ_THE_FILE);
			}
		};

		reader.onerror = (event) => {
			reject(StringUtils.COULD_NOT_READ_THE_FILE);
		};

		if (rABS) {
			reader.readAsBinaryString(file);
		} else {
			reader.readAsArrayBuffer(file);
		}
	});
}

