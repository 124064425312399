import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleChangeBulkTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/bulk/simple-change-bulk-tab.directive';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { RegexUtils } from 'app-core/shared-core/tools/regex-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { ListUserComponent } from 'app-core/user/list-user/list-user.component';
import { Subscriber, User } from 'app-core/user/user';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { StaticReport } from '../static-report';

@Component({
	selector: 'static-report-tab',
	templateUrl: './static-report-tab.component.html',
	styleUrls: ['../../report-shared-style.less']
})
export class StaticReportTabComponent extends SimpleChangeBulkTabDirective<StaticReport> implements OnInit {

	selectableEmailItems: FormSelectItem[] = [];

	bsModalRef: BsModalRef<any>;

	@Input() subscribers: Subscriber[];
	@Input() tooLargeForDownload: boolean;
	@Input() sendReportByEmail: boolean;
	@Input() hideText: boolean;

	@Output() onSendReportByEmailToggle = new EventEmitter();

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableEmailItems();

		this.form = this.formBuilder.group({
			['sendReportByEmail']: [this.sendReportByEmail,
			Validators.required
			],
			[this.propertyStrings.emails]: [this.changeModel.emails,
			this.emailsValidator
			]
		});
		super.ngOnInit();
	}

	private emailsValidator(control: AbstractControl) {
		if (control.value && control.value.some((value: any) => !new RegExp(RegexUtils.EMAIL).test(value))) {
			return { emails: true };
		} else {
			return null;
		}
	}

	setSelectableEmailItems() {
		this.selectableEmailItems = this.changeModel.selectableEmails.map(email => new FormSelectItem(email, email));
	}

	handleSendReportByEmailClick() {
		this.onSendReportByEmailToggle.emit();
	}

	handleAddUsersClick() {
		this.bsModalRef = this.modalService.show(
			ListUserComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: true,
					hideTableHeaderActions: true,
					hideTableRowActions: true,
					idsToSetAsSelected: this.changeModel.users.map(item => item.id)
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);

		const listComponent = this.bsModalRef.content;
		this.subscriptions.add(
			listComponent.confirmed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe((confirmedItems: User[]) => {
					if (confirmedItems) {
						this.changeModel.users = confirmedItems;
						this.closeModal();
					}
				})
		);
		this.subscriptions.add(
			listComponent.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(wasClosed => {
					if (wasClosed) {
						this.closeModal();
					}
				})
		);
	}

	closeModal() {
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
	}

	removeUser(userToRemove: User) {
		this.changeModel.users = this.changeModel.users.filter(user => user.id !== userToRemove.id);
	}

	handleAddNewItemInSelect(item: FormSelectItem) {
		this.changeModel.selectableEmails.push(item.name);
	}
}
