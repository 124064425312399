import { Directive, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { Organization } from 'app-core/organization/organization';
import { BackendResponse } from 'app-core/shared-core/abstract-components/service/base.service';
import { SwalConfig } from 'app-core/shared-core/swal/swal-config.component';
import { PropertyNameGetter } from 'app-core/shared-core/tools/property-name-getter';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { LoggedInUser } from 'app-core/user/user';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Directive()
export abstract class SimpleCrudDirective<T extends { id: string }> implements OnDestroy {

	bsModalRef: BsModalRef<SimpleCrudDirective<T>>;
	currentCrudModalComponent: SimpleCrudDirective<T>;

	pending: boolean = false;

	created$ = new BehaviorSubject<T[]>([]);
	updated$ = new BehaviorSubject<T[]>([]);
	deleted$ = new BehaviorSubject<string[]>([]);
	confirmed$ = new BehaviorSubject<T[]>(null);
	closed$ = new BehaviorSubject<boolean>(false);
	handled$ = new BehaviorSubject<boolean>(false);

	loggedInUser: LoggedInUser;
	selectedOrganization: Organization;

	disableTabIndexUrl: boolean = false;

	stringUtils = StringUtils;
	utils = Utils;

	protected subscriptions = new Subscription();
	protected destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

	@ViewChild(TabsetComponent) tabsetComponent: TabsetComponent;

	get propertyStrings() {
		return PropertyNameGetter.propertiesOf({} as new (args?: any) => T);
	}

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService) {
		this.loggedInUser = this.authService.loggedInUser;
		this.selectedOrganization = this.authService.selectedOrganization;
	}

	close() {
		if (!this.closed$.value) {
			this.closed$.next(true);
		}
	}

	closeWithDelay() {
		setTimeout(() => {
			this.close();
		}, 500);
	}

	setTabIndexInUrl(index: number) {
		if (!this.disableTabIndexUrl) {
			this.router.navigate([], {
				queryParams: {
					[RoutesUtils.modalTabParam]: index
				},
				queryParamsHandling: 'merge',
				replaceUrl: true
			});
		}
	}

	activateTabFromUrl() {
		if (!this.disableTabIndexUrl) {
			const index = this.router.routerState.snapshot.root.queryParams[RoutesUtils.modalTabParam];
			if (index && index > 0) {
				const tab = this.tabsetComponent.tabs[index - 1];
				if (tab) {
					tab.active = true;
				}
			}
		}
	}

	goToTab(index: number) {
		this.setTabIndexInUrl(index);
		setTimeout(() => {
			this.activateTabFromUrl();
		}, 0);
	}

	protected displaySuccessMessage(message: string) {
		this.toastrService.success(message);
	}

	protected displayErrorMessage(message: string) {
		this.toastrService.error(message);
	}

	handleErrorResponse(response: BackendResponse) {
		if (response.errorMessage) {
			this.displayErrorMessage(response.errorMessage);
		} else {
			if (typeof response === 'string') {
				this.displayErrorMessage(response);
			} else {
				if (this.loggedInUser.isSuperAdmin()) {
					console.error('For developers: ', response);
				}
				this.displayErrorMessage(this.translationService.instant(StringUtils.ERROR_REQUEST_FAILED));
			}
		}
	}

	protected getUniqueVariant(data: T) {
		return JSON.parse(JSON.stringify(data));
	}

	protected displayUnsavedChangesSwal() {
		return Swal.fire(new SwalConfig(this.translationService).getUnsavedChanges({
			confirmButtonText: this.translationService.instant('IUnderstand'),
			cancelButtonText: this.translationService.instant('Cancel')
		}));
	}

	hasUnsavedChanges() {
		return this.currentCrudModalComponent?.hasUnsavedChanges();
	}

	trackByFn(index, item) {
		return item.id;
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
		this.subscriptions.unsubscribe();
		this.created$.complete();
		this.updated$.complete();
		this.deleted$.complete();
		this.confirmed$.complete();
		this.closed$.complete();
		this.handled$.complete();
	}
}
