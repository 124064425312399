import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { SortDirection } from 'app-core/shared-core/tools/extensions';
import { LogLevel, Utils } from 'app-core/shared-core/tools/utils';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
	DateTypes,
	NewDataNotification,
	ReferenceStatisticsData,
	ReferenceTypes,
	SortOptions,
	SpanTypes,
	Statistics,
	StatisticsAdvancedView,
	StatisticsAssignments,
	StatisticsByDataReloadData,
	StatisticsByDateAndTypePayload,
	StatisticsByDateData,
	StatisticsByDateDataRenderConfig,
	StatisticsDataByReferenceType,
	StatisticsDataBySpanType,
	StatisticsDataByStatisticsType,
	StatisticsOrganizations,
	StatisticsPayload,
	StatisticsSort,
	StatisticsTypes,
	StatisticsUsers
} from './statistics';
import { StatisticsService } from './statistics.service';

@Component({
	selector: 'statistics-modal',
	templateUrl: './statistics-modal.component.html',
	styleUrls: ['./statistics.component.less']
})
export class StatisticsModalComponent implements OnInit, OnDestroy {

	refreshing = false;
	error: string = null;
	locale: string = 'sv-SE';
	utils = Utils;
	modalRef: BsModalRef;
	referenceTypesEnum = ReferenceTypes;
	statisticsTypesEnum = StatisticsTypes;
	spanTypesEnum = SpanTypes;
	dateTypeEnum = DateTypes;
	advancedStatisticsView: StatisticsAdvancedView;
	clickedRelatedStatistics: number;
	relatedDataPayload?: StatisticsPayload;
	statisticsByTypeAndDatePayload?: StatisticsByDateAndTypePayload;
	relatedDataButtonsDisabled = false;
	identifiers: number[] = [];

	mapStatistics = (data: ReferenceStatisticsData) => {
		const statisticsBySpan: StatisticsDataBySpanType[] = [];
		let name: string;
		Object.keys(data).forEach(p => {
			name = SpanTypes[SpanTypes[p[0].charAt(0).toUpperCase() + p.substring(1)]];
			if (name) {
				statisticsBySpan.push(new StatisticsDataBySpanType({
					type: SpanTypes[name],
					count: data[p],
					identifier: this.identifiers.shift()
				}));
			}
		});

		return new StatisticsDataByReferenceType({
			type: data.discriminator,
			data: statisticsBySpan,
			year: data.year.map(p => new StatisticsByDateData(p)),
			month: data.month.map(p => new StatisticsByDateData(p)),
			day: data.day.map(p => new StatisticsByDateData(p)),
		});
	}

	combinedTotalByDate = (
		statisticsByType: ReferenceStatisticsData[],
		date: DateTypes,
		combinedDiscriminators: ReferenceTypes[]) => {
		const highestTotalByDate = [].concat.apply([], statisticsByType.map(o => o[DateTypes[date].toLowerCase()]))
			.sort(o => o.total) as StatisticsByDateData[];

		if (!highestTotalByDate.length) {
			return null;
		}

		const combinedTotalGroupedByDate = Utils.convertObjectToArray(
			Utils.groupBy(
				[].concat.apply([], statisticsByType.map(o => o[DateTypes[date].toLowerCase()])),
				Utils.nameof<StatisticsByDateData>('date')
			)
		);

		for (const data of combinedTotalGroupedByDate) {
			const lastArray = data[data.length - 1];
			const newData = new StatisticsByDateData({
				date: lastArray[0].date,
				dateType: lastArray[0].dateType,
				discriminator: ReferenceTypes.Combined,
				total: data[data.length - 1]
					.map(o => o.total)
					.reduce((a, b) => a + b, 0),
				type: lastArray[0].type,
				combinedDiscriminators: combinedDiscriminators,
				renderConfig: new StatisticsByDateDataRenderConfig()
			});
			combinedTotalGroupedByDate[combinedTotalGroupedByDate.indexOf(data)] = newData;
		}

		combinedTotalGroupedByDate.sort((a, b) => b.total - a.total);

		if (date === DateTypes.Month || date === DateTypes.Day) {
			const highest = combinedTotalGroupedByDate[0] as StatisticsByDateData;
			const momentDate = date === DateTypes.Month
				? moment(new Date()).format('YYYY-MM')
				: moment(new Date()).format('YYYY-MM-DD');

			if (momentDate === highest.date) {
				highest.isTodayDate = true;
			}
		}

		return combinedTotalGroupedByDate;
	}

	private _destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	private _subscriptions = new Subscription();
	private _statisticsRelatedDataSubscription = new Subscription();
	private _getStatisticsByDateDataReloadData: {
		discriminators: ReferenceTypes[],
		type: StatisticsTypes,
		dateType: DateTypes,
	};
	newData$: Observable<NewDataNotification[]> = null;
	@Input() statistics: Statistics;
	@Output() onClose = new EventEmitter<any>();
	@Output() refresh = new EventEmitter<any>();

	@ViewChild('modal') modal: ModalDirective;

	constructor(
		private statisticsService: StatisticsService,
		private authService: AuthService,
		private cdRef: ChangeDetectorRef,
		private modalService: BsModalService) { }

	ngOnInit() {
		const currentUser = this.authService.loggedInUser;
		if (!currentUser.isSuperAdmin()) {
			return;
		}

		this.locale = currentUser.culture;

		if (!this.statisticsService.refreshing$
			&& this.statistics === null) {
			this.refresh.emit();
		}



		this._subscriptions.add(
			this.statisticsService.refreshing$
				.pipe(takeUntil(this._destroyed$))
				.subscribe(state => {
					this.statisticsService.resetRelatedData();
					this.refreshing = state;
					this.cdRef.detectChanges();
				})
		);

		this._subscriptions.add(
			this.statisticsService.refreshingDone$
				.pipe(takeUntil(this._destroyed$))
				.subscribe(state => {
					if (!this.refreshing && state && this.statistics) {
						this.setupAdvancedView();
						this.cdRef.detectChanges();
					}
				})
		);

		this._subscriptions.add(
			this.statisticsService.error$
				.pipe(takeUntil(this._destroyed$))
				.subscribe(state => {
					this.error = state;
					this.cdRef.detectChanges();
				})
		);

		this._subscriptions.add(
			this.statisticsService.statisticsByDateReload$
				.pipe(
					takeUntil(this._destroyed$),
					filter(o => o.value === 'in'))
				.subscribe(_ => {
					this.setStatisticsByDateDataPayloadByRefresh();
					this.cdRef.detectChanges();
					this.statisticsService.statisticsByDateReload$.next(
						new StatisticsByDataReloadData(
							'out',
							this.statisticsByTypeAndDatePayload as StatisticsByDateAndTypePayload));
				})
		);

		// Notifications for new data
		this.statisticsService
			.getNewData()
			.pipe(takeUntil(this._destroyed$))
			.subscribe(data => {
				this.newData$ = of(data);
				this.cdRef.detectChanges();
			});

		this.statisticsService.resetNewDataNotificationStatus();
	}

	trackByFn(index, item) {
		return item.id;
	}

	generateIdentifiers() {
		// Generate some numbers based on used enums to use as clicked value identifiers
		this.identifiers = Array.from(
			Array(
				Object.keys(StatisticsTypes).filter(o => !isNaN(Number(o))).length
				* (Object.keys(ReferenceTypes).filter(o => !isNaN(Number(o))).length
					* Object.keys(SpanTypes).filter(o => !isNaN(Number(o))).length)
			), (_, i) => i + 1);
	}

	setupAdvancedView() {
		const advancedStatisticsView = new StatisticsAdvancedView({
			refreshed: this.statistics.refreshed
		});

		if (this.statistics.assignments?.length
			&& this.statistics?.organizations?.length
			&& this.statistics?.users?.length) {
			this.generateIdentifiers();

			Object.keys(StatisticsTypes)
				.filter(statisticsType =>
					!isNaN(Number(statisticsType))
					&& StatisticsTypes[statisticsType] !== StatisticsTypes[StatisticsTypes.Combined])
				.forEach(statisticsType => {
					const assignmentStatisticsByType = this.statistics.assignments
						.filter(statisticData => String(statisticData.type) === StatisticsTypes[statisticsType]);
					const organizationStatisticsByType = this.statistics.organizations
						.filter(statisticData => String(statisticData.type) === StatisticsTypes[statisticsType]);
					const userStatisticsByType = this.statistics.users
						.filter(statisticData => String(statisticData.type) === StatisticsTypes[statisticsType]);

					if (assignmentStatisticsByType.length) {
						this.addAssignmentStatisticsByTypeToAdvancedStatisticsView(advancedStatisticsView, assignmentStatisticsByType, statisticsType);
					}

					if (organizationStatisticsByType.length) {
						this.addOrgStatisticsByTypeToAdvancedStatisticsView(advancedStatisticsView, organizationStatisticsByType, statisticsType);
					}

					if (userStatisticsByType.length) {
						this.addUserStatisticsByTypeToAdvancedStatisticsView(advancedStatisticsView, userStatisticsByType, statisticsType);
					}
				});
		}
		this.advancedStatisticsView = advancedStatisticsView;
	}

	addAssignmentStatisticsByTypeToAdvancedStatisticsView(
		advancedStatisticsView: StatisticsAdvancedView,
		assignmentStatisticsByType: ReferenceStatisticsData[],
		statisticsType: String
	) {
		const combinedDiscriminators = [
			ReferenceTypes.ScheduledAssignment,
			ReferenceTypes.MeasureAssignment
		];
		advancedStatisticsView.assignmentStatisticsByType.push(
			new StatisticsDataByStatisticsType({
				type: Number(statisticsType),
				data: assignmentStatisticsByType.map(this.mapStatistics),
				discriminators: combinedDiscriminators,
				combinedYears: this.combinedTotalByDate(
					assignmentStatisticsByType,
					DateTypes.Year,
					combinedDiscriminators),
				combinedMonths: this.combinedTotalByDate(
					assignmentStatisticsByType,
					DateTypes.Month,
					combinedDiscriminators),
				combinedDays: this.combinedTotalByDate(
					assignmentStatisticsByType,
					DateTypes.Day,
					combinedDiscriminators)
			})
		);
	}

	addOrgStatisticsByTypeToAdvancedStatisticsView(
		advancedStatisticsView: StatisticsAdvancedView,
		organizationStatisticsByType: ReferenceStatisticsData[],
		statisticsType: String
	) {
		const combinedDiscriminators = [
			ReferenceTypes.Organization
		];
		advancedStatisticsView.organizationStatisticsByType.push(
			new StatisticsDataByStatisticsType({
				type: Number(statisticsType),
				data: organizationStatisticsByType.map(this.mapStatistics),
				discriminators: combinedDiscriminators,
				combinedYears: this.combinedTotalByDate(
					organizationStatisticsByType,
					DateTypes.Year,
					combinedDiscriminators),
				combinedMonths: this.combinedTotalByDate(
					organizationStatisticsByType,
					DateTypes.Month,
					combinedDiscriminators),
				combinedDays: this.combinedTotalByDate(
					organizationStatisticsByType,
					DateTypes.Day,
					combinedDiscriminators)
			})
		);
	}

	addUserStatisticsByTypeToAdvancedStatisticsView(
		advancedStatisticsView: StatisticsAdvancedView,
		userStatisticsByType: ReferenceStatisticsData[],
		statisticsType: String
	) {
		const combinedDiscriminators = [
			ReferenceTypes.User
		];
		advancedStatisticsView.userStatisticsByType.push(
			new StatisticsDataByStatisticsType({
				type: Number(statisticsType),
				data: userStatisticsByType.map(this.mapStatistics),
				discriminators: combinedDiscriminators,
				combinedYears: this.combinedTotalByDate(
					userStatisticsByType,
					DateTypes.Year,
					combinedDiscriminators),
				combinedMonths: this.combinedTotalByDate(
					userStatisticsByType,
					DateTypes.Month,
					combinedDiscriminators),
				combinedDays: this.combinedTotalByDate(
					userStatisticsByType,
					DateTypes.Day,
					combinedDiscriminators)
			})
		);
	}

	async getRelatedData(
		discriminator: ReferenceTypes,
		type: StatisticsTypes,
		span: SpanTypes,
		modalTemplate: TemplateRef<any>,
		index: number,
		modalSize: 'm-large' | 'x-large') {
		this.clickedRelatedStatistics = index;
		this.relatedDataButtonsDisabled = true;

		const assignmentDiscriminators = [
			ReferenceTypes[ReferenceTypes.ScheduledAssignment],
			ReferenceTypes[ReferenceTypes.MeasureAssignment]
		];

		this.relatedDataPayload = new StatisticsPayload({
			discriminator: discriminator,
			type: assignmentDiscriminators.includes(discriminator.toString()) ? type : null,
			span: span
		});

		try {
			this.statisticsService.error$.next(null);
			let response: StatisticsAssignments | StatisticsOrganizations | StatisticsUsers;

			if (assignmentDiscriminators.includes(discriminator.toString())) {
				response = await this.statisticsService.getAssignmentRelatedData(this.relatedDataPayload);
				if (response) {
					const data = new StatisticsAssignments(response);
					this.setRelatedData(data);
					this.openModal(modalTemplate, modalSize);
				}
			} else if (discriminator.toString() === ReferenceTypes[ReferenceTypes.Organization]) {
				response = await this.statisticsService.getOrganizationRelatedData(this.relatedDataPayload);
				if (response) {
					const data = new StatisticsOrganizations(response);
					this.setRelatedData(data);
					this.openModal(modalTemplate, modalSize);
				}
			} else if (discriminator.toString() === ReferenceTypes[ReferenceTypes.User]) {
				response = await this.statisticsService.getUserRelatedData(this.relatedDataPayload);
				if (response) {
					const data = new StatisticsUsers(response);
					this.setRelatedData(data);
					this.openModal(modalTemplate, modalSize);
				}
			}
		} catch (e) {
			this.statisticsService.error$.next('StatisticsUnavailable');
			this.clickedRelatedStatistics = null;
			this.relatedDataButtonsDisabled = false;
		}
	}

	setRelatedData(data: StatisticsAssignments | StatisticsOrganizations | StatisticsUsers) {
		data.payload = this.relatedDataPayload;
		this.statisticsService.statisticsRelatedData$.next(data);
		this.clickedRelatedStatistics = null;
		this.relatedDataButtonsDisabled = false;
	}

	getStatisticsByDateData(
		discriminators: ReferenceTypes[],
		type: StatisticsTypes,
		dateType: DateTypes,
		modalTemplate: TemplateRef<any>) {
		this.setStatisticsByDateDataPayload(discriminators, type, dateType);
		this.openModal(modalTemplate, 'm-large');
	}

	private setStatisticsByDateDataPayloadByRefresh() {
		if (!this._getStatisticsByDateDataReloadData) {
			Utils.logMessage(
				'Variable _getStatisticsByDateDataReloadData must be set from initial opening of modal, to be able to refresh data.',
				this.authService.loggedInUser,
				LogLevel.Error
			);
		}

		this.setStatisticsByDateDataPayload(
			this._getStatisticsByDateDataReloadData.discriminators,
			this._getStatisticsByDateDataReloadData.type,
			this._getStatisticsByDateDataReloadData.dateType);
	}

	private setStatisticsByDateDataPayload(
		discriminators: ReferenceTypes[],
		type: StatisticsTypes,
		dateType: DateTypes) {
		const currentData = this.advancedStatisticsView;

		// Extracts statistics by given type and date type
		const dataByType = currentData.assignmentStatisticsByType
			.concat(currentData.organizationStatisticsByType)
			.concat(currentData.userStatisticsByType)
			.filter(o => StatisticsTypes[o.type] === String(type) &&
				Utils.arraysEqual(o.discriminators, discriminators))[0];

		const sort = new StatisticsSort({
			option: SortOptions[dateType],
			direction: SortDirection.Ascending
		});

		this.statisticsByTypeAndDatePayload = {
			...dataByType,
			dateType,
			sort
		} as StatisticsByDateAndTypePayload;

		this._getStatisticsByDateDataReloadData = {
			discriminators: discriminators,
			type: type,
			dateType: dateType
		};
	}

	openModal(modalTemplate: TemplateRef<any>, modalSize: 'm-large' | 'x-large') {
		setTimeout(() => {

			if (!this.modalRef) {
				this.modalRef = this.modalService.show(modalTemplate, {
					class: modalSize,
					keyboard: false,
					ignoreBackdropClick: true
				});
			}
		})
	}

	closeModal() {
		this.onClose.emit();
	}

	closeDataModal() {
		if (this.modalRef) {
			this.modalService.config.animated = false;
			this.modalRef.hide();
			this.modalRef = null;
		}
	}

	ngOnDestroy() {
		this._destroyed$.next(true);
		this._destroyed$.complete();
		this._statisticsRelatedDataSubscription.unsubscribe();
		this._subscriptions.unsubscribe();
	}
}
