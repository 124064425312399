<simple-crud-modal
	[titleText]="'StatisticsRelatedData' | translate"
	[closeText]="'Close' | translate"
	[submitText]="'ToTheTopOfList' | translate"
	[disableSubmit]="!!externalError || !!internalError || refreshing"
	[isLoading]="refreshing"
	(onClose)="closeModal()"
	(onSubmit)="scrollToTop()">
	<div class="extra-actions refresh-action-in-header" extraActions *ngIf="!externalError && !internalError && newDataAvailable">
		<span class="refresh-text">{{ "StatisticsUpdatedNewData" | translate }}</span>
		<div class="refresh"
			[ngClass]="{'disabled': refreshing }"
			title="{{ !refreshing ? ('RefreshStatistics' | translate) : '' }}"
			(click)="updateData()">
			<i class="icon" [ngClass]="{'refreshing': refreshing}"></i>
		</div>
	</div>
	<div class="statistics related-data in-modal">
		<ng-container *ngTemplateOutlet="dataTemplate"></ng-container>
		<ng-container *ngTemplateOutlet="pendingTemplate"></ng-container>
		<ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
	</div>
</simple-crud-modal>

<!-- Data template -->
<ng-template #dataTemplate>
	<ng-container *ngIf="!refreshing && !externalError && !internalError;">
		<div *ngIf="assignmentStatisticsData" class="statistics-data">
			<div class="section">
				<h4 class="h4" [innerHTML]="header"></h4>
				<div class="organization-navigation" *ngIf="assignmentStatisticsData.length > 1">
					<ng-select
						[items]="assignmentStatisticsData"
						[bindValue]="'name'"
						[bindLabel]="'name'"
						[searchable]="false"
						[appearance]="'outline'"
						[placeholder]="!ngSelectComponent?.selectedItems.length ? ('NavigateToOrganization' | translate) : ''"
						[clearable]="false"
						(change)="navigate($event.name)">
						<ng-template ng-label-tmp let-item="item">
							<span class="ng-value-label"
								[title]="item.name">
								{{ item.name }} ({{ item.assignments.length }})
							</span>
						</ng-template>
					</ng-select>
				</div>

				<div class="section-inner" #section
					*ngFor="let statsByOrgName of assignmentStatisticsData; trackBy: trackByFn"
					[attr.data-location]="statsByOrgName.name"
					[ngClass]="{
						'collapsed': expandedSection !== statsByOrgName.name
					}">
					<h5 class="h4 type"
						[ngClass]="{
							'clickable': expandedSection !== statsByOrgName.name
						}"
						(click)="expandSection(statsByOrgName.name)">
						<div class="type-content">
							{{ statsByOrgName.name }}
							<span class="count">({{ statsByOrgName.assignments.length | localeString }})</span>
						</div>
						<div class="toggle-inner-section"
							[ngClass]="{
								'expand': expandedSection !== statsByOrgName.name
							}">
							<i class="icon"></i>
						</div>

						<!-- TODO uncomment when adjusted for the separation of assignment lists and new retain param logic. -->
						<!-- <div *ngIf="payload.type !== statisticsTypesEnum.Deleted"
							class="show-in-list">
							<span (click)="showInList(
									statsByOrgName.friendlyOrganizationName,
									statsByOrgName.isManualMeasure,
									statsByOrgName.isMeasure
								)"
								title="{{ 'ShowInListInNewTab' | translate }}">
									{{ 'OpenList' | translate }}
								<i class="icon"></i>
							</span>
						</div> -->
					</h5>
					<div class="expandable">
						<div class="section-inner-description assignment desktop">
							<div class="description-value description lighter flex-none">
								<span class="header">{{ 'Name' | translate }}</span>
							</div>
							<div class="description-value description lighter flex-1">
								<span class="header">{{ 'Id' | translate }}</span>
							</div>
							<div class="description-value description lighter right flex-2">
								<span class="header">{{ 'Updated' | translate }}</span>
							</div>
							<div class="description-value description lighter right flex-2 flex-grow-1-5">
								<span class="header">{{ statisticsTypesEnum[payload.type] + 'StatisticsHeader' | translate }}</span>
							</div>
						</div>
						<div *ngFor="let data of statsByOrgName.assignments; trackBy: trackByFn">
							<div class="section-inner-data assignment">
								<ng-container *ngIf="payload.type !== statisticsTypesEnum.Deleted; else deletedContent">
									<a href="{{ data.url }}"
										target="_blank"
										title="{{ data.linkTitle }}"
										class="larger">
											<ng-container *ngTemplateOutlet="
												dataRowAssignment;
												context: {
													data: data
												}">
											</ng-container>
									</a>
								</ng-container>
								<ng-template #deletedContent>
									<div class="unclickable-content larger">
										<ng-container *ngTemplateOutlet="
											dataRowAssignment;
											context: {
												data: data
											}">
										</ng-container>
									</div>
								</ng-template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="organizationStatisticsData" class="statistics-data">
			<div class="section">
				<h4 class="h4" [innerHTML]="header">
				</h4>

				<div class="section-inner">
					<div class="section-inner-description organization desktop">
						<div class="description-value description lighter flex-none">
							<span class="header">{{ 'Logotype' | translate }}</span>
						</div>
						<div class="description-value description lighter flex-2">
							<span class="header">{{ 'Name' | translate }}</span>
						</div>
						<div class="description-value description lighter copy flex-1">
							<span class="header">{{ 'Id' | translate }}</span>
						</div>
						<div class="description-value description lighter count right flex-none">
							<span class="header desktop">{{ 'Users' | translate }}</span>
							<span class="header mobile">{{ 'UsersShort' | translate }}</span>
						</div>
						<div class="description-value description lighter count right flex-none">
							<span class="header desktop">{{ 'Members' | translate }}</span>
							<span class="header mobile">{{ 'MembersShort' | translate }}</span>
						</div>
						<div class="description-value description lighter flex-2 u-pl-15">
							<span class="header desktop">{{ 'Integrations' | translate }}</span>
							<span class="header mobile">{{ 'IntegrationsShort' | translate }}</span>
						</div>
						<div class="description-value description lighter right flex-2 flex-grow-1-5">
							<span class="header">{{ 'Updated' | translate }}</span>
						</div>
						<div class="description-value description lighter right flex-2">
							<span class="header">{{ 'Created' | translate }}</span>
						</div>
					</div>
					<div class="section-inner-data organization" *ngFor="let organization of organizationStatisticsData; trackBy: trackByFn">
						<a href="{{ organization.url }}"
							target="_blank"
							title="{{ organization.linkTitle }}"
							class="larger">
								<ng-container *ngTemplateOutlet="
									dataRowOrganization;
									context: {
										data: organization
									}">
								</ng-container>
						</a>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="userStatisticsData" class="statistics-data">
			<div class="section">
				<h4 class="h4" [innerHTML]="header">
				</h4>

				<div class="organization-navigation" *ngIf="userStatisticsData.length > 1">
					<ng-select
						[items]="userStatisticsData"
						[bindValue]="'name'"
						[bindLabel]="'name'"
						[searchable]="false"
						[appearance]="'outline'"
						[placeholder]="!ngSelectComponent?.selectedItems.length ? ('NavigateToOrganization' | translate) : ''"
						[clearable]="false"
						(change)="navigate($event.name)">
						<ng-template ng-label-tmp let-item="item">
							<span class="ng-value-label"
								[title]="item.name">
								{{ item.name }} ({{ item.users.length }})
							</span>
						</ng-template>
					</ng-select>
				</div>

				<div class="section-inner" #section
					*ngFor="let statsByOrgName of userStatisticsData; trackBy: trackByFn"
					[attr.data-location]="statsByOrgName.name"
					[ngClass]="{
						'collapsed': expandedSection !== statsByOrgName.name
					}">
					<h5 class="h4 type"
						[ngClass]="{
							'clickable': expandedSection !== statsByOrgName.name
						}"
						(click)="expandSection(statsByOrgName.name)">
						<div class="type-content">
							{{ statsByOrgName.name }}
							<span class="count">({{ statsByOrgName.users.length }})</span>
						</div>
						<div class="toggle-inner-section"
							[ngClass]="{
								'expand': expandedSection !== statsByOrgName.name
							}">
							<i class="icon"></i>
						</div>
					</h5>

					<div class="expandable">
						<div class="section-inner-description user desktop">
							<div class="description-value description lighter flex-none">
								<span class="header">{{ 'Logotype' | translate }}</span>
							</div>
							<div class="description-value description lighter largest flex-3">
								<span class="header">{{ 'Name' | translate }}/{{ 'Email' | translate }}</span>
							</div>
							<div class="description-value description lighter flex-1">
								<span class="header">{{ 'HighestRole' | translate }}</span>
							</div>
							<div class="description-value description lighter copy flex-1">
								<span class="header">{{ 'Id' | translate }}</span>
							</div>
							<div class="description-value description lighter right flex-1">
								<span class="header">{{ 'LastSync' | translate }}</span>
							</div>
							<div class="description-value description lighter right flex-2">
								<span class="header">{{ 'LastSignIn' | translate }}</span>
							</div>
							<div class="description-value description lighter right flex-2 flex-grow-1-75">
								<span class="header">{{ 'Updated' | translate }}</span>
							</div>
							<div class="description-value description lighter right flex-2">
								<span class="header">{{ 'Created' | translate }}</span>
							</div>
						</div>

						<div *ngFor="let user of statsByOrgName.users; trackBy: trackByFn">
							<div class="section-inner-data user">
								<a href="{{ user.url }}"
									target="_blank"
									title="{{ user.linkTitle }}"
									class="larger">
										<ng-container *ngTemplateOutlet="
											dataRowUser;
											context: {
												data: user
											}">
										</ng-container>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</ng-template>

<!-- Pending template -->
<ng-template #pendingTemplate>
	<ng-container *ngIf="refreshing">
		<div class="empty-state">
			<h4>
				{{ "FetchingStatistics" | translate }}...
			</h4>
		</div>
	</ng-container>
</ng-template>

<!-- Error template -->
<ng-template #errorTemplate>
	<ng-container *ngIf="externalError || internalError">
		<h4>
			{{ (externalError ? externalError : internalError) | translate }}...
		</h4>
	</ng-container>
</ng-template>

<ng-template #dataRowAssignment let-data="data">
	<div class="description-value flex-none">
		<span class="header mobile">
			{{ 'Name' | translate }}
		</span>
		<span>
			{{ data.name }}
		</span>
	</div>
	<div class="description-value copy only-icons flex-1">
		<span class="header mobile">
			{{ 'Id' | translate }}
		</span>
		<span class="copy-icon">
			<i #copiedPop="bs-popover"
				[popover]="popTemplate"
				placement="bottom"
				containerClass="ni-menu-popover popover-ol-negative10"
				container="body"
				[outsideClick]="true"
				class="icon"
				title="{{ 'Copy' | translate }} ({{data.id}})"
				(click)="dataCopied(copiedPop); $event.preventDefault()"
				[cdkCopyToClipboard]="data.id">
			</i>
		</span>
	</div>
	<div class="description-value right flex-2 flex-grow-1-5"
		[ngClass]="{'lighter': !data.updatedByName}">
		<span class="header mobile">
			{{ 'Updated' | translate }}
		</span>
		<span *ngIf="data.updatedByName" class="vertical-sections">
			<span class="over">
				{{ data.updated | datex: dateFormatDate }}
			</span>
			<span class="under">
				{{ data.updated | datex: dateFormatTime }}
			</span>
			<span class="under extra-data"
				[ngClass]="{'lighter': data.updatedByNameShort}">
				<b class="strong">{{ "By" | translate }}:&nbsp;</b>
				<span title="{{ data.updatedByName }}">
					{{ data.updatedByNameShort ? data.updatedByNameShort : data.updatedByName }}
				</span>
			</span>
		</span>
		<span *ngIf="!data.updatedByName">
			{{ "NeverUpdated" | translate }}
		</span>
	</div>
	<div class="description-value right flex-2 flex-grow-1-5">
		<span class="header mobile">
			{{ statisticsTypesEnum[payload.type] + 'StatisticsHeader' | translate }}
		</span>
		<span class="vertical-sections">
			<span class="over">
				{{ data.date | datex: dateFormatDate }}
			</span>
			<span class="under">
				{{ data.date | datex: dateFormatTime }}
			</span>
			<span *ngIf="statisticsTypesEnum[payload.type] !== 'DueDate'"
				class="under extra-data"
				[ngClass]="{'lighter': data.actionByNameShort}">
				<b class="strong">{{ "By" | translate }}:&nbsp;</b>
				<span title="{{ data.actionByName }}">
					{{ data.actionByNameShort ? data.actionByNameShort : data.actionByName }}
				</span>
			</span>
		</span>
	</div>
</ng-template>

<ng-template #dataRowOrganization let-data="data">
	<div class="description-value flex-none">
		<span class="header mobile">
			{{ 'Logotype' | translate }}
		</span>
		<div class="logotype" #parentDivOrg>
			<extensive-image
				[imageUrl]="data.logotypeUrl"
				[imageStorageSize]="parentDivOrg | getImageStorageSize"
				[rounded]="true"
				[placeholderImageType]="'organization'"
				[displayLoader]="true">
			</extensive-image>
		</div>
	</div>
	<div class="description-value flex-2">
		<span class="header mobile">
			{{ 'Name' | translate }}
		</span>
		<span>
			{{ data.name }}
		</span>
	</div>
	<div class="description-value copy flex-1">
		<span class="header mobile">
			{{ 'Id' | translate }}
		</span>
		<span class="data-id">
			{{ data.id }}
		</span>
		<span class="copy-icon">
			<i #copiedPop="bs-popover"
				[popover]="popTemplate"
				placement="bottom"
				containerClass="ni-menu-popover"
				container="body"
				[outsideClick]="true"
				class="icon"
				title="{{ 'Copy' | translate }} ({{data.id}})"
				(click)="dataCopied(copiedPop); $event.preventDefault()"
				[cdkCopyToClipboard]="data.id">
			</i>
		</span>
	</div>
	<div class="description-value count right flex-none"
		[ngClass]="{'lighter': data.users === 0}">
		<span class="header mobile">
			{{ 'Users' | translate }}
		</span>
		<span>
			{{ data.users }}
		</span>
	</div>
	<div class="description-value count right flex-none"
		[ngClass]="{'lighter': data.members === 0}">
		<span class="header mobile">
			{{ 'Members' | translate }}
		</span>
		<span>
			{{ data.members }}
		</span>
	</div>
	<div class="description-value flex-2 u-pl-15"
		[ngClass]="{'lighter': !data.integrations.length}">
		<span class="header mobile">
			{{ 'Integrations' | translate }}
		</span>
		<span *ngIf="data.integrations.length"
			[ngClass]="{'vertical-sections': data.integrations.length > 1}">
			<ng-container *ngFor="let integration of data.integrations; trackBy: trackByFn; let first = first;">
				<span [ngClass]="{
					'over': data.integrations.length > 1 && first,
					'under': data.integrations.length > 1 && !first
				}">{{ integration }}</span>
			</ng-container>
		</span>
		<span *ngIf="!data.integrations.length">
			{{ "NoIntegrations" | translate }}
		</span>
	</div>
	<div class="description-value right flex-2 flex-grow-1-5"
		[ngClass]="{'lighter': !data.updatedByName}">
		<span class="header mobile">
			{{ 'Updated' | translate }}
		</span>
		<span *ngIf="data.updatedByName" class="vertical-sections">
			<span class="over">
				{{ data.updated | datex: dateFormatDate }}
			</span>
			<span class="under">
				{{ data.updated | datex: dateFormatTime }}
			</span>
			<span class="under extra-data"
				[ngClass]="{'lighter': data.updatedByNameShort}">
				<b class="strong">{{ "By" | translate }}:&nbsp;</b>
				<span title="{{ data.updatedByName }}">
					{{ data.updatedByNameShort ? data.updatedByNameShort : data.updatedByName }}
				</span>
			</span>
		</span>
		<span *ngIf="!data.updatedByName">
			{{ "NeverUpdated" | translate }}
		</span>
	</div>
	<div class="description-value right flex-2">
		<span class="header mobile">
			{{ 'Created' | translate }}
		</span>
		<span class="vertical-sections">
			<span class="over">
				{{ data.created | datex: dateFormatDate }}
			</span>
			<span class="under">
				{{ data.created | datex: dateFormatTime }}
			</span>
			<span class="under extra-data"
				[ngClass]="{'lighter': data.createdByNameShort}">
				<b class="strong">{{ "By" | translate }}:&nbsp;</b>
				<span title="{{ data.createdByName }}">
					{{ data.createdByNameShort ? data.createdByNameShort : data.createdByName }}
				</span>
			</span>
		</span>
	</div>
</ng-template>

<ng-template #dataRowUser let-data="data">
	<div class="description-value flex-none">
		<span class="header mobile">
			{{ 'Logotype' | translate }}
		</span>
		<div class="logotype round" #parentDivUser>
			<extensive-image
				[imageUrl]="data.profileImageUrl"
				[imageStorageSize]="parentDivUser | getImageStorageSize"
				[rounded]="true"
				[placeholderImageType]="'user'"
				[displayLoader]="true">
			</extensive-image>
		</div>
	</div>
	<div class="description-value flex-3">
		<span class="header mobile">
			{{ 'Name' | translate }}
		</span>
		<span class="vertical-sections">
			<span class="over">
				{{ data.firstname }} {{ data.lastname }}
			</span>
			<span class="under">
				{{ data.email }}
			</span>
		</span>
	</div>
	<div class="description-value flex-1">
		<span class="header mobile">
			{{ 'HighestRole' | translate }}
		</span>
		<span>
			{{ data.role | translate }}
		</span>
	</div>
	<div class="description-value copy only-icons flex-1">
		<span class="header mobile">
			{{ 'Id' | translate }}
		</span>
		<span class="copy-icon">
			<i #copiedPop="bs-popover"
				[popover]="popTemplate"
				placement="bottom"
				containerClass="ni-menu-popover popover-ol-negative10"
				container="body"
				[outsideClick]="true"
				class="icon"
				title="{{ 'Copy' | translate }} ({{data.id}})"
				(click)="dataCopied(copiedPop); $event.preventDefault()"
				[cdkCopyToClipboard]="data.id">
			</i>
		</span>
	</div>
	<div class="description-value right flex-1"
		[ngClass]="{'lighter': !data.lastSync}">
		<span class="header mobile">
			{{ 'LastSync' | translate }}
		</span>
		<span *ngIf="data.lastSync" class="vertical-sections">
			<span class="over">
				{{ data.lastSync | datex: dateFormatDate }}
			</span>
			<span class="under">
				{{ data.lastSync | datex: dateFormatTime }}
			</span>
		</span>
		<span *ngIf="!data.lastSync">
			{{ "NeverSynced" | translate }}
		</span>
	</div>
	<div class="description-value right flex-2"
		[ngClass]="{'lighter': !data.lastSignInDate}">
		<span class="header mobile">
			{{ 'LastSignIn' | translate }}
		</span>
		<span *ngIf="data.lastSignInDate" class="vertical-sections">
			<span class="over">
				{{ data.lastSignInDate | datex: dateFormatDate }}
			</span>
			<span class="under">
				{{ data.lastSignInDate | datex: dateFormatTime }}
			</span>
			<span class="under extra-data">
				<b class="strong">{{ "Client" | translate }}:&nbsp;</b>
				<span title="{{ data.lastSignInPlatform | translate }}">
					{{ data.lastSignInPlatform | translate }}
				</span>
			</span>
		</span>
		<span *ngIf="!data.lastSignInDate">
			{{ "NoAuthenticationActivity" | translate }}
		</span>
	</div>
	<div class="description-value right flex-2 flex-grow-1-75"
		[ngClass]="{'lighter': !data.updatedByName}">
		<span class="header mobile">
			{{ 'Updated' | translate }}
		</span>
		<span *ngIf="data.updatedByName" class="vertical-sections">
			<span class="over">
				{{ data.updated | datex: dateFormatDate }}
			</span>
			<span class="under">
				{{ data.updated | datex: dateFormatTime }}
			</span>
			<span class="under extra-data"
				[ngClass]="{'lighter': data.updatedByNameShort}">
				<b class="strong">{{ "By" | translate }}:&nbsp;</b>
				<span title="{{ data.updatedByName }}">
					{{ data.updatedByNameShort ? data.updatedByNameShort : data.updatedByName }}
				</span>
			</span>
		</span>
		<span *ngIf="!data.updatedByName">
			{{ "NeverUpdated" | translate }}
		</span>
	</div>
	<div class="description-value right flex-2">
		<span class="header mobile">
			{{ 'Created' | translate }}
		</span>
		<span class="vertical-sections">
			<span class="over">
				{{ data.created | datex: dateFormatDate }}
			</span>
			<span class="under">
				{{ data.created | datex: dateFormatTime }}
			</span>
			<span class="under extra-data"
				[ngClass]="{'lighter': data.createdByNameShort}">
				<b class="strong">{{ "By" | translate }}:&nbsp;</b>
				<span title="{{ data.createdByName }}">
					{{ data.createdByNameShort ? data.createdByNameShort : data.createdByName }}
				</span>
			</span>
		</span>
	</div>
</ng-template>


<ng-template #popTemplate>
	<div class="ni-menu-item">
		{{ ('HasBeenCopiedToClipboard' | translate) }}
	</div>
</ng-template>