import {
	Component
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleDeleteModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-delete-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Facility } from '../facility';
import { FacilityService } from '../facility.service';

@Component({
	templateUrl: './delete-facilities.component.html',
	styleUrls: ['./delete-facilities.component.less']
})
export class DeleteFacilitiesComponent extends SimpleDeleteModalDirective<Facility> {

	shouldDeleteEntities: boolean = true;
	shouldShowEntitiesDeletionOption: boolean = false;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private facilityService: FacilityService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	protected async delete() {
		this.pending = true;
		try {
			const response = await this.facilityService.deleteFacility(this.deleteModelIds[0], this.shouldDeleteEntities);
			this.handleSuccessResponse(response, this.translationService.instant('BackgroundJobStartedDeleteFacility'));

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected async deleteRange() {
		this.pending = true;
		try {
			const response = await this.facilityService.deleteFacilities(this.deleteModelIds, this.shouldDeleteEntities);
			this.handleSuccessResponse(response, this.translationService.instant('BackgroundJobStartedDeleteFacilities'));

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	toggleRemoveEntities() {
		this.shouldDeleteEntities = !this.shouldDeleteEntities;
	}
}
