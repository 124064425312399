import { Component, ViewChild } from '@angular/core';
import { GoogleMapsComponent } from 'app-core/shared-core/google-maps/google-maps.component';
import { MarkdownObject } from 'app-core/shared-core/markdown-viewer/markdown-viewer.component';
import { MarkdownViewerModalComponent } from 'app-core/shared-core/markdown-viewer/modal/markdown-viewer-modal.component';
import { SimpleViewTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/view/simple-view-tab.directive';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { Entity } from 'app-inspection/entity/entity';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'view-details-tab',
	templateUrl: './view-details-tab.component.html',
	styleUrls: ['./view-details-tab.component.less']
})
export class ViewDetailsTabComponent extends SimpleViewTabDirective<Entity> {

	bsModalRef: BsModalRef<any>;

	@ViewChild(GoogleMapsComponent) googleMapsComponent: GoogleMapsComponent;

	get thumbnailUrl() {
		const defaultMedia = this.viewModel.media.find(media => media.default === true);
		return defaultMedia && defaultMedia.url ? defaultMedia.url : null;
	}

	get blob() {
		const defaultMedia = this.viewModel.media.find(media => media.default === true);
		return defaultMedia && defaultMedia.blob ? defaultMedia.blob : null;
	}

	openExtraInfo() {
		this.bsModalRef = this.modalService.show(
			MarkdownViewerModalComponent,
			{
				initialState: {
					titleText: this.translationService.instant('ExtraInfo'),
					readModel: new MarkdownObject({
						content: this.viewModel.extraInfo
					})
				},
				...ConfigUtils.MODAL_CONFIG_M_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}
}
