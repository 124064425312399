import { Component, Input } from '@angular/core';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

@Component({
	selector: 'notice-icon',
	templateUrl: './notice-icon.component.html',
	styleUrls: ['./notice-icon.component.less']
})
export class NoticeIconComponent {

	@Input() shouldDisplay: boolean;
	@Input() iconClass: string;
	@Input() titleText: string;
	@Input() noticeText: string;
	@Input() topPx: string = '5px';
	@Input() leftPx: string = '-8px';
	@Input() rightPx: string = null;
	@Input() position: string = 'absolute';
	@Input() placement: AvailbleBSPositions = 'top';
	@Input() shouldStopPropagation: boolean;
}
