import { Injectable } from '@angular/core';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { TranslationService } from '../translation/translation.service';

@Injectable()
export class FileHelper {

	constructor(
		private translationService: TranslationService) { }


	handleFile(fileData: FileData) {
		try {
			const binaryString = window.atob(fileData.fileContents);
			const uint8Array = new Uint8Array(binaryString.length);

			for (let i = 0; i < binaryString.length; i++) {
				uint8Array[i] = binaryString.charCodeAt(i);
			}

			const blob = new Blob([uint8Array], { type: fileData.contentType });
			const name = fileData.fileDownloadName;
			const url = URL.createObjectURL(blob);

			this.downloadFile(name, url);

		} catch (errorResponse) {
			// this.validationHelper.showMessage({ todo toastr?
			// 	succeeded: false,
			// 	errorMessage: this.translationService.instant(StringUtils.COULD_NOT_BE_GENERATED)
			// });
		}
	}

	downloadFile(name: string, url: string) {
		const element = document.createElement('a');
		element.download = name;
		element.href = url;
		element.click();
	}

	async convertToZipAndDownload(items: MediaWidgetItem[]) {
		const isImage = items.every(item => item.isImage);
		const jsZip = new JSZip();
		const promises = [];
		items.forEach(item => {
			promises.push(
				fetch(item.url)
					.then((response: any) => {
						if (response.ok) {
							return response.arrayBuffer()
								.then((buff: any) => jsZip.file(item.title, buff));
						} else {
							throw Error();
						}
					})
			);
		});

		try {
			await Promise.all(promises);
			saveAs(
				await jsZip.generateAsync({ type: 'blob' }),
				isImage ? 'images.zip' : 'documents.zip'
			);
			return true;
		} catch (errorResponse) {
			return false;
		}
	}
}

export class FileData {
	contentType: string;
	fileDownloadName: string;
	fileContents: string;

	constructor(data: Partial<FileData>) {
		Object.assign(this, data);
	}
}
