import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'timeline',
	templateUrl: './timeline.component.html',
	styleUrls: ['./timeline.component.less']
})
export class TimelineComponent {

	previousItemYear: string;

	@Input() items: DateItem[];
	@Input() previewCount: number;

	@Output() onClick = new EventEmitter();

	shouldDisplayYear(item: DateItem) {
		if ((this.items.length && this.items[0] === item) || item.yearString !== this.previousItemYear) {
			this.previousItemYear = item.yearString;
			return true;
		}
		return false;
	}
}

export class DateItem {
	display: boolean;
	yearString: string;
	infoText: string;
	dayAndMonthString: string;
	dayNameString: string;

	constructor(dateItem: Partial<DateItem>) {
		Object.assign(this, dateItem);
	}
}
