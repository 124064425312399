import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { GoogleMapsComponent, LocationObject } from 'app-core/shared-core/google-maps/google-maps.component';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { RegexUtils } from 'app-core/shared-core/tools/regex-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Entity } from 'app-inspection/entity/entity';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'change-details-tab',
	templateUrl: './change-details-tab.component.html',
	styleUrls: ['./change-details-tab.component.less']
})
export class ChangeDetailsTabComponent extends SimpleChangeTabDirective<Entity> implements OnInit {

	selectableStatusItems: FormSelectItem[] = [];
	selectableAssemblyYearItems: FormSelectItem[] = [];
	selectableFacilityItems: FormSelectItem[] = [];
	selectableAccessibilityItems: FormSelectItem[] = [];

	markdownTextAreaIsFocused: boolean = false;

	@Input() hideFacility: boolean;

	@ViewChild(GoogleMapsComponent) googleMapsComponent: GoogleMapsComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableStatusItems();
		this.setSelectableAssemblyYearItems();
		this.setSelectableFacilityItems();
		this.setSelectableAccessibilityItems();

		this.form = this.formBuilder.group({
			[this.propertyStrings.name]: [this.changeModel.name,
			[Validators.required, Validators.maxLength(150)]
			],
			[this.propertyStrings.description]: [this.changeModel.description,
			Validators.maxLength(500)
			],
			[this.propertyStrings.facilityId]: [this.changeModel.facilityId],
			[this.propertyStrings.status]: [this.changeModel.status,
			Validators.required
			],
			[this.propertyStrings.manufacturer]: [this.changeModel.manufacturer,
			Validators.maxLength(50)
			],
			[this.propertyStrings.assembler]: [this.changeModel.assembler,
			Validators.maxLength(50)
			],
			[this.propertyStrings.assemblyYear]: [this.changeModel.assemblyYear],
			[this.propertyStrings.typeDesignation]: [this.changeModel.typeDesignation,
			Validators.maxLength(50)
			],
			[this.propertyStrings.articleId]: [this.changeModel.articleId,
			Validators.maxLength(50)
			],
			[this.propertyStrings.latitude]: [this.changeModel.latitude,
			this.latitudePattern
			],
			[this.propertyStrings.longitude]: [this.changeModel.longitude,
			this.longitudePattern
			],
			[this.propertyStrings.accessible]: [this.changeModel.accessible,
			Validators.required
			],
			[this.propertyStrings.infoLink]: [this.changeModel.infoLink,
			[
				Validators.maxLength(250),
				this.urlPattern
			]
			],
			[this.propertyStrings.externalId]: [this.changeModel.externalId,
			Validators.maxLength(50)
			]
		}, { validators: this.positionValidator });

		super.ngOnInit();
	}

	handleMarkerMove(locationObject: LocationObject) {
		this.form.get(this.changeModel.propertyStrings.latitude).setValue(locationObject.latitude.toString());
		this.form.get(this.changeModel.propertyStrings.longitude).setValue(locationObject.longitude.toString());
	}

	isExtraSmallScreenSize() {
		return Utils.isExtraSmallScreenSize();
	}

	handleExtraInfoChange(text: string) {
		this.changeModel.extraInfo = text.trim();
	}

	handleMarkdownTextAreaFocus(focused: boolean) {
		this.markdownTextAreaIsFocused = focused;
	}

	protected async getCurrentLocation() {
		const locationObject = await this.googleMapsComponent.getCurrentLocation();
		if (locationObject) {
			const location = new google.maps.LatLng(parseFloat(locationObject.latitude), parseFloat(locationObject.longitude));
			this.googleMapsComponent.emitLocation(location);
		}
	}

	private setSelectableStatusItems() {
		this.selectableStatusItems = [
			new FormSelectItem(true, this.translationService.instant(StringUtils.ACTIVE)),
			new FormSelectItem(false, this.translationService.instant(StringUtils.INACTIVE)),
		];
	}

	private setSelectableAssemblyYearItems() {
		const yearItems: FormSelectItem[] = [];
		const currentYear = new Date().getFullYear();
		let endYear = currentYear;
		while ((currentYear - endYear) < 30) {
			yearItems.push(new FormSelectItem(endYear.toString(), endYear.toString()));
			endYear--;
		}
		this.selectableAssemblyYearItems = yearItems;
	}

	private setSelectableFacilityItems() {
		this.selectableFacilityItems = this.changeModel.selectableFacilities.map(facility => new FormSelectItem(facility.id, facility.name));
	}

	private setSelectableAccessibilityItems() {
		this.selectableAccessibilityItems = [
			new FormSelectItem(true, this.translationService.instant(StringUtils.YES)),
			new FormSelectItem(false, this.translationService.instant(StringUtils.NO)),
		];
	}

	private latitudePattern(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.LATITUDE).test(control.value)) {
			return { latitude: true };
		} else {
			return null;
		}
	}

	private longitudePattern(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.LONGITUDE).test(control.value)) {
			return { longitude: true };
		} else {
			return null;
		}
	}

	private positionValidator(form: FormGroup): ValidationErrors {
		const latitude = form.get('latitude');
		const longitude = form.get('longitude');

		if (latitude.value && !longitude.value) {
			longitude.setErrors({ required: true });
			longitude.markAsTouched();
		} else if (longitude.value && !latitude.value) {
			latitude.setErrors({ required: true });
			latitude.markAsTouched();
		} else if (!latitude.value && !longitude.value) {
			latitude.setErrors(null);
			longitude.setErrors(null);
		}
		return null;
	}

	private urlPattern(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.URL).test(control.value)) {
			return { url: true };
		} else {
			return null;
		}
	}
}
