import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'simple-crud-modal',
	templateUrl: './simple-crud-modal.component.html',
	styleUrls: ['./simple-crud-modal.component.less']
})
export class SimpleCrudModalComponent {

	@HostListener('document:keydown.enter', ['$event']) keydownEnter(event: KeyboardEvent) {
		const target = event.target as HTMLElement;
		const wrapper = this.wrapperEl.nativeElement as HTMLElement;
		if (target.classList.contains('submittable') && wrapper.contains(target)) {
			this.handleSubmit();
		}
	}

	@ViewChild('wrapper', { read: ElementRef }) wrapperEl: ElementRef;

	@Input() titleText: string;
	@Input() closeText: string;
	@Input() submitText: string;
	@Input() disableSubmit: boolean;
	@Input() isDelete: boolean;
	@Input() isLoading: boolean;
	@Input() displayDummy: boolean;
	@Input() hideHeader: boolean;

	@Output() onClose = new EventEmitter<boolean>(false);
	@Output() onSubmit = new EventEmitter<boolean>(false);

	handleClose() {
		this.onClose.emit();
	}

	handleSubmit() {
		this.onSubmit.emit();
	}
}
