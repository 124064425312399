import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';

@Pipe({
	name: 'mdToHtml'
})

export class MarkdownToHTMLPipe implements PipeTransform {
	transform(value: any, args?: any[]): any {
		if (value && value.length > 0) {
			const renderer = new marked.Renderer();
			renderer.link = function (href, title, text) {
				return '<a target="_blank" href="' + href + '" title="' + href + '">' + text + '</a>';
			};

			const contexts = value.split('\n');
			const cleanedContexts = [];
			contexts.forEach(context => {
				const lines = context.split('\n');
				context = lines.map(line => RegExp(/^\s{4,}/).test(line) ? line.trimStart() : line).join('\n');
				cleanedContexts.push(context);
			});
			value = cleanedContexts.join('\n');


			const options: marked.MarkedOptions = {
				renderer: renderer,
				breaks: true,
			};

			// const preparedString = prepareForHTML(value);
			return marked(value, options);
		}
		return value;
	}
}

// function prepareForHTML(value: string): string {
// 	const regex = new RegExp(/\n\n/g);
// 	const preparedString = value.replace(regex, '  \n');
// 	return preparedString;
// }
