export class SimpleTableRow<T extends { id: string }> {

	readonly data: T;
	readonly id: string;

	affected: boolean;
	selected: boolean;
	unselectable: boolean;
	added: boolean;
	modified: boolean;
	actionsOpened: boolean;
	referenceUrl: string;
	index: number;
	hovered: boolean;

	constructor(data: T) {
		this.data = data;
		this.id = data.id;
		this.affected = false;
		this.selected = false;
		this.unselectable = false;
		this.added = false;
		this.modified = false;
		this.actionsOpened = false;
	}
}
