
export class ConversionHelper {

	// Converts a file/blob to a base 64 representation.
	static blobToBase64asPromise = (data: any) => {
		const reader = new FileReader();
		return new Promise(resolve => {
			reader.onload = event => {
				const result = (event.target as any).result;
				resolve(result.substr(result.indexOf(',') + 1));
			};
			reader.readAsDataURL(data);
		});
	}
}
