import { Component, Input } from '@angular/core';
import { SimpleChangeTabComponent } from '../simple-change-tab.component';

@Component({
	selector: 'simple-change-bulk-tab',
	templateUrl: './simple-change-bulk-tab.component.html',
	styleUrls: ['./simple-change-bulk-tab.component.less']
})
export class SimpleChangeBulkTabComponent extends SimpleChangeTabComponent {
	@Input() affectableIdsCount: number;
	@Input() selectedIdsCount: number;
	@Input() hideText: boolean;
}
