import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { Filter } from '../../filter';
import { HostedHttpClientService } from '../../hosted-httpclient.service';

export abstract class BaseService<T extends { id: string | number }> {

	protected useCoreApi: boolean = false;

	constructor(
		protected hostedHttpClient: HostedHttpClientService,
		protected prefix: string) { }

	async getFiltered(filter: Filter): Promise<T[]> {
		return this.hostedHttpClient.get(`${this.prefix}/filtered`, filter.toPayloadObject(), this.useCoreApi)
			.then(res => res.data.map((item: T) => this.instantiateModel(item)));
	}

	async getFilteredIds(filter: Filter): Promise<string[]> {
		return this.hostedHttpClient.get(`${this.prefix}/ids`, filter.toPayloadObject(), this.useCoreApi)
			.then(res => res.data);
	}

	async get(id: string | number): Promise<T> {
		return this.hostedHttpClient.get(`${this.prefix}/${id}`, {}, this.useCoreApi)
			.then(res => this.instantiateModel(res.data));
	}

	create(item: T) {
		return this.hostedHttpClient.post(`${this.prefix}`, item, this.useCoreApi);
	}

	update(item: T) {
		return this.hostedHttpClient.put(`${this.prefix}/${item.id}`, item, this.useCoreApi);
	}

	delete(id: string | number) {
		return this.hostedHttpClient.delete(`${this.prefix}/${id}`, {}, this.useCoreApi);
	}

	deleteRange(itemIds: string[]) {
		return this.hostedHttpClient.delete(`${this.prefix}`, { itemIds: itemIds }, this.useCoreApi);
	}

	updateStatus(itemIds: string[], active: boolean) {
		const model = {
			itemIds: itemIds,
			active: active
		};
		return this.hostedHttpClient.put(`${this.prefix}/statuses`, model, this.useCoreApi);
	}

	uploadMedia(parentId: string, mediaItem: MediaWidgetItem) {
		const formData = new FormData();
		formData.append('file', mediaItem.blob);
		formData.append('isDefault', mediaItem.default ? 'true' : 'false');

		return this.hostedHttpClient.upload(`${this.prefix}/${parentId}/media/upload`, formData);
	}

	assignMedia(parentId: string, mediaList: MediaWidgetItem[]) {
		return this.hostedHttpClient.put(`${this.prefix}/${parentId}/media`, { media: mediaList });
	}

	abstract instantiateModel(item: T): T;
}

export class BackendResponse {
	successMessage: string;
	errorMessage: string;
	data: any;
	error: any;
}
