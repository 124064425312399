import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleCreateEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-create-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { InvitedUser } from 'app-core/user/user';
import { UserService } from 'app-core/user/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PasswordTabComponent } from './tabs/password-tab.component';

@Component({
	templateUrl: './password.component.html'
})
export class PasswordComponent extends SimpleCreateEditModalDirective<InvitedUser> implements OnInit {

	otherUserId: string = '';

	@ViewChild(PasswordTabComponent) passwordTabComponent: PasswordTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private userService: UserService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.initialModel = new InvitedUser({});
		this.modifiedModel = new InvitedUser(this.getUniqueVariant(this.initialModel));
	}

	changeDetailsIsValid() {
		return this.passwordTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.passwordTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = this.otherUserId ?
				await this.userService.updatePasswordWithOtherUserId(
					this.modifiedModel.newPassword,
					this.modifiedModel.confirmPassword,
					this.otherUserId
				)
				: await this.userService.updatePassword(
					this.modifiedModel.oldPassword,
					this.modifiedModel.newPassword,
					this.modifiedModel.confirmPassword
				);

			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);

			if (this.serverErrors.length) {
				this.passwordTabComponent.setServerErrors(this.serverErrors);
			}
		}
	}

	protected triggerAllValidation() {
		this.passwordTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: InvitedUser) {
		return new InvitedUser(item);
	}

	override submit() {
		if (this.everythingIsValid()) {
			this.serverErrors = [];
			this.createOrUpdate();
		} else {
			this.triggerAllValidation();
		}
	}

	hasUnsavedChanges() {
		return false;
	}
}
