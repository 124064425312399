import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DEFAULT_DISPLAY_DATE_FORMAT } from '../tools/utils';

// Workaround for the default DatePipe.
// Taken from https://stackoverflow.com/a/40160501/186787
@Pipe({
	name: 'datex'
})
export class DatexPipe implements PipeTransform {
	transform(value: any, format: string = DEFAULT_DISPLAY_DATE_FORMAT): string {
		// Try and parse the passed value.
		const momentDate = moment(value);

		// If moment didn't understand the value, return it unformatted.
		if (!momentDate.isValid()) {
			return value;
		}

		// Otherwise, return the date formatted as requested.
		return momentDate.format(format);
	}
}
