import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { FormSelectItem } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/form/form-select/simple-form-select.component';
import { SimpleChangeTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/change/simple-change-tab.directive';
import { RegexUtils } from 'app-core/shared-core/tools/regex-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { InfraControlIntegration } from '../../../integration';

@Component({
	selector: 'change-infra-control-details-tab',
	templateUrl: './change-infra-control-details-tab.component.html'
})
export class ChangeInfraControlDetailsTabComponent extends SimpleChangeTabDirective<InfraControlIntegration> implements OnInit {

	selectableStatusItems: FormSelectItem[] = [];
	selectableEmailItems: FormSelectItem[] = [];

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private formBuilder: FormBuilder) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setSelectableStatusItems();
		this.setSelectableEmailItems();

		if (this.isEdit) {
			this.form = this.formBuilder.group({
				[this.propertyStrings.system]: [this.changeModel.system,
				Validators.required
				],
				[this.propertyStrings.serviceAccountEmail]: [this.changeModel.serviceAccountEmail,
				[Validators.required, this.emailValidator]
				],
				[this.propertyStrings.username]: [this.changeModel.username,
				Validators.required
				],
				[this.propertyStrings.password]: [this.changeModel.password],
				[this.propertyStrings.newPassword]: [this.changeModel.newPassword,
				[
					this.passwordDigitValidator,
					this.passwordLowerValidator,
					this.passwordUpperValidator,
					this.passwordSpecialValidator,
					this.passwordLengthValidator
				]
				],
				[this.propertyStrings.confirmPassword]: [this.changeModel.confirmPassword,
				[
					this.passwordDigitValidator,
					this.passwordLowerValidator,
					this.passwordUpperValidator,
					this.passwordSpecialValidator,
					this.passwordLengthValidator
				]
				],
				[this.propertyStrings.emails]: [this.changeModel.emails,
				this.emailsValidator
				],
				[this.propertyStrings.active]: [this.changeModel.active,
				Validators.required
				],
			}, { validators: [this.passwordMatchValidator, this.changeCurrentPasswordValidator] });
		} else {
			this.form = this.formBuilder.group({
				[this.propertyStrings.system]: [this.changeModel.system,
				Validators.required
				],
				[this.propertyStrings.serviceAccountEmail]: [this.changeModel.serviceAccountEmail,
				[Validators.required, this.emailValidator]
				],
				[this.propertyStrings.username]: [this.changeModel.username,
				Validators.required
				],
				[this.propertyStrings.newPassword]: [this.changeModel.newPassword,
				[
					Validators.required,
					this.passwordDigitValidator,
					this.passwordLowerValidator,
					this.passwordUpperValidator,
					this.passwordSpecialValidator,
					this.passwordLengthValidator
				]
				],
				[this.propertyStrings.confirmPassword]: [this.changeModel.confirmPassword,
				[
					Validators.required,
					this.passwordDigitValidator,
					this.passwordLowerValidator,
					this.passwordUpperValidator,
					this.passwordSpecialValidator,
					this.passwordLengthValidator
				]
				],
				[this.propertyStrings.emails]: [this.changeModel.emails,
				this.emailsValidator
				],
				[this.propertyStrings.active]: [this.changeModel.active,
				Validators.required
				],
			}, { validators: this.passwordMatchValidator });

		}

		super.ngOnInit();
	}

	private emailValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.EMAIL).test(control.value)) {
			return { email: true };
		} else {
			return null;
		}
	}

	private emailsValidator(control: AbstractControl) {
		if (control.value && control.value.some((value: any) => !new RegExp(RegexUtils.EMAIL).test(value))) {
			return { emails: true };
		} else {
			return null;
		}
	}

	private passwordMatchValidator(form: FormGroup): ValidationErrors {
		const newPassword = form.get('newPassword');
		const confirmPassword = form.get('confirmPassword');
		if (newPassword && confirmPassword
			&& newPassword.value !== confirmPassword.value) {
			confirmPassword.setErrors({ passwordsDoNotMatch: true });
		} else {
			confirmPassword.setErrors(null);
		}
		return null;
	}

	private passwordDigitValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_DIGIT).test(control.value)) {
			return { passwordRequiresDigit: true };
		} else {
			return null;
		}
	}

	private passwordLowerValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_LOWER).test(control.value)) {
			return { passwordRequiresLower: true };
		} else {
			return null;
		}
	}

	private passwordUpperValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_UPPER).test(control.value)) {
			return { passwordRequiresUpper: true };
		} else {
			return null;
		}
	}

	private passwordSpecialValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_SPECIAL).test(control.value)) {
			return { passwordRequiresNonAlphanumeric: true };
		} else {
			return null;
		}
	}

	private passwordLengthValidator(control: AbstractControl) {
		if (control.value && !new RegExp(RegexUtils.PASSWORD_LENGTH).test(control.value)) {
			return { passwordRequiresLengthSixOrMore: true };
		} else {
			return null;
		}
	}

	private changeCurrentPasswordValidator(form: FormGroup): ValidationErrors {
		const currentPassword = form.get('password');
		const newPassword = form.get('newPassword');
		const confirmPassword = form.get('confirmPassword');

		if (currentPassword.value && (!newPassword.value || !confirmPassword.value)) {
			if (!newPassword.value) {
				newPassword.setErrors({ required: true })
				newPassword.markAsTouched();
			}
			if (!confirmPassword.value) {
				confirmPassword.setErrors({ required: true })
				confirmPassword.markAsTouched();
			}
		} else if (newPassword.value && (!currentPassword.value || !confirmPassword.value)) {
			if (!currentPassword.value) {
				currentPassword.setErrors({ required: true })
				currentPassword.markAsTouched();
			}
			if (!confirmPassword.value) {
				confirmPassword.setErrors({ required: true })
				confirmPassword.markAsTouched();
			}
		} else if (confirmPassword.value && (!currentPassword.value || !newPassword.value)) {
			if (!currentPassword.value) {
				currentPassword.setErrors({ required: true })
				currentPassword.markAsTouched();
			}
			if (!newPassword.value) {
				newPassword.setErrors({ required: true })
				newPassword.markAsTouched();
			}
		} else if (!currentPassword.value && !newPassword.value && !confirmPassword.value) {
			currentPassword.setErrors(null);
			newPassword.setErrors(null);
			confirmPassword.setErrors(null);
		}
		return null;
	}

	private setSelectableStatusItems() {
		this.selectableStatusItems = [
			new FormSelectItem(true, this.translationService.instant(StringUtils.ACTIVE)),
			new FormSelectItem(false, this.translationService.instant(StringUtils.INACTIVE)),
		];
	}

	private setSelectableEmailItems() {
		this.selectableEmailItems = this.changeModel.emails.map(email => new FormSelectItem(email, email));
	}
}
