import { Component } from '@angular/core';
import { SimpleReadModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-read-modal.directive';
import { Assignment } from 'app-inspection/assignment/assignment';

@Component({
	templateUrl: './view-assignments.component.html',
	styleUrls: ['.//view-assignments.component.less']
})
export class ViewAssignmentsModalComponent extends SimpleReadModalDirective<Assignment> {
	assignments: Assignment[] = [];
	property: keyof Assignment;
	firstLabel: string;
	secondLabel: string;
	thirdLabel: string;

	get titleText() {
		return this.assignments[0].isMeasureAssignment() ? 'MeasureAssignments' : 'ScheduledAssignments';
	}
}
