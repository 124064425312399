import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { User, UserManager } from 'oidc-client';
import { Observable } from 'rxjs';
import { BackendResponse } from './abstract-components/service/base.service';
import { HostedHttpMethod, HostedHttpRequest } from './hosted-http-request';
import { HostedHttpClientServiceMiddleware } from './hosted-httpclient-middleware';
import { StringUtils } from './tools/string-utils';
import { UrlUtils } from './tools/url-utils';

const DEFAULT_CONTENT_TYPE = 'application/json';

export const REQUEST_HEADERS = {
	Authorization: 'Authorization',
	ContentType: 'Content-Type',
	Accept: 'Accept'
};

/**
 * A generic service that wraps all API calls.
 * Appends the authorization header to every request and handles eventual errors.
 */
@Injectable()
export class HostedHttpClientService {
	mgr: UserManager;
	lastRequestMoment: number;
	lastRequestJSON: string;
	loggedInUserUM: User = {} as User;
	loggedIn: any;
	selectedOrganizationId: number;
	selectedOrganizationParentId: number;
	private coreApi = UrlUtils.API_CORE_URL; // Core
	private inspectionApi = UrlUtils.API_INSPECTION_URL; // Inspection
	private middleware = new Array<HostedHttpClientServiceMiddleware>(); // Checks http requests. This can be used (for example) to check for additional info properties in responses.

	headers: HttpHeaders;

	public get router(): Router { // this creates router property on your service.
		return this.injector.get(Router);
	}

	constructor(
		private http: HttpClient,
		private injector: Injector) { }

	add(middleware: HostedHttpClientServiceMiddleware) {
		this.middleware.push(middleware);
	}

	getObs<T>(endpoint: string, params: Object): Observable<T> {
		return this.send(new HostedHttpRequest({
			method: HostedHttpMethod.GET,
			endpoint,
			params,
			options: {
				observe: 'response'
			},
			asObservable: true,
		})) as Observable<T>;
	}

	async get(endpoint: string, params: Object, useCoreApi?: boolean): Promise<BackendResponse> {
		if (params && params['organization'] !== this.selectedOrganizationParentId) {
			params['organization'] = this.selectedOrganizationId;
		}

		return this.send(new HostedHttpRequest({
			method: HostedHttpMethod.GET,
			endpoint,
			params,
			options: {
				observe: 'response'
			},
			useCoreApi: useCoreApi
		}));
	}

	async fetch(url: string, configuration: {}): Promise<any> {
		const response = await fetch(url, configuration);

		if (!response.ok) {
			throw new Error(`Error! Status: ${response.status}`);
		}

		return await response.json();
	}

	async post(endpoint: string, data: Object, useCoreApi?: boolean): Promise<BackendResponse> {
		return this.send(new HostedHttpRequest({
			method: HostedHttpMethod.POST,
			endpoint,
			data,
			useCoreApi: useCoreApi
		}));
	}

	async postWithHeaders(endpoint: string, data: Object, headers: Object, useCoreApi?: boolean): Promise<BackendResponse> {
		return this.send(new HostedHttpRequest({
			method: HostedHttpMethod.POST,
			endpoint,
			data,
			headers,
			useCoreApi: useCoreApi
		}));
	}

	async postWithResponseType(endpoint: string, data: Object, responseType: string, useCoreApi?: boolean): Promise<BackendResponse> {
		return this.send(new HostedHttpRequest({
			method: HostedHttpMethod.POST,
			endpoint,
			data,
			options: {
				responseType
			},
			useCoreApi: useCoreApi
		}));
	}

	async put(endpoint: string, data: Object, useCoreApi?: boolean): Promise<BackendResponse> {
		return this.send(new HostedHttpRequest({
			method: HostedHttpMethod.PUT,
			endpoint,
			data,
			useCoreApi: useCoreApi
		}));
	}

	async putWithHeaders(endpoint: string, data: Object, headers: Object, useCoreApi?: boolean): Promise<BackendResponse> {
		return this.send(new HostedHttpRequest({
			method: HostedHttpMethod.PUT,
			endpoint,
			data,
			headers,
			useCoreApi: useCoreApi
		}));
	}

	async delete(endpoint: string, data: Object, useCoreApi?: boolean): Promise<BackendResponse> {
		return this.send(new HostedHttpRequest({
			method: HostedHttpMethod.DELETE,
			endpoint,
			options: {
				body: data
			},
			useCoreApi: useCoreApi
		}));
	}

	async deleteWithParams(endpoint: string, params: Object, useCoreApi?: boolean): Promise<BackendResponse> {
		return this.send(new HostedHttpRequest({
			method: HostedHttpMethod.DELETE,
			endpoint,
			params,
			useCoreApi: useCoreApi
		}));
	}

	send(originalRequest: HostedHttpRequest): Promise<any> | Observable<any> { // todo typa också?
		this.maybePreventRequest(originalRequest);
		const makeRequest = (request: HostedHttpRequest) => {
			const url = this.buildFullEndpoint(request.endpoint, request.useCoreApi);
			const options = {
				params: request.params ?? {},
				headers: this.getRequestHeaders(request.headers ?? {}),
				...(request.options ?? {})
			};

			let response: Observable<any>;

			switch (request.method) {
				case HostedHttpMethod.GET:
					response = this.http.get<Response>(url, options);
					break;
				case HostedHttpMethod.POST:
					response = this.http.post(url, request.data, options);
					break;
				case HostedHttpMethod.PUT:
					response = this.http.put(url, request.data, options);
					break;
				case HostedHttpMethod.DELETE:
					response = this.http.delete(url, options);
					break;
				default:
					throw new TypeError(`${request.method} is not a ${HostedHttpMethod}`);
			}

			if (request.asObservable) {
				return response;
			} else {
				if (request.method === HostedHttpMethod.GET) {
					return response.toPromise()
						.then(r => r['body']);
				} else {
					return response.toPromise();
				}
			}
		};

		if (originalRequest.asObservable) {
			return makeRequest(originalRequest);
		} else {
			return this.withMiddleware(originalRequest, async (request) => makeRequest(request));
		}
	}

	maybePreventRequest(request: HostedHttpRequest) {
		const requestJSON = JSON.stringify(request);
		const currentMoment = moment().valueOf();

		if (requestJSON === this.lastRequestJSON && currentMoment - this.lastRequestMoment < 0.5) {
			throw StringUtils.DOUBLE_CLICK_ERROR;
		}

		this.lastRequestJSON = requestJSON;
		this.lastRequestMoment = currentMoment;
	}

	// Using middleware to be able to check for additionalInfo properies in responses. This will be used for all CRUD where additionalInfo is necessary.
	private async withMiddleware(request: HostedHttpRequest, func: (request: HostedHttpRequest) => Promise<Object>): Promise<Object> {
		let currentResult;
		try {
			currentResult = await func(request);
			for (const middleware of this.middleware) {
				currentResult = await middleware.handleHttpResponse(request, currentResult, (newRequest: HostedHttpRequest) => this.withMiddleware(newRequest, func));
			}
		} catch (e) {
			currentResult = e;
			if (this.middleware.length !== 0) {
				for (const middleware of this.middleware) {
					currentResult = await middleware.handleHttpError(currentResult, request, (newRequest: HostedHttpRequest) => this.withMiddleware(newRequest, func));
				}
			} else {
				throw e;
			}
		}
		return currentResult;
	}

	private buildFullEndpoint(endpoint: any, useCoreApi: boolean) {
		const server = useCoreApi ? this.coreApi : this.inspectionApi;
		return `${server}${endpoint}`;
	}

	private getRequestHeaders(headers: any) {
		const newHeaders = Object.assign({
			'Accept': DEFAULT_CONTENT_TYPE,
			'Content-Type': DEFAULT_CONTENT_TYPE,
			'Authorization': ''
		});

		if (headers && headers.ContentType && headers.ContentType === 'upload-file') {
			delete newHeaders['Content-Type'];
		}

		// Set access_token
		if (this.loggedIn) {
			newHeaders[REQUEST_HEADERS.Authorization] = this.loggedInUserUM.token_type + ' ' + this.loggedInUserUM.access_token;
		} else if (!UrlUtils.isInvitationRoute()) {
			if (this.mgr) {
				this.mgr.signinRedirect();
			}
		}
		return newHeaders;
	}

	/**
	 * File upload: uses a XHR directly so it is possible to track upload progress.
	 * @param endpoint
	 * @param formData
	 */
	upload(endpoint: any, formData: FormData, useCoreApi?: boolean): Observable<BackendResponse> {
		const url = this.buildFullEndpoint(endpoint, useCoreApi);

		return Observable.create(observer => {
			const headers = this.getRequestHeaders({});
			const xhr = new XMLHttpRequest();

			// Publish IN PROGRESS (notify upload progress change)
			xhr.upload.onprogress = (event) => {
				observer.next({
					done: false,
					progress: event.loaded / event.total * 100
				});
			};

			// Publish DONE
			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						observer.next({
							done: true,
							response: xhr.response
						});
						observer.complete();
					} else {
						observer.error(xhr.response);
					}
				}
			};

			xhr.open('POST', url, true);
			xhr.setRequestHeader(REQUEST_HEADERS.Authorization, headers[REQUEST_HEADERS.Authorization]);
			xhr.setRequestHeader(REQUEST_HEADERS.Accept, headers[REQUEST_HEADERS.Accept]);
			xhr.send(formData);

			return xhr;
		});
	}
}
