import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UrlUtils } from 'app-core/shared-core/tools/url-utils';
import { AuthService } from 'app-core/auth/auth.service';
import { ErrorTypeKey } from './error-type';

@Component({
	selector: 'error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit {

	errorTypeKey = ErrorTypeKey;
	translateParam: any;

	@Input() errorType: ErrorTypeKey = ErrorTypeKey.NotFound;

	constructor(
		private authService: AuthService,
		private router: Router) { }

	ngOnInit() {
		this.setTranslateParameters();
	}

	setTranslateParameters() {
		const defaultEmailAddress = 'support@inquinova.com';
		const defaultCountryCode = '46';
		const defaultPhoneNumber = '0480-50016';

		const selectedOrganization = this.authService.selectedOrganization;

		this.translateParam = {
			email: selectedOrganization.emailAddress ? selectedOrganization.emailAddress : defaultEmailAddress,
			countryCode: selectedOrganization.countryCode ? selectedOrganization.countryCode : defaultCountryCode,
			phoneNumber: selectedOrganization.phoneNumber ? selectedOrganization.phoneNumber : defaultPhoneNumber
		};
	}

	handleRedirectClick() {
		this.router.navigate([`/${UrlUtils.DEFAULT_REDIRECT}`]);
	}
}
