<div class="media-widget">
	<div class="media-widget-heading" *ngIf="!viewOnly && !useHeaderInBottom">
		<input #fileInput type="file"
			class="u-display-none"
			[multiple]="allowMultipleUpload"
			[accept]="acceptedMediaTypes"
			(change)="handleFileChange($event)" />

		<div class="info-text" *ngIf="infoText"
			[title]="infoText">
				*{{ infoText }}
		</div>

		<button type="button" class="btn btn-transparent w-border u-uppercase" *ngIf="!hideAddButton"
			title="{{ 'AddMedia' | translate }}"
			[disabled]="disabled"
			(click)="disabled ? null : fileInput.click()">
				{{ 'AddMedia' | translate }}
		</button>
	</div>

	<div id="{{ mediaWidgetBodyId }}" class="media-widget-body apx-scrollbar"
		[ngClass]="{
			'disabled': disabled
		}"
		(dragover)="handleDragOver($event)"
		(dragleave)="handleDragLeave($event)"
		(drop)="handleFileDrop($event)">
			<div class="ml-list" *ngIf="collection.length">
				<media-widget-item #widgetItem *ngFor="let item of collection; trackBy: trackByFn"
					[item]="item"
					[canToggleDefault]="canToggleDefault"
					[viewOnly]="viewOnly || disabled"
					[itemSize]="itemSize"
					(onOpen)="openItem($event)"
					(onToggleDefault)="toggleDefault($event)"
					(onDownload)="downloadItem($event)"
					(onRemove)="removeItem($event)">
				</media-widget-item>
			</div>

			<div class="ml-empty" *ngIf="!collection.length">
				{{ modifiedDummyText ? modifiedDummyText : 'NoMediaAdded' | translate }}
			</div>

			<div #dropHelper class="filedrop-helper">
				<img width="68" height="68" src="{{ media.MISC_UPLOAD}}" />
			</div>
	</div>

	<div class="media-widget-heading bottom" *ngIf="!viewOnly && useHeaderInBottom">
		<input #fileInput type="file"
			class="u-display-none"
			[multiple]="allowMultipleUpload"
			[accept]="acceptedMediaTypes"
			(change)="handleFileChange($event)" />

		<div class="info-text" *ngIf="infoText"
			[title]="infoText">
				*{{ infoText }}
		</div>

		<button type="button" class="btn btn-transparent w-border u-uppercase" *ngIf="!hideAddButton"
			title="{{ 'AddMedia' | translate }}"
			[disabled]="disabled"
			(click)="disabled ? null : fileInput.click()">
				{{ 'AddMedia' | translate }}
		</button>
	</div>
</div>
