import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRoute } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {
	TranslateLoader,
	TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'environments/environment';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import 'scroll-shadow-element';
import { AdministrationModule } from './administration/administration.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './auth/auth.service';
import { ErrorModule } from './error/error.module';
import { FeedbackModule } from './feedback/feedback.module';
import { InitModule } from './init.module';
import { ExtensiveMediaModule } from './media/extensive-image/extensive-media.module';
import { MediaModule } from './media/media-module';
import { OrganizationModule } from './organization/organization.module';
import { ServicesModule } from './services.module';
import { AdditionalInfoModule } from './shared-core/additional-info/additional-info.module';
import { DirectivesModule } from './shared-core/directives/directives.module';
import { DotsLoaderModule } from './shared-core/dots-loader/dots-loader.module';
import { PipesModule } from './shared-core/pipes/pipes.module';
import { UrlUtils } from './shared-core/tools/url-utils';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { HelpModule } from './user-support/help/help.module';
import { NotificationMessageModule } from './user-support/notification-message/notification-message.module';
import { UserModule } from './user/user.module';

/**
 * Load a file with translations for the current user's language
 * and enables the translate-service's instant method.
 * @param http
 */
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(
		http,
		UrlUtils.toDeployUrl('assets/i18n/'),
		'.json?cacheBuster=' + environment.cacheBusterHash);
}

/**
 * Our app. Here the main modules will be imported that we need to render the header, menu and to configure the app.
 *
 * It is here we setup the initial loader which fires before the app loads. This is essential for the app to work. We need to know
 * which user is logged in, which organization is chosen before we can determine if the user should have permission and which
 * organizationId we should use in API-requests.
 */
@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		AuthModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient, AuthService, ActivatedRoute]
			}
		}),
		ToastrModule.forRoot({
			closeButton: true,
			enableHtml: true,
			tapToDismiss: false
		}),
		ModalModule.forRoot(),
		ButtonsModule.forRoot(),
		BsDatepickerModule.forRoot(),
		BsDropdownModule.forRoot(),
		PopoverModule.forRoot(),
		TooltipModule.forRoot(),
		CollapseModule.forRoot(),
		ProgressbarModule.forRoot(),
		TabsModule.forRoot(),
		UserModule,
		OrganizationModule,
		ErrorModule,
		DotsLoaderModule,
		AdditionalInfoModule,
		InitModule,
		ServicesModule,
		PipesModule,
		MediaModule,
		NgSelectModule,
		ExtensiveMediaModule,
		AdministrationModule,
		HelpModule,
		NotificationMessageModule,
		FeedbackModule,
		DirectivesModule
	],
	declarations: [
		AppComponent,
		SideMenuComponent
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }
