<simple-crud-modal
	[titleText]="('AssignmentTemplate' | translate) + (modifiedModel?.name ? ' ─ ' + modifiedModel?.name : '')"
	[closeText]="'Close' | translate"
	[submitText]="(isEdit ? 'Update' : 'Create') | translate"
	[disableSubmit]="!everythingIsValid() || !hasUnsavedChanges()"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab
				[customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(1)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-card-text'"
							[title]="'Details' | translate"
							[helpText]="isEdit ? ('AssignmentTemplateChangeDetailsEditHelpText' | translate) : ('AssignmentTemplateChangeDetailsHelpText' | translate)">
						</simple-tab-heading>
					</ng-template>
					<change-details-tab
						[changeModel]="modifiedModel"
						(onValueChange)="handleValueChanges()">
					</change-details-tab>
			</tab>
			<tab #handleTab="tab"
				[customClass]="handleFacilitiesIsValid() ? 'valid' : handleFacilitiesHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(2)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'ii-facility'"
							[title]="'Facilities' | translate"
							[helpText]="isEdit ? ('AssignmentTemplateHandleFacilitiesEditHelpText' | translate) : ('AssignmentTemplateHandleFacilitiesHelpText' | translate)"
							[displayCount]="modifiedModel.facilities.length">
						</simple-tab-heading>
					</ng-template>
					<handle-facilities-tab
						[handleItems]="modifiedModel.facilities"
						[headerSubtitleText]="'AddedFacilitiesSubtitleAssignmentTemplate' | translate"
						[readonlyListModal]="true"
						[readonlyItems]="isEdit || !(loggedInUser | isSuperAdmin)"
						[hideHeaderAddButton]="isEdit || !(loggedInUser | isSuperAdmin)"
						[hideHeaderRemoveSelected]="isEdit || !(loggedInUser | isSuperAdmin)"
						[hideHeaderSelectAll]="isEdit || !(loggedInUser | isSuperAdmin)"
						[hideHeaderSearch]="isEdit"
						[displayOnlyFacilitiesWithPositionDataInList]="modifiedModel.geoControlled"
						[wasSelected]="handleTab.active"
						(onModelsChange)="modifiedModel.facilities = $event; setFacilityIdsAndClearEntities()">
					</handle-facilities-tab>
			</tab>
			<tab #handleTab2="tab"
				[customClass]="handleFacilityTasksIsValid() ? 'valid' : handleFacilityTasksHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(3)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'ii-task'"
							[title]="'FacilityTasks' | translate"
							[helpText]="isEdit ? ('AssignmentTemplateHandleFacilityTasksEditHelpText' | translate) : ('AssignmentTemplateHandleFacilityTasksHelpText' | translate)"
							[displayCount]="modifiedModel.facilityTasks.length">
						</simple-tab-heading>
					</ng-template>
					<handle-tasks-tab #handleFacilityTasksComponent
						[handleItems]="modifiedModel.facilityTasks"
						[headerSubtitleText]="'AddedTasksSubtitleAssignmentTemplate' | translate"
						[readonlyListModal]="true"
						[readonlyChoices]="true"
						[includeChoicesInList]="true"
						[templateTypeIdForList]="modifiedModel.templateTypeId"
						[readonlyItems]="!(loggedInUser | isSuperAdmin)"
						[hideHeaderAddButton]="!(loggedInUser | isSuperAdmin)"
						[hideHeaderRemoveSelected]="!(loggedInUser | isSuperAdmin)"
						[hideHeaderSelectAll]="!(loggedInUser | isSuperAdmin)"
						[wasSelected]="handleTab2.active"
						(onModelsChange)="modifiedModel.facilityTasks = $event">
					</handle-tasks-tab>
			</tab>
			<tab #handleTab3="tab"
				[customClass]="handleEntitiesIsValid() ? 'valid' : handleEntitiesHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(4)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'ii-entities'"
							[title]="'Entities' | translate"
							[helpText]="isEdit ? ('AssignmentTemplateHandleEntitiesEditHelpText' | translate) : ('AssignmentTemplateHandleEntitiesHelpText' | translate)"
							[displayCount]="modifiedModel.entities.length">
						</simple-tab-heading>
					</ng-template>
					<handle-entities-tab
						[handleItems]="modifiedModel.entities"
						[headerSubtitleText]="'AddedEntitiesSubtitleAssignmentTemplate' | translate"
						[readonlyListModal]="true"
						[facilityIds]="facilityIds"
						[displayFacilityNames]="!isEdit && modifiedModel.facilities.length > 1"
						[readonlyItems]="!(loggedInUser | isSuperAdmin)"
						[hideHeaderAddButton]="!(loggedInUser | isSuperAdmin)"
						[hideHeaderRemoveSelected]="!(loggedInUser | isSuperAdmin)"
						[hideHeaderSelectAll]="!(loggedInUser | isSuperAdmin)"
						[wasSelected]="handleTab3.active"
						(onModelsChange)="modifiedModel.entities = $event">
					</handle-entities-tab>
			</tab>
			<tab #handleTab4="tab"
				[customClass]="handleEntityTasksIsValid() ? 'valid' : handleEntityTasksHasErrors() ? 'invalid' : ''"
				(selectTab)="setTabIndexInUrl(5)">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'ii-task'"
							[title]="'EntityTasks' | translate"
							[helpText]="'AssignmentTemplateHandleEntityTasksHelpText' | translate"
							[displayCount]="modifiedModel.entityTasks.length">
						</simple-tab-heading>
					</ng-template>
					<handle-tasks-tab #handleEntityTasksComponent
						[handleItems]="modifiedModel.entityTasks"
						[headerSubtitleText]="'AddedTasksSubtitleAssignmentTemplate' | translate"
						[readonlyListModal]="true"
						[readonlyChoices]="true"
						[includeChoicesInList]="true"
						[templateTypeIdForList]="modifiedModel.templateTypeId"
						[readonlyItems]="!(loggedInUser | isSuperAdmin)"
						[hideHeaderAddButton]="!(loggedInUser | isSuperAdmin)"
						[hideHeaderRemoveSelected]="!(loggedInUser | isSuperAdmin)"
						[hideHeaderSelectAll]="!(loggedInUser | isSuperAdmin)"
						[wasSelected]="handleTab4.active"
						(onModelsChange)="modifiedModel.entityTasks = $event">
					</handle-tasks-tab>
			</tab>
		</tabset>
</simple-crud-modal>
