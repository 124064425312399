
import { Injectable } from '@angular/core';
import { Filter } from 'app-core/shared-core/filter';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { BaseService } from '../../app-core/shared-core/abstract-components/service/base.service';
import { TemplateBaseType, TemplateType, TemplateTypePayload } from './template-type';

@Injectable()
export class TemplateTypeService extends BaseService<TemplateType> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
		super(
			hostedHttpClient,
			RoutesUtils.templateType
		);
	}

	importFromParent(templateTypes: TemplateType[], organizationId: number) {
		const model = {
			itemIds: templateTypes.map(templateType => templateType.id),
			organizationId: organizationId
		};
		return this.hostedHttpClient.post(`${this.prefix}/copy`, model);
	}

	async getTemplateBaseTypes(shouldIncludeFlag?: boolean, organizationId?: number): Promise<TemplateBaseType[]> {
		if (shouldIncludeFlag && organizationId) {
			return this.hostedHttpClient.get(`templateBaseType?forCreatingTemplateType=true&organizationId=${organizationId}`, {})
				.then(res => res.data.map((item: TemplateBaseType) => new TemplateBaseType(item)));
		} else {
			return this.hostedHttpClient.get(`templateBaseType`, {})
				.then(res => res.data.map((item: TemplateBaseType) => new TemplateBaseType(item)));
		}
	}

	updateWithAffectedEntities(templateType: TemplateTypePayload) {
		return this.hostedHttpClient.put(`${this.prefix}/${templateType.id}/affectedentities`, templateType);
	}

	updateDescription(id: string, value: string, organizationId: number) {
		const model = {
			templateTypes: [
				{
					id: id,
					assignmentDescription: value
				}
			]
		};
		return this.hostedHttpClient.put(`${this.prefix}/assignmentdescription/${organizationId}`, model);
	}

	instantiateModel(item: TemplateType) {
		return new TemplateType(item);
	}
}

export class TemplateTypeFilter extends Filter {
	parentOrganizationId: number;

	toPayloadObject() {
		const tempFacets = {};
		const currentFacets = (Object.entries(this.facets) as [string, string][])
			.map(facet => {
				return {
					key: facet[0],
					value: facet[1]
				};
			});

		currentFacets.forEach(facet => {
			if (facet.key === StringUtils.BASE_TYPES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.TEMPLATE_BASE_TYPE_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.TEMPLATE_BASE_TYPE_IDS_KEY) {
				delete this.facets[facet.key];
			}
		});

		Object.assign(this.facets, tempFacets);

		// Exclude parentOrganizationId property from the filterObject.
		const { parentOrganizationId, ...theRest } = this;

		if (this.parentOrganizationId) {
			return {
				filter: JSON.stringify(theRest),
				organization: this.parentOrganizationId
			};
		} else {
			return {
				filter: JSON.stringify(theRest)
			};
		}
	}
}
