import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { Assignment } from 'app-inspection/assignment/assignment';
import { AssignmentService } from 'app-inspection/assignment/assignment.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	templateUrl: './handle-assignee-groups-and-assignees.component.html'
})
export class HandleAssigneeGroupsAndAssigneesComponent extends SimpleBulkEditModalDirective<Assignment> implements OnInit {

	isMeasure: boolean;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private assignmentService: AssignmentService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.pending = true;
		try {
			const assignments = await this.assignmentService.getAssigneeGroupsAndAssignees(this.editModelIds, this.selectedOrganization.id);
			this.pending = false;

			const allAssigneeGroups = assignments.flatMap(item => item.assigneeGroups);
			const allAssignees = assignments.flatMap(item => item.assignees);
			const allUniqueAssigneeGroups = [];
			const allUniqueAssignees = [];

			const map = new Map();
			for (const item of allAssigneeGroups) {
				if (!map.has(item.userGroup.id)) {
					map.set(item.userGroup.id, true);
					allUniqueAssigneeGroups.push(item);
				}
			}
			for (const item of allAssignees) {
				if (!map.has(item.user.id)) {
					map.set(item.user.id, true);
					allUniqueAssignees.push(item);
				}
			}

			this.initialModel = new Assignment({
				assigneeGroups: allUniqueAssigneeGroups,
				assignees: allUniqueAssignees,
				errorAssignmentId: this.isMeasure ? 'someIdToTriggerIsMeasureCheck' : ''
			});

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
			this.closeWithDelay();
		}
	}

	everythingIsValid() {
		return true;
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = await this.assignmentService.updateAssigneeGroupsAndAssignees(
				this.editModelIds,
				this.initialModel.assigneeGroups,
				this.initialModel.assignees,
				this.isMeasure
			);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() { }

	protected instantiateModel(item: Assignment) {
		return new Assignment(item);
	}

	hasUnsavedChanges() {
		return false;
	}
}
