import { Injectable } from '@angular/core';
import { OrganizationTreeItem } from '../organization';
import { HostedHttpClientService } from '../../shared-core/hosted-httpclient.service';
import { AuthService } from '../../auth/auth.service';

const ENDPOINTS = {
	GET_ORG_TREE_ADMIN_ONLY: (selectedOrganizationId) => `organization/${selectedOrganizationId}/userTree?adminOnly=true`,
	GET_WHOLE_ORG_TREE: (selectedOrganizationId) => `organization/${selectedOrganizationId}/userTree`,
	GET_BRANCH: (organizationId) => `organization/${organizationId}/children`
};

/**
 * API requests and help functions for handling global organization
 */
@Injectable()
export class GlobalOrganizationService {

	private treeData: OrganizationTreeItem[];

	constructor(
		private hostedHttpClient: HostedHttpClientService,
		private authService: AuthService) { }

	public hasTreeData() {
		return !!this.treeData;
	}

	public getTreeData() {
		const userSelectedOrganization = this.authService.selectedOrganization.id;

		return new Promise(resolve => {
			// No cached data: request
			if (!this.hasTreeData()) {
				this.getWholeOrgTree(userSelectedOrganization)
					.then((organizations: OrganizationTreeItem[]) => {
						this.treeData = organizations;
						resolve(null);
					});
			} else {
				resolve(null);
			}
		}).then(_ => {
			// Create a clone so changes don't affect the original array.
			return Object.assign([], this.treeData);
		});
	}

	public resetTreeData() {
		this.treeData = undefined;
	}

	public getOrgTreeAdminOnly(selectedOrganizationId: number) {
		return this.hostedHttpClient.get(ENDPOINTS.GET_ORG_TREE_ADMIN_ONLY(selectedOrganizationId), {}, true)
			.then(res => res.data)
			.then(rawOrganizations => {
				return rawOrganizations.map(organization => {
					return new OrganizationTreeItem(organization);
				});
			});
	}

	public getWholeOrgTree(selectedOrganizationId: number) {
		return this.hostedHttpClient.get(ENDPOINTS.GET_WHOLE_ORG_TREE(selectedOrganizationId), {}, true)
			.then(res => res.data)
			.then(rawOrganizations => {
				return rawOrganizations.map(organization => {
					return new OrganizationTreeItem(organization);
				});
			});
	}

	/**
	 * Requests a collection of sub-organizations for a specified organization.
	 * @param rootId The root of an organization branch
	 */
	public getTreeBranch(rootId: number) {
		return this.hostedHttpClient.get(ENDPOINTS.GET_BRANCH(rootId), {}, true)
			.then(res => res.data)
			.then(rawOrganizations => {
				return rawOrganizations.map(organization => {
					return new OrganizationTreeItem(organization);
				});
			});
	}
}
