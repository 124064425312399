import { Component, Input } from '@angular/core';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { MediaWidgetItemViewerComponent } from 'app-core/media/widget/item/viewer/media-widget-item-viewer.component';
import { SimpleHandleTabDirective } from 'app-core/shared-core/simple-components/crud/modal/tabs/handle/simple-handle-tab.directive';
import { ColorUtils } from 'app-core/shared-core/tools/color-utils';
import { ConfigUtils } from 'app-core/shared-core/tools/config-utils';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { Choice } from 'app-inspection/choice/choice';
import { Facility } from 'app-inspection/facility/facility';
import { ListFacilityComponent } from 'app-inspection/facility/list-facility/list-facility.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IssueModalFacilityComponent } from '../../issue-modal-facility/issue-modal-facility.component';

@Component({
	selector: 'handle-facility-tab',
	templateUrl: './handle-facility-tab.component.html',
	styleUrls: ['./handle-facility-tab.component.less']
})
export class HandleFacilityTabComponent extends SimpleHandleTabDirective<Facility> {

	colorUtils = ColorUtils;
	bsModalRef: BsModalRef<any>;

	@Input() displayOnlyFacilitiesWithPositionDataInList: boolean;
	@Input() useSingleSelectionInList: boolean;
	@Input() onlyActiveFacilitiesInList: boolean;
	@Input() choice: Choice;

	toggleListFacilityModal() {
		this.bsModalRef = this.modalService.show(
			ListFacilityComponent,
			{
				initialState: {
					isInModal: true,
					hideListActions: this.readonlyListModal,
					hideTableHeaderActions: this.readonlyListModal,
					hideTableRowActions: this.readonlyListModal,
					onlyFacilitiesWithPositionData: this.displayOnlyFacilitiesWithPositionDataInList,
					onlyActiveFacilities: this.onlyActiveFacilitiesInList,
					idsToSetAsSelected: this.handleItems.map(item => item.id),
					isSingleSelectTable: this.useSingleSelectionInList
				},
				...ConfigUtils.MODAL_CONFIG_XX_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	setupHover(div: HTMLDivElement) {
		const backgroundImageUrl = div.style.backgroundImage.slice(4, -1).replace(/"/g, "");
		DomUtils.showLargerImageOnHover(div, false, backgroundImageUrl);

		// Click
		const substringedImageUrl = backgroundImageUrl.substring(0, backgroundImageUrl.lastIndexOf('\/'));
		div.addEventListener('click',
			(event) => {
				event.stopPropagation();
				const item = new MediaWidgetItem({
					isImage: true,
					url: substringedImageUrl.slice(substringedImageUrl.lastIndexOf('\/') + 1)
				});
				if (this.bsModalRef) {
					this.closeModal();
				}
				setTimeout(() => {
					if (!this.bsModalRef) {
						this.bsModalRef = this.modalService.show(
							MediaWidgetItemViewerComponent,
							{
								initialState: {
									item: item,
									collection: [item]
								},
								...ConfigUtils.MODAL_CONFIG_LARGE_WITH_OUTSIDE_CLICK
							}
						);

						this.subscribeToCrudModalContent();
					}
				});
			});
	}

	itemListIsValid() {
		return this.handleItems[0]?.issueDetails.length > 0;
	}

	openFacilityIssueModal() {
		this.bsModalRef = this.modalService.show(
			IssueModalFacilityComponent,
			{
				initialState: {
					initialModel: new Facility(this.getUniqueVariant(this.handleItems[0])),
					modifiedModel: new Facility(this.getUniqueVariant(this.handleItems[0])),
					choice: this.choice,
					disableTabIndexUrl: true
				},
				...ConfigUtils.MODAL_CONFIG_LARGE
			}
		);
		this.subscribeToCrudModalContent();
	}

	openMediaTab(event: MouseEvent) {
		event.stopImmediatePropagation();
		this.openFacilityIssueModal();
		setTimeout(() => {
			(this.bsModalRef.content as IssueModalFacilityComponent).tabsetComponent.tabs[1].active = true;
		});
	}

	getXMoreParam() {
		return {
			x: this.handleItems[0].issueMedia.length - 5
		};
	}

	clearIssue() {
		this.handleItems[0].issueDetails = '';
		this.handleItems[0].issueMedia = [];
		this.onUpdated.emit(this.handleItems);
	}

	override handleUpdated(items: Facility[]) {
		this.onUpdated.emit(items);
		// this.validate();
		this.closeModal();
	}

	protected override getUniqueVariant(data: Facility) {
		const { issueMedia, ...theRest } = data;
		const stringified = JSON.stringify(theRest);
		const object = JSON.parse(stringified);
		object.issueMedia = issueMedia.slice();
		return object;
	}

	override emitModelsChange() {
		this.handleItems.sortByProperty(this.propertyStrings.name);
		super.emitModelsChange();
	}

	protected validate() { }

	getItems() {
		return this.handleItems;
	}
}
