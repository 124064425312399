import { ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Utils } from 'app-core/shared-core/tools/utils';
import { SimpleTextContentComponent, TextContent } from '../../../various/text-content/simple-text-content.component';
import { SimpleTableColumn } from './simple-table-column';

export class SimpleTableTextColumn<T> extends SimpleTableColumn<T> {

	readonly getTextContent: (item: T) => TextContent;

	constructor(
		label: string,
		getTextContent: (item: T) => TextContent,
		sanitizer: DomSanitizer,
		viewContainerRef: ViewContainerRef,
		secondaryLabel: string,
		maxWidth: string = null) {
		super(
			label,
			sanitizer,
			viewContainerRef,
			secondaryLabel
		);
		this.getTextContent = getTextContent;
		this.maxWidth = maxWidth;
	}

	renderValue(item: T) {
		const componentRef = this.viewContainerRef.createComponent(SimpleTextContentComponent);
		const textContent = this.getTextContent(item);
		componentRef.setInput('textContent', textContent);
		componentRef.changeDetectorRef.detectChanges();
		this.viewContainerRef.clear();
		const textContentDiv = componentRef.location.nativeElement.childNodes[0] as HTMLDivElement;

		if ((textContent.mainText && textContent.mainUrl) || (textContent.secondaryText && textContent.secondaryUrl)) {
			const id = Utils.getRandomNumberString();
			textContentDiv.id = id;

			setTimeout(() => {
				const parent = document.getElementById(id);
				if (textContent.mainText && textContent.mainUrl) {
					const element = parent?.children[0];
					if (element) {
						element.addEventListener('click', (event: any) => {
							event.stopImmediatePropagation();
							window.open(textContent.mainUrl, '_blank');
						});
					}
				}

				if (textContent.secondaryText && textContent.secondaryUrl) {
					const element = parent?.children[1];
					if (element) {
						element.addEventListener('click', (event: any) => {
							event.stopImmediatePropagation();
							window.open(textContent.secondaryUrl, '_blank');
						});
					}
				}
			}, 0);
		}

		return this.sanitizer.bypassSecurityTrustHtml(textContentDiv.outerHTML);
	}
}


