import { Injectable } from '@angular/core';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { delay, distinctUntilChanged, filter, repeatWhen, switchMap, takeUntil } from 'rxjs/operators';
import {
	NewDataNotification,
	ReferenceTypes,
	SpanTypes,
	StatisticsAssignments,
	StatisticsByDataReloadData,
	StatisticsOrganizations,
	StatisticsPayload,
	StatisticsTypes,
	StatisticsUsers
} from './statistics';

@Injectable()
export class StatisticsService {

	private _newData$: BehaviorSubject<NewDataNotification[]> =
		new BehaviorSubject<NewDataNotification[]>([]);
	// private _subscriptions = new Subscription();
	private _hideAndRemoveSubscriptions = new Subscription();
	private _destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	private _pauseResume$ = new Subject<boolean>();

	public statisticsRelatedData$ = new BehaviorSubject<StatisticsAssignments | StatisticsOrganizations | StatisticsUsers>(null);
	public error$ = new BehaviorSubject<string>(null);
	refreshing$ = new BehaviorSubject<boolean>(false);
	public refreshingDone$ = new BehaviorSubject<boolean>(false);
	public statisticsByDateReload$ = new BehaviorSubject<StatisticsByDataReloadData>(
		new StatisticsByDataReloadData('none')
	);

	private hideDelay = 7500;
	private removeDelay = 10000;

	constructor(
		private hostedHttpClient: HostedHttpClientService) {
	}

	updateDelayOnStatisticsNewData() {
		const currentValue = this._newData$.value;

		if (currentValue?.some(o => o.class === 'in')) {
			this._pauseResume$.next(true);
		}
	}

	initializeNewDataNotifications() {
		this.resetAndUnsubscribeNewDataNotifications();

		this._pauseResume$.next(false);
		const newDataObservable = this.getNewData();
		const paused$ = this._pauseResume$.pipe(
			takeUntil(this._destroyed$),
			filter(o => o));
		const resumed$ = this._pauseResume$.pipe(
			takeUntil(this._destroyed$),
			filter(o => !o));

		this._hideAndRemoveSubscriptions = new Subscription();
		this._hideAndRemoveSubscriptions.add(
			newDataObservable
				.pipe(
					distinctUntilChanged(),
					delay(this.hideDelay),
					filter(this.newData('in')),
					takeUntil(this._destroyed$),
					takeUntil(paused$),
					repeatWhen(_ => resumed$)
				)
				.subscribe(state => {
					state.forEach(o => {
						o.class = 'out';
					});

					this.setNewDataNotifications(state);
				})
		);

		this._hideAndRemoveSubscriptions.add(
			newDataObservable
				.pipe(
					distinctUntilChanged(),
					delay(this.removeDelay),
					filter(this.newData('out')),
					takeUntil(this._destroyed$),
					takeUntil(paused$),
					repeatWhen(_ => resumed$)
				)
				.subscribe(state => {
					state.forEach(o => {
						o.value = 0;
					});

					this.setNewDataNotifications(state);
					this.resetNewDataNotificationStatus();
				})
		);
	}

	getRelatedDataObs(payload?: StatisticsPayload, forceRefresh: boolean = false)
		: Observable<StatisticsAssignments | StatisticsOrganizations | StatisticsUsers> {
		const shouldFetchNew = !!(!this.statisticsRelatedData$.value
			|| (payload && !Utils.isEqual(this.statisticsRelatedData$.value.payload, payload)));

		if (shouldFetchNew || forceRefresh) {
			this.statisticsRelatedData$.next(null);

			if (payload) {
				this.getAssignmentRelatedData(payload);
			} else if (payload.discriminator.toString() === ReferenceTypes[ReferenceTypes.Organization]) {
				this.getOrganizationRelatedData(payload);
			} else if (payload.discriminator.toString() === ReferenceTypes[ReferenceTypes.User]) {
				this.getUserRelatedData(payload);
			}
		}

		return this.statisticsRelatedData$
			.asObservable()
			.pipe(
				distinctUntilChanged(),
				filter(o => !!o),
				switchMap(_ => this.statisticsRelatedData$));
	}

	resetRelatedData() {
		this.statisticsRelatedData$.next(null);
	}

	getNewData(): Observable<NewDataNotification[]> {
		return this._newData$
			.asObservable()
			.pipe(
				distinctUntilChanged(),
				filter(this.newData()),
				switchMap(_ => this._newData$)
			);
	}

	setNewDataNotifications(data: NewDataNotification[]) {
		this._newData$.next(data);
	}

	resetStatisticsNewData() {
		this._newData$.next(null);
	}

	resetNewDataNotificationStatus() {
		this._pauseResume$.next(null);
	}

	private resetAndUnsubscribeNewDataNotifications() {
		this.resetNewDataNotificationStatus();
		this._hideAndRemoveSubscriptions.unsubscribe();
	}

	private newData(state?: 'in' | 'out') {
		const classCheck = (p: NewDataNotification) => state
			? p.class === state
			: true;

		return (o: NewDataNotification[]) => o?.some(p =>
			p.value > 0 && classCheck);
	}

	getStatistics(isRefresh: boolean) {
		return this.hostedHttpClient.get(StringUtils.STATISTICS, { refresh: isRefresh });
	}

	getAssignmentRelatedData(payload: StatisticsPayload): Promise<StatisticsAssignments> {
		const payloadAsStrings = {
			discriminator: payload.discriminator,
			type: StatisticsTypes[payload.type],
			span: SpanTypes[payload.span]
		};
		return this.hostedHttpClient.get(`${RoutesUtils.statistics}/${RoutesUtils.assignment}`, { stat: JSON.stringify(payloadAsStrings) })
			.then(res => res.data);
	}

	getOrganizationRelatedData(payload: StatisticsPayload): Promise<StatisticsOrganizations> {
		const payloadAsStrings = {
			span: SpanTypes[payload.span]
		};
		return this.hostedHttpClient.get(`${RoutesUtils.statistics}/${RoutesUtils.organization}`, { stat: JSON.stringify(payloadAsStrings) })
			.then(res => res.data);
	}

	getUserRelatedData(payload: StatisticsPayload): Promise<StatisticsUsers> {
		const payloadAsStrings = {
			span: SpanTypes[payload.span]
		};
		return this.hostedHttpClient.get(`${RoutesUtils.statistics}/${RoutesUtils.user}`, { stat: JSON.stringify(payloadAsStrings) })
			.then(res => res.data);
	}

	unsubscribeRelatedData() {
		this._destroyed$.next(true);
		this._destroyed$.complete();
		this.statisticsRelatedData$.complete();
		this.error$.complete();
		this.refreshing$.complete();
		this.statisticsByDateReload$.complete();
		// this._subscriptions.unsubscribe();
	}
}
