<div class="wrapper">
	<div class="header">
		<div class="details">
			<div class="primary"
				[title]="'Administration' | translate">
				{{ 'Administration' | translate }}
			</div>
			<div class="secondary"
				[title]="'Här kan vi på Inquinova få översikt eller utföra administrativa åtgärder i tjänsten'">
					{{ 'Här kan vi på Inquinova få översikt eller utföra administrativa åtgärder i tjänsten' }}
			</div>
		</div>
	</div>
	<div class="main apx-scrollbar" scrollShadow>
		<div class="column">
			<div class="section">
				<div class="section-header">
					{{ 'Statistics' | translate }}
				</div>
				<div class="section-body">
					<statistics></statistics>
				</div>
			</div>
		</div>
		<div class="column">
			<div class="section">
				<div class="section-header">
					{{ 'Assignments' | translate }}
				</div>
				<div class="section-body">
					<div class="action-buttons"
						[ngClass]="{
							'loadmask': pending
						}">
							<button type="button"
								class="btn btn-transparent"
								[title]="'RecreateAssignmentReports' | translate"
								(click)="openRecreateSwal()">
									{{ 'RecreateAssignmentReports' | translate }}
									<div class="icon bi bi-wrench-adjustable"></div>
							</button>
							<dots-loader *ngIf="pending"></dots-loader>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>





