<simple-handle-tab
	[headerTitleText]="'SelectedFacility' | translate"
	[headerSubtitleText]="headerSubtitleText"
	[headerAddButtonText]="handleItems.length ? ('ChangeFacility' | translate) : ('SelectFacility' | translate)"
	[hideRemoveSelected]="true"
	[hideSelectAll]="true"
	[hideSearch]="true"
	(onAddButtonClick)="toggleListFacilityModal()">
		<ng-container slot="itemList">
			<cdk-virtual-scroll-viewport itemSize="{{ itemSize }}" minBufferPx="999" maxBufferPx="1000" class="apx-scrollbar" scrollShadow>
				<simple-item-extended *cdkVirtualFor="let item of handleItems; let index = index; cdkVirtualForTrackBy: trackByFn"
					[item]="item"
					[noBorder]="index === handleItems.length - 1"
					[hideSelectButton]="true"
					[isValid]="!item.errorMessages.length && item.issueDetails.length"
					[url]="(loggedInUser | canEdit: selectedOrganization.friendlyUrl) ? item.url : ''"
					[name]="item.name"
					(onRemove)="remove($event)">
						<ng-container slot="content">
							<div class="content-wrapper">
								<div class="details-prio-wrapper">
									<div class="image-details-wrapper">
										<div class="item-image" #parentDiv>
											<extensive-image
												[imageUrl]="item.thumbnailUrl"
												[imageStorageSize]="parentDiv | getImageStorageSize"
												(onImageLoaded)="setupHover($event)">
											</extensive-image>
										</div>
										<div class="details">
											<div class="name" title="{{ item.name }}" *ngIf="item.name">
												{{ item.name }}
											</div>
											<div class="description" title="{{item.description}}" *ngIf="item.description">
												{{ item.description }}
											</div>
										</div>
									</div>
									<div class="icons">
										<div class="bi bi-person-wheelchair" *ngIf="item.accessible"
											title="{{ 'Accessibility' | translate }}">
										</div>
										<div class="bi bi-geo-alt-fill" *ngIf="item.hasPosition"
											title="{{ 'LocationData' | translate }}">
										</div>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-container slot="contentExtra">
							<div class="issue-section"
								title="{{'Open' | translate}}"
								(click)="openFacilityIssueModal()">
								<div class="issue-wrapper">
									<div class="issue-details-wrapper">
										<div class="label">
											{{ 'IssueDetails' | translate }}
										</div>
										<div class="content"
											[ngClass]="{
												'dummy-text': !item.issueDetails
											}">
											{{ item.issueDetails
												? item.issueDetails
												: ('NoIssueDetailsAdded' | translate) }}
										</div>
									</div>
									<div class="issue-media-wrapper">
										<div class="label">
											{{ 'IssueMedia' | translate }}
										</div>
										<div class="content media" *ngIf="item.issueMedia.length"
											(click)="openMediaTab($event)">
											<div class="image" #parentDiv *ngFor="let image of item.issueMedia | slice:0:5">
												<extensive-image
													[imageStorageSize]="parentDiv | getImageStorageSize"
													[blob]="$any(image).blob">
												</extensive-image>
											</div>
											<div *ngIf="item.issueMedia.length > 5">
												{{ 'AndXMore' | translate: getXMoreParam() }}
											</div>
										</div>
										<div class="content dummy-text" *ngIf="!item.issueMedia.length">
											{{ 'NoIssueMediaAdded' | translate }}
										</div>
									</div>
									<div class="icons" *ngIf="item.priority || item.geoControlled">
										<div class="bi bi-arrow-up" *ngIf="item.priority"
											title="{{ 'Priority' | translate }}">
										</div>
										<div class="bi bi-bounding-box-circles" *ngIf="item.geoControlled"
											title="{{ 'Geofencing' | translate }}">
										</div>
									</div>
								</div>
								<div class="actions">
									<div class="action bi-eraser-fill" *ngIf="item.issueDetails"
										title="{{'Clear' | translate}}"
										(click)="$event.stopImmediatePropagation(); clearIssue()">
									</div>
									<div class="action bi-pencil"
										title="{{'Open' | translate}}">
									</div>
								</div>
							</div>
						</ng-container>
					</simple-item-extended>
				<simple-no-items *ngIf="!handleItems.length && !searchValue"
					[title]="'NoFaciliySelected' | translate">
				</simple-no-items>
				<simple-no-items *ngIf="!getItems().length && searchValue"
					[title]="'NoResultsMatchingSearch' | translate">
				</simple-no-items>
			</cdk-virtual-scroll-viewport>
		</ng-container>
</simple-handle-tab>