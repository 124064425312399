<simple-change-bulk-tab
	[form]="form"
	[selectedIdsCount]="selectedIdsCount">
		<div class="change-bulk-section">
			<div class="change-bulk-section-header">
				{{ 'General' | translate }}
			</div>
			<simple-form-select
				[label]="'Status' | translate"
				[name]="propertyStrings.status"
				[form]="form"
				[items]="selectableStatusItems"
				[serverErrors]="serverErrors">
			</simple-form-select>

			<simple-form-input *ngIf="changeModel.status !== stringUtils.ACTIVE && changeModel.status !== stringUtils.STARTED"
				[label]="'Notes' | translate"
				[name]="propertyStrings.notes"
				[form]="form"
				[maxlength]="500"
				[serverErrors]="serverErrors"
				[useTextarea]="true">
			</simple-form-input>
		</div>
</simple-change-bulk-tab>
