import { Injectable } from '@angular/core';
import { BaseService } from 'app-core/shared-core/abstract-components/service/base.service';
import { Filter, KeyValuePair, SortDirection } from 'app-core/shared-core/filter';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { StringUtils } from 'app-core/shared-core/tools/string-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { UserGroup } from 'app-core/user-group/user-group';
import { User } from 'app-core/user/user';
import { AssignmentTemplate } from 'app-inspection/assignment-template/assignment-template';
import { TemplateType } from 'app-inspection/template-type/template-type';
import { Schedule, ScheduleGroup, ScheduleUser } from './schedule';

@Injectable()
export class ScheduleService extends BaseService<Schedule> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
		super(
			hostedHttpClient,
			RoutesUtils.schedule
		);
	}

	getStatusData(itemIds: string[], active: boolean) {
		const model = {
			itemIds: itemIds,
			active: active
		};
		return this.hostedHttpClient.post(`${this.prefix}/updatestatusdata`, model);
	}

	getScheduleGroupsAndScheduleUsers(itemIds: string[], organizationId: number): Promise<Schedule[]> {
		const model = {
			itemIds: itemIds
		};
		return this.hostedHttpClient.post(`${this.prefix}/scheduleusers?organization=${organizationId}`, model)
			.then(res => res.data.map((item: Schedule) => this.instantiateModel(item)));
	}

	updateScheduleGroupsAndScheduleUsers(itemIds: string[], scheduleGroups: ScheduleGroup[], scheduleUsers: ScheduleUser[]) {
		const model = {
			schedules: []
		};
		itemIds.forEach(id => {
			model.schedules.push({
				id: id,
				scheduleUsers: scheduleUsers.map(user => {
					return {
						userId: user.userId,
						canPerformMeasure: user.canPerformMeasure,
						canPerformScheduled: user.canPerformScheduled,
						canAssign: user.canAssign
					};
				}),
				scheduleGroups: scheduleGroups.map(group => {
					return {
						userGroupId: group.userGroupId,
						canPerformMeasure: group.canPerformMeasure,
						canPerformScheduled: group.canPerformScheduled,
						canAssign: group.canAssign
					};
				})
			});
		});
		return this.hostedHttpClient.put(`${this.prefix}/scheduleusers`, model);
	}

	getPublicationDates(item: Schedule): Promise<string[]> {
		const model = {
			startDate: item.startDate,
			endDate: item.endDate,
			publishingTime: item.publishingTime,
			weeklyInterval: item.weeklyInterval,
			monthlyInterval: item.monthlyInterval,
			annualInterval: item.annualInterval,
			weekdays: item.weekdays
		};
		return this.hostedHttpClient.post(`${this.prefix}/publicationdates`, model)
			.then(res => res.data.publishDates);
	}

	getAssignmentTemplates(organizationId: number): Promise<AssignmentTemplate[]> {
		const filter = new Filter();
		filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		filter.addSort(
			new KeyValuePair(
				Utils.uppercaseEachFirst(new TemplateType({}).propertyStrings.name),
				SortDirection.Ascending
			)
		);
		return this.hostedHttpClient.get(`assignmenttemplate?organization=${organizationId}`, filter.toPayloadObject())
			.then(res => res.data.map((item: AssignmentTemplate) => new AssignmentTemplate(item)));
	}

	getUserGroups(organizationId: number): Promise<UserGroup[]> {
		const filter = new Filter();
		filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		filter.addSort(
			new KeyValuePair(
				Utils.uppercaseEachFirst(Utils.nameof<UserGroup>('name')),
				SortDirection.Ascending
			)
		);
		return this.hostedHttpClient.get(`usergroup?organization=${organizationId}`, filter.toPayloadObject(), true)
			.then(res => res.data.map((item: UserGroup) => new UserGroup(item)));
	}

	getUsers(organizationId: number): Promise<User[]> {
		const filter = new Filter();
		filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
		filter.addSort(
			new KeyValuePair(
				Utils.uppercaseEachFirst(Utils.nameof<User>('firstname')),
				SortDirection.Ascending
			)
		);
		return this.hostedHttpClient.get(`user?organization=${organizationId}`, filter.toPayloadObject(), true)
			.then(res => res.data.map((item: User) => new User(item)));
	}

	instantiateModel(item: Schedule) {
		return new Schedule(item);
	}
}

export class ScheduleFilter extends Filter {
	toPayloadObject() {
		const tempFacets = {};
		const currentFacets = (Object.entries(this.facets) as [string, string][])
			.map(facet => {
				return {
					key: facet[0],
					value: facet[1]
				};
			});

		currentFacets.forEach(facet => {
			if (facet.key === StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.ASSIGNMENT_TEMPLATES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.ASSIGNMENT_TEMPLATE_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.ASSIGNMENT_TEMPLATE_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.HAS_GROUPS_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.HAS_SCHEDULE_GROUPS_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.HAS_SCHEDULE_GROUPS_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.HAS_SCHEDULE_GROUPS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.GROUPS_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.USER_GROUP_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.USER_GROUP_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.HAS_ASSIGNEES_KEY) {
				const status = facet.value;
				if (status === StringUtils.YES) {
					tempFacets[StringUtils.HAS_SCHEDULE_USERS_KEY] = 'true';
				} else if (status === StringUtils.NO) {
					tempFacets[StringUtils.HAS_SCHEDULE_USERS_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.HAS_SCHEDULE_USERS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.ASSIGNEES_KEY) {
				const ids = facet.value;
				tempFacets[StringUtils.USER_IDS_KEY] = ids;
			} else if (facet.key === StringUtils.USER_IDS_KEY) {
				delete this.facets[facet.key];
			} else if (facet.key === StringUtils.STATUS_KEY) {
				const status = facet.value;
				if (status === StringUtils.ACTIVE) {
					tempFacets[StringUtils.ACTIVE_KEY] = 'true';
				} else if (status === StringUtils.INACTIVE) {
					tempFacets[StringUtils.ACTIVE_KEY] = 'false';
				}
			} else if (facet.key === StringUtils.ACTIVE_KEY) {
				delete this.facets[facet.key];
			}
		});

		Object.assign(this.facets, tempFacets);

		return {
			filter: JSON.stringify(this)
		};
	}
}
