import { SortDirection } from 'app-core/shared-core/tools/extensions';
import { HelpArticle } from './help/help-article';
import { NotificationMessage } from './notification-message/notification-message';
import { UserConfig } from './user-config';

export class UserSupportFile {
	helpArticles: HelpArticle[] = [];
	notificationMessages: NotificationMessage[] = [];

	constructor(userSupport: Partial<UserSupportFile>, currentLang: string) {

		this.helpArticles = (userSupport['data'] as HelpArticle[])
			.map(article => new HelpArticle(article, currentLang));

		this.notificationMessages = this.helpArticles.filter(article => !!article.notification)
			.map(article => new NotificationMessage(article.notification, currentLang));

		this.notificationMessages.sortByProperty('startDate', SortDirection.Ascending);
	}
}

export class UserSupportStorage {
	userConfigs: UserConfig[] = [];

	constructor(storage: Partial<UserSupportStorage>) {
		Object.assign(this, storage);

		this.userConfigs.map(config => new UserConfig(config));
	}
}
