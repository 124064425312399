<simple-change-bulk-tab
	[form]="form"
	[affectableIdsCount]="affectableIdsCount"
	[selectedIdsCount]="selectedIdsCount"
	[hideText]="hideText">
		<div class="change-bulk-section">
			<simple-form-checkbox
				[label]="'SendReportByEmail' | translate"
				[name]="'sendReportByEmail'"
				[form]="form"
				[serverErrors]="serverErrors"
				[disabled]="tooLargeForDownload || affectableIdsCount === 0">
			</simple-form-checkbox>
		</div>

		<div class="change-bulk-section" *ngIf="sendReportByEmail || tooLargeForDownload">
			<div class="change-bulk-section-header">
				{{ 'Recipients' | translate }}
			</div>
			<div class="recipient-section" *ngIf="subscribers.length">
				<div class="recipient-section-header">
					{{ 'FromNotifications' | translate }}
				</div>
				<div class="recipient" *ngFor="let subscriber of subscribers"
					title="{{ (subscriber.selected ? 'Unselect' : 'Select') | translate }}"
					(mouseover)="subscriber.hovered = true"
					(mouseleave)="subscriber.hovered = false"
					(click)="subscriber.selected = !subscriber.selected">
						<span class="email subscriber"
							[ngClass]="{
								'selected': subscriber.selected
							}">
							{{ subscriber.email }}
						</span>
						<div class="action bi"
							[ngClass]="{
								'bi-square': !subscriber.selected && !subscriber.hovered,
								'bi-check-square': subscriber.hovered && !subscriber.selected,
								'bi-check-square-fill': subscriber.selected
							}">
						</div>
				</div>
			</div>
			<div class="recipient-section">
				<div class="recipient-section-header">
					{{ 'Users' | translate }}
				</div>
				<div class="recipient" *ngFor="let user of changeModel.users"
					title="{{ 'Remove' | translate }}"
					(click)="removeUser(user)">
						<span class="email">
							{{ user.email }}
						</span>
						<div class="action bi bi-trash">
						</div>
				</div>
				<button
					class="btn btn-transparent"
					title="{{ 'Add' | translate }}"
					(click)="handleAddUsersClick()">
						{{ 'Add' | translate }}
				</button>
			</div>
			<div class="recipient-section">
				<simple-form-select
					[label]="'ExternalEmailParenthesis' | translate"
					[name]="propertyStrings.emails"
					[form]="form"
					[items]="selectableEmailItems"
					[multiple]="true"
					[searchable]="true"
					[addable]="true"
					[serverErrors]="serverErrors"
					(onAddNewItem)="handleAddNewItemInSelect($event)">
				</simple-form-select>
			</div>
		</div>

		<div class="change-bulk-section" *ngIf="tooLargeForDownload">
			<div class="report-info">
				{{ 'TheReportIsTooLargeToDownload' | translate }}
			</div>
		</div>
</simple-change-bulk-tab>
