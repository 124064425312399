import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app-core/auth/auth.service';
import { DomUtils } from 'app-core/shared-core/tools/dom-utils';
import { NumberUtils } from 'app-core/shared-core/tools/number-utils';
import { Utils } from 'app-core/shared-core/tools/utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SimpleCrudDirective } from '../../../simple-crud.directive';

@Directive()
export abstract class SimpleHandleTabDirective<T extends { id: string, selected: boolean, errorMessages: string[], url: string }> extends SimpleCrudDirective<T> implements OnInit, OnChanges, AfterViewInit {

	allAreSelected: boolean = false;
	someAreSelected: boolean = false;

	isCompactView: boolean;
	itemSize: number;

	requiredError: boolean = false;

	searchValue: string;

	@Input() handleItems: T[];
	@Input() headerSubtitleText: string;
	@Input() readonlyItems: boolean;
	@Input() readonlyListModal: boolean;
	@Input() hideHeaderAddButton: boolean;
	@Input() hideHeaderRemoveSelected: boolean;
	@Input() hideHeaderSelectAll: boolean;
	@Input() hideHeaderSearch: boolean;
	@Input() wasSelected: boolean;
	@Input() bypassRequiredError: boolean;

	@Output() onModelsChange = new EventEmitter<T[]>();
	@Output() onUpdated = new EventEmitter<T[]>();
	@Output() onDeleted = new EventEmitter<string[]>();

	@ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		protected cdRef: ChangeDetectorRef) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.isCompactView = Utils.isExtraSmallScreenSize();
		this.itemSize = this.isCompactView ? 200 : 50; // TODO variables
		this.subscriptions.add(
			fromEvent(window, 'resize')
				.pipe(
					debounceTime(NumberUtils.WINDOW_RESIZE_WAIT_TIME),
					takeUntil(this.destroyed$)
				)
				.subscribe(__ => {
					this.isCompactView = Utils.isExtraSmallScreenSize();
					this.itemSize = this.isCompactView ? 200 : 50;
					setTimeout(() => {
						this.refreshViewport();
					}, 0);
				})
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!!changes['wasSelected']?.currentValue) {
			setTimeout(() => {
				this.refreshViewport();
			}, 0);
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.refreshViewport();
		}, 0);
	}

	refreshViewport() {
		this.viewPort?.checkViewportSize();
		this.cdRef.detectChanges();
	}

	protected subscribeToCrudModalContent() {
		this.currentCrudModalComponent = this.bsModalRef.content;
		this.subscriptions.add(
			this.currentCrudModalComponent.confirmed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(confirmedItems => {
					if (confirmedItems) {
						this.handleConfirmed(confirmedItems);
					}
				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.updated$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(updatedItems => {
					if (updatedItems && updatedItems.length) {
						this.handleUpdated(updatedItems);
					}
				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.deleted$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(deletedItemIds => {
					if (deletedItemIds && deletedItemIds.length) {
						this.handleDeleted(deletedItemIds);
					}
				})
		);
		this.subscriptions.add(
			this.currentCrudModalComponent.closed$
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(wasClosed => {
					if (wasClosed) {
						this.closeModalWithUnsavedChanges();
					}
				})
		);
	}

	closeModalWithUnsavedChanges() {
		if (this.hasUnsavedChanges()) {
			this.displayUnsavedChangesSwal()
				.then(result => {
					if (result.value) {
						this.closeModal();
					} else {
						// Set the closed value to false again as we have clicked the close button.
						this.currentCrudModalComponent.closed$.next(false);
					}
				});
		} else {
			this.closeModal();
		}
	}

	closeModal() {
		DomUtils.hideLatestOpenedModal();
		this.bsModalRef.hide();
		this.bsModalRef = null;
	}

	handleConfirmed(confirmedItems: T[]) {
		this.handleItems = this.constructItemList(this.handleItems, confirmedItems);
		this.setAllAreSelected();
		this.setSomeAreSelected();
		this.emitModelsChange();
		this.closeModal();

		this.requiredError = !this.handleItems.length;
	}

	handleUpdated(updatedItems: T[]) {
		updatedItems.forEach(item => {
			const existingItem = this.handleItems.find(task => task.id === item.id);
			if (existingItem) {
				Object.assign(existingItem, item);
			}
		});
		this.onUpdated.emit(updatedItems);
		this.emitModelsChange();
	}

	handleDeleted(deletedItemIds: string[]) {
		this.handleItems = this.handleItems.filter(model => !deletedItemIds.includes(model.id));
		this.setAllAreSelected();
		this.setSomeAreSelected();
		this.onDeleted.emit(deletedItemIds);
		this.emitModelsChange();

		this.requiredError = !this.handleItems.length;
	}

	remove(itemToRemove: T) {
		this.handleItems = this.handleItems.filter(model => model.id !== itemToRemove.id);
		this.setAllAreSelected();
		this.setSomeAreSelected();
		this.emitModelsChange();

		this.requiredError = !this.handleItems.length;
	}

	removeSelected() {
		const selectedItems = this.handleItems.filter(model => model.selected === true);
		this.handleItems = this.handleItems.filter(model => !selectedItems.includes(model));
		this.setAllAreSelected();
		this.setSomeAreSelected();
		this.emitModelsChange();

		this.requiredError = !this.handleItems.length;
	}

	selectAll() {
		this.allAreSelected = !this.allAreSelected;
		this.getItems().forEach(model => model.selected = this.allAreSelected);
		this.setSomeAreSelected();
	}

	select(item: T) {
		item.selected = !item.selected;
		this.setAllAreSelected();
		this.setSomeAreSelected();
	}

	getSelectedCount() {
		return this.handleItems.filter(item => item.selected).length;
	}

	setSelectedFlags() {
		setTimeout(() => {
			this.setAllAreSelected();
			this.setSomeAreSelected();
			this.cdRef.detectChanges();
		}, 0);
	}

	protected setAllAreSelected() {
		this.allAreSelected = this.getItems().length && this.getItems().every(model => model.selected === true);
	}

	protected setSomeAreSelected() {
		this.someAreSelected = this.handleItems.some(model => model.selected === true);
	}

	protected emitModelsChange() {
		this.onModelsChange.emit(this.handleItems);

		setTimeout(() => {
			this.refreshViewport();
		}, 0);
	}

	triggerValidation() {
		this.requiredError = !this.handleItems.length;
		this.validate(this.handleItems);
		this.cdRef.detectChanges();
	}

	itemListHasErrors() {
		return (!this.bypassRequiredError && this.requiredError) || this.handleItems.some(model => model.errorMessages.length);
	}

	constructItemList(currentItemList: T[], confirmedItemList: T[]) { // todo ligga i util och anropa ifrån där den används??
		// Reduce to only confirmed items.
		currentItemList = currentItemList
			.filter(currentItem => confirmedItemList.find(confirmedItem => confirmedItem.id === currentItem.id));

		// Apply only new items.
		currentItemList = currentItemList
			.concat(confirmedItemList
				.filter(confirmedItem => !currentItemList.find(currentItem => currentItem.id === confirmedItem.id)));

		return currentItemList;
	}

	handleSearch(value: string) {
		this.searchValue = value;
		this.setAllAreSelected();
		this.setSomeAreSelected();
	}

	protected abstract validate(items: T[]): void;
	abstract itemListIsValid(): void;
	abstract getItems(): T[];
}
