import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DotsLoaderModule } from 'app-core/shared-core/dots-loader/dots-loader.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FeedbackComponent } from './feedback.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		TranslateModule,
		PopoverModule,
		DotsLoaderModule
	],
	declarations: [
		FeedbackComponent
	],
	exports: [
		FeedbackComponent
	]
})
export class FeedbackModule { }
