import {
	Component,
	OnDestroy,
	OnInit
} from '@angular/core';
import { MediaUtils } from 'app-core/shared-core/tools/media-utils';
import { MediaWidgetItem } from 'app-core/media/widget/item/media-widget-item';
import { AuthService } from 'app-core/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { MediaResolver } from 'app-core/media/resolver/media-resolver';
import { SimpleReadModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-read-modal.directive';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';

@Component({
	templateUrl: 'media-widget-item-viewer.component.html',
	styleUrls: ['media-widget-item-viewer.component.less']
})
export class MediaWidgetItemViewerComponent extends SimpleReadModalDirective<MediaWidgetItem> implements OnInit, OnDestroy {

	item: MediaWidgetItem;
	collection: MediaWidgetItem[] = [];

	media = MediaUtils;

	index: number;
	hasNext: boolean;
	hasPrev: boolean;

	currentItem$ = new BehaviorSubject<MediaWidgetItem>(null);

	pdfThumbnail = MediaUtils.MISC_PDF;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private mediaResolver: MediaResolver) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	ngOnInit() {
		this.setCurrentItem(this.item);
	}

	setCurrentItem(item: MediaWidgetItem) {
		if (item !== this.currentItem$.value) {
			this.currentItem$.next(item);

			this.index = this.collection.indexOf(item);

			this.hasNext = this.collection.length && this.index < this.collection.length - 1;
			this.hasPrev = this.collection.length && this.index > 0;
		}
	}

	next() {
		if (this.collection.length && (this.index < this.collection.length - 1)) {
			this.setCurrentItem(this.collection[this.index + 1]);
		}
	}

	prev() {
		if (this.collection.length && this.index > 0) {
			this.setCurrentItem(this.collection[this.index - 1]);
		}
	}

	download() {
		const mediaItem = this.currentItem$.value;
		const url = this.mediaResolver.resolve(mediaItem.url);
		window.open(url, '_blank');
	}

	ngOnDestroy() {
		this.currentItem$.complete();
		super.ngOnDestroy();
	}
}
