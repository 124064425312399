<div class="app-container"
	[ngClass]="{
		'blurred': hasOpenModals()
	}">
	<!-- When logged in -->
	<ng-container *ngIf="loggedInUser">
		<div class="app-header">
			<div class="logotype"
				[title]="'Inquinova'">
			</div>
			<div class="collapse-button-mobile bi-list"
				[title]="'ToggleMenu' | translate"
				(click)="toggleMenuMobile()">
			</div>
			<important-message></important-message>
			<div class="actions-wrapper">
				<div class="actions">
					<help></help>
					<feedback></feedback>
					<notification-messages></notification-messages>
				</div>
				<div class="current-user"
					[ngClass]="{
						'is-active': routeIsActive(routesUtils.profile),
						'is-disabled': routeIsDisabled(routesUtils.profile)
					}"
					[title]="'NavigateToProfile' | translate"
					(click)="navigateToProfile()">
						<div class="user-info">
							<div class="full-name"
								title="{{ loggedInUser.firstname }} {{ loggedInUser.lastname }}">
									{{ loggedInUser.firstname }} {{ loggedInUser.lastname }}
							</div>
							<div class="email"
								title="{{ loggedInUser.email }}">
								{{ loggedInUser.email }}
							</div>
						</div>
						<div class="user-image" #parentDiv>
							<extensive-image
								[imageUrl]="loggedInUser.profileImageUrl"
								[imageStorageSize]="parentDiv | getImageStorageSize"
								[rounded]="true"
								[placeholderImageType]="'user'">
							</extensive-image>
						</div>
				</div>
			</div>
		</div>
		<div class="app-body">
			<side-menu
				[isInvalidRoute]="isInvalidRoute"
				[menuOpenedMobile]="menuOpenedMobile"
				(onItemClick)="toggleMenuMobile()">
			</side-menu>
			<div class="main-content">
				<div class="main-content-header"></div>
				<div class="main-content-body">
					<router-outlet *ngIf="(errorService.errorOfType$ | async) === errorTypeKey.Default">
					</router-outlet>
					<error *ngIf="(errorService.errorOfType$ | async) !== errorTypeKey.Default"
						[errorType]="errorService.errorOfType$ | async">
					</error>
					<div class="environment-ribbon" *ngIf="displayEnvironmentName">
						{{ environmentName }}!
					</div>
				</div>
			</div>
		</div>

	</ng-container>

	<!-- When not logged in -->
	<ng-container *ngIf="!loggedInUser">
		<router-outlet></router-outlet>
	</ng-container>

	<dots-loader *ngIf="displayLoader"></dots-loader>
</div>
