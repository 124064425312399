<simple-change-tab
	[form]="form">
	<div class="change-section">
		<div class="content">
			<markdown-editor
				[initialTextareaValue]="initialTextAreaValue"
				[toolbarAsPopover]="isExtraSmallScreenSize()"
				[errorText]="errorText"
				(onTextChange)="handleMarkdownChange($event)">
			</markdown-editor>
		</div>
	</div>

		<!-- Needed just to have a form..... -->
		<simple-form-input *ngIf="!changeModel"
			[label]="''"
			[name]="propertyStrings.name"
			[form]="form">
		</simple-form-input>
</simple-change-tab>
