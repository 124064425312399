import { Injectable } from '@angular/core';
import { BaseService } from 'app-core/shared-core/abstract-components/service/base.service';
import { HostedHttpClientService } from 'app-core/shared-core/hosted-httpclient.service';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { Integration } from './integration';

@Injectable()
export class IntegrationService extends BaseService<Integration> {

	constructor(
		protected hostedHttpClient: HostedHttpClientService) {
		super(
			hostedHttpClient,
			RoutesUtils.webhook
		);
		this.useCoreApi = true;
	}

	async getIntegrations(orgId: number): Promise<Integration[]> {
		return this.hostedHttpClient.get(`${this.prefix}/status/${orgId}`, {}, true)
			.then(res => res.data.integrations.map((item: Integration) => this.instantiateModel(item)));
	}

	instantiateModel(item: Integration) {
		return new Integration(item);
	}
}
