<simple-change-tab
	[form]="form">
	<div class="change-section">
		<div class="change-section-header">
			{{ 'General' | translate }}
		</div>
		<simple-form-input
			[label]="'Name' | translate"
			[name]="propertyStrings.name"
			[form]="form"
			[maxlength]="100"
			[serverErrors]="serverErrors"
			[focusOnInit]="!isEdit">
		</simple-form-input>
	</div>
</simple-change-tab>
