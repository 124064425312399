import { Component, EventEmitter, Output } from '@angular/core';
import { DEFAULT_TIME_FORMAT, KEYS } from 'app-core/shared-core/tools/utils';
import { SimpleFormControlDirective } from '../simple-form-control.directive';
import * as moment from 'moment';

@Component({
	selector: 'simple-form-time-input',
	templateUrl: './simple-form-time-input.component.html',
	styleUrls: ['./simple-form-time-input.component.less']
})
export class SimpleFormTimeInputComponent extends SimpleFormControlDirective {

	@Output() onTimeChange = new EventEmitter();

	handleKeydown(event: KeyboardEvent) {
		if (event.code === KEYS.Space) {
			event.preventDefault();
		}
		this.onTimeChange.emit();
	}

	handleTimeInputScroll(event: any) {
		event.preventDefault();
		const scrollUp = event.deltaY < 0;
		const control = this.getFormControl();
		const fullDate = moment(`1980-01-01 ${control.value}`);
		if (scrollUp) {
			control.setValue(fullDate.add(1, 'minute').format(DEFAULT_TIME_FORMAT));
		} else {
			control.setValue(fullDate.subtract(1, 'minute').format(DEFAULT_TIME_FORMAT));
		}
		this.onTimeChange.emit();
	}
}
