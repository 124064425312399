<simple-crud-modal
	[titleText]="'Group' | translate"
	[closeText]="'Close' | translate"
	[submitText]="getSelectedCountText('Delete' | translate)"
	[isDelete]="true"
	[isLoading]="pending"
	(onClose)="close()"
	(onSubmit)="submit()">
		<simple-delete
			[selectedIdsCount]="deleteModelIds.length">
		</simple-delete>
</simple-crud-modal>
