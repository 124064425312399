export class FileUtils {
	public static bytesToMb(size: number) {
		return size / 1024 / 1024;
	}

	public static mbToBytes(size: number) {
		return size * 1024 * 1024;
	}

	public static isAllowedFileSize(file: File, limit: number) {
		return file.size < limit;
	}

	public static isImage(file: File) {
		return file.type && ACCEPTED_MEDIA_TYPES.IMAGE.includes(file.type.substring(6));
	}

	public static isPDF(file: File) {
		return file.type && ACCEPTED_MEDIA_TYPES.PDF.includes(file.type);
	}

	public static isExcel(file: File) {
		if (file.type) {
			const fileType = file.type.substring(file.type.length - 5);
			if (fileType === 'excel' || fileType === 'sheet') {
				return true;
			}
		}
		return false;
	}

	/**
	 * Converts an image to a Blob, base64 format.
	 * @param String dataURI, the image path
	 */
	public static dataURItoBlob(dataURI: string, mimeString?: string) {
		// convert base64 to raw binary data held in a string
		// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
		const byteString = atob(dataURI.split(',')[1]);

		// separate out the mime component
		if (!mimeString) {
			mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
		}

		// write the bytes of the string to an ArrayBuffer
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		// Old Code
		// write the ArrayBuffer to a blob, and you're done
		// var bb = new BlobBuilder();
		// bb.append(ab);
		// return bb.getBlob(mimeString);

		// New Code
		return new Blob([ab], { type: mimeString });
	}
}

export const ACCEPTED_MEDIA_TYPES = {
	IMAGE: '.jpeg,.jpg,.png',
	PDF: 'application/pdf',
	SPREADSHEET: '.xlsx,.xls',
};
