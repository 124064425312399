import { CrudItem } from 'app-core/shared-core/simple-components/crud/crud-item';
import { DEFAULT_LANGUAGE } from 'app-core/user/user';
import { CultureDataObject } from '../help/help-article';

export enum NotificationMessageType {
	Release = 'release',
	Bug = 'bug',
	Info = 'info'
}

export class NotificationMessage extends CrudItem<NotificationMessage> {
	icon: string;
	title: string;
	summary: string;
	version: string;
	type: NotificationMessageType;
	linkable: boolean;
	important: boolean;
	startDate: string;
	endDate: string;
	hasBeenOpened: boolean;
	path: string;

	titles: CultureDataObject;
	summaries: CultureDataObject;

	constructor(notificationMessage: Partial<NotificationMessage>, currentLang: string) {
		super();
		Object.assign(this, notificationMessage);
		this.id = `${this.type}:${this.version ?? 0}:${this.path}`;
		this.setIcon();

		const title = this.titles[currentLang];
		const summary = this.summaries[currentLang];

		this.title = title ? title : this.titles[DEFAULT_LANGUAGE];
		this.summary = summary ? summary : this.summaries[DEFAULT_LANGUAGE];
	}

	setIcon() {
		switch (this.type) {
			case NotificationMessageType.Release:
				this.icon = 'bi-rocket-takeoff-fill';
				break;
			case NotificationMessageType.Bug:
				this.icon = 'bi-bug';
				break;
			case NotificationMessageType.Info:
				this.icon = 'bi-info-lg';
				break;
		}
	}
}
