<simple-change-tab
	[form]="form">
		<div class="change-section">
			<simple-form-input *ngIf="!otherUserId"
				[label]="'OldPassword' | translate"
				[name]="propertyStrings.oldPassword"
				[form]="form"
				[usePasswordType]="true"
				[serverErrors]="serverErrors">
			</simple-form-input>

			<simple-form-input
				[label]="'NewPassword' | translate"
				[name]="propertyStrings.newPassword"
				[form]="form"
				[usePasswordType]="true"
				[serverErrors]="serverErrors">
			</simple-form-input>

			<simple-form-input
				[label]="'ConfirmPassword' | translate"
				[name]="propertyStrings.confirmPassword"
				[form]="form"
				[usePasswordType]="true"
				[serverErrors]="serverErrors">
			</simple-form-input>
		</div>
</simple-change-tab>
