import { NgModule } from '@angular/core';
import { MediaModule } from 'app-core/media/media-module';
import { OrganizationModule } from '../organization/organization.module';
import { SharedModule } from '../shared-core/shared.module';
import { CreateEditUserComponent } from './create-edit-user/create-edit-user.component';
import { ChangeDetailsTabComponent } from './create-edit-user/tabs/change-details/change-details-tab.component';
import { ViewDetailsTabComponent } from './create-edit-user/tabs/view-details/view-details-tab.component';
import { DeleteUsersComponent } from './delete-users/delete-users.component';
import { ListUserComponent } from './list-user/list-user.component';
import { SetStatusUserComponent } from './list-user/set-status/set-status-user.component';
import { SetStatusTabComponent } from './list-user/set-status/tabs/set-status-tab.component';
import { ProfileImageComponent } from './my-profile/profile-image/profile-image.component';
import { ProfileComponent } from './my-profile/profile.component';
import { PasswordComponent } from './my-profile/security/password/password.component';
import { PasswordTabComponent } from './my-profile/security/password/tabs/password-tab.component';
import { ResendEmailComponent } from './resend-email/resend-email.component';
import { ResendEmailTabComponent } from './resend-email/tabs/resend-email-tab.component';
import { UserRoutingModule } from './user-routing.module';

@NgModule({
	imports: [
		SharedModule,
		UserRoutingModule,
		OrganizationModule,
		MediaModule
	],
	declarations: [
		ListUserComponent,
		CreateEditUserComponent,
		ViewDetailsTabComponent,
		ChangeDetailsTabComponent,
		ResendEmailComponent,
		ResendEmailTabComponent,
		DeleteUsersComponent,
		SetStatusUserComponent,
		SetStatusTabComponent,
		ProfileComponent,
		ProfileImageComponent,
		PasswordComponent,
		PasswordTabComponent
	]
})
export class UserModule { }
