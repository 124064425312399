<div class="form-select" [formGroup]="form"
	[ngClass]="{
		'disabled': disabled
	}">
		<div class="label-wrapper"
			[ngClass]="{
				'focused': focused,
				'has-errors': hasErrors()
			}">
				<label [for]="name">
					{{ label }}
				</label>
				<label [for]="name" class="secondary-label">
					{{ isRequired ? '' : ' (' + ('Optional' | translate | lowercase)  + ')' }}
				</label>
		</div>
		<ng-select
			[id]="name"
			[formControlName]="name"
			[ngClass]="{
				'has-errors': hasErrors(),
				'red-background': redBackground,
				'multiple': multiple,
				'searchable': searchable
			}"
			[disabled]="disabled"
			[placeholder]="!ngSelectComponent?.selectedItems.length ? label : ''"
			[items]="items"
			[multiple]="multiple"
			[closeOnSelect]="!multiple"
			[searchable]="searchable"
			[addTag]="addable ? addTagFn : false"
			[markFirst]="false"
			[clearSearchOnAdd]="true"
			[bindValue]="'id'"
			[bindLabel]="'name'"
			[clearable]="!isRequired"
			[searchFn]="useStrictSearch ? searchFnStrict : searchFnLoose"
			(focus)="focused = true"
			(blur)="focused = false"
			(add)="handleAdd($event)">
			<ng-template ng-label-tmp let-item="item" let-clear="clear">
				<span class="ng-value-label"
					[title]="item.name">
						{{ item.name }}
				</span>
				<span class="ng-value-icon right"
					[title]="'Remove' | translate"
					(click)="clear(item)" aria-hidden="true">
						×
				</span>
			</ng-template>
		</ng-select>
		<simple-form-error
			[name]="name"
			[form]="form"
			[serverErrors]="serverErrors">
		</simple-form-error>
</div>
