import { Component, ElementRef, ViewContainerRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AdministrationService } from "app-core/administration/administration.service";
import { AuthService } from "app-core/auth/auth.service";
import { IntegrationTypes } from "app-core/organization/my-organization/integrations/integration";
import { KeyValuePair } from "app-core/shared-core/filter";
import { SimpleListDirective } from "app-core/shared-core/simple-components/list/simple-list.directive";
import {
    SimpleTableRowAction,
    SimpleTableRowActionDelete,
    SimpleTableRowActionEdit,
} from "app-core/shared-core/simple-components/list/table/body/simple-table-row-action";
import { SimpleTableAssessmentColumn } from "app-core/shared-core/simple-components/list/table/columns/simple-table-assessment-column";
import {
    EventsContent,
    SimpleTableEventsColumn,
} from "app-core/shared-core/simple-components/list/table/columns/simple-table-events-column";
import {
    IconContent,
    SimpleTableIconsColumn,
} from "app-core/shared-core/simple-components/list/table/columns/simple-table-icons-column";
import { SimpleTableStatusColumn } from "app-core/shared-core/simple-components/list/table/columns/simple-table-status-column";
import { SimpleTableTextColumn } from "app-core/shared-core/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app-core/shared-core/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleFilterHelperChoice } from "app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-choice";
import { SimpleFilterHelperEntity } from "app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-entity";
import { SimpleFilterHelperFacility } from "app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-facility";
import { SimpleFilterHelperSchedule } from "app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-schedule";
import { SimpleFilterHelperTask } from "app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-task";
import { SimpleFilterHelperTemplateType } from "app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-template-type";
import { SimpleFilterHelperUser } from "app-core/shared-core/simple-components/list/table/filter/helpers/simple-filter-helper-user";
import {
    SimpleFilterInput,
    SimpleFilterInputType,
} from "app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-input-settings";
import { SimpleFilterListModalInput } from "app-core/shared-core/simple-components/list/table/filter/input-settings/simple-filter-list-modal-input-settings";
import { SimpleFilterInputItem } from "app-core/shared-core/simple-components/list/table/filter/simple-filter-input-item";
import { SimpleRetainService } from "app-core/shared-core/simple-components/list/table/filter/simple-retain.service";
import {
    SimpleTableHeaderAction,
    SimpleTableHeaderActionDelete,
} from "app-core/shared-core/simple-components/list/table/head/simple-table-header-action";
import { TextContent } from "app-core/shared-core/simple-components/various/text-content/simple-text-content.component";
import { ColorUtils } from "app-core/shared-core/tools/color-utils";
import { ConfigUtils } from "app-core/shared-core/tools/config-utils";
import { RoutesUtils } from "app-core/shared-core/tools/routes-utils";
import { StringUtils } from "app-core/shared-core/tools/string-utils";
import {
    DEFAULT_DISPLAY_DATE_FORMAT,
    Utils,
} from "app-core/shared-core/tools/utils";
import { TranslationService } from "app-core/shared-core/translation/translation.service";
import { User } from "app-core/user/user";
import { Assignment } from "app-inspection/assignment/assignment";
import {
    AssignmentFilter,
    AssignmentService,
} from "app-inspection/assignment/assignment.service";
import { DeleteAssignmentsComponent } from "app-inspection/assignment/delete-assignments/delete-assignments.component";
import { DetailedAssignmentComponent } from "app-inspection/assignment/detailed-assignment/detailed-assignment.component";
import { GenerateReportComponent } from "app-inspection/assignment/report/generated/generate-report.component";
import { StaticReportComponent } from "app-inspection/assignment/report/static/static-report.component";
import { environment } from "environments/environment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import {
    SimpleFilterConfig,
    SortObject,
} from "../../../../../app-core/shared-core/simple-components/list/table/filter/simple-filter-config";
import { SimpleTableConfig } from "../../../../../app-core/shared-core/simple-components/list/table/simple-table-config";

@Component({
    selector: "list-assignment-measure-archived",
    templateUrl:
        "../../../../../app-core/shared-core/simple-components/list/simple-list-shared-template.html",
    providers: [
        SimpleFilterHelperFacility,
        SimpleFilterHelperEntity,
        SimpleFilterHelperTask,
        SimpleFilterHelperChoice,
        SimpleFilterHelperUser,
        SimpleFilterHelperTemplateType,
        SimpleFilterHelperSchedule,
    ],
})
export class ListAssignmentMeasureArchivedComponent extends SimpleListDirective<Assignment> {
    // Override filterObject to match backend.
    readonly filterObject = new AssignmentFilter();

    bsModalRef: BsModalRef<any>;
    wasRowAction: boolean = false;

    constructor(
        protected authService: AuthService,
        protected modalService: BsModalService,
        protected toastrService: ToastrService,
        protected translationService: TranslationService,
        protected sanitizer: DomSanitizer,
        protected assignmentService: AssignmentService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected retainService: SimpleRetainService,
        protected elementRef: ElementRef,
        protected viewContainerRef: ViewContainerRef,
        private simpleFilterHelperFacility: SimpleFilterHelperFacility,
        private simpleFilterHelperEntity: SimpleFilterHelperEntity,
        private simpleFilterHelperTask: SimpleFilterHelperTask,
        private simpleFilterHelperChoice: SimpleFilterHelperChoice,
        private simpleFilterHelperUser: SimpleFilterHelperUser,
        private simpleFilterHelperTemplateType: SimpleFilterHelperTemplateType,
        private simpleFilterHelperSchedule: SimpleFilterHelperSchedule,
        private administrationService: AdministrationService
    ) {
        super(
            authService,
            modalService,
            toastrService,
            translationService,
            assignmentService,
            route,
            router,
            retainService,
            elementRef,
            viewContainerRef,
            RoutesUtils.assignment
        );
    }

    protected configureListActions() {}

    protected async configureTableFilter(
        config: SimpleFilterConfig<Assignment>
    ) {
        this.filterObject.context = RoutesUtils.measureArchived;
        config.hideSearch = true;

        config.setInitialFacets(
            new KeyValuePair(StringUtils.IS_COMPLETED_KEY, "true"),
            new KeyValuePair(StringUtils.IS_CLOSED_KEY, "true"),
            new KeyValuePair(StringUtils.IS_MEASURE_KEY, "true"),
            new KeyValuePair(StringUtils.IS_MANUAL_MEASURE_KEY, "true"),
            new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, "Or")
        );

        const typeKey = StringUtils.TYPE_KEY;
        const facilitiesKey = StringUtils.FACILITIES_KEY;
        const entitiesKey = StringUtils.ENTITIES_KEY;
        const tasksKey = StringUtils.TASKS_KEY;
        const choicesKey = StringUtils.CHOICES_KEY;
        const prioritizedKey = StringUtils.PRIORITIZED_KEY;
        const handledByKey = StringUtils.HANDLED_BY_KEY;
        const archivedBetweenKey = StringUtils.ARCHIVED_BETWEEN_KEY;
        const statusKey = StringUtils.STATUS_KEY;
        const integrationsKey = StringUtils.INTEGRATIONS_KEY;
        const templateTypesKey = StringUtils.TEMPLATE_TYPES_KEY;
        const categoriesKey = StringUtils.CATEGORIES_KEY;
        const issueNumbersKey = StringUtils.ISSUE_NUMBERS_KEY;
        const schedulesKey = StringUtils.SCHEDULES_KEY;
        if (!this.isInModal) {
            this.retainService.setCurrentRetainEntries({
                search: null,
                sort: null,
                [typeKey]: null,
                [facilitiesKey]: null,
                [entitiesKey]: null,
                [tasksKey]: null,
                [choicesKey]: null,
                [prioritizedKey]: null,
                [handledByKey]: null,
                [archivedBetweenKey]: null,
                [statusKey]: null,
                [integrationsKey]: null,
                [templateTypesKey]: null,
                [categoriesKey]: null,
                [issueNumbersKey]: null,
                [schedulesKey]: null,
            });
        }

        try {
            const [
                templateTypes,
                facilities,
                entities,
                tasks,
                choices,
                users,
                integrations,
                schedules,
                categories,
                issueNumbers,
            ] = await Promise.all([
                this.assignmentService.getTemplateTypes(
                    this.selectedOrganization.id
                ),
                this.assignmentService.getFacilities(),
                this.assignmentService.getEntities(),
                this.assignmentService.getTasks(this.selectedOrganization.id),
                this.assignmentService.getChoices(this.selectedOrganization.id),
                this.assignmentService.getUsers(this.selectedOrganization.id),
                this.assignmentService.getIntegrations(
                    this.selectedOrganization.id
                ),
                this.assignmentService.getSchedules(
                    this.selectedOrganization.id
                ),
                this.assignmentService.getCategories(
                    this.selectedOrganization.id
                ),
                this.assignmentService.getIssueNumbers(
                    this.selectedOrganization.id,
                    false
                ),
            ]);

            const manualMeasure = templateTypes.find(
                (templateType) => templateType.isManualMeasure
            );
            const convertedManualMeasure = manualMeasure
                ? new SimpleFilterInputItem(
                      manualMeasure.id,
                      manualMeasure.name
                  )
                : null;
            const convertedFacilities = facilities.map(
                (facility) =>
                    new SimpleFilterInputItem(facility.id, facility.name)
            );
            const convertedEntities = entities.map(
                (entity) => new SimpleFilterInputItem(entity.id, entity.name)
            );
            const convertedTasks = tasks.map(
                (task) => new SimpleFilterInputItem(task.id, task.text)
            );
            const convertedChoices = choices.map(
                (choice) => new SimpleFilterInputItem(choice.id, choice.value)
            );
            const convertedUsers = users.map(
                (user) => new SimpleFilterInputItem(user.id, user.email)
            );
            const convertedIntegrations = integrations
                .filter(
                    (i) =>
                        i.type === IntegrationTypes.DfRespons ||
                        i.type === IntegrationTypes.InfraControl
                )
                .map(
                    (integration) =>
                        new SimpleFilterInputItem(
                            integration.id,
                            integration.name
                        )
                );
            const convertedTemplateTypes = templateTypes
                .filter((templateType) => !templateType.isManualMeasure)
                .map(
                    (templateType) =>
                        new SimpleFilterInputItem(
                            templateType.id,
                            templateType.name
                        )
                );
            const convertedCategories = categories.map(
                (category) =>
                    new SimpleFilterInputItem(category.id, category.name)
            );
            const convertedIssueNumbers = issueNumbers.map(
                (issueNumber) =>
                    new SimpleFilterInputItem(
                        issueNumber.toString(),
                        issueNumber.toString()
                    )
            );
            const convertedSchedules = schedules.map(
                (schedule) =>
                    new SimpleFilterInputItem(schedule.id, schedule.name)
            );
            if (convertedIntegrations.length) {
                convertedIntegrations.push(
                    new SimpleFilterInputItem(
                        IntegrationTypes.None,
                        this.translationService.instant(IntegrationTypes.None)
                    )
                );
            }

            config.setFilterInputs(
                new SimpleFilterListModalInput(
                    facilitiesKey,
                    this.translationService.instant(facilitiesKey),
                    SimpleFilterInputType.ListModal,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(facilitiesKey, values)
                            );
                        } else {
                            keyValuePairs.push(
                                new KeyValuePair(facilitiesKey, "")
                            );
                        }
                        return keyValuePairs;
                    },
                    (filterInput: SimpleFilterListModalInput) => {
                        this.simpleFilterHelperFacility.openListModal(
                            filterInput,
                            facilities,
                            this
                        );
                    },
                    convertedFacilities
                ),
                new SimpleFilterListModalInput(
                    tasksKey,
                    this.translationService.instant(tasksKey),
                    SimpleFilterInputType.ListModal,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(tasksKey, values)
                            );
                        } else {
                            keyValuePairs.push(new KeyValuePair(tasksKey, ""));
                        }
                        return keyValuePairs;
                    },
                    (filterInput: SimpleFilterListModalInput) => {
                        this.simpleFilterHelperTask.openListModal(
                            filterInput,
                            tasks,
                            this
                        );
                    },
                    convertedTasks
                ),
                new SimpleFilterListModalInput(
                    choicesKey,
                    this.translationService.instant(choicesKey),
                    SimpleFilterInputType.ListModal,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(choicesKey, values)
                            );
                        } else {
                            keyValuePairs.push(
                                new KeyValuePair(choicesKey, "")
                            );
                        }
                        return keyValuePairs;
                    },
                    (filterInput: SimpleFilterListModalInput) => {
                        this.simpleFilterHelperChoice.openListModal(
                            filterInput,
                            choices,
                            this
                        );
                    },
                    convertedChoices
                ),
                new SimpleFilterListModalInput(
                    entitiesKey,
                    this.translationService.instant(entitiesKey),
                    SimpleFilterInputType.ListModal,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(entitiesKey, values)
                            );
                        } else {
                            keyValuePairs.push(
                                new KeyValuePair(entitiesKey, "")
                            );
                        }
                        return keyValuePairs;
                    },
                    (filterInput: SimpleFilterListModalInput) => {
                        this.simpleFilterHelperEntity.openListModal(
                            filterInput,
                            entities,
                            this
                        );
                    },
                    convertedEntities
                ),
                new SimpleFilterListModalInput(
                    templateTypesKey,
                    this.translationService.instant(templateTypesKey),
                    SimpleFilterInputType.ListModal,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(templateTypesKey, values)
                            );
                        } else {
                            keyValuePairs.push(
                                new KeyValuePair(templateTypesKey, "")
                            );
                        }
                        return keyValuePairs;
                    },
                    (filterInput: SimpleFilterListModalInput) => {
                        this.simpleFilterHelperTemplateType.openListModal(
                            filterInput,
                            templateTypes,
                            this
                        );
                    },
                    convertedTemplateTypes
                ),
                new SimpleFilterInput(
                    categoriesKey,
                    this.translationService.instant(categoriesKey),
                    SimpleFilterInputType.MultiSelectWithSearch,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(categoriesKey, values)
                            );
                        } else {
                            keyValuePairs.push(
                                new KeyValuePair(categoriesKey, "")
                            );
                        }
                        return keyValuePairs;
                    },
                    ...convertedCategories
                ),
                new SimpleFilterInput(
                    issueNumbersKey,
                    this.translationService.instant(issueNumbersKey),
                    SimpleFilterInputType.MultiSelectWithSearch,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(issueNumbersKey, values)
                            );
                        } else {
                            keyValuePairs.push(
                                new KeyValuePair(issueNumbersKey, "")
                            );
                        }
                        return keyValuePairs;
                    },
                    ...convertedIssueNumbers
                ),
                new SimpleFilterListModalInput(
                    schedulesKey,
                    this.translationService.instant(schedulesKey),
                    SimpleFilterInputType.ListModal,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(schedulesKey, values)
                            );
                        } else {
                            keyValuePairs.push(
                                new KeyValuePair(schedulesKey, "")
                            );
                        }
                        return keyValuePairs;
                    },
                    (filterInput: SimpleFilterListModalInput) => {
                        this.simpleFilterHelperSchedule.openListModal(
                            filterInput,
                            schedules,
                            this
                        );
                    },
                    convertedSchedules
                ),
                new SimpleFilterListModalInput(
                    handledByKey,
                    this.translationService.instant(handledByKey),
                    SimpleFilterInputType.ListModal,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(handledByKey, values)
                            );
                        } else {
                            keyValuePairs.push(
                                new KeyValuePair(handledByKey, "")
                            );
                        }
                        return keyValuePairs;
                    },
                    (filterInput: SimpleFilterListModalInput) => {
                        this.simpleFilterHelperUser.openListModal(
                            filterInput,
                            users,
                            this
                        );
                    },
                    convertedUsers
                ),
                new SimpleFilterInput(
                    archivedBetweenKey,
                    this.translationService.instant(archivedBetweenKey),
                    SimpleFilterInputType.DateRangeSelect,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(archivedBetweenKey, values)
                            );
                        } else {
                            keyValuePairs.push(
                                new KeyValuePair(archivedBetweenKey, "")
                            );
                        }
                        return keyValuePairs;
                    }
                ),
                new SimpleFilterInput(
                    prioritizedKey,
                    this.translationService.instant(prioritizedKey),
                    SimpleFilterInputType.SingleSelect,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(prioritizedKey, values)
                            );
                        } else {
                            keyValuePairs.push(
                                new KeyValuePair(prioritizedKey, "")
                            );
                        }
                        return keyValuePairs;
                    },
                    new SimpleFilterInputItem(
                        StringUtils.YES,
                        this.translationService.instant(StringUtils.YES)
                    ),
                    new SimpleFilterInputItem(
                        StringUtils.NO,
                        this.translationService.instant(StringUtils.NO)
                    )
                ),
                new SimpleFilterInput(
                    integrationsKey,
                    this.translationService.instant(integrationsKey),
                    SimpleFilterInputType.MultiSelect,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(integrationsKey, values)
                            );
                        } else {
                            keyValuePairs.push(
                                new KeyValuePair(integrationsKey, "")
                            );
                        }
                        return keyValuePairs;
                    },
                    ...convertedIntegrations
                ),
                new SimpleFilterInput(
                    statusKey,
                    this.translationService.instant(statusKey),
                    SimpleFilterInputType.SingleSelect,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(statusKey, values)
                            );
                        } else {
                            keyValuePairs.push(new KeyValuePair(statusKey, ""));
                        }
                        return keyValuePairs;
                    },
                    new SimpleFilterInputItem(
                        StringUtils.COMPLETED,
                        this.translationService.instant(StringUtils.COMPLETED)
                    ),
                    new SimpleFilterInputItem(
                        StringUtils.CLOSED,
                        this.translationService.instant(StringUtils.CLOSED)
                    )
                ),
                new SimpleFilterInput(
                    typeKey,
                    this.translationService.instant(typeKey),
                    SimpleFilterInputType.SingleSelect,
                    (values: string) => {
                        const keyValuePairs: KeyValuePair[] = [];
                        if (values) {
                            keyValuePairs.push(
                                new KeyValuePair(typeKey, values)
                            );
                        } else {
                            keyValuePairs.push(new KeyValuePair(typeKey, ""));
                        }
                        return keyValuePairs;
                    },
                    new SimpleFilterInputItem(
                        StringUtils.MEASURE_ASSIGNMENT,
                        this.translationService.instant(
                            StringUtils.MEASURE_ASSIGNMENT
                        )
                    ),
                    new SimpleFilterInputItem(
                        StringUtils.MANUAL_MEASURE_ASSIGNMENT,
                        convertedManualMeasure
                            ? convertedManualMeasure.name
                            : this.translationService.instant(
                                  StringUtils.MANUAL_MEASURE_ASSIGNMENT
                              )
                    )
                )
            );

            if (!this.isInModal) {
                // Needs to be called here due to the data fetch delay.
                this.setSelectedFilterItems();
            }
        } catch (errorResponse) {
            this.handleErrorResponse(errorResponse);
        }
    }

    protected configureTableSort(config: SimpleFilterConfig<Assignment>) {
        config.setSortObjects(
            new SortObject(
                "AssignmentTemplate.Facility.Name" as keyof Assignment,
                this.translationService.instant("Facility")
            ),
            new SortObject(
                this.propertyStrings.issueNumber as keyof Assignment,
                this.translationService.instant("IssueNumber")
            ),
            new SortObject(
                "ErrorResult.TaskChoice.Choice.Value" as keyof Assignment,
                this.translationService.instant("Choice")
            ),
            new SortObject(
                this.propertyStrings.adminComment,
                this.translationService.instant("AdminComment")
            ),
            new SortObject(
                this.propertyStrings.completed,
                this.translationService.instant("Archived"),
                true,
                true
            )
        );
    }

    protected configureTableColumns(config: SimpleTableConfig<Assignment>) {
        config.setColumns(
            new SimpleTableTextColumn(
                this.translationService.instant("Facility"),
                (item) =>
                    new TextContent(
                        item.facility?.name,
                        item.entity?.name,
                        item.facility &&
                        this.loggedInUser.canEdit(
                            this.selectedOrganization.friendlyUrl
                        )
                            ? `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${item.facility.url}`
                            : "",
                        item.entity &&
                        this.loggedInUser.canEdit(
                            this.selectedOrganization.friendlyUrl
                        )
                            ? `${environment.coreUrl}/${this.authService.selectedOrganization.friendlyUrl}/${item.entity.url}`
                            : ""
                    ),
                this.sanitizer,
                this.viewContainerRef,
                this.translationService.instant("Entity")
            ),
            new SimpleTableAssessmentColumn(
                this.translationService.instant("Assessment"),
                (item) => item.choices,
                (item) => item.task.text,
                (item) => item.task.code,
                this.sanitizer,
                this.viewContainerRef,
                "",
                this.translationService
            ),
            new SimpleTableTextColumn(
                this.translationService.instant("Comment"),
                (item) => new TextContent(item.comment, "", "", ""),
                this.sanitizer,
                this.viewContainerRef,
                ""
            ),
            new SimpleTableTextColumn(
                this.translationService.instant("IssueNumber"),
                (item) => new TextContent(item.issueNumber, "", "", ""),
                this.sanitizer,
                this.viewContainerRef,
                "",
                "75px"
            ),
            new SimpleTableTextColumn(
                this.translationService.instant("HandledBy"),
                (item) =>
                    new TextContent(
                        item.completedByUser
                            ? item.completedByUser.getCurrentDeleted(
                                  this.selectedOrganization.id
                              )
                                ? ""
                                : `${item.completedByUser.firstname} ${item.completedByUser.lastname}`
                            : "",
                        item.completedByUser
                            ? item.completedByUser.getCurrentDeleted(
                                  this.selectedOrganization.id
                              )
                                ? this.translationService.instant(
                                      "DeletedUserOn",
                                      {
                                          date: Utils.formatDate(
                                              item.completedByUser.getCurrentDeleted(
                                                  this.selectedOrganization.id
                                              ),
                                              DEFAULT_DISPLAY_DATE_FORMAT
                                          ),
                                      }
                                  )
                                : item.completedByUser.email
                            : "",
                        item.completedByUser
                            ? `${environment.coreUrl}/${
                                  this.authService.selectedOrganization
                                      .friendlyUrl
                              }/${this.getUserUrl(item.completedByUser)}`
                            : "",
                        ""
                    ),
                this.sanitizer,
                this.viewContainerRef,
                ""
            ),
            new SimpleTableEventsColumn(
                this.translationService.instant("Events"),
                (item) => [
                    new EventsContent(
                        `${this.translationService.instant(
                            item.status === StringUtils.COMPLETED
                                ? StringUtils.RESOLVED
                                : "Closed"
                        )} ${Utils.getFormattedDateStringFromString(
                            item.archived,
                            DEFAULT_DISPLAY_DATE_FORMAT
                        )}`,
                        item.archived,
                        item.status === StringUtils.COMPLETED
                            ? "bi-clipboard-check"
                            : "bi-clipboard-x"
                    ),
                ],
                this.sanitizer,
                this.viewContainerRef,
                ""
            ),
            new SimpleTableIconsColumn(
                "",
                (item) => [
                    item.priority
                        ? new IconContent(
                              "bi-arrow-up",
                              "",
                              this.translationService.instant("Prioritized"),
                              ColorUtils.RED_COLOR
                          )
                        : null,
                    this.selectedOrganization.hasDfIntegration &&
                    item.integrations.length
                        ? new IconContent(
                              "bi-database-fill",
                              "",
                              this.translationService.instant("DFRespons")
                          )
                        : null,
                    !!item.adminComment
                        ? new IconContent(
                              "bi-chat-right-text",
                              "",
                              item.adminComment
                          )
                        : null,
                ],
                this.sanitizer,
                this.viewContainerRef,
                ""
            ),
            new SimpleTableStatusColumn(
                this.translationService.instant("Status"),
                (item) =>
                    item.status === StringUtils.COMPLETED
                        ? StringUtils.RESOLVED
                        : item.status,
                this.sanitizer,
                this.viewContainerRef,
                ""
            )
        );
    }

    private getUserUrl(user: User) {
        const pendingUrl = `${RoutesUtils.users}?${RoutesUtils.user}=${RoutesUtils.modalPrefixValueResendEmail}_${user.id}&tab=1`;
        return user.getCurrentStatus(this.selectedOrganization.id) ===
            StringUtils.PENDING
            ? pendingUrl
            : user.id === this.loggedInUser.id
            ? RoutesUtils.profile
            : user.url;
    }

    protected configureTableActions(config: SimpleTableConfig<Assignment>) {
        // HEADER
        config.setHeaderActions(
            new SimpleTableHeaderAction(
                this.translationService.instant("GenerateErrorReport"),
                StringUtils.icons.generateErrorReport,
                () => {
                    const selectedIds = Array.from(this.selectedIds.keys());
                    this.setAsAffected(selectedIds);
                    this.openModalByActionName(
                        `${
                            RoutesUtils.modalPrefixValueBulkGenerateReport
                        }_${selectedIds.join()}`
                    );
                }
            ),
            new SimpleTableHeaderAction(
                this.translationService.instant("GetAssignmentReport"),
                StringUtils.icons.download,
                () => {
                    const selectedIds = Array.from(this.selectedIds.keys());
                    this.setAsAffected(selectedIds);
                    this.wasRowAction = false;
                    this.openModalByActionName(
                        `${
                            RoutesUtils.modalPrefixValueBulkGetReport
                        }_${selectedIds.join()}`
                    );
                }
            ),
            new SimpleTableHeaderActionDelete(
                this.translationService.instant("Delete"),
                StringUtils.icons.delete,
                () => {
                    const selectedIds = Array.from(this.selectedIds.keys());
                    this.setAsAffected(selectedIds);
                    this.openModalByActionName(
                        `${
                            RoutesUtils.modalPrefixValueDelete
                        }_${selectedIds.join()}`
                    );
                }
            )
        );

        // ROW
        this.hideTableRowActions = false;
        config.setHideRowActions(this.hideTableRowActions);

        config.setRowActions(
            new SimpleTableRowActionEdit(
                this.translationService.instant("Open"),
                StringUtils.icons.handle,
                (row) => {
                    this.setAsAffected([row.id]);
                    this.setCrudParams(
                        `${RoutesUtils.modalPrefixValueEdit}_${row.id}`
                    );
                }
            ),
            new SimpleTableRowAction(
                this.translationService.instant("GenerateErrorReport"),
                StringUtils.icons.generateErrorReport,
                (row) => {
                    this.setAsAffected([row.id]);
                    this.openModalByActionName(
                        `${RoutesUtils.modalPrefixValueBulkGenerateReport}_${row.id}`
                    );
                }
            ),
            new SimpleTableRowAction(
                this.translationService.instant("GetAssignmentReport"),
                StringUtils.icons.download,
                (row) => {
                    this.setAsAffected([row.id]);
                    this.wasRowAction = true;
                    this.openModalByActionName(
                        `${RoutesUtils.modalPrefixValueGetReport}_${row.id}`
                    );
                },
                (row) => {
                    return !row.data.reportUrl;
                }
            ),
            new SimpleTableRowAction(
                this.translationService.instant("RecreateAssignmentReport"),
                StringUtils.icons.recreateReport,
                (row) => {
                    this.setAsAffected([row.id]);
                    this.administrationService.openRecreateReportSwal(
                        row.id,
                        this.selectedOrganization.id,
                        () => this.getTableData()
                    );
                },
                () => {
                    return !this.loggedInUser.isSuperAdmin();
                }
            ),
            new SimpleTableRowAction(
                this.translationService.instant("RecreateMeasureAssignment"),
                StringUtils.icons.recreateAssignment,
                (row) => {
                    this.setAsAffected([row.id]);
                    this.administrationService.openRecreateAssignmentSwal(
                        row.id,
                        null,
                        () => this.getTableDataAndClearAfterUpdate(row.id),
                        true
                    );
                },
                () => {
                    return !this.loggedInUser.isSuperAdmin();
                }
            ),
            new SimpleTableRowActionDelete(
                this.translationService.instant("Delete"),
                StringUtils.icons.delete,
                (row) => {
                    this.setAsAffected([row.id]);

                    if (!this.isInModal) {
                        this.setCrudParams(
                            `${RoutesUtils.modalPrefixValueDelete}_${row.id}`
                        );
                    } else {
                        this.openModalByActionName(
                            `${RoutesUtils.modalPrefixValueDelete}_${row.id}`
                        );
                    }
                },
                (row) => {
                    return !this.authService.canEdit();
                }
            )
        );
    }

    private getTableDataAndClearAfterUpdate(rowId) {
        this.selectedIds.delete(rowId);
        this.getTableData();
    }

    protected configureTableEmptyState(config: SimpleTableConfig<Assignment>) {
        config.emptyState = new SimpleTableEmptyState(
            "MeasureAssignments",
            StringUtils.icons.measureAssignment
        );
    }
    protected setPropertiesAndOpenViewObjectsPopover() {}

    protected openModalByActionName(value: string) {
        const [actionName, idString] = value.split("_");
        this.shouldClearAfterUpdate =
            !this.isInModal && this.isBulkAction(actionName);
        if (actionName === RoutesUtils.modalPrefixValueEdit) {
            this.bsModalRef = this.modalService.show(
                DetailedAssignmentComponent,
                {
                    initialState: {
                        editModelId: idString,
                        disableTabIndexUrl: this.isInModal,
                    },
                    ...ConfigUtils.MODAL_CONFIG_X_LARGE,
                    ...this.closeInterceptorConfig(),
                }
            );
        } else if (
            actionName === RoutesUtils.modalPrefixValueBulkGenerateReport
        ) {
            this.bsModalRef = this.modalService.show(GenerateReportComponent, {
                initialState: {
                    editModelIds: idString.split(","),
                    isMeasure: true,
                    isArchived: true,
                    sort: this.filterObject.sort,
                    disableTabIndexUrl: true,
                },
                ...ConfigUtils.MODAL_CONFIG_LARGE,
            });
        } else if (
            actionName === RoutesUtils.modalPrefixValueBulkGetReport ||
            actionName === RoutesUtils.modalPrefixValueGetReport
        ) {
            this.bsModalRef = this.modalService.show(StaticReportComponent, {
                initialState: {
                    editModelIds: idString.split(","),
                    disableTabIndexUrl: true,
                    useAffectableIds: true,
                    hideText: this.wasRowAction,
                    isMeasure: true,
                },
                ...ConfigUtils.MODAL_CONFIG_LARGE,
            });
        } else if (actionName === RoutesUtils.modalPrefixValueDelete) {
            this.bsModalRef = this.modalService.show(
                DeleteAssignmentsComponent,
                {
                    initialState: {
                        deleteModelIds: idString.split(","),
                    },
                    ...ConfigUtils.MODAL_CONFIG_SMALL,
                }
            );
        }
        this.subscribeToCrudModalContent();
    }

    protected import(file: File) {}

    protected export() {}
}
