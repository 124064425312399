import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import { AuthService } from 'app-core/auth/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { Facility } from 'app-inspection/facility/facility';
import { FacilityService } from 'app-inspection/facility/facility.service';
import { ToastrService } from 'ngx-toastr';
import { SimpleBulkEditModalDirective } from 'app-core/shared-core/simple-components/crud/modal/simple-bulk-edit-modal.directive';
import { SetStatusTabComponent } from './tabs/set-status-tab.component';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';

@Component({
	templateUrl: './set-status-facility.component.html'
})
export class SetStatusFacilityComponent extends SimpleBulkEditModalDirective<Facility> implements OnInit {

	@ViewChild(SetStatusTabComponent) setFacilityStatusTabComponent: SetStatusTabComponent;

	constructor(
		protected authService: AuthService,
		protected modalService: BsModalService,
		protected router: Router,
		protected toastrService: ToastrService,
		protected translationService: TranslationService,
		private facilityService: FacilityService) {
		super(
			authService,
			modalService,
			router,
			toastrService,
			translationService
		);
	}

	async ngOnInit() {
		this.initialModel = new Facility({});
	}

	changeDetailsIsValid() {
		return this.setFacilityStatusTabComponent?.formIsValid();
	}

	changeDetailsHasErrors() {
		return this.setFacilityStatusTabComponent?.formHasErrors();
	}

	everythingIsValid() {
		return this.changeDetailsIsValid();
	}

	protected async createOrUpdate() {
		this.pending = true;
		try {
			const response = await this.facilityService.updateStatus(this.editModelIds, this.initialModel.status);
			this.handleSuccessResponse(response);

		} catch (errorResponse) {
			this.pending = false;
			this.handleErrorResponse(errorResponse);
		}
	}

	protected triggerAllValidation() {
		this.setFacilityStatusTabComponent?.triggerValidation();
	}

	protected instantiateModel(item: Facility) {
		return new Facility(item);
	}

	hasUnsavedChanges() {
		return false;
	}
}
