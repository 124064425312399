<simple-crud-modal
	[titleText]="'Subscriber' | translate"
	[closeText]="'Close' | translate"
	[submitText]="(isEdit ? 'Update' : 'Create') | translate"
	[disableSubmit]="!everythingIsValid() || !hasUnsavedChanges()"
	[isLoading]="pending"
	[displayDummy]="!modifiedModel"
	(onClose)="close()"
	(onSubmit)="submit()">
		<tabset *ngIf="modifiedModel">
			<tab
				[customClass]="changeDetailsIsValid() ? 'valid' : changeDetailsHasErrors() ? 'invalid' : ''">
					<ng-template tabHeading>
						<simple-tab-heading
							[icon]="'bi bi-card-text'"
							[title]="'Details' | translate"
							[helpText]="'SubscriberChangeDetailsHelpText' | translate">
						</simple-tab-heading>
					</ng-template>
					<change-subscriber-details-tab
						[changeModel]="modifiedModel">
					</change-subscriber-details-tab>
			</tab>
		</tabset>
</simple-crud-modal>
