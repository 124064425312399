import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorUtils } from 'app-core/shared-core/tools/color-utils';

@Component({
	selector: 'simple-form-color-picker',
	templateUrl: './simple-form-color-picker.component.html',
	styleUrls: ['./simple-form-color-picker.component.less']
})
export class SimpleFormColorPickerComponent {

	@Input() label: string;
	@Input() colorString: string;

	@Output() onColorChange = new EventEmitter<string>();

	getPresetColors() {
		return ColorUtils.getInspectionColors();
	}

	handlePickerChange(colorString: string) {
		this.onColorChange.emit(colorString);
	}
}
