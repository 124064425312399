import { Injectable, OnDestroy } from '@angular/core';
import { CanEditPipe } from 'app-core/shared-core/pipes/can-edit.pipe';
import { RoutesUtils } from 'app-core/shared-core/tools/routes-utils';
import { TranslationService } from 'app-core/shared-core/translation/translation.service';
import { DEFAULT_LANGUAGE, LoggedInUser } from 'app-core/user/user';
import * as Oidc from 'oidc-client';
import { User, UserManager } from 'oidc-client';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { Organization } from '../organization/organization';
import { HostedHttpClientService } from '../shared-core/hosted-httpclient.service';
import { LocalStorageKey, LocalStorageService } from '../shared-core/local-storage/local-storage.service';

const AUTH_HOSTED_ENDPOINTS = {
	LOGGED_IN_USER: `${RoutesUtils.user}/${RoutesUtils.current}`,
};

const config: Oidc.UserManagerSettings = {
	authority: environment.idServer,
	client_id: environment.clientId,
	redirect_uri: `${environment.coreUrl}/${RoutesUtils.callback}`,
	post_logout_redirect_uri: environment.coreUrl,
	response_type: 'id_token token',
	scope: 'openid profile core.api inspection-api.full_access offline_access',
	automaticSilentRenew: true,
	silentRequestTimeout: 120000,
	silent_redirect_uri: `${environment.coreUrl}/${RoutesUtils.renewToken}`,
	userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
	revokeAccessTokenOnSignout: true
};

@Injectable()
export class AuthService implements OnDestroy {

	private _userManager: UserManager = new UserManager(config);

	loggedInUser$ = new BehaviorSubject<LoggedInUser>(null);
	selectedOrganization$ = new BehaviorSubject<Organization>(null);
	isSwitchingUser$ = new BehaviorSubject<boolean>(false);

	get userManager() {
		return this._userManager;
	}

	get loggedInUser() {
		return this.loggedInUser$.value;
	}

	get selectedOrganization() {
		return this.selectedOrganization$.value;
	}

	constructor(
		private hostedHttpClient: HostedHttpClientService,
		private localStorageService: LocalStorageService,
		private translationService: TranslationService,
		private canEditPipe: CanEditPipe) {
		this.initiate();
	}

	initiate() {
		if (Oidc && Oidc.Log && Oidc.Log.logger && environment.logger) {
			Oidc.Log.logger = console;
		}
		if (this.hostedHttpClient) {
			this.hostedHttpClient.mgr = this.userManager;
		}
		this.userManager.events.addUserUnloaded(() => {
			this.clearStaleState();
			this.hostedHttpClient.loggedIn = false;
		});
		this.userManager.events.addUserSignedOut(() => {
			Swal.fire({
				title: this.translationService.instant('YouHaveBeenLoggedOut'),
				text: this.translationService.instant('YourSessionHasExpired'),
				icon: 'info',
				confirmButtonText: this.translationService.instant('LoginAgain'),
				allowOutsideClick: false,
				allowEscapeKey: false,
				customClass: {
					actions: 'single-button',
				}
			}).then((result) => {
				if (result.value) {
					this.startLogout();
				}
			});

			// Eftersom detta verkar skapa andra problem så testar vi att kommentera ut detta!

			// // When detecting a login state change,
			// // determine whether to log in or log out.
			// this.isSwitchingUser$.next(true);
			// Promise.all([
			// 	this.userManager.querySessionStatus(),
			// 	this.userManager.getUser()
			// ])
			// 	.then(res => {
			// 		const [session, user] = res;
			// 		if (user?.profile?.sub !== session?.sub) {
			// 			this.startLogin();
			// 		} else {
			// 			this.startLogout();
			// 		}
			// 	})
			// 	.catch(error => {
			// 		Utils.logMessage(error, null, LogLevel.Error);
			// 		this.startLogout();
			// 	});
		});
		this.userManager.events.addAccessTokenExpiring(() => {
			this.refresh();
		});
		this.userManager.events.addSilentRenewError((error) => {
			if (!environment.production) {
				console.error('Silent renew failed', error);
			}
		});
		this.userManager.startSilentRenew();
	}

	setSelectedOrganization(organization: Organization) {
		this.selectedOrganization$.next(new Organization(organization));

		this.localStorageService.setItem(LocalStorageKey.SelectedOrganization, new Organization(this.selectedOrganization));
		this.hostedHttpClient.selectedOrganizationId = this.selectedOrganization.id;
		this.hostedHttpClient.selectedOrganizationParentId = this.selectedOrganization.parentId;
	}

	canEdit() {
		return this.loggedInUser && this.canEditPipe.transform(this.loggedInUser, this.selectedOrganization.friendlyUrl);
	}

	async requestUser() {
		try {
			const user = await this.userManager.getUser();
			if (user) {
				this.hostedHttpClient.loggedInUserUM = user;
				this.hostedHttpClient.loggedIn = true;
				const response = await this.hostedHttpClient.get(AUTH_HOSTED_ENDPOINTS.LOGGED_IN_USER, {}, true);
				this.loggedInUser$.next(new LoggedInUser(response.data));
				return this.loggedInUser;
			} else {
				this.clearStaleState();
				return null;
			}
		} catch (errorResponse) {
			this.clearStaleState();
			this.startLogin();
			return null;
		}
	}

	// User manager.

	startLogin(ignoreData?: boolean) {
		this.userManager.signinRedirect(this.addOidcMetadata(ignoreData))
			.catch(() => {
				window.location.href = environment.idServer;
			});
	}

	// Called from callback component.
	completeLogin(): Promise<User> {
		return this.userManager.signinRedirectCallback();
	}

	startLogout() {
		this.userManager.signoutRedirect(this.addOidcMetadata())
			.then(() => {
				this.completeLogout();
			})
			.catch(() => {
				window.location.href = environment.idServer;
			});
	}

	completeLogout() {
		this.userManager.signoutRedirectCallback()
			.then(_ => {
				this.clearStaleState();
				this.localStorageService.clearStorage();
				sessionStorage.clear();
				this.hostedHttpClient.loggedInUserUM = null;
				this.hostedHttpClient.loggedIn = false;
				this.loggedInUser$.next(null);
			})
			.catch(() => {
				window.location.href = environment.idServer;
			});
	}

	refresh() {
		return this.userManager.signinSilent()
			.then(() => {
				return this.requestUser();
			});
	}

	clearStaleState() {
		this.userManager.clearStaleState();
	}

	// Unclear if something else than the 'ui_locales' are used,
	// but we will keep it here until we are sure they can be removed.

	// When redirected back to login from invitation pages we get stuck
	// in an endless loop if we are sending the 'data' property.
	// Therefore we will ignore it in that specific case.
	addOidcMetadata(ignoreData?: boolean) {
		return {
			data: ignoreData ? '' : window.location.pathname,
			ui_locales: this.loggedInUser && this.loggedInUser.culture ? this.loggedInUser.culture : DEFAULT_LANGUAGE,
			end_session_endpoint: `/${RoutesUtils.logout}`
		};
	}

	ngOnDestroy() {
		this.loggedInUser$.complete();
		this.selectedOrganization$.complete();
		this.isSwitchingUser$.complete();
	}
}
