import { Directive } from '@angular/core';
import { BackendResponse } from 'app-core/shared-core/abstract-components/service/base.service';
import { KeyValuePair } from 'app-core/shared-core/filter';
import { SimpleCrudDirective } from '../simple-crud.directive';

@Directive()
export abstract class SimpleCreateEditModalDirective<T extends { id: string }> extends SimpleCrudDirective<T> {

	editModelId: string = '';
	disableTabIndexUrl: boolean;

	initialModel: T;
	modifiedModel: T;

	serverErrors: KeyValuePair[] = [];

	get isEdit() {
		return !!this.editModelId;
	}

	submit() {
		if (this.everythingIsValid() && this.hasUnsavedChanges()) {
			this.serverErrors = [];
			this.createOrUpdate();
		} else {
			this.triggerAllValidation();
		}
	}

	protected handleSuccessResponse(response: BackendResponse, customMessage?: string) {
		if (response.data?.backgroundJobStarted) {
			this.displaySuccessMessage(customMessage);
			Object.assign(this.initialModel, this.modifiedModel);
			this.close();
		} else {
			this.displaySuccessMessage(response.successMessage);
			if (response.data && !response.data.file) {
				const data = response.data;
				let items: T[] = [];
				if (data.created && Array.isArray(data.created) && data.created.length) {
					items = data.created;
				} else if (Array.isArray(data) && data.length) {
					items = data;
				} else {
					items = [data];
				}
				items.forEach(item => this.instantiateModel(item));
				this.emit(items);
			} else {
				this.close();
			}
		}
	}

	protected emit(items: T[]) {
		if (this.isEdit) {
			this.updated$.next(items);
		} else {
			this.created$.next(items);
		}
	}

	override handleErrorResponse(response: BackendResponse) {
		if (response.error?.errors) {
			const errors = Object.entries(response.error.errors)
				.map(entry => new KeyValuePair(entry[0], entry[1].toString()));

			errors.forEach(error => {
				if (this.modifiedModel) {
					const key = error.key.toLowerCase();
					this.serverErrors.push(new KeyValuePair(key, error.value));
				} else {
					this.displayErrorMessage(error.value);
				}
			});
		} else {
			super.handleErrorResponse(response);
		}
	}

	abstract everythingIsValid(): boolean;
	protected abstract createOrUpdate(): void;
	protected abstract triggerAllValidation(): void;
	protected abstract instantiateModel(item: T): T;
}
