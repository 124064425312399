import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { SimpleTableTextColumn } from '../simple-components/list/table/columns/simple-table-text-column';

@Pipe({
	name: 'renderValue'
})
export class RenderValuePipe<T> implements PipeTransform {
	transform(column: SimpleTableTextColumn<T>, item: T): SafeHtml {
		return column.renderValue(item);
	}
}
