import { Directive } from '@angular/core';
import { SimpleCrudDirective } from '../simple-crud.directive';

@Directive()
export abstract class SimpleReadModalDirective<T extends { id: string }> extends SimpleCrudDirective<T> {

	readModelId: string;

	readModel: T;
}
